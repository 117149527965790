import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const RefreshRotate = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M12 20.0034C14.9314 20.0036 17.6284 18.4013 19.0299 15.8267" />
      <path d="M20.0033 11.9999C20.0033 7.5798 16.4201 3.99658 12 3.99658" />
      <path d="M12 3.99658C9.06862 3.99634 6.37165 5.59871 4.97008 8.17332" />
      <path d="M3.99667 12C3.99667 16.4201 7.57988 20.0033 12 20.0033" />
      <path d="M4.97008 8.17335C6.71955 4.95833 10.4272 3.33982 13.9742 4.2427C17.5213 5.14558 20.0039 8.33975 20.0033 11.9999" />
      <path d="M3.99667 12C3.99611 15.6602 6.47869 18.8544 10.0258 19.7572C13.5729 20.6601 17.2805 19.0416 19.0299 15.8266" />
      <path d="M8.04535 8.1737H4.50888V4.63623" />
      <path d="M15.9547 15.8267H19.4911V19.3641" />
    </svg>
  );
};
