import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const PageWithCheck = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
    className={props.className}
  >
    <defs>
      <path
        id="page-with-check-a"
        d="M12 4c1.323 0 2.486.78 3.019 1.94l.026.06h1.622a2 2 0 011.996 1.883l.004.117v10a2 2 0 01-2 2H7.333a2 2 0 01-2-2V8a2 2 0 012-2h1.622l.026-.06a3.324 3.324 0 012.867-1.937zm0 1.333c-.913 0-1.7.623-1.927 1.5a.667.667 0 01-.646.5H7.333A.667.667 0 006.667 8v10c0 .368.299.667.666.667h9.334a.667.667 0 00.666-.667V8a.667.667 0 00-.666-.667h-2.094a.667.667 0 01-.646-.5A1.994 1.994 0 0012 5.334zm2.703 5.446c.285.19.376.56.224.857l-.039.067-2.667 4a.667.667 0 01-.857.224l-.067-.039-2-1.333a.667.667 0 01.672-1.15l.067.04 1.445.963 2.298-3.444a.667.667 0 01.924-.185zM12 6.66c.342 0 .624.257.662.589l.005.084a.667.667 0 01-1.33.078l-.004-.084c0-.369.299-.667.667-.667z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="page-with-check-b" fill="#fff">
        <use xlinkHref="#page-with-check-a"></use>
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#page-with-check-a"></use>
      <g
        fill={props.color || theme.colors.grey700}
        mask="url(#page-with-check-b)"
      >
        <path d="M0 0h24v24H0z"></path>
      </g>
    </g>
  </svg>
);
