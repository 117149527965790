import React from "react";

import { CheckListItem, Margins } from "@ui/Atoms";

import { List, ListWrapper, SectionHeading, StyledSection } from "./shared";

export interface AdvantagesProps {
  data: {
    advantagesTitle: string;
    advantagesList: string[];
  };
}

const AdvantagesSection = (props: AdvantagesProps): React.ReactElement => {
  return (
    <StyledSection isWhite>
      <Margins xs={[null, "base_x8", null, null]}>
        <SectionHeading type="h4">{props.data.advantagesTitle}</SectionHeading>
      </Margins>
      <ListWrapper>
        <List>
          {props.data.advantagesList.map(advantage => (
            <CheckListItem key={advantage} value={advantage} />
          ))}
        </List>
      </ListWrapper>
    </StyledSection>
  );
};

export { AdvantagesSection };
