import { getLangCode } from "@services/IntlLanguage";

export const intlFormatDate = (
  date: Date | number,
  format: Intl.DateTimeFormatOptions,
  locales: string | string[] = getLangCode(),
): string => {
  return new Intl.DateTimeFormat(locales, format).format(date);
};

export const intlFormatRange = (
  startDate: Date | number,
  endDate: Date | number,
  format: Intl.DateTimeFormatOptions,
  locales: string | string[] = getLangCode(),
): string => {
  // TypeScript's lib do not support `formatRange` method yet,
  const dateTimeFormatInstance = new Intl.DateTimeFormat(
    locales,
    format,
  ) as any;

  // fallback for browsers without formatRange support
  if (!dateTimeFormatInstance.formatRange) {
    return (
      intlFormatDate(startDate, format, locales) +
      "-" +
      intlFormatDate(endDate, format, locales)
    );
  }

  return dateTimeFormatInstance.formatRange(startDate, endDate);
};
