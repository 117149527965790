import * as React from "react";

import theme from "@ui/themes/default";

import { Icon, style } from "./Symbolicons";

export const Minus = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <path
        d="M17.333 11.333a.667.667 0 01.078 1.33l-.078.004H6.667a.667.667 0 01-.078-1.33l.078-.004h10.666z"
        fill={props.color || theme.colors.grey700}
        fillRule="nonzero"
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <path
        d="M17.333 10.667H6.667a1.334 1.334 0 000 2.666h10.666a1.334 1.334 0 000-2.666z"
        fill={props.color || theme.colors.grey700}
        fillRule="nonzero"
      />
    </svg>
  );
