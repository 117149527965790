import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";

import { ThunkActionDispatch } from "@typings/work.reduxThunkActions";
import { ILoginUser } from "@typings/user";

import {
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  WHOAMI_ENDPOINT,
  USER_RECOVER_PASSWORD_ENDPOINT,
  USER_DISCONNECT_OAUTH_ENDPOINT,
  CONTACT_FORM_ENDPOINT,
  DEVICE_ENDPOINT,
} from "@config/endpoints";

import { IStore } from "@work/redux/reducers";

import { asyncActionCreator } from "@work/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("user");

export const FETCH_USER_DATA = action.make("fetch user data");
export const LOGIN_USER = action.make("login");
export const LOGOUT_USER = action.make("logout");
export const RECOVER_PASSWORD = action.make("recover password");
export const DISCONNECT_OAUTH = action.make("disconnect oauth");
export const SEND_CONTACT_FORM = action.make("send contact form");
export const SEND_DEVICE_TOKEN = action.make("send device token");

export const fetchUserData = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
) => {
  const action = await asyncActionCreator({
    url: WHOAMI_ENDPOINT,
    action: FETCH_USER_DATA,
  });

  return dispatch(action);
};

export const loginUser = ({
  username,
  password,
  rememberMe,
}: ILoginUser) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
) => {
  const action = await asyncActionCreator({
    action: LOGIN_USER,
    method: ASYNC_ACTION_TYPES.POST,
    url: LOGIN_ENDPOINT,
    body: { username, password, rememberMe },
    retryConnection: false,
  });

  return dispatch(action);
};

export const logoutUser = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
) => {
  const action = await asyncActionCreator({
    action: LOGOUT_USER,
    url: LOGOUT_ENDPOINT,
    method: ASYNC_ACTION_TYPES.POST,
  });

  return dispatch(action);
};

export const recoverPassword = (email: string) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
) => {
  const action = await asyncActionCreator({
    action: RECOVER_PASSWORD,
    url: USER_RECOVER_PASSWORD_ENDPOINT,
    method: ASYNC_ACTION_TYPES.POST,
    body: { email },
  });

  return dispatch(action);
};

export const disconnectOAuth = (password: string) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
) => {
  const action = await asyncActionCreator({
    action: DISCONNECT_OAUTH,
    url: USER_DISCONNECT_OAUTH_ENDPOINT,
    method: ASYNC_ACTION_TYPES.POST,
    body: {
      password,
    },
  });

  return dispatch(action);
};

export const sendContactForm = (body: object) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
) => {
  const action = await asyncActionCreator({
    action: SEND_CONTACT_FORM,
    url: CONTACT_FORM_ENDPOINT,
    method: ASYNC_ACTION_TYPES.POST,
    body,
  });

  return dispatch(action);
};

/**
 * For now, I'm adding this action in user reducer,
 * since /devices endpoint has only one path.
 * We can create a separate reducer
 * if the API for this domain keeps growing.
 */
export const sendDeviceToken = (token: string) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
) => {
  const action = await asyncActionCreator({
    action: SEND_DEVICE_TOKEN,
    url: DEVICE_ENDPOINT,
    method: ASYNC_ACTION_TYPES.PUT,
    body: {
      deviceToken: token,
    },
  });

  return dispatch(action);
};
