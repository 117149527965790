import React from "react";
import styled from "styled-components";

import theme from "@ui/themes/default";
import { Container, Heading, Margins, Paragraph } from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useBrowserRerender } from "@hooks/useBrowserRerender";

import { StyledSection } from "./shared";

export interface QuoteSectionProps {
  quote_title: { text: string };
  quote_quote: { text: string };
  quote_author: { text: string };
}

const QuoteSection = (props: QuoteSectionProps): React.ReactElement => {
  const isBrowser = useBrowserRerender();
  const { mobile, tablet } = useBreakpoint();
  const isMobile = mobile || tablet;

  return (
    <Section>
      <Container>
        <Margins
          xs={[null, "base_x3", null, null]}
          lg={[null, "base_x8", null, null]}
        >
          <Heading
            type="h2"
            styleAs={isBrowser && isMobile ? "h6" : "h4"}
            color={theme.colors.white}
            textAlignXs="left"
            textAlignMd="left"
            textAlignLg="center"
          >
            {props.quote_title.text}
          </Heading>
        </Margins>

        <blockquote>
          <Quote>
            {isMobile ? `„${props.quote_quote.text}”` : props.quote_quote.text}
          </Quote>
        </blockquote>
        <Author>
          {isMobile
            ? props.quote_author.text
            : `— ${props.quote_author.text} —`}
        </Author>
      </Container>
    </Section>
  );
};

export { QuoteSection };

const Section = styled(StyledSection)`
  background: ${props => props.theme.colors.black500};
  color: ${props => props.theme.colors.white};
`;

const Quote = styled(Paragraph)`
  position: relative;
  margin: 0 auto;
  font-size: ${props => props.theme.fonts.sizes.h6};
  line-height: ${props => props.theme.fonts.lineHeights.h5};
  color: ${props => props.theme.colors.white};
  white-space: pre-line;
  text-align: left;
  font-size: ${props => props.theme.fonts.sizes.body};
  font-style: italic;

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.fonts.sizes.h5};
    text-align: center;

    &:before {
      content: "''";
      position: absolute;
      left: -${rem(20)};
      top: -${rem(20)};
    }

    &:after {
      content: ",,";
      position: absolute;
      right: -${rem(20)};
      bottom: -${rem(20)};
    }
  }
`;

const Author = styled.div`
  color: ${props => props.theme.colors.grey500};
  margin-top: ${props => props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.desktop} {
    margin-top: ${props => props.theme.margins.base_x4};
    text-align: center;
  }
`;
