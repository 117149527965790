import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Star = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        clipRule="evenodd"
        d="m12 2.896 2.935 5.993 6.565.967-4.75 4.662 1.121 6.586L12 17.993l-5.871 3.111 1.121-6.586L2.5 9.856l6.564-.967L12 2.896Z"
      />
    </svg>
  );
};
