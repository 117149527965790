import * as React from "react";
import styled, { css, useTheme } from "styled-components";

import { Heading } from "@ui/Atoms/Headings";
import { Paragraph } from "@ui/Atoms/Paragraph";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

import { Markdown } from "@containers/Markdown";

type BaseProps = {
  children?: React.ReactNode;
  id?: string;
  className?: string;
  testId?: string;
};

type DefaultProps = {
  variant?: never;
  title?: string | React.ReactElement;
  subtitle?: string;
  withSmallTitle?: boolean;
  isThin?: boolean;
  isTransparent?: boolean;
  noMarginsMobile?: boolean;
  textAlignLg?: string;
};

type NewProps = {
  variant: "new";
  title: string;
  subtitle: string;
  icon: React.ReactElement;
  label?: React.ReactElement;
};

type Props = BaseProps & (DefaultProps | NewProps);

const OptionPanel = (props: Props): React.ReactElement => {
  const theme = useTheme();
  const { title, ...otherProps } = props;

  if (props.variant === "new") {
    return (
      <TestWrapper testId={props.testId}>
        <OptionPanelSection {...otherProps} isNew>
          <HeadingWrapper>
            <Title>
              {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
              <StyledHeading hasSubtitle={false} noMarginBotton>
                <Markdown>{props.title}</Markdown>
              </StyledHeading>
              {props.label}
            </Title>
            <Paragraph type="body2" weight="normal" noMarginBottom>
              {props.subtitle}
            </Paragraph>
          </HeadingWrapper>
          {props.children}
        </OptionPanelSection>
      </TestWrapper>
    );
  }

  return (
    <TestWrapper testId={props.testId}>
      <OptionPanelSection {...otherProps}>
        {title && (
          <StyledHeading
            withSmallTitle={props.withSmallTitle}
            hasSubtitle={!!props.subtitle}
            textAlignLg={props.textAlignLg}
          >
            {typeof title === "string" ? <Markdown>{title}</Markdown> : title}
          </StyledHeading>
        )}
        {props.subtitle && (
          <Paragraph
            type="body2"
            color={theme.colors.grey600}
            textAlignLg={props.textAlignLg}
            noMarginBottom
          >
            {props.subtitle}
          </Paragraph>
        )}
        {props.children}
      </OptionPanelSection>
    </TestWrapper>
  );
};

export const OptionPanelSection = styled.div<{
  isThin?: boolean;
  isTransparent?: boolean;
  noMarginsMobile?: boolean;
  isNew?: boolean;
}>`
  margin: ${props => (props.isThin ? "0" : props.theme.margins.base)} 0;

  padding: ${props =>
    props.isThin
      ? `${props.theme.margins.base} ${props.theme.margins.base_x2}`
      : `${props.theme.margins.base_x4} ${props.theme.margins.base_x3}`};

  border-radius: ${props => props.theme.buttons.borderRadius};

  ${props =>
    props.noMarginsMobile &&
    css`
      margin-left: -${props.theme.margins.base_x2};
      margin-right: -${props.theme.margins.base_x2};
    `}

  ${props =>
    !props.isTransparent &&
    `
    background: ${props.theme.colors.white};
  `}

  ${props => props.theme.breakpoints.tablet} {
    padding: ${props =>
      props.isThin
        ? `${props.theme.margins.base} ${props.theme.margins.base_x2}`
        : props.theme.margins.base_x5};
  }

  ${props => props.theme.breakpoints.desktop} {
    margin: ${props => props.theme.margins.base} 0;
  }

  ${props =>
    props.isNew &&
    css`
      margin-top: 0;
      margin-bottom: 0;
      display: flex;
      gap: ${({ theme }) => theme.margins.base};
    `}
`;

const IconWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.grey20};
  border-radius: ${({ theme }) => theme.border.radius.half};
  padding: ${({ theme }) => theme.margins.base};
`;

const StyledHeading = styled(Heading).attrs({ type: "h6" })<{
  withSmallTitle?: boolean;
  hasSubtitle?: boolean;
}>`
  margin-bottom: ${props =>
    props.noMarginBotton
      ? 0
      : props.hasSubtitle
      ? props.theme.margins.base
      : props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.withSmallTitle && props.theme.fonts.sizes.body};
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.margins.base};
`;

const Title = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margins.base};
  width: 100%;
  font-size: ${({ theme }) => theme.fonts.sizes.h6};
  font-weight: ${({ theme }) => theme.fonts.weights.semibold};
  line-height: ${({ theme }) => theme.fonts.lineHeights.h6};
  align-items: center;
`;

export { OptionPanel, StyledHeading as OptionPanelHeader };
