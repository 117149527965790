import * as React from "react";
import { Record } from "immutable";
import { NavLink as Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { NAVBAR } from "@config/testIds/navbar";

import { IUser } from "@typings/user";

import {
  Heading,
  Margins,
  TestWrapper,
  VisibleOn,
  TotalCounter,
} from "@ui/Atoms";
import { Color } from "@ui/Atoms/NotificationBall";
import {
  Dropdown,
  DropdownItem,
  DropdownGroupHeading,
} from "@ui/Molecules/Dropdown";
import { NavLink, NavGroupHeading } from "@ui/Organisms/Navbar";
import { VIEWPORTS } from "@ui/themes/ThemeInterface";
interface NavigationItem {
  label: string;
  icon?: React.ReactElement;
  href?: string;
  action?: () => void;
  isHidden?: boolean;
  isSpecial?: boolean;
  testId?: string;
  skipHrefPrefix?: boolean;
  telemetryEventId?: string;
  counter?: {
    value: number;
    color: string;
  };
}

interface NavigationGroup {
  heading?: string;
  headingIcon?: React.ReactElement;
  items: NavigationItem[];
}

export type NavigationConfig = NavigationGroup[];

interface Props {
  isUserLoggedIn: boolean;
  userData: Record<IUser> | null;
  currentLanguage: string;
  baseUrl: string;
  useNativeLinks: boolean;
  loginLabel: string;
  config: NavigationConfig;
  countOfNotifications?: {
    myCleaningsTotal: number | null;
    newCleaningsTotal: number | null;
  };
  isDark?: boolean;
  skipLoginButton?: boolean;
  loginUser: () => void;
  handleTelemetry?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    label: string,
    eventId: string,
    redirect: boolean,
  ) => Promise<any>;
}

interface LinkFacadeProps {
  children: React.ReactNode;
  useNativeLinks: boolean;
  to: string;
  testId?: string;
  className?: string;
  onClick?: (e: React.SyntheticEvent) => void;
}

const LinkFacade = (props: LinkFacadeProps) => {
  const onClickHandler = (e: React.SyntheticEvent) => {
    if (!props.onClick) {
      return;
    }

    e.preventDefault();
    props.onClick(e);
  };

  const LinkComponent = () =>
    props.useNativeLinks ? (
      <TestWrapper testId={props.testId}>
        <StyledA
          href={props.to}
          onClick={onClickHandler}
          className={props.className}
        >
          {props.children}
        </StyledA>
      </TestWrapper>
    ) : (
      <TestWrapper testId={props.testId}>
        <StyledLink
          to={props.to}
          onClick={onClickHandler}
          className={props.className}
        >
          {props.children}
        </StyledLink>
      </TestWrapper>
    );

  return <LinkComponent />;
};

const UserDropdown = (props: Props): React.ReactElement | null => {
  const linkPrefix = props.useNativeLinks
    ? `/${props.currentLanguage}/client#${props.baseUrl}`
    : props.baseUrl;

  const userName = props.userData
    ? props.userData.get("firstName") !== ""
      ? `${props.userData.get("firstName")} ${props.userData.get("lastName")}`
      : props.userData.get("userName")
    : null;

  return (
    <React.Fragment>
      {/** Mobile Navigation --- start */}
      <VisibleOn viewports={[VIEWPORTS.MOBILE, VIEWPORTS.TABLET]} as={"li"}>
        <ul>
          {userName && (
            <TestWrapper testId={NAVBAR.MOBILE_HEADING}>
              <div>
                <StyledHeading isDark={props.isDark} type="h6" hotjarSupress>
                  {userName}
                </StyledHeading>
              </div>
            </TestWrapper>
          )}
          {props.config.map((group, groupIdx) => {
            return (
              <React.Fragment key={`nav-group-${groupIdx}`}>
                {group.heading && (
                  <NavGroupHeading isDark={props.isDark}>
                    <span>{group.heading}</span>
                    {group.headingIcon}
                  </NavGroupHeading>
                )}
                {group.items.map((item, idx) => {
                  const withBorder =
                    idx === group.items.length - 1 &&
                    groupIdx < props.config.length - 1 &&
                    !props.skipLoginButton;

                  const linkFacadeProps = {
                    to: item.skipHrefPrefix
                      ? `${item.href}`
                      : `${linkPrefix}${item.href}`,
                    useNativeLinks:
                      props.useNativeLinks || Boolean(item.skipHrefPrefix),
                    ...((item.action || item.telemetryEventId) && {
                      onClick: (
                        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                      ) => {
                        item.action?.();

                        if (item.telemetryEventId) {
                          props.handleTelemetry?.(
                            e,
                            item.label,
                            item.telemetryEventId,
                            !item.action,
                          );
                        }
                      },
                    }),
                    testId: item.testId,
                  };

                  return !item.isHidden ? (
                    <NavLink
                      key={`nav-item-${idx}`}
                      isThin
                      special={item.isSpecial}
                      withIcon={Boolean(item.icon)}
                      withBorder={withBorder}
                      isDark={props.isDark}
                    >
                      <LinkFacade {...linkFacadeProps}>
                        <span>
                          {item.label}
                          {!!item.counter && (
                            <TotalCounterStyled
                              color={item.counter.color as Color}
                              total={item.counter.value}
                            />
                          )}
                        </span>

                        {item.icon}
                      </LinkFacade>
                    </NavLink>
                  ) : null;
                })}
              </React.Fragment>
            );
          })}
        </ul>
      </VisibleOn>
      {/** Mobile Navigation --- end */}

      {/** Desktop Navigation --- start */}
      {props.isUserLoggedIn && (
        <Margins lg={[null, null, null, "base_x3"]}>
          <ListItemDesktopOnly>
            <TestWrapper testId={NAVBAR.USER_DROPDOWN}>
              <div>
                <Dropdown
                  className={"user-dropdown"}
                  toLeft
                  isDark={props.isDark}
                  isDarkOutline={!props.isDark}
                  currentState={
                    <span data-recording-disable data-hj-suppress>
                      {userName}
                    </span>
                  }
                  containerTestId={NAVBAR.USER_DROPDOWN_ITEMS}
                >
                  {props.config.map((group, groupIdx) => {
                    return (
                      <React.Fragment key={`nav-group-${groupIdx}`}>
                        {group.heading && (
                          <TestWrapper
                            testId={NAVBAR.USER_DROPDOWN_BATGROUP_HEADING}
                          >
                            <DropdownGroupHeading>
                              {group.heading}
                              {group.headingIcon}
                            </DropdownGroupHeading>
                          </TestWrapper>
                        )}
                        {group.items.map((item, idx) => {
                          const withBorder =
                            idx === group.items.length - 1 &&
                            groupIdx < props.config.length - 1;

                          const linkFacadeProps = {
                            to: item.skipHrefPrefix
                              ? `${item.href}`
                              : `${linkPrefix}${item.href}`,
                            useNativeLinks:
                              item.skipHrefPrefix || props.useNativeLinks,
                            ...((item.action || item.telemetryEventId) && {
                              onClick: (
                                e: React.MouseEvent<
                                  HTMLAnchorElement,
                                  MouseEvent
                                >,
                              ) => {
                                item.action?.();

                                if (item.telemetryEventId) {
                                  props.handleTelemetry?.(
                                    e,
                                    item.label,
                                    item.telemetryEventId,
                                    !item.action,
                                  );
                                }
                              },
                            }),
                          };

                          if (item.isHidden) {
                            return null;
                          }

                          return (
                            <TestWrapper
                              testId={item.testId || ""}
                              key={`nav-item-${idx}`}
                            >
                              <DesktopDropdownItem
                                withIcon={Boolean(item.icon)}
                                as="div"
                                withBorder={withBorder}
                              >
                                <DesktopLink
                                  {...linkFacadeProps}
                                  withIcon={Boolean(item.icon)}
                                >
                                  {item.label}
                                  {item.icon}
                                </DesktopLink>
                              </DesktopDropdownItem>
                            </TestWrapper>
                          );
                        })}
                      </React.Fragment>
                    );
                  })}
                </Dropdown>
              </div>
            </TestWrapper>
          </ListItemDesktopOnly>
        </Margins>
      )}

      {!props.isUserLoggedIn && !props.skipLoginButton && (
        <NavLinkDesktopOnly isDark={props.isDark}>
          <TestWrapper testId={NAVBAR.LOGIN_BUTTON}>
            <a
              role="button"
              href="#"
              onClick={async e => {
                e.preventDefault();

                await props.handleTelemetry?.(
                  e,
                  props.loginLabel,
                  "login-header",
                  false,
                );
                props.loginUser();
              }}
            >
              {props.loginLabel}
            </a>
          </TestWrapper>
        </NavLinkDesktopOnly>
      )}
      {/** Desktop Navigation --- end */}
    </React.Fragment>
  );
};

export { UserDropdown };

UserDropdown.displayName = "UserDropdown";

const StyledHeading = styled(Heading)<{ isDark?: boolean }>`
  color: ${props =>
    props.isDark ? props.theme.colors.white : props.theme.colors.black700};
  padding-left: ${props => props.theme.margins.base};
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

const linkStyles = css`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const StyledA = styled.a`
  ${linkStyles}
`;

const NavLinkDesktopOnly = styled(NavLink)`
  display: none;

  ${props => props.theme.breakpoints.desktop} {
    display: flex;
  }
`;

const ListItemDesktopOnly = styled.li`
  display: none;

  ${props => props.theme.breakpoints.desktop} {
    display: flex;
  }
`;

const TotalCounterStyled = styled(TotalCounter)`
  font-weight: ${props => props.theme.fonts.weights.bold};
  transform: scale(0.8);
`;

const DesktopDropdownItem = styled(DropdownItem)`
  padding: 0;
  max-height: none;
`;

const DesktopLink = styled(LinkFacade)<{ withIcon: boolean }>`
  padding: ${props =>
    `${props.theme.margins.base} ${props.theme.margins.base_x2}`};

  ${props =>
    props.withIcon &&
    css`
      padding: ${props => props.theme.margins.base_x2};
    `}
`;
