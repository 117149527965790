import * as React from "react";

import { IS_BROWSER, POSSIBLE_LANGUAGE_OPTIONS } from "@config/consts";
import i18next from "@config/i18next";

import { AVAILABLE_LANGS } from "@typings/globals";

import { langFromPathname } from "@services/LangFromPathname";
import { resolveCountryFromUrl } from "@services/ResolveCountryFromUrl";

import { useLocalisation } from "./useLocalisation";

interface Props {
  children: React.ReactNode;
  skipSetCountryOnMount?: boolean;
  onLanguageChange?: (lang: AVAILABLE_LANGS) => void;
}

const detectLanguage = (): AVAILABLE_LANGS => {
  if (!IS_BROWSER) {
    return AVAILABLE_LANGS.EN;
  }

  const languageString: AVAILABLE_LANGS | "" = langFromPathname();

  if (POSSIBLE_LANGUAGE_OPTIONS.includes(languageString)) {
    return languageString;
  }

  return AVAILABLE_LANGS.EN;
};

/**
 * @param skipSetCountryOnMount
 * Temporary solution until we refactor every app to use this Provider instead of their local `localisation.reducer`.
 * Some of our apps set country in their `Header.tsx` & when mixed with `setCountry` from this Provider can create unpredictable app state.
 */
const LocalisationProvider = (props: Props): React.ReactElement => {
  const { setLanguage, setCountry } = useLocalisation();

  React.useEffect(() => {
    const detectedLanguage = detectLanguage();
    setLanguage(detectedLanguage);
    i18next.changeLanguage(detectedLanguage);

    !props.skipSetCountryOnMount && setCountry(resolveCountryFromUrl());
    props.onLanguageChange?.(detectedLanguage);
  }, []);

  return <React.Fragment>{props.children}</React.Fragment>;
};

export { LocalisationProvider };
