/**
 * @author tomekbuszewski
 * @since 2019-3-6
 */

import * as React from "react";
import styled from "styled-components";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { BatmaidLogoCH } from "./BatmaidLogoCH";
import { BatmaidLogoFR } from "./BatmaidLogoFR";
import { BatmaidLogoDE } from "./BatmaidLogoDE";
import { BatmaidLogoIT } from "./BatmaidLogoIT";
import { BatmaidLogoNL } from "./BatmaidLogoNL";
import { BatmaidLogoBE } from "./BatmaidLogoBE";
import { BatmaidLogoCHChristmas } from "./BatmaidLogoCHChristmas";
import { BatmaidLogoFRChristmas } from "./BatmaidLogoFRChristmas";
import { BatmaidLogoDEChristmas } from "./BatmaidLogoDEChristmas";
import { BatmaidLogoITChristmas } from "./BatmaidLogoITChristmas";
import { BatmaidLogoNLChristmas } from "./BatmaidLogoNLChristmas";
import { BatmaidLogoBEChristmas } from "./BatmaidLogoBEChristmas";
import { BatsoftLogo } from "./BatsoftLogo";
import { BatworkLogo } from "./BatworkLogo";
import { TwintLogo } from "./TwintLogo";

interface Props {
  className?: string;
  width?: number;
  height?: number;
  white?: boolean;
  isChristmas?: boolean;
  country?: AVAILABLE_COUNTRIES;
}

const getLogoPerOrigin = (origin: ORIGIN, country: AVAILABLE_COUNTRIES) => {
  switch (origin) {
    case ORIGIN.BATMAID:
      return <StyledBatmaidLogo white country={country} />;
    case ORIGIN.BATSOFT:
      return <StyledBatsoftLogo />;
    case ORIGIN.BATWORK:
      return <StyledBatworkLogo />;
    default:
      return <StyledBatmaidLogo white />;
  }
};

const BatmaidLogo = (props: Props) => {
  const { isChristmas, country, white, ...iconProps } = props;

  switch (true) {
    case country === AVAILABLE_COUNTRIES.DE && isChristmas:
      return <BatmaidLogoDEChristmas {...iconProps} />;
    case country === AVAILABLE_COUNTRIES.DE:
      return <BatmaidLogoDE {...iconProps} white={white} />;

    case country === AVAILABLE_COUNTRIES.FR && isChristmas:
      return <BatmaidLogoFRChristmas {...iconProps} />;
    case country === AVAILABLE_COUNTRIES.FR:
      return <BatmaidLogoFR {...iconProps} white={white} />;

    case country === AVAILABLE_COUNTRIES.IT && isChristmas:
      return <BatmaidLogoITChristmas {...iconProps} />;
    case country === AVAILABLE_COUNTRIES.IT:
      return <BatmaidLogoIT {...iconProps} white={white} />;

    // uncomment if custom logo is needed
    // case (country === AVAILABLE_COUNTRIES.AT && isChristmas):
    //   return <BatmaidLogoATChristmas {...iconProps} />;
    // case country === AVAILABLE_COUNTRIES.AT:
    //   return <BatmaidLogoAT {...iconProps} />;

    case country === AVAILABLE_COUNTRIES.BE && isChristmas:
      return <BatmaidLogoBEChristmas {...iconProps} />;
    case country === AVAILABLE_COUNTRIES.BE:
      return <BatmaidLogoBE {...iconProps} />;

    case country === AVAILABLE_COUNTRIES.NL && isChristmas:
      return <BatmaidLogoNLChristmas {...iconProps} />;
    case country === AVAILABLE_COUNTRIES.NL:
      return <BatmaidLogoNL {...iconProps} />;

    // uncomment if custom logo is needed
    // case (country === AVAILABLE_COUNTRIES.UK && isChristmas):
    //   return <BatmaidLogoUKChristmas {...iconProps} />;
    // case country === AVAILABLE_COUNTRIES.UK:
    //   return <BatmaidLogoUK {...iconProps} />;

    // uncomment if custom logo is needed
    // case (country === AVAILABLE_COUNTRIES.US && isChristmas):
    //   return <BatmaidLogoUSChristmas {...iconProps} />;
    // case country === AVAILABLE_COUNTRIES.US:
    //   return <BatmaidLogoUS {...iconProps} />;

    case isChristmas:
      return <BatmaidLogoCHChristmas {...iconProps} />;
    default:
      return <BatmaidLogoCH {...iconProps} white={white} />;
  }
};

const StyledBatmaidLogo = styled(BatmaidLogo)`
  display: block;
  pointer-events: none;
`;

const StyledBatsoftLogo = styled(BatsoftLogo)`
  display: block;
`;

const StyledBatworkLogo = styled(BatworkLogo)`
  display: block;
`;

export {
  StyledBatmaidLogo as BatmaidLogo,
  StyledBatsoftLogo as BatsoftLogo,
  StyledBatworkLogo as BatworkLogo,
  TwintLogo,
  Props,
  getLogoPerOrigin,
};
