import React from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";

import { BoxesProps, BoxesSection, DefaultCard } from "./BoxesSection";

interface WhatDoWeOfferCard {
  what_do_we_offer_card_image: {
    gatsbyImageData: IGatsbyImageData;
  };
  what_do_we_offer_card_image_mobile: {
    gatsbyImageData: IGatsbyImageData;
  };
  what_do_we_offer_card_title: { text: string };
  what_do_we_offer_card_list_element__1: { text: string };
  what_do_we_offer_card_list_element__2: { text: string };
  what_do_we_offer_card_list_element__3: { text: string };
  what_do_we_offer_card_list_element__4: { text: string };
  what_do_we_offer_card_list_element__5: { text: string };
}

export interface OfferProps {
  what_do_we_offer_title: { text: string };
  what_do_we_offer_cards: WhatDoWeOfferCard[];
  what_do_we_offer_button_label?: {
    text: string;
  };
  what_do_we_offer_button_link?: {
    url: string | null;
    slug: string | null;
    relative: string | null;
  };
}

interface ComponentOfferProps extends OfferProps {
  language?: string;
}

const getTrimmedListOfBenefits = (card: WhatDoWeOfferCard): string[] =>
  [
    card.what_do_we_offer_card_list_element__1.text,
    card.what_do_we_offer_card_list_element__2.text,
    card.what_do_we_offer_card_list_element__3.text,
    card.what_do_we_offer_card_list_element__4.text,
    card.what_do_we_offer_card_list_element__5.text,
  ].filter(benefit => benefit);

const Offer = (props: ComponentOfferProps): React.ReactElement => {
  const isSectionLinkEnabled =
    !!(
      props.what_do_we_offer_button_link?.slug ||
      props.what_do_we_offer_button_link?.url ||
      props.what_do_we_offer_button_link?.relative
    ) && props.what_do_we_offer_button_label?.text;

  //@TODO: refactor prismic custom type to be the same
  const boxesData: BoxesProps<DefaultCard> = {
    language: props.language,
    section_title: props.what_do_we_offer_title,
    items: props.what_do_we_offer_cards.map(card => ({
      box_image: card.what_do_we_offer_card_image.gatsbyImageData,
      box_image_mobile: card.what_do_we_offer_card_image_mobile.gatsbyImageData,
      box_image_text: card.what_do_we_offer_card_title,
      list: getTrimmedListOfBenefits(card),
    })),
    ...(isSectionLinkEnabled && {
      section_link: {
        url: props.what_do_we_offer_button_link!.url,
        slug: props.what_do_we_offer_button_link!.slug,
        relative: props.what_do_we_offer_button_link!.relative,
        label: props.what_do_we_offer_button_label!.text,
      },
    }),
  };

  return <BoxesSection {...boxesData} isWhite />;
};

export { Offer };
