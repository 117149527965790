import * as React from "react";
import { useTheme } from "styled-components";

import { style, Icon } from "./Symbolicons";

export const Search = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  return (
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.222 14.222a8 8 0 1 0-3.007 6.251l5.045 5.044.084.074a.89.89 0 0 0 1.173-1.33l-5.044-5.046a7.966 7.966 0 0 0 1.75-4.993ZM8 14.222a6.222 6.222 0 1 1 12.444 0 6.222 6.222 0 0 1-12.444 0Z"
        fill={props.color || theme.colors.grey700}
      />
    </svg>
  );
};
