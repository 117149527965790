import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const WashingMachineAppliance = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 21H18.779C19.884 21 20.779 20.105 20.779 19V5C20.779 3.895 19.884 3 18.779 3H5C3.895 3 3 3.895 3 5V19C3 20.105 3.895 21 5 21Z"
      />
      <path d="M20.78 8H3" />
      <path d="M8.536 16.5V16.5C8.035 15.632 8.29 14.525 9.12 13.964L9.121 13.963C9.787 13.512 10.664 13.527 11.314 14L12.686 14.999C13.336 15.472 14.213 15.487 14.879 15.036L14.88 15.035C15.71 14.474 15.964 13.367 15.464 12.499V12.499" />
      <path d="M14.8284 11.6716C16.3905 13.2337 16.3905 15.7664 14.8284 17.3284C13.2663 18.8905 10.7336 18.8905 9.17154 17.3284C7.60944 15.7663 7.60944 13.2337 9.17154 11.6716C10.7336 10.1095 13.2663 10.1095 14.8284 11.6716" />
      <path d="M16 5.5H18" />
    </svg>
  );
};
