import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";

const getEmployeeContactDetailsFunction = (store: IStore) =>
  store.declareEmployee.get("employeeContactDetails");

export const getEmployeeContactDetails = createSelector(
  getEmployeeContactDetailsFunction,
  f => f,
);

const getEmployeePersonalInformationFunction = (store: IStore) =>
  store.declareEmployee.get("employeePersonalInformation");

export const getEmployeePersonalInformation = createSelector(
  getEmployeePersonalInformationFunction,
  f => f,
);

const getEmployeeBankAccountFunction = (store: IStore) =>
  store.declareEmployee.get("employeeBankAccount");

export const getEmployeeBankAccount = createSelector(
  getEmployeeBankAccountFunction,
  f => f,
);

const getEmergencyContactFunction = (store: IStore) =>
  store.declareEmployee.get("emergencyContact");

export const getEmergencyContact = createSelector(
  getEmergencyContactFunction,
  f => f,
);

const getEmployeeJobAddressFunction = (store: IStore) =>
  store.declareEmployee.get("employeeJobAddress");

export const getEmployeeJobAddress = createSelector(
  getEmployeeJobAddressFunction,
  f => f,
);

const getEmployeeJobCityFunction = (store: IStore) =>
  store.declareEmployee.getIn(["employeeJobAddress", "city"]);

export const getEmployeeJobCity = createSelector(
  getEmployeeJobCityFunction,
  f => f,
);

const getPreviousAgentsFunction = (store: IStore) =>
  store.declareEmployee.get("previousAgents");

export const getPreviousAgents = createSelector(
  getPreviousAgentsFunction,
  f => f,
);

const getServiceLocationIdFunction = (store: IStore) =>
  store.declareEmployee.getIn(["employeeJobAddress", "serviceLocationId"]);

export const getServiceLocationId = createSelector(
  getServiceLocationIdFunction,
  f => f,
);

const getVoucherFunction = (store: IStore) =>
  store.declareEmployee.get("voucherToken");

export const getVoucher = createSelector(getVoucherFunction, f => f);

const getVoucherStatusFunction = (store: IStore) =>
  store.declareEmployee.get("voucherStatus");

export const getVoucherStatus = createSelector(
  getVoucherStatusFunction,
  f => f,
);

const getVoucherMessageFunction = (store: IStore) =>
  store.declareEmployee.get("voucherMessage");

export const getVoucherMessage = createSelector(
  getVoucherMessageFunction,
  f => f,
);
const getContractTermFunction = (store: IStore) =>
  store.declareEmployee.get("contractTerm");

export const getContractTerm = createSelector(getContractTermFunction, f => f);

const getJobFrequencyFunction = (store: IStore) =>
  store.declareEmployee.get("jobFrequency");

export const getJobFrequency = createSelector(getJobFrequencyFunction, f => f);

const getJobDurationFunction = (store: IStore) =>
  store.declareEmployee.get("jobDuration");

export const getJobDuration = createSelector(getJobDurationFunction, f => f);

const getAgentUuidFn = (store: IStore) =>
  store.declareEmployee.get("agentUuid");

export const getAgentUuid = createSelector(getAgentUuidFn, f => f);

const getAgentDataFn = (store: IStore) =>
  store.declareEmployee.get("agentData");

export const getAgentData = createSelector(getAgentDataFn, f => f);

const getEmployeeChildrenNumberFunction = (store: IStore) =>
  store.declareEmployee.get("employeeChildrenNumber");

export const getEmployeeChildrenNumber = createSelector(
  getEmployeeChildrenNumberFunction,
  f => f,
);

const getEmployeeHasChildrenFunction = (store: IStore) =>
  store.declareEmployee.get("employeeHasChildren");

export const getEmployeeHasChildren = createSelector(
  getEmployeeHasChildrenFunction,
  f => f,
);

const getServiceFunction = (store: IStore) =>
  store.declareEmployee.get("service");

export const getService = createSelector(getServiceFunction, f => f);

const getVacationsFunction = (store: IStore) =>
  store.declareEmployee.get("vacations");

export const getVacations = createSelector(getVacationsFunction, f => f);

const getPaymentAliasIdFunction = (store: IStore) =>
  store.declareEmployee.get("paymentAliasId");

export const getPaymentAliasId = createSelector(
  getPaymentAliasIdFunction,
  f => f,
);

const getDeclarationUuidFunction = (store: IStore) =>
  store.declareEmployee.get("declarationUuid");

export const getDeclarationUuid = createSelector(
  getDeclarationUuidFunction,
  f => f,
);

const getIsSalaryChosenFunction = (store: IStore) =>
  store.declareEmployee.get("isSalaryChosen");

export const getIsSalaryChosen = createSelector(
  getIsSalaryChosenFunction,
  f => f,
);

const getIsLegalAddressTheSameFunction = (store: IStore) => {
  const employeeJobAddress = store.declareEmployee.get("employeeJobAddress");
  const employeeContactDetails = store.declareEmployee.get(
    "employeeContactDetails",
  );

  return (
    employeeJobAddress.get("address") ===
      employeeContactDetails.get("streetAddress") &&
    employeeJobAddress.get("zip") === employeeContactDetails.get("zipCode") &&
    employeeJobAddress.get("city") === employeeContactDetails.get("city")
  );
};
export const getIsLegalAddressTheSame = createSelector(
  getIsLegalAddressTheSameFunction,
  f => f,
);
