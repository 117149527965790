import * as React from "react";
import { StaticQuery, graphql, navigate } from "gatsby";

import { AVAILABLE_LANGS, AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";
import { Contact, FooterGraphQlData, PrismicFooter } from "@typings/footer";

import { Footer as FooterView } from "@ui/Organisms/Footer";
import { getLogoPerOrigin } from "@ui/Assets";

import { langInPrismicFormat } from "@services/LangInPrismicFormat";

import { useTelemetry } from "@hooks/useTelemetry";
interface AllPrismicFooterStaticQuery {
  allPrismicFooter: {
    nodes: PrismicFooter[];
  };
}

interface Props {
  currentLanguage: AVAILABLE_LANGS;
  currentFlag: AVAILABLE_COUNTRIES;
  origin?: ORIGIN;
  isStandalone?: boolean;
  isTeaserVersion?: boolean;
}

interface FilterDataInput {
  data: PrismicFooter[];
  language: AVAILABLE_LANGS;
  country: AVAILABLE_COUNTRIES;
  origin?: ORIGIN;
}

const filterData = ({
  data,
  language,
  country,
  origin,
}: FilterDataInput): PrismicFooter => {
  const lang = langInPrismicFormat(language);
  const resolvedOrigin = origin ? origin : ORIGIN.BATMAID;

  const [filtered, ...rest] = data.filter((node: any) => {
    return (
      node.lang === lang &&
      node.data.country === country &&
      node.data.origin === resolvedOrigin
    );
  });

  // temporary hack
  // fallback to CH footer if data for specific country is missing
  // as a last resort it will take the first one (might be random)
  if (!filtered) {
    return (
      data.find(
        footer =>
          footer?.lang === lang &&
          footer?.data?.country === AVAILABLE_COUNTRIES.CH &&
          footer?.data?.origin === ORIGIN.BATMAID,
      ) || data[0]
    );
  }

  return filtered;
};

const FooterStatic = (props: Props): React.ReactElement => {
  const { elementClick } = useTelemetry();

  return (
    <StaticQuery
      query={graphql`
        query FooterStatic {
          allPrismicFooter {
            nodes {
              data {
                origin
                country
                app_coming_soon_text {
                  text
                }
                app_download_header {
                  text
                }
                contact_media {
                  text
                }
                coming_soon {
                  text
                }
                contact_us {
                  text
                }
                email {
                  text
                }
                follow_us_header {
                  text
                }
                hashtag_links {
                  text
                }
                links {
                  text
                }
                locations_header {
                  text
                }
                locations_left_column {
                  text
                }
                locations_right_column {
                  text
                }
                navigation_header {
                  text
                }
                offices_header {
                  text
                }
                opening_hours {
                  text
                }
                payment_options {
                  text
                }
                review_us_header {
                  text
                }
                telephone {
                  text
                }
                terms_of_use_text {
                  text
                }
                we_operate_in {
                  text
                }
                android_app_url {
                  url
                  target
                }
                ios_app_url {
                  url
                  target
                }
                trustpilot_url {
                  url
                }
                google_review_url {
                  url
                }
              }
              lang
            }
          }
        }
      `}
      render={({ allPrismicFooter }: AllPrismicFooterStaticQuery) => {
        const {
          data: {
            origin,
            country,
            app_coming_soon_text,
            app_download_header,
            contact_media,
            coming_soon,
            contact_us,
            email,
            follow_us_header,
            hashtag_links,
            links,
            locations_header,
            locations_left_column,
            locations_right_column,
            navigation_header,
            offices_header,
            opening_hours,
            payment_options,
            review_us_header,
            telephone,
            terms_of_use_text,
            we_operate_in,
            android_app_url,
            ios_app_url,
            trustpilot_url,
            google_review_url,
          },
        } = filterData({
          data: allPrismicFooter.nodes,
          language: props.currentLanguage,
          country: props.currentFlag,
          origin: props.origin,
        });

        const graphqlData: FooterGraphQlData = {
          navigationHeader: navigation_header.text,
          officesHeader: offices_header.text,
          locationsHeader: locations_header.text,
          appDownloadHeader: app_download_header.text,
          reviewUsHeader: review_us_header.text,
          followUsHeader: follow_us_header.text,
          termsOfUseText: terms_of_use_text.text,
          appComingSoonText: app_coming_soon_text.text,
          cities: we_operate_in.text,
          links: links.text,
          locationsLeftColumn: locations_left_column.text,
          locationsRightColumn: locations_right_column
            ? locations_right_column.text
            : "",
          comingSoonBody: coming_soon.text,
          hashtagLinks: hashtag_links.text,
          contact: {
            email: email.text,
            openingHours: opening_hours.text,
            paymentOptions: payment_options.text,
            telephone: telephone.text,
            contactUs: contact_us.text,
            contactMedia: contact_media.text,
          },
          androidAppUrl: android_app_url?.url,
          iosAppUrl: ios_app_url?.url,
          trustpilotUrl: trustpilot_url?.url,
          googleReviewUrl: google_review_url?.url,
        };

        const footerViewProps = {
          variant: origin as ORIGIN,
          logo: getLogoPerOrigin(props.origin as ORIGIN, country),
          links: graphqlData.links,
          cities: graphqlData.cities,
          comingSoonBody: graphqlData.comingSoonBody,
          hashtagLinks: graphqlData.hashtagLinks || "",
          locationsLeftColumn: graphqlData.locationsLeftColumn || "",
          locationsRightColumn: graphqlData.locationsRightColumn || "",
          contact: graphqlData.contact as Contact,
          navigationHeader: graphqlData.navigationHeader,
          officesHeader: graphqlData.officesHeader,
          locationsHeader: graphqlData.locationsHeader,
          appDownloadHeader: graphqlData.appDownloadHeader,
          reviewUsHeader: graphqlData.reviewUsHeader,
          followUsHeader: graphqlData.followUsHeader,
          termsOfUseText: graphqlData.termsOfUseText,
          appComingSoonText: graphqlData.appComingSoonText,
          isStandalone: false,
          language: props.currentLanguage,
          country,
          androidAppUrl: graphqlData.androidAppUrl || "",
          iosAppUrl: graphqlData.iosAppUrl || "",
          isTeaserVersion: props.isTeaserVersion,
          trustpilotUrl: graphqlData.trustpilotUrl,
          googleReviewUrl: graphqlData.googleReviewUrl,
          onPhoneClick: () =>
            elementClick("give_call_footer", { buttonId: "give_call_footer" }),
          onEmailClick: () =>
            elementClick("send_email_footer", {
              buttonId: "send_email_footer",
            }),
        };

        return <FooterView {...footerViewProps} />;
      }}
    />
  );
};

export default FooterStatic;
