import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Info = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={props.className}
    >
      <defs>
        <path
          d="M11.9999999,3.99999995 C16.418278,3.99999995 19.9999999,7.58172197 19.9999999,12 C19.9999999,16.418278 16.418278,20 11.9999999,20 C7.58172197,20 3.99999995,16.418278 3.99999995,12 C3.99999995,7.58172197 7.58172197,3.99999995 11.9999999,3.99999995 Z M12,5.33333329 C8.31810165,5.33333329 5.33333329,8.31810165 5.33333329,12 C5.33333329,15.6818984 8.31810165,18.6666667 12,18.6666667 C15.6818984,18.6666667 18.6666667,15.6818984 18.6666667,12 C18.6666667,8.31810165 15.6818984,5.33333329 12,5.33333329 Z M12,10.6666667 C12.3418906,10.6666667 12.6236714,10.9240268 12.6621815,11.2555859 L12.6666667,11.3333333 L12.6666667,15.3333334 C12.6666667,15.7015232 12.3681898,16 12,16 C11.6581094,16 11.3763286,15.7426399 11.3378185,15.4110808 L11.3333333,15.3333334 L11.3333333,11.3333333 C11.3333333,10.9651435 11.6318102,10.6666667 12,10.6666667 Z M12,8.65999998 C12.3418906,8.65999998 12.6236714,8.91736011 12.6621815,9.24891923 L12.6666667,9.33333332 C12.6666667,9.70152315 12.3681898,9.99999999 12,9.99999999 C11.6581094,9.99999999 11.3763286,9.74263986 11.3378185,9.41108073 L11.3333333,9.32666665 C11.3333333,8.95847681 11.6318102,8.65999998 12,8.65999998 Z"
          id="path-1-info-line"
        ></path>
      </defs>
      <g
        id="icon-24x24px[16x16px]---line&amp;solid"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atom/icon/security/alert/line">
          <mask id="mask-2-info-line" fill="white">
            <use xlinkHref="#path-1-info-line"></use>
          </mask>
          <use
            id="Combined-Shape"
            fill={props.color || theme.colors.grey700}
            fillRule="nonzero"
            xlinkHref="#path-1-info-line"
          ></use>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <defs>
        <path
          id="info-solid-a"
          d="M12 4a8 8 0 100 16 8 8 0 000-16zm.667 12a.667.667 0 01-1.334 0v-4.667a.667.667 0 011.334 0V16zM12 9.333a1 1 0 110-2 1 1 0 010 2z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="info-solid-b" fill={props.color || theme.colors.grey700}>
          <use xlinkHref="#info-solid-a"></use>
        </mask>
        <use
          fill={props.color || theme.colors.grey700}
          fillRule="nonzero"
          xlinkHref="#info-solid-a"
        ></use>
      </g>
    </svg>
  );
