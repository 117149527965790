import React from "react";
import styled, { css } from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";
import { rem } from "@ui/helpers";

type TagSize = "s" | "m" | "l";
interface Props {
  children?: React.ReactNode;
  isActive?: boolean;
  className?: string;
  size?: "s" | "m" | "l";
  background?: string;
  color?: string;
  type?: "button" | "reset" | "submit";
  disabled?: boolean;
  variant?: "rounded" | "angular";
  onClick?: () => void;
}

const Tag = (props: Props): React.ReactElement => {
  return (
    <StyledTag
      type={props.type || "button"}
      isActive={props.isActive}
      className={props.className}
      onClick={props.onClick}
      size={props.size || "m"}
      background={props.background}
      color={props.color}
      disabled={props.disabled}
      variant={props.variant}
    >
      {props.children}
    </StyledTag>
  );
};

export { Tag };

const getPadding = (size: TagSize, theme: ITheme) => {
  switch (size) {
    case "s":
      return `${theme.margins.quarter} ${theme.margins.base_x1_5}`;
    case "m":
    default:
      return `${theme.margins.base} ${theme.margins.base_x2}`;
    case "l":
      return `${theme.margins.base_x1_5} ${theme.margins.base_x2}`;
  }
};

const getFontSize = (size: TagSize) => {
  switch (size) {
    case "s":
      return rem(14);
    case "m":
    default:
      return rem(16);
    case "l":
      return rem(16);
  }
};

const StyledTag = styled.button<{
  isActive?: boolean;
  size: TagSize;
  background?: string;
  color?: string;
  disabled?: boolean;
  variant?: "rounded" | "angular";
}>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: fit-content;
  padding: ${props => getPadding(props.size, props.theme)};
  border: ${rem(1)} solid var(--border-color);
  border-radius: ${props => (props.variant === "angular" ? rem(4) : rem(40))};
  font-size: ${props => getFontSize(props.size)};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  box-shadow: inset 0 0 0 var(--spread-width) var(--border-color);
  color: ${props => props.color || props.theme.colors.black700};
  --border-color: ${props => props.background || props.theme.colors.greyDark};
  --spread-width: 0;

  ${props =>
    props.background &&
    css`
      background: ${props.background};
    `}

  ${({ isActive }) =>
    isActive &&
    css`
      --border-color: ${props => props.theme.colors.blue400};
      --spread-width: 1px;
    `}

  &:hover:not(:disabled) {
    --border-color: ${props => props.theme.colors.blue100};
    --spread-width: 1px;

    ${({ isActive }) =>
      isActive &&
      css`
        --border-color: ${props => props.theme.colors.blue300};
      `}
  }
`;
