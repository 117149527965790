import * as React from "react";

/*
  Use the hook for enforcing rerender for static sites.
*/
const useBrowserRerender = () => {
  const [isBrowser, setIsBrowser] = React.useState(false);

  React.useEffect(() => {
    setIsBrowser(true);
  }, []);

  return isBrowser;
};

export { useBrowserRerender };
