/**
 * @author jakubmaslon
 * @since 2020-08-06
 */

import * as React from "react";
import styled from "styled-components";

import { AVAILABLE_LANGS } from "@typings/globals";

import { formatDateToHumanReadable } from "@services/FormatDateToHumanReadable";
import { stringifyFalsies } from "@services/StringifyFalsies";

interface Props {
  dateString: string | null;
  language: AVAILABLE_LANGS;
  withTime?: boolean;
}

/**
 * @deprecated use intlFormatDate or <DateDisplay /> instead
 */
const HumanFormatDate = (props: Props): React.ReactElement => {
  const { dayInWeek, monthAndDay, time } = formatDateToHumanReadable(
    stringifyFalsies(props.dateString),
    props.language,
  );

  return (
    <HumanFormatDateWrapper>
      <DayInWeek data-hj-allow>{dayInWeek}</DayInWeek>,{" "}
      <MonthAndDay data-hj-allow>{monthAndDay}</MonthAndDay>
      {props.withTime && <span data-hj-allow>{` - ${time}`}</span>}
    </HumanFormatDateWrapper>
  );
};

export { HumanFormatDate };

const HumanFormatDateWrapper = styled.div`
  display: inline;
`;

const DayInWeek = styled.span`
  text-transform: uppercase;
`;

const MonthAndDay = styled.span`
  text-transform: capitalize;
`;
