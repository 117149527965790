import { HR_API_ENDPOINT } from "@config/consts";
import { AVAILABLE_COUNTRIES } from "@typings/globals";

import { getApiUrl } from "@services/ApiUrl/ApiUrl";
import { langFromPathname } from "@services/LangFromPathname";
import { defaultOptions as defaultFetchOptions } from "@services/FetchFacade";

import { IStore } from "@work/redux/reducers";

import {
  getForm,
  getAccessToken,
  getPersonalInfo,
  getExperienceInfo,
} from "./apply.selectors";

const asyncActionConfig = {
  apiEndpoint: getApiUrl("hr", "v1", HR_API_ENDPOINT),
  headers: {
    "Accept-Language": langFromPathname(),
  },
};

export const getAsyncActionHRConfig = () => asyncActionConfig;

export const getAsyncActionHRConfigSecure = (
  store: IStore,
  opts?: { contentType: string },
) => {
  const accessToken = getAccessToken(store);

  return {
    ...asyncActionConfig,
    headers: {
      ...asyncActionConfig.headers,
      Authorization: `${defaultFetchOptions.headers.Authorization}, Bearer ${accessToken}`,
      ...(opts && opts.contentType && { "Content-Type": opts.contentType }),
    },
  };
};

export const getSubmitEmailEndpointRequest = (store: IStore) => ({
  email: getForm(store).get("email"),
});

export const getSubmitPersonalInfoEndpointRequest = (store: IStore) => ({
  ...getPersonalInfo(store),
});

export const getSubmitExperienceInfoEndpointRequest = (store: IStore) => {
  const { files, workPermitType, ...rest } = getExperienceInfo(store);

  const countryFlag = store.localisation.get("country");

  return {
    ...rest,
    ...(countryFlag === AVAILABLE_COUNTRIES.CH && { workPermitType }),
  };
};
