import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CleaningSprayAction = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49687 1.99583H12.6128C12.9344 1.99583 13.2364 2.15042 13.4244 2.4113C13.6124 2.67217 13.6636 3.00754 13.5619 3.31261L13.0795 4.75982C13.0297 4.90918 12.9125 5.02639 12.7631 5.07618L10.6832 5.76947C10.2747 5.90564 9.99917 6.28795 9.99917 6.71856V8.58434C9.99917 8.84966 10.1046 9.10412 10.2922 9.29173L13.8292 12.8287C14.5797 13.5792 15.0012 14.5971 15.0012 15.6584V20.0033C15.0012 21.1084 14.1054 22.0042 13.0004 22.0042H5.9975C4.89247 22.0042 3.99667 21.1084 3.99667 20.0033V12.6058C3.99667 12.2108 4.11359 11.8246 4.33271 11.4959L5.66146 9.50281C5.88057 9.17414 5.9975 8.78796 5.9975 8.39295V6.8263C5.9975 6.29564 5.7867 5.78672 5.41147 5.41149L4.14318 4.14318C4.04937 4.04937 3.99667 3.92214 3.99667 3.78947V2.49604C3.99667 2.21979 4.22062 1.99583 4.49687 1.99583Z"
      />
      <path d="M11.7142 5.42579L13.0004 7.99833" />
      <path d="M17.6023 2.19592L17.0021 2.79617" />
      <path d="M17.0021 5.19717L17.6023 5.79742" />
      <path d="M19.4031 3.99667H20.0033" />
      <path d="M9.99917 8.99875H5.99751" />
    </svg>
  );
};
