export type directions = "column" | "row" | undefined;

export type shapes = "square" | "row" | "rectVertical" | "circle" | undefined;

export const shapeHeights = {
  row: 48,
  square: 100,
  rectVertical: 200,
  circle: 110,
};
