import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Key = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.418 17.3729V15.9589H11.186L12.687 14.4579L14.759 15.3379C15.51 15.6569 16.379 15.4879 16.955 14.9109L19.898 11.9679C20.475 11.3909 20.643 10.5219 20.325 9.77193L18.823 6.23593C18.621 5.75893 18.241 5.37993 17.764 5.17693L14.228 3.67493C13.477 3.35593 12.609 3.52493 12.032 4.10193L9.089 7.04493C8.513 7.62093 8.344 8.48993 8.663 9.24093L9.52 11.2599L3.496 17.2839V20.4659H6.678L8.004 19.1399V17.3719H9.418V17.3729Z"
      />
      <path d="M14.376 8.8939C14.137 8.8949 13.943 9.0889 13.944 9.3279C13.944 9.5669 14.139 9.7609 14.378 9.7599C14.617 9.7599 14.811 9.5659 14.811 9.3269C14.811 9.0879 14.617 8.8939 14.378 8.8939" />
    </svg>
  );
};
