import React from "react";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

import * as Styled from "./SubpageLayout.styled";

interface Props {
  testId?: string;
  className?: string;
  heading?: React.ReactNode;
  description?: React.ReactNode;
}

const Heading = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <Styled.HeadingWrapper className={props.className}>
      {!!props.heading && <Styled.Heading>{props.heading}</Styled.Heading>}
      {!!props.description && (
        <Styled.Description>{props.description}</Styled.Description>
      )}
    </Styled.HeadingWrapper>
  </TestWrapper>
);

export { Heading };
