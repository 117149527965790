/**
 * @author tomekbuszewski
 * @since 2019-3-22
 */

import * as React from "react";
import styled, { keyframes } from "styled-components";

import { rem } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";

import { directions, shapeHeights, shapes } from "./Skeleton.helpers";

interface Props {
  children?: React.ReactNode;
  theme: ITheme;
  direction?: directions;
  shape?: shapes;
  noGutter?: boolean;
}

const slide = keyframes`
  0% {
    transform: translate(0);
    opacity: 0;
  }
  
  75% {
    transform: translateX(${rem(800)});
    opacity: 1;
  }
  
  100% {
    transform: translateX(${rem(1100)});
    opacity: 0;
  }
`;

const getHeightFromShape = (shape: shapes) => rem(shapeHeights[shape || "row"]);

const Skeleton = styled.div<Props>`
  background: ${props => props.theme.colors.blue100};
  border-radius: ${props =>
    props.shape !== "circle" ? props.theme.buttons.borderRadius : "50%"};
  min-height: ${props => getHeightFromShape(props.shape)};

  opacity: 0.25;

  position: relative;
  overflow: hidden;

  & {
    margin-bottom: ${props =>
      props.noGutter ? "0" : props.theme.margins.base_x2};
    margin-right: ${props =>
      props.direction === "row" ? props.theme.margins.base_x2 : 0};
  }

  &:after {
    animation: ${slide} 2s linear infinite;

    content: "";
    display: block;

    width: ${rem(240)};
    height: ${rem(280)};

    border-radius: ${rem(16)};
    filter: blur(${rem(40)});

    position: absolute;
    top: -50%;
    left: 0;

    background: ${props => props.theme.colors.blue600};
  }
`;

export { Skeleton };

Skeleton.displayName = "Skeleton";
