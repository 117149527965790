import { createSelector } from "reselect";

import { IStore } from "@work/redux/reducers";

const getServiceLocationInfoFunction = (store: IStore) => store.serviceLocation;

export const getServiceLocationInfo = createSelector(
  getServiceLocationInfoFunction,
  f => f,
);
