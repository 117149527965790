import { Record as ImmutableRecord, List } from "immutable";

import {
  AVAILABLE_CURRENCIES,
  PROMO_CODE_NOTIFICATION,
  PAYMENT_METHODS,
} from "@typings/globals";
import { PriceSubpart, PriceSubpartDTO } from "@typings/priceSubparts";
import { LOADING_STATE } from "@typings/reduxThunkActions";
import { CustomDay } from "@typings/booking";

export const LOCAL_STORAGE_KEY = "store:endOfTenancy";

export enum END_OF_TENANCY_EXTRA_SERVICES {
  END_OF_TENANCY_CLEANING = "END-OF-TENANCY-CLEANING",
  INSIDE_FRIDGE = "INSIDE-FRIDGE",
  INSIDE_OVEN = "INSIDE-OVEN",
  DOOR_HANDLES = "DOOR-HANDLES",
  BASEBOARDS = "BASEBOARDS",
  INTERIOR_WINDOWS = "INTERIOR-WINDOWS",
  INSIDE_CABINETS = "INSIDE-CABINETS",
  WINDOW_BLINDS = "WINDOW-BLINDS",
  BALCONY = "BALCONY",
}

export enum END_OF_TENANCY_EXTRA_SERVICES_LEGACY {
  INSIDE_OWEN = "INSIDE-OWEN", // remove this enum value when "Micheal Owen gate" gets fixed
}

export interface EndOfTenancyReducer {
  availablePaymentMethods: List<PAYMENT_METHODS>;
  calendar: ImmutableRecord<EndOfTenancyAvailability>;
  calendarV2: ImmutableRecord<EndOfTenancyAvailabilityV2>;
  availabilityLoadingState: LOADING_STATE;
  executionDate: string;
  form: ImmutableRecord<EndOfTenancyRegistrationForm>;
  locationUuid: string;
  mainLocationUuid: string;
  pricing: ImmutableRecord<EndOfTenancyPricing>;
  pricingLoadingState: LOADING_STATE;
  isPricingFetched: boolean;
  requestDifferentStartingHour: boolean;
  reservationUuid: string;
  selectedPaymentMethod: PAYMENT_METHODS | null;
  serviceLocationId: number;
  skipAgentAssignment: boolean;
  showOffer: boolean;
  specialInstructions: string | null;
  squareMeters: number | null;
  tasks: ImmutableRecord<EndOfTenancyTasks>;
  warranty: boolean;
  warrantyPriceAndCurrency: WarrantyPriceAndCurrency;
  voucherToken: string;
  voucherMessage: string;
  voucherStatus: PROMO_CODE_NOTIFICATION;
  queryParams: string;
}

export interface EndOfTenancyRegistrationForm {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  serviceLocationId: number;
  floorAndDoor: string;
  entryCode: string;
  marketingConsent: boolean;
}

export interface EndOfTenancyPricingDTO {
  oncePricePerHour: number;
  displayedPriceForFirstBooking: number;
  currency: AVAILABLE_CURRENCIES;
  hasSpecialPricing: boolean;
  specialPricingTranslatedMessage: string | null;
  recommendedBasisHours: number;
  recommendedExtraHours: number;
  discountsToDisplay: PriceSubpartDTO[];
}

export interface EndOfTenancyPricing {
  oncePricePerHour: number;
  displayedPriceForFirstBooking: number;
  currency: AVAILABLE_CURRENCIES;
  hasSpecialPricing: boolean;
  specialPricingTranslatedMessage: string | null;
  recommendedBasisHours: number;
  recommendedExtraHours: number;
  discountsToDisplay: List<PriceSubpart>;
}

export interface DayItemRaw {
  day: string;
}

export interface EndOfTenancyAvailabilityRaw {
  defaultSelection: string;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  days: DayItemRaw[];
}

export interface EndOfTenancyAvailability {
  defaultSelection: string;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  days: List<DayItemRaw>;
  staticDays: List<DayItemRaw>;
}
export interface EndOfTenancyAvailabilityV2 {
  defaultSelection: string;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  availableDays: List<string>;
  staticDays: Record<string, CustomDay> | null;
}

export type EndOfTenancyTasks = Record<
  END_OF_TENANCY_EXTRA_SERVICES,
  ImmutableRecord<Task>
>;

export interface Task {
  service: END_OF_TENANCY_EXTRA_SERVICES;
  adminOnly?: boolean;
  selected?: boolean;
}

export interface WarrantyPriceAndCurrencyDTO {
  warrantyPrice: number;
  currency: string;
}
export interface WarrantyPriceAndCurrency {
  warrantyPrice: number;
  currency: AVAILABLE_CURRENCIES;
}
