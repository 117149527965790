import React from "react";
import { FormikProps, FormikValues, Form } from "formik";
import styled, { css } from "styled-components";

import { HOMEPAGE } from "@config/testIds/app";

import { LOADING_STATE } from "@typings/reduxThunkActions";
import { SERVICE_TYPE } from "@typings/globals";
import { MODALS } from "@typings/modals";
import { SMARTLOOK_IDS } from "@typings/smartlookIds";

import { rem, transition } from "@ui/helpers";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { Button, Variant } from "@ui/Atoms/Button/Button";
import { SelectOption } from "@ui/Atoms/Form/SelectExtended";
import { FormikInputWithError } from "@ui/Molecules/FormikInputWithError";
import { FormikSelectExtended } from "@ui/Molecules/FormikSelectExtended";

import { useBrowserRerender } from "@hooks/useBrowserRerender";
import { useModalManager } from "@hooks/modal";
import { useTelemetry } from "@hooks/useTelemetry";
import { useTranslate } from "@hooks/useTranslate";

import { FIELDS, handleOnInputChange } from "./StartBookingForm.helpers";

const ZipCodeUnknownModal = React.lazy(
  () => import("@www/src/modals/ZipCodeUnknownModal"),
);

interface Props {
  className?: string;
  formikProps: FormikProps<FormikValues>;
  autoFocus?: boolean;
  isHorizontal?: boolean;
  serviceType: SERVICE_TYPE;
  emailSubmit: string;
  emailPlaceholder: string;
  buttonVariant?: Variant;
  zipSuggestions: SelectOption[];
  loadingSuggestionsState: LOADING_STATE;
  isZipCodeUnknownVisible?: boolean;
  setZipSuggestions: (value: SelectOption[]) => void;
  setLoadingSuggestionsState: (state: LOADING_STATE) => void;
}

const StartBookingFormView = (props: Props): React.ReactElement => {
  const { showModal, isModalActive } = useModalManager();
  const isBrowser = useBrowserRerender();
  const { elementClick } = useTelemetry();
  const translate = useTranslate("batmaid_pwa_generic");

  const [abortController, setAbortController] =
    React.useState<null | AbortController>(null);

  const fallbackFormProps = !isBrowser
    ? {
        action: "/en/submit-zip-code",
        method: "POST",
      }
    : {};

  const zipOnClick = (e: React.SyntheticEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    elementClick(e.currentTarget.text, {
      buttonId: "dont-know-zip-code",
    });
    showModal(MODALS.ZIP_CODE_UNKNOWN);
  };

  React.useEffect(() => {
    props.setZipSuggestions([]);
    props.formikProps.setFieldValue(FIELDS.ZIPCODE, "");
  }, [props.serviceType]);

  // BFCACHE handler
  React.useEffect(() => {
    const clearField = (event: PageTransitionEvent) => {
      if (!event.persisted) return;

      props.setZipSuggestions([]);
      props.formikProps.setFieldValue(FIELDS.ZIPCODE, "");
      props.formikProps.resetForm();
    };

    window.addEventListener("pageshow", clearField);

    return () => window.removeEventListener("pageshow", clearField);
  }, []);

  const handleSelectChange =
    (formikProps: FormikProps<FormikValues>) =>
    (input: { name: string; value: string }) => {
      formikProps.setFieldValue(input.name, input.value);
      formikProps.submitForm();
      return null;
    };

  return (
    <StyledForm className={props.className} {...fallbackFormProps}>
      <FormContent isHorizontal={props.isHorizontal}>
        <SelectWrapper>
          {isBrowser ? (
            <FormikSelectExtended
              testId={HOMEPAGE.ZIP_INPUT}
              autoFocus={props.autoFocus}
              placeholder={props.emailPlaceholder}
              hideDropdownIndicator
              options={props.zipSuggestions}
              name={FIELDS.ZIPCODE}
              value={props.formikProps.values[FIELDS.ZIPCODE] || null}
              clearInput={
                !props.formikProps.values[FIELDS.ZIPCODE] &&
                !props.zipSuggestions
              }
              onInputChange={(value: string) => {
                // abort previous API call
                if (value !== "" && abortController) {
                  abortController.abort();
                }

                handleOnInputChange({
                  value,
                  setFieldValue: props.formikProps.setFieldValue,
                  serviceType: props.serviceType,
                  setZipSuggestions: props.setZipSuggestions,
                  setZipSuggestionsFetchingState:
                    props.setLoadingSuggestionsState,
                  setLoadingAbortController: setAbortController,
                });
              }}
              onChange={handleSelectChange(props.formikProps)}
            />
          ) : (
            /**
             * fallback input when JS haven't loaded yet on the client side
             */
            <InputWrapper>
              <FormikInputWithError
                isLabelFloating={false}
                placeholder={props.emailPlaceholder}
                name={FIELDS.ZIPCODE}
                value={props.formikProps.values[FIELDS.ZIPCODE]}
                handleChange={props.formikProps.handleChange}
              />
              <HiddenInput
                name="serviceType"
                value={props.serviceType}
                type={"hidden"}
              />
            </InputWrapper>
          )}
        </SelectWrapper>

        <TestWrapper testId={HOMEPAGE.LETS_GO_BTN}>
          <StyledButton
            id={SMARTLOOK_IDS.START_BOOKING}
            type="submit"
            variant={props.buttonVariant}
            disabled={props.formikProps.isSubmitting}
            auto
            isHorizontal={props.isHorizontal}
            isLoading={props.loadingSuggestionsState === LOADING_STATE.LOADING}
          >
            {props.emailSubmit}
          </StyledButton>
        </TestWrapper>
      </FormContent>

      {props.isZipCodeUnknownVisible && (
        <ZipCodeLinkWrapper
          isErrorVisible={
            !!props.formikProps.errors[FIELDS.ZIPCODE] &&
            !!props.formikProps.touched[FIELDS.ZIPCODE]
          }
        >
          <ZipCodeUnknownButton
            type="button"
            size="fit"
            variant="textBlue"
            onClick={zipOnClick}
          >
            {translate("batmaid_pwa_generic.zip_code_unknown_button")}
          </ZipCodeUnknownButton>
        </ZipCodeLinkWrapper>
      )}

      {props.isZipCodeUnknownVisible &&
        isModalActive(MODALS.ZIP_CODE_UNKNOWN) && (
          <React.Suspense>
            <ZipCodeUnknownModal />
          </React.Suspense>
        )}
    </StyledForm>
  );
};

export { StartBookingFormView, SelectWrapper };

const StyledForm = styled(Form)`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const FormContent = styled.div<{ isHorizontal?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isHorizontal ? "row" : "column")};

  .select-extended {
    width: 100%;
    min-width: ${props => (props.isHorizontal ? rem(192) : rem(234))};
  }

  ${props => props.theme.breakpoints.tablet} {
    flex-direction: row;

    > button {
      margin-left: ${props => props.theme.margins.base_x3};
    }
  }
`;

const SelectWrapper = styled.div`
  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base_x2};
    width: ${rem(234)};

    .input-with-error {
      position: absolute;
      width: auto;
    }
  }
`;

const StyledButton = styled(Button)<{ isHorizontal?: boolean }>`
  max-height: ${rem(3.5)};
  margin: ${props =>
    props.isHorizontal
      ? `0 0 0 ${props.theme.margins.base}`
      : `${props.theme.margins.base} 0 0`};
  width: ${props =>
    props.isHorizontal ? props.theme.buttons.sizes.medium : "100%"};

  ${props => props.theme.breakpoints.tablet} {
    margin: ${props => `0 0 0 ${props.theme.margins.base_x3}`};
    width: ${props => props.theme.buttons.sizes.medium};
  }
`;

const InputWrapper = styled.div`
  min-width: ${rem(234)};

  input::placeholder {
    font-size: ${props => props.theme.fonts.sizes.body};
  }
`;

const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
`;

const ZipCodeLinkWrapper = styled.div<{ isErrorVisible: boolean }>`
  margin-top: ${props => props.theme.margins.base};
  text-align: center;

  ${props => props.theme.breakpoints.tablet} {
    text-align: left;
    transform: translateY(-${props => props.theme.margins.base_x2});
    transition: ${transition(["transform"])};

    ${props =>
      props.isErrorVisible &&
      css`
        transform: translateY(0);
      `}
  }
`;

const ZipCodeUnknownButton = styled(Button)`
  font-weight: ${props => props.theme.fonts.weights.normal};
`;
