import * as React from "react";
import { useTheme } from "styled-components";

import { Icon, style } from "./Symbolicons";

export const Plus = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  return props.variant === "line" ? (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <path
        d="M12 6c.342 0 .624.257.662.589l.005.078-.001 4.666h4.667a.667.667 0 01.078 1.33l-.078.004-4.667-.001v4.667a.667.667 0 01-.567.66L12 18a.667.667 0 01-.662-.589l-.005-.078v-4.667H6.667a.667.667 0 01-.078-1.328l.078-.005h4.666V6.667c0-.369.299-.667.667-.667z"
        fill={props.color || theme.colors.grey700}
        fillRule="nonzero"
      />
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <path
        d="M17.333 10.667h-4v-4a1.334 1.334 0 00-2.666 0v4h-4a1.334 1.334 0 000 2.666h4v4a1.334 1.334 0 002.666 0v-4h4a1.334 1.334 0 000-2.666z"
        fillRule="nonzero"
        fill={props.color || theme.colors.grey700}
      />
    </svg>
  );
};
