import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CalendarScheduleUser = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M7.498 2.996v3.002M16.502 2.996v3.002M11 21.004H5.998a3.001 3.001 0 0 1-3.002-3.001V7.498a3.001 3.001 0 0 1 3.002-3h12.005a3.001 3.001 0 0 1 3 3V9M11.5 16.002h-1" />
      <path d="M7.498 15.877a.125.125 0 1 0 0 .25.125.125 0 0 0 0-.25M13 12h-2.5M7.498 11.875a.125.125 0 1 0 0 .25.125.125 0 0 0 0-.25" />
      <path
        clipRule="evenodd"
        d="M15.002 20.504v-.406a2.1 2.1 0 0 1 2.094-2.095h2.814a2.1 2.1 0 0 1 2.094 2.095v.406a.5.5 0 0 1-.5.5h-6.002a.5.5 0 0 1-.5-.5Z"
      />
      <circle cx={18.503} cy={13.751} r={2.001} />
    </svg>
  );
};
