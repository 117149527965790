import { List } from "immutable";
import { createSelector } from "reselect";

import { IStore } from "@work/redux/reducers";

import { FileAPIAccepted } from "@typings/fileUploading";

import {
  ApplyFormPersonalInfo,
  ApplyFormExperienceInfo,
} from "./apply.typings";

const getFormFn = (store: IStore) => store.apply.get("form");
export const getForm = createSelector(getFormFn, f => f);

const getPersonalInfoFn = (store: IStore): ApplyFormPersonalInfo => {
  const {
    title,
    firstName,
    lastName,
    phoneNumberPrefix,
    phoneNumber,
    streetAddress,
    zipCode,
    city,
  } = store.apply.get("form").toJS();
  return {
    title,
    firstName,
    lastName,
    phoneNumberPrefix,
    phoneNumber,
    streetAddress,
    zipCode,
    city,
  };
};
export const getPersonalInfo = createSelector(getPersonalInfoFn, f => f);

const getExperienceInfoFn = (store: IStore): ApplyFormExperienceInfo => {
  const {
    workPermitType,
    hoursPerWeek,
    yearsOfExperience,
    sourcesOfExperience,
    methodOfCommuting,
    files,
  }: any = store.apply
    .get("form")
    .toSeq()
    .map(val => (val === null ? "" : val))
    .toJS();

  return {
    workPermitType,
    hoursPerWeek,
    yearsOfExperience,
    sourcesOfExperience,
    methodOfCommuting,
    files,
  };
};
export const getExperienceInfo = createSelector(getExperienceInfoFn, f => f);

const getFilesFn = (store: IStore): List<FileAPIAccepted> =>
  store.apply.get("form").get("files");

export const getFiles = createSelector(getFilesFn, f => f);

const getAccessTokenFn = (store: IStore) => store.apply.get("accessToken");
export const getAccessToken = createSelector(getAccessTokenFn, f => f);

const getIsFormDataPrefetchedFn = (store: IStore) =>
  store.apply.get("isFormDataPrefetched");
export const getIsFormDataPrefetched = createSelector(
  getIsFormDataPrefetchedFn,
  f => f,
);

const getTokenStatusFn = (store: IStore) => store.apply.get("isTokenInvalid");
export const getTokenStatus = createSelector(getTokenStatusFn, f => f);

const getLocalStorageStatusFn = (store: IStore) =>
  store.apply.get("isLocalStorageChecked");
export const getLocalStorageStatus = createSelector(
  getLocalStorageStatusFn,
  f => f,
);
