import * as React from "react";

import {
  CORONAVIRUS_PANIC_MODE_ENABLED,
  CORONAVIRUS_PANIC_MODE_LU_ENABLED,
  IS_BROWSER,
} from "@config/consts";
import { USER_NOTIFICATIONS_TEST_IDS } from "@config/testIds/userNotifications";
import { ROUTES } from "@config/app.routing";

import { MY_CLEANINGS_MODALS } from "@typings/clientDashboard";

import { Markdown } from "@containers/Markdown";
import { hideToast, showToast } from "@containers/Toastify";

import { useAuth } from "@hooks/useAuth";
import { useTranslate } from "@hooks/useTranslate";
import { useLocalisation } from "@hooks/useLocalisation";
import { useFeatureToggle } from "@hooks/useFeatureToggle";

import { useModalStore } from "@app/hooks/useModalStore";
import { useUserNotifications } from "@app/hooks/useUserNotifications";

const MY_CLEANINGS_ROUTE = `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_DASHBOARD_MY_CLEANINGS}`;

const UserNotificationHandler = (): null => {
  const translate = useTranslate();
  const { showModal } = useModalStore();
  const { country } = useLocalisation();

  const currentRoute = IS_BROWSER
    ? window.location.hash.split("#")[1]
    : undefined;

  const { isUserClient, isUserAgent } = useAuth();
  const { userNotifications, patchUserNotifications } = useUserNotifications();

  const isOverdueStatusAlertVisible = useFeatureToggle(
    "pwa_client_overdue_status_alert",
  );

  const hasPendingPayments = userNotifications.pendingPaymentsAlert;
  const hasNewBookingReminder = userNotifications.showNewBookingReminder;
  const hasEndOfTenancyReservationWithoutJobs =
    userNotifications.showEndOfTenancyReservationWithoutJobs;
  const hasPayedForB2BwithInvoice =
    userNotifications.showB2BSuccessMessageForInvoicePayment;
  const hasNewB2BBookingWithNonStandardFrequency =
    userNotifications.showB2BSuccessMessageForNonStandardFrequencyBooking;
  const hasNewBookingWithMoreOftenFrequency =
    userNotifications.showSuccessMessageForMoreOftenFrequencyBooking;

  const isMyCleaningsRoute = currentRoute?.split("?")[0] === MY_CLEANINGS_ROUTE;

  // Notifications
  const dispatchPendingPaymentsAlertNotification = (): void => {
    showToast(translate("batmaid_pwa_generic.pending_payments_alert"), {
      type: "dark",
      autoClose: false,
      toastId: USER_NOTIFICATIONS_TEST_IDS.PENDING_PAYMENTS_ALERT,
    });
  };

  const dispatchCoronavirusNotification = (): void => {
    showToast(
      <Markdown>
        {translate(
          `batmaid_pwa_generic.coronavirus_alert_${country.toLowerCase()}_md`,
        )}
      </Markdown>,
      {
        type: "dark",
        toastId: "COVID-19",
        autoClose: false,
      },
    );
  };

  const dispatchEndOfTenancyConfirmedNotification = (): void => {
    showToast(
      translate("batmaid_pwa_my_cleanings.end_of_tenancy_booking_confirmed"),
      {
        type: "dark",
        autoClose: false,
        toastId: USER_NOTIFICATIONS_TEST_IDS.END_OF_TENANCY_BOOKING_CONFIRMED,
      },
    );
  };

  const dispatchB2BInvoicePaymentToast = (): void => {
    showToast(
      translate("batmaid_pwa_my_cleanings.b2b_invoice_payment_message"),
      {
        type: "dark",
        autoClose: false,
        toastId: USER_NOTIFICATIONS_TEST_IDS.B2B_INVOICE_PAYMENT,
        onClose: () =>
          patchUserNotifications({
            showB2BSuccessMessageForInvoicePayment: false,
          }),
      },
    );
  };

  const dispatchNewB2BBookingWithNonStandardFrequency = (): void => {
    showToast(
      translate(
        "batmaid_pwa_my_cleanings.new_b2b_booking_with_non_standard_frequency_success_message",
      ),
      {
        type: "dark",
        autoClose: false,
        toastId:
          USER_NOTIFICATIONS_TEST_IDS.NEW_B2B_BOOKING_WITH_NON_STANDARD_FREQUENCY,
        onClose: () =>
          patchUserNotifications({
            showB2BSuccessMessageForNonStandardFrequencyBooking: false,
          }),
      },
    );
  };

  const dispatchNewBookingWithMoreOftenFrequency = () => {
    showToast(
      translate(
        "batmaid_pwa_my_cleanings.new_booking_with_more_often_frequency_message",
      ),
      {
        type: "dark",
        autoClose: false,
        toastId:
          USER_NOTIFICATIONS_TEST_IDS.NEW_BOOKING_WITH_MORE_OFTEN_FREQUENCY,
        onClose: () =>
          patchUserNotifications({
            showSuccessMessageForMoreOftenFrequencyBooking: false,
          }),
      },
    );
  };

  const hideCoronavirusNotification = () => {
    hideToast("COVID-19");
  };

  // Modals
  const dispatchNewBookingReferralModal = (): void => {
    showModal(MY_CLEANINGS_MODALS.THANK_YOU_FOR_BOOKING, {
      id: MY_CLEANINGS_MODALS.THANK_YOU_FOR_BOOKING,
    });
  };

  const handleNotifications = (): void => {
    if (!isMyCleaningsRoute) {
      return;
    }

    if (
      CORONAVIRUS_PANIC_MODE_ENABLED ||
      CORONAVIRUS_PANIC_MODE_LU_ENABLED(country)
    ) {
      dispatchCoronavirusNotification();
    } else {
      hideCoronavirusNotification();
    }

    if (hasPendingPayments && !isOverdueStatusAlertVisible) {
      dispatchPendingPaymentsAlertNotification();
    }

    if (hasNewBookingReminder) {
      dispatchNewBookingReferralModal();
    }

    if (hasEndOfTenancyReservationWithoutJobs) {
      dispatchEndOfTenancyConfirmedNotification();
    }

    if (hasPayedForB2BwithInvoice) {
      dispatchB2BInvoicePaymentToast();
    }

    if (hasNewB2BBookingWithNonStandardFrequency) {
      dispatchNewB2BBookingWithNonStandardFrequency();
    }

    if (hasNewBookingWithMoreOftenFrequency) {
      dispatchNewBookingWithMoreOftenFrequency();
    }
  };

  React.useEffect(() => {
    if (isUserClient && !isUserAgent) {
      handleNotifications();
    }
  }, [
    isUserClient,
    isUserAgent,
    country,
    window.location.hash,
    userNotifications,
  ]);

  return null;
};

export { UserNotificationHandler };
