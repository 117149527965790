import React from "react";
import styled, { css, useTheme } from "styled-components";

import { Check } from "@ui/Assets/Symbolicons/Check";
import { rem } from "@ui/helpers";
import { Close } from "@ui/Assets/Symbolicons";

interface Props {
  value: string;
  description?: string;
  isMarginless?: boolean;
  checkColor?: string;
  className?: string;
  checkSize?: number;
  isDisabled?: boolean;
}

const CheckListItem = (props: Props): React.ReactElement => {
  const theme = useTheme();

  return (
    <StyledListItem
      isMarginless={props.isMarginless}
      className={props.className}
    >
      <Title hasDescription={!!props.description}>
        {props.isDisabled && (
          <Close
            size={props.checkSize || theme.icons.sizes.base_x2}
            variant="line"
            color={theme.colors.grey500}
          />
        )}
        {!props.isDisabled && (
          <Check
            size={props.checkSize || theme.icons.sizes.base_x2}
            variant="line"
            color={props.checkColor || theme.colors.green300}
          />
        )}
        <ItemValue isDisabled={props.isDisabled}>{props.value}</ItemValue>
      </Title>
      {!!props.description && (
        <ListItemDescription isDisabled={props.isDisabled}>
          {props.description}
        </ListItemDescription>
      )}
    </StyledListItem>
  );
};

export { CheckListItem, Title, ListItemDescription };

const StyledListItem = styled.li<{ isMarginless?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  width: 100%;
  margin-bottom: ${props =>
    props.isMarginless
      ? "0"
      : props.theme.margins
          .base_x1_5} !important; //<Section> is overwriting margin-bottom
  padding-right: ${props => props.theme.margins.base};
  list-style: none;
  font-size: ${props => props.theme.fonts.sizes.body};

  svg {
    margin-right: ${props => props.theme.margins.base_x2};
    min-width: ${rem(14)};
    max-width: ${rem(14)};
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const Title = styled.span<{ hasDescription: boolean }>`
  display: flex;
  align-items: baseline;

  ${props =>
    props.hasDescription &&
    css`
      font-weight: ${props => props.theme.fonts.weights.semibold};
      font-size: ${props => props.theme.fonts.sizes.h6};
    `}
`;

const ItemValue = styled.span<{ isDisabled?: boolean }>`
  display: inline-block;

  ${props =>
    props.isDisabled &&
    css`
      color: ${props => props.theme.colors.grey500};
    `}
`;

const ListItemDescription = styled.span<{ isDisabled?: boolean }>`
  margin: ${props =>
    `${props.theme.margins.base_x2} 0 ${props.theme.margins.base_x3} 0`};
  padding-left: ${props => props.theme.margins.base_x4};
  color: ${props =>
    props.isDisabled ? props.theme.colors.grey500 : props.theme.colors.grey700};
  font-size: ${props => props.theme.fonts.sizes.body2};

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base_x6};
  }

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.fonts.sizes.body};
  }
`;
