/**
 * @author jakubmaslon
 * @since 2021-9-14
 */

import * as React from "react";
import styled from "styled-components";

import { VIEWPORTS } from "@ui/themes/ThemeInterface";

interface Props {
  children: React.ReactNode;
  viewports: VIEWPORTS[];
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
}

const VisibleOn = (props: Props): React.ReactElement => (
  <VisibleOnWrapper viewports={props.viewports} as={props.as}>
    {props.children}
  </VisibleOnWrapper>
);

VisibleOn.displayName = "VisibleOn";

export { VisibleOn };

interface VisibleOnWrapperProps {
  viewports: VIEWPORTS[];
}

const setDisplayValue =
  (viewport: VIEWPORTS) => (visibleOnWrapperProps: VisibleOnWrapperProps) =>
    visibleOnWrapperProps.viewports.includes(viewport) ? "block" : "none";

const VisibleOnWrapper = styled.div<VisibleOnWrapperProps>`
  display: ${setDisplayValue(VIEWPORTS.MOBILE)};

  ${props => props.theme.breakpoints.tablet} {
    display: ${setDisplayValue(VIEWPORTS.TABLET)};
  }

  ${props => props.theme.breakpoints.desktop} {
    display: ${setDisplayValue(VIEWPORTS.DESKTOP)};
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    display: ${setDisplayValue(VIEWPORTS.DESKTOP_LARGE)};
  }
`;
