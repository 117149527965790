import React from "react";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { Margins, Paragraph } from "@ui/Atoms";
import { Heading } from "@ui/Atoms/Headings";

import { langFromPathname } from "@services/LangFromPathname";
import { resolveCountryFromUrl } from "@services/ResolveCountryFromUrl";

import { useTranslate } from "@hooks/useTranslate";

import { ContactForm } from "@app/containers/ContactForm";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const NotFoundPage = (): React.ReactElement => {
  const translate = useTranslate("batmaid_pwa_generic");

  const country = process.env.COUNTRY || resolveCountryFromUrl();
  const language = langFromPathname();

  return (
    <Layout
      origin={process.env.ORIGIN as ORIGIN}
      country={country as AVAILABLE_COUNTRIES}
      language={language}
    >
      <SEO title={translate("unuspported_browser_title")} />
      <Margins xs={["base_x4", null, null, null]}>
        <Heading type="h1" styleAs="h4">
          {translate("unuspported_browser_title")}
        </Heading>
      </Margins>
      <Paragraph type="body">
        {translate("unsupported_browser_subtitle")}
      </Paragraph>
      <ContactForm />
    </Layout>
  );
};

export default NotFoundPage;
