import React from "react";
import { Formik, FormikProps, FormikValues, Form } from "formik";
import styled, { css } from "styled-components";

import { Heading } from "@ui/Atoms";
import { Button, Variant } from "@ui/Atoms/Button/Button";
import { FormikInputWithError } from "@ui/Molecules/FormikInputWithError";
import { rem } from "@ui/helpers";

import { showToast } from "@containers/Toastify";

import { useTranslate } from "@hooks/useTranslate";

import {
  FIELDS,
  getValidationSchema,
  initialValues,
  sendUncoveredLocation,
} from "./SubscribeEmail.helpers";

interface Props {
  emailSubmit: string;
  emailLabel?: string;
  emailPlaceholder: string;
  buttonVariant?: Variant;
  inputIcon?: React.ReactElement;
}

const SubscribeEmail = (props: Props): React.ReactElement => {
  const translate = useTranslate();

  return (
    <Formik
      onSubmit={async values => {
        const { onSuccess, onError } = await sendUncoveredLocation({
          email: values.email,
          zipCode: String(process.env.COUNTRY),
          serviceType: null,
        });

        onError(err => {
          if (err.message.includes("already_subscribed")) {
            return showToast(
              translate("batmaid_pwa_generic.email_already_subscribed"),
              {
                type: "dark",
                toastId: "emailSubscriptionErr",
              },
            );
          } else {
            showToast(translate("batmaid_pwa_generic.something_went_wrong"), {
              type: "error",
              toastId: "batmaid-generic-error",
            });
          }
        });

        onSuccess(() => {
          showToast(translate("batmaid_pwa_generic.thank_you"), {
            type: "success",
            toastId: "emailSubscriptionSuccsess",
          });
        });
      }}
      initialValues={initialValues}
      validateOnBlur
      validationSchema={getValidationSchema(translate)}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <StyledForm>
          {props.emailLabel && (
            <StyledHeading type="h6">{props.emailLabel}</StyledHeading>
          )}
          <FormContent>
            <InputWrapper hasError={!!formikProps.errors[FIELDS.EMAIL]}>
              <StyledInput
                label={props.emailPlaceholder}
                name={FIELDS.EMAIL}
                value={formikProps.values[FIELDS.EMAIL]}
                handleChange={formikProps.handleChange}
                isDisabled={formikProps.isSubmitting}
                icon={props.inputIcon}
              />
            </InputWrapper>

            <StyledButton
              variant={props.buttonVariant}
              disabled={formikProps.isSubmitting}
            >
              {props.emailSubmit}
            </StyledButton>
          </FormContent>
        </StyledForm>
      )}
    </Formik>
  );
};

export { SubscribeEmail };

const StyledForm = styled(Form)`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const StyledInput = styled(FormikInputWithError)`
  label {
    font-size: ${props => props.theme.fonts.sizes.body};
  }

  > div {
    width: 100%;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    width: 100%;
  }

  label,
  input {
    color: ${props => props.theme.colors.grey700};
  }

  input {
    font-size: ${props => props.theme.fonts.sizes.body};
  }

  ${props => props.theme.breakpoints.tablet} {
    flex-direction: row;

    > button {
      margin-left: ${props => props.theme.margins.base_x3};
    }
  }
`;

const StyledButton = styled(Button)`
  margin: ${props => `${props.theme.margins.base} 0 0`};
  width: 100%;
  max-height: ${rem(56)};

  ${props => props.theme.breakpoints.tablet} {
    margin: ${props => `0 0 0 ${props.theme.margins.base_x3}`};
    width: ${props => props.theme.buttons.sizes.medium};
  }
`;

const InputWrapper = styled.div<{ hasError: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${props =>
    props.hasError &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        > div:nth-child(2) {
          position: absolute;
          bottom: -${rem(24)};
        }
      }
    `}
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base};
  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props => props.theme.fonts.weights.normal};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.body};
    font-weight: ${props => props.theme.fonts.weights.bold};
  }
`;
