import { format, formatISO, parse } from "date-fns";

import { BATMAID_HOST, HR_API_ENDPOINT } from "@config/consts";

import { getApiUrl } from "@services/ApiUrl/ApiUrl";
import { langFromPathname } from "@services/LangFromPathname";
import { DATE_FORMAT } from "@services/DateFormatter";

import { IStore } from "@soft/redux/reducers";
import { getUserData } from "@soft/redux/user/user.selectors";
import { getDefaultLocationId } from "@soft/redux/locations/locations.selectors";

import {
  ClientDeclareYourEmployeeBody,
  HRDeclareYourEmployeeBody,
} from "./declareEmployee.typings";
import {
  getEmployeeContactDetails,
  getEmployeePersonalInformation,
  getEmployeeBankAccount,
  getEmergencyContact,
  getEmployeeChildrenNumber,
  getEmployeeHasChildren,
  getAgentUuid,
  getService,
  getJobDuration,
  getVacations,
  getContractTerm,
  getJobFrequency,
} from "./declareEmployee.selectors";

const asyncActionConfig = {
  apiEndpoint: getApiUrl("hr", "v1", HR_API_ENDPOINT),
  headers: {
    "Accept-Language": langFromPathname(),
  },
  host: BATMAID_HOST,
};

export const getAsyncActionHRConfig = () => asyncActionConfig;

export const getDeclarationProcessEndpointRequest = (
  store: () => IStore,
): ClientDeclareYourEmployeeBody => {
  const agentUuid = getAgentUuid(store());
  const service = getService(store());
  const duration = getJobDuration(store());
  const frequency = getJobFrequency(store());
  const contractTerm = getContractTerm(store());
  const vacation = getVacations(store());
  const defaultLocationId = getDefaultLocationId(store());

  const startDateTimeJoined = parse(
    `${contractTerm.get("contractTermStartDate")} ${contractTerm.get(
      "contractTermStartTime",
    )}`,
    DATE_FORMAT.DAY_MONTH_YEAR_HOUR_MINUTE,
    new Date(),
  );
  const startDateTime = formatISO(startDateTimeJoined);

  const endDate = contractTerm.get("contractTermEndDate")
    ? format(
        parse(
          contractTerm.get("contractTermEndDate"),
          DATE_FORMAT.DAY_PICKER_INPUT,
          new Date(),
        ),
        DATE_FORMAT.YEAR_MONTH_DAY,
      )
    : null;

  return {
    service,
    duration,
    frequency: frequency.get("frequency"),
    otherFrequencyComment: frequency.get("otherFrequencyDetails"),

    // contract term
    startDateTime,
    ...(endDate && { endDate }),

    vacation,
    jobLocationUuid: defaultLocationId,
    agentUuid,
  };
};

export const getHRDeclareEmployeeEndpointRequest = (
  store: () => IStore,
): HRDeclareYourEmployeeBody => {
  const userData = getUserData(store());
  const contactDetails = getEmployeeContactDetails(store());
  const personalInformation = getEmployeePersonalInformation(store());
  const bankAccount = getEmployeeBankAccount(store());
  const emergencyContact = getEmergencyContact(store());
  const howManyChildren = getEmployeeChildrenNumber(store());
  const hasChildren = getEmployeeHasChildren(store());

  const birthDate =
    personalInformation.get("dateOfBirth") &&
    format(
      parse(
        personalInformation.get("dateOfBirth"),
        DATE_FORMAT.DAY_PICKER_INPUT,
        new Date(),
      ),
      DATE_FORMAT.YEAR_MONTH_DAY,
    );

  const endpointData = {
    // clientId
    clientId: userData?.get("uuid") || "",

    // contact details
    title: contactDetails.get("title"),
    firstName: contactDetails.get("firstName"),
    lastName: contactDetails.get("lastName"),
    streetAddress: contactDetails.get("streetAddress"),
    zipCode: contactDetails.get("zipCode"),
    city: contactDetails.get("city"),
    phoneNumber: contactDetails.get("mobilePhone"),
    email: contactDetails.get("email"),

    // personalInformation
    birthDate,
    maritalStatus: personalInformation.get("civilStatus"),
    workPermit: personalInformation.get("workPermit"),
    socialNumber: personalInformation.get("avsNumber"),

    // bank account
    bankAccountOwnerName: bankAccount.get("bankAccountOwner"),
    bankAccountOwnerAddress: bankAccount.get("bankAccountStreetAddress"),
    iban: bankAccount.get("bankAccount"),
    bankAccountOwnerZipCode: bankAccount.get("bankAccount"),

    // emergency contact
    emergencyContactName: emergencyContact.get("name"),
    emergencyContactNumber: emergencyContact.get("phoneNumber"),

    // children
    hasChildren,
    howManyChildren,
  };

  //@ts-ignore
  return Object.fromEntries(
    Object.entries(endpointData).filter(([_, value]) => !!value),
  );
};
