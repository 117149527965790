/**
 * @author michalstolarski
 * @since 2023-2-2
 */

import * as React from "react";
import styled, { css, useTheme } from "styled-components";

import { AVAILABLE_CURRENCIES } from "@typings/globals";

import { rem } from "@ui/helpers";
import { Info } from "@ui/Assets/Symbolicons";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { Tooltip } from "@ui/Atoms/Tooltip";
import { Skeleton } from "@ui/Atoms/Skeleton";

import {
  intlNumberFormatCurrencyToParts,
  intlNumberFormatCurrency,
} from "@services/IntlNumberFormatCurrency";

type Variant = "mobile" | "desktop";

interface Props {
  testId?: string;
  testIds?: {
    price?: string;
    tooltip?: string;
    caption?: string;
  };
  className?: string;
  variant: Variant;
  price: number;
  currency: AVAILABLE_CURRENCIES;
  overridePrice?: string;
  caption?: string;
  tooltip?: string;
  translations: {
    totalText: string;
  };
  isLoading?: boolean;
}

const SummaryTotal = (props: Props): React.ReactElement => {
  const theme = useTheme();

  const formattedPrice = React.useMemo((): React.ReactElement => {
    const formattedPrice = intlNumberFormatCurrency(
      props.price,
      props.currency,
    );
    const formattedCurrency = intlNumberFormatCurrencyToParts(
      props.price,
      props.currency,
    ).find(({ type }) => type === "currency");

    if (
      !formattedCurrency ||
      formattedPrice.indexOf(formattedCurrency.value) === -1
    ) {
      return <Price>{formattedPrice}</Price>;
    }

    const currencyPosition = formattedPrice.indexOf(formattedCurrency.value);

    return (
      <React.Fragment>
        {currencyPosition === 0 ? <span>{formattedCurrency.value}</span> : null}
        <Price>
          {formattedPrice.replace(formattedCurrency.value, "").trim()}
        </Price>
        {currencyPosition !== 0 ? <span>{formattedCurrency.value}</span> : null}
      </React.Fragment>
    );
  }, [props.price, props.currency]);

  return (
    <TestWrapper testId={props.testId}>
      <Total className={props.className} variant={props.variant}>
        <LabelWrapper withCaption={!!props.caption}>
          <Label>{props.translations.totalText.toUpperCase()}</Label>

          {!!props.caption && (
            <TestWrapper testId={props.testIds?.caption}>
              <Caption>{props.caption}</Caption>
            </TestWrapper>
          )}
        </LabelWrapper>

        <TestWrapper testId={props.testIds?.price}>
          <PriceWrapper>
            {props.isLoading && <PriceSkeleton noGutter />}

            {!props.overridePrice && !props.isLoading && (
              <React.Fragment>{formattedPrice}</React.Fragment>
            )}

            {!!props.overridePrice && !props.isLoading && (
              <span>{props.overridePrice}</span>
            )}

            {!!props.tooltip && (
              <TestWrapper testId={props.testIds?.tooltip}>
                <TooltipWrapper>
                  <Tooltip placeholder={props.tooltip}>
                    <PriceIconWrapper>
                      <Info
                        size={theme.icons.sizes.base_x3}
                        variant="solid"
                        color={theme.colors.blue400}
                      />
                    </PriceIconWrapper>
                  </Tooltip>
                </TooltipWrapper>
              </TestWrapper>
            )}
          </PriceWrapper>
        </TestWrapper>
      </Total>
    </TestWrapper>
  );
};

export { Props, SummaryTotal };

const Total = styled.div<{ variant: Variant }>`
  --total-line-height: ${rem(32)};
  --total-font-size: ${props => props.theme.fonts.sizes.h6};

  ${({ variant }) =>
    variant === "desktop" &&
    css`
      --total-line-height: ${props => props.theme.fonts.lineHeights.normal};
      --total-font-size: ${props => props.theme.fonts.sizes.body};
    `}

  font-size: ${props => props.theme.fonts.sizes.h6};
  line-height: var(--total-line-height);
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: ${props => props.theme.margins.base_x4};
`;

const LabelWrapper = styled.div<{ withCaption: boolean }>`
  ${({ withCaption }) =>
    withCaption &&
    css`
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      gap: 0 ${props => props.theme.margins.half};
    `}
`;

const Label = styled.div`
  flex: 0 1 auto;
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

const Caption = styled.div`
  flex: 1 1 auto;
  font-size: ${props => props.theme.fonts.sizes.overline};
  line-height: ${props => props.theme.fonts.lineHeights.overline};
  color: ${props => props.theme.colors.grey700};
  white-space: nowrap;
`;

const PriceWrapper = styled.div`
  white-space: nowrap;
  display: flex;
  gap: ${props => props.theme.margins.half};
  align-items: center;
`;

const Price = styled.div`
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

const TooltipWrapper = styled.div`
  white-space: normal;
`;

const PriceIconWrapper = styled.div`
  flex: 0 0 auto;
  font-size: 0;
`;

const PriceSkeleton = styled(Skeleton)`
  flex: 0 1 auto;
  min-height: var(--total-line-height);
  width: ${rem(90)};
`;
