import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { MODALS } from "@typings/modals";

import {
  Row,
  Paragraph,
  Button,
  Container,
  Heading,
  CheckListItem,
} from "@ui/Atoms";
import { Info } from "@ui/Assets/Symbolicons/Info";
import { rem } from "@ui/helpers";

import { useModalStore } from "@soft/hooks/useModalStore";
import { isUserLoggedIn } from "@soft/redux/user";

import { GatsbyImage } from "../../components/GatsbyImage";
import { List } from "../Homepage/";

import { Section, StyledColumn } from "./shared";

interface ListItem {
  list_item: { text: string };
}

export interface BatsoftHeroProps {
  hero_title: {
    text: string;
  };
  hero_title_description: {
    text: string;
  };
  hero_list: ListItem[];
  hero_button_register_text: {
    text: string;
  };
  hero_button_register_link: {
    text: string;
  };
  hero_button_declare_employee_text: {
    text: string;
  };
  hero_button_declare_employee_link: {
    text: string;
  };
  hero_info: {
    text: string;
  };
  hero_login: {
    text: string;
  };
  hero_image: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  lang: string;
}

const HeroSection = (props: BatsoftHeroProps): React.ReactElement => {
  const { showModal } = useModalStore();

  const isLoggedIn = useSelector(isUserLoggedIn);

  const buttonText = isLoggedIn
    ? props.hero_button_declare_employee_text.text
    : props.hero_button_register_text.text;
  const buttonLink = isLoggedIn
    ? props.hero_button_declare_employee_link.text
    : props.hero_button_register_link.text;

  const handleRedirect = () => {
    window.location.href = `/${props.lang}${buttonLink}`;
  };

  return (
    <HeroWrapper>
      <StyledSection isWhite>
        <Container>
          <Row>
            <StyledColumn xs={12} lg={6}>
              <StyledHeading type="h1" styleAs="h4">
                {props.hero_title.text}
              </StyledHeading>
              <Description>{props.hero_title_description.text}</Description>
              <StyledList>
                {props.hero_list.map((item: ListItem) => (
                  <StyledListItem
                    key={item.list_item.text}
                    value={item.list_item.text}
                  />
                ))}
              </StyledList>
              <StyledButton variant="green" onClick={handleRedirect}>
                {buttonText}
              </StyledButton>

              {!isLoggedIn && (
                <InfoText>
                  <Info variant="line" size={24} height={24} />{" "}
                  {props.hero_info.text}
                  <LoginText onClick={() => showModal(MODALS.NAVBAR_LOGIN)}>
                    {props.hero_login.text}
                  </LoginText>
                </InfoText>
              )}
            </StyledColumn>
            <StyledColumn xs={12} lg={6}>
              <ImageWrapper>
                <GatsbyImage
                  image={props.hero_image.gatsbyImageData}
                  alt={props.hero_image.alt}
                />
              </ImageWrapper>
            </StyledColumn>
          </Row>
        </Container>
      </StyledSection>
    </HeroWrapper>
  );
};

export { HeroSection };

const StyledSection = styled(Section)`
  padding: ${props => props.theme.margins.base_x4} 0
    ${props => props.theme.margins.base_x8};

  ${props => props.theme.breakpoints.desktop} {
    padding: ${props => props.theme.margins.base_x8} 0 ${rem(82)};
  }
`;

const HeroWrapper = styled.div`
  padding: ${props => props.theme.margins.base} 0;
  background: ${props => props.theme.colors.white};

  ${props => props.theme.breakpoints.tablet} {
    padding: ${props => props.theme.margins.base_x6} 0;
  }

  ${props => props.theme.breakpoints.desktop} {
    margin-top: -${props => props.theme.margins.base};
    padding: 0 0 ${props => props.theme.margins.base_x5};
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base_x3};

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base_x4};
  }

  ${props => props.theme.breakpoints.desktop} {
    margin-bottom: ${props => props.theme.margins.base_x2};
  }
`;

const Description = styled(Paragraph)`
  margin-bottom: ${props => props.theme.margins.base_x3};
  font-size: ${props => props.theme.fonts.sizes.h6};
  color: ${props => props.theme.colors.grey700};
  line-height: ${props => props.theme.fonts.lineHeights.h5};

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base_x3};
  }
`;

const StyledList = styled(List)`
  margin-bottom: ${props => props.theme.margins.base_x3};
  color: ${props => props.theme.colors.black700};
  width: 100%;
`;

const StyledListItem = styled(CheckListItem)`
  margin-bottom: ${props => props.theme.margins.base};

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base_x2};
  }
`;

const StyledButton = styled(Button)`
  margin-bottom: ${props => props.theme.margins.base_x3};
  width: 100%;

  ${props => props.theme.breakpoints.tablet} {
    width: ${rem(328)};
  }
`;

const ImageWrapper = styled.div`
  display: none;

  ${props => props.theme.breakpoints.tablet} {
    display: block;
    min-width: ${rem(600)};
    min-height: ${rem(400)};

    > div {
      border-radius: ${props => props.theme.border.radius.base};
    }

    img {
      min-width: ${rem(600)};
      min-height: ${rem(400)};
    }
  }

  ${props => props.theme.breakpoints.desktop} {
    margin-right: -${props => props.theme.margins.base_x6};
    margin-left: ${props => props.theme.margins.base_x2};
  }
`;

const InfoText = styled(Paragraph)`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.grey700};

  svg {
    margin-right: ${props => props.theme.margins.base};
  }
`;

const LoginText = styled.span`
  margin-left: ${props => props.theme.margins.base};
  color: ${props => props.theme.colors.blue400};
  cursor: pointer;
`;
