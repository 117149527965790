import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const House = (props: Icon): React.ReactElement => {
  if (props.variant === "line") {
    return (
      <svg
        viewBox="0 0 32 32"
        style={{ ...style(props.size, props.height), ...props.style }}
        className={props.className}
      >
        <g
          fill={props.color || theme.colors.grey700}
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path d="M15.4373 5.53442C15.7283 5.29629 16.1327 5.26983 16.449 5.45504L16.563 5.53442L26.3408 13.5344C26.5128 13.6751 26.6245 13.8739 26.657 14.0906L26.6668 14.2224V24.0002C26.6668 25.4063 25.5793 26.5577 24.1992 26.6595L24.0002 26.6668H8.00016C6.59398 26.6668 5.44258 25.5793 5.34081 24.1992L5.3335 24.0002V14.2224C5.3335 14.0002 5.4166 13.7878 5.56371 13.6255L5.65951 13.5344L15.4373 5.53442ZM16.0002 7.36994L7.11127 14.6428V24.0002C7.11127 24.4108 7.38896 24.7559 7.76696 24.8582L7.89643 24.8831L8.00016 24.8891L11.5548 24.8882L11.5557 20.4446C11.5557 17.9901 13.5457 16.0002 16.0002 16.0002C18.39 16.0002 20.3396 17.8868 20.4405 20.2518L20.4446 20.4446L20.4442 24.8882L24.0002 24.8891C24.4468 24.8891 24.816 24.5605 24.8794 24.1316L24.889 24.0002V14.6437L16.0002 7.36994ZM13.338 20.2879C13.4191 18.8882 14.5801 17.7779 16.0002 17.7779C17.4728 17.7779 18.6668 18.972 18.6668 20.4446V24.8882H13.3335V20.4446L13.338 20.2879Z" />
        </g>
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 24 24"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <g fill={props.color || theme.colors.grey700}>
        <path d="M19.4667 10.1087L12 4L4.53333 10.1087C4.196 10.3853 4 10.7987 4 11.2347V18.5453C4 19.3487 4.65133 20 5.45467 20H9.33333V16C9.33333 14.5273 10.5273 13.3333 12 13.3333C13.4727 13.3333 14.6667 14.5273 14.6667 16V20H18.5453C19.3487 20 20 19.3487 20 18.5453V11.2347C20 10.7987 19.804 10.3853 19.4667 10.1087Z" />
      </g>
    </svg>
  );
};
