import { createSelector } from "reselect";

import { USER_OPTIONS_KEYS } from "@typings/userOptions";

import { IStore } from "@soft/redux/reducers";

const getOptionValueFunction = (option: USER_OPTIONS_KEYS, store: IStore) =>
  store.userOptions.getIn(["options", option]);

export const getOptionValue = createSelector(getOptionValueFunction, f => f);

const getAllOptionsFunction = (store: IStore) => store.userOptions;

export const getAllOptions = createSelector(getAllOptionsFunction, f => f);

const getTrustMandate2020UrlsFunction = (store: IStore) =>
  store.userOptions.get("trustMandate2020Urls");

export const getTrustMandate2020Urls = createSelector(
  getTrustMandate2020UrlsFunction,
  f => f,
);

const getTrustMandateUrlsFetchedStatusFn = (store: IStore) =>
  store.userOptions.get("trustMandateUrlsFetched");

export const getTrustMandateUrlsFetchedStatus = createSelector(
  getTrustMandateUrlsFetchedStatusFn,
  f => f,
);

const hasSignedTrustMandateInDocusignFn = (store: IStore) => {
  return store.userOptions.get("hasSignedTrustMandateInDocusign");
};

export const hasSignedTrustMandateInDocusign = createSelector(
  hasSignedTrustMandateInDocusignFn,
  f => f,
);
