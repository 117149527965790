import * as React from "react";

import {
  COUNTRY_LOCAL_STORAGE_KEY,
  LANGUAGE_LOCAL_STORAGE_KEY,
} from "@config/consts";
import i18next from "@config/i18next";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

import { localStorageFacade } from "@services/LocalStorageFacade";

import { useTranslateManager } from "@hooks/useTranslate";

import { useLocalisationDispatch, useLocalisationSelector } from "./redux";
import {
  getLanguage,
  getCountry,
  setLanguage as setLanguageAction,
  setCountry as setCountryAction,
} from "./redux/localisation";

/**
 * Caution!
 * Please do not use actions & selectors from this hook in apps that have their own `localisation.reducer`.
 * This may result in unpredictable app state, violating one of the fundamentary principles of Redux.
 * As of the day of writing this comment (13.01.2022), the above mentioned actions and selector should only be used in `agentApp` & `app`.
 */
const useLocalisation = () => {
  const dispatch = useLocalisationDispatch();
  const language = useLocalisationSelector(getLanguage);
  const country = useLocalisationSelector(getCountry);
  const localStorage = localStorageFacade();
  const { clearTranslationsCache } = useTranslateManager();

  const setLanguage = (language: AVAILABLE_LANGS) => {
    clearTranslationsCache();
    i18next.changeLanguage(language);
    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, language);
    return dispatch(setLanguageAction(language));
  };

  const setCountry = (country: AVAILABLE_COUNTRIES) => {
    localStorage.setItem(COUNTRY_LOCAL_STORAGE_KEY, country);
    return dispatch(setCountryAction(country));
  };

  return { language, setLanguage, country, setCountry };
};

export { useLocalisation };
