import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import React from "react";
import styled from "styled-components";

import { AVAILABLE_COUNTRIES } from "@typings/globals";

import { Container, Heading } from "@ui/Atoms";

import {
  PrismicCountryLinks,
  PrismicGlobalContentPageDataBodyCountriesLinks,
} from "@www/graphql-types";

import { CountryLinks } from "../../components/CountryLinks";
import { SliceContainer } from "../../components/SliceContainer";

const CountriesLinksSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyCountriesLinks>,
): React.ReactElement | null => {
  const primary = props.slice.primary;
  const document = primary.links?.document as PrismicCountryLinks;
  const links = document?.data?.countries_links;

  if (!links?.length) {
    return null;
  }

  return (
    <SliceContainer>
      <StyledContainer>
        <StyledHeading type="h3" textAlignLg="center">
          {primary.title1?.text}
        </StyledHeading>
        <PrismicRichText field={primary.description?.richText} />

        <ButtonsContainer>
          {links.map(
            item =>
              item?.country?.richText &&
              item?.country_code &&
              item?.links?.richText && (
                <CountryLinks
                  key={item.country_code}
                  countryCode={item.country_code! as AVAILABLE_COUNTRIES}
                  countryName={item.country?.richText}
                  field={item?.links?.richText}
                />
              ),
          )}
        </ButtonsContainer>
      </StyledContainer>
    </SliceContainer>
  );
};

export { CountriesLinksSlice };

const StyledContainer = styled(Container)`
  padding: ${props =>
    `${props.theme.margins.base_x8} ${props.theme.margins.base_x3}`};

  ${props => props.theme.breakpoints.tablet} {
    padding: 0;
  }
`;

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${props => props.theme.margins.base_x2};
  padding-bottom: ${props => props.theme.margins.base_x2};
  border-radius: ${props => props.theme.border.radius.base};
  background: ${props => props.theme.colors.white};
  text-align: left;

  ${props => props.theme.breakpoints.tablet} {
    padding: ${props => props.theme.margins.base_x2};
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base};
  font-size: ${props => props.theme.fonts.sizes.h5};
  font-weight: ${props => props.theme.fonts.weights.bold};
  line-height: ${props => props.theme.fonts.lineHeights.h5};

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base_x4};
    font-size: ${props => props.theme.fonts.sizes.h3};
    line-height: ${props => props.theme.fonts.lineHeights.h3};
  }
`;
