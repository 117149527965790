import { IStore } from "@soft/redux/reducers";

import { getLegalLocation } from "./bookingsToValidate.selectors";

export const getAddLegalLocationEndpointRequest = (store: IStore) => {
  const legalLocation = getLegalLocation(store);

  return {
    address: legalLocation.get("address"),
    floorAndDoor: legalLocation.get("floorAndDoor"),
    serviceLocationId: legalLocation.get("serviceLocationId"),
  };
};
