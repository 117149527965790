import { Record, Map } from "immutable";
import { AnyAction } from "redux";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import { FETCH_LOCATIONS } from "./locations.actions";
import { LocationsReducer } from "./locations.typings";
import { locationsFactory } from "./locations.factories";

const initialState = Record<LocationsReducer>({
  defaultLocationId: "",
  invoiceLocationId: "",
  legalLocationId: "",
  locations: Map(),
});

const locationsReducer = (
  store: Record<LocationsReducer> = initialState(),
  action: AnyAction,
) => {
  const { payload, type } = action;

  switch (type) {
    case `${FETCH_LOCATIONS}${ACTION_SUCCESS}`: {
      const { data } = payload;

      return store.merge(locationsFactory(data));
    }
    default: {
      return store;
    }
  }
};

export { locationsReducer };
