import { createSelector } from "reselect";

import { AVAILABLE_COUNTRIES } from "@typings/globals";

import { LocalisationState } from "@hooks/useLocalisation/redux";

const getLanguageFunction = (store: LocalisationState) =>
  store.useLocalisation.get("language");
export const getLanguage = createSelector(getLanguageFunction, f => f);

const getCountryFunction = (store: LocalisationState): AVAILABLE_COUNTRIES =>
  store.useLocalisation.get("country");
export const getCountry = createSelector(getCountryFunction, f => f);
