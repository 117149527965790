/**
 * @author tomekbuszewski & jakubbujakowski
 * @since 2019-3-8
 */

import * as React from "react";
import styled from "styled-components";
import Cookies from "js-cookie";

import { getAgentNavigationConfig } from "@config/navigation";
import {
  AGENT_LOGOUT_LINK,
  BECOME_A_BATMAID_LINK,
  GIFT_CARDS_LINK,
  HELP_LINK_BATMAID,
} from "@config/links";
import { ROUTES } from "@config/app.routing";
import {
  IS_BROWSER,
  COUNTRY_LOCAL_STORAGE_KEY,
  HAS_USER_LOGGED_OUT,
} from "@config/consts";
import { NAVBAR } from "@config/testIds/navbar";

import {
  AVAILABLE_LANGS,
  AVAILABLE_COUNTRIES,
  APP_NAMES,
} from "@typings/globals";

import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { Header as HeaderUI } from "@ui/Organisms/Header";
import {
  NavbarToggle,
  Navigation as NavigationUI,
  NavLink,
} from "@ui/Organisms/Navbar";
import { UserDropdown } from "@ui/Organisms/UserDropdown";
import { LanguageDropdown } from "@ui/Organisms/LanguageDropdown";

import { getCountriesList, getLanguagesList } from "@services/Localisation";
import { localStorageFacade } from "@services/LocalStorageFacade";
import { setCountryInUrl } from "@services/SetCountryInUrl";
import { setLanguageInUrl } from "@services/SetLanguageInUrl";
import { resolveCountryFromUrl } from "@services/ResolveCountryFromUrl";

import { ToastContainer } from "@containers/Toastify";

import { useCountOfNotifications } from "@hooks/useNumberOfNotifications";
import { useAuth, useAuthManager } from "@hooks/useAuth";
import { useFeatureToggle } from "@hooks/useFeatureToggle";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { useTranslate } from "@hooks/useTranslate";
import { useOnClickOutside } from "@hooks/useOnClickOutside";
import { useTelemetry } from "@hooks/useTelemetry";
import { useLocalisation } from "@hooks/useLocalisation";

import { UserNotificationHandler } from "@app/containers/UserNotificationHandler";
import { useUserNotifications } from "@app/hooks/useUserNotifications";

import { HeaderModals } from "./Header.modals";
import {
  createHref,
  eventHandler,
  getUserNavigationConfig,
} from "./Header.helpers";

interface Props {
  children?: React.ReactNode;
  useNativeLinks: boolean;
  staticLanguage?: AVAILABLE_LANGS;
  isSSR?: boolean;
  isTeaserVersion?: boolean;
  staticNavigate?: (path: string) => void;
}

const Header = React.memo((props: Props) => {
  const localStorage = localStorageFacade();
  const breakpoint = useBreakpoint();
  const translate = useTranslate();
  const [isMenuActive, setIsMenuActive] = React.useState(false);
  const [isHiddenEnvironment, setIsHiddenEnvironment] = React.useState(false);
  const {
    isUserClient,
    isUserLoggedIn,
    isUserAdmin,
    isUserAgent,
    isAdminImpersonator,
    user,
    logout,
  } = useAuth();
  const { openLoginModal } = useAuthManager();
  const { myCleanings, newCleanings } = useCountOfNotifications();
  const { elementClick } = useTelemetry();
  const { country, language, setCountry, setLanguage } = useLocalisation();
  const { userNotifications } = useUserNotifications();

  const menuEffectDeps = IS_BROWSER ? [window.location.href] : [];
  const resolvedLanguage = props.staticLanguage || language;
  const isMobile = breakpoint.mobile || breakpoint.tablet;
  const hasBookingsToValidate = userNotifications.hasBookingsToValidate;

  const currentCountry = props.isSSR
    ? (process.env.COUNTRY as AVAILABLE_COUNTRIES)
    : country;

  const showBatgroupApps = currentCountry === AVAILABLE_COUNTRIES.CH;

  // didFetchFeatureToggles is needed to avoid hydration issues
  const usePwaAgentScheduleRoute = useFeatureToggle("agent_pwa_schedule");
  const usePwaAgentMyCleaningsRoute = useFeatureToggle(
    "agent_pwa_my_cleanings",
  );
  const usePwaAgentNewCleaningsRoute = useFeatureToggle(
    "agent_pwa_new_cleanings",
  );
  const usePwaAgentAvailabilitiesRoute = useFeatureToggle(
    "agent_pwa_availabilities",
  );
  const usePwaAgentProfileRoute = useFeatureToggle("agent_pwa_profile");
  const isGiftCardEnabled = useFeatureToggle("gift_cards");

  const agentRoutesFeatureFlagConfig = {
    usePwaAgentScheduleRoute,
    usePwaAgentMyCleaningsRoute,
    usePwaAgentNewCleaningsRoute,
    usePwaAgentAvailabilitiesRoute,
    usePwaAgentProfileRoute,
  };

  const ref = useOnClickOutside(() => setIsMenuActive(false));

  const handleSetCountry = (country: AVAILABLE_COUNTRIES) => {
    setCountry(country);

    const setCountryInLocalStorage = (country: AVAILABLE_COUNTRIES) =>
      localStorage.setItem(COUNTRY_LOCAL_STORAGE_KEY, country);

    setCountryInUrl(
      country,
      resolvedLanguage,
      isMobile,
      setCountryInLocalStorage,
    );
  };

  const handleSetLanguage = (lang: AVAILABLE_LANGS) => {
    setLanguageInUrl(lang);
    setLanguage(lang);
  };

  const logoutUser = async () => {
    if (!props.isSSR) {
      /*
        set localstorage flag (only for PWA, gatsby won't redirect)
        to hide modal in /redirection after intentional logout by UserDropdown
      */
      sessionStorage.setItem(HAS_USER_LOGGED_OUT, "true");
    }

    await logout();
    props.staticNavigate
      ? props.staticNavigate(`/${resolvedLanguage}/`)
      : (window.location.href = "/");
  };

  const loginUser = () => {
    openLoginModal();
  };

  const toggleEnvironment = () => {
    if (isHiddenEnvironment) {
      Cookies.remove("hidden");
    } else {
      Cookies.set("hidden", "true");
    }

    location.reload();
  };

  const navigationConfig = isUserAgent
    ? getAgentNavigationConfig({
        exitUserUrl: user?.get("exitUserUrl") || "",
        isUserLoggedIn,
        isUserAdmin,
        isAdminImpersonator,
        isMobile,
        showBatgroupApps,
        language: resolvedLanguage,
        logoutUrl: AGENT_LOGOUT_LINK,
        loginAction: loginUser,
        logoutAction: logoutUser,
        translate,
        toggleEnvironment,
        routesFeatureFlagConfig: agentRoutesFeatureFlagConfig,
        counters: {
          agentNewCleanings: newCleanings.totalCount,
          agentMyCleanings: myCleanings.totalCount,
        },
      })
    : getUserNavigationConfig({
        exitUserUrl: user?.get("exitUserUrl") || "",
        logoutUrl: user?.get("logoutUrl") || "",
        isUserAdmin,
        isUserClient,
        isAdminImpersonator: isAdminImpersonator,
        hasBookingsToValidate,
        isMobile: isMobile,
        showBatgroupApps,
        language: resolvedLanguage,
        isTeaserVersion: props.isTeaserVersion,
        isGiftCardEnabled,
        logoutAction: logoutUser,
        loginAction: loginUser,
        translate,
        toggleEnvironment,
      });

  React.useEffect(() => {
    setIsMenuActive(false);
  }, menuEffectDeps);

  React.useEffect(() => {
    setIsHiddenEnvironment(!!Cookies.get("hidden"));

    (async () => {
      const countryFromUrl = resolveCountryFromUrl();

      setCountry(countryFromUrl);
    })();
  }, []);

  return (
    <React.Fragment>
      {/** Main View */}
      <HeaderUI
        currentLanguage={resolvedLanguage}
        currentCountry={currentCountry}
        isMobile={isMobile}
        variant={APP_NAMES.BATMAID}
        isSSR={props.isSSR}
        loginUser={loginUser}
        rowRef={ref}
        id="header"
        onLogoClick={async e => {
          e.preventDefault();

          await elementClick(undefined, {
            buttonId: "header-logo",
          });

          const $target = e.target as HTMLElement;
          const hrefTarget =
            $target.tagName === "a"
              ? ($target as HTMLAnchorElement).href
              : $target.closest("a")!.href;

          if (props.staticNavigate) {
            return props.staticNavigate(hrefTarget);
          }

          window.location.href = hrefTarget;
        }}
      >
        <NavigationUI active={isMenuActive} variant={APP_NAMES.BATMAID}>
          {/*
              First NavLink won't get styles, that's why we use empty one as the first NavLink.
              This is a temporary solution for a styled-components issue.
          */}
          <NavLinkDesktopOnly>
            <a
              href={"#"}
              style={{
                display: "none",
              }}
            />
          </NavLinkDesktopOnly>
          {!isUserLoggedIn && (
            <NavLinkDesktopOnly isDark>
              <TestWrapper testId={NAVBAR.BECOME_BATMAID}>
                <a
                  href={createHref(resolvedLanguage, BECOME_A_BATMAID_LINK)}
                  onClick={eventHandler(
                    () =>
                      elementClick(
                        translate("batmaid_pwa_navigation.become_a_batmaid"),
                        {
                          buttonId: "become-batmaid-header",
                        },
                      ),
                    true,
                  )}
                >
                  {translate("batmaid_pwa_navigation.become_a_batmaid")}
                </a>
              </TestWrapper>
            </NavLinkDesktopOnly>
          )}
          {!isUserAgent && !props.isTeaserVersion && (
            <React.Fragment>
              <NavLinkDesktopOnly isDark>
                <a
                  href={createHref(resolvedLanguage, HELP_LINK_BATMAID)}
                  onClick={eventHandler(
                    () =>
                      elementClick(translate("batmaid_pwa_navigation.help"), {
                        buttonId: "help-header",
                      }),
                    true,
                  )}
                >
                  {translate("batmaid_pwa_navigation.help")}
                </a>
              </NavLinkDesktopOnly>
              {isGiftCardEnabled && (
                <NavLinkDesktopOnly special>
                  <a
                    href={createHref(resolvedLanguage, GIFT_CARDS_LINK)}
                    onClick={eventHandler(
                      () =>
                        elementClick(
                          translate("batmaid_pwa_navigation.gift_cards"),
                          {
                            buttonId: "gift-card-header",
                          },
                        ),
                      true,
                    )}
                  >
                    {translate("batmaid_pwa_navigation.gift_cards")}
                  </a>
                </NavLinkDesktopOnly>
              )}
            </React.Fragment>
          )}
          <UserDropdown
            isUserLoggedIn={isUserLoggedIn}
            userData={user}
            currentLanguage={resolvedLanguage}
            baseUrl={ROUTES.CLIENT_DASHBOARD}
            useNativeLinks={props.useNativeLinks}
            isDark
            loginLabel={translate("batmaid_pwa_navigation.login")}
            loginUser={loginUser}
            skipLoginButton={props.isTeaserVersion}
            config={navigationConfig}
            handleTelemetry={(event, label, eventId, redirect) =>
              eventHandler(
                () =>
                  elementClick(label, {
                    buttonId: eventId,
                  }),
                redirect,
              )(event)
            }
          />
        </NavigationUI>

        <LanguageDropdown
          currentFlag={currentCountry}
          currentLanguage={resolvedLanguage}
          countries={getCountriesList(translate)}
          languages={getLanguagesList()}
          setCountry={handleSetCountry}
          setLanguage={handleSetLanguage}
          languageHeaderText={translate(
            "batmaid_pwa_navigation.choose_language",
          )}
          countryHeaderText={translate("batmaid_pwa_navigation.choose_country")}
          onToggle={active =>
            elementClick(resolvedLanguage, {
              buttonId: "change-language-header",
              context: {
                open: active,
              },
            })
          }
        />

        <NavbarToggle
          onClick={() => setIsMenuActive(prevState => !prevState)}
          active={isMenuActive}
          variant={APP_NAMES.BATMAID}
          label={translate("batmaid_pwa_navigation.toggle_menu")}
        />
      </HeaderUI>
      {/** Extras */}
      <HeaderModals />
      <ToastContainer />
      {IS_BROWSER && !props.isSSR && <UserNotificationHandler />}
    </React.Fragment>
  );
});
export { Header };
export default Header;

Header.displayName = "Header";

const NavLinkDesktopOnly = styled(NavLink)`
  display: none;

  ${props => props.theme.breakpoints.desktop} {
    display: flex;
  }
`;
