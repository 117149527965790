import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const HandsShield = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M21 21L21.578 19.844C21.8556 19.2887 22.0001 18.6765 22 18.0557V11.5C22 10.6716 21.3284 10 20.5 10V10C19.6716 10 19 10.6716 19 11.5V15.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 7.5C8 9.40546 10.3747 10.8207 11.4667 11.3709C11.8014 11.543 12.1986 11.543 12.5333 11.3709C13.6253 10.8207 16 9.40846 16 7.5V4.79308C15.9928 4.52977 15.7864 4.31521 15.5235 4.29782C14.4608 4.21236 13.4434 3.83028 12.5872 3.19508C12.239 2.93497 11.7611 2.93497 11.4129 3.19508C10.5566 3.83029 9.53921 4.21236 8.47649 4.29781C8.21218 4.31201 8.00398 4.52841 8 4.79307L8 7.5Z"
      />
      <path d="M14 21V18.0866C14 17.6539 14.1404 17.2328 14.4 16.8866L16.0779 14.6494C16.3603 14.2729 16.7921 14.0375 17.2615 14.0041C17.731 13.9708 18.1917 14.1427 18.5245 14.4755V14.4755C19.0958 15.0467 19.16 15.9511 18.6753 16.5974L17.6233 18" />
      <path d="M3 21L2.422 19.844C2.14439 19.2887 1.99991 18.6765 2 18.0557V11.5C2 10.6716 2.67157 10 3.5 10V10C4.32843 10 5 10.6716 5 11.5V15.5" />
      <path d="M10 21V18.0866C10 17.6539 9.85964 17.2328 9.6 16.8866L7.92206 14.6494C7.6397 14.2729 7.20787 14.0375 6.73846 14.0041C6.26904 13.9708 5.80825 14.1427 5.47549 14.4755V14.4755C4.90424 15.0467 4.83998 15.9511 5.3247 16.5974L6.37665 18" />
    </svg>
  );
};
