import * as React from "react";
import { useSelector } from "react-redux";

import { Modal as GenericModal } from "@ui/Molecules";

import slugify from "@services/SlugifyFacade";

import { useTranslate } from "@hooks/useTranslate";

import {
  getModalInfo,
  getModalOptions,
  getModalAutohideOption,
  getModals,
} from "@soft/redux/modals/modals.selectors";
import { IStore } from "@soft/redux/reducers";
import { useModalStore } from "@soft/hooks/useModalStore";

export type Size = "thin" | "base" | "base_x2" | "large";
export type Padding = "normal" | "base_x9" | "none";

interface Props {
  auto?: boolean;
  cancel?: (e?: any) => void;
  cancelText?: string;
  centeredContent?: boolean;
  children?: React.ReactNode;
  className?: string;
  close?: (e?: any) => void;

  id?: string;
  isSubmitBtnDisabled?: boolean;
  padding?: Padding;
  size?: Size;
  submit?: (e?: any) => void;
  submitText?: string;
  testId?: string;
  title?: any;
  withContainedHeight?: boolean;
  withFooter?: boolean;
  withHeader?: boolean;
  withCancelBtn?: boolean;
  withMobileHeaderGutter?: boolean;
  submitBtnColor?: "green" | "blue" | "red";
  isBottomVariant?: boolean;
}

const Modal = (props: Props): React.ReactElement => {
  const translate = useTranslate("batsoft_pwa_generic");
  const { hideModal, showModal } = useModalStore();

  const getInfo = (id: string) =>
    useSelector((state: IStore) => getModalInfo(state, id));
  const getOptions = (id: string) =>
    useSelector((state: IStore) => getModalOptions(state, id));
  const getAutohide = (id: string) =>
    useSelector((state: IStore) => getModalAutohideOption(state, id));
  const modals = useSelector(getModals);

  return (
    <GenericModal
      {...props}
      id={props.id || slugify(props.title)}
      submitText={props.submitText || translate("button_confirm")}
      cancelText={props.cancelText || translate("button_cancel")}
      showModal={showModal}
      hideModal={hideModal}
      getInfo={getInfo}
      getOptions={getOptions}
      getAutohide={getAutohide}
      modals={modals}
      withOldCloseIcon
    />
  );
};

export { Modal };
