import * as React from "react";
import styled, { css } from "styled-components";
import debounce from "lodash.debounce";

import { SHORT_DEBOUNCE } from "@config/consts";

import { rem } from "@ui/helpers";
import { Caret } from "@ui/Assets/Symbolicons/Caret";

import { isSafari } from "@services/UserAgent";

interface SliderButtonProps {
  onClick: (e: any, isRight?: boolean) => void;
  isRight?: boolean;
  isAvailable?: boolean;
  whiteButtons?: boolean;
}

const SliderButton = (props: SliderButtonProps): React.ReactElement => (
  <ButtonWrapper
    isRight={props.isRight}
    onClick={props.onClick}
    isAvailable={props.isAvailable}
    whiteButtons={props.whiteButtons}
  >
    <Caret variant="line" />
  </ButtonWrapper>
);

interface SlidesToShow {
  mobile: number;
  tablet: number;
  desktop: number;
}

interface Props {
  children: React.ReactNode;
  isHorizontallyCentered?: boolean;
  isVerticallyCentered?: boolean;
  slidesToShow: SlidesToShow;
  initSlideIndex?: number;
  goTo?: number;
  whiteButtons?: boolean;
  slidesAmount?: number;
  className?: string;
  hideButtons?: boolean;
  sliderButton?: React.FunctionComponent<SliderButtonProps>;
  containOverscroll?: boolean;
}

const DraggableCarousel = (props: Props): React.ReactElement => {
  const sliderButton = props.sliderButton || SliderButton;
  const carouselRef = React.useRef<any>(null);
  const [isScrollToLeftAvailable, setIsScrollToLeftAvailable] =
    React.useState<boolean>(false);
  const [isScrollToRightAvailable, setIsScrollToRightAvailable] =
    React.useState<boolean>(false);

  const [isScrollable, setIsScrollable] = React.useState(false);

  const handleSliderButtonClick = (
    _: React.SyntheticEvent,
    isRight?: boolean,
  ) => {
    const distanceToNextChild = carouselRef.current.childNodes[1].offsetLeft;
    const alreadyScrolledFromLeft = carouselRef.current.scrollLeft;

    const scrollDistance = isRight
      ? alreadyScrolledFromLeft + distanceToNextChild
      : alreadyScrolledFromLeft - distanceToNextChild;

    carouselRef.current.scroll({
      left: scrollDistance,
      behavior: "smooth",
    });
  };

  const handleGoTo = (goTo: number): void => {
    if (!carouselRef.current || !carouselRef.current.scroll) {
      return;
    }

    const item = carouselRef.current.childNodes[0];
    const itemSpace =
      item.clientWidth + parseFloat(window.getComputedStyle(item).marginRight);

    carouselRef.current.scroll({
      left: itemSpace * goTo,
      behavior: "smooth",
    });
  };

  const checkAvailabilityOfCarouselNavigationArrows = debounce(() => {
    // carousel (wrapper) calculations
    const leftmostPointOfCarousel =
      carouselRef?.current?.getBoundingClientRect().x;
    const widthOfCarousel = carouselRef?.current?.getBoundingClientRect().width;
    const rightmostPointOfCarousel = leftmostPointOfCarousel + widthOfCarousel;

    // first item calculations
    const leftmostPointOfFirstCarouselItem =
      carouselRef?.current?.children[0]?.getBoundingClientRect().x;

    // last item calculations
    const lastCarouselItemChildrenIndex =
      carouselRef?.current?.children?.length - 1;
    const leftmostPointOfLastCarouselItem =
      carouselRef?.current?.children[
        lastCarouselItemChildrenIndex
      ]?.getBoundingClientRect().x;
    const widthOfLastCarouselItem =
      carouselRef?.current?.children[
        lastCarouselItemChildrenIndex
      ]?.getBoundingClientRect().width;
    const rightmostPointOfLastCarouselItem =
      leftmostPointOfLastCarouselItem + widthOfLastCarouselItem;

    const shouldScrollLeftBeAvailable =
      leftmostPointOfFirstCarouselItem < leftmostPointOfCarousel;
    if (shouldScrollLeftBeAvailable !== isScrollToLeftAvailable) {
      setIsScrollToLeftAvailable(!isScrollToLeftAvailable);
    }

    const shouldScrollRightBeAvailable =
      rightmostPointOfLastCarouselItem > rightmostPointOfCarousel;
    if (shouldScrollRightBeAvailable !== isScrollToRightAvailable) {
      setIsScrollToRightAvailable(!isScrollToRightAvailable);
    }
  }, SHORT_DEBOUNCE);

  const checkIfAreaIsScrollable = debounce(() => {
    setIsScrollable(
      carouselRef?.current?.scrollWidth > carouselRef?.current?.clientWidth,
    );
  }, SHORT_DEBOUNCE);

  React.useEffect(() => {
    checkAvailabilityOfCarouselNavigationArrows();
    checkIfAreaIsScrollable();

    if (typeof props.initSlideIndex === "number") {
      handleGoTo(props.initSlideIndex);
    }
  }, []);

  React.useEffect(() => {
    if (typeof props.goTo !== "number") return;

    handleGoTo(props.goTo);
  }, [props.goTo, props.children]);

  return (
    <Wrapper className={props.className}>
      {!props.hideButtons && (
        <React.Fragment>
          {React.createElement(sliderButton, {
            isAvailable: isScrollToLeftAvailable,
            onClick: handleSliderButtonClick,
            whiteButtons: props.whiteButtons,
          })}

          {React.createElement(sliderButton, {
            isAvailable: isScrollToRightAvailable,
            isRight: true,
            onClick: e => handleSliderButtonClick(e, true),
            whiteButtons: props.whiteButtons,
          })}
        </React.Fragment>
      )}

      <CSSCarouselWrapper>
        <CSSCarousel
          ref={carouselRef}
          slidesToShow={props.slidesToShow}
          isHorizontallyCentered={props.isHorizontallyCentered}
          isVerticallyCentered={props.isVerticallyCentered}
          onScroll={checkAvailabilityOfCarouselNavigationArrows}
          isScrollable={isScrollable}
          slidesAmount={props.slidesAmount}
        >
          {props.children}

          {/*Safari do not support margin at the end of row so we have to add fake element*/}
          {isSafari && isScrollable && <FillElement />}
        </CSSCarousel>
      </CSSCarouselWrapper>
    </Wrapper>
  );
};

export {
  SliderButtonProps,
  SlidesToShow,
  DraggableCarousel,
  CSSCarousel,
  CSSCarouselWrapper,
};

const Wrapper = styled.div`
  position: relative;
`;

const FillElement = styled.div`
  width: ${rem(48)};
  min-width: ${rem(48)} !important;
  scroll-snap-align: none !important;
`;

const CSSCarouselWrapper = styled.div``;

const CSSCarousel = styled.div<{
  slidesToShow: SlidesToShow;
  isHorizontallyCentered?: boolean;
  isScrollable?: boolean;
  isVerticallyCentered?: boolean;
  slidesAmount?: number;
  containOverscroll?: boolean;
}>`
  position: relative;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  ${props => (props.isHorizontallyCentered ? "justify-content: center;" : "")}
  ${props => (props.isVerticallyCentered ? "align-items: center;" : "")}
  flex-wrap: nowrap;

  margin: 0 -${props => props.theme.margins.base_x4};
  padding: 0 ${props => props.theme.margins.base_x4};
  scroll-padding: 0 ${props => props.theme.margins.base_x4};

  ${({ containOverscroll }) =>
    containOverscroll &&
    css`
      overscroll-behavior: contain;
    `}

  /* a workaround for old Safari where it cannot use gap for flexbox  */
  & > *:not(:last-child) {
    margin-right: ${props => props.theme.margins.base_x2};
  }

  & > * {
    flex: 1;
  }

  ${props =>
    props.isScrollable &&
    css`
      & > *:last-child {
        margin-right: ${props => props.theme.margins.base_x2};
      }
    `}

  ${props => props.theme.breakpoints.tablet} {
    margin: 0 -${rem(88)};
    padding: 0 ${rem(88)};
    scroll-padding: 0 ${rem(88)};
    & > *:not(:last-child) {
      margin-right: ${props => props.theme.margins.base_x4};
    }

    ${props =>
      props.isScrollable &&
      css`
        & > *:last-child {
          margin-right: ${props => props.theme.margins.base_x4};
        }
      `}

    ${props =>
      props.slidesAmount &&
      props.slidesAmount <= props.slidesToShow.tablet &&
      css`
        justify-content: center;
      `}
  }

  ${props => props.theme.breakpoints.desktop} {
    margin: 0;
    padding: 0;
    scroll-padding: 0 ${rem(88)};

    ${props =>
      props.slidesAmount &&
      props.slidesAmount <= props.slidesToShow.desktop &&
      css`
        justify-content: center;
      `}
  }

  /* hide horizontal scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
    -webkit-appearance: none;
  }

  /* css carousel item */
  > * {
    scroll-snap-align: start;

    /* MOBILE */
    ${props => props.theme.breakpoints.upToDesktop} {
      min-width: calc(
        ${props => 100 / props.slidesToShow.mobile}% -
          ${props => props.theme.margins.base_x2}
      );
    }

    /* TABLET */
    ${props => props.theme.breakpoints.tabletToDesktop} {
      min-width: calc(
        ${props => 100 / props.slidesToShow.tablet}% -
          ${props => props.theme.margins.base_x4}
      );
    }
  }
`;

const ButtonWrapper = styled.div<{
  isRight?: boolean;
  isAvailable?: boolean;
  whiteButtons?: boolean;
}>`
  position: absolute;
  /* it's not required supporting arrows in navigation carousel on mobile */
  display: none;
  top: 50%;
  ${props => (props.isRight ? "right: 0;" : "left: 0;")}
  justify-content: center;
  align-items: center;
  width: ${rem(32)};
  height: ${rem(32)};
  margin: 0 ${props => props.theme.margins.base};
  border-radius: ${props => props.theme.buttons.borderRadius};
  background: ${props => props.theme.colors.greySurface};
  cursor: pointer;
  transform: translateY(-50%);
  z-index: 2;

  > svg {
    transform: rotate(${props => (props.isRight ? "270deg" : "90deg")});
  }

  &:hover {
    background: ${props => props.theme.colors.greySurface};
  }

  ${props =>
    props.whiteButtons &&
    css`
      background: ${props.theme.colors.white};

      &:hover {
        background: ${props.theme.colors.white};
      }
    `}

  &:before {
    content: "";
  }

  ${props => props.theme.breakpoints.desktop} {
    display: ${props => (props.isAvailable ? "flex !important" : "none")};
  }
`;
