import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const TaskListStar = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M7.99833 3.99667H5.9975C4.89247 3.99667 3.99667 4.89247 3.99667 5.9975V19.0029C3.99667 20.1079 4.89247 21.0037 5.9975 21.0037H12" />
      <rect x="7.99833" y="1.99583" width="8.00333" height="4.00167" rx="1" />
      <path d="M16.0017 3.99667H18.0025C19.1075 3.99667 20.0033 4.89247 20.0033 5.9975V10.9996" />
      <path d="M16.0017 9.99917H10.9996" />
      <path d="M13.0004 14.0008H10.9996" />
      <path d="M7.99833 9.87412C7.92927 9.87412 7.87328 9.93011 7.87328 9.99917C7.87328 10.0682 7.92927 10.1242 7.99833 10.1242C8.0674 10.1242 8.12339 10.0682 8.12339 9.99917C8.12339 9.93011 8.0674 9.87412 7.99833 9.87412" />
      <path d="M7.99833 13.8758C7.92927 13.8758 7.87328 13.9318 7.87328 14.0008C7.87328 14.0699 7.92927 14.1259 7.99833 14.1259C8.0674 14.1259 8.12339 14.0699 8.12339 14.0008C8.12339 13.9318 8.0674 13.8758 7.99833 13.8758" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0025 14.0008C18.0025 16.2109 16.2109 18.0025 14.0008 18.0025C16.2109 18.0025 18.0025 19.7941 18.0025 22.0042C18.0025 19.7941 19.7941 18.0025 22.0042 18.0025C19.7941 18.0025 18.0025 16.2109 18.0025 14.0008Z"
      />
    </svg>
  );
};
