import React from "react";
import styled from "styled-components";

export const NavGroupHeading = styled.li<{ isDark?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${props => props.theme.margins.base} 0;
  color: ${props =>
    props.isDark ? props.theme.colors.greySurface : props.theme.colors.grey700};

  span {
    padding-left: ${props => props.theme.margins.base};
    font-size: ${props => props.theme.fonts.sizes.overline};
    font-weight: ${props => props.theme.fonts.weights.normal};
    letter-spacing: ${props => props.theme.fonts.letterSpacings.overline};
    text-transform: uppercase;
  }
`;
