import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const EditPen = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M18.1751 9.17515L14.8257 5.82573" />
      <path d="M20 13V15C20 17.7614 17.7614 20 15 20H9C6.23858 20 4 17.7614 4 15V9C4 6.23858 6.23858 4 9 4H11" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.665 14.0014H10.9997C10.4474 14.0014 9.9997 13.5537 9.9997 13.0014V11.3345C9.9997 10.8968 10.1736 10.4771 10.4831 10.1677L15.9567 4.69501C16.8816 3.77018 18.3811 3.77018 19.306 4.69501V4.69501C19.7502 5.13916 19.9997 5.74159 19.9997 6.36975C19.9997 6.99791 19.7502 7.60034 19.306 8.0445L13.8317 13.5182C13.5223 13.8276 13.1026 14.0014 12.665 14.0014V14.0014Z"
      />
    </svg>
  );
};
