/**
 * @author jakubmaslon
 * @since 2020-10-27
 */

import * as React from "react";
import styled from "styled-components";

import { CheckMark } from "@ui/Assets/Symbolicons/CheckMark";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import theme from "@ui/themes/default";
import { rem } from "@ui/helpers";

interface Props {
  label: string;
  testId?: string;
}

const Badge = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <StyledBadge>
      <CheckMark size={16} color={theme.colors.green500} />
      <BadgeText>{props.label}</BadgeText>
    </StyledBadge>
  </TestWrapper>
);

Badge.displayName = "Badge";

export { Badge };

const StyledBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.margins.quarter}
    ${props => props.theme.margins.half};
  border-radius: ${rem(12)};
  border: 1px solid ${props => props.theme.colors.green500};
  color: ${props => props.theme.colors.green500};
  font-size: ${props => props.theme.fonts.sizes.caption};
`;

const BadgeText = styled.span`
  margin-left: ${props => props.theme.margins.base_x1_5};
`;
