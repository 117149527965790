import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { Row, Paragraph, Button, Container, Margins, Heading } from "@ui/Atoms";
import { rem } from "@ui/helpers";
import theme from "@ui/themes/default";

import { isUserLoggedIn } from "@soft/redux/user";

import { Section, StyledColumn } from "./shared";

export interface DeclareNowProps {
  are_you_ready_title: {
    text: string;
  };
  are_you_ready_description: {
    text: string;
  };
  are_you_ready_register_button_link: {
    text: string;
  };
  are_you_ready_register_button_text: {
    text: string;
  };
  are_you_ready_declare_now_button_text: {
    text: string;
  };
  are_you_ready_declare_now_button_link: {
    text: string;
  };
  lang: string;
}

const DeclareNow = (props: DeclareNowProps): React.ReactElement => {
  const isLoggedIn = useSelector(isUserLoggedIn);

  const buttonText = isLoggedIn
    ? props.are_you_ready_declare_now_button_text.text
    : props.are_you_ready_register_button_text.text;

  const buttonLink = isLoggedIn
    ? props.are_you_ready_declare_now_button_link.text
    : props.are_you_ready_register_button_link.text;

  const heading = props.are_you_ready_title.text;
  const desc = props.are_you_ready_description.text;

  const handleRedirect = () => {
    window.location.href = `/${props.lang}${buttonLink}`;
  };

  return (
    <StyledSection isWhite>
      <Container>
        <Row>
          <StyledColumn xs={12} pushLg={3} lg={6}>
            <Content>
              <Margins xs={[null, "base_x3", null, null]}>
                <Heading
                  type="h2"
                  styleAs="h4"
                  textAlignXs="left"
                  textAlignLg="center"
                >
                  {heading}
                </Heading>
              </Margins>
              <Description type="body" color={theme.colors.grey700}>
                {desc}
              </Description>
              <LinkWrapper>
                <Button variant="green" size="fill" onClick={handleRedirect}>
                  {buttonText}
                </Button>
              </LinkWrapper>
            </Content>
          </StyledColumn>
        </Row>
      </Container>
    </StyledSection>
  );
};

export { DeclareNow };

const StyledSection = styled(Section)`
  padding-bottom: ${rem(80)};

  ${props => props.theme.breakpoints.tablet} {
    padding-bottom: ${rem(96)};
  }
  ${props => props.theme.breakpoints.desktop} {
    padding-bottom: ${rem(224)};
  }
`;

const Content = styled.div`
  ${props => props.theme.breakpoints.desktop} {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
`;

const Description = styled(Paragraph)`
  margin-bottom: ${props => props.theme.margins.base_x4};
  color: ${props => props.theme.colors.grey700};

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base_x5};
  }
`;

const LinkWrapper = styled.div`
  width: 100%;
  max-width: ${rem(328)};
`;
