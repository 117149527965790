/**
 * @author jakubbujakowski
 * @since 2019-8-21
 */

import * as React from "react";
import * as Sentry from "@sentry/browser";

interface Props {
  children?: React.ReactNode;
  fallbackUi: (params: { reportBug: () => void }) => React.ReactElement;
}

interface State {
  hasError: boolean;
  eventId: string;
}

class ErrorBoundary extends React.Component<Props, State> {
  public state: State = {
    hasError: false,
    eventId: "",
  };

  componentDidCatch(error: Error, errorInfo: { [k: string]: any }) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, hasError: true });
    });
  }

  private reportBug = () => {
    Sentry.showReportDialog({ eventId: this.state.eventId });
  };

  render() {
    if (this.state.hasError) {
      return this.props.fallbackUi({ reportBug: this.reportBug });
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
