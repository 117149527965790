import React from "react";

import { IS_BROWSER } from "@config/consts";

const eventType = ((): "touchstart" | "mousedown" => {
  // Recognize if the user doesn't use pointer devices
  if (
    IS_BROWSER &&
    window.matchMedia("(any-hover: none)").matches &&
    "TouchEvent" in window
  ) {
    return "touchstart";
  }

  return "mousedown";
})();

// todo: refactor hook usage to decrease rerenders
const useOnClickOutside = (callback: () => void) => {
  const ref = React.useRef<any>();

  React.useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target)) return;

      callback();
    };

    document.addEventListener(eventType, handleClick);

    return () => {
      document.removeEventListener(eventType, handleClick);
    };
  }, [ref, callback]);

  return ref;
};

export { useOnClickOutside };
