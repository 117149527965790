/**
 * @author jakubmaslon
 * @since 2021-04-19
 */

import * as React from "react";
import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";

import { Pencil } from "@ui/Assets/Symbolicons/Pencil";
import { IconVariant } from "@ui/Assets/Symbolicons/Symbolicons";
import { Newspaper } from "@ui/Assets/Symbolicons/Newspaper";
import { Map } from "@ui/Assets/Symbolicons/Map";
import { PhoneCall } from "@ui/Assets/Symbolicons/PhoneCall";
import { Info } from "@ui/Assets/Symbolicons/Info";
import { PageWithPencil } from "@ui/Assets/Symbolicons/PageWithPencil";
import { PageWithSearch } from "@ui/Assets/Symbolicons/PageWithSearch";
import { PageWithCheck } from "@ui/Assets/Symbolicons/PageWithCheck";
import { CalendarTime } from "@ui/Assets/Symbolicons/CalendarTime";
import { CreditCard } from "@ui/Assets/Symbolicons/CreditCard";
import { HomeHeart } from "@ui/Assets/Symbolicons/HomeHeart";
import { Computer } from "@ui/Assets/Symbolicons/Computer";
import { ContactCard } from "@ui/Assets/Symbolicons/ContactCard";
import { Pen } from "@ui/Assets/Symbolicons/Pen";
import { Phone } from "@ui/Assets/Symbolicons/Phone";
import { WashingMachine } from "@ui/Assets/Symbolicons/WashingMachine";
import { Paragraph } from "@ui/Atoms";
import { rem } from "@ui/helpers";
import theme from "@ui/themes/default";

export enum Icon {
  NEWSPAPER = "newspaper",
  PHONE_CALL = "phoneCall",
  MAP = "map",
  PAGE_WITH_PENCIL = "pageWithPencil",
  PAGE_WITH_SEARCH = "pageWithSearch",
  PAGE_WITH_CHECK = "pageWithCheck",
  CALENDAR_TIME = "calendarTime",
  CREDIT_CARD = "creditCard",
  HOME_HEART = "homeHeart",
  COMPUTER = "computer",
  CONTACT_CARD = "contactCard",
  WASHING_MACHINE = "washingMachine",
  PHONE = "phone",
  PENCIL = "pencil",
  PEN = "pen",
}

interface RenderIconParams {
  icon: Icon | undefined;
  iconsColor?: string;
  iconsSecondaryColor?: string;
  iconsSize?: number;
  variant?: IconVariant;
}

export const renderIcon = ({
  icon,
  iconsColor,
  iconsSecondaryColor,
  iconsSize,
  variant,
}: RenderIconParams): React.ReactElement => {
  const iconStyle = {
    size: iconsSize || 64,
    color: iconsColor || theme.colors.blue400,
    secondaryColor: iconsSecondaryColor || iconsColor || theme.colors.blue400,
  };

  const iconStyleScaled = {
    ...iconStyle,
    size: 8 + (iconsSize || 64),
  };

  switch (icon) {
    case Icon.NEWSPAPER:
      return <Newspaper {...iconStyle} />;
    case Icon.MAP:
      return <Map {...iconStyle} />;
    case Icon.PHONE_CALL:
      return <PhoneCall {...iconStyle} />;
    case Icon.PAGE_WITH_PENCIL:
      return <PageWithPencil {...iconStyle} />;
    case Icon.PAGE_WITH_SEARCH:
      return <PageWithSearch {...iconStyle} />;
    case Icon.PAGE_WITH_CHECK:
      return <PageWithCheck {...iconStyle} />;
    case Icon.CALENDAR_TIME:
      return <CalendarTime {...iconStyle} variant={variant || "line"} />;
    case Icon.CREDIT_CARD:
      return <CreditCard {...iconStyle} variant={variant || "line"} />;
    case Icon.HOME_HEART:
      return <HomeHeart {...iconStyle} variant={variant || "line"} />;
    case Icon.COMPUTER:
      return <Computer {...iconStyle} variant={variant || "line"} />;
    case Icon.CONTACT_CARD:
      return <ContactCard {...iconStyle} variant={variant || "line"} />;
    case Icon.WASHING_MACHINE:
      return <WashingMachine {...iconStyle} variant={variant || "solid"} />;
    case Icon.PHONE:
      return <Phone {...iconStyleScaled} variant={variant || "solid"} />;
    case Icon.PENCIL:
      return <Pencil {...iconStyle} variant={variant || "solid"} />;
    case Icon.PEN:
      return <Pen {...iconStyle} />;
    default:
      return <Info {...iconStyle} />;
  }
};

interface Step {
  title?: string;
  icon: Icon | undefined;
  markdownContent: string | undefined;
}

interface Props {
  steps: Step[];
  iconsColor?: string;
  numberBackground?: string;
  stepTextColor?: string;
  iconsSize?: number;
}

const Steps = (props: Props): React.ReactElement => (
  <StepsContainer>
    {props.steps.map((step, index) => (
      <Step key={index}>
        <IconContainer>
          {renderIcon({
            icon: step.icon,
            iconsColor: props.iconsColor,
            iconsSize: props.iconsSize,
          })}
        </IconContainer>
        <NumberWithLine
          isLast={index + 1 === props.steps.length}
          iconsColor={props.iconsColor}
        >
          <NumberWrapper numberBackground={props.numberBackground}>
            <Number iconsColor={props.iconsColor}>{index + 1}</Number>
          </NumberWrapper>
        </NumberWithLine>
        {step.title && <StepTitle>{step.title}</StepTitle>}
        <Text stepTextColor={props.stepTextColor}>
          <ReactMarkdown
            components={{
              p: Paragraph,
            }}
          >
            {step.markdownContent || ""}
          </ReactMarkdown>
        </Text>
      </Step>
    ))}
  </StepsContainer>
);

export { Steps };

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${theme.breakpoints.tablet} {
    flex-direction: row;
    width: 100%;
  }
`;

const Step = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: ${props => props.theme.margins.base_x4};

  &:last-child {
    padding-bottom: 0;
  }

  ${theme.breakpoints.tablet} {
    padding: 0;
    width: calc(100% / 3);
  }
`;

const StepTitle = styled.div`
  margin-bottom: ${props => props.theme.margins.base};
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${rem(64)};
`;

const NumberWrapper = styled.div<{ numberBackground?: string }>`
  position: relative;
  padding: ${props => props.theme.margins.base} 0;
  background: ${props =>
    props.numberBackground || props.theme.colors.greySurface};
  z-index: 1;

  ${theme.breakpoints.tablet} {
    padding: 0 ${props => props.theme.margins.base};
  }
`;

const NumberWithLine = styled.div<{ iconsColor?: string; isLast?: boolean }>`
  position: absolute;
  top: -${props => props.theme.margins.base};
  left: 0;
  height: 100%;

  ${props => props.theme.breakpoints.tablet} {
    position: relative;
    display: flex;
    top: 0;
    justify-content: center;
    height: auto;
    width: 100%;
    margin: ${props => props.theme.margins.base_x4} 0;
  }

  ${({ isLast, iconsColor }) =>
    !isLast &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        border-left: ${rem(1)} dashed
          ${props => iconsColor || props.theme.colors.blue400};

        ${theme.breakpoints.tablet} {
          content: "";
          top: 50%;
          left: 50%;
          width: 100%;
          height: 0;
          border-left: 0;
          border-top: ${rem(1)} dashed
            ${props => iconsColor || props.theme.colors.blue400};
        }
      }
    `};
`;

const Number = styled.div<{ iconsColor?: string }>`
  width: ${props => props.theme.margins.base_x4};
  height: ${props => props.theme.margins.base_x4};
  border-radius: 50%;
  border: ${rem(2)} solid
    ${props => props.iconsColor || props.theme.colors.blue400};
  color: ${props => props.iconsColor || props.theme.colors.blue400};
  font-weight: ${props => props.theme.fonts.weights.bold};
  text-align: center;
  font-size: ${props => props.theme.fonts.sizes.h6};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.div<{ stepTextColor?: string }>`
  width: 100%;
  max-width: ${rem(280)};
  margin: ${props => props.theme.margins.base_x2}
    ${props => props.theme.margins.base_x4} 0;
  padding: 0 ${props => props.theme.margins.base_x2};

  ${props =>
    props.stepTextColor &&
    css`
      > p {
        color: ${props.stepTextColor};
      }
    `}

  p {
    margin: 0;
    font-size: ${props => props.theme.fonts.sizes.subtitle};
  }

  a {
    font-weight: ${props => props.theme.fonts.weights.bold};
  }

  ${theme.breakpoints.tablet} {
    margin: 0 ${props => props.theme.margins.base_x4};
  }

  ${theme.breakpoints.desktop} {
    max-width: ${rem(320)};
    margin: 0;
    padding: 0 ${props => props.theme.margins.base_x4};
  }
`;
