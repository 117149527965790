import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  CORONAVIRUS_PANIC_MODE_ENABLED,
  CORONAVIRUS_PANIC_MODE_LU_ENABLED,
  IS_BROWSER,
  MODAL_DELAY,
} from "@config/consts";
import { ROUTES } from "@config/soft.routing";
import { USER_NOTIFICATIONS_TEST_IDS } from "@config/testIds/userNotifications";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";
import { AVAILABLE_COUNTRIES } from "@typings/globals";
import { MY_CLEANINGS_MODALS } from "@typings/clientDashboard";

import { AsyncActionReturnType } from "@services/AsyncActionCreatorFactory";

import { Markdown } from "@containers/Markdown";
import { hideToast, showToast } from "@containers/Toastify";

import { useTranslate } from "@hooks/useTranslate";
import { usePrevious } from "@hooks/usePrevious";

import { fetchUserNotifications } from "@soft/redux/userNotifications/userNotifications.actions";
import { getUserNotifications } from "@soft/redux/userNotifications/userNotifications.selectors";
import {
  isUserClient as isUserClientSelector,
  isUserAgent as isUserAgentSelector,
} from "@soft/redux/user/user.selectors";
import {
  fetchTrustMandate2020Urls,
  setHasSignedTrustMandateInDocusign,
} from "@soft/redux/userOptions/userOptions.actions";
import {
  getTrustMandateUrlsFetchedStatus,
  hasSignedTrustMandateInDocusign,
} from "@soft/redux/userOptions/userOptions.selectors";
import { getCountry } from "@soft/redux/localisation/localisation.selectors";
import { USER_NOTIFICATIONS } from "@soft/redux/userNotifications/userNotifications.typings";
import { useModalStore } from "@soft/hooks/useModalStore";

const MY_SCHEDULE_ROUTE = `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_DASHBOARD_MY_SCHEDULE}`;
const OPTIONS_ROUTE = `${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_DASHBOARD_OPTIONS}`;

const UserNotificationHandler = (): null => {
  const translate = useTranslate();
  const dispatch = useDispatch<ThunkActionDispatch>();
  const { showModal } = useModalStore();

  const isUserClient = useSelector(isUserClientSelector);
  const isUserAgent = useSelector(isUserAgentSelector);
  const country: AVAILABLE_COUNTRIES = useSelector(getCountry);
  const userNotificationsCollection = useSelector(getUserNotifications);
  const trustMandateUrlsFetched = useSelector(getTrustMandateUrlsFetchedStatus);
  const isReturningFromDocusign = useSelector(hasSignedTrustMandateInDocusign);

  const currentRoute = IS_BROWSER
    ? window.location.hash.split("#")[1]
    : undefined;

  const hasSignedTrustMandate2020 =
    IS_BROWSER &&
    window.location.href.toLowerCase().includes("documentsigned=true");

  const showWorkPermitUploadModal =
    IS_BROWSER &&
    window.location.href.includes("showWorkPermitUploadModal=true");

  const hasPendingPayments = userNotificationsCollection.get(
    USER_NOTIFICATIONS.PENDING_PAYMENTS_ALERT,
  );
  const hasNewBookingReminder = userNotificationsCollection.get(
    USER_NOTIFICATIONS.SHOW_NEW_BOOKING_REMINDER,
  );
  const showMissingTrustMandate2020 = userNotificationsCollection.get(
    USER_NOTIFICATIONS.SHOW_MISSING_TRUST_MANDATE_2020,
  );
  const hasEndOfTenancyReservationWithoutJobs = userNotificationsCollection.get(
    USER_NOTIFICATIONS.SHOW_END_OF_TENANCY_RESERVATION_WITHOUT_JOBS,
  );

  const prevUserNotificationsCollection = usePrevious(
    userNotificationsCollection,
  );

  const userNotificationsChanged =
    JSON.stringify(userNotificationsCollection) !==
    JSON.stringify(prevUserNotificationsCollection);

  const isMyScheduleRoute = currentRoute?.includes(MY_SCHEDULE_ROUTE);
  const isOptionsRoute = currentRoute?.includes(OPTIONS_ROUTE);

  let timeout: ReturnType<typeof setTimeout>;

  // Notifications
  const dispatchPendingPaymentsAlertNotification = (): void => {
    showToast(translate("batsoft_pwa_generic.pending_payments_alert"), {
      type: "dark",
      autoClose: false,
      toastId: USER_NOTIFICATIONS_TEST_IDS.PENDING_PAYMENTS_ALERT,
    });
  };

  const dispatchCoronavirusNotification = (): void => {
    showToast(
      <Markdown>
        {translate(
          `batsoft_pwa_generic.coronavirus_alert_${country.toLowerCase()}_md`,
        )}
      </Markdown>,
      {
        type: "dark",
        toastId: "COVID-19",
        autoClose: false,
      },
    );
  };

  const dispatchEndOfTenancyConfirmedNotification = (): void => {
    showToast(
      translate("batsoft_pwa_my_cleanings.end_of_tenancy_booking_confirmed"),
      {
        type: "dark",
        autoClose: false,
        toastId: USER_NOTIFICATIONS_TEST_IDS.END_OF_TENANCY_BOOKING_CONFIRMED,
      },
    );
  };

  const hideCoronavirusNotification = (): void => {
    hideToast("COVID-19");
  };

  const dispatchTrustMandateSignedNotification = (): void => {
    showToast(
      translate("batsoft_pwa_my_cleanings.trust_mandate2020_submitted_success"),
      {
        type: "dark",
        autoClose: false,
        toastId: USER_NOTIFICATIONS_TEST_IDS.TM2020_SIGNED,
      },
    );
  };

  // Modals
  const dispatchNewBookingReferralModal = (): void => {
    showModal(MY_CLEANINGS_MODALS.THANK_YOU_FOR_BOOKING, {
      id: MY_CLEANINGS_MODALS.THANK_YOU_FOR_BOOKING,
    });
  };

  const dispatchTrustMandateReminderModal = (): void => {
    showModal(MY_CLEANINGS_MODALS.TRUST_MANDATE_REMINDER, {
      id: MY_CLEANINGS_MODALS.TRUST_MANDATE_REMINDER,
    });
  };

  const dispatchWorkPermitUploadModal = (): void => {
    showModal(MY_CLEANINGS_MODALS.WORK_PERMIT_UPLOAD, {
      id: MY_CLEANINGS_MODALS.WORK_PERMIT_UPLOAD,
    });
  };

  const handleNotifications = (): void => {
    if (!isMyScheduleRoute) {
      return;
    }

    if (
      CORONAVIRUS_PANIC_MODE_ENABLED ||
      CORONAVIRUS_PANIC_MODE_LU_ENABLED(country)
    ) {
      dispatchCoronavirusNotification();
    } else {
      hideCoronavirusNotification();
    }

    if (showWorkPermitUploadModal) {
      dispatchWorkPermitUploadModal();
    }

    if (hasPendingPayments) {
      dispatchPendingPaymentsAlertNotification();
    }

    if (hasNewBookingReminder) {
      dispatchNewBookingReferralModal();
    }

    if (showMissingTrustMandate2020 && !isReturningFromDocusign) {
      timeout = setTimeout(
        () => dispatchTrustMandateReminderModal(),
        MODAL_DELAY,
      );
    }

    if (hasSignedTrustMandate2020) {
      dispatchTrustMandateSignedNotification();
    }

    if (hasEndOfTenancyReservationWithoutJobs) {
      dispatchEndOfTenancyConfirmedNotification();
    }
  };

  const handleNotificationsFetch = () => {
    (async () => {
      const { onError } = await dispatch<AsyncActionReturnType>(
        fetchUserNotifications(),
      );

      onError(err =>
        showToast(err.message, {
          type: "error",
        }),
      );
    })();
  };

  const handleTrustMandate2020UrlsFetch = () => {
    if (trustMandateUrlsFetched) {
      return;
    }

    (async () => {
      const { onError } = await dispatch<AsyncActionReturnType>(
        fetchTrustMandate2020Urls(),
      );
      onError(err => showToast(err.message, { type: "error" }));
    })();
  };

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  React.useEffect(() => {
    if (isUserClient && !isUserAgent) {
      if (isMyScheduleRoute || isOptionsRoute) {
        handleTrustMandate2020UrlsFetch();
      }
      handleNotificationsFetch();
    }
  }, [isUserClient, country, window.location.hash]);

  React.useEffect(() => {
    if (isUserClient && !isUserAgent) {
      handleNotifications();
    }
  }, [isUserClient, country, window.location.hash, userNotificationsChanged]);

  React.useEffect(() => {
    if (hasSignedTrustMandate2020) {
      dispatch(setHasSignedTrustMandateInDocusign(true));
    }
  }, [hasSignedTrustMandate2020]);

  return null;
};

export { UserNotificationHandler };
