import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Baseboards = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M20.5946 15.0857L20.5946 5.73682L11.6178 3.09868L4.53269 4.86003L4.53269 14.7209" />
      <path d="M3.70082 17.306L11.5402 15.4386L21.5695 18.3513" />
      <path d="M3.70082 20.8262L11.5402 18.9588L21.5695 21.8715" />
    </svg>
  );
};
