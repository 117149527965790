/**
 * @author tomekbuszewski
 * @since 2019-3-8
 */

import * as React from "react";
import styled from "styled-components";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  className?: string;
  children?: any;
  type?: "body" | "body2";
  weight?: "semibold" | "bold" | "normal" | "light";
  withSmallMargin?: boolean;
  noMarginBottom?: boolean;
  testId?: string;
  color?: string;
  hotjarAllow?: boolean;
  textAlignXs?: string;
  textAlignMd?: string;
  textAlignLg?: string;
}

const Paragraph = (props: Props) => (
  <TestWrapper testId={props.testId}>
    <ParagraphStyled
      type={props.type}
      weight={props.weight}
      withSmallMargin={props.withSmallMargin}
      noMarginBottom={props.noMarginBottom}
      className={props.className}
      data-hj-allow={props.hotjarAllow}
      color={props.color}
      textAlignXs={props.textAlignXs}
      textAlignMd={props.textAlignMd}
      textAlignLg={props.textAlignLg}
    >
      {props.children}
    </ParagraphStyled>
  </TestWrapper>
);

const ParagraphStyled = styled.p<Props>`
  line-height: ${props => props.theme.fonts.lineHeights.normal};
  font-size: ${props => props.theme.fonts.sizes[props.type!]};
  font-weight: ${props => props.theme.fonts.weights[props.weight!]};
  color: ${props => props.color || props.theme.colors.black700};
  margin-bottom: ${props => props.theme.margins.base_x2};

  ${props =>
    props.withSmallMargin &&
    `
    margin-bottom: ${props.theme.margins.base};
  `}

  ${props =>
    props.noMarginBottom &&
    `
    margin-bottom: 0;
  `}


  text-align: ${props => props.textAlignXs || "initial"};

  ${props => props.theme.breakpoints.tablet} {
    text-align: ${props => props.textAlignMd || "initial"};
  }

  ${props => props.theme.breakpoints.desktop} {
    text-align: ${props => props.textAlignLg || "initial"};
  }
`;

Paragraph.defaultProps = {
  type: "body2",
  weight: "normal",
} as Partial<Props>;

export { Paragraph };
