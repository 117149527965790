import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";

import { asyncActionCreator } from "@soft/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("payslips");

const FETCH_PAYSLIPS = action.make("fetch payslips");

const payslipActionTypes = {
  FETCH_PAYSLIPS,
};

const fetchPayslips =
  (year: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: "TODO", // TODO
      action: payslipActionTypes.FETCH_PAYSLIPS,
    });

    return dispatch(action);
  };

export { fetchPayslips, payslipActionTypes };
