import { Record, List, Map } from "immutable";

import {
  BookingTasks,
  KEY_PICK,
  CLEANING_FREQUENCY,
  BookingPricing,
  PART_OF_DAY,
  ChosenAgentWSortOrder,
  PLAN,
  IAgent,
} from "@typings/booking";
import {
  SicknessInsuranceCosts,
  SecondPillarRetirementCosts,
  SalaryDefaults,
  BookingSalary,
  SECOND_PILLAR_RETIREMENT,
  SICKNESS_INSURANCE,
} from "@typings/salary";
import { EmployeeDetails } from "@typings/contracts";

export const LOCAL_STORAGE_KEY = "store:work:booking";

export interface ReservationData {
  clientLocationId?: string;
  superGrossSalary?: 0;
  insurancePolicy?: boolean;
  insurancePolicyNumber?: string;
  insurancePolicyWillBeProvidedLater?: boolean;
  secondPillarRetirement?: SECOND_PILLAR_RETIREMENT;
  sicknessInsurance?: SICKNESS_INSURANCE;
  agreeToProfessionalAccidentInsurance?: boolean;
  agentUuid?: string | null;
  executionDateTime?: string | null;
}

export interface HourItem {
  duration: string;
  availableAgents: List<string>;
  partOfDay?: PART_OF_DAY;
}

export interface DayItemRaw {
  day: string;
  hours: HourItemRaw[];
}

export interface HourItemRaw {
  time: string;
  availableAgents: string[];
}

export interface BookingAvailabilityRaw {
  defaultSelection: string;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  days: DayItemRaw[];
}

export interface BookingAvailability {
  defaultSelection: string;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  days: List<string>;
  hours: Map<string, List<HourItem>>;
  hasNoAvailableDates: boolean;
}

export interface BookingReducerState {
  keyPick: KEY_PICK;
  locationComment: string | null;
  hasPets: boolean;
  serviceLocationId: number;
  specialInstructions: string | null;
  tasks: Record<BookingTasks>;
  frequency: CLEANING_FREQUENCY;
  pricing: Record<BookingPricing>;
  calendar: Record<BookingAvailability>;
  executionDateTime: string | null;
  reservationUuid: string | null;
  isExecutionDateTimeValidated: boolean;
  chosenAgentIds: List<string>;
  chosenAgents: MapOfChosenAgents;
  chosenAgentId: string | null;
  prevSicknessInsurance: SICKNESS_INSURANCE;
  sicknessInsurance: SICKNESS_INSURANCE;
  sicknessInsuranceCosts: Record<SicknessInsuranceCosts>;
  professionalAccidentInsurance: boolean;
  agreeToProfessionalAccidentInsurance: boolean;
  insurancePolicyNumber: string;
  insurancePolicyWillBeProvidedLater: boolean;
  prevSecondPillarRetirement: SECOND_PILLAR_RETIREMENT;
  secondPillarRetirement: SECOND_PILLAR_RETIREMENT;
  secondPillarRetirementCosts: Record<SecondPillarRetirementCosts>;
  salaryDefaults: Record<SalaryDefaults>;
  salaryDefaultsFetched: boolean;
  salary: Record<BookingSalary>;
  sliderSuperGrossSalary: number;
  sliderChangeCount: number;
  queryParams: string;
  locationUuid: string;
  mainLocationUuid: string;
  form: Record<BookingRegistrationForm>;
  hasSomePaymentAliases: boolean;
  plan: PLAN;
  isCheckoutDone: boolean;
  previousEmployee: Record<EmployeeDetails> | null;
  hasPreviousActiveContract: boolean;
  relaxPlanRedirectUrl: string | null;
  isRelaxPlanCompleted: boolean;
  previousAgents: List<Record<IAgent>>;
}

export interface BookingRegistrationForm {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  address: string;
  serviceLocationId: number;
  floorAndDoor: string;
  entryCode: string;
  birthDate: string;
  additionalNumber: string;
}

export type MapOfChosenAgents = Map<string, Record<ChosenAgentWSortOrder>>;

export type BookingReducer = Record<BookingReducerState>;
