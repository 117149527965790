/**
 * @author tomekbuszewski
 * @since 2019-6-18
 */

import * as React from "react";
import styled from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  children?: React.ReactNode;
  theme: ITheme;
}

const List = styled.ul<Props>`
  margin: ${props => `${props.theme.margins.base_x2} 0`};
  padding: 0;
  list-style-position: inside;
  list-style-type: none;

  li {
    padding: ${props => `${props.theme.margins.half} 0`};
  }
`;

List.displayName = "List";

const NormalizedList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

NormalizedList.displayName = "NormalizedList";

export { List, NormalizedList };
