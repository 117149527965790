import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";

const getServiceLocationInfoFunction = (store: IStore) => store.serviceLocation;

export const getServiceLocationInfo = createSelector(
  getServiceLocationInfoFunction,
  f => f,
);
