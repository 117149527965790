import React from "react";
import styled, { css } from "styled-components";
import { FormikHandlers } from "formik";

import { rem, transition } from "@ui/helpers";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

type ToggleSwitchVariant = "auto" | "full";

interface Props {
  name: string;
  testId?: string;
  className?: string;
  variant?: ToggleSwitchVariant;
  value: any;
  checked: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onChange: (
    e: React.SyntheticEvent<HTMLInputElement> | FormikHandlers["handleChange"],
  ) => void;
}

const ToggleSwitch = React.memo((props: Props): React.ReactElement => {
  const variant = props.variant || "auto";

  return (
    <TestWrapper testId={props.testId}>
      <Container className={props.className} variant={variant}>
        <Content>{props.children}</Content>

        <ToggleUI isChecked={props.checked} isDisabled={props.disabled}>
          <HiddenCheckbox
            name={props.name}
            type="checkbox"
            value={props.value}
            checked={props.checked}
            disabled={props.disabled}
            onChange={props.onChange}
          />
        </ToggleUI>
      </Container>
    </TestWrapper>
  );
});

ToggleSwitch.displayName = "ToggleSwitch";

export { ToggleSwitchVariant, ToggleSwitch };

const Content = styled.span`
  flex: 1 1 100%;

  ${props => props.theme.breakpoints.mobile} {
    flex: 0 1 auto;
  }
`;

const Container = styled.div<{ variant: ToggleSwitchVariant }>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: ${props => props.theme.margins.base_x2};
  user-select: none;

  ${Content} {
    ${props =>
      props.variant === "full" &&
      css`
        flex: 1 1 100%;
      `}
  }
`;

const ToggleUI = styled.label<{ isChecked: boolean; isDisabled?: boolean }>`
  --toggle-switch-width: ${rem(42)};
  --toggle-switch-height: ${rem(24)};
  --toggle-switch-border: ${rem(2)};

  position: relative;
  width: var(--toggle-switch-width);
  height: var(--toggle-switch-height);
  margin: 0;
  border-radius: calc(var(--toggle-switch-height) / 2);
  background-color: ${props => props.theme.colors.grey300};
  transition: ${transition("background-color")};
  flex: 0 0 auto;
  cursor: pointer;

  ${props =>
    props.isDisabled &&
    css`
      background-color: ${props => props.theme.colors.grey200};
      pointer-events: none;
    `}

  ${props =>
    props.isChecked &&
    css`
      background-color: ${props => props.theme.colors.blue400};
    `}

  ${props =>
    props.isChecked &&
    props.isDisabled &&
    css`
      background-color: ${props => props.theme.colors.blue200};
    `}

  &::after {
    --toggle-switch-size: calc(
      var(--toggle-switch-height) - 2 * var(--toggle-switch-border)
    );

    content: "";
    position: absolute;
    top: var(--toggle-switch-border);
    left: var(--toggle-switch-border);
    width: var(--toggle-switch-size);
    height: var(--toggle-switch-size);
    border-radius: calc(var(--toggle-switch-size) / 2);
    background-color: ${props => props.theme.colors.white};
    transform: translate(0, 0);
    transition: ${transition("transform")};

    ${props =>
      props.isChecked &&
      css`
        transform: translate(
          calc(
            var(--toggle-switch-width) - var(--toggle-switch-border) * 2 - 100%
          ),
          0
        );
      `}
  }
`;

const HiddenCheckbox = styled.input`
  position: absolute;
  inset: 0;
  visibility: hidden;
`;
