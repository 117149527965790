import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import React from "react";
import styled, { useTheme } from "styled-components";

import { Container, Heading } from "@ui/Atoms";
import { DraggableCarousel } from "@ui/Molecules/DraggableCarousel";
import { rem } from "@ui/helpers";
import { Star } from "@ui/Assets/Symbolicons/Star";

import { PrismicGlobalContentPageDataBodyTestimonials } from "../../../graphql-types";
import { StyledSection } from "../Homepage/shared";

const slidesToShow = {
  mobile: 1,
  tablet: 4,
  desktop: 4,
};

const arrayToFive = Array.from(Array(5).keys());

const TestimonialsSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyTestimonials>,
): React.ReactElement => {
  const theme = useTheme();

  const { primary, items } = props.slice;

  return (
    <Section backgroundColor={theme.colors.white}>
      <Container widthVariant="fullMobile">
        <StyledHeading type="h2" styleAs="h5">
          {primary?.title1?.text}
        </StyledHeading>

        <DraggableCarousel hideButtons slidesToShow={slidesToShow}>
          {items?.map((testimonial, index: number) => (
            <Testimonial key={index}>
              <TestimonialContent>
                {arrayToFive.map((_, index) => (
                  <Star
                    key={index}
                    size={16}
                    color={
                      Number(testimonial?.stars) >= index + 1
                        ? theme.colors.gold
                        : theme.colors.grey500
                    }
                  />
                ))}
              </TestimonialContent>
              <TestimonialContent>
                <PrismicRichText field={testimonial.description?.richText} />
              </TestimonialContent>
              <PrismicRichText field={testimonial.author?.richText} />
            </Testimonial>
          ))}
        </DraggableCarousel>

        <ReadMore>
          <PrismicRichText field={primary.read_more?.richText} />
        </ReadMore>
      </Container>
    </Section>
  );
};

export { TestimonialsSlice };

const Section = styled(StyledSection)`
  padding: ${props =>
    `0 ${props.theme.margins.base_x3} ${props.theme.margins.base_x10}`};
  overflow: hidden;

  ${props => props.theme.breakpoints.tablet} {
    padding: ${rem(96)} 0 ${props => props.theme.margins.base_x10};
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base_x4};
  text-align: left;
  font-weight: ${props => props.theme.fonts.weights.bold};

  ${props => props.theme.breakpoints.tablet} {
    text-align: center;
  }
`;

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${props => props.theme.colors.greySurface};
  border-radius: ${props => props.theme.border.radius.base};
  padding: ${props => props.theme.margins.base_x2};

  p {
    margin: 0;
  }
`;

const TestimonialContent = styled.div`
  margin-bottom: ${props => props.theme.margins.base_x2};

  &:last-of-type {
    display: flex;
    flex: 1;
  }
`;

const ReadMore = styled.div`
  margin-top: ${props => props.theme.margins.base_x6};
  text-align: center;

  > p {
    margin: 0;
  }
`;
