/**
 * @author michalstolarski
 * @since 2023-9-7
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { rgba } from "@ui/helpers";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  testId?: string;
  className?: string;
  children: React.ReactNode;
  hasShadow?: boolean;
  hasOverflowHidden?: boolean;
}

const Container = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <CardWrapper className={props.className} hasShadow={props.hasShadow}>
      {props.children}
    </CardWrapper>
  </TestWrapper>
);

const Heading = styled.div`
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${props => props.theme.fonts.lineHeights.normal};
  color: currentColor;
`;

export const Card = {
  Container,
  Heading,
};

const CardWrapper = styled.div<Pick<Props, "hasShadow" | "hasOverflowHidden">>`
  --card-box-shadow--color: ${props => rgba(props.theme.colors.black, 0.08)};
  --card-box-shadow: 0px 2px 12px 0px var(--card-box-shadow--color),
    0px 1px 2px 0px var(--card-box-shadow--color);

  position: relative;
  padding: ${props => props.theme.margins.base_x4};
  border-radius: ${props => props.theme.border.radius.base};
  background-color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.margins.base_x3};

  ${props =>
    props.hasShadow &&
    css`
      box-shadow: var(--card-box-shadow);
    `}

  ${props =>
    props.hasOverflowHidden &&
    css`
      overflow: hidden;
    `}
`;
