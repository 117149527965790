import * as React from "react";
import { useTheme } from "styled-components";

import { style, Icon } from "./Symbolicons";

export const UserHeart = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <path
        fill={props.color || theme.colors.grey700}
        fillRule="evenodd"
        d="M12.445 6.222a4.889 4.889 0 110 9.778 4.889 4.889 0 010-9.778zm11.11 10.667c1.907 0 3.112 1.505 3.112 3.378 0 2.326-2.293 4.406-5.015 5.452a.889.889 0 01-.637 0C18.293 24.673 16 22.593 16 20.267c0-1.873 1.205-3.378 3.111-3.378.808 0 1.397.185 1.972.577l.25.185.036-.028c.518-.401 1.027-.631 1.678-.706l.287-.023.222-.005zm-11.11.889c.384 0 .772.028 1.184.083a.889.889 0 01-.235 1.762 7.154 7.154 0 00-.95-.068c-2.909 0-5.215 1.715-5.328 3.734l-.005.155v1.445a.889.889 0 01-1.772.104l-.006-.104v-1.445c0-3.184 3.233-5.666 7.112-5.666zm9.514 1.698c.615-.61.961-.81 1.597-.81.826 0 1.333.634 1.333 1.6l-.006.144-.028.199c-.237 1.197-1.664 2.495-3.41 3.271l-.111.049-.113-.048-.27-.126c-1.785-.866-3.173-2.264-3.173-3.488 0-.967.507-1.6 1.333-1.6.636 0 .982.2 1.597.809a.889.889 0 001.251 0zM9.334 11.11a3.111 3.111 0 116.222 0 3.111 3.111 0 01-6.222 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};
