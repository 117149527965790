import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const SendFastCircle = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M2.99625 11.9998C2.99625 7.02721 7.02736 2.99609 12 2.99609C16.9726 2.99609 21.0037 7.02721 21.0037 11.9998C21.0037 16.9725 16.9726 21.0036 12 21.0036" />
      <path d="M3.99667 20.0032L6.99792 17.002" />
      <path d="M7.99832 21.0043L8.99874 20.0039" />
      <path d="M2.99625 16.0014L3.99666 15.001" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.10795 12.1879L10.3381 13.1255C10.5792 13.2268 10.771 13.4186 10.8724 13.6597L11.8117 15.8933C11.976 16.2833 12.3678 16.5278 12.7903 16.504C13.2128 16.4802 13.5747 16.1931 13.694 15.7871L15.4621 9.77984C15.5655 9.42862 15.4688 9.04894 15.2099 8.79003C14.951 8.53113 14.5713 8.4344 14.2201 8.53785L8.21395 10.3056C7.80791 10.4249 7.52087 10.7868 7.49708 11.2094C7.47328 11.6319 7.71789 12.0237 8.10795 12.1879Z"
      />
    </svg>
  );
};
