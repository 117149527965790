/**
 * @author michalstolarski
 * @since 2023-10-25
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

import { Markdown } from "@containers/Markdown";

interface Props {
  testId?: string;
  className?: string;
  name: string;
  description: string;
  variant?: "modal" | "tooltip";
}

const ChecklistExplanationPriority = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <Container className={props.className} variant={props.variant}>
      <div>
        <strong>{props.name}</strong>
      </div>

      <div>
        <Markdown>{props.description}</Markdown>
      </div>
    </Container>
  </TestWrapper>
);

export { ChecklistExplanationPriority };

const Container = styled.div<Pick<Props, "variant">>`
  ${props =>
    props.variant === "modal" &&
    css`
      padding: ${props => props.theme.margins.base};
    `}

  ${props =>
    props.variant === "tooltip" &&
    css`
      padding: ${props => props.theme.margins.base_x2}
        ${props => props.theme.margins.base_x1_5};
    `}

  strong {
    font-weight: ${props => props.theme.fonts.weights.semibold};
  }
`;
