import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const UserCircle = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
    className={props.className}
  >
    <path
      fill={props.color || theme.colors.grey700}
      fillRule="evenodd"
      d="M12 15.482c2.223 0 4.22.851 4.997 2.182a.667.667 0 01-1.11.737l-.042-.065c-.501-.86-2.052-1.52-3.845-1.52-1.729 0-3.233.614-3.787 1.428l-.058.092a.667.667 0 01-1.152-.672c.776-1.33 2.774-2.182 4.997-2.182zm0-11.333a8 8 0 018 8c0 1.49-.416 2.924-1.187 4.178a.667.667 0 01-1.136-.698 6.62 6.62 0 00.99-3.48 6.667 6.667 0 00-13.334 0c0 1.24.346 2.433.99 3.48a.667.667 0 01-1.136.699A7.958 7.958 0 014 12.148a8 8 0 018-8zm0 3.333a3.333 3.333 0 110 6.667 3.333 3.333 0 010-6.667zm0 1.333a2 2 0 100 4 2 2 0 000-4z"
    ></path>
  </svg>
);
