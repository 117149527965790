import { IssuesMap } from "@typings/rating";

import { nullifyString } from "@services/NullifyString";

import { IStore } from "@soft/redux/reducers";

import { getRatingState } from "./rating.selectors";

const getSelectedIssues = (issues: IssuesMap) =>
  issues.valueSeq()?.reduce((issues, currIssue) => {
    if (!currIssue?.get("selected")) {
      return issues;
    }

    return [
      ...issues,
      {
        key: currIssue.get("key"),
        subIssues: currIssue
          .get("subIssues")
          ?.valueSeq()
          ?.reduce((subIssues, currSubIssue) => {
            if (currSubIssue.get("selected")) {
              return [
                ...subIssues,
                {
                  key: currSubIssue.get("key"),
                },
              ];
            }
            return subIssues;
          }, []),
      },
    ];
  }, []);

const getRatingEndpointRequest = (store: IStore) => {
  const ratingState = getRatingState(store);
  const rating = ratingState.get("rating");

  const issues = ratingState
    .get("negativeRatings")
    .includes(ratingState.get("rating"))
    ? ratingState.get("negativeIssues")
    : ratingState.get("positiveIssues");

  return {
    rating,
    issues: getSelectedIssues(issues),
    comment: nullifyString(ratingState.get("comment")),
    shareWithAgent: ratingState.get("shareWithAgent"),
  };
};

export { getRatingEndpointRequest };
