import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Timer = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <circle cx="12" cy="13" r="8" />
      <path d="M12.9085 8.09155C11.4472 7.80655 9.93523 8.19309 8.79007 9.14444C7.6449 10.0958 6.98772 11.5113 7 13" />
      <path d="M15.5356 9.46445L13.0607 11.9393" />
      <circle cx="12" cy="13" r="1.5" />
      <path d="M10 2H14" />
      <path d="M20 5L17.66 7.34" />
      <path d="M19 4L21 6" />
    </svg>
  );
};
