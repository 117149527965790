import React from "react";
import { PageProps } from "gatsby";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { SystemError } from "@ui/Organisms/SystemError";

import { resolveCountryFromUrl } from "@services/ResolveCountryFromUrl";
import { langFromPathname } from "@services/LangFromPathname";

import { useTranslate } from "@hooks/useTranslate";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { linkResolver } from "../config/linkResolver";
import Homepage from "../templates/Homepage";

type Props = PageProps<any, { lang?: string }>;

const NotFoundPage = (props: Props): React.ReactElement => {
  const translate = useTranslate("batmaid_pwa_generic");

  const country = process.env.COUNTRY || resolveCountryFromUrl();
  const language = props.pageContext.lang || langFromPathname();

  return (
    <Layout
      origin={process.env.ORIGIN as ORIGIN}
      country={country as AVAILABLE_COUNTRIES}
      language={language}
    >
      <SEO title="404: Not found" />
      <SystemError
        htmlMetaTitleText={translate("404_meta_title")}
        titleText={translate("404_title")}
        bodyText={translate("404_body")}
        btnText={translate("404_btn_text")}
        btnHref={`/${language}`}
        statusCode={404}
      />
    </Layout>
  );
};

export default NotFoundPage;
