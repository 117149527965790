import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const QuestionCircle = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <circle cx="12" cy="11.9998" r="9.00375" />
      <path d="M9.68469 9.68482C9.92172 8.66326 10.8522 7.95496 11.8999 7.99844C13.0726 7.93349 14.0779 8.82709 14.1509 9.99928C14.1509 11.5038 12 12.0001 12 13.0005" />
      <path d="M12.125 15.7522C12.125 15.8212 12.0691 15.8772 12 15.8772C11.9309 15.8772 11.8749 15.8212 11.8749 15.7522C11.8749 15.6831 11.9309 15.6271 12 15.6271" />
      <path d="M12 15.6262C12.0691 15.6262 12.1251 15.6821 12.1251 15.7512" />
    </svg>
  );
};
