import * as Yup from "yup";

import { IS_DEV } from "@config/consts";
import {
  SERVICE_LOCATIONS_ENDPOINT,
  ZIP_SUGGESTION_ENDPOINT,
} from "@config/endpoints";

import { LOADING_STATE } from "@typings/reduxThunkActions";
import { AVAILABLE_LANGS, HERO_TYPES, SERVICE_TYPE } from "@typings/globals";

import { SelectOption } from "@ui/Atoms/Form/SelectExtended";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { buildBookingUrl } from "@services/ResolveBookingRoute";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { buildUrl } from "@services/BuildUrl";

import { TranslateFunction } from "@hooks/useTranslate";

import { showGenericErrorToast } from "@app/containers/Toastify";

import { asyncActionCreator } from "../../services/AsyncActionCreator";

export enum FIELDS {
  ZIPCODE = "zip",
}

export const initialValues = {
  [FIELDS.ZIPCODE]: "",
};

interface HandleSubmitParams {
  onSuccess: (url: string, zip: string) => void;
  zip: string;
  showUncoveredLocationModal: (uncoveredCode: string) => void;
  language: AVAILABLE_LANGS;
  serviceType?: SERVICE_TYPE;
  hasSuggestions: boolean;
  zipSuggestionsFetchingState: LOADING_STATE;
}

export const handleSubmit = async ({
  zip,
  onSuccess,
  showUncoveredLocationModal,
  language,
  serviceType,
  hasSuggestions,
  zipSuggestionsFetchingState,
}: HandleSubmitParams): Promise<void> => {
  if (!zip.trim()) return;

  // do nothing when suggestions fetching is in progress
  if (zipSuggestionsFetchingState !== LOADING_STATE.SUCCEEDED) {
    return;
  }

  // `zipCode is blocked for this serviceType` case (this zipCode will work in fetchServiceLocationCoverage)
  if (!hasSuggestions) {
    showUncoveredLocationModal(zip);
    return;
  }

  try {
    const checkZip = await fetchServiceLocationCoverage(zip);

    checkZip.onError(() => {
      showUncoveredLocationModal(zip);
      return;
    });

    checkZip.onSuccess(payload => {
      const { zip } = payload.data;

      onSuccess(
        buildBookingUrl({
          serviceType,
          language,
          zip,
        }),
        zip,
      );
    });
  } catch {
    showGenericErrorToast();
  }
};

interface HandleOnInputChange {
  value: string;
  setFieldValue: (field: string, value: string) => void;
  setZipSuggestions: (value: SelectOption[]) => void;
  setZipSuggestionsFetchingState: (state: LOADING_STATE) => void;
  serviceType: SERVICE_TYPE;
  setLoadingAbortController: (controller: AbortController) => void;
}

export const handleOnInputChange = async ({
  value,
  setFieldValue,
  serviceType,
  setZipSuggestions,
  setZipSuggestionsFetchingState,
  setLoadingAbortController,
}: HandleOnInputChange): Promise<void> => {
  setFieldValue(FIELDS.ZIPCODE, value);

  if (!value.trim()) {
    return;
  }

  try {
    const controller = new AbortController();
    const signal = controller.signal;

    setZipSuggestionsFetchingState(LOADING_STATE.LOADING);
    setLoadingAbortController(controller);

    const resp = await fetchSuggestions({ query: value, serviceType, signal });

    resp.onSuccess(({ data }) => {
      const suggestions = data.map(zipSuggestionFactory);
      const inputValue = data.length === 1 ? data[0].zip : value;

      setFieldValue(FIELDS.ZIPCODE, inputValue);
      setZipSuggestions(suggestions);
      setZipSuggestionsFetchingState(LOADING_STATE.SUCCEEDED);
    });
  } catch (error) {
    /**
     * Intentional silent errors.
     * Zip code suggestions are an optional feature.
     * We don't want to spam with error toasts on the Homepage if these requests fail.
     */
    IS_DEV && error?.name !== "AbortError" && console.error(error);
    setZipSuggestionsFetchingState(LOADING_STATE.FAILED);
  }
};

export const getServiceType = (
  heroType: HERO_TYPES | SERVICE_TYPE | undefined,
): SERVICE_TYPE => {
  switch (heroType) {
    case SERVICE_TYPE.B2B:
    case HERO_TYPES.OFFICE_CLEANING:
      return SERVICE_TYPE.B2B;
    case HERO_TYPES.END_OF_TENANCY:
    case SERVICE_TYPE.END_OF_TENANCY:
      return SERVICE_TYPE.END_OF_TENANCY;
    default:
      return SERVICE_TYPE.HOME_CLEANING;
  }
};

export const getValidationSchema = (
  translate: TranslateFunction,
): Yup.ObjectSchema =>
  Yup.object().shape({
    [FIELDS.ZIPCODE]: Yup.string()
      .trim()
      .required(translate("batmaid_pwa_generic.validate_is_required")),
  });

export interface SuggestionsRawResponse {
  city: string;
  zip: string;
  hideCityName: boolean;
}

const zipSuggestionFactory = (
  suggestion: SuggestionsRawResponse,
): SelectOption => ({
  value: suggestion.zip,
  label: suggestion.hideCityName
    ? suggestion.zip
    : `${suggestion.zip} - ${suggestion.city}`,
});

interface FetchSuggestionsProps {
  query: string;
  serviceType: SERVICE_TYPE;
  signal: AbortSignal;
}

const fetchSuggestions = ({
  query,
  serviceType,
  signal,
}: FetchSuggestionsProps): Promise<
  AsyncActionResult<SuggestionsRawResponse[]>
> => {
  return asyncActionCreator(
    {
      action: "",
      method: ASYNC_ACTION_TYPES.GET,
      url: buildUrl(ZIP_SUGGESTION_ENDPOINT)
        .setQuery("q", query.trim())
        .setQuery("type", serviceType)
        .done(),
    },
    {
      signal,
    },
  );
};

/**
 * Based on the `fetchServiceLocationCoverage` from `app/redux/serviceLocation/serviceLocation.actions.ts`
 * Checks if zip code is covered by our cleaning services.
 * When `serviceType` is undefined backend checks for both Home Cleanings and End of Tenancy.
 */
const fetchServiceLocationCoverage = (
  zip: string,
): Promise<AsyncActionResult> => {
  return asyncActionCreator({
    action: "",
    method: ASYNC_ACTION_TYPES.GET,
    url: buildUrl(SERVICE_LOCATIONS_ENDPOINT)
      .setQuery("zip", zip.trim(), { optional: false })
      .setQuery("serviceType", "", { optional: true })
      .done(),
  });
};
