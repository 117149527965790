/**
 * @author jakubbujakowski
 * @since 2019-11-21
 */

import * as React from "react";
import ReactTooltip from "react-tooltip";
import styled, { css } from "styled-components";

import { IS_BROWSER } from "@config/consts";

import { rem } from "@ui/helpers";

interface Offset {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

interface Props {
  children: React.ReactElement;
  placeholder?: string;
  position?: "top" | "right" | "bottom" | "left";
  id?: string;
  testId?: string;
  offset?: Offset;
  delayShow?: number;
  backgroundColor?: string;
  textColor?: string;
  tooltipChildren?: React.ReactElement;
  disabled?: boolean;
  withShadow?: boolean;
  isVisible?: boolean;
  isClickable?: boolean;
}

const Tooltip = (props: Props): React.ReactElement => {
  const tooltipRef = React.useRef<Element | null>(null);

  if (React.Children.count(props.children) > 1) {
    throw new Error("Only one child is allowed within Tooltip.");
  }

  React.useEffect(() => {
    if (props.isVisible && tooltipRef && tooltipRef.current) {
      ReactTooltip.show(tooltipRef.current);
    }
  }, [props.isVisible]);

  return (
    <React.Fragment>
      {React.Children.only(
        React.cloneElement(props.children, {
          "data-tip": props.placeholder,
          "data-for": props.id,
          ref: tooltipRef,
        }),
      )}
      {!props.disabled && (
        <StyledTooltip
          place={props.position}
          effect="solid"
          multiline
          clickable={props.isClickable}
          id={props.id}
          disable={props.disabled}
          offset={props.offset}
          delayShow={props.delayShow}
          backgroundColor={props.backgroundColor}
          textColor={props.textColor}
          withShadow={props.withShadow}
        >
          {IS_BROWSER && props.tooltipChildren}
        </StyledTooltip>
      )}
    </React.Fragment>
  );
};

Tooltip.displayName = "Tooltip";
Tooltip.defaultProps = {
  isClickable: true,
} as Partial<Props>;

export { Tooltip };

const StyledTooltip = styled(ReactTooltip)<{ withShadow?: boolean }>`
  max-width: ${rem(264)};
  font-size: ${props => props.theme.fonts.sizes.caption};
  font-weight: ${props => props.theme.fonts.weights.normal};
  line-height: ${props => props.theme.fonts.lineHeights.caption};
  padding: ${props =>
    `${props.theme.margins.base} ${props.theme.margins.base_x2}`};
  opacity: 1 !important;

  ${props =>
    props.withShadow &&
    css`
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
    `}

  &.place-top {
    &::before {
      z-index: -1 !important;
    }
  }
`;
