import * as React from "react";
import styled from "styled-components";

import { NAVBAR } from "@config/testIds/navbar";

import { APP_NAMES } from "@typings/globals";

import { TestWrapper } from "@ui/Atoms";
import { getBackgroundHoverPerApp, rem, transition } from "@ui/helpers";

interface NavbarToggleProps {
  active?: boolean;
  className?: string;
  onClick: (e: React.SyntheticEvent) => void;
  variant: APP_NAMES;
  label: string;
}

const NavbarToggleComponent = (props: NavbarToggleProps) => (
  <TestWrapper testId={NAVBAR.HAMBURGER_DROPDDOWN}>
    <button
      className={props.className}
      onClick={props.onClick}
      aria-label={props.label}
      aria-expanded={props.active}
    >
      <span />
      <span />
      <span />
    </button>
  </TestWrapper>
);

const NavbarToggle = styled(NavbarToggleComponent)`
  border-radius: ${props => props.theme.buttons.borderRadius};
  background: transparent;
  width: ${rem(40)};

  margin-left: ${props => props.theme.margins.base};
  height: 100%;

  position: relative;

  &:focus {
    background: ${props => getBackgroundHoverPerApp(props.variant)};
  }

  &:active,
  &:focus {
    outline: 0;
  }

  span {
    display: block;
    border-bottom: ${rem(2)} solid
      ${props =>
        props.variant === APP_NAMES.BATWORK
          ? props.theme.colors.grey700
          : props.theme.colors.greyDark};

    transition: ${transition(["opacity", "transform"])};

    margin: ${props => props.theme.margins.half} auto;
    width: ${rem(16)};

    &:nth-of-type(1) {
      transform: ${props =>
        props.active && `translateY(${rem(8)}) rotate(45deg)`};
    }

    &:nth-of-type(2) {
      opacity: ${props => props.active && 0};
    }

    &:nth-of-type(3) {
      transform: ${props =>
        props.active && `translateY(-${rem(4)}) rotate(-45deg)`};
    }
  }

  ${props => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export { NavbarToggle };
