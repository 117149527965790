import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const TimeClockEdit = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M12 21.004c-5.002 0-9.004-4.102-9.004-9.004 0-4.902 4.102-9.004 9.004-9.004 4.602 0 8.404 3.502 8.904 8.003" />
      <path
        clipRule="evenodd"
        d="m15.301 17.302 3.502-3.502c.4-.4 1-.4 1.4 0v0l1.501 1.5c.4.4.4 1.001 0 1.401v0l-3.501 3.502c-.2.2-.4.3-.7.3H15v-2.501c0-.2.1-.5.3-.7Z"
      />
      <path d="m8.599 14.9 3.701-2.3V7.998" />
    </svg>
  );
};
