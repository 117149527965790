/**
 * @author jakubbujakowski
 * @since 2019-10-16
 */

import { applyMiddleware, compose, createStore, Middleware } from "redux";
import thunk from "redux-thunk";

import { IS_BROWSER, REDUX_STATE_KEY_WORK } from "@config/consts";

import { combinedReducers } from "./reducers";

const middleware: Middleware[] = [thunk];
const win = IS_BROWSER ? (window as any) : null;

const initialState = IS_BROWSER ? win[REDUX_STATE_KEY_WORK] || {} : {};
const composeEnhancers =
  (win && win.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const makeStore = () => {
  const store = createStore(
    combinedReducers,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  return store;
};

export default makeStore();
