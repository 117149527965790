import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Google = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 95.39 30"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <path
      fill={props.color || theme.colors.grey700}
      d="M12 10.58v3.36h7.63a6.82 6.82 0 01-1.75 4 7.86 7.86 0 01-5.93 2.35 8.42 8.42 0 01-8.45-8.53 8.42 8.42 0 018.41-8.54 8.16 8.16 0 015.78 2.3L20 3.26A11.08 11.08 0 0012 0 11.9 11.9 0 000 11.74a11.91 11.91 0 0011.91 11.75 10.77 10.77 0 008.2-3.29 10.63 10.63 0 002.78-7.5 12.06 12.06 0 00-.17-2.12zm20.48-2.5a7.47 7.47 0 00-7.61 7.58 7.61 7.61 0 0015.22 0 7.47 7.47 0 00-7.61-7.58m0 12.17a4.38 4.38 0 01-4.28-4.59 4.29 4.29 0 118.56 0 4.38 4.38 0 01-4.28 4.59M69.9 9.83h-.11a5.18 5.18 0 00-3.94-1.68 7.28 7.28 0 00-7 7.49 7.3 7.3 0 007 7.48 5.12 5.12 0 003.94-1.7h.11v1.15S68.22 27 65.73 27a4.22 4.22 0 01-3.83-2.7L59 25.52A7.22 7.22 0 0065.73 30c3.9 0 7.2-2.3 7.16-7.89V8.71h-3zM66 20.25c-2.3 0-4.05-2-4.05-4.59s1.75-4.59 4.05-4.59 4 1.95 4 4.62-1.78 4.56-4 4.56M49.44 8.08a7.46 7.46 0 00-7.6 7.58 7.61 7.61 0 0015.21 0 7.47 7.47 0 00-7.61-7.58m0 12.17a4.39 4.39 0 01-4.28-4.59 4.29 4.29 0 118.56 0 4.38 4.38 0 01-4.28 4.59M75.51.48h3.36v22.8h-3.36zm13.42 19.77A3.86 3.86 0 0185.25 18l10.14-4.19-.34-.86a7 7 0 00-6.49-4.83c-3.9 0-7.15 3.06-7.15 7.58a7.41 7.41 0 007.52 7.58 7.6 7.6 0 006.32-3.35l-2.59-1.73a4.32 4.32 0 01-3.73 2.05zm-.24-9.33a3 3 0 012.88 1.67l-6.86 2.83c0-3.18 2.26-4.5 4-4.5"
    ></path>
  </svg>
);
