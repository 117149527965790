import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const LivingRoomColorfulAsteriskSofa = (
  props: Icon,
): React.ReactElement => (
  <svg
    style={{ ...style(props.size), ...props.style }}
    viewBox="0 0 168 104"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="On-boarding"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="how-it-works-step-3---8pt-copy-2"
        transform="translate(-104.000000, -288.000000)"
      >
        <g
          id="Organism/widget/onboarding/step-3"
          transform="translate(32.000000, 184.000000)"
        >
          <g
            id="Atom/artwork/salon"
            transform="translate(72.000000, 104.000000)"
          >
            <path
              d="M84.5492213,28.0016887 L83.0722882,27 L84.5492213,25.9982097 C85.1516898,25.6543782 85.3600623,24.8861491 85.0122943,24.2906494 C84.6643204,23.6953532 83.8866296,23.4894613 83.2841611,23.8330892 L81.8072279,24.8348796 L81.8072279,23.25 C81.8072279,22.5625407 81.2379097,22 80.5421677,22 C79.8464257,22 79.2771075,22.5625407 79.2771075,23.25 L79.2771075,24.8348796 L77.8001743,23.8330893 C77.1977058,23.4894613 76.420015,23.6953533 76.0720411,24.2906495 C75.7242731,24.8861492 75.9326456,25.6543783 76.5351141,25.9982098 L78.0120472,27 L76.5351141,28.0016887 C75.9326456,28.3455201 75.7242731,29.1138509 76.0720411,29.7092489 C76.420015,30.3046468 77.1977058,30.5105388 77.8001743,30.1668092 L79.2771075,29.1651204 L79.2771075,30.75 C79.2771075,31.4374593 79.8464257,32 80.5421677,32 C81.2379097,32 81.8072279,31.4374593 81.8072279,30.75 L81.8072279,29.1651204 L83.2841611,30.1668091 C83.8866296,30.5105388 84.6643204,30.3046468 85.0122943,29.7092488 C85.3600623,29.1138509 85.1516898,28.34552 84.5492213,28.0016887 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
