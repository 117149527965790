import styled from "styled-components";

import { rem } from "@ui/helpers";

const Container = styled.aside`
  position: relative;
  width: ${rem(100)};
  aspect-ratio: 1;
  color: ${props => props.theme.colors.blue900};
`;

const Shape = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8%
  background-color: ${props => props.theme.colors.blue50};
  transform: translate(-50%, -50%);
  transform-origin: center center;
  z-index: 1;
`;

const Icon = styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  opacity: 0;
  z-index: 2;
  pointer-events: none;
`;

export { Container, Shape, Icon };
