import { CONTACT_FORM_ENDPOINT } from "@config/endpoints";

import { SimpleObject } from "@typings/globals";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";

import { asyncActionCreator } from "@app/services/AsyncActionCreator";

export const sendContactForm = (body: SimpleObject) => {
  return asyncActionCreator({
    action: "",
    url: CONTACT_FORM_ENDPOINT,
    method: ASYNC_ACTION_TYPES.POST,
    body,
  });
};
