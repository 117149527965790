/**
 * @author michalstolarski
 * @since 2023-7-28
 */

import * as React from "react";
import styled from "styled-components";

import { TestWrapper } from "@ui/Atoms/TestWrapper";
import * as Grid from "@ui/Atoms/Grid";

type Variant = "warning" | "danger"; // next possible: "success" | "info" | "primary" | "secondary

interface Props {
  testId?: string;
  className?: string;
  children: React.ReactNode;
  variant: Variant;
}

/**
 * Component's purpose is to display a notification bar with a simply message.
 * @param props.children - It's not wrapped by the Markdown component.
 */
const Bar = React.memo((props: Props): React.ReactElement => {
  return (
    <TestWrapper testId={props.testId}>
      <Container className={props.className} variant={props.variant}>
        <Grid.Container>
          <Grid.Row>
            <Grid.Column>{props.children}</Grid.Column>
          </Grid.Row>
        </Grid.Container>
      </Container>
    </TestWrapper>
  );
});

Bar.displayName = "NotificationBar";

const Message = styled.div`
  text-align: center;
`;

const Link = styled.a`
  text-decoration: underline;

  &,
  &:hover {
    color: currentColor;
  }
`;

const NotificationBar = { Bar, Message, Link };
export { NotificationBar, Variant as NotificationBarVariant };

const createBgColor = (variant: Variant, color: string): string =>
  `--notification-bar-bg-color--${variant}: ${color}`;
const createTextColor = (variant: Variant, color: string): string =>
  `--notification-bar-color--${variant}: ${color}`;

const Container = styled.div<Pick<Props, "variant">>`
  /* variant -- warning */
  ${props => createTextColor("warning", props.theme.colors.black700)};
  ${props => createBgColor("warning", props.theme.colors.orange500)};

  /* variant -- danger */
  ${props => createTextColor("danger", props.theme.colors.white)};
  ${props => createBgColor("danger", props.theme.colors.red700)};

  padding: ${props => props.theme.margins.base_x2} 0;
  font-size: ${props => props.theme.fonts.sizes.body2};
  line-height: ${props => props.theme.fonts.lineHeights.normal};
  color: var(
    --notification-bar-color--${props => props.variant},
    ${props => props.theme.colors.black700}
  );
  background-color: var(
    --notification-bar-bg-color--${props => props.variant},
    transparent
  );

  ${props => props.theme.breakpoints.mobile} {
    font-size: ${props => props.theme.fonts.sizes.body};
  }
`;
