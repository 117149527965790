import * as React from "react";
import { useTheme } from "styled-components";

import { style, Icon } from "./Symbolicons";

export const Team = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 32 32"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 6.222A4.889 4.889 0 1 1 16 16a4.889 4.889 0 0 1 0-9.778Zm0 11.556c3.8 0 6.98 2.382 7.107 5.472l.004.194v1.445a.889.889 0 0 1-1.771.104l-.006-.104v-1.445c0-2.084-2.35-3.889-5.334-3.889-2.91 0-5.215 1.715-5.329 3.734l-.004.155v1.445a.889.889 0 0 1-1.772.104l-.006-.104v-1.445c0-3.184 3.233-5.666 7.111-5.666Zm-8.34.73a.889.889 0 0 1 .15 1.249c-.413.526-.648 1.108-.692 1.709l-.007.2V24a.889.889 0 0 1-1.772.104L5.333 24v-2.333c0-1.083.381-2.119 1.079-3.008a.889.889 0 0 1 1.248-.15Zm19 2.91c-.054-.993-.428-1.938-1.072-2.759a.889.889 0 0 0-1.398 1.098c.459.585.7 1.238.7 1.91V24l.005.104A.89.89 0 0 0 26.667 24v-2.333l-.007-.25Zm-.882-8.974a3.54 3.54 0 0 0-1.875-3.119.889.889 0 0 0-.844 1.565l.13.077c.502.325.811.873.811 1.477a1.77 1.77 0 0 1-1.653 1.762l-.103.013a.889.889 0 0 0 .229 1.76 3.547 3.547 0 0 0 3.305-3.535ZM9.302 9.686a.89.89 0 0 1-.273 1.15l-.088.054c-.577.311-.94.9-.94 1.554 0 .884.651 1.619 1.515 1.747l.138.015a.889.889 0 0 1-.125 1.773 3.548 3.548 0 0 1-3.307-3.535 3.54 3.54 0 0 1 1.876-3.119.889.889 0 0 1 1.204.36Zm3.587 1.425a3.111 3.111 0 1 1 6.222 0 3.111 3.111 0 0 1-6.222 0Z"
        fill={props.color || theme.colors.grey700}
      />
    </svg>
  );
};
