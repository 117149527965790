/**
 * @author jakubmaslon
 * @since 2020-11-19
 */

import * as React from "react";
import styled from "styled-components";

import { AVAILABLE_CURRENCIES } from "@typings/globals";

import { rem } from "@ui/helpers";
import { Info } from "@ui/Assets/Symbolicons/Info";
import theme from "@ui/themes/default";
import { Tooltip } from "@ui/Atoms/Tooltip";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  className?: string;
  currency: AVAILABLE_CURRENCIES | string;
  price: number;
  withTooltip?: boolean;
  tooltip?: string;
  testId?: string;
  isStruckOut?: boolean;
  isPriceNotFixed?: boolean;
}

const SummaryPrice = (props: Props): React.ReactElement => (
  <PriceWrapper className={props.className}>
    <TestWrapper testId={props.testId}>
      <Value isStruckOut={props.isStruckOut} data-hj-allow>
        <Currency>{props.currency}</Currency>&nbsp;
        {props.isPriceNotFixed
          ? Number(props.price)
          : Number(props.price).toFixed(2)}
      </Value>
    </TestWrapper>
    {props.withTooltip && (
      <Tooltip position={"left"} placeholder={props.tooltip} id="price_tooltip">
        <InfoIconContainer>
          <Info
            variant="solid"
            color={theme.colors.blue400}
            size={20}
            height={20}
          />
        </InfoIconContainer>
      </Tooltip>
    )}
  </PriceWrapper>
);

export { SummaryPrice };

const Currency = styled.span`
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.normal};
`;

const Value = styled.span<{ isStruckOut?: boolean }>`
  display: inline-flex;
  align-items: baseline;
  font-size: ${props => props.theme.fonts.sizes.body};
  ${props => (props.isStruckOut ? "text-decoration: line-through;" : "")}
`;

const InfoIconContainer = styled.span`
  display: inline-flex;
  align-self: flex-start;
  margin-right: -${props => props.theme.margins.base_x2};
  cursor: help;
  height: ${rem(14)};
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-weight: ${props => props.theme.fonts.weights.bold};
`;
