/**
 * @author tomekbuszewski
 * @since 2019-6-24
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { rem, transition } from "@ui/helpers";
import { Label, LabelWrapper } from "@ui/Atoms/Form/Label";
import { FormError } from "@ui/Atoms/Form/FormError";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { ITheme } from "@ui/themes/ThemeInterface";
import { ExtendedInputProps } from "@ui/Atoms/Form";

interface PristineRadioProps {
  children?: React.ReactNode;
  checked?: boolean;
  theme: ITheme;
  inline?: boolean;
}

interface Props extends ExtendedInputProps {
  id: string;
  inline?: boolean;
  withoutLabel?: boolean;
  fitContent?: boolean;
  testId?: string;
  className?: string;
}

const Radio = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <StyledLabelWrapper
      asCheck
      inline={props.inline}
      withoutLabel={props.withoutLabel}
      fitContent={props.fitContent}
      className={props.className}
    >
      <PristineRadio type="radio" {...props} />
      <RadioElement />
      <StyledLabel
        htmlFor={props.id}
        isDisabled={props.disabled}
        active={props.checked}
      >
        {props.label}
      </StyledLabel>
      {props.errors && <FormError>{props.errors}</FormError>}
    </StyledLabelWrapper>
  </TestWrapper>
);

export { PristineRadio, Radio };

const StyledLabelWrapper = styled(LabelWrapper)`
  --pristine-radio-size: ${rem(18)};
  --pristine-radio-position: ${rem(4.5)};
`;

const PristineRadio = styled.input<PristineRadioProps>`
  opacity: 0;
  position: absolute;
  cursor: pointer;
  top: 0;
  width: var(--pristine-radio-size);
  height: var(--pristine-radio-size);
  margin-top: var(--pristine-radio-position);

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
    `}
`;

export const RadioElement = styled.span<PristineRadioProps>`
  display: flex;
  flex-shrink: 0;
  transition: ${transition(["background", "border", "box-shadow"])};
  pointer-events: none;

  position: relative;
  margin-top: var(--pristine-radio-position);
  width: var(--pristine-radio-size, ${rem(18)});
  height: var(--pristine-radio-size, ${rem(18)});

  border-radius: 50%;
  border: ${rem(2)} solid ${props => props.theme.colors.grey300};
  background: ${props => props.theme.colors.white};
  box-sizing: border-box;

  ${PristineRadio}:checked + &,
  ${PristineRadio}:not(:checked):hover + &,
  .radio-element-connector:checked + &,
  .radio-element-connector:not(:checked):hover + & {
    border-color: ${props => props.theme.colors.blue400};
  }

  ${PristineRadio}:disabled:checked + &,
  ${PristineRadio}:disabled:hover + &,
  .radio-element-connector:disabled:checked + &,
  .radio-element-connector:disabled:hover + & {
    border-color: ${props => props.theme.colors.grey300};
  }

  &::after {
    transition: ${transition(["opacity", "background-color"])};

    position: absolute;
    content: "";
    display: block;

    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    transform: translate(-50%, -50%);

    border-radius: 50%;
    background-color: ${props => props.theme.colors.blue400};
    opacity: 0;

    ${PristineRadio}:checked + &,
    .radio-element-connector:checked + & {
      opacity: 1;
    }

    ${PristineRadio}:disabled + &,
    .radio-element-connector:disabled + & {
      background-color: ${props => props.theme.colors.grey300};
    }
  }
`;

const StyledLabel = styled(Label)`
  ${props =>
    props.isDisabled &&
    css`
      color: ${props => props.theme.colors.grey500};
    `}
`;
