/**
 * @author jakubbujakowski
 * @since 2019-9-25
 */

import * as React from "react";
import styled, { css, useTheme } from "styled-components";

import { COUNTER } from "@config/testIds/counter";

import { Minus } from "@ui/Assets/Symbolicons/Minus";
import { Plus } from "@ui/Assets/Symbolicons/Plus";
import { ITheme } from "@ui/themes/ThemeInterface";
import { Input } from "@ui/Atoms/Form";
import { Button } from "@ui/Atoms/Button";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { rem } from "@ui/helpers";
import { IconVariant } from "@ui/Assets/Symbolicons/Symbolicons";

import { CaretWithDirection } from "./CaretWithDirection";

const calcValue = (
  current: number,
  value: number,
  maxValue: number,
  minValue?: number,
): number => {
  if (!value) {
    return current;
  }

  if (value > (minValue ?? 0) && maxValue > value) {
    return value;
  }

  if (value > maxValue) {
    return maxValue;
  }

  if (typeof minValue === "number" && minValue > value) {
    return minValue;
  }

  return value;
};

type WidthType = "default" | "wide" | "auto";

interface Props {
  children?: React.ReactNode;
  theme: ITheme;
  className?: string;
  maxValue: number;
  minValue?: number;
  value?: number;
  inputId?: string;
  step?: number;
  displayValue?: string;
  autoWidth?: boolean;
  customSize?: string;
  useArrows?: boolean;
  isInputDisabled?: boolean;
  inputCustomWidth?: WidthType;
  infoGridArea?: string;
  filter?: (value: string | number) => string | number;
  onChange?: (count: number, action?: "decrement" | "increment") => void;
  getMaximumCountValueExceededText?: () => string;
  getMinimumCountValueNotReachedText?: () => string;
}

const CounterComponent = (props: Props) => {
  const [count, setCount] = React.useState<number>(0);
  const step = props.step ? props.step : 1;
  const minValue = props.minValue ? props.minValue : 0;
  const [countInfo, setCountInfo] = React.useState<string | null>(null);
  const theme = useTheme();

  React.useEffect(() => {
    if (!props.value) return;

    const newValue = calcValue(
      count,
      props.value,
      props.maxValue,
      props.minValue,
    );

    if (count !== newValue) {
      setCount(newValue);
    }
  }, [props.value, props.maxValue, props.minValue]);

  const iconStyles = {
    color: theme.colors.blue500,
    size: theme.icons.sizes.base_x3,
    variant: "line" as IconVariant,
  };

  const handleCountSet = (val: number, action?: "decrement" | "increment") => {
    setCount(val);
    props.onChange && props.onChange(val, action);
  };

  const handleDecrement = () => {
    const result = count - step;
    if (result >= minValue) {
      handleCountSet(result, "decrement");
      setCountInfo(null);
    }
  };

  const handleIncrement = () => {
    const result = count + step;
    if (props.maxValue >= result) {
      handleCountSet(result, "increment");
      setCountInfo(null);
    }
  };

  const handleCountChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    const filteredValue = props.filter ? props.filter(value) : value;
    const parsedValue = Number(filteredValue);

    if (isNaN(parsedValue)) return;

    if (parsedValue < minValue) {
      handleCountSet(minValue);
      setCountInfo(props.getMinimumCountValueNotReachedText?.() || null);

      return;
    }

    if (parsedValue > props.maxValue) {
      handleCountSet(props.maxValue);
      setCountInfo(props.getMaximumCountValueExceededText?.() || null);

      return;
    }

    handleCountSet(parsedValue);
    setCountInfo(null);
  };

  const getValue = () => {
    if (props.displayValue) {
      return props.displayValue;
    }

    if (props.filter) {
      return props.filter(count);
    }

    return count;
  };

  return (
    <div className={props.className}>
      <TestWrapper testId={COUNTER.BTN_DECREMENT}>
        <CounterButtonLeft
          type="button"
          variant="borderGrey"
          size="icon"
          auto
          onClick={handleDecrement}
          isDecrementDisabled={count <= minValue}
          customSize={props.customSize}
        >
          {props.useArrows ? (
            <CaretWithDirection iconStyles={iconStyles} />
          ) : (
            <Minus {...iconStyles} />
          )}
        </CounterButtonLeft>
      </TestWrapper>
      <TestWrapper testId={COUNTER.INPUT}>
        <CounterInput
          isLabelFloating={false}
          customWidth={props.inputCustomWidth}
          value={getValue()}
          textCenter
          onChange={handleCountChange}
          id={props.inputId || "counterInput"}
          onPaste={e => {
            e.preventDefault();
            return false;
          }}
          disabled={props.isInputDisabled}
          hotjarAllow
          customSize={props.customSize}
          inputWrapperStyles={{ flex: "1 1 auto" }}
        />
      </TestWrapper>
      <TestWrapper testId={COUNTER.BTN_INCREMENT}>
        <CounterButtonRight
          type="button"
          variant="borderGrey"
          size="icon"
          auto
          onClick={handleIncrement}
          isIncrementDisabled={count >= props.maxValue}
          customSize={props.customSize}
        >
          {props.useArrows ? (
            <CaretWithDirection isRight iconStyles={iconStyles} />
          ) : (
            <Plus {...iconStyles} />
          )}
        </CounterButtonRight>
      </TestWrapper>

      {countInfo && <CounterInputInfo>{countInfo}</CounterInputInfo>}
    </div>
  );
};

const CounterInputInfo = styled.div`
  margin-top: ${props => props.theme.margins.half};
  font-size: ${props => props.theme.fonts.sizes.caption};
  line-height: ${props => props.theme.fonts.lineHeights.caption};
  color: ${props => props.theme.colors.black300};
  text-align: left;
  grid-area: alert;
`;

const CounterButton = styled(Button)<{ customSize?: string }>`
  border: 1px solid ${props => props.theme.colors.greyDark};
  font-weight: ${props => props.theme.fonts.weights.light};
  font-size: ${props => props.theme.fonts.sizes.h5};
  min-height: ${props =>
    props.customSize ? props.customSize : props.theme.margins.base_x7};
  color: ${props => props.theme.colors.blue500};
  line-height: 0;
  user-select: none;

  &:hover,
  &:active {
    color: ${props => props.theme.colors.blue700};
  }

  ${props => props.theme.breakpoints.tablet} {
    min-width: ${props =>
      props.customSize ? props.customSize : props.theme.margins.base_x7};
  }
`;

const CounterButtonLeft = styled(CounterButton)<{
  isDecrementDisabled?: boolean;
  customSize?: string;
}>`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;

  ${props =>
    props.isDecrementDisabled &&
    css`
      color: ${props.theme.colors.grey600};
      pointer-events: none;

      svg {
        g,
        path {
          fill: currentColor;
        }
      }
    `}
`;

const CounterButtonRight = styled(CounterButton)<{
  isIncrementDisabled?: boolean;
  customSize?: string;
}>`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;

  ${props =>
    props.isIncrementDisabled &&
    css`
      color: ${props.theme.colors.grey600};
      pointer-events: none;

      svg {
        g,
        path {
          fill: currentColor;
        }
      }
    `}
`;

const CounterInput = styled(Input)<{
  customWidth?: WidthType;
  customSize?: string;
}>`
  min-height: ${props => props.customSize || props.theme.margins.base_x7};
  min-width: ${props => props.customSize || props.theme.margins.base_x7};
  max-width: ${props => props.customSize || props.theme.margins.base_x7};
  border-radius: 0;
  padding: ${rem(6)};
  appearance: none;

  ${props =>
    props.customWidth === "auto" &&
    css`
      max-width: none;
      min-width: auto;
    `}

  ${props =>
    props.customWidth === "wide" &&
    css`
      max-width: none;
    `}

  &:disabled {
    border: 1px solid ${props => props.theme.colors.greyDark};
    background: ${props => props.theme.colors.white};
    opacity: 1;
    color: ${props => props.theme.colors.black500};
    -webkit-text-fill-color: ${props => props.theme.colors.black500};
  }
`;

const Counter = styled(CounterComponent)<Props>`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr auto;
  gap: 0px 0px;
  grid-template-areas:
    ". . ."
    "alert alert alert";
  align-items: center;
  text-align: center;
  max-width: ${props => (props.autoWidth ? "none" : rem(168))};
`;

Counter.displayName = "Counter";

export { Counter };
