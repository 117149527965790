/**
 * @author tomekbuszewski
 * @since 2019-3-11
 */

import styled, { css } from "styled-components";

import { rem } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";

type Color = "red700" | "orange700" | "blue400";
interface Props {
  color?: Color;
  children?: any;
  floating?: boolean;
  withBorder?: boolean;
  theme: ITheme;
}

const NotificationBall = styled.span<Props>`
  display: inline-block;
  min-width: ${rem(26)};
  margin-left: ${props => props.theme.margins.half};
  padding: ${props =>
    `${props.theme.margins.half} ${props.theme.margins.base}`};
  border-radius: ${props => props.theme.margins.base_x2};
  border: ${rem(1)} solid
    ${props => (props.withBorder ? props.theme.colors.white : "transparent")};
  background: ${props => props.theme.colors[props.color || "red700"]};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fonts.sizes.caption};
  line-height: ${props => props.theme.fonts.lineHeights.caption};
  transform: translateY(1px);

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.caption};
    transform: translateY(-1px);

    ${props =>
      props.floating &&
      css`
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: 0;
        transform: translate(-50%, -50%);
      `};
  }
`;

export { Color, NotificationBall };
