/**
 * @author michalstolarski
 * @since 2022-4-4
 */

import React from "react";
import { NavLink } from "react-router-dom";
import styled, { useTheme } from "styled-components";

import { Margins } from "@ui/Atoms/Margins";
import { Column, Row, Container } from "@ui/Atoms/Grid";
import { Caret } from "@ui/Assets/Symbolicons";

interface Props {
  path: string;
  onClick?: () => void;
}

const SupportMobileBack = (props: Props): React.ReactElement => {
  const theme = useTheme();
  const onClickHandler = () => {
    props.onClick?.();
  };

  return (
    <Margins xs={[null, "base_x2", null, null]}>
      <Container widthVariant="small">
        <Row>
          <Column>
            <NavLink to={props.path} onClick={onClickHandler}>
              <CaretStyled
                variant="solid"
                size={28}
                color={theme.colors.blue400}
              />
            </NavLink>
          </Column>
        </Row>
      </Container>
    </Margins>
  );
};

export { SupportMobileBack };

const CaretStyled = styled(Caret)`
  transform: rotateZ(90deg);
`;
