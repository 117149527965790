/**
 * @author tomekbuszewski
 * @since 2019-3-7
 */

import * as React from "react";
import styled from "styled-components";

import { TestWrapper } from "@ui/Atoms";
import { rem } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";

import { useOnClickOutside } from "@hooks/useOnClickOutside";

import DropdownContainer from "./DropdownContainer";
import DropdownHeader from "./DropdownHeader";
import DropdownItem from "./DropdownItem";
import DropdownTrigger from "./DropdownTrigger";

/**
 * Dropdown component
 */
interface Props {
  autoWidth?: boolean;
  children: React.ReactNode;
  className?: string;
  currentState: React.ReactElement | string | number;
  size?: "normal" | "small" | "pill";
  isDark?: boolean;
  isDarkOutline?: boolean;
  theme: ITheme;
  toLeft?: boolean;
  withPadding?: boolean;
  withVerticalPadding?: boolean;
  smallPaddings?: boolean;
  buttonTestId?: string;
  containerTestId?: string;
  withWideTrigger?: boolean;
  alignment?: "left" | "right";
  dropdownMaxHeight?: string | null;
  dropdownMaxWidth?: string | null;
  variant?: "borderless" | "default";
  onToggle?: (active: boolean) => void;
}

const DropdownComponent = (props: Props) => {
  const [isActive, setIsActive] = React.useState<boolean>(false);
  const [isVisible, setIsVisible] = React.useState<boolean>(false);

  const containerRef = useOnClickOutside(() => {
    setIsActive(false);
    setIsVisible(false);
  });

  return (
    <div className={props.className} ref={containerRef}>
      <DropdownTrigger
        toLeft={props.toLeft}
        onClick={e => {
          e.preventDefault();

          setIsActive(prevState => {
            props.onToggle?.(!prevState);
            return !prevState;
          });
          setIsVisible(prevState => !prevState);
        }}
        isDark={props.isDark}
        isDarkOutline={props.isDarkOutline}
        active={isActive}
        testId={props.buttonTestId || ""}
        withWideTrigger={props.withWideTrigger}
        smallPaddings={props.smallPaddings}
        variant={props.variant}
      >
        {props.currentState}
      </DropdownTrigger>
      <TestWrapper testId={props.containerTestId}>
        <DropdownContainer
          active={isActive}
          withPadding={props.withPadding}
          withVerticalPadding={props.withVerticalPadding}
          auto={props.autoWidth}
          alignment={props.alignment}
          maxHeight={props.dropdownMaxHeight}
          maxWidth={props.dropdownMaxWidth}
          onClick={() => {
            setIsActive(false);
            setIsVisible(false);
          }}
        >
          {isVisible && props.children}
        </DropdownContainer>
      </TestWrapper>
    </div>
  );
};

const Dropdown = styled(DropdownComponent)<Props>`
  display: flex;
  flex-flow: row wrap;
  position: relative;

  ${props =>
    props.size === "small" &&
    `
     max-width: ${rem(175)};
   `};

  ${props =>
    props.size === "pill" &&
    `
     max-width: ${rem(64)};
   `};
`;

export {
  Dropdown,
  DropdownContainer,
  DropdownHeader,
  DropdownItem,
  DropdownTrigger,
};

Dropdown.defaultProps = {
  autoWidth: false,
  size: "normal",
};
