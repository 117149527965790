import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const PageWithPencil = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <defs>
      <path
        id="page-with-pencil-a"
        d="M12 5.333a.667.667 0 01.078 1.33L12 6.666H6a.666.666 0 00-.662.589l-.005.077V18c0 .342.257.624.59.662l.077.005h8.667a.666.666 0 00.662-.59l.004-.077v-4a.667.667 0 011.33-.078l.004.078v4a2 2 0 01-1.883 1.997l-.117.003H6a2 2 0 01-1.997-1.882L4 18V7.333a2 2 0 011.882-1.996L6 5.333h6zm3.867-.624a2.42 2.42 0 113.424 3.424l-1.466 1.466a.68.68 0 01-.005.004l-.005.004-5.222 5.223a.667.667 0 01-.184.13l-.106.04-3.455.975a.667.667 0 01-.823-.823L9 11.697c.03-.11.09-.21.17-.29l5.222-5.222a.68.68 0 01.005-.005l.004-.005zm-.999 2.885l-4.633 4.633-.604 2.141 2.142-.604 4.632-4.633-1.537-1.537zm3.48-1.942a1.087 1.087 0 00-1.538 0l-.999.999 1.537 1.537 1-.998c.37-.37.418-.937.145-1.358l-.075-.103z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="page-with-pencil-b" fill="#fff">
        <use xlinkHref="#page-with-pencil-a"></use>
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#page-with-pencil-a"></use>
      <g
        fill={props.color || theme.colors.grey700}
        mask="url(#page-with-pencil-b)"
      >
        <path d="M0 0h24v24H0z"></path>
      </g>
    </g>
  </svg>
);
