/**
 * @author tomekbuszewski
 * @since 2019-3-11
 */

import * as React from "react";

import { TabsComponent } from "./Tabs.types";
import { Tab, TabElement } from "./Tab";
import * as Styled from "./Tabs.styled";

/**
 * Tabs wrapper
 */
const Tabs: TabsComponent = ({ color = "blue400", ...props }) => (
  <Styled.Tabs variant={props.variant} className={props.className}>
    {React.Children.toArray(props.children).map(child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child as TabElement, {
          color: color,
          variant: props.variant,
        });
      }

      return null;
    })}
  </Styled.Tabs>
);

Tabs.Tab = Tab;
Tabs.displayName = "Tabs";

export { Tabs };
