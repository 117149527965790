/**
 * @author jakubbujakowski
 * @since 2021-5-25
 */

import * as React from "react";
import styled from "styled-components";
import { RTTextNode } from "@prismicio/client";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS, ORIGIN } from "@typings/globals";

import theme from "@ui/themes/default";
import { rem } from "@ui/helpers";
import { Caret } from "@ui/Assets/Symbolicons/Caret";
import { Input, Margins, List, Paragraph } from "@ui/Atoms";
import { Row, Column } from "@ui/Atoms/Grid";

import { langFromTag } from "@services/LangFromTag/LangFromTag";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { usePrismic } from "@hooks/usePrismic";
import { useBrowserRerender } from "@hooks/useBrowserRerender";

import {
  HelpWidgetSearchResultsSkeleton,
  HelpWidgetCategoriesSkeleton,
} from "./HelpWidget.skeletons";
import {
  HelpArticle,
  HelpCategories,
  HelpCategory,
  sortByOrder,
  debouncedSearch,
  fetchCategories,
} from "./HelpWidget.helpers";

interface Props {
  renderQuestions: () => React.ReactElement | null;
  country: AVAILABLE_COUNTRIES;
  language: AVAILABLE_LANGS;
  origin: ORIGIN;
  filterBy?: string | null;
  staticNavigate?: (path: string) => void;
  translations: {
    backToCategoriesText: string;
    noResultsText: string;
    searchInputLabelText: string;
  };
}

const HelpWidget = (props: Props): React.ReactElement => {
  const { getPrismicEntry, isApiReady } = usePrismic();
  const { mobile, tablet } = useBreakpoint();
  const isMobile = mobile || tablet;
  const isBrowser = useBrowserRerender();

  const [searchResults, setSearchResults] = React.useState<HelpArticle[]>([]);
  const [categories, setCategories] = React.useState<HelpCategories>([]);
  const [isQueryPending, setIsQueryPending] = React.useState<boolean>(false);
  const [searchString, setSearchString] = React.useState<string>("");

  React.useEffect(() => {
    if (!isApiReady) {
      return;
    }

    fetchCategories({
      country: props.country,
      origin: props.origin,
      language: props.language,
      setCategories,
      getPrismicEntry,
      ...(props.filterBy && {
        custom: {
          label: "filter_by",
          value: props.filterBy,
        },
      }),
    });
  }, [isApiReady]);

  const createCategoryLink = (category: HelpCategory) =>
    `/${langFromTag(category.lang || "")}/${
      (category.data.slug[0] as RTTextNode).text
    }`;

  const createArticleLink = (article: HelpArticle) =>
    `/${langFromTag(article.lang || "")}/${
      (article.data.slug[0] as RTTextNode).text
    }`;

  return (
    <HelpWidgetWrapper>
      <Row>
        <Column xs={12} lg={7} orderXs={2} orderLg={1}>
          {/** Skeleton for pending search */}
          {isBrowser && isQueryPending && <HelpWidgetSearchResultsSkeleton />}

          {/** Help Articles list */}
          {!!searchString.length && !isQueryPending && (
            <React.Fragment>
              <GoBackButton>
                <CaretWrapper>
                  <Caret variant="solid" color={theme.colors.grey} />
                </CaretWrapper>
                <a
                  href="#"
                  role="button"
                  onClick={e => {
                    e.preventDefault();
                    setSearchResults([]);
                    setSearchString("");
                  }}
                >
                  {props.translations.backToCategoriesText}
                </a>
              </GoBackButton>

              {searchResults.length > 0 ? (
                <StyledList>
                  {searchResults.map((article: HelpArticle) => (
                    <li key={article.id}>
                      <a
                        href={createArticleLink(article)}
                        onClick={e => {
                          if (!props.staticNavigate) return;
                          e.preventDefault();
                          props.staticNavigate(createArticleLink(article));
                        }}
                      >
                        {(article.data.title[0] as RTTextNode).text}
                      </a>
                    </li>
                  ))}
                </StyledList>
              ) : (
                <Paragraph>{props.translations.noResultsText}</Paragraph>
              )}
            </React.Fragment>
          )}

          {/** Help Categories */}
          {!searchResults.length && !isQueryPending && !searchString.length && (
            <React.Fragment>
              <CategoryList as="ul">
                {categories &&
                  categories.sort(sortByOrder).map(category => (
                    <CategoryListItem as="li" xs={12} lg={6} key={category.id}>
                      <Icon
                        src={category.data.icon.url}
                        alt={category.data.icon.alt}
                      />
                      <StyledLink
                        href={createCategoryLink(category)}
                        onClick={e => {
                          if (!props.staticNavigate) return;
                          e.preventDefault();
                          props.staticNavigate(createCategoryLink(category));
                        }}
                      >
                        {(category.data.title[0] as RTTextNode).text}
                      </StyledLink>
                    </CategoryListItem>
                  ))}
              </CategoryList>
              {isBrowser && !categories.length && (
                <HelpWidgetCategoriesSkeleton />
              )}
              {isMobile && props.renderQuestions()}
            </React.Fragment>
          )}
        </Column>

        {/* Text Search Section */}
        <Column xs={12} lg={5} orderXs={1} orderLg={2}>
          <Margins xs={[null, "base_x4", null, null]}>
            <Input
              label={props.translations.searchInputLabelText}
              value={searchString}
              onChange={async e => {
                e.persist();
                const searchString = e.target.value;

                setIsQueryPending(true);
                setSearchString(searchString);
                debouncedSearch({
                  searchString,
                  setSearchResults,
                  setIsQueryPending,
                  country: props.country,
                  origin: props.origin,
                  language: props.language,
                  getPrismicEntry,
                });
              }}
            />
          </Margins>
          {!isMobile && props.renderQuestions()}
        </Column>
      </Row>
    </HelpWidgetWrapper>
  );
};

HelpWidget.displayName = "HelpWidgetOrganism";

export { HelpWidget };

const HelpWidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.margins.base_x4};
`;

const CategoryList = styled(Row)`
  list-style-type: none;
  padding-left: 0;

  li,
  &:last-of-type li {
    margin-bottom: ${props => props.theme.margins.base_x4};
  }
`;

const CategoryListItem = styled(Column)`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  padding-right: ${props => props.theme.margins.base_x2};
  width: ${rem(42)};
`;

const StyledLink = styled.a`
  font-size: ${props => props.theme.fonts.sizes.enlarged};
`;

const StyledList = styled(List)`
  margin-top: 0;
`;

const GoBackButton = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.base_x2};
  padding-bottom: ${rem(14)};
  border-bottom: 1px dashed ${props => props.theme.colors.greyDisabled};
`;

const CaretWrapper = styled.div`
  line-height: ${rem(30)};
  padding-right: ${props => props.theme.margins.half};

  svg {
    transform: rotate(90deg);
  }
`;
