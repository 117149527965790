// soft
import batsoft_pwa_client_options from "@translations/soft/en.batsoft_pwa_client_options.json";
import batsoft_pwa_generic from "@translations/soft/en.batsoft_pwa_generic.json";
import batsoft_pwa_holidays from "@translations/soft/en.batsoft_pwa_holidays.json";
import batsoft_pwa_my_cleanings from "@translations/soft/en.batsoft_pwa_my_cleanings.json";
import batsoft_pwa_navigation from "@translations/soft/en.batsoft_pwa_navigation.json";
import batsoft_pwa_password from "@translations/soft/en.batsoft_pwa_password.json";
import batsoft_pwa_bookings_to_validate from "@translations/soft/en.batsoft_pwa_bookings_to_validate.json";
import batsoft_pwa_profile from "@translations/soft/en.batsoft_pwa_profile.json";
import batsoft_pwa_my_employees from "@translations/soft/en.batsoft_pwa_my_employees.json";
import batsoft_pwa_support from "@translations/soft/en.batsoft_pwa_support.json";
import batsoft_pwa_booking from "@translations/soft/en.batsoft_pwa_booking.json";
import batsoft_pwa_declare_employee from "@translations/soft/en.batsoft_pwa_declare_employee.json";
import batsoft_pwa_account_creation from "@translations/soft/en.batsoft_pwa_account_creation.json";
import batwork_pwa_my_cleanings from "@translations/work/en.batwork_pwa_my_cleanings.json"; // used in NewCleaning
import batsoft_pwa_rating from "@translations/soft/en.batsoft_pwa_rating.json";

export const softTranslations = {
  batsoft_pwa_client_options,
  batsoft_pwa_generic,
  batsoft_pwa_holidays,
  batsoft_pwa_my_cleanings,
  batsoft_pwa_navigation,
  batsoft_pwa_password,
  batsoft_pwa_bookings_to_validate,
  batsoft_pwa_profile,
  batsoft_pwa_my_employees,
  batsoft_pwa_support,
  batsoft_pwa_booking,
  batsoft_pwa_declare_employee,
  batsoft_pwa_account_creation,
  batwork_pwa_my_cleanings,
  batsoft_pwa_rating,
};
