import {
  AGENT_PREFERENCE,
  EXTRA_SERVICES_BOOKING,
  EXTRA_SERVICES_LEGACY,
} from "@typings/booking";

import { IStore } from "@soft/redux/reducers";

import {
  getSelectedTasksList,
  getServiceLocationId,
  getPetsInfo,
  getWeekStartDate,
  getKeyPick,
  getLocationComment,
  getSpecialInstructions,
  getExecutionDateTime,
  getForm,
  getPreselectedAgentUuid,
  getAgentPreference,
} from "./booking.selectors";

export const getOnceAvailabilityEndpointRequest = (store: IStore) => {
  const tasks = getSelectedTasksList(store).map(item => ({
    //@ToDo: remove this condition once backend fixes extra services enum
    service:
      item.get("service") === EXTRA_SERVICES_BOOKING.INSIDE_OVEN
        ? EXTRA_SERVICES_LEGACY.INSIDE_OWEN
        : item.get("service"),
    duration: item.get("duration"),
  }));

  const weekStartDate = getWeekStartDate(store);
  const preselectedAgentUuid = getPreselectedAgentUuid(store);
  const agentPreference = getAgentPreference(store);

  return {
    serviceLocationId: getServiceLocationId(store),
    hasPets: getPetsInfo(store),
    tasks,
    agentPreference,
    ...(weekStartDate && { weekStartDate }),
    ...(preselectedAgentUuid &&
      agentPreference === AGENT_PREFERENCE.PRESELECTED && {
        preselectedAgentUuid,
      }),
  };
};

export const getAddOnceJobToContractEndpointRequest = (store: IStore) => {
  const keyPick = getKeyPick(store);
  const locationComment = getLocationComment(store);
  const specialInstructions = getSpecialInstructions(store);
  const tasks = getSelectedTasksList(store).map(item => ({
    //@ToDo: remove this condition once backend fixes extra services enum
    service:
      item.get("service") === EXTRA_SERVICES_BOOKING.INSIDE_OVEN
        ? EXTRA_SERVICES_LEGACY.INSIDE_OWEN
        : item.get("service"),
    duration: item.get("duration"),
  }));
  const executionDateTime = getExecutionDateTime(store);

  return {
    executionDateTime,
    tasks,
    keyPick,
    locationComment,
    specialInstructions,
  };
};

export const getAddLocationEndpointRequest = (store: IStore) => {
  const form = getForm(store);

  return {
    serviceLocationId: getServiceLocationId(store),
    address: form.get("address"),
    floorAndDoor: form.get("floorAndDoor"),
    entryCode: form.get("entryCode"),
  };
};
