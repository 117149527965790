import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { Row, Container, Heading, Margins, CheckListItem } from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { GatsbyImage } from "../../components/GatsbyImage";
import { List } from "../Homepage/";

import { Description, Section, StyledColumn } from "./shared";

interface ListItem {
  list_title: { text: string };
  list_text: { text: string };
}

export interface FrictionlessCollaborationProps {
  collaboration_title: {
    text: string;
  };
  collaboration_description: {
    text: string;
  };
  collaboration_list: ListItem[];

  collaboration_image: {
    gatsbyImageData: IGatsbyImageData;
  };
}

const FrictionlessCollaboration = (
  props: FrictionlessCollaborationProps,
): React.ReactElement => {
  return (
    <Section isWhite>
      <Container>
        <Row>
          <HeaderColumn lg={9}>
            <Margins xs={[null, "base_x3", null, null]}>
              <Heading
                type="h2"
                styleAs="h4"
                textAlignXs="left"
                textAlignLg="center"
              >
                {props.collaboration_title.text}
              </Heading>
            </Margins>
            <Description>{props.collaboration_description.text}</Description>
          </HeaderColumn>
        </Row>

        <Row>
          <StyledColumn xs={12} lg={6}>
            <List isFullWidth>
              {props.collaboration_list.map(item => (
                <React.Fragment key={item.list_title.text}>
                  <CheckListItem
                    description={item.list_text.text}
                    value={item.list_title.text}
                    isMarginless
                  />
                </React.Fragment>
              ))}
            </List>
          </StyledColumn>
          <StyledColumn xs={12} lg={6}>
            <ImageWrapper>
              <GatsbyImage
                image={props.collaboration_image.gatsbyImageData}
                alt="batsoft"
              />
            </ImageWrapper>
          </StyledColumn>
        </Row>
      </Container>
    </Section>
  );
};

export { FrictionlessCollaboration };

const HeaderColumn = styled(StyledColumn)`
  margin: 0 auto;
  text-align: center;
`;

const ImageWrapper = styled.div`
  ${props => props.theme.breakpoints.tablet} {
    width: ${rem(455)};
    margin: 0 auto;
  }

  ${props => props.theme.breakpoints.desktop} {
    width: 100%;
    padding-left: ${props => props.theme.margins.base_x4};
    margin-left: ${props => props.theme.margins.base_x6};
  }
`;
