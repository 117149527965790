import color from "color";

import { APP_NAMES } from "@typings/globals";

import theme from "@ui/themes/default";

export const getBackgroundPerApp = (variant: APP_NAMES) => {
  const colorMap = {
    [APP_NAMES.BATMAID]: theme.colors.black700,
    [APP_NAMES.BATSOFT]: theme.colors.blue900,
    [APP_NAMES.BATWORK]: theme.colors.white,
  };
  return colorMap[variant];
};

export const getBackgroundHoverPerApp = (variant: APP_NAMES) => {
  const colorMap = {
    [APP_NAMES.BATMAID]: color(getBackgroundPerApp(variant))
      .lighten(0.3)
      .toString(),
    [APP_NAMES.BATSOFT]: color(getBackgroundPerApp(variant))
      .lighten(0.1)
      .toString(),
    [APP_NAMES.BATWORK]: color(getBackgroundPerApp(variant))
      .darken(0.07)
      .toString(),
  };
  return colorMap[variant];
};
