import { PrismicImageField } from "@www/graphql-types";
import React from "react";
import { graphql, PageProps } from "gatsby";

import { IS_BROWSER } from "@config/consts";

import { useVoucherManager } from "@hooks/useVoucherManager";

import SEO, { SeoProps } from "../components/SEO";

export interface SeoSponsorProps {
  seo_title_sponsor?: { text: string };
  seo_description_sponsor?: { text: string };
  seo_image_sponsor?: PrismicImageField;
}

type Props = PageProps<{
  prismicBatsoftLandingPage: { data: SeoSponsorProps };
}>;

const BatsoftSponsor = (props: Props): React.ReactElement => {
  const { data } = props;
  const { prismicBatsoftLandingPage } = data;

  const voucherManger = useVoucherManager();

  React.useEffect(() => {
    if (IS_BROWSER) {
      const [path, voucherId] = props.location.pathname.split("/sponsor/");

      voucherManger.storeDeeplinkVoucher(String(voucherId).replace(/\//g, ""));

      window.location.href = path;
    }
  });

  const seoData: SeoProps = {
    seo_title: prismicBatsoftLandingPage.data.seo_title_sponsor,
    seo_description: prismicBatsoftLandingPage.data.seo_description_sponsor,
    seo_image: prismicBatsoftLandingPage.data.seo_image_sponsor,
  };

  return (
    <SEO
      title={seoData.seo_title?.text || ""}
      description={seoData.seo_description?.text || ""}
      path={props.location.pathname}
      {...seoData}
    />
  );
};

export default BatsoftSponsor;

export const query = graphql`
  query BatsoftSponsorPage($id: String!) {
    prismicBatsoftLandingPage(id: { eq: $id }) {
      _previewable
      lang
      data {
        country
        seo_title_sponsor {
          text
        }
        seo_description_sponsor {
          text
        }
        seo_image_sponsor {
          gatsbyImageData
        }
      }
    }
  }
`;
