/**
 * @author jakubbujakowski
 * @since 2020-5-12
 */

import * as React from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ReCaptcha from "react-google-recaptcha";

import { CAPTCHA_SITE_KEY } from "@config/consts";

import { SimpleObject } from "@typings/globals";

import { rem } from "@ui/helpers";
import theme from "@ui/themes/default";
import { Envelope } from "@ui/Assets/Symbolicons/Envelope";
import { PhoneTalking } from "@ui/Assets/Symbolicons/PhoneTalking";
import { Section, Button, Margins, Row, Column, Paragraph } from "@ui/Atoms";
import { Tabs, Tab } from "@ui/Molecules/Tabs";
import { FormikInputWithError } from "@ui/Molecules/FormikInputWithError";
import { ErrorFocus, FormikSelectExtended } from "@ui/Molecules";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { Markdown } from "@containers/Markdown";
import { showToast } from "@containers/Toastify";

enum TABS {
  EMAIL,
  CALL,
}

export enum FORM_FIELDS {
  EMAIL = "email",
  NAME = "name",
  REASON = "reason",
  MESSAGE = "message",
}

interface GetValidationSchemaProps {
  isRequiredText: string;
  invalidEmailText: string;
}

const getValidationSchema = ({
  isRequiredText,
  invalidEmailText,
}: GetValidationSchemaProps) =>
  Yup.object().shape({
    [FORM_FIELDS.NAME]: Yup.string().trim().required(isRequiredText),
    [FORM_FIELDS.REASON]: Yup.string().trim().required(isRequiredText),
    [FORM_FIELDS.EMAIL]: Yup.string()
      .email(invalidEmailText)
      .required(isRequiredText),
    [FORM_FIELDS.MESSAGE]: Yup.string().trim().required(isRequiredText),
  });

interface Props {
  children?: React.ReactNode;
  className?: string;
  onSubmitAction: (payload: SimpleObject) => Promise<AsyncActionResult>;
  onCallTabClick?: () => void;
  onEmailTabClick?: () => void;
  userName?: string;
  userEmail?: string;
  reasonAsSelect?: boolean;
  translations: {
    emailTabText: string;
    callTabText: string;
    messageSuccessText: string;
    introText: string;
    nameLabelText: string;
    emailLabelText: string;
    reasonLabelText: string;
    tellUsMoreLabelText: string;
    submitButtonText: string;
    callUsTabInfoText: string;
    callHoursText: string;
    phoneNumberText: string;
    isRequiredText: string;
    invalidEmailText: string;
    reasonsList: string[];
  };
}

const ContactForm = (props: Props): React.ReactElement => {
  const [activeTab, setActiveTab] = React.useState<TABS>(TABS.EMAIL);
  const [captchaValue, setCaptchaValue] = React.useState<null | string>(null);
  const [formName, setFormName] = React.useState(() => props.userName || "");
  const [formEmail, setFormEmail] = React.useState(() => props.userEmail || "");

  const initialValues = {
    [FORM_FIELDS.NAME]: formName,
    [FORM_FIELDS.EMAIL]: formEmail,
    [FORM_FIELDS.REASON]: "",
    [FORM_FIELDS.MESSAGE]: "",
  };

  return (
    <ContactFormWrapper className={props.className}>
      <StyledTabs variant="buttons" color="greyDark">
        <StyledTab
          onClick={() => {
            props.onEmailTabClick?.();
            setActiveTab(TABS.EMAIL);
          }}
          active={activeTab === TABS.EMAIL}
        >
          <Envelope
            variant="solid"
            size={26}
            color={
              activeTab === TABS.EMAIL
                ? theme.colors.blue500
                : theme.colors.grey500
            }
          />
          <span>
            <Markdown>{props.translations.emailTabText}</Markdown>
          </span>
        </StyledTab>
        <StyledTab
          onClick={() => {
            props.onCallTabClick?.();
            setActiveTab(TABS.CALL);
          }}
          active={activeTab === TABS.CALL}
        >
          <PhoneTalking
            size={26}
            color={
              activeTab === TABS.CALL
                ? theme.colors.blue500
                : theme.colors.grey500
            }
          />
          {props.translations.callTabText}
        </StyledTab>
      </StyledTabs>
      <Margins xs={[0, null, null, null]}>
        <StyledSection small smallMargins>
          {activeTab === TABS.EMAIL ? (
            <Row>
              <Column lg={7}>
                <Formik
                  onSubmit={async (
                    values,
                    { setErrors, resetForm, setFieldValue },
                  ) => {
                    const { mapApiErrorsToForm, onSuccess } =
                      await props.onSubmitAction({
                        ...values,
                        captchaValue,
                      });

                    mapApiErrorsToForm(setErrors);

                    onSuccess(() => {
                      setFormName("");
                      setFormEmail("");
                      resetForm();

                      // resetform doesn't work for these fields
                      setFieldValue(FORM_FIELDS.NAME, "");
                      setFieldValue(FORM_FIELDS.EMAIL, "");

                      showToast(props.translations.messageSuccessText, {
                        type: "success",
                      });
                    });

                    setCaptchaValue(null);
                  }}
                  initialValues={initialValues}
                  validationSchema={getValidationSchema({
                    isRequiredText: props.translations.isRequiredText,
                    invalidEmailText: props.translations.invalidEmailText,
                  })}
                >
                  {({ values, handleChange, setFieldValue, isSubmitting }) => (
                    <React.Fragment>
                      <Form>
                        <ErrorFocus>
                          <Margins xs={[null, "base", null, null]}>
                            <div>
                              <Paragraph>
                                {props.translations.introText}
                              </Paragraph>
                            </div>
                          </Margins>
                          <StyledRow>
                            <Column md={6}>
                              <Margins
                                xs={[null, "base_x4", null, null]}
                                md={[null, "0", null, null]}
                              >
                                <FormikInputWithError
                                  label={props.translations.nameLabelText}
                                  name={FORM_FIELDS.NAME}
                                  value={values[FORM_FIELDS.NAME]}
                                  handleChange={handleChange}
                                />
                              </Margins>
                            </Column>
                            <Column md={6}>
                              <FormikInputWithError
                                label={props.translations.emailLabelText}
                                name={FORM_FIELDS.EMAIL}
                                value={values[FORM_FIELDS.EMAIL]}
                                handleChange={handleChange}
                              />
                            </Column>
                          </StyledRow>
                          <StyledRow>
                            <Column>
                              {props.reasonAsSelect && (
                                <FormikSelectExtended
                                  clearInput={!values[FORM_FIELDS.REASON]}
                                  name={FORM_FIELDS.REASON}
                                  value={values[FORM_FIELDS.REASON] || null}
                                  options={props.translations.reasonsList.map(
                                    reason => ({
                                      label: reason,
                                      value: reason,
                                    }),
                                  )}
                                  onChange={input => {
                                    setFieldValue(
                                      FORM_FIELDS.REASON,
                                      input.value,
                                    );
                                  }}
                                  isSearchable={false}
                                  placeholder={
                                    props.translations.reasonLabelText
                                  }
                                />
                              )}
                              {!props.reasonAsSelect && (
                                <FormikInputWithError
                                  name={FORM_FIELDS.REASON}
                                  value={values[FORM_FIELDS.REASON]}
                                  handleChange={handleChange}
                                />
                              )}
                            </Column>
                          </StyledRow>
                          <StyledRow>
                            <Column>
                              <FormikInputWithError
                                label={props.translations.tellUsMoreLabelText}
                                name={FORM_FIELDS.MESSAGE}
                                value={values[FORM_FIELDS.MESSAGE]}
                                handleChange={handleChange}
                                as="textarea"
                              />
                            </Column>
                          </StyledRow>
                          <Row>
                            <Column lg={6}>
                              <CaptchaWrapper>
                                <ReCaptcha
                                  sitekey={CAPTCHA_SITE_KEY}
                                  onChange={value => setCaptchaValue(value)}
                                />
                              </CaptchaWrapper>
                            </Column>
                            <Column lg={6} style={{ alignSelf: "flex-end" }}>
                              <Button
                                variant="green"
                                size="large"
                                type="submit"
                                disabled={!captchaValue || isSubmitting}
                              >
                                {props.translations.submitButtonText}
                              </Button>
                            </Column>
                          </Row>
                        </ErrorFocus>
                      </Form>
                    </React.Fragment>
                  )}
                </Formik>
              </Column>
            </Row>
          ) : (
            <CallUsSection>
              <Paragraph>{props.translations.callUsTabInfoText}</Paragraph>
              <Paragraph>{props.translations.callHoursText}</Paragraph>
              <StyledLink href={`tel:${props.translations.phoneNumberText}`}>
                {props.translations.phoneNumberText}
              </StyledLink>
            </CallUsSection>
          )}
        </StyledSection>
      </Margins>
    </ContactFormWrapper>
  );
};

ContactForm.displayName = "ContactForm";

export { ContactForm };

const ContactFormWrapper = styled.div`
  margin: ${props => props.theme.margins.base_x4} 0;
`;

const StyledSection = styled(Section)`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.margins.base_x4};
`;

const StyledTabs = styled(Tabs)`
  display: flex;

  button {
    flex-grow: 1;
    padding: ${props => props.theme.margins.base_x4};
  }
`;

const StyledTab = styled(Tab)<{ active: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props =>
    props.active ? props.theme.colors.blue500 : props.theme.colors.grey500};
  background: ${props =>
    props.active ? props.theme.colors.white : props.theme.colors.whiteHover};

  svg {
    margin-right: ${props => props.theme.margins.base_x2};
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: ${props => props.theme.margins.base_x4};
`;

const StyledLink = styled.a`
  font-size: ${props => props.theme.fonts.sizes.enlarged};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  padding: ${props => props.theme.margins.base_x3};
`;

const CallUsSection = styled.div`
  margin: ${props => props.theme.margins.base_x4} 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    text-align: center;
  }
`;

const CaptchaWrapper = styled.div`
  margin-bottom: ${props => props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.desktop} {
    margin-bottom: -${rem(2)};
  }
`;
