import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Mail = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 20"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <path
      fill="none"
      stroke={props.color || theme.colors.grey700}
      strokeWidth="2"
      d="M1 5.92l9.32 4.31a4 4 0 003.36 0L23 5.92"
    ></path>
    <rect
      fill="none"
      stroke={props.color || theme.colors.grey700}
      strokeWidth="2"
      width="22"
      height="18"
      x="1"
      y="1"
      rx="2"
    ></rect>
  </svg>
);
