import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const HomeHouseLine = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 21H8C5.23858 21 3 18.7614 3 16V11.2C3.00001 9.68108 3.69046 8.24453 4.87653 7.29568L8.87653 4.09568C10.7026 2.63477 13.2974 2.63477 15.1235 4.09568L19.1235 7.29568C20.3096 8.24455 21 9.6811 21 11.2V16C21 18.7614 18.7614 21 16 21Z"
      />
      <path d="M14.25 21V15.75C14.25 14.7831 13.4669 14 12.5 14H10.75C9.78312 14 9 14.7831 9 15.75V21" />
    </svg>
  );
};
