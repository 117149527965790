import { format, isValid, parse } from "date-fns";

import { DATE_FORMAT } from "@services/DateFormatter";

import { IStore } from "@soft/redux/reducers";

import {
  getEmployerAccount,
  getLegalLocation,
  getMainLocation,
  getPersonalDetails,
} from "./accountCreation.selectors";

export const getClientRegistrationEndpointRequest = (store: IStore) => {
  const personalDetails = getPersonalDetails(store);
  const mainLocation = getMainLocation(store);

  const birthDate = personalDetails.get("birthDate") || "";

  const resolvedBirthDate = isValid(new Date(birthDate))
    ? format(
        parse(birthDate, DATE_FORMAT.DAY_PICKER_INPUT, new Date()),
        DATE_FORMAT.YEAR_MONTH_DAY,
      )
    : null;

  return {
    email: personalDetails.get("email"),
    password: personalDetails.get("password"),
    firstName: personalDetails.get("firstName"),
    lastName: personalDetails.get("lastName"),
    mainLocation,
    phoneNumber: personalDetails.get("phoneNumber"),
    birthDate: resolvedBirthDate,
    gender: personalDetails.get("title"),
  };
};

export const getAddLegalLocationEndpointRequest = (store: IStore) => {
  const legalLocation = getLegalLocation(store);

  return {
    serviceLocationId: legalLocation.get("serviceLocationId"),
    address: legalLocation.get("address"),
  };
};

export const getPutEmployerInfoEndpointRequest = (store: IStore) => {
  const employerAccount = getEmployerAccount(store);

  return {
    svaOffice: employerAccount.get("svaOffice"),
    affiliateNumber: employerAccount.get("affiliateNumber"),
    svaNumber: employerAccount.get("svaNumber"),
  };
};
