/**
 * @author michalstolarski
 * @since 2022-4-4
 */

import React from "react";
import styled from "styled-components";

import { rem } from "@ui/helpers";
import { Heading } from "@ui/Atoms/Headings";

interface Props {
  className?: string;
  children: React.ReactNode;
}

const SupportHeading = (props: Props): React.ReactElement => (
  <HeadingStyled type="h5" className={props.className}>
    {props.children}
  </HeadingStyled>
);

export { SupportHeading };

const HeadingStyled = styled(Heading)`
  line-height: ${rem(32)};
  text-align: center;

  br {
    ${props => props.theme.breakpoints.upToDesktop} {
      display: none;
    }
  }
`;
