import React from "react";

import { MODALS } from "@typings/modals";

import { Margins } from "@ui/Atoms/Margins";
import { Modal } from "@ui/Molecules/Modal2";

import { useTranslate } from "@hooks/useTranslate";
import { useModalManager } from "@hooks/modal";

const ZipCodeUnknownModal = React.memo((): React.ReactElement => {
  const translate = useTranslate("batmaid_pwa_generic");
  const { hideModal } = useModalManager();

  return (
    <Modal
      size="thin"
      title={translate("zip_code_unknown_modal_header")}
      submitText=""
      cancelText=""
      onRequestClose={() => hideModal(MODALS.ZIP_CODE_UNKNOWN)}
    >
      <Margins xs={[null, "base_x4", null, null]}>
        <div>{translate("zip_code_unknown_modal_content")}</div>
      </Margins>
    </Modal>
  );
});

ZipCodeUnknownModal.displayName = "ZipCodeUnknownModal";

export default ZipCodeUnknownModal;
