/**
 * @author michalstolarski
 * @since 2023-9-8
 */

import * as React from "react";

import TwintLogo from "@assets/images/twint_logo.png";

import { PaymentAliasType } from "@typings/payments";

import { TestWrapper } from "@ui/Atoms/TestWrapper";
import {
  Visa,
  Amex,
  MasterCard,
  MasterCardV2,
  AmexV2,
  VisaV2,
  TwintV2,
} from "@ui/Assets/PaymentProviders";

interface Props {
  testId?: string;
  className?: string;
  style?: React.CSSProperties;
  type: PaymentAliasType;
  version: "v1" | "v2";
}

const paymentAliasIconsV1 = {
  [PaymentAliasType.VIS]: Visa,
  [PaymentAliasType.AMX]: Amex,
  [PaymentAliasType.ECA]: MasterCard,
  [PaymentAliasType.TWI]: (props: Pick<Props, "className" | "style">) => (
    <img src={TwintLogo} height={24} {...props} />
  ),
  default: null,
};

const paymentAliasIconsV2 = {
  [PaymentAliasType.VIS]: VisaV2,
  [PaymentAliasType.AMX]: AmexV2,
  [PaymentAliasType.ECA]: MasterCardV2,
  [PaymentAliasType.TWI]: TwintV2,
  default: null,
};

const PaymentAliasLogo = React.memo(
  (props: Props): React.ReactElement | null => {
    const Logo = React.useMemo(() => {
      if (props.version === "v2") {
        return paymentAliasIconsV2[props.type] || paymentAliasIconsV2.default;
      }

      return paymentAliasIconsV1[props.type] || paymentAliasIconsV1.default;
    }, [props.type, props.version]);

    return (
      <TestWrapper testId={props.testId}>
        <Logo className={props.className} style={props.style} />
      </TestWrapper>
    );
  },
);

PaymentAliasLogo.displayName = "PaymentAliasLogo";

export { PaymentAliasLogo };
