import { SliceComponentProps } from "@prismicio/react";
import React from "react";
import styled from "styled-components";

import { Container, Heading, Margins } from "@ui/Atoms";
import { rem } from "@ui/helpers";
import { DraggableCarousel } from "@ui/Molecules/DraggableCarousel";
import { Team } from "@ui/Assets/Symbolicons/Team";
import { Search } from "@ui/Assets/Symbolicons/Search";
import { UserHeart } from "@ui/Assets/Symbolicons/UserHeart";
import { Gearwheel, HandsHeart, LightBulb } from "@ui/Assets/Symbolicons";

import { useBreakpoint } from "@hooks/useBreakpoint";

import { PrismicGlobalContentPageDataBodyIconTitleDescriptionBoxesRow } from "../../../graphql-types";
import { StyledSection } from "../Homepage/shared";

const slidesToShow = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
};

const icons = {
  team: Team,
  search: Search,
  "user-heart": UserHeart,
  lightbulb: LightBulb,
  "hands-heart": HandsHeart,
  gearwheel: Gearwheel,
};

type Icon = keyof typeof icons;

const IconTitleDescriptionBoxesRowSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyIconTitleDescriptionBoxesRow>,
): React.ReactElement => {
  const { mobile } = useBreakpoint();

  const { primary, items } = props.slice;

  return (
    <Section isWhite>
      <StyledContainer>
        <Margins xs={[null, null, null, "base_x7"]}>
          <StyledHeading type="h2" styleAs="h4" textAlignLg="center">
            {primary?.title1?.text}
          </StyledHeading>
        </Margins>

        {!mobile && (
          <BoxesContainer>
            {items?.map(step => {
              const Icon = icons[step.icon as Icon];

              return (
                <StepContent key={step.title1?.text}>
                  {!!Icon && (
                    <IconWrapper>
                      <Icon size={32} />
                    </IconWrapper>
                  )}
                  <Heading type="h6">{step?.title1?.text}</Heading>
                  <p>{step?.description?.text}</p>
                </StepContent>
              );
            })}
          </BoxesContainer>
        )}

        {mobile && (
          <DraggableCarousel hideButtons slidesToShow={slidesToShow}>
            {items?.map(step => {
              const Icon = icons[step.icon as Icon];

              return (
                <StepContent key={step?.title1?.text}>
                  {!!Icon && (
                    <IconWrapper>
                      <Icon size={32} />
                    </IconWrapper>
                  )}
                  <Heading type="h6">{step?.title1?.text}</Heading>
                  <p>{step?.description?.text}</p>
                </StepContent>
              );
            })}
          </DraggableCarousel>
        )}
      </StyledContainer>
    </Section>
  );
};

export { IconTitleDescriptionBoxesRowSlice };

const StyledContainer = styled(Container)`
  ${props => props.theme.breakpoints.desktop} {
    display: flex;
  }
`;

const Section = styled(StyledSection)`
  overflow: hidden;
  padding: ${props => props.theme.margins.base_x8}
    ${props => props.theme.margins.base_x2};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base_x4};
  font-size: ${props => props.theme.fonts.sizes.h5};
  text-align: left;
  font-weight: ${props => props.theme.fonts.weights.bold};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.h3};
    text-align: center;
  }

  ${props => props.theme.breakpoints.desktop} {
    min-width: ${rem(240)};
  }
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin-bottom: 0;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(40)};
  height: ${rem(40)};
  margin-bottom: ${props => props.theme.margins.base};
  background: ${props => props.theme.colors.greySurface};
  border-radius: 50%;
`;

const BoxesContainer = styled.div`
  display: grid;
  gap: ${props => props.theme.margins.base_x4}
    ${props => props.theme.margins.base_x2};
  grid-template-columns: 1fr 1fr 1fr;
`;
