/**
 * @author michalstolarski
 * @since 2023-11-23
 */

import * as React from "react";
import { useTheme } from "styled-components";

import { CaretLeft } from "@ui/Assets/MyIcons/CaretLeft";
import { InfoCircle } from "@ui/Assets/MyIcons/InfoCircle";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import * as Grid from "@ui/Atoms/Grid";

import * as Styled from "./FocusLayout.styled";
import * as Types from "./FocusLayout.types";

const Layout = React.forwardRef(
  (
    props: Types.LayoutProps,
    ref: React.RefObject<HTMLDivElement>,
  ): React.ReactElement => (
    <Grid.Container>
      <Grid.Row>
        <Grid.Column xs={12} pushXs={0} lg={8} pushLg={2}>
          <Styled.Layout
            ref={ref}
            className={props.className}
            variant={props.variant}
          >
            {props.children}
          </Styled.Layout>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  ),
);

Layout.displayName = "Layout";

const BackButton = ({
  type = "button",
  testId,
  children,
  className,
  Icon,
  ...props
}: Types.BackButtonProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <TestWrapper testId={testId}>
      <Styled.BackButton type={type} className={className} {...props}>
        <React.Fragment>
          {!Icon && <CaretLeft width={theme.icons.sizes.base_x3} />}
          {!!Icon && <Icon width={theme.icons.sizes.base_x3} />}

          <Styled.BackButtonContent>{children}</Styled.BackButtonContent>
        </React.Fragment>
      </Styled.BackButton>
    </TestWrapper>
  );
};

const StepCounter = (props: Types.StepCounterProps): React.ReactElement => {
  return (
    <Styled.StepCounter
      testId={props.testId}
      className={props.className}
      variant="default"
    >
      {props.children}
    </Styled.StepCounter>
  );
};

const Info = (props: Types.InfoProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <TestWrapper testId={props.testId}>
      <Styled.Info
        type="button"
        className={props.className}
        onClick={props.onClick}
      >
        <InfoCircle width={theme.icons.sizes.base_x3} />

        {!props.isTextHidden && <span>{props.children}</span>}
      </Styled.Info>
    </TestWrapper>
  );
};

const Header = React.forwardRef<HTMLHeadElement, Types.HeaderProps>(
  (props, ref): React.ReactElement => (
    <TestWrapper testId={props.testId}>
      <Styled.HeaderBar
        ref={ref}
        className={props.className}
        minHeight={props.hasMinHeight}
      >
        {props.children}
      </Styled.HeaderBar>
    </TestWrapper>
  ),
);

Header.displayName = "FocusLayout.Header";

export { Layout, BackButton, StepCounter, Info, Header };
