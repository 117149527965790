export type IntlFormatDateName =
  | "full"
  | "long"
  | "short"
  | "fullWithYear"
  | "shortWithYear"
  | "shortDateMonth"
  | "fullDate"
  | "weekdayTime"
  | "weekdayFullDate"
  | "weekdayLongFullDate"
  | "weekdayShortDateMonth"
  | "altFull"
  | "altFullDate"
  | "altShortDateMonth"
  | "time"
  | "weekdayShortOnly"
  | "dayOnly"
  | "monthYear"
  | "monthLongOnly"
  | "weekdayLongFullDateWithYear";

export const IntlDateFormat: Record<
  IntlFormatDateName,
  Intl.DateTimeFormatOptions
> = {
  /**
   * full
   * @returns "Sunday, February 13, 13:15"
   */
  full: {
    weekday: "long",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },

  /**
   * long
   * @returns "Sunday, 13 Feb, 13:15"
   */
  long: {
    weekday: "long",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },

  /**
   * short
   * @returns "Sun, 13 Feb, 13:15"
   */
  short: {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },

  /**
   * shortDateMonth
   * @returns "13 Feb"
   */
  shortDateMonth: {
    month: "short",
    day: "numeric",
  },

  /**
   * shortWithYear
   * @returns "Sun, 13 Feb 2022, 13:15"
   */
  shortWithYear: {
    year: "numeric",
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },

  /**
   * fullWithYear
   * @returns "Sunday, 13 Feb 2022, 13:15"
   */
  fullWithYear: {
    year: "numeric",
    weekday: "long",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },

  /**
   * fullDate
   * @returns "13 Feb 2022"
   */
  fullDate: {
    day: "numeric",
    month: "short",
    year: "numeric",
  },

  /**
   * weekdayTime
   * @returns "Sun 13:15"
   */
  weekdayTime: {
    weekday: "short",
    hour: "numeric",
    minute: "numeric",
  },

  /**
   * weekdayFullDate
   * @returns "Sun, 13 Feb 2022"
   */
  weekdayFullDate: {
    day: "numeric",
    weekday: "short",
    month: "short",
    year: "numeric",
  },

  /**
   * weekdayLongFullDate
   * @returns "Sunday, 13 February"
   */
  weekdayLongFullDate: {
    day: "numeric",
    weekday: "long",
    month: "long",
  },

  /**
   * weekdayLongFullDate
   * @returns "Saturday, 2 Apr 2005"
   */
  weekdayLongFullDateWithYear: {
    day: "numeric",
    weekday: "long",
    month: "short",
    year: "numeric",
  },

  /**
   * weekdayShortDateMonth
   * @returns "Sun, 13 Feb"
   */
  weekdayShortDateMonth: {
    day: "numeric",
    weekday: "short",
    month: "short",
  },

  /**
   * altFull
   * @returns "13/02/2022, 13:15"
   */
  altFull: {
    dateStyle: "short",
    timeStyle: "short",
  },

  /**
   * altFullDate
   * @returns "13/02/2022"
   */
  altFullDate: {
    day: "numeric",
    year: "numeric",
    month: "numeric",
  },

  /**
   * altShortDateMonth
   * @returns "02/2022"
   */
  altShortDateMonth: {
    year: "numeric",
    month: "numeric",
  },

  /**
   * time
   * @returns "13:15"
   */
  time: {
    minute: "numeric",
    hour: "numeric",
  },
  weekdayShortOnly: {
    weekday: "short",
  },
  dayOnly: {
    day: "2-digit",
  },
  monthYear: {
    year: "numeric",
    month: "long",
  },
  monthLongOnly: {
    month: "long",
  },
};
