import { Link } from "gatsby";
import { navigate } from "gatsby-link";
import * as React from "react";
import styled, { css } from "styled-components";

import { HOMEPAGE } from "@config/testIds/app";

import { AVAILABLE_LANGS, HERO_TYPES, SERVICE_TYPE } from "@typings/globals";

import { WashingMachine } from "@ui/Assets/Symbolicons";
import { House } from "@ui/Assets/Symbolicons/House";
import { MartinaSign } from "@ui/Assets/Symbolicons/MartinaSign";
import { Shampoo } from "@ui/Assets/Symbolicons/Shampoo";
import {
  ModernBuilding4,
  HomeHouseLine,
  BaggageBags,
} from "@ui/Assets/MyIcons";
import {
  Button,
  CheckListItem,
  Container,
  Margins,
  OptionPanel,
  Paragraph,
  Row,
  TestWrapper,
} from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { useTelemetry } from "@hooks/useTelemetry";

import { SubscribeEmail } from "@www/src/components/SubscribeEmail";
import { PrismicImageField } from "@www/graphql-types";

import { getServiceType } from "../../components/StartBookingForm/StartBookingForm.helpers";
import { StartBookingForm } from "../../components/StartBookingForm";

import { List, SectionHeading } from "./shared";

interface HeroListItem {
  hero_description_image: PrismicImageField;
  list_item: { text: string };
}

export interface SliceHeroProps {
  items: HeroListItem[];
  primary: {
    hero_navigation_text: {
      text: string;
    };
    hero_section_title: { text: string };
    hero_section_subtitle: { text: string };
    hero_section_description: { text: string };
    hero_type: HERO_TYPES;
    hero_background_img: PrismicImageField;
    button_text: {
      text: string;
    };
    button_link: {
      text: string;
    };
  };
}

export interface HeroProps {
  language: AVAILABLE_LANGS;
  heroesList: SliceHeroProps[];
  hero_quote?: { text: string };
  hero_quote_author?: { text: string };
  hero_image_mobile?: PrismicImageField;
  teaser_form_label?: { text: string };
  teaser_form_placeholder?: { text: string };
  teaser_form_submit?: { text: string };
  booking_form_placeholder: { text: string };
  booking_form_submit: { text: string };
  isCityPage?: boolean;
  landingUrl?: string;
  hero_breadcrumb_text?: { text: string };
  is_teaser_version?: boolean;
  email_label?: { text: string };
  email_submit: { text: string };
  email_placeholder: { text: string };
  onServiceTypeChange?: (type: SERVICE_TYPE) => void;
}

const navbarIcons = {
  [HERO_TYPES.HOME_CLEANING]: HomeHouseLine,
  [HERO_TYPES.END_OF_TENANCY]: BaggageBags,
  [HERO_TYPES.OFFICE_CLEANING]: ModernBuilding4,
  [HERO_TYPES.CLEANING_PRODUCTS]: Shampoo,
};

const HERO_TYPES_WITH_FORM = [
  HERO_TYPES.HOME_CLEANING,
  HERO_TYPES.END_OF_TENANCY,
  HERO_TYPES.OFFICE_CLEANING,
];

interface HandleFormSuccessParams {
  path: string;
  activeHeroType: HERO_TYPES;
}

const handleFormSuccess = ({
  path,
  activeHeroType,
}: HandleFormSuccessParams): void => {
  if (activeHeroType === HERO_TYPES.END_OF_TENANCY) {
    const eotUrl = path.replace("booking", "end-of-tenancy");

    window.location.href = eotUrl;
    return;
  }

  window.location.href = path;
};

const HeroSection = (props: HeroProps): React.ReactElement | null => {
  const [activeHeroIndex, setActiveHeroIndex] = React.useState(0);
  const { elementClick } = useTelemetry();

  const activeHero: SliceHeroProps = props.is_teaser_version
    ? props.heroesList[0]
    : props.heroesList[activeHeroIndex];

  const activeHeroType = activeHero?.primary?.hero_type;

  const serviceType = getServiceType(activeHeroType);

  React.useEffect(() => {
    props.onServiceTypeChange?.(serviceType);
  }, [serviceType]);

  if (!activeHero) {
    return null;
  }

  const isFormHero = HERO_TYPES_WITH_FORM.includes(activeHeroType);
  const isImageDescription = activeHeroType === HERO_TYPES.CLEANING_PRODUCTS;

  const shouldShowNavigation =
    props.heroesList?.length > 1 &&
    !props.is_teaser_version &&
    !props.isCityPage;

  const changeActiveHero = (index: number, hero: SliceHeroProps) => {
    elementClick(hero.primary?.hero_navigation_text?.text);
    setActiveHeroIndex(index);
  };
  return (
    <HeroWrapper
      imgUrl={
        activeHero.primary?.hero_background_img?.gatsbyImageData?.images
          ?.fallback?.src
      }
      hasNavigation={shouldShowNavigation}
      isTeaserVersion={props.is_teaser_version}
    >
      <StyledContainer isTeaserVersion={props.is_teaser_version}>
        <StyledRow>
          <PanelWrapper>
            {shouldShowNavigation && (
              <HeroNavbar>
                {props.heroesList?.map((hero, index) => {
                  const Icon = navbarIcons[hero?.primary?.hero_type] || House;
                  return (
                    <TestWrapper
                      key={index}
                      testId={`${HOMEPAGE.HERO_NAV_ITEM}-${hero?.primary?.hero_type}`}
                    >
                      <NavbarItem
                        key={index}
                        onClick={() => changeActiveHero(index, hero)}
                        isActive={index === activeHeroIndex}
                      >
                        <Icon height={24} width={24} />
                        {hero.primary?.hero_navigation_text?.text}
                      </NavbarItem>
                    </TestWrapper>
                  );
                })}
              </HeroNavbar>
            )}
            <StyledPanel
              hasNavigation={shouldShowNavigation}
              isTeaserVersion={props.is_teaser_version}
            >
              {props.landingUrl && (
                <Breadcrumb>
                  <Link to={props.landingUrl}>
                    <House variant="line" size={10} />
                  </Link>
                  <Margins xs={[null, null, "base", "base"]}>
                    <span>/</span>
                  </Margins>
                  {props.hero_breadcrumb_text?.text}
                </Breadcrumb>
              )}
              {!!props.hero_image_mobile?.gatsbyImageData?.gatsbyImageData
                ?.images?.fallback?.src && (
                <MobileImg
                  src={
                    props.hero_image_mobile?.gatsbyImageData?.gatsbyImageData
                      ?.images?.fallback?.src
                  }
                />
              )}

              <MainHeading type="h1">
                {activeHero.primary?.hero_section_title?.text}
              </MainHeading>

              {props.is_teaser_version && (
                <Subheading type="body" weight="bold">
                  {activeHero.primary?.hero_section_subtitle.text}
                </Subheading>
              )}

              <DesktopListWrapper>
                <Paragraph type="body">
                  {!isImageDescription
                    ? activeHero.primary?.hero_section_description?.text
                    : activeHero.items?.map(
                        (item: HeroListItem, index) =>
                          item.hero_description_image?.gatsbyImageData?.images
                            ?.fallback?.src && (
                            <DescriptionImg
                              key={index}
                              src={
                                item.hero_description_image?.gatsbyImageData
                                  ?.images?.fallback?.src
                              }
                            />
                          ),
                      )}
                </Paragraph>
              </DesktopListWrapper>

              <StyledList>
                {activeHero.items?.map((item: HeroListItem, index) => (
                  <CheckListItem key={index} value={item.list_item?.text} />
                ))}
              </StyledList>

              {isFormHero ? (
                props.is_teaser_version ? (
                  <SubscribeEmail
                    emailLabel={
                      props.teaser_form_label?.text || props.email_label?.text
                    }
                    emailSubmit={
                      props.teaser_form_submit?.text || props.email_submit.text
                    }
                    emailPlaceholder={
                      props.teaser_form_placeholder?.text ||
                      props.email_placeholder.text
                    }
                    buttonVariant="green"
                  />
                ) : (
                  <StartBookingForm
                    language={props.language}
                    emailSubmit={
                      props.booking_form_submit?.text || props.email_submit.text
                    }
                    emailPlaceholder={
                      props.booking_form_placeholder?.text ||
                      props.email_placeholder.text
                    }
                    buttonVariant="green"
                    onSuccess={path =>
                      handleFormSuccess({ path, activeHeroType })
                    }
                    serviceType={serviceType}
                    isZipCodeUnknownVisible
                  />
                )
              ) : (
                <Button
                  variant="green"
                  size="fill"
                  onClick={() =>
                    navigate(activeHero.primary?.button_link?.text)
                  }
                >
                  {activeHero.primary?.button_text?.text}
                </Button>
              )}
            </StyledPanel>
          </PanelWrapper>

          {activeHeroType === HERO_TYPES.HOME_CLEANING &&
            !props.isCityPage &&
            props.hero_quote?.text && (
              <QuoteWrapper>
                <Quote>{props.hero_quote?.text}</Quote>
                <Margins xs={[null, "base", null, null]}>
                  <QuoteAuthor>{props.hero_quote_author?.text}</QuoteAuthor>
                </Margins>
                <MartinaSign size={120} />
              </QuoteWrapper>
            )}
        </StyledRow>
      </StyledContainer>
    </HeroWrapper>
  );
};

export { HeroSection };

const HeroWrapper = styled.section<{
  imgUrl: string;
  hasNavigation?: boolean;
  isTeaserVersion?: boolean;
}>`
  display: flex;
  align-items: center;
  height: auto;
  position: relative;

  /* Background, while image not loaded */
  &::before {
    background: ${props => props.theme.colors.white};
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: -1;
  }

  /* Overlay when the isTeaserVersion true */
  &::after {
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    display: block;
    height: inherit;
    position: absolute;
    width: 100%;
    z-index: 0;
  }

  ${props => props.theme.breakpoints.tablet} {
    background-image: url("${props => props.imgUrl}");
    background-size: cover;
    background-position: center 15%;
    background-repeat: no-repeat;
    background-blend-mode: screen;

    ${props =>
      props.isTeaserVersion &&
      css`
        height: ${rem(608)};
      `}
  }
`;

const StyledContainer = styled(Container)<{ isTeaserVersion?: boolean }>`
  height: 100%;
  padding: 0;

  ${props => props.theme.breakpoints.tablet} {
    padding: ${props => `${props.theme.margins.base_x4} 0`};

    ${props =>
      props.isTeaserVersion &&
      css`
        padding: ${props => `${props.theme.margins.base_x7} 0`};
      `}
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const PanelWrapper = styled.div`
  z-index: 1;
`;

// ==== navbar ====
const HeroNavbar = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  background: ${props => props.theme.colors.white};
  z-index: 2;
  height: ${rem(88)};
  width: 100%;
  list-style: none;
  border-radius: ${props => props.theme.border.radius.base};
  border-bottom-left-radius: 0;
  position: relative;

  ${props => props.theme.breakpoints.tablet} {
    width: 130%;

    &::after {
      width: 100%;
      margin: 0;
    }
  }

  ${props => props.theme.breakpoints.desktop} {
    width: ${rem(880)};
  }
`;

const NavbarItem = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: ${props => props.theme.margins.base};
  cursor: pointer;
  text-align: center;
  font-weight: ${props => props.theme.fonts.weights.normal};
  font-size: ${props => props.theme.fonts.sizes.caption};
  transition: opacity
    ${props =>
      `${props.theme.animations.duration} ${props.theme.animations.easing}`};

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.fonts.sizes.body2};
  }

  ${props =>
    props.isActive &&
    css`
      position: relative;
      font-weight: ${props => props.theme.fonts.weights.bold};

      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 25%;
        width: 50%;
        height: ${rem(4)};
        background: black;
      }

      ${props => props.theme.breakpoints.tablet} {
        font-weight: ${props => props.theme.fonts.weights.bold};
      }
    `}

  &:hover {
    ${props => props.theme.breakpoints.desktop} {
      ${props =>
        !props.isActive &&
        css`
          opacity: 0.8;
        `}
    }
  }

  > svg {
    margin-bottom: ${props => props.theme.margins.base_x2};
  }
`;

const Breadcrumb = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.base};
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.grey500};
  font-size: ${props => props.theme.fonts.sizes.overline};

  ${props => props.theme.breakpoints.tablet} {
    display: none;
  }
`;

// ==== panel ====

const MobileImg = styled.img`
  width: 100%;
  height: auto;
  margin: ${props => `0 auto ${props.theme.margins.base_x4}`};
  border-radius: ${props => props.theme.border.radius.base};

  ${props => props.theme.breakpoints.tablet} {
    display: none;
  }
`;

const StyledPanel = styled(OptionPanel)<{
  hasNavigation: boolean;
  isTeaserVersion?: boolean;
}>`
  background: ${props => props.theme.colors.white};
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding: ${props =>
    `${props.theme.margins.base_x2} ${props.theme.margins.base_x4} ${props.theme.margins.base_x2}`};
  border-top: ${rem(1)} solid ${props => props.theme.colors.greyDark};
  border-radius: ${props => props.theme.border.radius.base};
  z-index: 2;

  ${props =>
    props.hasNavigation &&
    css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  ${props => props.theme.breakpoints.tablet} {
    width: ${rem(496)};
    padding: ${props =>
      `${props.theme.margins.base_x3} ${props.theme.margins.base_x4} ${props.theme.margins.base_x4}`};

    ${props =>
      props.isTeaserVersion &&
      css`
        height: ${rem(498)};
        width: ${rem(600)};
        padding: ${props => props.theme.margins.base_x4};
      `}
  }
`;

const MainHeading = styled(SectionHeading)`
  margin-bottom: ${props => props.theme.margins.base_x3};
  font-size: ${props => props.theme.fonts.sizes.h4};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${props => props.theme.fonts.lineHeights.h4};
  text-align: left;

  ${props => props.theme.breakpoints.tablet} {
    margin: ${props => `0 0 ${props.theme.margins.base_x4}`};
    font-size: ${props => props.theme.fonts.sizes.h3};
    line-height: ${props => props.theme.fonts.lineHeights.h3};
  }
`;

const Subheading = styled(Paragraph)`
  margin: 0 0 ${props => props.theme.margins.base_x4};
`;

const StyledList = styled(List)`
  margin-bottom: ${props => props.theme.margins.base_x4};
  padding-right: ${props => props.theme.margins.base};

  ${props => props.theme.breakpoints.tablet} {
    padding-right: 0;
  }
`;

const QuoteWrapper = styled.div`
  display: none;

  ${props => props.theme.breakpoints.desktopLarge} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    height: fit-content;
    top: calc(50% + ${rem(100)});
    right: 25%;
    z-index: 2;
    color: ${props => props.theme.colors.white};
    font-size: ${props => props.theme.fonts.sizes.h6};
  }
`;

const Quote = styled.q`
  ${props => props.theme.breakpoints.desktopLarge} {
    font-style: italic;
    font-weight: ${props => props.theme.fonts.weights.semibold};
  }
`;

const QuoteAuthor = styled.span`
  ${props => props.theme.breakpoints.desktopLarge} {
    font-size: ${props => props.theme.fonts.sizes.body};
  }
`;

const DesktopListWrapper = styled.div`
  display: none;

  ${props => props.theme.breakpoints.tablet} {
    display: block;
  }
`;

const DescriptionImg = styled.img`
  height: ${rem(64)};

  &:first-child {
    margin-right: ${props => props.theme.margins.base_x2};
  }
`;
