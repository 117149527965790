import * as React from "react";

import { GTM_EVENT_BATMAID_LOGIN_STATE_CHANGED } from "@config/consts";

import { dataLayerFacade } from "@services/DataLayerFacade";

import { useAuth } from "@hooks/useAuth";

const Analytics = (): null => {
  const { isUserLoggedIn, isUserDataFetched, user } = useAuth();

  React.useEffect(() => {
    if (isUserDataFetched) {
      dataLayerFacade().push({
        event: GTM_EVENT_BATMAID_LOGIN_STATE_CHANGED,
        isUserLoggedIn: String(isUserLoggedIn),
        ...(user?.get("hexHumanId") && {
          humanId: user?.get("hexHumanId"),
        }),
      });
    }
  }, [isUserLoggedIn, isUserDataFetched, user]);

  return null;
};

export { Analytics };
