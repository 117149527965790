import * as React from "react";
import { graphql, PageProps } from "gatsby";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { Section, Subheading, OptionPanel } from "@ui/Atoms";

import { langFromTag } from "@services/LangFromTag/LangFromTag";

import { HelpWidget } from "@soft/containers/HelpWidget";
import { ContactForm } from "@soft/containers/ContactForm";

import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import TopQuestions, { LinkedArticle } from "../../components/Top10Questions";

interface TopQuestions {
  document: {
    data: {
      linked_articles: LinkedArticle[];
    };
  };
}

type Props = PageProps<
  {
    prismicHelpPage: {
      data: {
        title: { text: string };
        seo_title: { text: string };
        top_questions: TopQuestions;
      };
    };
  },
  {
    lang: string;
    country: AVAILABLE_COUNTRIES;
    origin: ORIGIN;
  }
>;

const HelpPage = (props: Props): React.ReactElement => {
  const lang = langFromTag(props.pageContext.lang) || DEFAULT_LANGUAGE;
  const origin = props.pageContext.origin || ORIGIN.BATSOFT;
  const country = props.pageContext.country;

  const title = props.data.prismicHelpPage.data.title.text;
  const seoTitle = props.data.prismicHelpPage.data.seo_title.text;

  const hasTopQuestions =
    props.data.prismicHelpPage.data.top_questions?.document;

  return (
    <Layout origin={origin} language={lang} country={country}>
      <SEO title={seoTitle} path={props.location.pathname} />
      <Section small>
        <OptionPanel>
          <Subheading>{title}</Subheading>
          <HelpWidget
            language={lang}
            renderQuestions={() =>
              hasTopQuestions ? (
                <TopQuestions
                  lang={lang}
                  articles={
                    props.data.prismicHelpPage.data.top_questions?.document
                      ?.data.linked_articles
                  }
                />
              ) : null
            }
          />
        </OptionPanel>
      </Section>
      <Section small>
        <ContactForm />
      </Section>
    </Layout>
  );
};

export default HelpPage;

export const pageQuery = graphql`
  query HelpPageBatsoft($id: String) {
    prismicHelpPage(id: { eq: $id }) {
      data {
        country
        origin
        title {
          text
        }
        seo_title {
          text
        }
        top_questions {
          document {
            ... on PrismicTopQuestions {
              data {
                linked_articles {
                  article {
                    document {
                      ... on PrismicHelpArticle {
                        id
                        data {
                          title {
                            text
                          }
                          slug {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
