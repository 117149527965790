import * as React from "react";
import styled from "styled-components";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { ORIGIN } from "@typings/globals";

import { Section, OptionPanel, Loader, Container, Margins } from "@ui/Atoms";

import Layout from "../components/Layout";
import { linkResolver } from "../config/linkResolver";

interface Props {
  isPrismicPreview: boolean | null;
}

const PreviewPage = (props: Props): React.ReactElement => {
  return (
    <Layout language={DEFAULT_LANGUAGE} origin={ORIGIN.BATMAID} isFullWidth>
      <Container>
        <Section small>
          <OptionPanel>
            <Wrapper>
              {!props.isPrismicPreview ? (
                <div>Go to Prismic to use the Preview feature.</div>
              ) : (
                <React.Fragment>
                  <Loader />
                  <Margins xs={["base_x2", null, null, null]}>
                    <div>Loading Prismic preview...</div>
                  </Margins>
                </React.Fragment>
              )}
            </Wrapper>
          </OptionPanel>
        </Section>
      </Container>
    </Layout>
  );
};

export default withPrismicPreviewResolver(PreviewPage);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
