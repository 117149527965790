module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"batmaid","accessToken":"MC5ZUUV3R3hFQUFDUUFST0FI.Vx4bVu-_vXPvv73vv73vv73vv712L--_ve-_ve-_vSvvv71o77-9bRYVRAxfK--_vX4adO-_vTk"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://c2f61acafc7342d6bba3e6ccdb725276@sentry.io/1723021","environment":"production","release":"116.0","enabled":false},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Batmaid","short_name":"Batmaid","start_url":"/","background_color":"#f2f2f2","theme_color":"#1f1f1f","display":"standalone","icon":"../@assets/images/batmaid_bell_favicon.png","legacy":true,"prefer_related_applications":true,"related_applications":[],"crossOrigin":"use-credentials","theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"08f3e2b3996990d0c8420aae2a931695"},
    },{
      plugin: require('../../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Open Sans","file":"https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
