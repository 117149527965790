/**
 * @author jakubbujakowski
 * @since 2020-6-22
 */

import * as React from "react";
import styled from "styled-components";

import { Caret } from "@ui/Assets/Symbolicons/Caret";
import { rem } from "@ui/helpers";

import slugifyFacade from "@services/SlugifyFacade";

interface Path {
  href: string;
  title: string;
}

interface Props {
  paths: Path[];
  currentPath: string;
}

const Breadcrumbs = (props: Props) => (
  <React.Fragment>
    {props.paths.map(item => (
      <React.Fragment key={slugifyFacade(item.title)}>
        <a href={item.href}>{item.title}</a>
        <CaretWrapper>{<Caret size={10} variant="line" />}</CaretWrapper>
      </React.Fragment>
    ))}
    <span>{props.currentPath}</span>
  </React.Fragment>
);

Breadcrumbs.displayName = "Breadcrumbs";

export { Breadcrumbs };

const CaretWrapper = styled.span`
  line-height: ${rem(30)};
  padding: 0 ${props => props.theme.margins.half};

  svg {
    transform: rotate(-90deg);
  }
`;
