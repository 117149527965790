import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "styled-components";

interface Props
  extends React.PropsWithoutRef<NavLinkProps>,
    React.RefAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
}

const SupportButton = (props: Props): React.ReactElement => {
  const { children, ...rest } = props;

  return <LinkStyled {...rest}>{children}</LinkStyled>;
};

export { SupportButton };

const LinkStyled = styled(NavLink)`
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.margins.base_x2} ${theme.margins.base}`};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  color: ${props => props.theme.colors.blue400};
  border: 1px solid ${props => props.theme.colors.blue400};
  border-radius: ${props => props.theme.buttons.borderRadius};
  transition: background-color
    ${({ theme }) => `${theme.animations.duration} ${theme.animations.easing}`};
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.margins.base_x2};

  &:hover {
    background-color: ${props => props.theme.colors.blue50};
  }
`;
