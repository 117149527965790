type Result<T> = {
  [k in keyof T]: string;
};

export const stringifyObjectValues = <T extends object>(obj: T): Result<T> =>
  (Object.entries(obj) as any[]).reduce(
    (acc, item) => {
      const [key, value] = item;
      acc[key] = String(value);
      return acc;
    },
    {} as Result<T>,
  );
