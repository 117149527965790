import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const SmileyLanding = (props: Icon): React.ReactElement => (
  <svg
    viewBox="0 0 104 103"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M48 .087c26.641-1.457 49.207 15.465 54.847 41.006a48.942 48.942 0 01.93 15.213 50.037 50.037 0 01-3.625 14.428 51.933 51.933 0 01-7.59 12.713A52.393 52.393 0 0181.608 93.59a51.726 51.726 0 01-13.709 6.715c-3.215 1.02-6.534 1.773-9.886 2.185-.812.099-7.127.554-10.014.508v-4.819c1.861.044 3.832.106 5.703 0 1.87-.105 3.729-.225 5.565-.58 6.524-1.266 13.041-3.565 18.662-7.056a47.275 47.275 0 002.753-1.845 42.424 42.424 0 009.946-10.095c2.882-4.13 4.994-8.736 6.442-13.519.179-.59.347-1.181.507-1.776 5.147-19.254-.804-35.77-16.544-48.52C71.53 7.09 60.26 4.083 48 4.615V.087z"
        fill="#535252"
      />
      <path
        d="M47.997 103c-7.85-.842-14.744-2.532-21.013-6.157C7.843 85.777-1.351 69.012.16 46.9a49.803 49.803 0 015.3-19.144A51.585 51.585 0 0134.62 2.455C39 .954 43.402.318 47.997 0v4.531c0-.01-5.904.877-6.425.99-7.21 1.573-14.017 4.825-19.737 9.512a47.186 47.186 0 00-12.32 15.5C6.578 36.46 4.894 43.005 4.66 49.623c-.09 2.523.048 5.036.356 7.54C7.89 79.104 25.557 96.418 48 98.17L47.997 103z"
        fill="#121114"
      />
      <path
        d="M75.628 67c.65.994.303 1.97.048 2.95-1.846 7.095-7.984 12.603-15 14.905-3.454 1.133-11.215 1.172-11.642 1.137C49.032 85.452 49.004 67 49 67h26.628z"
        fill="#64C2E0"
      />
      <path
        d="M48.962 67L49 86c-10.752-.437-21.513-10.016-20.981-19h20.943z"
        fill="#27A7D2"
      />
      <path
        d="M34.46 52A6.465 6.465 0 0128 45.494c.018-3.583 2.961-6.52 6.51-6.494 3.541.027 6.489 3.001 6.49 6.551.001 3.54-2.96 6.46-6.54 6.449"
        fill="#121114"
      />
      <path
        d="M63 45.561c-.036-3.715 2.849-6.6 6.56-6.56 3.586.038 6.409 2.854 6.44 6.425.03 3.682-2.885 6.601-6.568 6.574-3.572-.026-6.397-2.855-6.432-6.439"
        fill="#525151"
      />
    </g>
  </svg>
);
