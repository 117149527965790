import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Bat = (props: Icon): React.ReactElement => (
  <svg
    style={{ ...style(props.size, props.height), ...props.style }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    ref={props.elementRef}
  >
    <defs>
      <path
        d="M18.667 8.667l-3.334 1v-3l-2 1.333h-2.666l-2-1.333v3l-3.334-1L4 17.333l2.667-2 1.333 2 2-2 2 2 2-2 2 2 1.333-2 2.667 2-1.333-8.666zm-8 2.666a.667.667 0 110-1.333.667.667 0 010 1.333zm2.666 0a.667.667 0 110-1.333.667.667 0 010 1.333z"
        id="prefix__a-bat"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b-bat" fill="#fff">
        <use xlinkHref="#prefix__a-bat" />
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#prefix__a-bat" />
      <g mask="url(#prefix__b-bat)" fill={props.color || theme.colors.black500}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
