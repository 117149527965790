/**
 * @author michalstolarski
 * @since 2023-1-18
 */

import React from "react";

import { SENTRY_ENABLED_PWA } from "@config/consts";

interface Props {
  data?: string;
  children: React.ReactElement;
}

/**
 * This component **modifies a children's props** by adding a custom class `sentry-`.
 * This component works only when `SENTRY_ENABLED_PWA` is `true`
 */
const SentryWrapper = (props: Props): React.ReactElement => {
  if (!SENTRY_ENABLED_PWA || !props.data) {
    return props.children;
  }

  if (React.Children.count(props.children) > 1) {
    throw new Error("Only one child is allowed within SentryWrapper.");
  }

  return React.Children.only(
    React.cloneElement(props.children, {
      className: `sentry-${props.data.replace(/ +/g, "-").toLocaleLowerCase()}`,
    }),
  );
};

export { SentryWrapper };
