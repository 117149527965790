import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CreditCardSecurityProtection = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M2.99625 7.99832H21.0037" />
      <path d="M9.49895 12H6.99791" />
      <path d="M9.99916 18.0025H5.9975C4.33995 18.0025 2.99625 16.6588 2.99625 15.0013V6.99792C2.99625 5.34038 4.33995 3.99667 5.9975 3.99667H18.0025C19.66 3.99667 21.0037 5.34038 21.0037 6.99792V10.9996" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4286 17.6353C13.4286 19.236 15.6775 20.4365 16.711 20.8947C17.0321 21.0397 17.4002 21.0397 17.7214 20.8947C18.7548 20.4315 21.0037 19.242 21.0037 17.6353V15.0603C21.0032 14.5285 20.6696 14.0541 20.1694 13.8738L18.2416 13.1795C17.5782 12.9404 16.8521 12.9404 16.1887 13.1795L14.2629 13.8728C13.7622 14.053 13.4284 14.5281 13.4286 15.0603V17.6353Z"
      />
    </svg>
  );
};
