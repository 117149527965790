import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const MaidLanding = (props: Icon): React.ReactElement => {
  return (
    <svg
      viewBox="0 0 112 104"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M45.302 103.768h1.052c-.575-1.944-7.852-24.558-8.389-26.513-.204-.748-.173-.92-.052-1.044 1.422-1.46.553-3.288.01-4.987-2.72-8.498-5.387-17.016-8.063-25.53-1.129-3.591-.597-4.662 2.782-5.825 1.075-.371 2.15-.771 3.176-1.265 1.504-.725 2.524-2.297 2.507-4.003-.016-1.801.962-2.662 2.28-3.62 2.168-1.573 2.639-3.38 1.708-5.4L.095 39.55 0 39.862c1.986 2.704 4.513 3.845 7.683 2.79 1.857 3.382 3.771 4.16 7.296 3.049.811-.255 1.61-.552 2.417-.818 1.949-.642 3.575.116 4.393 2.079.25.598.406 1.238.61 1.856 3.773 11.412 14.24 43.588 18.462 54.95h1.242c-.65-2.33-8.156-23.727-8.797-26.018.651.336 1.988-.298 3.405-.9l8.59 26.918z"
          fill="#0E0D10"
        />
        <path
          d="M74.995.123c13.93-1.065 26.46 10.16 27.901 24.057.652 6.284.319 12.582.265 18.867-.053 6.164.66 10.093 8.557 12.233.518 3.992-2.793 8.677-6.812 8.798-8.243.248-16.498.074-24.975.074 14.126-5.112 15.078-15.33 13.993-27.055-11.386-2.144-11.776-2.474-17.692-14.995l-1.166 3.02-.07-25"
          fill="#61C1E0"
        />
        <path
          d="M74.995.123c.024 8.333.048 16.666.07 24.999-2.525 8.25-8.656 11.28-16.634 11.85.05 5.107-.63 10.14 1.12 15.042 1.997 5.589 5.688 9.357 11.196 11.239.712.244 1.43.472 2.753.907-8.995 0-17.332.109-25.665-.055-3.23-.064-6.504-3.515-7.054-6.744-.221-1.302-.056-2.097 1.487-2.54 5.113-1.47 7.01-4.281 7.134-9.741.155-6.877-.375-13.77.309-20.632 1.187-11.917 9.988-21.7 21.491-23.96 1.247-.244 2.487-.71 3.793-.365"
          fill="#28A7D2"
        />
        <path
          d="M108.25 104c-4.038-2.058-7.154-4.46-8.956-8.365-1.367-2.963-2.818-5.885-4.266-8.808-.203-.411-.354-.776-.27-1.232 1.107-5.897 1.297-11.867 1.232-18.047 3.17.985 5.577 2.809 7.58 5.234 2.415 2.922 3.823 6.37 4.248 10.113.782 6.893.338 13.824.432 21.105"
          fill="#525150"
        />
        <path
          d="M45.887 101.916c.014-5.635-.192-11.283.093-16.903.415-8.166 3.739-14.587 11.454-17.941.178-.078.386-.085.723-.155-.042 4.666-.334 9.326.66 13.769.97 4.33-.765 7.672-2.397 11.258-2.126 4.678-4.819 8.764-9.552 11.034-.311.15-.556.467-.956.288l-.025-1.35"
          fill="#0B0A0D"
        />
        <path
          d="M75.002 74.783c6.589.12 12.384-2.206 17.834-6.171.52 1.747-.172 3.337-.243 4.936-.172 3.874-.443 7.744-.558 11.619-.052 1.753-.768 2.804-2.28 3.574-4.716 2.405-9.65 2.832-14.762 1.942l.01-15.9"
          fill="#504F4F"
        />
        <path
          d="M75.002 74.783l-.009 15.9c-4.541.135-8.36-1.901-12.108-4.195-.927-.567-1.055-1.331-1.174-2.342-.662-5.582.285-11.205-.646-17.113 4.319 3.682 8.876 6.209 13.937 7.75"
          fill="#0D0C0F"
        />
        <path
          d="M75.009 51.9h7.649c.033 3.619-3.248 5.81-7.656 5.163l.007-5.163"
          fill="#504F4F"
        />
        <path
          d="M75.009 51.9l-.007 5.163c-2.935-.627-4.335-2.044-4.974-5.13 1.667-.241 3.323-.186 4.98-.033"
          fill="#100F11"
        />
        <path
          d="M67.066 41.428c-1.377-.108-2.09-.974-2.061-2.29.027-1.29.711-2.23 2.118-2.21 1.403.02 2.053.96 2.053 2.268 0 1.339-.737 2.155-2.11 2.232"
          fill="#141316"
        />
        <path
          d="M87.544 39.183c-.084 1.348-.71 2.196-2.092 2.167-1.267-.026-1.907-.826-1.949-2.084-.044-1.366.637-2.267 1.968-2.299 1.336-.032 2.008.85 2.073 2.216"
          fill="#545353"
        />
      </g>
    </svg>
  );
};
