import { createSelector } from "reselect";
import { Map } from "immutable";
import { addDays, subDays, format, parseISO } from "date-fns";

import { EXTRA_SERVICES_BOOKING, CALENDAR_WEEK_SHIFT } from "@typings/booking";
import { ContractSummary, CONTRACT_SUMMARY_STATUS } from "@typings/contracts";

import mapToList from "@services/MapToList";
import { DATE_FORMAT } from "@services/DateFormatter";

import { IStore } from "@work/redux/reducers";

const getTaskFunction = (store: IStore, service: EXTRA_SERVICES_BOOKING) =>
  store.booking.getIn(["tasks", service]);

export const getTask = createSelector(getTaskFunction, f => f);

const getTasksFunction = (store: IStore) => Map(store.booking.get("tasks"));

export const getTasks = createSelector(getTasksFunction, f => f);

const getPricingFunction = (store: IStore) => store.booking.get("pricing");

export const getPricing = createSelector(getPricingFunction, f => f);

const getSelectedCleaningFrequencyFunction = (store: IStore) =>
  store.booking.get("frequency");

export const getSelectedCleaningFrequency = createSelector(
  getSelectedCleaningFrequencyFunction,
  f => f,
);

const getKeyPickFunction = (store: IStore) => store.booking.get("keyPick");

export const getKeyPick = createSelector(getKeyPickFunction, f => f);

const getLocationCommentFunction = (store: IStore) =>
  store.booking.get("locationComment");

export const getLocationComment = createSelector(
  getLocationCommentFunction,
  f => f,
);

const getPetsInfoFunction = (store: IStore) => store.booking.get("hasPets");

export const getPetsInfo = createSelector(getPetsInfoFunction, f => f);

const getSpecialInstructionsFunction = (store: IStore) =>
  store.booking.get("specialInstructions");

export const getSpecialInstructions = createSelector(
  getSpecialInstructionsFunction,
  f => f,
);

const getTotalCleaningTimeFunction = (store: IStore) =>
  getTasks(store)
    .valueSeq()
    .filter(task => task.get("selected"))
    .reduce((acc: number, task) => acc + task.get("duration"), 0);

export const getTotalCleaningTime = createSelector(
  getTotalCleaningTimeFunction,
  f => f,
);

const getBookingAvailabilityFunction = (store: IStore) =>
  store.booking.get("calendar");

export const getBookingAvailability = createSelector(
  getBookingAvailabilityFunction,
  f => f,
);

const getExecutionDateTimeFunction = (store: IStore) =>
  store.booking.get("executionDateTime");

export const getExecutionDateTime = createSelector(
  getExecutionDateTimeFunction,
  f => f,
);

const getCalendarWeekFunction = (store: IStore, week: CALENDAR_WEEK_SHIFT) => {
  const weekStartDate = store.booking.getIn(["calendar", "weekStartDate"]);
  const currentWeek = parseISO(weekStartDate);

  return week === CALENDAR_WEEK_SHIFT.PREVIOUS
    ? format(subDays(currentWeek, 7), DATE_FORMAT.YEAR_MONTH_DAY)
    : format(addDays(currentWeek, 7), DATE_FORMAT.YEAR_MONTH_DAY);
};

export const getCalendarWeek = createSelector(getCalendarWeekFunction, f => f);

const getServiceLocationIdFunction = (store: IStore) =>
  store.booking.get("serviceLocationId");

export const getServiceLocationId = createSelector(
  getServiceLocationIdFunction,
  f => f,
);

const getRoomQuantityFunction = (
  store: IStore,
  roomType: "bedrooms" | "bathrooms",
) => getTask(store, EXTRA_SERVICES_BOOKING.HOME_CLEANING).get(roomType);

export const getRoomQuantity = createSelector(getRoomQuantityFunction, f => f);

const getReservationUuidFunction = (store: IStore) =>
  store.booking.get("reservationUuid");

export const getReservationUuid = createSelector(
  getReservationUuidFunction,
  f => f,
);

const getWeekStartDateFunction = (store: IStore) =>
  store.booking.getIn(["calendar", "weekStartDate"]);

export const getWeekStartDate = createSelector(
  getWeekStartDateFunction,
  f => f,
);

const getSelectedTasksListFunction = (store: IStore) =>
  mapToList(getTasks(store)).filter(item => item.get("selected"));

export const getSelectedTasksList = createSelector(
  getSelectedTasksListFunction,
  f => f,
);

const getSicknessInsuranceFunction = (store: IStore) =>
  store.booking.get("sicknessInsurance");

export const getSicknessInsurance = createSelector(
  getSicknessInsuranceFunction,
  f => f,
);

const getPrevSicknessInsuranceFunction = (store: IStore) =>
  store.booking.get("prevSicknessInsurance");

export const getPrevSicknessInsurance = createSelector(
  getPrevSicknessInsuranceFunction,
  f => f,
);

const getSicknessInsuranceCostsFunction = (store: IStore) =>
  store.booking.get("sicknessInsuranceCosts");

export const getSicknessInsuranceCosts = createSelector(
  getSicknessInsuranceCostsFunction,
  f => f,
);

const getSecondPillarRetirementFunction = (store: IStore) =>
  store.booking.get("secondPillarRetirement");

export const getSecondPillarRetirement = createSelector(
  getSecondPillarRetirementFunction,
  f => f,
);

const getPrevSecondPillarRetirementFunction = (store: IStore) =>
  store.booking.get("prevSecondPillarRetirement");

export const getPrevSecondPillarRetirement = createSelector(
  getPrevSecondPillarRetirementFunction,
  f => f,
);

const getSecondPillarRetirementCostsFunction = (store: IStore) =>
  store.booking.get("secondPillarRetirementCosts");

export const getSecondPillarRetirementCosts = createSelector(
  getSecondPillarRetirementCostsFunction,
  f => f,
);

const getChosenAgentIdsFunction = (store: IStore) =>
  store.booking.get("chosenAgentIds");

export const getChosenAgentIds = createSelector(
  getChosenAgentIdsFunction,
  f => f,
);

const getChosenAgentsFunction = (store: IStore) =>
  store.booking.get("chosenAgents");

export const getChosenAgents = createSelector(getChosenAgentsFunction, f => f);

const getChosenAgentIdFunction = (store: IStore) =>
  store.booking.get("chosenAgentId");

export const getChosenAgentId = createSelector(
  getChosenAgentIdFunction,
  f => f,
);

const getAgentByIdFunction = (store: IStore, id: string | null) =>
  id ? store.booking.getIn(["chosenAgents", id]) : null;

export const getAgentById = createSelector(getAgentByIdFunction, f => f);

const getProfessionalAccidentInsuranceFunction = (store: IStore) =>
  store.booking.get("professionalAccidentInsurance");

export const getProfessionalAccidentInsurance = createSelector(
  getProfessionalAccidentInsuranceFunction,
  f => f,
);

const getAgreeToProfessionalAccidentInsuranceFunction = (store: IStore) =>
  store.booking.get("agreeToProfessionalAccidentInsurance");

export const getAgreeToProfessionalAccidentInsurance = createSelector(
  getAgreeToProfessionalAccidentInsuranceFunction,
  f => f,
);

const getInsurancePolicyNumberFunction = (store: IStore) =>
  store.booking.get("insurancePolicyNumber");

export const getInsurancePolicyNumber = createSelector(
  getInsurancePolicyNumberFunction,
  f => f,
);

const getInsurancePolicyWillBeProvidedLaterFunction = (store: IStore) =>
  store.booking.get("insurancePolicyWillBeProvidedLater");

export const getInsurancePolicyWillBeProvidedLater = createSelector(
  getInsurancePolicyWillBeProvidedLaterFunction,
  f => f,
);

const getQueryParamsFunction = (store: IStore) =>
  store.booking.get("queryParams");

export const getQueryParams = createSelector(getQueryParamsFunction, f => f);

const getSalaryDefaultsFunction = (store: IStore) =>
  store.booking.get("salaryDefaults");

export const getSalaryDefaults = createSelector(
  getSalaryDefaultsFunction,
  f => f,
);

const getClientRegistrationFormFunction = (store: IStore) =>
  store.booking.get("form");

export const getClientRegistrationForm = createSelector(
  getClientRegistrationFormFunction,
  f => f,
);

const getMainLocationUuidFunction = (store: IStore) =>
  store.booking.get("mainLocationUuid");

export const getMainLocationUuid = createSelector(
  getMainLocationUuidFunction,
  f => f,
);

const getLocationUuidFunction = (store: IStore) =>
  store.booking.get("locationUuid");

export const getLocationUuid = createSelector(getLocationUuidFunction, f => f);

const getSliderSupergrossSalaryFunction = (store: IStore) =>
  store.booking.get("sliderSuperGrossSalary");

export const getSliderSupergrossSalary = createSelector(
  getSliderSupergrossSalaryFunction,
  f => f,
);

const getSalaryDefaultsFetchedStatusFunction = (store: IStore) =>
  store.booking.get("salaryDefaultsFetched");

export const getSalaryDefaultsFetchedStatus = createSelector(
  getSalaryDefaultsFetchedStatusFunction,
  f => f,
);

const getSliderChangeCountFunction = (store: IStore) =>
  store.booking.get("sliderChangeCount");

export const getSliderChangeCount = createSelector(
  getSliderChangeCountFunction,
  f => f,
);

const getSalaryFunction = (store: IStore) => store.booking.get("salary");

export const getSalary = createSelector(getSalaryFunction, f => f);

const getHasSomePaymentAliasesFunction = (store: IStore) =>
  store.booking.get("hasSomePaymentAliases");

export const getHasSomePaymentAliases = createSelector(
  getHasSomePaymentAliasesFunction,
  f => f,
);

const getPlanFunction = (store: IStore) => store.booking.get("plan");

export const getPlan = createSelector(getPlanFunction, f => f);

const getIsCheckoutDoneFunction = (store: IStore) =>
  store.booking.get("isCheckoutDone");

export const getIsCheckoutDone = createSelector(
  getIsCheckoutDoneFunction,
  f => f,
);

const getPreviousEmployeeLatestActiveContractFunction = (
  store: IStore,
): null | ContractSummary => {
  const prevEmployee = store.booking.get("previousEmployee");

  if (!prevEmployee) {
    return null;
  }

  const contracts: ContractSummary[] =
    prevEmployee && prevEmployee.get("contracts");

  if (!contracts.length) {
    return null;
  }

  const activeContracts = contracts
    .sort(
      (a, b) =>
        new Date(a.creationDate).getTime() - new Date(b.creationDate).getTime(),
    )
    .filter(a => a.status === CONTRACT_SUMMARY_STATUS.ACTIVE);

  return activeContracts ? activeContracts[0] : null;
};

export const getPreviousEmployeeLatestActiveContract = createSelector(
  getPreviousEmployeeLatestActiveContractFunction,
  f => f,
);

const getPreviousEmployeeFunction = (store: IStore) =>
  store.booking.get("previousEmployee");

export const getPreviousEmployee = createSelector(
  getPreviousEmployeeFunction,
  f => f,
);

const getHasPreviousActiveContractFunction = (store: IStore) =>
  store.booking.get("hasPreviousActiveContract");

export const getHasPreviousActiveContract = createSelector(
  getHasPreviousActiveContractFunction,
  f => f,
);

const getRelaxPlanRedirectUrlFunction = (store: IStore) =>
  store.booking.get("relaxPlanRedirectUrl");

export const getRelaxPlanRedirectUrl = createSelector(
  getRelaxPlanRedirectUrlFunction,
  f => f,
);

const getIsRelaxPlanCompletedFunction = (store: IStore) =>
  store.booking.get("isRelaxPlanCompleted");

export const getIsRelaxPlanCompleted = createSelector(
  getIsRelaxPlanCompletedFunction,
  f => f,
);

const getPreviousAgentsFunction = (store: IStore) =>
  store.booking.get("previousAgents");

export const getPreviousAgents = createSelector(
  getPreviousAgentsFunction,
  f => f,
);
