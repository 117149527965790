import * as React from "react";

export const useActiveElement = () => {
  const [activeElement, setActiveElement] = React.useState(
    document.activeElement,
  );
  const [isFocusedOnTextInputOrTextarea, setIsFocusedOnTextInputOrTextarea] =
    React.useState(false);

  const handleFocusIn = (): void => setActiveElement(document.activeElement);

  const handleFocusOut = (): void => setActiveElement(document.activeElement);

  React.useEffect(() => {
    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);

    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };
  }, []);

  React.useEffect(() => {
    const isFocusedOnTextInputOrTextarea =
      (activeElement?.nodeName === "INPUT" &&
        activeElement?.getAttribute("type") !== "radio" &&
        activeElement?.getAttribute("type") !== "checkbox") ||
      activeElement?.nodeName === "TEXTAREA";

    setIsFocusedOnTextInputOrTextarea(isFocusedOnTextInputOrTextarea);
  }, [activeElement]);

  return {
    activeElement,
    isFocusedOnTextInputOrTextarea,
  };
};

export const forceBlur = (): void => {
  try {
    (document.activeElement as HTMLElement).blur();
  } catch {
    // silent Error from activeElement
    // the "Element" type doesn't have "blur" method
  }
};
