import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const ForkSpoonKnife = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M4.5 10.5V13" />
      <rect x="3" y="13" width="3" height="8" rx="1.5" />
      <path d="M4.5 7.5V3" />
      <path d="M7 3V8.5C7 9.60457 6.10457 10.5 5 10.5H4C2.89543 10.5 2 9.60457 2 8.5V3" />
      <path d="M7 7.5H2" />
      <path d="M12.5 10V13" />
      <rect x="11" y="13" width="3" height="8" rx="1.5" />
      <ellipse cx="12.5" cy="6.5" rx="2.5" ry="3.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 3.00919L21.9908 3C19.7867 3 18 5.97791 18 9.65135V14.6C18 14.8209 18.1791 15 18.4 15H22L21.9908 3.00919H22Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 15H22V19.5C22 20.3284 21.3284 21 20.5 21V21C19.6716 21 19 20.3284 19 19.5V15Z"
      />
    </svg>
  );
};
