import { AnyAction } from "redux";

import {
  SERVICE_LOCATION_BY_ID_ENDPOINT,
  SERVICE_LOCATIONS_ENDPOINT,
} from "@config/endpoints";

import { ThunkActionDispatch } from "@typings/work.reduxThunkActions";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { buildUrl } from "@services/BuildUrl";

import { asyncActionCreator } from "@work/services/AsyncActionCreator";

const actionCreator = new ReduxActionNameCreator("serviceLocation", true);

export const FETCH_SERVICE_LOCATION: string = actionCreator.make(
  "fetch service location",
);
export const FETCH_SERVICE_LOCATION_COVERAGE = actionCreator.make(
  "fetch service location coverage",
);
export const RESET_STATE = actionCreator.make("reset state");

export const fetchServiceLocation =
  (serviceLocationId: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: SERVICE_LOCATION_BY_ID_ENDPOINT.replace("{id}", serviceLocationId),
      action: FETCH_SERVICE_LOCATION,
    });

    return dispatch(action);
  };

export const fetchServiceLocationCoverage =
  (zip: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: buildUrl(SERVICE_LOCATIONS_ENDPOINT)
        .setQuery("zip", zip)
        .setQuery("serviceType", "", { optional: true })
        .done(),
      action: FETCH_SERVICE_LOCATION_COVERAGE,
    });

    return dispatch(action);
  };

export const resetState = (): AnyAction => ({
  type: RESET_STATE,
});
