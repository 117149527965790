import { Header } from "./SubpageLayout.styled";
import { Layout } from "./Layout";
import { Heading } from "./Heading";
import { BackButton } from "./BackButton";

export const SubpageLayout = {
  Layout,
  Header,
  Heading,
  BackButton,
};
