import { AVAILABLE_LANGS, TRUSTPILOT_AVAILABLE_LANGS } from "@typings/globals";

export const langInTrustpilotFormat = (
  lang: AVAILABLE_LANGS,
): TRUSTPILOT_AVAILABLE_LANGS => {
  switch (lang) {
    case AVAILABLE_LANGS.FR:
      return TRUSTPILOT_AVAILABLE_LANGS.FR;
    case AVAILABLE_LANGS.PL:
      return TRUSTPILOT_AVAILABLE_LANGS.PL;
    case AVAILABLE_LANGS.IT:
      return TRUSTPILOT_AVAILABLE_LANGS.IT;
    case AVAILABLE_LANGS.DE:
      return TRUSTPILOT_AVAILABLE_LANGS.DE;
    case AVAILABLE_LANGS.NL:
      return TRUSTPILOT_AVAILABLE_LANGS.NL;
    case AVAILABLE_LANGS.EN:
    default:
      return TRUSTPILOT_AVAILABLE_LANGS.EN;
  }
};
