/**
 * Achtung!
 * If you are making any changes to this component, please remember
 * to check if it renders correctly for all country-related variants.
 * Currently supported countries are PL & FR.
 */
import React from "react";
import styled, { css } from "styled-components";
import { IGatsbyImageData } from "gatsby-plugin-image";

import { AVAILABLE_LANGS } from "@typings/globals";

import { Heading } from "@ui/Atoms";
import { Caret } from "@ui/Assets/Symbolicons";

import { GatsbyImage } from "../../components/GatsbyImage";

interface SpecialBannerProps {
  special_banner_image: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  special_banner_link_text: {
    text: string;
  };
  special_banner_link_url: {
    document: {
      data: {
        slug: {
          text: string;
        };
      };
    };
    target: string | null;
  };
  special_banner_title: {
    text: string;
  };
}

interface Props extends SpecialBannerProps {
  language: AVAILABLE_LANGS;
}

const SpecialBanner = (props: Props): React.ReactElement => {
  return (
    <SpecialBannerWrapper>
      {props.special_banner_image.gatsbyImageData && (
        <ImageWrapper>
          <GatsbyImage
            alt={props.special_banner_image.alt}
            image={props.special_banner_image.gatsbyImageData}
          />
        </ImageWrapper>
      )}
      <ContentWrapper withImage={!!props.special_banner_image.gatsbyImageData}>
        <Heading type={"h6"} color="white" noMarginBotton>
          {props.special_banner_title.text}
        </Heading>
        <Link
          href={`/${props.language}/${props.special_banner_link_url.document.data.slug.text}`}
          target={props.special_banner_link_url.target || "_blank"}
        >
          {props.special_banner_link_text.text}{" "}
          <Caret size={20} height={20} variant={"line"} />
        </Link>
      </ContentWrapper>
    </SpecialBannerWrapper>
  );
};

const ImageWrapper = styled.div`
  max-width: 320px;
  margin-left: -180px;

  ${props => props.theme.breakpoints.tablet} {
    margin-left: 0;
  }
`;

const ContentWrapper = styled.div<{ withImage: boolean }>`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin: ${props => props.theme.margins.base};

  ${props =>
    props.withImage &&
    css`
      flex-shrink: 0;
      margin: 0 ${props.theme.margins.base} 0 0;
    `}
`;

const Link = styled.a`
  display: flex;
  color: ${props => props.theme.colors.greyDark};
  font-size: ${props => props.theme.fonts.sizes.caption};

  svg {
    transform: rotate(-90deg);

    use {
      fill: ${props => props.theme.colors.greyDark};
    }
  }

  &:hover {
    color: ${props => props.theme.colors.white};

    svg {
      use {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
`;

const SpecialBannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: ${props => props.theme.colors.black400};
  min-height: 64px;
`;

export { SpecialBanner, SpecialBannerProps };
