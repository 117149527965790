export enum APP {
  MY_CLEANINGS = "MyCleanings",
  BOOKING = "Booking",
  HC_BOOKING_ZIP_INPUT = "homeCleaningPostalInput",
  END_OF_TENANCY = "EndOfTenancy",
  B2B = "B2B",
  PAYMENT_PROCESSED = "PaymentProcessed",
  APPLY = "Apply",
  LANDING_PAGE = "LandingPage",
  SUPPORT = "Support",
  ONBOARDING = "Onboarding",
}

export enum HOMEPAGE {
  LETS_GO_BTN = "letsGoButton",
  ZIP_INPUT = "zipInput",
  HERO_NAV_ITEM = "heroNavItem",
  VIDEO_SECTION = "videoSection",
  FOOTER_CONTACT = "footerContactList",
  FOOTER_NAVIGATION = "footerNavigation",
  FOOTER_SOCIAL = "footerSocial",
}
