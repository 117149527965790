import { AVAILABLE_COUNTRIES } from "@typings/globals";

export enum FOOTER_TYPE {
  DEFAULT = "Default",
}

export interface PrismicFooter {
  lang: string;
  data: {
    origin: string;
    country: AVAILABLE_COUNTRIES;
    app_coming_soon_text: {
      text: string;
    };
    app_download_header: {
      text: string;
    };
    contact_media: {
      text: string;
    };
    coming_soon: {
      text: string;
    };
    contact_us: {
      text: string;
    };
    email: {
      text: string;
    };
    follow_us_header: {
      text: string;
    };
    hashtag_links: {
      text: string;
    };
    links: {
      text: string;
    };
    locations_header: {
      text: string;
    };
    locations_left_column: {
      text: string;
    };
    locations_right_column: {
      text: string;
    };
    navigation_header: {
      text: string;
    };
    offices_header: {
      text: string;
    };
    opening_hours: {
      text: string;
    };
    payment_options: {
      text: string;
    };
    review_us_header: {
      text: string;
    };
    telephone: {
      text: string;
    };
    terms_of_use_text: {
      text: string;
    };
    we_operate_in: {
      text: string;
    };
    android_app_url: {
      url: string;
      target: string;
    };
    ios_app_url: {
      url: string;
      target: string;
    };
    trustpilot_url: {
      url: string;
    };
    google_review_url: {
      url: string;
    };
  };
}

export interface Contact {
  email: string;
  openingHours: string;
  paymentOptions: string;
  telephone: string;
  contactUs: string;
  contactMedia: string;
}

export interface FooterGraphQlData {
  navigationHeader: string;
  officesHeader: string;
  locationsHeader: string;
  appDownloadHeader: string;
  reviewUsHeader: string;
  followUsHeader: string;
  termsOfUseText: string;
  appComingSoonText: string;

  cities: string;
  links: string;
  comingSoonBody: string;
  hashtags?: string;
  hashtagLinks?: string;
  locationsLeftColumn?: string;
  locationsRightColumn?: string;
  contact?: Contact;

  androidAppUrl?: string;
  iosAppUrl?: string;

  trustpilotUrl?: string;
  googleReviewUrl?: string;
}

export interface DryFooterGraphQlData {
  reviewUsHeader: string;
  followUsHeader: string;

  hashtagLinks: string;
  locationsLeftColumn: string;
  locationsRightColumn?: string;
  contact?: Contact;
}
