/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import {
  DEFAULT_LANGUAGE,
  LANGS_BY_COUNTRY,
  EXCLUDED_COUNTRIES,
} from "@config/consts";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { capitalize } from "@services/Capitalize";

import { PrismicImageField } from "@www/graphql-types";

const getDomainByCountry = () => {
  switch (process.env.COUNTRY as AVAILABLE_COUNTRIES) {
    case AVAILABLE_COUNTRIES.CH:
      return process.env.DOMAIN_CH;
    case AVAILABLE_COUNTRIES.LU:
      return process.env.DOMAIN_LU;
    case AVAILABLE_COUNTRIES.FR:
      return process.env.DOMAIN_FR;
    case AVAILABLE_COUNTRIES.DE:
      return process.env.DOMAIN_DE;
    case AVAILABLE_COUNTRIES.PL:
      return process.env.DOMAIN_PL;
    case AVAILABLE_COUNTRIES.IT:
      return process.env.DOMAIN_IT;
    case AVAILABLE_COUNTRIES.AT:
      return process.env.DOMAIN_AT;
    case AVAILABLE_COUNTRIES.BE:
      return process.env.DOMAIN_BE;
    case AVAILABLE_COUNTRIES.NL:
      return process.env.DOMAIN_NL;
    case AVAILABLE_COUNTRIES.US:
      return process.env.DOMAIN_US;
    case AVAILABLE_COUNTRIES.UK:
      return process.env.DOMAIN_UK;
    default:
      return null;
  }
};

const IOS_APP_SUPPORTED_COUNTRIES: string[] = [
  AVAILABLE_COUNTRIES.CH,
  AVAILABLE_COUNTRIES.FR,
  AVAILABLE_COUNTRIES.LU,
  AVAILABLE_COUNTRIES.PL,
  AVAILABLE_COUNTRIES.IT,
  AVAILABLE_COUNTRIES.DE,
  AVAILABLE_COUNTRIES.NL,
  AVAILABLE_COUNTRIES.BE,
];

const hasIosSmartBanner = () =>
  process.env.ORIGIN === ORIGIN.BATMAID &&
  process.env.COUNTRY &&
  IOS_APP_SUPPORTED_COUNTRIES.includes(process.env.COUNTRY);

const breadcrumbCreator = (breadcrumbs: BreadcrumbCreatorItem[]) => {
  const domain = getDomainByCountry();

  const items = breadcrumbs.map((item, idx) => ({
    "@type": "ListItem",
    position: idx + 1,
    name: item.title,
    item: `https://${domain}${item.href}`,
  }));

  return `
  {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": ${JSON.stringify(items)}
    }`;
};

const getCanonicalLink = (path?: string): string | null => {
  const domain = getDomainByCountry();

  if (path === undefined || !domain) {
    return null;
  }

  return `https://${domain}${path}`;
};

interface Meta {
  name: string;
  content: string;
}

// SEO data from Prismic
export interface SeoProps {
  seo_title?: { text: string };
  seo_description?: { text: string };
  seo_image?: PrismicImageField;
}

interface BreadcrumbCreatorItem {
  title: string;
  href: string;
}

interface Props extends SeoProps {
  description: string;
  lang: string;
  meta: Meta[];
  title: string;
  structuredData?: string;
  breadcrumbs?: BreadcrumbCreatorItem[];
  withHreflangs?: boolean;
  path?: string;
}

const SEO = (props: Props): React.ReactElement => {
  const { site } = useStaticQuery(
    graphql`
      query SEOQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const country = process.env.COUNTRY;

  const getTitleTemplate = (origin?: ORIGIN): string => {
    return origin
      ? `%s | ${capitalize(origin)}`
      : `%s | ${site.siteMetadata.title}`;
  };

  const titleTemplate = getTitleTemplate(
    (process.env.ORIGIN as ORIGIN) || (ORIGIN.BATMAID as ORIGIN),
  );

  const getMetaTitle = (): string => props.seo_title?.text || props.title;

  const metaDescription = props.description || site.siteMetadata.description;

  const getMetaDescription = (): string =>
    props.seo_description?.text || metaDescription;

  const getMetaImage = (): string => {
    return props.seo_image?.gatsbyImageData?.images?.fallback?.src || "";
  };

  const smartBanner = hasIosSmartBanner()
    ? {
        name: "apple-itunes-app",
        content: "app-id=1527257152",
      }
    : {};

  const hrefLangs = Object.keys(LANGS_BY_COUNTRY)
    .filter(
      (country: AVAILABLE_COUNTRIES) => !EXCLUDED_COUNTRIES.includes(country),
    )
    .reduce(
      (acc: string[], country: AVAILABLE_COUNTRIES): string[] =>
        acc.concat(
          [],
          LANGS_BY_COUNTRY[country]
            .map(lang => `${lang}-${country}`)
            .filter(
              hreflang => hreflang !== `${props.lang}-${process.env.COUNTRY}`,
            ),
        ),
      [],
    );

  const canonicalLink = getCanonicalLink(props.path);

  return (
    <Helmet
      htmlAttributes={{
        lang: props.lang,
      }}
      title={props.title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: getMetaDescription(),
        },
        {
          property: `og:title`,
          content: getMetaTitle(),
        },
        {
          property: `og:description`,
          content: getMetaDescription(),
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: getMetaTitle(),
        },
        {
          name: `twitter:description`,
          content: getMetaDescription(),
        },
        {
          name: `og:image`,
          content: getMetaImage(),
        },
        smartBanner,
      ].concat(props.meta)}
    >
      <link rel="preconnect" href="https://images.prismic.io/" />
      {props.structuredData && (
        <script type="application/ld+json">{props.structuredData}</script>
      )}
      {props.breadcrumbs?.length && (
        <script type="application/ld+json">
          {breadcrumbCreator(props.breadcrumbs)}
        </script>
      )}
      {props.withHreflangs &&
        hrefLangs.map(hreflang => (
          <link
            key={hreflang}
            rel="alternate"
            hrefLang={hreflang}
            href={`https://${process.env.ORIGIN}.${hreflang.split("-")[1]}/${
              hreflang.split("-")[0]
            }`}
          />
        ))}
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: DEFAULT_LANGUAGE,
  meta: [],
  description: "",
};

export default SEO;
