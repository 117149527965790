import { AnyAction } from "redux";

import { ThunkActionDispatch } from "@typings/work.reduxThunkActions";

import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import {
  APPLY_ENDPOINT,
  APPLY_SUBMIT_FILES_ENDPOINT,
  APPLY_DELETE_FILES_ENDPOINT,
  APPLY_AUTH_ENDPOINT,
} from "@config/endpoints";
import {
  HR_APPLY_PERSONAL_INFO,
  HR_APPLY_EXPERIENCE_INFO,
  HR_APPLY_AUTH_TOKEN,
  MULTIPART_FORM_DATA,
} from "@config/contentTypes";

import { asyncActionCreator } from "@work/services/AsyncActionCreator";

import { IStore } from "@work/redux/reducers";
import {
  setLocalStorageHelper,
  setFromLocalStorageHelper,
  deleteLocalStorageHelper,
} from "@work/redux/helpers";

import { getCountry } from "@work/redux/localisation/localisation.selectors";

import {
  ApplyForm,
  SOURCES_OF_EXPERIENCE,
  LOCAL_STORAGE_KEY,
} from "./apply.typings";
import {
  getSubmitEmailEndpointRequest,
  getAsyncActionHRConfig,
  getSubmitPersonalInfoEndpointRequest,
  getAsyncActionHRConfigSecure,
  getSubmitExperienceInfoEndpointRequest,
} from "./apply.repository";

const action = new ReduxActionNameCreator("apply");

export const SET_FORM = action.make("set form");
export const SET_EXPERIENCE_SOURCES = action.make("set experience sources");
export const SUBMIT_APPLICATION_FILE = action.make("submit application file");
export const DELETE_APPLICATION_FILE = action.make("delete application file");
export const SUBMIT_EMAIL = action.make("submit email");
export const FETCH_PREFILLED_DATA = action.make("fetch prefilled data");
export const SUBMIT_PERSONAL_INFO = action.make("submit personal info");
export const SUBMIT_EXPERIENCE_INFO = action.make("submit personal info");
export const AUTHORIZE_BY_TOKEN = action.make("authorize by token");
export const DELETE_ACCESS_TOKEN = action.make("delete access token");

/** LOCAL STORAGE ACTIONS */
export const SET_IN_LOCAL_STORAGE = action.make("set in localstorage");
export const SET_FROM_LOCAL_STORAGE = action.make("set from localstorage");
export const DELETE_IN_LOCAL_STORAGE = action.make("delete in localstorage");

export const setForm = (payload: Partial<ApplyForm>): AnyAction => ({
  type: SET_FORM,
  payload,
});

export const setExperienceSources = (
  payload: SOURCES_OF_EXPERIENCE[],
): AnyAction => ({
  type: SET_EXPERIENCE_SOURCES,
  payload,
});

export const submitApplicationFile = (file: File) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const formData = new FormData();
  formData.append("file", file);

  const action = await asyncActionCreator(
    {
      url: APPLY_SUBMIT_FILES_ENDPOINT,
      action: SUBMIT_APPLICATION_FILE,
      method: ASYNC_ACTION_TYPES.POST,
      formData,
    },
    getAsyncActionHRConfigSecure(store(), {
      contentType: MULTIPART_FORM_DATA,
    }),
  );

  return dispatch(action);
};

export const deleteApplicationFile = (id: string) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator(
    {
      url: APPLY_DELETE_FILES_ENDPOINT.replace("{fileId}", id),
      action: DELETE_APPLICATION_FILE,
      method: ASYNC_ACTION_TYPES.DELETE,
      params: { id },
    },
    getAsyncActionHRConfigSecure(store()),
  );

  return dispatch(action);
};

export const submitEmail = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const body = getSubmitEmailEndpointRequest(store());

  const action = await asyncActionCreator(
    {
      url: APPLY_ENDPOINT,
      action: SUBMIT_EMAIL,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    },
    getAsyncActionHRConfig(),
  );

  return dispatch(action);
};

export const fetchPrefilledData = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator(
    {
      url: APPLY_ENDPOINT,
      action: FETCH_PREFILLED_DATA,
      method: ASYNC_ACTION_TYPES.GET,
    },
    getAsyncActionHRConfigSecure(store()),
  );

  return dispatch(action);
};

export const submitPersonalInfo = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const body = getSubmitPersonalInfoEndpointRequest(store());

  const action = await asyncActionCreator(
    {
      url: APPLY_ENDPOINT,
      action: SUBMIT_PERSONAL_INFO,
      method: ASYNC_ACTION_TYPES.PATCH,
      body,
    },
    getAsyncActionHRConfigSecure(store(), {
      contentType: HR_APPLY_PERSONAL_INFO,
    }),
  );

  return dispatch(action);
};

export const submitExperienceInfo = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const body = getSubmitExperienceInfoEndpointRequest(store());
  const country = getCountry(store()).toLowerCase();

  const action = await asyncActionCreator(
    {
      url: APPLY_ENDPOINT,
      action: SUBMIT_EXPERIENCE_INFO,
      method: ASYNC_ACTION_TYPES.PATCH,
      body,
    },
    getAsyncActionHRConfigSecure(store(), {
      contentType: HR_APPLY_EXPERIENCE_INFO.replace("{country}", country),
    }),
  );

  return dispatch(action);
};

export const authorizeByToken = (authToken: string) => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): Promise<AsyncActionResult> => {
  const action = await asyncActionCreator(
    {
      url: APPLY_AUTH_ENDPOINT,
      action: AUTHORIZE_BY_TOKEN,
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        authToken,
      },
    },
    getAsyncActionHRConfigSecure(store(), {
      contentType: HR_APPLY_AUTH_TOKEN,
    }),
  );

  return dispatch(action);
};

export const deleteAccessToken = (): AnyAction => ({
  type: DELETE_ACCESS_TOKEN,
});

export const setLocalStorage = () => (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
): void => {
  setLocalStorageHelper(
    dispatch,
    SET_IN_LOCAL_STORAGE,
    store().apply,
    LOCAL_STORAGE_KEY,
  );
};

export const setFromLocalStorage = () => (
  dispatch: ThunkActionDispatch,
): void => {
  setFromLocalStorageHelper(
    dispatch,
    SET_FROM_LOCAL_STORAGE,
    LOCAL_STORAGE_KEY,
  );
};

export const deleteLocalStorage = () => (
  dispatch: ThunkActionDispatch,
): void => {
  deleteLocalStorageHelper(
    dispatch,
    DELETE_IN_LOCAL_STORAGE,
    LOCAL_STORAGE_KEY,
  );
};
