import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const SpiderWeb = (props: Icon): React.ReactElement => (
  <svg
    style={{ ...style(props.size, props.height), ...props.style }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M12 4c.342 0 .624.257.662.589l.005.078-.001.226 3.848 1.649.348-.347a.667.667 0 01.998.88l-.055.063-.348.347 1.649 3.848h.227a.667.667 0 01.078 1.33l-.078.004-.227-.001-1.649 3.848.348.348a.667.667 0 01-.88.998l-.063-.055-.348-.348-3.848 1.649v.227a.667.667 0 01-1.328.078l-.005-.078v-.227l-3.848-1.649-.347.348a.667.667 0 01-.998-.88l.055-.063.347-.348-1.649-3.848h-.226a.667.667 0 01-.078-1.328l.078-.005h.226l1.649-3.848-.347-.347a.667.667 0 01.88-.998l.063.055.347.347 3.848-1.649v-.226c0-.369.299-.667.667-.667zm2.647 11.59l-1.981.849v1.216l2.832-1.214-.851-.851zm-5.295 0l-.851.851 2.832 1.213v-1.215l-1.981-.849zM7.56 12.666H6.344l1.213 2.833.852-.852-.849-1.981zm10.095 0h-1.216l-.849 1.981.851.851 1.214-2.832zM12 12.943l-1.633 1.632 1.633.699 1.632-.699L12 12.943zm2.575-2.576L12.943 12l1.632 1.632.699-1.632-.699-1.633zm-5.151 0L8.725 12l.699 1.632L11.057 12l-1.633-1.633zM7.557 8.5l-1.214 2.833H7.56l.849-1.981-.852-.852zm8.884.001l-.851.851.849 1.981h1.215l-1.213-2.832zM12 8.725l-1.633.699L12 11.057l1.632-1.633L12 8.725zm-.667-2.381L8.5 7.557l.852.852 1.981-.849V6.344zm1.333-.001V7.56l1.981.849.852-.852-2.833-1.214z"
        id="prefix__a-spiderweb"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b-spiderweb" fill="#fff">
        <use xlinkHref="#prefix__a-spiderweb" />
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#prefix__a-spiderweb" />
      <g
        mask="url(#prefix__b-spiderweb)"
        fill={props.color || theme.colors.black500}
      >
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
