import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { Button, Column, Container, Heading, Row } from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { langFromTag } from "@services/LangFromTag";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useTelemetry } from "@hooks/useTelemetry";
import { useBrowserRerender } from "@hooks/useBrowserRerender";

import { GatsbyImage } from "../../components/GatsbyImage";

import { StyledSection } from "./shared";

export interface BecomeBatmaidProps {
  become_cleaner_title: { text: string };
  become_cleaner_quote: { text: string };
  become_cleaner_quote_author?: { text: string };
  become_cleaner_button_text?: { text: string };
  become_cleaner_button_link?: { text: string };
  lang: string;
  become_cleaner_image: { gatsbyImageData: IGatsbyImageData };
}

interface Props extends BecomeBatmaidProps {
  withoutImage?: boolean;
}

const BecomeBatmaidSection = (props: Props): React.ReactElement => {
  const [author, authorDescription] =
    props.become_cleaner_quote_author?.text?.split(" - ") || ["", ""];

  const isBrowser = useBrowserRerender();
  const { mobile, tablet } = useBreakpoint();
  const isMobile = mobile || tablet;

  const { sendEvent } = useTelemetry();

  return (
    <StyledSection isWhite>
      <Container>
        <Row>
          <Column xs={12} lg={7}>
            <TextWrapper>
              <StyledHeading
                type="h2"
                styleAs={isBrowser && isMobile ? "h6" : "h4"}
              >
                {props.become_cleaner_title.text}
              </StyledHeading>

              {!props.withoutImage && (
                <MobileImage>
                  <GatsbyImage
                    image={props.become_cleaner_image.gatsbyImageData}
                    alt="maids"
                  />
                </MobileImage>
              )}

              <Blockqoute>
                <Quote>{props.become_cleaner_quote.text}</Quote>
              </Blockqoute>

              <Author>
                {author} - <span>{authorDescription}</span>
              </Author>

              <StyledLink
                onClick={() => {
                  sendEvent("become_batmaid_click", {
                    buttonLabel: props.become_cleaner_button_text?.text,
                    context: {
                      source: "section",
                    },
                  });
                }}
                to={`/${langFromTag(props.lang)}${
                  props.become_cleaner_button_link?.text
                }`}
              >
                <StyledButton>
                  {props.become_cleaner_button_text?.text}
                </StyledButton>
              </StyledLink>
            </TextWrapper>
          </Column>
          {!props.withoutImage && (
            <StyledColumn xs={12} lg={5}>
              <GatsbyImage
                image={props.become_cleaner_image.gatsbyImageData}
                alt="maids"
              />
            </StyledColumn>
          )}
        </Row>
      </Container>
    </StyledSection>
  );
};

export { BecomeBatmaidSection };

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base_x3};

  ${props => props.theme.breakpoints.desktop} {
    margin-bottom: ${props => props.theme.margins.base_x8};
    text-align: left;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  text-align: left;
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  width: 100%;

  ${props => props.theme.breakpoints.desktop} {
    width: auto;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;

  ${props => props.theme.breakpoints.tablet} {
    width: ${rem(320)};
  }
`;

const Blockqoute = styled.blockquote`
  margin: 0 0 ${props => props.theme.margins.base_x4} 0;
`;

const Quote = styled.em`
  color: ${props => props.theme.colors.black300};
`;

const Author = styled.p`
  color: ${props => props.theme.colors.black300};
  margin-bottom: ${props => props.theme.margins.base_x4};

  > span {
    color: ${props => props.theme.colors.grey700};
  }

  ${props => props.theme.breakpoints.desktop} {
    margin-bottom: ${props => props.theme.margins.base_x8};
  }
`;

const StyledColumn = styled(Column)`
  display: none;

  ${props => props.theme.breakpoints.desktop} {
    display: block;
  }
`;

const MobileImage = styled.div`
  margin-bottom: ${props => props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.desktop} {
    display: none !important;
  }
`;
