import * as Yup from "yup";

import { VALIDATE_FIELD_LIMIT_LONG } from "@config/consts";
import { UNCOVERED_LOCATIONS_ENDPOINT } from "@config/endpoints";

import { SERVICE_TYPE } from "@typings/globals";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { TranslateFunction } from "@hooks/useTranslate";

import { asyncActionCreator } from "../../services/AsyncActionCreator";

enum FIELDS {
  EMAIL = "email",
}

const initialValues = {
  [FIELDS.EMAIL]: "",
};

const getValidationSchema = (translate: TranslateFunction): Yup.ObjectSchema =>
  Yup.object().shape({
    [FIELDS.EMAIL]: Yup.string()
      .email(translate("batmaid_pwa_generic.email_wrong_format"))
      .required(translate("batmaid_pwa_generic.email_required"))
      .max(
        VALIDATE_FIELD_LIMIT_LONG,
        translate("batmaid_pwa_generic.validate_too_long", {
          maxValue: VALIDATE_FIELD_LIMIT_LONG,
        }),
      ),
  });

interface UncoveredLocationBody {
  email: string;
  zipCode: string;
  serviceType: SERVICE_TYPE | null;
}
const sendUncoveredLocation = async (
  body: UncoveredLocationBody,
): Promise<AsyncActionResult> => {
  return await asyncActionCreator({
    action: "",
    url: UNCOVERED_LOCATIONS_ENDPOINT,
    method: ASYNC_ACTION_TYPES.POST,
    body,
  });
};

export { FIELDS, initialValues, getValidationSchema, sendUncoveredLocation };
