import styled from "styled-components";

import { rem } from "@ui/helpers";

export const SidebarContainer = styled.div`
  position: sticky;
  top: ${props => props.theme.margins.base_x4};
  z-index: 1;

  ${props => props.theme.breakpoints.desktop} {
    margin-bottom: ${props => props.theme.margins.base_x2};
  }
`;

export const SummaryList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  flex: 1;

  li {
    margin: 0;
    padding: 0;
  }

  svg {
    margin-right: ${props => props.theme.margins.base_x2};
    position: relative;
    top: -${rem(1)};
  }

  ${props => props.theme.breakpoints.desktop} {
    width: 100%;

    li {
      padding: ${props => props.theme.margins.base} 0;
    }
  }
`;

export const TopQuestionsList = styled.div`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: ${props => props.theme.fonts.sizes.body2};

    li {
      margin-bottom: ${props => props.theme.margins.half};
      line-height: ${props => props.theme.fonts.lineHeights.normal};

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
