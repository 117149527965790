/**
 * @author tomekbuszewski
 * @author jakub-bujakowski
 * @since 2019-3-20
 * DISCLAIMER:
 * This is a one-of-a-kind component in the app.
 * It uses injected <style></style> insted of StyledComponents
 * in order to encapsule styling and make this component reusable outside of React.
 * We are using Gatsby to render this component as plain HTML + CSS and then
 * embedding it in the monolith (Symfony) app, on our blog and Instapages.
 */

import * as React from "react";

import {
  BATMAID_GOOGLE_LINK,
  BATMAID_TRUSTPILOT_LINK,
  BATMAID_LINKEDIN_LINK,
  BATMAID_INSTAGRAM_LINKS,
  BATMAID_TWITTER_LINK,
  BATMAID_YOUTUBE_LINK,
  BATMAID_FACEBOOK_LINKS,
} from "@config/consts";
import { HOMEPAGE } from "@config/testIds/app";

import { ORIGIN } from "@typings/globals";

import { rem } from "@ui/helpers";
import theme from "@ui/themes/default";
import { AppStore } from "@ui/Assets/Symbolicons/AppStore";
import { GooglePlay } from "@ui/Assets/Symbolicons/GooglePlay";
import { Google } from "@ui/Assets/Symbolicons/Google";
import { TrustPilot } from "@ui/Assets/Symbolicons/TrustPilot";
import { Headset } from "@ui/Assets/Symbolicons/Headset";
import { PhoneLegacy } from "@ui/Assets/Symbolicons/PhoneLegacy";
import { Mail } from "@ui/Assets/Symbolicons/Mail";
import { CreditCard } from "@ui/Assets/Symbolicons/CreditCard";
import { Paper } from "@ui/Assets/Symbolicons/Paper";
import { Megaphone } from "@ui/Assets/Symbolicons/Megaphone";
import {
  Facebook,
  Twitter,
  YouTube,
  Instagram,
  LinkedIn,
} from "@ui/Assets/SocialIcons";
import { scaleIcon } from "@ui/helpers/icon";
import { TestWrapper } from "@ui/Atoms";

import {
  Markdown,
  MarkdownRelNoreferrerLinkRenderer,
} from "@containers/Markdown";

import { styling, Props } from "./Footer.helpers";

const ScaledCreditCard = scaleIcon(CreditCard, 1.5);

const Footer = (props: Props): React.ReactElement => {
  const isBatwork = props.variant === ORIGIN.BATWORK;

  const ICON_STYLE = {
    size: 24,
    height: 24,
    color: isBatwork ? theme.colors.black700 : theme.colors.white,
    style: {
      height: rem(24),
    },
  };

  const SOCIAL_ICON_STYLE = {
    size: 40,
    height: 40,
    color: isBatwork ? theme.colors.black700 : theme.colors.white,
    style: {
      height: rem(40),
    },
  };

  const hasLocationsContainer = props.cities && props.locationsHeader;
  const instagramLink =
    BATMAID_INSTAGRAM_LINKS.get(props.country) ||
    BATMAID_INSTAGRAM_LINKS.get("DEFAULT");

  const facebookLink =
    BATMAID_FACEBOOK_LINKS.get(props.country) ||
    BATMAID_FACEBOOK_LINKS.get("DEFAULT");

  return (
    <footer
      className={`footer-universal ${props.country}-${props.language} ${props.variant}`}
      id="footer"
    >
      <style dangerouslySetInnerHTML={{ __html: styling() }}></style>
      <div
        className={`${
          props.isStandalone
            ? "container block-inner"
            : "footer__grid-container"
        }`}
      >
        <div
          className={`footer__grid-row footer__grid-row--even ${
            props.isTeaserVersion ? "teaser__row" : ""
          }`}
        >
          <div
            className={`footer__column footer__links${
              props.isTeaserVersion ? "--teaser" : ""
            }`}
          >
            <div
              className={
                props.isTeaserVersion
                  ? "footer__logo-wrapper--teaser"
                  : "footer__logo-wrapper"
              }
            >
              <a href={`/${props.language}/`}>{props.logo}</a>
            </div>
            {!props.isTeaserVersion && (
              <TestWrapper testId={HOMEPAGE.FOOTER_CONTACT}>
                <ul className="footer__contact">
                  <li>
                    <PhoneLegacy {...ICON_STYLE} variant="solid" />
                    <a
                      href={`tel:${props.contact.telephone}`}
                      onClick={props.onPhoneClick}
                    >
                      {props.contact.telephone}
                    </a>
                  </li>
                  <li>
                    <Mail {...ICON_STYLE} variant="solid" />
                    <a
                      href={`mailto:${props.contact.email}`}
                      onClick={props.onEmailClick}
                    >
                      {props.contact.email}
                    </a>
                  </li>
                  <li>
                    <Headset {...ICON_STYLE} variant="solid" />
                    {props.contact.openingHours}
                  </li>
                  <li>
                    <ScaledCreditCard {...ICON_STYLE} variant="line" />
                    {props.contact.paymentOptions}
                  </li>
                  <li>
                    <Paper {...ICON_STYLE} variant="line" />
                    <Markdown linkTarget="_self">
                      {props.contact.contactUs}
                    </Markdown>
                  </li>
                  <li>
                    <Megaphone {...ICON_STYLE} variant="line" />
                    <Markdown linkTarget="_self">
                      {props.contact.contactMedia}
                    </Markdown>
                  </li>
                </ul>
              </TestWrapper>
            )}
          </div>

          {!props.isTeaserVersion && (
            <React.Fragment>
              <TestWrapper testId={HOMEPAGE.FOOTER_NAVIGATION}>
                <div className="footer__column footer__column--tablet-gutter footer__links footer__navigation">
                  <h4 className="footer__heading footer__heading--visible-tablet">
                    {props.navigationHeader}
                  </h4>
                  <span>
                    <Markdown linkTarget="_self">{props.links}</Markdown>
                  </span>
                </div>
              </TestWrapper>

              <div className="footer__column footer__column--50-mobile">
                <h4 className="footer__heading footer__heading--shrink-tablet">
                  {props.officesHeader}
                </h4>
                <p className="footer__paragraph">
                  <Markdown linkTarget="_self">
                    {props.locationsLeftColumn}
                  </Markdown>
                </p>
              </div>

              <div className="footer__column footer__column--50-mobile">
                <p className="footer__paragraph footer__paragraph--top-margin">
                  <Markdown linkTarget="_self">
                    {props.locationsRightColumn}
                  </Markdown>
                </p>
              </div>
            </React.Fragment>
          )}

          <div
            className={`footer__column-social${
              props.isTeaserVersion ? "--teaser" : ""
            }`}
          >
            <div className="footer__social-tablet-wrapper">
              {/* Download Section */}
              {props.appDownloadHeader && !props.isTeaserVersion && (
                <div className="footer__social-tablet-item">
                  <h4 className="footer__heading footer__heading--sm-gutter">
                    {props.appDownloadHeader}
                  </h4>
                  <div className="footer__downloads">
                    <a
                      href={props.iosAppUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AppStore
                        variant="solid"
                        size={115}
                        height={35}
                        color={
                          isBatwork ? theme.colors.grey600 : theme.colors.white
                        }
                      />
                    </a>
                    <a
                      href={props.androidAppUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <GooglePlay
                        variant="solid"
                        size={115}
                        height={35}
                        color={
                          isBatwork ? theme.colors.grey600 : theme.colors.white
                        }
                      />
                    </a>
                  </div>
                  {props.appComingSoonText && (
                    <div className="footer__app-coming-soon">
                      {props.appComingSoonText}
                    </div>
                  )}
                </div>
              )}

              {/* Reviews Section */}
              {!props.isTeaserVersion && (
                <div className="footer__social-tablet-item">
                  <h4 className="footer__heading">{props.reviewUsHeader}</h4>
                  <div className="footer__downloads footer__reviews">
                    <a
                      href={props.googleReviewUrl || BATMAID_GOOGLE_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Google
                        variant="solid"
                        size={80}
                        height={27}
                        color={
                          isBatwork ? theme.colors.black700 : theme.colors.white
                        }
                      />
                    </a>
                    <a
                      href={props.trustpilotUrl || BATMAID_TRUSTPILOT_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TrustPilot
                        variant="solid"
                        size={115}
                        height={27}
                        color={
                          isBatwork ? theme.colors.black700 : theme.colors.white
                        }
                      />
                    </a>
                  </div>
                </div>
              )}

              {/* Social Media Section */}
              <div className="footer__social-tablet-item">
                {!props.isTeaserVersion && (
                  <h4 className="footer__heading footer__heading--sm-gutter">
                    {props.followUsHeader}
                  </h4>
                )}
                <TestWrapper testId={HOMEPAGE.FOOTER_SOCIAL}>
                  <div
                    className={
                      props.isTeaserVersion
                        ? "footer__social footer__social--teaser"
                        : "footer__social"
                    }
                  >
                    <a
                      href={facebookLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook {...SOCIAL_ICON_STYLE} />
                    </a>
                    <a
                      href={BATMAID_LINKEDIN_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LinkedIn {...SOCIAL_ICON_STYLE} />
                    </a>
                    <a
                      href={instagramLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Instagram {...SOCIAL_ICON_STYLE} />
                    </a>
                    <a
                      href={BATMAID_TWITTER_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Twitter {...SOCIAL_ICON_STYLE} />
                    </a>
                    <a
                      href={BATMAID_YOUTUBE_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <YouTube {...SOCIAL_ICON_STYLE} />
                    </a>
                  </div>
                </TestWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
      {hasLocationsContainer && !props.isTeaserVersion && (
        <div className={`footer__location-container ${props.variant}`}>
          <div
            className={`${
              props.isStandalone
                ? "container block-inner"
                : "footer__grid-container"
            }`}
          >
            <div className="footer__grid-row">
              <div className="footer__column-full">
                <h4 className="footer__heading footer__heading--locations">
                  {props.locationsHeader}
                </h4>
                <Markdown linkTarget="_self">{props.cities}</Markdown>
                <div className="footer__coming-soon-locations">
                  <Markdown linkTarget="_self">{props.comingSoonBody}</Markdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        className={`footer__copyright-container ${props.variant} ${
          !hasLocationsContainer ? "extra-margin" : ""
        }`}
      >
        <div
          className={`${
            props.isStandalone
              ? "container block-inner"
              : "footer__grid-container"
          }`}
        >
          <div className="footer__grid-row">
            <div className="footer__column-full">
              <span className={`footer__copyright ${props.variant}`}>
                {props.variant === ORIGIN.BATMAID &&
                  `© Batmaid ${new Date().getFullYear()}`}
                {props.variant === ORIGIN.BATSOFT &&
                  `© Batsoft ${new Date().getFullYear()}`}
                {props.variant === ORIGIN.BATWORK &&
                  `© Batwork ${new Date().getFullYear()}`}
              </span>{" "}
              <Markdown renderers={{ link: MarkdownRelNoreferrerLinkRenderer }}>
                {props.hashtagLinks}
              </Markdown>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { Footer };

Footer.displayName = "Footer";
