/**
 * @author michalstolarski
 * @since 2022-7-26
 */

import * as React from "react";
import styled, { css, useTheme } from "styled-components";

import { rem } from "@ui/helpers";
import { Info } from "@ui/Assets/Symbolicons/Info";
import { Tooltip } from "@ui/Atoms/Tooltip";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  testId?: string;
  className?: string;
  vouchersLabel: string;
  tooltip?: string;
  isStruckOut?: boolean;
}

const SummaryVouchers = (props: Props): React.ReactElement => {
  const theme = useTheme();

  return (
    <PriceWrapper className={props.className}>
      <TestWrapper testId={props.testId}>
        <SummaryVouchersValue isStruckOut={props.isStruckOut} data-hj-allow>
          {props.vouchersLabel}
        </SummaryVouchersValue>
      </TestWrapper>
      {!!props.tooltip && (
        <Tooltip
          position={"left"}
          placeholder={props.tooltip}
          id="price_tooltip"
        >
          <InfoIconContainer>
            <Info
              variant="solid"
              color={theme.colors.blue400}
              size={20}
              height={20}
            />
          </InfoIconContainer>
        </Tooltip>
      )}
    </PriceWrapper>
  );
};

export { SummaryVouchers, SummaryVouchersValue };

const SummaryVouchersValue = styled.span<{ isStruckOut?: boolean }>`
  display: inline-flex;
  align-items: baseline;
  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props => props.theme.fonts.weights.normal};

  ${props =>
    props.isStruckOut &&
    css`
      text-decoration: line-through;
    `}

  ${props => props.theme.breakpoints.desktopLarge} {
    font-size: ${props => props.theme.fonts.sizes.body};
  }
`;

const InfoIconContainer = styled.span`
  display: inline-flex;
  align-self: flex-start;
  margin-right: -${props => props.theme.margins.base_x2};
  cursor: help;
  height: ${rem(14)};
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  font-weight: ${props => props.theme.fonts.weights.bold};
  align-self: center;
`;
