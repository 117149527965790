import { DesignSystemPalette } from "./palette";

interface SimpleObject {
  [k: string]: string;
}

export type Viewports = "mobile" | "tablet" | "desktop" | "desktopLarge";
export enum VIEWPORTS {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
  DESKTOP_LARGE = "desktopLarge",
}

/**
 * @deprecated FOR BATMAID ONLY -- please use the DesignSystemPalette instead
 */
interface DesignSystemColors extends SimpleObject {
  white: string;
  greySurface: string;
  greyLight: string;
  greyDark: string;
  grey20: string;
  grey50: string;
  grey100: string;
  grey200: string;
  grey300: string;
  grey500: string;
  grey600: string;
  grey700: string;

  blue20: string;
  blue50: string;
  blue100: string;
  blue200: string;
  blue300: string;
  blue400: string;
  blue500: string;
  blue600: string;
  blue700: string;
  blue800: string;
  blue900: string;

  green50: string;
  green100: string;
  green200: string;
  green300: string;
  green400: string;
  green500: string;
  green600: string;
  green700: string;
  green800: string;
  green900: string;

  red50: string;
  red500: string;
  red700: string;

  black700: string;

  orange50: string;
  orange500: string;
  orange700: string;
  orange900: string;
}

//@ToDo: remove extends when migration to 8pt DS is complete
interface DesignSystemFontSizes extends SimpleObject {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  body: string;
  body2: string;
  subtitle: string;
  subtitle2: string;
  caption: string;
  overline: string;
}

interface DesignSystemLineHeights {
  normal: string;
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  subtitle: string;
  subtitle2: string;
  caption: string;
  overline: string;
}

interface DesignSystemFontWeights {
  light: number;
  normal: number;
  semibold: number;
  bold: number;
}

interface DesignSystemLetterSpacings {
  normal: string;
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  h5: string;
  h6: string;
  subtitle: string;
  subtitle2: string;
  caption: string;
  overline: string;
}

interface DesignSystemIconSizes {
  base: number;
  base_x1_5: number;
  base_x2: number;
  base_x3: number;
  base_x4: number;
  base_x5: number;
  base_x6: number;
  base_x7: number;
  base_x8: number;
}

interface DesignSystemMargins extends SimpleObject {
  none: string;
  quarter: string;
  half: string;
  base: string;
  base_x1_5: string;
  base_x2: string;
  base_x3: string;
  base_x4: string;
  base_x5: string;
  base_x6: string;
  base_x7: string;
  base_x8: string;
  base_x9: string;
  base_x10: string;
}

export interface ITheme {
  animations: {
    durationShort: string;
    duration: string;
    durationLong: string;
    easing: string;
  };
  breakpoints: {
    belowMobileSmall: string;
    mobile: string;
    upToTablet: string;
    tablet: string;
    upToDesktop: string;
    tabletToDesktop: string;
    desktop: string;
    upToDesktopLarge: string;
    desktopToDesktopLarge: string;
    desktopLarge: string;
  };
  buttons: {
    borderRadius: string;
    height: string;
    heightSmall: string;
    lineHeight: string;
    sizes: SimpleObject;
    paddings: SimpleObject;
  };
  border: {
    radius: {
      half: string;
      base: string;
      base_x1_5: string;
      base_x2: string;
    };
  };
  container: {
    mobile: string;
    tablet: string;
    desktopSmall: string;
    desktop: string;
    desktopLarge: string;
    calendar: string;
  };
  gradients: {
    blue800_blue600: string;
    lightblue_white: string;
  };
  palette: DesignSystemPalette;

  /**
   * @deprecated FOR BATMAID ONLY -- please use the palette instead
   */
  colors: DesignSystemColors;
  notifications: SimpleObject;
  fonts: {
    default: string;
    base: string;
    baseSize: string;
    sizes: DesignSystemFontSizes;
    lineHeights: DesignSystemLineHeights;
    weights: DesignSystemFontWeights;
    letterSpacings: DesignSystemLetterSpacings;
  };
  grid: {
    columns: number;
  };
  margins: DesignSystemMargins;
  icons: {
    sizes: DesignSystemIconSizes;
  };
  name: string;
  orientation: {
    landscape: string;
    portrait: string;
  };
  zIndex: {
    modal: number;
    header: number;
    bottomNavigation: number;
    twaBottomSheet: number;
    corruptedSession: number;
    loadingIndicator: number;
    bottomSheet: number;
  };
}
