/**
 * @author jakubbujakowski
 * @since 2020-6-17
 */

import * as React from "react";
import { useSelector } from "react-redux";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS, ORIGIN } from "@typings/globals";

import { HelpWidget as HelpWidgetOrganism } from "@ui/Organisms/HelpWidget";

import { useTranslate } from "@hooks/useTranslate";

import {
  getCountry,
  getLanguage,
} from "@soft/redux/localisation/localisation.selectors";

interface Props {
  renderQuestions: () => React.ReactElement | null;
  language: AVAILABLE_LANGS;
}

const HelpWidget = (props: Props): React.ReactElement => {
  const translate = useTranslate("batsoft_pwa_generic");
  const country: AVAILABLE_COUNTRIES = useSelector(getCountry);
  const language = useSelector(getLanguage);

  return (
    <HelpWidgetOrganism
      origin={ORIGIN.BATSOFT}
      country={country}
      language={props.language || language}
      renderQuestions={props.renderQuestions}
      translations={{
        backToCategoriesText: translate("back_to_categories"),
        noResultsText: translate("no_search_results"),
        searchInputLabelText: translate("type_in_question"),
      }}
    />
  );
};

HelpWidget.displayName = "HelpWidget";

export { HelpWidget };
