import { IGatsbyImageData } from "gatsby-plugin-image";
import * as React from "react";
import { graphql, PageProps } from "gatsby";
import rehypeRaw from "rehype-raw";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { ORIGIN } from "@typings/globals";

import { Heading } from "@ui/Atoms/Headings";
import {
  Section,
  List,
  OptionPanel,
  Paragraph,
  Row,
  Column,
  Margins,
} from "@ui/Atoms";

import { langFromTag } from "@services/LangFromTag/LangFromTag";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { GatsbyImage } from "../components/GatsbyImage";

type SliceParagraphItems = { paragraph: { text: string } }[];

type SliceParagraphPrimary = {
  title: {
    text: string;
  };
  seo_description: {
    text: string;
  };
  seo_title: {
    text: string;
  };
};

type SliceWhatsIncludedPrimary = {
  whats_included_image: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  whats_included_title: { text: string };
  content_body: {
    text: string;
  };
};

interface SliceParagraph {
  slice_type: "paragraph";
  items: SliceParagraphItems;
  primary: SliceParagraphPrimary;
}

interface SliceWhatsIncluded {
  slice_type: "what_s_included";
  primary: SliceWhatsIncludedPrimary;
}

type Props = PageProps<
  {
    prismicWhatsIncludedModal: {
      data: {
        title: {
          text: string;
        };
        body: (SliceParagraph | SliceWhatsIncluded)[];
      };
    };
  },
  {
    origin: ORIGIN;
    lang: string;
  }
>;

const WhatsIncluded = (props: Props): React.ReactElement => {
  const origin = props.pageContext.origin;
  const lang = langFromTag(props.pageContext.lang);

  const { data } = props.data?.prismicWhatsIncludedModal;

  return (
    <Layout language={lang || DEFAULT_LANGUAGE} origin={origin}>
      <SEO title={data.title?.text} path={props.location.pathname} />
      <Section small>
        <OptionPanel>
          <Heading type="h1" styleAs="h4">
            {data.title?.text}
          </Heading>
          {data.body?.map(slice => {
            switch (slice.slice_type) {
              case "paragraph":
                return (
                  <div>
                    <Margins xs={[null, "base_x2", null, null]}>
                      <Heading type="h1" styleAs="h4">
                        {slice.primary.title.text}
                      </Heading>
                    </Margins>

                    {slice.items.map((item, idx) => (
                      <React.Fragment key={`paragraph-${idx}`}>
                        <ReactMarkdown
                          key={idx}
                          unwrapDisallowed
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            p: Paragraph,
                          }}
                        >
                          {item.paragraph.text}
                        </ReactMarkdown>
                      </React.Fragment>
                    ))}
                  </div>
                );
              default:
                return (
                  <StyledRow>
                    <Column lg={6}>
                      <StyledImg>
                        <GatsbyImage
                          image={
                            slice.primary.whats_included_image.gatsbyImageData
                          }
                          alt={slice.primary.whats_included_image.alt}
                        />
                      </StyledImg>
                    </Column>
                    <StyledColumn lg={6}>
                      <div>
                        <Heading type="h6">
                          {slice.primary.whats_included_title.text}
                        </Heading>
                        <ReactMarkdown
                          unwrapDisallowed
                          rehypePlugins={[rehypeRaw]}
                          components={{
                            ul: List as any,
                            li: ListItem as any,
                          }}
                        >
                          {slice.primary.content_body?.text?.replace(
                            /-/g,
                            "\n -",
                          )}
                        </ReactMarkdown>
                      </div>
                    </StyledColumn>
                  </StyledRow>
                );
            }
          })}
        </OptionPanel>
      </Section>
    </Layout>
  );
};

export default WhatsIncluded;

export const whatsIncludedQuery = graphql`
  query WhatsIncludedQuery($id: String) {
    prismicWhatsIncludedModal(id: { eq: $id }) {
      id
      data {
        title {
          text
        }
        body {
          ... on PrismicWhatsIncludedModalDataBodyWhatSIncluded {
            slice_type
            primary {
              whats_included_title {
                text
              }
              whats_included_image {
                alt
                gatsbyImageData(
                  layout: CONSTRAINED
                  imgixParams: { q: 95 }
                  width: 1980
                )
              }
              content_body {
                text
              }
            }
          }
          ... on PrismicWhatsIncludedModalDataBodyParagraph {
            items {
              paragraph {
                text
              }
            }
            slice_type
            primary {
              title {
                text
              }
              seo_description {
                text
              }
              seo_title {
                text
              }
            }
          }
        }
      }
    }
  }
`;

const StyledRow = styled(Row)`
  display: flex;
  margin-bottom: ${props => props.theme.margins.base_x3};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const StyledColumn = styled(Column)`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const StyledImg = styled.div`
  img {
    margin-bottom: ${props => props.theme.margins.base_x3};
  }
`;

const ListItem = styled.li`
  margin-bottom: ${props => props.theme.margins.base};

  > svg {
    margin-right: ${props => props.theme.margins.base};
  }

  > p {
    margin-bottom: 0;
  }
`;
