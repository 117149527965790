import { format, parse } from "date-fns";

import {
  EXTRA_SERVICES_BOOKING,
  EXTRA_SERVICES_LEGACY,
} from "@typings/booking";

import { DATE_FORMAT } from "@services/DateFormatter";

import { IStore } from "@work/redux/reducers";

import {
  getSelectedTasksList,
  getSelectedCleaningFrequency,
  getServiceLocationId,
  getPetsInfo,
  getRoomQuantity,
  getReservationUuid,
  getWeekStartDate,
  getKeyPick,
  getLocationComment,
  getSpecialInstructions,
  getChosenAgentIds,
  getSicknessInsurance,
  getChosenAgentId,
  getAgreeToProfessionalAccidentInsurance,
  getSecondPillarRetirement,
  getSliderSupergrossSalary,
  getExecutionDateTime,
  getClientRegistrationForm,
  getSalary,
  getPrevSecondPillarRetirement,
  getPrevSicknessInsurance,
  getProfessionalAccidentInsurance,
  getInsurancePolicyNumber,
  getInsurancePolicyWillBeProvidedLater,
  getLocationUuid,
} from "./booking.selectors";

export const getAvailabilityEndpointRequest = (store: IStore) => {
  const tasks = getSelectedTasksList(store).map(item => ({
    //@ToDo: remove this condition once backend fixes extra services enum
    service:
      item.get("service") === EXTRA_SERVICES_BOOKING.INSIDE_OVEN
        ? EXTRA_SERVICES_LEGACY.INSIDE_OWEN
        : item.get("service"),
    duration: item.get("duration"),
  }));

  const reservationUuid = getReservationUuid(store);
  const weekStartDate = getWeekStartDate(store);

  return {
    serviceLocationId: getServiceLocationId(store),
    frequency: getSelectedCleaningFrequency(store),
    hasPets: getPetsInfo(store),
    tasks,
    ...(reservationUuid && { reservationUuid }),
    ...(weekStartDate && { weekStartDate }),
  };
};

export const getReservationEndpointRequest = (store: IStore) => {
  const tasks = getSelectedTasksList(store).map(item => ({
    //@ToDo: remove this condition once backend fixes extra services enum
    service:
      item.get("service") === EXTRA_SERVICES_BOOKING.INSIDE_OVEN
        ? EXTRA_SERVICES_LEGACY.INSIDE_OWEN
        : item.get("service"),
    duration: item.get("duration"),
  }));

  return {
    serviceLocationId: getServiceLocationId(store),
    frequency: getSelectedCleaningFrequency(store),
    hasPets: getPetsInfo(store),
    bedrooms: getRoomQuantity(store, "bedrooms"),
    bathrooms: getRoomQuantity(store, "bathrooms"),
    tasks,
    keyPick: getKeyPick(store),
    locationComment: getLocationComment(store),
    specialInstructions: getSpecialInstructions(store),
  };
};

export const getReservationDataEndpointRequest = (store: IStore) => {
  // 1st step - Cleaning Information
  const frequency = getSelectedCleaningFrequency(store);
  const hasPets = getPetsInfo(store);
  const bedrooms = getRoomQuantity(store, "bedrooms");
  const bathrooms = getRoomQuantity(store, "bathrooms");
  const keyPick = getKeyPick(store);
  const locationComment = getLocationComment(store);
  const specialInstructions = getSpecialInstructions(store);
  const tasks = getSelectedTasksList(store).map(item => ({
    //@ToDo: remove this condition once backend fixes extra services enum
    service:
      item.get("service") === EXTRA_SERVICES_BOOKING.INSIDE_OVEN
        ? EXTRA_SERVICES_LEGACY.INSIDE_OWEN
        : item.get("service"),
    duration: item.get("duration"),
  }));

  // 2nd step - Employee Selection
  const executionDateTime = getExecutionDateTime(store);
  const agentUuid = getChosenAgentId(store);

  // 3rd step - Salary Proposal
  const superGrossSalary = getSliderSupergrossSalary(store);
  const secondPillarRetirement = getSecondPillarRetirement(store);
  const sicknessInsurance = getSicknessInsurance(store);
  const agreeToProfessionalAccidentInsurance = getAgreeToProfessionalAccidentInsurance(
    store,
  );
  const insurancePolicy = getProfessionalAccidentInsurance(store);
  const insurancePolicyNumber = getInsurancePolicyNumber(store);
  const insurancePolicyWillBeProvidedLater = getInsurancePolicyWillBeProvidedLater(
    store,
  );

  // 4th step - Account Creation
  const clientLocationId = getLocationUuid(store);

  return {
    // 1st step - Cleaning Information
    frequency,
    hasPets,
    bedrooms,
    bathrooms,
    keyPick,
    tasks,
    ...(locationComment && { locationComment }),
    ...(specialInstructions && { specialInstructions }),

    // 2nd step - Employee Selection
    ...(agentUuid && { agentUuid }),
    ...(executionDateTime && { executionDateTime }),

    // 3rd step - Salary Proposal
    insurancePolicyWillBeProvidedLater,
    secondPillarRetirement,
    sicknessInsurance,
    agreeToProfessionalAccidentInsurance,
    ...(insurancePolicy && { insurancePolicy }),
    ...(superGrossSalary && { superGrossSalary }),
    ...(insurancePolicyNumber && { insurancePolicyNumber }),

    // 4th step - Account Creation
    ...(clientLocationId && { clientLocationId }),
  };
};

export const getAgentsMetadataEndpointRequest = (store: IStore) => {
  const chosenAgentIds = getChosenAgentIds(store)
    .valueSeq()
    .toArray();

  if (chosenAgentIds.length === 0) {
    return "";
  }

  const idsVarName = "ids[]=";
  const separator = `&${idsVarName}`;
  const prefix = `?${idsVarName}`;

  const chosenAgentIdsAsString = chosenAgentIds.join(separator);
  const chosenAgentIdsAsStringWithPrefix = `${prefix}${chosenAgentIdsAsString}`;

  return chosenAgentIdsAsStringWithPrefix;
};

export const getSicknessInsuranceEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  superGrossSalary: getSliderSupergrossSalary(store),
  secondPillarRetirement: getSecondPillarRetirement(store),
  sicknessInsurance: getSicknessInsurance(store),
});

export const getSecondPillarRetirementEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  superGrossSalary: getSliderSupergrossSalary(store),
  secondPillarRetirement: getSecondPillarRetirement(store),
  sicknessInsurance: getSicknessInsurance(store),
});

export const getSalaryDefaultsEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  chosenAgentId: getChosenAgentId(store),
  secondPillarRetirement: getSecondPillarRetirement(store),
});

export const getAddLocationEndpointRequest = (store: IStore) => {
  const form = getClientRegistrationForm(store);

  return {
    serviceLocationId: getServiceLocationId(store),
    address: form.get("address"),
    floorAndDoor: form.get("floorAndDoor"),
    entryCode: form.get("entryCode"),
  };
};

export const getSalaryEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  superGrossSalary: getSliderSupergrossSalary(store),
  secondPillarRetirement: getSecondPillarRetirement(store),
  sicknessInsurance: getSicknessInsurance(store),
  agreeToProfessionalAccidentInsurance: getAgreeToProfessionalAccidentInsurance(
    store,
  ),
});

export const getClientRegistrationEndpointRequest = (store: IStore) => {
  const form = getClientRegistrationForm(store);

  const birthDate = format(
    parse(form.get("birthDate"), DATE_FORMAT.DAY_PICKER_INPUT, new Date()),
    DATE_FORMAT.YEAR_MONTH_DAY,
  );

  return {
    email: form.get("email"),
    password: form.get("password"),
    firstName: form.get("firstName"),
    lastName: form.get("lastName"),
    phoneNumber: form.get("phoneNumber"),
    additionalNumber: form.get("additionalNumber"),
    birthDate,
    mainLocation: {
      serviceLocationId: getServiceLocationId(store),
      address: form.get("address"),
      floorAndDoor: form.get("floorAndDoor"),
      entryCode: form.get("entryCode"),
    },
  };
};

export const getSupergrossSalaryEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  grossSalaryIncludingVacation: getSalary(store).get(
    "grossSalaryIncludingVacation",
  ),
  currentSecondPillarRetirement: getPrevSecondPillarRetirement(store),
  newSecondPillarRetirement: getSecondPillarRetirement(store),
  currentSicknessInsurance: getPrevSicknessInsurance(store),
  newSicknessInsurance: getSicknessInsurance(store),
});
