import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const CreditCard = (props: Icon): React.ReactElement => {
  if (props.variant === "line") {
    return (
      <svg
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        style={{ ...style(props.size, props.height), ...props.style }}
        className={props.className}
      >
        <title>{"CreditCard"}</title>
        <g fill="none" fillRule="evenodd">
          <path
            d="M19.333 10a.667.667 0 01.078 1.329l-.078.004H4.667a.667.667 0 01-.078-1.329L4.667 10h14.666z"
            fill={props.secondaryColor || props.color || theme.colors.grey700}
          />
          <path
            d="M18 6H6a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2zM6 7.333h12c.368 0 .667.299.667.667v8a.666.666 0 01-.667.667H6A.666.666 0 015.333 16V8c0-.368.299-.667.667-.667z"
            fill={props.color || theme.colors.grey700}
          />
          <path
            d="M9.333 13.993c.342 0 .624.258.663.59l.004.084a.667.667 0 01-1.329.077l-.004-.084c0-.368.298-.667.666-.667zM7.333 13.993c.342 0 .624.258.663.59l.004.084a.667.667 0 01-1.329.077l-.004-.084c0-.368.298-.667.666-.667z"
            fill={props.color || theme.colors.grey700}
          />
        </g>
      </svg>
    );
  }

  return (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
      viewBox="0 0 24 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.001 16.004c0 1.103.894 1.997 1.997 1.997h20.006a1.997 1.997 0 0 0 1.997-1.997V8.001h-24v8.003zm9.5-4.503a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zm-5 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3zM22 .001H2.002A2.001 2.001 0 0 0 .001 2.002v1.999h24V2.002A2.001 2.001 0 0 0 22 .001z"
        fill={props.color || theme.colors.grey700}
        fillRule="nonzero"
      />
    </svg>
  );
};
