import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Newspaper = (props: Icon): React.ReactElement => (
  <svg
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <defs>
      <path
        id="newspaper-a"
        d="m14.679 66.431-0.012445-0.43099v-24.545c0-1.35 1.0944-2.4444 2.4444-2.4444 1.2536 0 2.2868 0.94365 2.428 2.1594l0.016446 0.28507v24.545c0 1.3511 1.0934 2.4444 2.4444 2.4444 1.2546 0 2.2869-0.94275 2.428-2.1592l0.016431-0.28527v-48.889c0-1.2536 0.94365-2.2868 2.1594-2.428l0.28507-0.016446h44c1.2536 0 2.2868 0.94365 2.428 2.1594l0.016446 0.28507v44c0 6.5722-5.1882 11.933-11.692 12.211l-0.53015 0.011293h-39.111c-3.9065 0-7.0979-3.0519-7.3209-6.9023zm53.765-46.875h-39.111v46.444c0 0.85727-0.14698 1.6801-0.41711 2.4447l32.195-2.603e-4c3.9051 0 7.0978-3.0532 7.3209-6.9025l0.01245-0.43086v-41.556zm-17.111 36.667c1.35 0 2.4444 1.0944 2.4444 2.4444 0 1.2536-0.94365 2.2868-2.1594 2.428l-0.28507 0.016446h-14.667c-1.35 0-2.4444-1.0944-2.4444-2.4444 0-1.2536 0.94365-2.2868 2.1594-2.428l0.28507-0.016446h14.667zm9.7778-9.7778c1.35 0 2.4444 1.0944 2.4444 2.4444 0 1.2536-0.94365 2.2868-2.1594 2.428l-0.28507 0.016446h-24.444c-1.35 0-2.4444-1.0944-2.4444-2.4444 0-1.2536 0.94365-2.2868 2.1594-2.428l0.28507-0.016446h24.444zm0-9.7778c1.35 0 2.4444 1.0944 2.4444 2.4444 0 1.2536-0.94365 2.2868-2.1594 2.428l-0.28507 0.016446h-24.444c-1.35 0-2.4444-1.0944-2.4444-2.4444 0-1.2536 0.94365-2.2868 2.1594-2.428l0.28507-0.016446h24.444zm0-9.7778c1.35 0 2.4444 1.0944 2.4444 2.4444 0 1.2536-0.94365 2.2868-2.1594 2.428l-0.28507 0.016446h-24.444c-1.35 0-2.4444-1.0944-2.4444-2.4444 0-1.2536 0.94365-2.2868 2.1594-2.428l0.28507-0.016446h24.444z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(-630 -918)">
        <g transform="translate(556 824)">
          <g transform="translate(0 80)">
            <g transform="translate(60)">
              <mask id="newspaper-b" fill="white">
                <use xlinkHref="#newspaper-a" />
              </mask>
              <g
                fill={props.color || theme.colors.grey700}
                mask="url(#newspaper-b)"
              >
                <rect width="88" height="88" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
