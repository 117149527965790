import * as React from "react";

import { MODALS } from "@typings/modals";

import { useModalManager } from "@hooks/modal/useModalManager";

const UncoveredCodeSubscriptionModal = React.lazy(
  () => import("@app/containers/UncoveredCodeSubscriptionModal"),
);

export const HeaderModals = (): React.ReactElement => {
  const { isModalActive } = useModalManager();

  return (
    <React.Fragment>
      {isModalActive(MODALS.UNCOVERED_CODE_SUBSCRIPTION) && (
        <React.Suspense>
          <UncoveredCodeSubscriptionModal />
        </React.Suspense>
      )}
    </React.Fragment>
  );
};
