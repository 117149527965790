import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Shirt = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.99875L8.96888 9.75653C8.43286 9.89053 7.8897 9.56464 7.7557 9.02862L7.13923 6.56274C7.04694 6.19357 7.06183 5.80575 7.18217 5.44475L7.77032 3.6803C7.9065 3.27179 8.28879 2.99625 8.7194 2.99625H15.2806C15.7112 2.99625 16.0935 3.27179 16.2297 3.68031L16.91 5.72116C16.9701 5.90166 16.9776 6.09557 16.9314 6.28016L16.2443 9.02865C16.1103 9.56467 15.5672 9.89056 15.0311 9.75656L12 8.99875Z"
      />
      <path d="M7.08028 5.9975H5.9975C4.33996 5.9975 2.99625 7.3412 2.99625 8.99875V18.0025C2.99625 19.66 4.33996 21.0037 5.9975 21.0037H18.0025C19.66 21.0037 21.0038 19.66 21.0038 18.0025V8.99875C21.0038 7.3412 19.66 5.9975 18.0025 5.9975H16.9613" />
      <path d="M15.8716 3.19133L12 8.99875L8.12839 3.19133" />
      <path d="M12.05 17.9425C12.05 17.9701 12.0276 17.9925 12 17.9925C11.9724 17.9925 11.95 17.9701 11.95 17.9425C11.95 17.9149 11.9724 17.8925 12 17.8925" />
      <path d="M12 17.8925C12.0276 17.8925 12.05 17.9148 12.05 17.9425" />
      <path d="M12.05 15.0024C12.05 15.03 12.0276 15.0524 12 15.0524C11.9724 15.0524 11.95 15.03 11.95 15.0024C11.95 14.9747 11.9724 14.9523 12 14.9523" />
      <path d="M12 14.9523C12.0276 14.9523 12.05 14.9747 12.05 15.0023" />
      <path d="M12.05 12.0605C12.05 12.0881 12.0276 12.1105 12 12.1105C11.9724 12.1105 11.95 12.0881 11.95 12.0605C11.95 12.0329 11.9724 12.0105 12 12.0105" />
      <path d="M12 12.0105C12.0276 12.0105 12.05 12.0329 12.05 12.0605" />
    </svg>
  );
};
