import * as React from "react";
import { Formik, FormikProps, FormikValues, Form } from "formik";
import * as Yup from "yup";
import styled, { css } from "styled-components";

import { Margins, Button } from "@ui/Atoms";
import { FormikInputWithError } from "@ui/Molecules/FormikInputWithError";
import { InfoMessage } from "@ui/Molecules";
import { rem } from "@ui/helpers";
import { EmailAt } from "@ui/Assets/Symbolicons/EmailAt";

interface Props {
  onFormSubmit: (email: string) => void;

  translations: {
    infoText: string;
    submitText: string;
    emailPlaceholderText: string;
    emailWrongFormatText: string;
    emailRequiredText: string;
  };
}

const ModalContentUncoveredCodeSubscription = React.memo((props: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(props.translations.emailWrongFormatText)
      .required(props.translations.emailRequiredText),
  });

  return (
    <div>
      <Margins xs={[null, "base_x4", null, null]}>
        <div>
          <InfoMessage message={props.translations.infoText} />
        </div>
      </Margins>

      <Formik
        onSubmit={values => {
          props.onFormSubmit(values.email);
        }}
        initialValues={{ email: "" }}
        validateOnBlur
        validationSchema={validationSchema}
      >
        {(formikProps: FormikProps<FormikValues>) => (
          <StyledForm>
            <FormContent>
              <InputWrapper hasError={!!formikProps.errors.email}>
                <StyledInput
                  label={props.translations.emailPlaceholderText}
                  name="email"
                  value={formikProps.values.email}
                  handleChange={formikProps.handleChange}
                  isDisabled={formikProps.isSubmitting}
                  icon={<EmailAt />}
                />
              </InputWrapper>

              <StyledButton
                variant="green"
                type="submit"
                disabled={formikProps.isSubmitting}
              >
                {props.translations.submitText}
              </StyledButton>
            </FormContent>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
});

export { ModalContentUncoveredCodeSubscription };

ModalContentUncoveredCodeSubscription.displayName =
  "ModalContentUncoveredCodeSubscription";

const StyledForm = styled(Form)`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;

const StyledInput = styled(FormikInputWithError)`
  label {
    font-size: ${props => props.theme.fonts.sizes.body};
  }

  > div {
    width: 100%;
  }
`;

const FormContent = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    width: 100%;
  }

  label,
  input {
    color: ${props => props.theme.colors.grey700};
  }

  input {
    font-size: ${props => props.theme.fonts.sizes.body};
  }

  ${props => props.theme.breakpoints.tablet} {
    flex-direction: row;

    > button {
      margin-left: ${props => props.theme.margins.base_x3};
    }
  }
`;

const StyledButton = styled(Button)`
  margin: ${props => `${props.theme.margins.base} 0 0`};
  width: 100%;

  ${props => props.theme.breakpoints.tablet} {
    margin: ${props => `0 0 0 ${props.theme.margins.base_x3}`};
    width: ${props => props.theme.buttons.sizes.medium};
  }
`;

const InputWrapper = styled.div<{ hasError: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;

  ${props =>
    props.hasError &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        > div:nth-child(2) {
          position: absolute;
          bottom: -${rem(24)};
        }
      }
    `}
`;
