import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Warning = (props: Icon): React.ReactElement => (
  <svg
    style={{ ...style(props.size, props.height), ...props.style }}
    className={props.className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      fill={props.color || theme.colors.grey700}
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        props.variant === "line"
          ? "M14.4176 5.95798C13.2415 4.18287 10.5611 4.23834 9.47908 6.1244L4.3864 15.0018L4.30613 15.1511C3.35127 17.0539 4.73504 19.3335 6.90667 19.3335H17.0933L17.2631 19.3288C19.3922 19.2107 20.6926 16.8829 19.6136 15.0018L14.5203 6.12439L14.4176 5.95798ZM10.7156 6.6621C11.3634 5.73962 12.7864 5.78155 13.3637 6.78792L18.4571 15.6653L18.5179 15.7818C18.9971 16.7994 18.2546 18.0002 17.0933 18.0002H6.90667L6.77458 17.9949C5.64673 17.9046 4.96845 16.6668 5.54295 15.6652L10.6356 6.7879L10.7156 6.6621ZM12 15.3335C12.3419 15.3335 12.6237 15.5909 12.6622 15.9224L12.6667 16.0068C12.6667 16.375 12.3682 16.6735 12 16.6735C11.6581 16.6735 11.3763 16.4161 11.3378 16.0846L11.3333 16.0002C11.3333 15.632 11.6318 15.3335 12 15.3335ZM12.6622 9.25575C12.6237 8.92419 12.3419 8.66683 12 8.66683C11.6318 8.66683 11.3333 8.9653 11.3333 9.33349V13.3335L11.3378 13.4112C11.3763 13.7428 11.6581 14.0002 12 14.0002C12.3682 14.0002 12.6667 13.7017 12.6667 13.3335V9.33349L12.6622 9.25575Z"
          : "M19.6747 15.6668L14.1187 5.9015C13.182 4.25484 10.818 4.25484 9.88134 5.9015L4.32534 15.6668C3.39534 17.3008 4.57001 19.3335 6.44401 19.3335H17.556C19.43 19.3335 20.6047 17.3008 19.6747 15.6668ZM11.3333 8.66617C11.3333 8.29817 11.632 7.9995 12 7.9995C12.368 7.9995 12.6667 8.2975 12.6667 8.66617V13.3335C12.6667 13.7015 12.368 14.0002 12 14.0002C11.632 14.0002 11.3333 13.7022 11.3333 13.3335V8.66617ZM11 16.3335C11 16.8855 11.448 17.3335 12 17.3335C12.552 17.3335 13 16.8855 13 16.3335C13 15.7815 12.552 15.3335 12 15.3335C11.448 15.3335 11 15.7815 11 16.3335Z"
      }
    />
  </svg>
);
