import { Record } from "immutable";
import { createSelector } from "reselect";

import { USER_LOGIN_STATE, IUser, USER_ROLES } from "@typings/user";

import { IStore } from "@work/redux/reducers";

const getUserStateFunction = (store: IStore): USER_LOGIN_STATE => {
  return store.user.get("state");
};
export const getUserState = createSelector(getUserStateFunction, f => f);

const isUserStateKnownFunction = (store: IStore): boolean =>
  getUserState(store) !== USER_LOGIN_STATE.UNKNOWN;
export const isUserStateKnown = createSelector(
  isUserStateKnownFunction,
  f => f,
);

const isUserLoggedInFunction = (store: IStore): boolean => {
  return getUserState(store) === USER_LOGIN_STATE.LOGGED_IN;
};
export const isUserLoggedIn = createSelector(isUserLoggedInFunction, f => f);

const isUserDataFetchedFunction = (store: IStore): boolean => {
  return store.user.get("isFetched");
};
export const isUserDataFetched = createSelector(
  isUserDataFetchedFunction,
  f => f,
);

const getUserDataFunction = (store: IStore): Record<IUser> | null => {
  if (isUserLoggedIn(store)) {
    return store.user.get("data");
  }

  return null;
};
export const getUserData = createSelector(getUserDataFunction, f => f);

const isUserAdminFunction = (store: IStore): boolean => {
  if (isUserLoggedIn(store)) {
    const roles = getUserData(store)!.get("roles") as USER_ROLES[];

    return (
      roles.indexOf(USER_ROLES.ROLE_ADMIN) > -1 ||
      roles.indexOf(USER_ROLES.ROLE_SUPER_ADMIN) > -1
    );
  }

  return false;
};
export const isUserAdmin = createSelector(isUserAdminFunction, f => f);

const getUserZipCodeFunction = (store: IStore): string => {
  return store.user.getIn(["data", "preferredZipCode"]);
};
export const getUserZipCode = createSelector(getUserZipCodeFunction, f => f);

const isUserClientFunction = (store: IStore): boolean => {
  if (isUserLoggedIn(store)) {
    const roles = getUserData(store)!.get("roles") as USER_ROLES[];

    return roles.includes(USER_ROLES.ROLE_CLIENT);
  }

  return false;
};
export const isUserClient = createSelector(isUserClientFunction, f => f);
