import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

import { langFromPathname } from "@services/LangFromPathname";
import { resolveCountryFromUrl } from "@services/ResolveCountryFromUrl";

export const getLangCode = (): string => {
  const country = resolveCountryFromUrl();
  const lang = langFromPathname();

  switch (lang) {
    case AVAILABLE_LANGS.FR: {
      switch (country) {
        case AVAILABLE_COUNTRIES.CH: {
          return "fr-CH";
        }
        case AVAILABLE_COUNTRIES.BE: {
          return "fr-BE";
        }
        default:
        case AVAILABLE_COUNTRIES.FR: {
          return "fr-FR";
        }
      }
    }
    case AVAILABLE_LANGS.DE: {
      switch (country) {
        case AVAILABLE_COUNTRIES.CH: {
          return "de-CH";
        }
        case AVAILABLE_COUNTRIES.AT: {
          return "de-AT";
        }
        default:
        case AVAILABLE_COUNTRIES.DE: {
          return "de-DE";
        }
      }
    }
    case AVAILABLE_LANGS.IT:
      switch (country) {
        case AVAILABLE_COUNTRIES.CH: {
          return "it-CH";
        }
        default:
        case AVAILABLE_COUNTRIES.IT: {
          return "it-IT";
        }
      }
    case AVAILABLE_LANGS.PL: {
      return "pl-PL";
    }
    case AVAILABLE_LANGS.NL: {
      switch (country) {
        case AVAILABLE_COUNTRIES.BE: {
          return "nl-BE";
        }
        default:
        case AVAILABLE_COUNTRIES.NL: {
          return "nl-NL";
        }
      }
    }
    case AVAILABLE_LANGS.EN: {
      switch (country) {
        case AVAILABLE_COUNTRIES.US: {
          return "en-US";
        }
        default:
        case AVAILABLE_COUNTRIES.UK: {
          return "en-GB";
        }
      }
    }
  }
};
