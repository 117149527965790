import React from "react";
import styled, { useTheme } from "styled-components";
import { SliceComponentProps } from "@prismicio/react";

import { ANDROID_APP_LINK, IOS_APP_LINK } from "@config/consts";

import { Heading } from "@ui/Atoms/Headings";
import { Button } from "@ui/Atoms/Button";
import { Margins } from "@ui/Atoms";

import { iOS } from "@services/iOS";

import { useBreakpoint } from "@hooks/useBreakpoint";

import {
  Maybe,
  PrismicGlobalContentPageDataBodyTitleDescription,
} from "../../../graphql-types";

const TitleDescriptionSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyTitleDescription>,
): React.ReactElement => {
  const theme = useTheme();
  const { mobile } = useBreakpoint();

  const data = props.slice.primary;

  const showSecondButton = props.slice.primary.is_second_button_mobile_only
    ? mobile
    : !mobile;

  const handleSecondButtonClick = (actionType?: Maybe<string> | undefined) => {
    switch (actionType) {
      case "App Download":
        iOS()
          ? (window.location.href = IOS_APP_LINK)
          : (window.location.href = ANDROID_APP_LINK);
        break;

      default:
        break;
    }
  };

  return (
    <Wrapper>
      {data?.section_title?.text && (
        <StyledHeading type="h3" styleAs="h5" color={theme.colors.white}>
          {data?.section_title?.text}
        </StyledHeading>
      )}

      {data?.section_description?.text && (
        <Description>{data?.section_description?.text}</Description>
      )}

      <ButtonsWrapper>
        {props.slice.primary?.button_url?.url && (
          <Button
            as="a"
            variant="green"
            href={props.slice.primary?.button_url?.url}
          >
            {props.slice.primary.button_text?.text}
          </Button>
        )}

        {showSecondButton && props.slice.primary?.second_button_text?.text && (
          <Margins xs={[null, null, "base_x2", null]}>
            <Button
              variant="green"
              onClick={() =>
                handleSecondButtonClick(props.slice.primary.second_button_type)
              }
            >
              {props.slice.primary.second_button_text?.text}
            </Button>
          </Margins>
        )}
      </ButtonsWrapper>
    </Wrapper>
  );
};

export { TitleDescriptionSlice };

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: ${props => props.theme.margins.base_x8}
    ${props => props.theme.margins.base_x3};
  background: ${props => props.theme.colors.black800};
  color: ${props => props.theme.colors.white};

  ${props => props.theme.breakpoints.desktop} {
    align-items: center;
    padding: ${props => props.theme.margins.base_x8} 0;
  }
`;

const Description = styled.p`
  margin: ${props =>
    `${props.theme.margins.base} 0 ${props.theme.margins.base_x2}`};
  text-align: left;

  ${props => props.theme.breakpoints.tablet} {
    width: 50%;
  }

  ${props => props.theme.breakpoints.desktop} {
    margin: ${props =>
      `${props.theme.margins.base_x2} 0 ${props.theme.margins.base_x4}`};
    text-align: center;
  }
`;

const StyledHeading = styled(Heading)`
  font-size: ${props => props.theme.fonts.sizes.h5};
  font-weight: ${props => props.theme.fonts.weights.bold};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.h3};
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;
