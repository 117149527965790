import * as React from "react";
import { graphql, PageProps } from "gatsby";
import ReactMarkdown from "react-markdown";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { Paragraph, Section, Margins, OptionPanel } from "@ui/Atoms";
import { Heading } from "@ui/Atoms/Headings";

import { langFromTag } from "@services/LangFromTag/LangFromTag";
import rehypeRaw from "rehype-raw";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { repositoryConfigs } from "../config/repositoryConfig";

type Props = PageProps<
  {
    prismicContentPage: {
      data: {
        is_teaser_version: boolean;
        country: AVAILABLE_COUNTRIES;
        origin: ORIGIN;
        title: { text: string };
        content: { raw: { text: string; url: string }[] };
      };
    };
  },
  {
    lang: string;
    country: AVAILABLE_COUNTRIES;
  }
>;

const ContentPage = (props: Props): React.ReactElement => {
  const { prismicContentPage } = props.data;
  const title = prismicContentPage.data.title.text;
  const content = prismicContentPage.data.content.raw;
  const origin = prismicContentPage.data.origin;

  const lang = props.pageContext.lang;
  const country = props.pageContext.country;

  return (
    <Layout
      language={langFromTag(lang) || DEFAULT_LANGUAGE}
      country={country}
      origin={origin}
      isTeaserVersion={prismicContentPage.data.is_teaser_version}
    >
      <SEO title={title} path={props.location.pathname} />
      <Section small>
        <OptionPanel>
          <Margins xs={[null, "base_x2", null, null]}>
            <Heading type="h1" styleAs="h4" withWordBreak>
              {title}
            </Heading>
          </Margins>
          {content.map((item, idx) => (
            <React.Fragment key={idx}>
              <ReactMarkdown
                key={idx}
                unwrapDisallowed
                rehypePlugins={[rehypeRaw]}
                components={{
                  p: Paragraph,
                }}
              >
                {item.text}
              </ReactMarkdown>
              {item.url && (
                <Paragraph>
                  <img src={item.url} />
                </Paragraph>
              )}
            </React.Fragment>
          ))}
        </OptionPanel>
      </Section>
    </Layout>
  );
};

export default withPrismicPreview(ContentPage);

export const pageQuery = graphql`
  query ContentPageQuery($id: String) {
    prismicContentPage(id: { eq: $id }) {
      _previewable
      data {
        is_teaser_version
        country
        origin
        content {
          raw
        }
        title {
          text
        }
      }
    }
  }
`;
