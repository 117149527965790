import React from "react";
import styled from "styled-components";

import { Container } from "@ui/Atoms";
import { transition } from "@ui/helpers";
import theme from "@ui/themes/default";
import { Play } from "@ui/Assets/Symbolicons/Play";

import { getIframeSrc } from "@services/GetIframeSrc";

import { Maybe, PrismicImageField, PrismicLinkField } from "@www/graphql-types";

import { GatsbyImage } from "../../components/GatsbyImage";
import { StyledSection } from "../Homepage/shared";

export interface VideoProps {
  video_image: Maybe<PrismicImageField>;
  video_url: Maybe<PrismicLinkField>;
}

const FullVideoSection = (props: VideoProps): React.ReactElement => {
  const [showVideo, setShowVideo] = React.useState<boolean>(false);
  const videoEmbedUrl = getIframeSrc(props.video_url?.url);

  if (showVideo && videoEmbedUrl) {
    return (
      <Section isWhite>
        <Container>
          <IframeWrapper>
            <StyledIframe
              src={videoEmbedUrl}
              frameBorder="0"
              allowFullScreen
              allow="autoplay"
            />
          </IframeWrapper>
        </Container>
      </Section>
    );
  }

  return (
    <Section isWhite>
      <Container>
        <Wrapper onClick={() => setShowVideo(true)}>
          {!!props.video_image && (
            <GatsbyImage
              image={props.video_image.gatsbyImageData}
              alt={props.video_image.alt || ""}
            />
          )}

          <PlayButton>
            <Play size={72} color={theme.colors.blue500} />
          </PlayButton>
        </Wrapper>
      </Container>
    </Section>
  );
};

export { FullVideoSection };

const Section = styled(StyledSection)`
  padding: 0;
  overflow: hidden;
`;

const IframeWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 */
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${props => props.theme.border.radius.base_x2};
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transition: ${transition("transform")};
  transform: translate(-50%, -50%);
  background: ${props => props.theme.colors.white};
  border-radius: 50%;
  z-index: 1;
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  img {
    border-radius: ${props => props.theme.border.radius.base_x2};
  }

  &:hover ${PlayButton} {
    transform: translate(-50%, -50%) scale(1.1);
  }
`;
