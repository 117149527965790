/**
 * @author tomekbuszewski
 * @since 2019-6-17
 */

import styled from "styled-components";

import { rem, transition } from "@ui/helpers";

import { TabComponent, TabProps } from "./Tab.types";
import { variantStyles } from "./Tab.variants";

/**
 * Tab item
 */

const Tab: TabComponent = styled.button<TabProps>`
  transition: ${transition(["background", "color"])};

  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${props => props.theme.buttons.lineHeight};

  min-height: ${rem(28)};
  max-height: ${props => props.theme.buttons.height};
  position: relative;
  border-right: 0;
  display: inline-flex;
  align-items: center;

  ${props =>
    !props.active &&
    `
    &:hover {
      background: ${props.theme.colors.blue50};
    }
  `}

  &:active,
  &:focus {
    outline: 0;
  }

  &:disabled {
    color: ${props => props.theme.colors.greyDisabled};
    pointer-events: none;
  }

  ${props => variantStyles(props)}
`;

export { Tab };

Tab.displayName = "Tab";
