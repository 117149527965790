import { FunctionComponent } from "react";
import styled from "styled-components";

// TODO: refactor all icons to allow to add `scale` prop and remove scaleIcon function
export const scaleIcon = (
  node: FunctionComponent<any>,
  scale: number,
) => styled(node)`
  transform: scale(${scale || 1});
`;
