import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { Row, Container, Heading, Margins, CheckListItem } from "@ui/Atoms";
import theme from "@ui/themes/default";

import { GatsbyImage } from "../../components/GatsbyImage";
import { List } from "../Homepage/";

import { Description, Section, StyledColumn, StyledHeading } from "./shared";

interface ListItem {
  list_item: { text: string };
}

export interface TimeManagementProps {
  time_management_description: {
    text: string;
  };
  time_management_image: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  time_management_list: ListItem[];

  time_management_title: {
    text: string;
  };
  title: {
    text: string;
  };
}

const TimeManagement = (props: TimeManagementProps): React.ReactElement => {
  return (
    <Section isWhite>
      <Container>
        <Row>
          <StyledColumn orderXs={2} orderLg={1} lg={5}>
            <ImageWrapper>
              <GatsbyImage
                image={props.time_management_image.gatsbyImageData}
                alt={props.time_management_image.alt}
              />
            </ImageWrapper>
          </StyledColumn>
          <StyledColumn lg={7} orderLg={2}>
            <TextWrapper>
              <Margins xs={[null, "base_x3", null, null]}>
                <Heading type="h2" styleAs="h4" textAlignXs="left">
                  {props.time_management_title.text}
                </Heading>
              </Margins>
              <Description color={theme.colors.grey700}>
                {props.time_management_description.text}
              </Description>
              <List isFullWidth>
                {props.time_management_list.map(item => (
                  <CheckListItem
                    key={item.list_item.text}
                    value={item.list_item.text}
                  />
                ))}
              </List>
            </TextWrapper>
          </StyledColumn>
        </Row>
      </Container>
    </Section>
  );
};

export { TimeManagement };

const ImageWrapper = styled.div`
  margin-top: ${props => props.theme.margins.base_x4};

  & > div {
    border-radius: ${props => props.theme.border.radius.base};
  }

  ${props => props.theme.breakpoints.tablet} {
    margin-top: ${props => props.theme.margins.base_x8};
  }

  ${props => props.theme.breakpoints.desktop} {
    padding-right: ${props => props.theme.margins.base_x4};
    width: 100%;
    margin-top: 0;
  }
`;

const TextWrapper = styled.div`
  ${StyledHeading} {
    margin-top: 0;
    text-align: left;
    line-height: normal;
  }

  ${Description} {
    text-align: left;
    margin-bottom: ${props => props.theme.margins.base_x6};
  }

  ${props => props.theme.breakpoints.desktop} {
    padding-left: ${props => props.theme.margins.base_x4};
  }
`;
