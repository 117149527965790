import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Envelope = (props: Icon): React.ReactElement => (
  <svg
    style={{ ...style(props.size, props.height), ...props.style }}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M7.3333333,11.442 L7.3333333,9.99999995 C7.3333333,9.26333332 7.93066664,8.66666665 8.66666665,8.66666665 L15.3333334,8.66666665 C16.0693334,8.66666665 16.6666667,9.26333332 16.6666667,9.99999995 L16.6666667,11.4453333 L17.9893334,10.7186667 L19.3413334,9.88066665 L12.0173333,3.99999995 L4.65999995,9.88199999 L6.0133333,10.7206667 L7.3333333,11.442 Z M9.99999999,10.6666667 C9.63333332,10.6666667 9.33333332,10.9666667 9.33333332,11.3333333 C9.33333332,11.7 9.63333332,12 9.99999999,12 L14,12 C14.3666667,12 14.6666667,11.7 14.6666667,11.3333333 C14.6666667,10.9666667 14.3666667,10.6666667 14,10.6666667 L9.99999999,10.6666667 Z M19.9993334,11.042 L12.702,15.5646667 C12.2713333,15.8313334 11.7273333,15.8313334 11.2973333,15.5646667 L4.00799995,11.0466667 C4.00466662,11.0486667 4.00399995,11.0493333 3.99999995,11.0513333 L3.99999995,18.6666667 C3.99999995,19.4033334 4.59733329,20.0000001 5.33333329,20.0000001 L18.6666667,20.0000001 C19.4026667,20.0000001 20,19.4033334 20,18.6666667 L19.9993334,11.042 Z"
        id="path-1-envelope"
      ></path>
    </defs>
    <g
      id="Atom/icon/email/mail-letter/solid"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <mask id="mask-2-envelope" fill="white">
        <use xlinkHref="#path-1-envelope"></use>
      </mask>
      <use
        id="Atom/icon/mail-letter"
        fill="#FFFFFF"
        fillRule="nonzero"
        xlinkHref="#path-1-envelope"
      ></use>
      <g
        id="Group"
        mask="url(#mask-2-envelope)"
        fill={props.color || theme.colors.grey700}
      >
        <g id="🎨-color">
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
        </g>
      </g>
    </g>
  </svg>
);
