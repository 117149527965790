import * as Yup from "yup";

import * as HTTP_CODE from "@config/httpStatuses";

import { OAUTH_PROVIDERS, IOAuthState } from "@typings/oauth";
import { MODALS } from "@typings/modals";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { showToast } from "@containers/Toastify";

export enum FIELDS {
  USERNAME = "username",
  PASSWORD = "password",
  REMEMBER_ME = "rememberMe",
  EMAIL = "email",
}

interface HandleOAuthLoginSuccess {
  provider: OAUTH_PROVIDERS;
  accessToken: string;
  setError: (val: string | null) => void;
  hideModal: (id: string) => void;
  onOAuthLoginSuccess?: () => void;
  loginUserByAccessToken: (
    provider: OAUTH_PROVIDERS,
    accessToken: string,
  ) => Promise<AsyncActionResult>;
  fetchUserData: () => Promise<AsyncActionResult>;
  profile: { firstName: string; lastName: string; email: string };
  tryTemporaryLogin?: boolean;
  setOAuthUserData: (payload: IOAuthState) => void;
  infoToastText?: string;
}

export const handleOAuthLogin = async ({
  provider,
  accessToken,
  setError,
  hideModal,
  onOAuthLoginSuccess,
  loginUserByAccessToken,
  fetchUserData,
  profile,
  tryTemporaryLogin,
  setOAuthUserData,
  infoToastText,
}: HandleOAuthLoginSuccess): Promise<void> => {
  const loginResp = await loginUserByAccessToken(provider, accessToken);

  /** Scenario 1: User logs in from Navbar
   *  OAuth login to Batmaid unsuccesfull -> show toast
   */
  if (!tryTemporaryLogin && loginResp.status !== HTTP_CODE.OK) {
    infoToastText && showToast(infoToastText, { type: "dark" });
    return;
  }

  /**
   * Scenario 2: User logs in from Booking page
   * OAuth login to Batmaid uscussefull -> user doesn't have a connected OAuth account
   * create 'temporary account' for Booking purpose
   */
  if (tryTemporaryLogin && loginResp.status !== HTTP_CODE.OK) {
    setOAuthUserData({
      provider,
      accessToken,
      ...profile,
    });
    hideModal(MODALS.NAVBAR_LOGIN);
    return;
  }

  await fetchUserData();
  onOAuthLoginSuccess?.();
  hideModal(MODALS.NAVBAR_LOGIN);
};

export const getLoginSchema = (
  emailWrongFormatText: string,
): Yup.ObjectSchema =>
  Yup.object().shape({
    email: Yup.string().email(emailWrongFormatText),
  });

export const sharedInputProps = {
  autoComplete: "none",
  autoCapitalize: "none",
  autoCorrect: "off",
  spellCheck: false,
};
