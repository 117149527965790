/**
 * @author jakubmaslon
 * @since 2020-11-19
 */

import * as React from "react";
import styled from "styled-components";

import { Heading } from "@ui/Atoms/Headings";
import { rem } from "@ui/helpers";

interface Props {
  title: string;
  color?: string;
  variant: "heading" | "caption";
  className?: string;
}

const PriceTitle = (props: Props): React.ReactElement => (
  <TitleContainer className={props.className}>
    {props.variant === "caption" && (
      <PriceTitleSubtitle color={props.color}>{props.title}</PriceTitleSubtitle>
    )}

    {props.variant === "heading" && (
      <Title type="h5" color={props.color}>
        {props.title}
      </Title>
    )}
  </TitleContainer>
);

PriceTitle.defaultProps = {
  variant: "heading",
};

export { PriceTitle, PriceTitleSubtitle };

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled(Heading)<{ color?: string }>`
  display: inline-block;
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.bold};
  text-transform: uppercase;
  margin-bottom: 0;
  margin-right: auto;
  color: ${props => props.color || props.theme.colors.black700};
  line-height: ${rem(24)};
`;

const PriceTitleSubtitle = styled.span<{ color?: string }>`
  display: inline-block;
  font-size: ${props => props.theme.fonts.sizes.caption};
  line-height: ${props => props.theme.fonts.sizes.body2};
  padding-bottom: ${props => props.theme.margins.half};
  margin-right: auto;
  color: ${props => props.color || props.theme.colors.black700};
`;
