import * as React from "react";
import { graphql, PageProps } from "gatsby";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { Section, Heading, OptionPanel, Paragraph } from "@ui/Atoms";

import { langFromTag } from "@services/LangFromTag/LangFromTag";

import { Markdown } from "@containers/Markdown";

import { useTranslate } from "@hooks/useTranslate";

import { Breadcrumbs } from "../components/Breadcrumbs";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

type Props = PageProps<
  {
    prismicHelpArticle: {
      data: {
        id: string;
        slug: { text: string };
        title: { text: string };
        seo_title: { text: string };
        content: { text: string };
      };
    };
    prismicHelpCategory?: {
      data: {
        title: { text: string };
        slug: { text: string };
      };
    };
  },
  {
    country: AVAILABLE_COUNTRIES;
    origin: ORIGIN;
    lang: string;
  }
>;

const HelpArticle = (props: Props): React.ReactElement => {
  // prismicHelpArticle props
  const origin = props.pageContext.origin;
  const lang = langFromTag(props.pageContext.lang);
  const slug = props.data.prismicHelpArticle.data.slug.text.trim();
  const title = props.data.prismicHelpArticle.data.title.text;
  const content = props.data.prismicHelpArticle.data.content.text;
  const seoTitle = props.data.prismicHelpArticle.data.seo_title.text;

  // prismicHelpCategory props
  const categoryTitle = props.data.prismicHelpCategory?.data.title.text || "";
  const categorySlug =
    props.data.prismicHelpCategory?.data.slug.text.trim() || "";

  const translate = useTranslate(`${origin}_pwa_navigation`);

  const escapeDoubleQuotes = (str: string) => {
    if (!str) {
      return "";
    }

    return str.replace(/\\([\s\S])|(")/g, "\\$1$2");
  };

  return (
    <Layout
      language={lang || DEFAULT_LANGUAGE}
      origin={props.pageContext.origin}
    >
      <SEO
        title={seoTitle}
        structuredData={`
            {
              "@context": "https://schema.org",
              "@type": "FAQPage",
              "mainEntity": [
                {
                  "@type": "Question",
                  "name": "${escapeDoubleQuotes(title)}",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "${escapeDoubleQuotes(content)}"
                  }
                }
              ]
            }
        `}
        path={props.location.pathname}
      />
      <Section flat>
        <Breadcrumbs
          paths={[
            {
              title: translate("help"),
              href: `/${lang}/${slug.split("/")[0]}`,
            },
            {
              title: categoryTitle,
              href: `/${lang}/${categorySlug}`,
            },
          ]}
          currentPath={title}
        />
      </Section>
      <Section small>
        <OptionPanel>
          <Heading type="h1" styleAs="h4">
            {title}
          </Heading>
          <Paragraph type="body">
            <Markdown>{content}</Markdown>
          </Paragraph>
        </OptionPanel>
      </Section>
    </Layout>
  );
};

export default HelpArticle;

export const articleQuery = graphql`
  query HelpArticleQuery($id: String, $categoryId: ID) {
    prismicHelpArticle(id: { eq: $id }) {
      id
      data {
        title {
          text
        }
        slug {
          text
        }
        seo_title {
          text
        }
        content {
          text
        }
        category {
          id
        }
        sort_order
      }
    }
    prismicHelpCategory(prismicId: { eq: $categoryId }) {
      data {
        title {
          text
        }
        slug {
          text
        }
      }
    }
  }
`;
