import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const HeadphonesMicrophone = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M21 15v-4a9 9 0 0 0-9-9v0a9 9 0 0 0-9 9v4" />
      <rect x={3} y={10} width={4} height={7} rx={2} />
      <rect x={17} y={10} width={4} height={7} rx={2} />
      <rect x={9.5} y={19} width={5} height={3} rx={1.5} />
      <path d="M9.5 20.5H8a3 3 0 0 1-3-3V17" />
    </svg>
  );
};
