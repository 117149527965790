/**
 * @author michalstolarski
 * @since 2023-2-2
 */

import * as React from "react";
import styled, { useTheme } from "styled-components";

import { InfoCircle } from "@ui/Assets/MyIcons";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

import { Markdown } from "@containers/Markdown";

interface Props {
  testId?: string;
  className?: string;
  content: string;
}

const SummaryDescription = (props: Props): React.ReactElement => {
  const theme = useTheme();

  return (
    <TestWrapper testId={props.testId}>
      <Description className={props.className}>
        <IconWrapper>
          <InfoCircle width={theme.icons.sizes.base_x3} />
        </IconWrapper>

        <div>
          <Markdown>{props.content}</Markdown>
        </div>
      </Description>
    </TestWrapper>
  );
};

export { SummaryDescription };

const Description = styled.div`
  margin-top: ${props => props.theme.margins.base};
  font-size: ${props => props.theme.fonts.sizes.body2};
  line-height: ${props => props.theme.fonts.lineHeights.normal};
  color: ${props => props.theme.colors.grey700};
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${props => props.theme.margins.base};

  ${props => props.theme.breakpoints.mobile} {
    margin-top: ${props => props.theme.margins.base_x1_5};
  }

  a {
    color: ${props => props.theme.colors.blue400};
  }
`;

const IconWrapper = styled.div`
  font-size: 0;
  line-height: 0;
`;
