import { Record, List } from "immutable";
import { AnyAction } from "redux";

import {
  ACTION_SUCCESS,
  ACTION_NO_DATA,
  ACTION_FAIL,
} from "@services/AsyncActionCreatorFactory";

import * as HTTP_CODE from "@config/httpStatuses";

import { ApplyState, ApplyForm } from "./apply.typings";
import {
  applyFormDefaultState,
  deleteApplicationFileFactory,
} from "./apply.factories";
import {
  SET_FORM,
  SUBMIT_EMAIL,
  FETCH_PREFILLED_DATA,
  SET_EXPERIENCE_SOURCES,
  SUBMIT_APPLICATION_FILE,
  DELETE_APPLICATION_FILE,
  AUTHORIZE_BY_TOKEN,
  SET_FROM_LOCAL_STORAGE,
  SUBMIT_PERSONAL_INFO,
  SUBMIT_EXPERIENCE_INFO,
  DELETE_ACCESS_TOKEN,
} from "./apply.actions";

const initialState = Record<ApplyState>({
  form: Record<ApplyForm>(applyFormDefaultState)(),
  accessToken: null,
  isFormDataPrefetched: false,
  isTokenInvalid: false,
  isLocalStorageChecked: false,
});

const applyReducer = (
  store: Record<ApplyState> = initialState(),
  action: AnyAction,
): Record<ApplyState> => {
  const { type, payload, params, status } = action;

  switch (type) {
    case SET_FORM: {
      return store.mergeDeepIn(["form"], payload);
    }
    case SET_EXPERIENCE_SOURCES: {
      return store.setIn(["form", "sourcesOfExperience"], payload);
    }

    case `${SUBMIT_APPLICATION_FILE}${ACTION_SUCCESS}`: {
      const fileList = List.isList(store.get("form").get("files"))
        ? store.get("form").get("files")
        : List();

      return store.setIn(["form", "files"], fileList.push(payload.data));
    }

    case `${DELETE_APPLICATION_FILE}${ACTION_NO_DATA}`: {
      return store.setIn(
        ["form", "files"],
        deleteApplicationFileFactory(store.get("form"), params.id),
      );
    }

    case `${SUBMIT_EMAIL}${ACTION_SUCCESS}`: {
      return store
        .set("form", Record(applyFormDefaultState)())
        .set("accessToken", payload.data.accessToken);
    }

    case `${FETCH_PREFILLED_DATA}${ACTION_SUCCESS}`: {
      return store
        .mergeDeepIn(["form"], payload.data)
        .set("isFormDataPrefetched", true);
    }

    case `${FETCH_PREFILLED_DATA}${ACTION_FAIL}`: {
      return store.set("isTokenInvalid", status === HTTP_CODE.UNAUTHORIZED);
    }

    case `${AUTHORIZE_BY_TOKEN}${ACTION_SUCCESS}`: {
      return store.set("accessToken", payload.data.accessToken);
    }

    case `${SUBMIT_PERSONAL_INFO}${ACTION_FAIL}`: {
      return store.set("isTokenInvalid", status === HTTP_CODE.UNAUTHORIZED);
    }

    case `${SUBMIT_EXPERIENCE_INFO}${ACTION_FAIL}`: {
      return store.set("isTokenInvalid", status === HTTP_CODE.UNAUTHORIZED);
    }

    case DELETE_ACCESS_TOKEN: {
      return store.set("accessToken", null);
    }

    case SET_FROM_LOCAL_STORAGE: {
      return store
        .merge(action.payload || store)
        .set("isLocalStorageChecked", true);
    }

    default: {
      return store;
    }
  }
};

export { applyReducer };
