/**
 * @author tomekbuszewski
 * @since 2019-3-19
 */

import * as React from "react";
import styled from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  children: React.ReactNode;
  theme: ITheme;
}

const Main = styled.div<Props>`
  min-height: 50vh;

  ${props => props.theme.breakpoints.desktop} {
    min-height: 76vh;
  }
`;

export { Main };

Main.displayName = "Main";
