import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const LivingRoomColorful = (props: Icon): React.ReactElement => (
  <svg
    style={{ ...style(props.size), ...props.style }}
    viewBox="0 0 168 104"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Atom/artwork/salon</title>
    <g
      id="On-boarding"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="how-it-works-step-3---8pt-copy-2"
        transform="translate(-104.000000, -288.000000)"
      >
        <g
          id="Organism/widget/onboarding/step-3"
          transform="translate(32.000000, 184.000000)"
        >
          <g
            id="Atom/artwork/salon"
            transform="translate(72.000000, 104.000000)"
          >
            <g id="frame-portrait" transform="translate(114.361446, 0.000000)">
              <path
                d="M18.2168675,8.00000005 C17.9579254,8.00000005 17.6989834,7.9023438 17.5013178,7.7070312 L12.1445783,2.4140625 L6.7878388,7.7070313 C6.39250748,8.0976563 5.75207073,8.0976563 5.35673941,7.7070313 C4.96140808,7.3164063 4.96140808,6.6835938 5.35673941,6.2929688 L11.4290286,0.2929688 C11.8243599,-0.0976562 12.4647966,-0.0976562 12.860128,0.2929688 L18.9324171,6.2929688 C19.3277484,6.6835938 19.3277484,7.3164063 18.9324171,7.7070313 C18.7347516,7.9023438 18.4758095,8.00000005 18.2168675,8.00000005 Z"
                id="Path"
                fill="#505050"
              ></path>
              <path
                d="M22.7710843,6 L1.51807229,6 C0.678981513,6 0,6.6708984 0,7.5 L0,22.5 C0,23.3291016 0.678981513,24 1.51807229,24 L22.7710843,24 C23.6101751,24 24.2891566,23.3291016 24.2891566,22.5 L24.2891566,7.5 C24.2891566,6.6708984 23.6101751,6 22.7710843,6 Z"
                id="Path"
                fill="#1EAAE1"
              ></path>
              <rect
                id="Rectangle"
                fill="#BEE6FA"
                x="3.03614458"
                y="9"
                width="18.2168675"
                height="12"
              ></rect>
              <path
                d="M17.1662745,21 C16.9681147,19.3269043 14.804664,18 12.1445783,18 C9.48449263,18 7.32104196,19.3269043 7.12263507,21 L17.1662745,21 Z"
                id="Path"
                fill="#2980B9"
              ></path>
              <ellipse
                id="Oval"
                fill="#2980B9"
                cx="12.4811272"
                cy="14"
                rx="3.03614458"
                ry="3"
              ></ellipse>
            </g>
            <g id="lamp-floor" transform="translate(139.662651, 33.000000)">
              <path
                d="M19.4579813,1.45747885 C18.9521837,0.557881633 17.9927434,0 16.9513954,0 L8.85583347,0 C7.8144854,0 6.85504518,0.557881633 6.34924758,1.45747885 L0,12.75 L25.8072289,12.75 L19.4579813,1.45747885 Z"
                id="Path"
                fill="#1ABC9C"
              ></path>
              <path
                d="M11.4698795,12.75 L11.4698795,31.1666667 L10.0361446,31.1666667 C8.45259552,31.1666667 7.1686747,32.4353027 7.1686747,34 L18.6385542,34 C18.6385542,32.4353027 17.3546334,31.1666667 15.7710843,31.1666667 L14.3373494,31.1666667 L14.3373494,12.75 L11.4698795,12.75 Z"
                id="Path"
                fill="#505050"
              ></path>
            </g>
            <g id="table-side" transform="translate(117.397590, 67.000000)">
              <path
                d="M2.08032129,34.9444444 C2.08032129,36.079617 3.01179325,37 4.16064257,37 C5.30949189,37 6.24096386,36.079617 6.24096386,34.9444444 L6.24096386,16.4444444 L43.686747,16.4444444 L43.686747,34.9444444 C43.686747,36.079617 44.618219,37 45.7670683,37 C46.9159176,37 47.8473896,36.079617 47.8473896,34.9444444 L47.8473896,4.11111111 L2.08032129,4.11111111 L2.08032129,34.9444444 Z"
                id="Path"
                fill="#F1C40F"
              ></path>
              <ellipse
                id="Oval"
                fill="#F39C12"
                cx="39.5261044"
                cy="10.2777778"
                rx="2.08032129"
                ry="2.05555556"
              ></ellipse>
              <ellipse
                id="Oval"
                fill="#F39C12"
                cx="10.4016064"
                cy="10.2777778"
                rx="2.08032129"
                ry="2.05555556"
              ></ellipse>
              <path
                d="M47.8473896,0 L2.08032129,0 C0.936043059,0 0,0.924899689 0,2.05555556 C0,3.18621142 0.936043059,4.11111111 2.08032129,4.11111111 L47.8473896,4.11111111 C48.9916678,4.11111111 49.9277108,3.18621142 49.9277108,2.05555556 C49.9277108,0.924899689 48.9916678,0 47.8473896,0 Z"
                id="Path"
                fill="#E67E22"
              ></path>
            </g>
            <g id="sofa" transform="translate(-0.000000, 24.000000)">
              <path
                d="M98.9558233,53.3333333 L98.9558233,26.6666667 C98.9558233,11.9390191 86.8729606,0 71.9678715,0 L35.9839357,0 C21.0788467,0 8.99598394,11.9390191 8.99598394,26.6666667 L8.99598394,53.3333333 L98.9558233,53.3333333 Z"
                id="Path"
                fill="#1EAAE1"
              ></path>
              <ellipse
                id="Oval"
                fill="#2980B9"
                cx="35.9839357"
                cy="26.6666667"
                rx="4.49799197"
                ry="4.44444444"
              ></ellipse>
              <ellipse
                id="Oval"
                fill="#2980B9"
                cx="71.9678715"
                cy="26.6666667"
                rx="4.49799197"
                ry="4.44444444"
              ></ellipse>
              <path
                d="M98.9558233,31.1111111 C93.9878262,31.1111111 89.9598394,35.0911458 89.9598394,40 L89.9598394,53.3333333 L17.9919679,53.3333333 L17.9919679,40 C17.9919679,35.0911458 13.963981,31.1111111 8.99598394,31.1111111 C4.02798689,31.1111111 0,35.0911458 0,40 L0,71.1111111 L107.951807,71.1111111 L107.951807,40 C107.951807,35.0911458 103.92382,31.1111111 98.9558233,31.1111111 Z"
                id="Path"
                fill="#2980B9"
              ></path>
              <polygon
                id="Path"
                fill="#2980B9"
                points="107.951807 80 94.4578313 80 89.9598394 71.1111111 107.951807 71.1111111"
              ></polygon>
              <polygon
                id="Path"
                fill="#2980B9"
                points="13.4939759 80 0 80 0 71.1111111 17.9919679 71.1111111"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
