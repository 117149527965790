import React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

import { AVAILABLE_COUNTRIES } from "@typings/globals";

import { Flag } from "@ui/Assets";
import { rem } from "@ui/helpers";

import { Scalars } from "../../graphql-types";

interface Props {
  countryCode: AVAILABLE_COUNTRIES;
  countryName: Scalars["PrismicRichText"];
  field: Scalars["PrismicRichText"];
}

const CountryLinks = (props: Props): React.ReactElement => (
  <Button>
    <Flag flag={props.countryCode} width={rem(16)} />
    <Details>
      <strong>
        <PrismicRichText field={props.countryName} />
      </strong>
      <PrismicRichText
        field={props.field}
        components={{
          paragraph: props => (
            <StyledParagraph key={props.key}>{props.children}</StyledParagraph>
          ),
        }}
      />
    </Details>
  </Button>
);

export { CountryLinks };

const Button = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${props => props.theme.margins.base};
  padding: ${props => props.theme.margins.base_x2} 0;
  border-radius: ${props => props.theme.border.radius.base};
  background: ${props => props.theme.colors.white};
  text-align: left;

  ${props => props.theme.breakpoints.tablet} {
    padding: ${props => props.theme.margins.base_x2};
  }
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  color: ${props => props.theme.colors.black700};

  p {
    line-height: ${props => props.theme.fonts.lineHeights.normal};
    margin-bottom: 0;
  }
`;

const StyledParagraph = styled.p`
  margin-bottom: 0;
`;
