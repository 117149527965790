import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import { AVAILABLE_LANGS, SERVICE_TYPE } from "@typings/globals";

import {
  Column,
  Margins,
  Row,
  Container,
  Heading,
  CheckListItem,
} from "@ui/Atoms";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useBrowserRerender } from "@hooks/useBrowserRerender";

import { SubscribeEmail } from "@www/src/components/SubscribeEmail";

import { GatsbyImage } from "../../components/GatsbyImage";
import { StartBookingForm } from "../../components/StartBookingForm";

import { List, StyledSection } from "./shared";

const handleFormSuccess = (path: string): void => {
  window.location.href = path;
};

interface ListItemProp {
  list_item: { text: string };
}

export interface BookCleaningProps {
  ready_to_book_title: { text: string };
  ready_to_book_list: ListItemProp[];
  ready_to_book_list_mobile: ListItemProp[];
  teaser_form_submit?: { text: string };
  teaser_form_placeholder?: { text: string };
  booking_form_submit: { text: string };
  booking_form_placeholder: { text: string };
  ready_to_book_image: {
    gatsbyImageData: IGatsbyImageData;
  };
  is_teaser_version?: boolean;
  email_submit: { text: string };
  email_placeholder: { text: string };
  serviceType?: SERVICE_TYPE;
  language: AVAILABLE_LANGS;
}

const BookCleaningSection = (props: BookCleaningProps): React.ReactElement => {
  const { mobile, tablet } = useBreakpoint();
  const isBrowser = useBrowserRerender();

  const isMobile = mobile || tablet;

  const listToRender = isMobile
    ? props.ready_to_book_list_mobile
    : props.ready_to_book_list;

  return (
    <StyledSection hasOverflow>
      <Container>
        <Row>
          <StyledColumn xs={12} lg={6}>
            <GatsbyImage
              image={props.ready_to_book_image.gatsbyImageData}
              alt="maid"
            />
          </StyledColumn>

          <Column xs={12} lg={6}>
            <Margins xs={[null, "base_x8", null, null]}>
              <StyledHeading
                type="h2"
                styleAs={isBrowser && isMobile ? "h6" : "h4"}
              >
                {props.ready_to_book_title.text}
              </StyledHeading>
            </Margins>
            <BookCleaningSectionImg>
              <GatsbyImage
                image={props.ready_to_book_image.gatsbyImageData}
                alt="maids"
              />
            </BookCleaningSectionImg>
            <ListWrapper>
              <List>
                {listToRender?.map(({ list_item }) => (
                  <CheckListItem key={list_item.text} value={list_item.text} />
                ))}
              </List>
            </ListWrapper>
            <div>
              {props.is_teaser_version ? (
                <SubscribeEmail
                  emailSubmit={
                    props.teaser_form_submit?.text || props.email_submit.text
                  }
                  emailPlaceholder={
                    props.teaser_form_placeholder?.text ||
                    props.email_placeholder.text
                  }
                  buttonVariant="green"
                />
              ) : (
                <StartBookingForm
                  language={props.language}
                  emailSubmit={
                    props.booking_form_submit?.text || props.email_submit.text
                  }
                  emailPlaceholder={
                    props.booking_form_placeholder?.text ||
                    props.email_placeholder.text
                  }
                  buttonVariant="green"
                  onSuccess={handleFormSuccess}
                  serviceType={props.serviceType || SERVICE_TYPE.HOME_CLEANING}
                />
              )}
            </div>
          </Column>
        </Row>
      </Container>
    </StyledSection>
  );
};

export { BookCleaningSection };

const BookCleaningSectionImg = styled.div`
  margin-bottom: ${props => props.theme.margins.base_x3};

  ${props => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  margin-bottom: ${props => props.theme.margins.base_x4};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base_x3};
  text-align: left;

  ${props => props.theme.breakpoints.desktop} {
    margin-bottom: ${props => props.theme.margins.base_x4};
  }
`;

const StyledColumn = styled(Column)`
  display: none;

  ${props => props.theme.breakpoints.desktop} {
    display: block;
  }
`;
