import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";

const getPersonalDetailsFunction = (store: IStore) =>
  store.accountCreation.get("personalDetails");

export const getPersonalDetails = createSelector(
  getPersonalDetailsFunction,
  f => f,
);

const getMainLocationFunction = (store: IStore) =>
  store.accountCreation.get("mainLocation");

export const getMainLocation = createSelector(getMainLocationFunction, f => f);

const getLegalLocationFunction = (store: IStore) =>
  store.accountCreation.get("legalLocation");

export const getLegalLocation = createSelector(
  getLegalLocationFunction,
  f => f,
);

const getIsLegalLocationTheSameFunction = (store: IStore) =>
  store.accountCreation.get("isLegalLocationTheSame");

export const getIsLegalLocationTheSame = createSelector(
  getIsLegalLocationTheSameFunction,
  f => f,
);

const getIsLegalLocationPutFunction = (store: IStore) =>
  store.accountCreation.get("isLegalLocationPut");

export const getIsLegalLocationPut = createSelector(
  getIsLegalLocationPutFunction,
  f => f,
);

const getEmployerAccountFunction = (store: IStore) =>
  store.accountCreation.get("employerAccount");

export const getEmployerAccount = createSelector(
  getEmployerAccountFunction,
  f => f,
);

const getHasEmployerAccountFunction = (store: IStore) =>
  store.accountCreation.get("hasEmployerAccount");

export const getHasEmployerAccount = createSelector(
  getHasEmployerAccountFunction,
  f => f,
);

const getIsEmployerInfoPutFunction = (store: IStore) =>
  store.accountCreation.get("isEmployerInfoPut");

export const getIsEmployerInfoPut = createSelector(
  getIsEmployerInfoPutFunction,
  f => f,
);
