import { SliceZone, SliceZoneLike } from "@prismicio/react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import React from "react";
import { ThemeProvider } from "styled-components";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

import { ErrorFallback } from "@ui/Molecules";
import GlobalStyle from "@ui/style";
import theme from "@ui/themes/default";

import { useTranslate } from "@hooks/useTranslate";

import { GlobalContentPageQueryQuery } from "../../graphql-types";
import FooterStatic from "../components/FooterStatic";
import SEO from "../components/SEO";
import { GlobalContentPageHeader } from "../components/GlobalContentPageHeader";

import { BannerWithCtaSlice } from "./slices/BannerWithCtaSlice";
import { PictureWithBullets } from "./slices/PictureWithBullets";
import { TitleDescriptionSlice } from "./slices/TitleDescriptionSlice";
import { TwoCardsSlice } from "./slices/TwoCardsSlice";
import { ImageWithListedTexts } from "./slices/ImageWithListedTexts";
import { TrustPilotWidgetSlice } from "./slices/TrustPilotWidgetSlice";
import { StepsSlice } from "./slices/StepsSlice";
import { TitleDescriptionImageSlice } from "./slices/TitleDescriptionImageSlice";
import { StepsWithLinesSlice } from "./slices/StepsWithLinesSlice";
import { IconTitleDescriptionBoxesRowSlice } from "./slices/IconTitleDescriptionBoxesRowSlice";
import { TitleWithTitleTextBoxesSlice } from "./slices/TitleWithTitleTextBoxesSlice";
import { CountriesLinksSlice } from "./slices/CountriesLinksSlice";
import { TestimonialsSlice } from "./slices/TestimonialsSlice";

type Props = PageProps<GlobalContentPageQueryQuery>;

const sectionComponents = {
  banner_with_cta: BannerWithCtaSlice,
  picture_with_bullets: PictureWithBullets,
  title_description: TitleDescriptionSlice,
  title_two_cards: TwoCardsSlice,
  imagewithlistedtext: ImageWithListedTexts,
  trustpilot_widget: TrustPilotWidgetSlice,
  steps: StepsSlice,
  title_description_image: TitleDescriptionImageSlice,
  steps_with_lines: StepsWithLinesSlice,
  icon_title_description_boxes_row: IconTitleDescriptionBoxesRowSlice,
  "title_with_title-text_boxes": TitleWithTitleTextBoxesSlice,
  countries_links: CountriesLinksSlice,
  testimonials: TestimonialsSlice,
};

const GlobalContentPage = (props: Props): React.ReactElement => {
  const translate = useTranslate("batmaid_pwa_generic");

  if (!props.data.prismicGlobalContentPage?.data?.body) {
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <ErrorFallback
          somethingWentWrongText={translate("something_went_wrong")}
          appCrashExplanationText={translate("app_crash_explanation")}
        />
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <SEO
        title={props.data.prismicGlobalContentPage?.data?.seo_title?.text || ""}
        description={
          props.data.prismicGlobalContentPage?.data?.seo_description?.text || ""
        }
      />
      <GlobalContentPageHeader />
      <SliceZone
        slices={props.data.prismicGlobalContentPage.data.body as SliceZoneLike}
        components={sectionComponents}
      />
      <FooterStatic
        currentLanguage={AVAILABLE_LANGS.EN}
        currentFlag={AVAILABLE_COUNTRIES.CH}
        isTeaserVersion
      />
    </ThemeProvider>
  );
};

export default withPrismicPreview(GlobalContentPage);

export const pageQuery = graphql`
  query GlobalContentPageQuery($id: String) {
    prismicGlobalContentPage(id: { eq: $id }) {
      _previewable
      lang
      data {
        slug {
          text
        }
        title {
          text
        }
        seo_title {
          text
        }
        seo_description {
          text
        }
        body {
          ... on PrismicGlobalContentPageDataBodyBannerWithCta {
            slice_type
            slice_label
            primary {
              description {
                text
                html
                raw
              }
              target_url {
                target
                url
              }
              title1 {
                text
                raw
              }
              background_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH)
                thumbnails {
                  mobile {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              cta_text
            }
          }
          ... on PrismicGlobalContentPageDataBodyPictureWithBullets {
            id
            slice_label
            slice_type
            primary {
              title1 {
                text
              }
              image {
                alt
                gatsbyImageData(layout: CONSTRAINED)
              }
              image_mobile {
                alt
                gatsbyImageData(layout: CONSTRAINED)
              }
              image_tablet {
                alt
                gatsbyImageData(layout: CONSTRAINED)
              }
              image_alignment_dry
              image_mobile_full_width
            }
            items {
              marker
              header
              description {
                raw
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyTitleDescription {
            slice_label
            slice_type
            primary {
              section_title {
                text
              }
              section_description {
                text
              }
              button_text {
                text
              }
              button_url {
                target
                url
              }
              second_button_text {
                text
              }
              second_button_type
              is_second_button_mobile_only
            }
          }

          ... on PrismicGlobalContentPageDataBodyTitleTwoCards {
            slice_label
            slice_type
            primary {
              two_cards_title {
                text
              }
              two_cards_description {
                text
              }
              two_cards_cto_text {
                text
              }
              two_cards_target_url {
                url
              }
            }
            items {
              background_image {
                alt
                gatsbyImageData(layout: CONSTRAINED)
              }
              background_image_mobile {
                alt
                gatsbyImageData(layout: CONSTRAINED)
              }
              card_title {
                text
              }
              card_description {
                text
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyImagewithlistedtext {
            slice_label
            slice_type
            primary {
              section_title {
                text
              }
              section_subtitle {
                text
                richText
              }
              image {
                alt
                gatsbyImageData(layout: CONSTRAINED)

                thumbnails {
                  mobile {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
            items {
              text_title {
                text
              }
              text {
                text
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyTrustpilotWidget {
            slice_label
            slice_type
            primary {
              business_unit_id {
                text
              }
              template_id {
                text
              }
              stars {
                text
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodySteps {
            slice_label
            slice_type
            primary {
              title1 {
                text
              }
              description {
                text
              }
            }
            items {
              icon
              step_title {
                text
              }
              step_description {
                text
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyTitleDescriptionImage {
            slice_type
            slice_label
            primary {
              title1 {
                text
              }
              description {
                text
                raw
              }
              image {
                gatsbyImageData

                thumbnails {
                  mobile {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyStepsWithLines {
            slice_type
            slice_label
            primary {
              title1 {
                text
              }
            }
            items {
              title1 {
                text
              }
              subtitle {
                text
              }
              description {
                text
                raw
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyIconTitleDescriptionBoxesRow {
            slice_type
            slice_label
            primary {
              title1 {
                text
              }
            }
            items {
              icon
              title1 {
                text
              }
              description {
                text
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyTitleWithTitleTextBoxes {
            slice_type
            slice_label
            primary {
              title1 {
                text
              }
            }
            items {
              title1 {
                text
              }
              description {
                text
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyCountriesLinks {
            slice_type
            slice_label
            primary {
              title1 {
                text
              }
              description {
                richText
              }
              links {
                document {
                  ... on PrismicCountryLinks {
                    data {
                      countries_links {
                        country_code
                        country {
                          richText
                        }
                        links {
                          richText
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          ... on PrismicGlobalContentPageDataBodyTestimonials {
            slice_type
            slice_label
            primary {
              title1 {
                text
              }
              read_more {
                richText
              }
            }
            items {
              stars
              description {
                richText
              }
              author {
                richText
              }
            }
          }
        }
      }
    }
  }
`;
