import * as React from "react";
import styled from "styled-components";

import { rgba } from "@ui/helpers";

interface Props {
  type: "horizontal" | "vertical";
}

const Shadow = (props: Props): React.ReactElement | null => {
  if (props.type === "horizontal") {
    return <HorizontalShadow />;
  }

  if (props.type === "vertical") {
    return <VerticalShadow />;
  }

  return null;
};

export { Shadow };

const VerticalShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:before {
    content: "";
    position: absolute;
    top: -${props => props.theme.margins.base_x2};
    left: 0;
    right: 0;
    height: ${props => props.theme.margins.base_x2};
    background-image: radial-gradient(
      ellipse at bottom,
      ${props => rgba(props.theme.colors.black, 0.2)},
      transparent
    );
    filter: blur(${props => props.theme.margins.base});
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -${props => props.theme.margins.base_x2};
    height: ${props => props.theme.margins.base_x2};
    background-image: radial-gradient(
      ellipse at top,
      ${props => rgba(props.theme.colors.black, 0.2)},
      transparent
    );
    filter: blur(${props => props.theme.margins.base});
  }
`;

const HorizontalShadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &:before {
    content: "";
    position: absolute;
    right: -${props => props.theme.margins.base_x2};
    top: 0;
    bottom: 0;
    width: ${props => props.theme.margins.base_x2};
    background-image: radial-gradient(
      ellipse at left,
      ${props => rgba(props.theme.colors.black, 0.2)},
      transparent
    );
    filter: blur(${props => props.theme.margins.base});
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -${props => props.theme.margins.base_x2};
    width: ${props => props.theme.margins.base_x2};
    background-image: radial-gradient(
      ellipse at right,
      ${props => rgba(props.theme.colors.black, 0.2)},
      transparent
    );
    filter: blur(${props => props.theme.margins.base});
  }
`;
