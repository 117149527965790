import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const CheckMark = (props: Icon): React.ReactElement => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style(props.size, props.height), ...props.style }}
    className={props.className}
  >
    <g
      transform="translate(-348.000000, -739.000000)"
      fill={props.color || theme.colors.grey700}
      fillRule="nonzero"
    >
      <g transform="translate(150.000000, 625.000000)">
        <g transform="translate(30.000000, 56.000000)">
          <path d="M188.48486,61.5145 C183.799006,56.8285 176.201244,56.8285 171.51439,61.5145 C166.828537,66.2015 166.828537,73.7985 171.51439,78.4855 C176.200244,83.1715 183.798006,83.1715 188.48486,78.4855 C193.171713,73.7995 193.171713,66.2005 188.48486,61.5145 Z M185.758945,66.6505 L179.149152,74.3615 L174.3993,70.7995 C173.957314,70.4685 173.868317,69.8415 174.199306,69.3995 C174.530296,68.9585 175.156276,68.8675 175.599263,69.1995 L178.849161,71.6375 L184.238993,65.3485 C184.598981,64.9305 185.230962,64.8815 185.648948,65.2405 C186.069935,65.5995 186.118934,66.2315 185.758945,66.6505 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);
