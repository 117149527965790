import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const CalendarTime = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M18 5.333a2 2 0 011.997 1.883l.003.117v1.349a.667.667 0 01-1.329.078l-.004-.078V7.333a.667.667 0 00-.59-.662L18 6.667H6a.667.667 0 00-.662.589l-.005.077V18c0 .342.258.624.59.662l.077.005h2.681a.667.667 0 01.078 1.329L8.681 20H6a2 2 0 01-1.997-1.882L4 18V7.333a2 2 0 011.883-1.996L6 5.333h12z"
          fill={props.color || theme.colors.grey700}
        />
        <path
          d="M8 4c.342 0 .624.257.662.589l.005.078V8a.667.667 0 01-1.33.078L7.334 8V4.667C7.333 4.298 7.632 4 8 4zM16 4c.342 0 .624.257.662.589l.005.078V8a.667.667 0 01-1.33.078L15.334 8V4.667c0-.369.299-.667.667-.667z"
          fill={props.color || theme.colors.grey700}
        />
        <path
          d="M15 10a5 5 0 100 10 5 5 0 000-10zm0 1.333a3.667 3.667 0 110 7.334 3.667 3.667 0 010-7.334z"
          fill={props.secondaryColor || props.color || theme.colors.grey700}
        />
        <path
          d="M15.333 12.667c.342 0 .624.257.663.589l.004.077v2a.667.667 0 01-.589.663l-.078.004h-2a.667.667 0 01-.077-1.329l.077-.004h1.334v-1.334c0-.342.257-.623.589-.662l.077-.004z"
          fill={props.color || theme.colors.grey700}
        />
      </g>
    </svg>
  ) : (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M18.667 5.333h-2v-.666a.667.667 0 00-1.334 0v.666H8.667v-.666a.667.667 0 00-1.334 0v.666h-2C4.597 5.333 4 5.931 4 6.667v12C4 19.403 4.597 20 5.333 20h6.233A6 6 0 1120 11.566v-4.9c0-.735-.597-1.333-1.333-1.333zM8 8.667a.667.667 0 110-1.334.667.667 0 010 1.334zm8 0a.667.667 0 110-1.334.667.667 0 010 1.334zm-.667 2a4.667 4.667 0 100 9.333 4.667 4.667 0 000-9.333zM16 16h-2.667a.669.669 0 01-.666-.667c0-.366.3-.666.666-.666h1.334v-1.334c0-.366.3-.666.666-.666.367 0 .667.3.667.666V16z"
          id="calendartime-solid-a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="calendartime-solid-b" fill="#fff">
          <use xlinkHref="#calendartime-solid-a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#calendartime-solid-a" />
        <g
          mask="url(#calendartime-solid-b)"
          fill={props.color || theme.colors.grey700}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
