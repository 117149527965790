import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const UserProfileSquare = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M13.592 7.658a2.25 2.25 0 1 1-3.182 3.183 2.25 2.25 0 0 1 3.182-3.183M16.323 16.543a2.906 2.906 0 0 0-2.866-2.454h-2.914a2.905 2.905 0 0 0-2.866 2.454" />
      <path
        clipRule="evenodd"
        d="M17.002 21.004H6.998a4.002 4.002 0 0 1-4.002-4.002V6.998a4.002 4.002 0 0 1 4.002-4.001h10.004a4.002 4.002 0 0 1 4.002 4.001v10.004a4.002 4.002 0 0 1-4.002 4.002Z"
      />
    </svg>
  );
};
