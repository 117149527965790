import { Record, List, Map } from "immutable";

import {
  BookingTasks,
  KEY_PICK,
  CLEANING_FREQUENCY,
  BookingPricing,
  PART_OF_DAY,
  ChosenAgentWSortOrder,
  IAgent,
  AGENT_PREFERENCE,
  ONCE_BOOKING_TYPE,
} from "@typings/booking";
import { SECOND_PILLAR_RETIREMENT, SICKNESS_INSURANCE } from "@typings/salary";
import { EmployeeDetails } from "@typings/contracts";

export const LOCAL_STORAGE_KEY = "store:soft:booking";

export interface ReservationData {
  clientLocationId?: string;
  superGrossSalary?: 0;
  insurancePolicy?: boolean;
  insurancePolicyNumber?: string;
  insurancePolicyWillBeProvidedLater?: boolean;
  secondPillarRetirement?: SECOND_PILLAR_RETIREMENT;
  sicknessInsurance?: SICKNESS_INSURANCE;
  agreeToProfessionalAccidentInsurance?: boolean;
  agentUuid?: string | null;
  executionDateTime?: string | null;
}

export interface HourItem {
  duration: string;
  availableAgents: List<string>;
  partOfDay?: PART_OF_DAY;
}

export interface DayItemRaw {
  day: string;
  hours: HourItemRaw[];
}

export interface HourItemRaw {
  time: string;
  availableAgents: string[];
}

export interface BookingAvailabilityRaw {
  defaultSelection: string;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  days: DayItemRaw[];
}

export interface BookingAvailability {
  defaultSelection: string;
  canSelectPreviousWeek: boolean;
  canSelectNextWeek: boolean;
  weekStartDate: string;
  days: List<string>;
  hours: Map<string, List<HourItem>>;
  hasNoAvailableDates: boolean;
}

export interface BookingAddressForm {
  serviceLocationId: number;
  address: string;
  floorAndDoor: string;
  entryCode: string;
}

export interface BookingReducerState {
  serviceLocationId: number;
  onceBookingType: ONCE_BOOKING_TYPE;
  contractUuid: string;
  keyPick: KEY_PICK;
  locationComment: string | null;
  hasPets: boolean;
  specialInstructions: string | null;
  tasks: Record<BookingTasks>;
  frequency: CLEANING_FREQUENCY;
  pricing: Record<BookingPricing>;
  calendar: Record<BookingAvailability>;
  executionDateTime: string | null;
  queryParams: string;
  locationUuid: string;
  mainLocationUuid: string;
  hasSomePaymentAliases: boolean;
  previousEmployee: Record<EmployeeDetails> | null;
  hasPreviousActiveContract: boolean;
  preselectedAgentUuid: string | null;
  agentPreference: AGENT_PREFERENCE;
  previousAgents: List<Record<IAgent>>;
  form: Record<BookingAddressForm>;
  isChoosingAnyEmployee: boolean;
}

export type MapOfChosenAgents = Map<string, Record<ChosenAgentWSortOrder>>;

export type BookingReducer = Record<BookingReducerState>;
