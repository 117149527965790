import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const TrashDeleteBin2Circle = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 25 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        id="Path"
        d="M14.6884 10.499V8.62319C14.6884 8.27786 14.4085 7.99792 14.0631 7.99792H10.9368C10.5915 7.99792 10.3116 8.27786 10.3116 8.62319V10.499"
      />
      <path id="Path_2" d="M16.9463 10.4989H8.05371" />
      <circle id="Oval" cx="12.5" cy="13" r="9.00375" />
      <path
        id="Path_3"
        d="M16.2034 10.499L15.7151 16.8474C15.665 17.499 15.1217 18.0021 14.4683 18.0021H10.5317C9.87823 18.0021 9.33493 17.499 9.28483 16.8474L8.79654 10.499"
      />
    </svg>
  );
};
