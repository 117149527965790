import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const DocumentAddPlus = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M4 9V5C4 3.89543 4.89543 3 6 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H14" />
      <path d="M8 16.5H5" />
      <path d="M6.5 18V15" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 16.5V16.5C11 18.9853 8.98528 21 6.5 21V21C4.01472 21 2 18.9853 2 16.5V16.5C2 14.0147 4.01472 12 6.5 12V12C8.98528 12 11 14.0147 11 16.5V16.5Z"
      />
      <path d="M17 8H8" />
      <path d="M17 12H14" />
      <path d="M17 16H15" />
    </svg>
  );
};
