import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CalendarScheduleLines = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <rect x="2.99622" y="2.99634" width="18.0075" height="18.0075" rx="3" />
      <path d="M21.0037 7.9985H2.99622" />
      <path d="M17.3022 11.5C17.3022 11.5276 17.2798 11.55 17.2522 11.55C17.2245 11.55 17.2021 11.5276 17.2021 11.5C17.2021 11.4723 17.2245 11.4499 17.2522 11.4499" />
      <path d="M17.2522 11.4498C17.2798 11.4498 17.3022 11.4722 17.3022 11.4998" />
      <path d="M13.7997 11.5C13.7997 11.5276 13.7773 11.55 13.7497 11.55C13.7221 11.55 13.6997 11.5276 13.6997 11.5C13.6997 11.4723 13.7221 11.4499 13.7497 11.4499" />
      <path d="M13.7497 11.4498C13.7774 11.4498 13.7998 11.4722 13.7998 11.4998" />
      <path d="M10.2983 11.5C10.2983 11.5276 10.2759 11.55 10.2483 11.55C10.2206 11.55 10.1982 11.5276 10.1982 11.5C10.1982 11.4723 10.2206 11.4499 10.2483 11.4499" />
      <path d="M10.2483 11.4498C10.2759 11.4498 10.2983 11.4722 10.2983 11.4998" />
      <path d="M6.79681 14.5012C6.79681 14.5288 6.77442 14.5512 6.74679 14.5512C6.71916 14.5512 6.69677 14.5288 6.69677 14.5012C6.69677 14.4736 6.71916 14.4512 6.74679 14.4512" />
      <path d="M6.7468 14.451C6.77443 14.451 6.79683 14.4734 6.79683 14.501" />
      <path d="M10.2983 14.5012C10.2983 14.5288 10.2759 14.5512 10.2483 14.5512C10.2206 14.5512 10.1982 14.5288 10.1982 14.5012C10.1982 14.4736 10.2206 14.4512 10.2483 14.4512" />
      <path d="M10.2483 14.451C10.2759 14.451 10.2983 14.4734 10.2983 14.501" />
      <path d="M13.7997 14.5012C13.7997 14.5288 13.7773 14.5512 13.7497 14.5512C13.7221 14.5512 13.6997 14.5288 13.6997 14.5012C13.6997 14.4736 13.7221 14.4512 13.7497 14.4512" />
      <path d="M13.7497 14.451C13.7774 14.451 13.7998 14.4734 13.7998 14.501" />
      <path d="M17.3022 14.5012C17.3022 14.5288 17.2798 14.5512 17.2522 14.5512C17.2245 14.5512 17.2021 14.5288 17.2021 14.5012C17.2021 14.4736 17.2245 14.4512 17.2522 14.4512" />
      <path d="M17.2522 14.451C17.2798 14.451 17.3022 14.4734 17.3022 14.501" />
      <path d="M17.3022 17.5024C17.3022 17.53 17.2798 17.5524 17.2522 17.5524C17.2245 17.5524 17.2021 17.53 17.2021 17.5024C17.2021 17.4748 17.2245 17.4524 17.2522 17.4524" />
      <path d="M17.2522 17.4525C17.2798 17.4525 17.3022 17.4749 17.3022 17.5025" />
      <path d="M13.7997 17.5024C13.7997 17.53 13.7773 17.5524 13.7497 17.5524C13.7221 17.5524 13.6997 17.53 13.6997 17.5024C13.6997 17.4748 13.7221 17.4524 13.7497 17.4524" />
      <path d="M13.7497 17.4525C13.7774 17.4525 13.7998 17.4749 13.7998 17.5025" />
      <path d="M10.2983 17.5024C10.2983 17.53 10.2759 17.5524 10.2483 17.5524C10.2206 17.5524 10.1982 17.53 10.1982 17.5024C10.1982 17.4748 10.2206 17.4524 10.2483 17.4524" />
      <path d="M10.2483 17.4525C10.2759 17.4525 10.2983 17.4749 10.2983 17.5025" />
      <path d="M6.79681 17.5024C6.79681 17.53 6.77442 17.5524 6.74679 17.5524C6.71916 17.5524 6.69677 17.53 6.69677 17.5024C6.69677 17.4748 6.71916 17.4524 6.74679 17.4524" />
      <path d="M6.7468 17.4525C6.77443 17.4525 6.79683 17.4749 6.79683 17.5025" />
    </svg>
  );
};
