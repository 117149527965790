import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CalendarScheduleCheckmark = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M21.45 9.428h1.238c1.004 0 1.816.813 1.816 1.816v11.542a1.815 1.815 0 0 1-1.816 1.815H9.98a1.815 1.815 0 0 1-1.816-1.816V21.1" />
      <path
        clipRule="evenodd"
        d="M4.664 21.1h13.203c.78 0 1.509-.39 1.942-1.04l.857-1.286a4.669 4.669 0 0 0 .784-2.59v-9.09a2.334 2.334 0 0 0-2.334-2.334H7.444A2.334 2.334 0 0 0 5.11 7.094v8.235c0 .725-.17 1.44-.493 2.088l-.997 1.994A1.167 1.167 0 0 0 4.664 21.1Z"
      />
      <path d="m15.927 11.288-3.283 3.283-1.97-1.97M9.775 3.593v2.334M16.778 3.593v2.334" />
    </svg>
  );
};
