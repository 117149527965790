import { IStore } from "@soft/redux/reducers";

import {
  getServiceLocationId,
  getSicknessInsurance,
  getChosenAgentId,
  getAgreeToProfessionalAccidentInsurance,
  getSecondPillarRetirement,
  getSliderSupergrossSalary,
  getSalary,
  getPrevSecondPillarRetirement,
  getPrevSicknessInsurance,
} from "./salary.selectors";

export const getSicknessInsuranceEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  superGrossSalary: getSliderSupergrossSalary(store),
  secondPillarRetirement: getSecondPillarRetirement(store),
  sicknessInsurance: getSicknessInsurance(store),
});

export const getSecondPillarRetirementEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  superGrossSalary: getSliderSupergrossSalary(store),
  secondPillarRetirement: getSecondPillarRetirement(store),
  sicknessInsurance: getSicknessInsurance(store),
});

export const getSalaryDefaultsEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  chosenAgentId: getChosenAgentId(store),
  secondPillarRetirement: getSecondPillarRetirement(store),
});

export const getSalaryEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  superGrossSalary: getSliderSupergrossSalary(store),
  secondPillarRetirement: getSecondPillarRetirement(store),
  sicknessInsurance: getSicknessInsurance(store),
  agreeToProfessionalAccidentInsurance: getAgreeToProfessionalAccidentInsurance(
    store,
  ),
});

export const getSupergrossSalaryEndpointRequest = (store: IStore) => ({
  serviceLocationId: getServiceLocationId(store),
  grossSalaryIncludingVacation: getSalary(store).get(
    "grossSalaryIncludingVacation",
  ),
  currentSecondPillarRetirement: getPrevSecondPillarRetirement(store),
  newSecondPillarRetirement: getSecondPillarRetirement(store),
  currentSicknessInsurance: getPrevSicknessInsurance(store),
  newSicknessInsurance: getSicknessInsurance(store),
});
