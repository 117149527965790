import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Shampoo = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      viewBox="0 0 12 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <defs>
        <path
          d="M13.333 4c.342 0 .624.257.663.589l.004.078v2.86A4.668 4.668 0 0117.334 12v6a2 2 0 01-2 2H8.667a2 2 0 01-2-2v-6c0-2.114 1.405-3.9 3.332-4.473L10 4.667c0-.342.257-.624.589-.663L10.667 4h2.666zm-.666 4.667h-1.334A3.333 3.333 0 008 12v6c0 .368.298.667.667.667h6.666A.666.666 0 0016 18v-6a3.333 3.333 0 00-3.333-3.333zM12 12a2.667 2.667 0 110 5.333A2.667 2.667 0 0112 12zm0 1.333A1.333 1.333 0 1012 16a1.333 1.333 0 000-2.667zm-2-2.673c.342 0 .624.257.662.589l.005.084a.667.667 0 01-1.33.078l-.004-.084c0-.369.299-.667.667-.667zm2.667-5.327h-1.334v2h1.335l-.001-2z"
          id="shampoo_line_a"
        />
      </defs>
      <g transform="translate(-6 -4)" fill="none" fillRule="evenodd">
        <mask id="shampoo_line_b" fill="#fff">
          <use xlinkHref="#shampoo_line_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#shampoo_line_a" />
        <g
          mask="url(#shampoo_line_b)"
          fill={props.color || theme.colors.black500}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      viewBox="0 0 10 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <defs>
        <path
          d="M14.41 8H9.587c-1.263.827-2.141 2.289-2.254 4v6.667c0 .736.597 1.333 1.334 1.333h6.666c.736 0 1.334-.597 1.334-1.333V12c-.11-1.687-.978-3.164-2.257-4zm-5.077 3.333a.667.667 0 111.334 0 .667.667 0 01-1.334 0zm2.667 6a2 2 0 110-4 2 2 0 010 4zM14 4h-4v2.667h4V4z"
          id="shampoo_solid_a"
        />
      </defs>
      <g transform="translate(-7 -4)" fill="none" fillRule="evenodd">
        <mask id="shampoo_solid_b" fill="#fff">
          <use xlinkHref="#shampoo_solid_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#shampoo_solid_a" />
        <g
          mask="url(#shampoo_solid_b)"
          fill={props.color || theme.colors.black500}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
