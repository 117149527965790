import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const ModernBuilding4 = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M21.0038 21.0037V4.28378C21.0023 3.8627 20.7949 3.46895 20.4485 3.2296C20.102 2.99026 19.6604 2.93561 19.266 3.08328L12.8344 5.49129C12.3316 5.67974 11.9989 6.16084 12 6.69779V21.0037" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.37266 5.24718H8.6236C9.24518 5.24718 9.74907 5.75107 9.74907 6.37265V8.62359H5.24719V6.37265C5.24719 5.75107 5.75108 5.24718 6.37266 5.24718V5.24718Z"
      />
      <path d="M7.49813 2.99625V5.24719" />
      <path d="M2.99625 21.0037V9.74906C2.99625 9.12748 3.50014 8.62359 4.12172 8.62359H10.8745C11.4961 8.62359 12 9.12748 12 9.74906V21.0037" />
      <path d="M5.81042 15.3764H9.18683" />
      <path d="M5.81042 15.3764H9.18683" />
      <path d="M5.81042 12H9.18683" />
      <path d="M7.49813 19.316V21.0037" />
      <path d="M22.0042 21.0037H1.99582" />
      <path d="M15.0012 9.05577V17.0021" />
      <path d="M18.0025 9.05577V17.0021" />
    </svg>
  );
};
