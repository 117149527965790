import styled, { css } from "styled-components";

import { TabProps } from "./Tab";

export const Tabs = styled.nav<{ variant: TabProps["variant"] }>`
  display: flex;

  ${props =>
    props.variant === "light" &&
    css`
      width: 100%;
      background: ${props.theme.colors.blue20};
      gap: ${props.theme.margins.half};

      ${props.theme.breakpoints.tablet} {
        width: fit-content;
      }
    `}
`;
