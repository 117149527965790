import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const PlusWithBorder = (props: Icon): React.ReactElement => (
  <svg
    style={{ ...style(props.size, props.height), ...props.style }}
    viewBox="0 0 37 37"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(-508.000000, -889.000000)"
        stroke={props.color || theme.colors.grey700}
        strokeWidth="2"
      >
        <g transform="translate(509.000000, 890.000000)">
          <path
            d="M29.8750455,29.8745682 C23.0405,36.7075227 11.9598182,36.7075227 5.12527273,29.8745682 C-1.70927273,23.0400227 -1.70927273,11.9593409 5.12527273,5.12479545 C11.9598182,-1.70815909 23.0405,-1.70815909 29.8750455,5.12479545 C36.7095909,11.9593409 36.7095909,23.0400227 29.8750455,29.8745682 Z"
            id="Stroke"
            fill="#FFFFFF"
          ></path>
          <line
            x1="17.5004773"
            y1="11.1363636"
            x2="17.5004773"
            y2="23.8636364"
            id="Stroke"
          ></line>
          <line
            x1="11.1368409"
            y1="17.5"
            x2="23.8641136"
            y2="17.5"
            id="Stroke"
          ></line>
        </g>
      </g>
    </g>
  </svg>
);
