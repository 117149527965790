import { hydrateImmutable, dehydrateImmutable } from "immutable-stringify";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { isLocalStorageAvailable } from "@services/IsLocalStorageAvailable";

import { IStoreValues } from "./reducers";

export const setLocalStorageHelper = (
  dispatch: ThunkActionDispatch,
  type: string,
  storeObject: IStoreValues,
  key: string,
) => {
  if (isLocalStorageAvailable()) {
    const dehydratedData = dehydrateImmutable(storeObject);
    localStorage.setItem(key, dehydratedData);
  }

  dispatch({
    type,
  });
};

export const setFromLocalStorageHelper = (
  dispatch: ThunkActionDispatch,
  type: string,
  key: string,
) => {
  const localStorageData = isLocalStorageAvailable()
    ? localStorage.getItem(key)
    : undefined;
  const payload = localStorageData
    ? hydrateImmutable(localStorageData)
    : undefined;

  dispatch({
    type,
    payload,
  });
};

export const deleteLocalStorageHelper = (
  dispatch: ThunkActionDispatch,
  type: string,
  key: string,
) => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key);
  }

  dispatch({
    type,
  });
};
