import { useDispatch, useSelector } from "react-redux";

import { SimpleObject } from "@typings/globals";
import { ModalCaller, ModalOptions } from "@typings/modals";

import { getModalOptions } from "@work/redux/modals/modals.selectors";
import { IStore } from "@work/redux/reducers";
import { hideModal, showModal } from "@work/redux/modals/modals.actions";

interface UseModalStoreFunctions {
  hideModal: (id: string) => void;
  options: (id: string) => ModalOptions;
  showModal: (id: string | ModalCaller, options?: SimpleObject) => void;
}

export const useModalStore = (): UseModalStoreFunctions => {
  const dispatch = useDispatch();

  const options = (id: string) =>
    useSelector((store: IStore) => getModalOptions(store, id));

  const showModalFunction = (
    id: string | ModalCaller,
    options?: SimpleObject,
  ) => dispatch(showModal(id, options));

  const hideModalFunction = (id: string) => dispatch(hideModal(id));

  return {
    options,
    showModal: showModalFunction,
    hideModal: hideModalFunction,
  };
};
