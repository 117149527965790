import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const BaggageBags = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M8 11V9C7.99985 8.46952 8.21052 7.96073 8.58563 7.58563C8.96073 7.21052 9.46952 6.99985 10 7H19C19.5305 6.99985 20.0393 7.21052 20.4144 7.58563C20.7895 7.96073 21.0001 8.46952 21 9V18C21.0001 18.5305 20.7895 19.0393 20.4144 19.4144C20.0393 19.7895 19.5305 20.0001 19 20H14" />
      <path d="M18 21V20" />
      <path d="M18 7V4C18.0001 3.46952 17.7895 2.96073 17.4144 2.58563C17.0393 2.21052 16.5305 1.99985 16 2H13C12.4695 1.99985 11.9607 2.21052 11.5856 2.58563C11.2105 2.96073 10.9999 3.46952 11 4V7" />
      <path d="M6.02499 14.3V12.1C6.02499 11.4925 6.51748 11 7.12499 11H9.87499C10.4825 11 10.975 11.4925 10.975 12.1V14.3" />
      <rect x="3" y="14.3" width="11" height="7.7" rx="1.65" />
    </svg>
  );
};
