/**
 * @author jakubmaslon & jakubbujakowski
 * @since 2020-04-27
 */
import * as React from "react";
import { Helmet } from "react-helmet";
import styled, { css } from "styled-components";

import { Heading } from "@ui/Atoms/Headings";
import {
  Container,
  Section,
  Paragraph,
  Button,
  Margins,
  Row,
  Column,
} from "@ui/Atoms";
import { Ghost } from "@ui/Assets/Symbolicons/Ghost";
import { SpiderWeb } from "@ui/Assets/Symbolicons/SpiderWeb";
import { Bat } from "@ui/Assets/Symbolicons/Bat";
import theme from "@ui/themes/default";
import { rem } from "@ui/helpers";
import { bounce } from "@ui/animations";

interface ErrorIconProps {
  statusCode?: number;
  elementRef?: React.MutableRefObject<any>;
}

const iconConfig = {
  size: 84,
  color: theme.colors.grey500,
};

const ErrorIcon = (props: ErrorIconProps) => {
  if (!props.statusCode) {
    return <Bat {...iconConfig} elementRef={props.elementRef} />;
  }

  if (props.statusCode >= 300 && props.statusCode <= 399) {
    return <SpiderWeb {...iconConfig} />;
  }

  if (props.statusCode >= 400 && props.statusCode <= 499) {
    return <Bat {...iconConfig} elementRef={props.elementRef} />;
  }

  if (props.statusCode >= 500 && props.statusCode < 599) {
    return <Ghost {...iconConfig} elementRef={props.elementRef} />;
  }

  // default
  return <Bat {...iconConfig} elementRef={props.elementRef} />;
};

interface Props {
  htmlMetaTitleText?: string;
  titleText: string;
  bodyText: string | React.ReactElement;
  btnText: string;
  btnHref?: string;
  statusCode?: number;
}

export const SystemError = (props: Props): React.ReactElement => {
  const iconRef = React.useRef<any>(null);

  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);

  const keydownHandler = (e: KeyboardEvent) => {
    if (!iconRef.current) return;

    iconRef.current.style.transition = "all 0.2s";

    const currentPos = iconRef.current.style.transform.match(
      /-?[1-9]\d{0,2}(\.\d*)?/,
    );
    const offset = currentPos?.length ? Number(currentPos[0]) : 0;

    if (e.key === "ArrowLeft" && offset > -120) {
      iconRef.current.style.transform = `translateX(${offset - 5}px)`;
      return;
    }

    if (e.key === "ArrowRight" && offset < 120) {
      iconRef.current.style.transform = `translateX(${offset + 5}px)`;
      return;
    }
  };

  /**
   * Batmaid game v 0.1
   */
  const play = () => {
    setIsPlaying(!isPlaying);
  };

  React.useEffect(() => {
    if (isPlaying) {
      window.addEventListener("keydown", keydownHandler);
    } else {
      window.removeEventListener("keydown", keydownHandler);
    }

    return () => window.removeEventListener("keydown", keydownHandler);
  }, [isPlaying]);

  return (
    <React.Fragment>
      {props.htmlMetaTitleText && (
        <Helmet>
          <title>{props.htmlMetaTitleText}</title>
        </Helmet>
      )}

      <Container>
        <Section>
          <Row>
            <Column md={6} pushMd={3}>
              <SystemErrorContainer>
                {props.statusCode && (
                  <Heading type="h4">{props.statusCode}</Heading>
                )}
                <Margins xs={[null, "base_x4", null, null]}>
                  <Heading
                    type="h5"
                    textAlignXs="center"
                    textAlignMd="center"
                    textAlignLg="center"
                  >
                    {props.titleText}
                  </Heading>
                </Margins>
                <Margins xs={[null, "base_x4", null, null]}>
                  <ErrorIconWrapper onClick={play}>
                    <AnimationWrapper isPlaying={isPlaying}>
                      <ErrorIcon
                        statusCode={props.statusCode}
                        elementRef={iconRef}
                      />
                    </AnimationWrapper>
                  </ErrorIconWrapper>
                </Margins>
                <Margins xs={[null, "base_x2", null, null]}>
                  <div>
                    <Paragraph>{props.bodyText}</Paragraph>
                  </div>
                </Margins>
                <a href={props.btnHref || "/"}>
                  <Button>{props.btnText}</Button>
                </a>
              </SystemErrorContainer>
            </Column>
          </Row>
        </Section>
      </Container>
    </React.Fragment>
  );
};

SystemError.displayName = "SystemError";

const SystemErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
`;

const ErrorIconWrapper = styled.div`
  position: relative;
  padding-bottom: ${props => props.theme.margins.base_x2};

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10%;
    height: ${rem(2)};
    width: 80%;
    background-color: ${props => props.theme.colors.grey500};
  }
`;

const AnimationWrapper = styled.div<{ isPlaying: boolean }>`
  ${props =>
    props.isPlaying &&
    css`
      animation: 1.5s ${bounce} infinite ease;
    `}
`;
