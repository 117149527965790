/**
 * @author michalstolarski
 * @since 2023-9-7
 */

import * as React from "react";

import { AVAILABLE_CURRENCIES } from "@typings/globals";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

import { intlNumberFormatCurrency } from "@services/IntlNumberFormatCurrency";

interface Props {
  testId?: string;
  className?: string;
  amount: number;
  currency: AVAILABLE_CURRENCIES;
}

const PriceLabel = React.memo((props: Props): React.ReactElement => {
  const label = intlNumberFormatCurrency(props.amount, props.currency);

  return (
    <TestWrapper testId={props.testId}>
      <div className={props.className}>{label}</div>
    </TestWrapper>
  );
});

PriceLabel.displayName = "PriceLabel";

export { PriceLabel };
