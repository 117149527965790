import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CalendarSchedule = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M7.49813 2.99609V5.99734" />
      <path d="M16.5019 2.99609V5.99734" />
      <rect x="2.99625" y="4.49707" width="18.0075" height="16.5069" rx="3" />
      <path d="M17.0021 17.0022H6.99792" />
      <path d="M13.5006 10.499L10.4994 13.5003" />
      <path d="M10.4994 10.499L13.5006 13.5003" />
    </svg>
  );
};
