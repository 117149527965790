import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Bell = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <path
      fill={props.color || theme.colors.grey700}
      fillRule="evenodd"
      d="M12.004 4c4.415.003 8.003 3.601 7.996 8.017-.007 4.402-3.601 7.985-8.008 7.983-4.418-.002-7.994-3.589-7.992-8.018C4.002 7.578 7.593 3.997 12.004 4zm.496 12.5h-1c.063.21.22.307.448.327.274.024.448-.081.552-.327zM12.005 6c-.424 0-.621.253-.576.73.082.852.17 1.702.238 2.555.033.416.084.84-.158 1.228-.178.286-.164.338.116.579-.058.018-.093.032-.13.04-.84.21-1.228.697-1.252 1.545a5.41 5.41 0 01-.206 1.421c-.17.562-.328 1.132-.957 1.402-.118.05-.103.153.028.213.197.09.406.134.618.16 1.43.17 2.86.156 4.292.036.273-.023.548-.063.807-.163.074-.029.178-.038.175-.147-.002-.089-.083-.114-.156-.142a.83.83 0 01-.283-.188c-.244-.24-.38-.54-.491-.853-.218-.616-.278-1.255-.306-1.901-.026-.602-.34-1.03-.902-1.274a1.665 1.665 0 00-.149-.055l-.307-.093c.261-.272.26-.271.073-.624a1.208 1.208 0 01-.16-.647c.035-.547.068-1.094.115-1.64.044-.522.118-1.043.154-1.567.027-.399-.195-.615-.583-.615z"
    ></path>
  </svg>
);
