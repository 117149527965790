/**
 * @author piotrzurek
 * @since 2022-2-22
 */

import * as React from "react";
import styled from "styled-components";

import { Heading, Margins } from "@ui/Atoms";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  testId?: string;
  className?: string;
  children?: React.ReactNode;
}

const MobilePageHeader = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <Margins xs={["base_x3", "base_x3", null, null]}>
      <Header type="h1" styleAs="h5" className={props.className}>
        {props.children}
      </Header>
    </Margins>
  </TestWrapper>
);

const Header = styled(Heading)`
  ${props => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

export { MobilePageHeader };
