import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

import { BoxesProps, BoxesSection, DefaultCard } from "./BoxesSection";

interface Service {
  service_title: { text: string };
  service_image: { gatsbyImageData: IGatsbyImageData };
  service_link?: {
    slug?: string;
    url?: string;
  };
}

export interface OurServicesProps {
  our_services_title: { text: string };
  our_services: Service[];
  isSectionWhite?: boolean;
  language: string;
  hideTilesWithoutLink?: boolean;
}

const OurServices = (props: OurServicesProps): React.ReactElement => {
  //@TODO: refactor prismic custom type to be the same
  const boxesData: BoxesProps<DefaultCard> = {
    section_title: props.our_services_title,
    items: props.our_services.map(card => ({
      box_image: card.service_image.gatsbyImageData,
      box_image_text: card.service_title,
      service_link: card.service_link,
    })),
    language: props.language,
  };

  return <BoxesSection {...boxesData} hideTilesWithoutLink />;
};

export { OurServices };
