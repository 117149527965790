import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Play = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <defs>
        <path
          d="M12 4a8 8 0 110 16 8 8 0 010-16zm0 1.333a6.667 6.667 0 100 13.334 6.667 6.667 0 000-13.334zM9.333 9.335c0-1.108 1.304-1.706 2.209-1.083l3.878 2.665a1.3 1.3 0 010 2.166l-3.878 2.665c-.905.623-2.209.025-2.209-1.083zm1.453.015c-.053-.036-.12-.006-.12-.015v5.33c0-.009.067.021.12-.016L14.64 12z"
          id="playcircle_line_a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="playcircle_line_b" fill="#fff">
          <use xlinkHref="#playcircle_line_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#playcircle_line_a" />
        <g mask="url(#playcircle_line_b)" fill="#FFF">
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <defs>
        <path
          d="M12 4a8 8 0 100 16 8 8 0 000-16zm3.042 8.533L11.164 15.2c-.48.33-1.164.016-1.164-.534v-5.33c0-.55.685-.864 1.164-.534l3.878 2.666c.389.266.389.8 0 1.066z"
          id="playcircle_solid_a"
        />
      </defs>
      <use
        fill={props.color || theme.colors.black500}
        fillRule="nonzero"
        xlinkHref="#playcircle_solid_a"
        transform="translate(-4 -4)"
      />
    </svg>
  );
