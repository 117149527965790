import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const TrashBin = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M18 6v12.75c0 1.243-1.027 2.25-2.269 2.25h-7.5A2.233 2.233 0 0 1 6 18.75V6M19.5 6h-15M10 3h4M14 10v7M10 17v-7" />
    </svg>
  );
};
