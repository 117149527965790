import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";

const getRatingStateFn = (store: IStore) => store.rating;
const getRatingState = createSelector(getRatingStateFn, f => f);

const getPicturesThumbnailsFn = (store: IStore) =>
  store.rating
    .get("pictures")
    .valueSeq()
    .toJS()
    .map(picture => ({
      url: picture.thumbnailUrl,
      loadingUuid: picture.loadingUuid,
    }));
const getPicturesThumbnails = createSelector(getPicturesThumbnailsFn, f => f);

export { getRatingState, getPicturesThumbnails };
