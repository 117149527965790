import * as React from "react";
import { graphql, PageProps } from "gatsby";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { Paragraph, Margins, Subheading } from "@ui/Atoms";

import { langFromTag } from "@services/LangFromTag/LangFromTag";

import { ContactForm } from "@soft/containers/ContactForm";

import SEO from "../../components/SEO";
import Layout from "../../components/Layout";

type Props = PageProps<
  {
    prismicContentPage: {
      data: {
        origin: ORIGIN;
        title: { text: string };
        content: { raw: { text: string; url: string }[] };
      };
    };
  },
  {
    lang: string;
    country: AVAILABLE_COUNTRIES;
  }
>;

const ContentPageLegacy = (props: Props): React.ReactElement => {
  const { prismicContentPage } = props.data;
  const title = prismicContentPage.data.title.text;
  const content = prismicContentPage.data.content.raw;
  const origin = prismicContentPage.data.origin;

  const lang = props.pageContext.lang;
  const country = props.pageContext.country;

  return (
    <Layout
      language={langFromTag(lang) || DEFAULT_LANGUAGE}
      origin={origin}
      country={country}
    >
      <SEO title={title} path={props.location.pathname} />
      <Margins xs={["base_x4", null, null, null]}>
        <Subheading>{title}</Subheading>
      </Margins>
      {content.map((item, idx) => (
        <ReactMarkdown
          key={idx}
          unwrapDisallowed
          rehypePlugins={[rehypeRaw]}
          components={{
            p: Paragraph,
          }}
        >
          {item.text}
        </ReactMarkdown>
      ))}
      <ContactForm />
    </Layout>
  );
};

export default ContentPageLegacy;

export const pageQuery = graphql`
  query ContactUsBatsoft($id: String) {
    prismicContentPage(id: { eq: $id }) {
      data {
        country
        origin
        content {
          raw
        }
        title {
          text
        }
      }
    }
  }
`;
