import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Paper = (props: Icon): React.ReactElement => {
  if (props.variant === "line") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 24"
        style={{ ...style(props.size, props.height), ...props.style }}
        className={props.className}
      >
        <g>
          <rect
            x="1"
            y="1"
            width="20"
            height="22"
            rx="2"
            fill="none"
            stroke={props.color || theme.colors.grey700}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            d="M6 12h10M6 17h6M6 7h10"
            fill="none"
            stroke={props.color || theme.colors.grey700}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84 100"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <path
        fill={props.color || theme.colors.grey700}
        d="M58.333 0v25h25l-25-25zM50 0H8.333A8.336 8.336 0 000 8.333v83.334C0 96.267 3.733 100 8.333 100H75c4.6 0 8.333-3.733 8.333-8.333V33.333H50V0zm12.5 83.333H20.833a4.179 4.179 0 01-4.166-4.166A4.179 4.179 0 0120.833 75H62.5a4.179 4.179 0 014.167 4.167 4.179 4.179 0 01-4.167 4.166zm0-29.166a4.179 4.179 0 014.167 4.166A4.179 4.179 0 0162.5 62.5H20.833a4.179 4.179 0 01-4.166-4.167 4.179 4.179 0 014.166-4.166H62.5z"
      ></path>
    </svg>
  );
};
