import { Record, Map } from "immutable";

import { CLEANING_FREQUENCY, KEY_PICK, Task } from "@typings/booking";
import { SECOND_PILLAR_RETIREMENT, SICKNESS_INSURANCE } from "@typings/salary";
import { AVAILABLE_CURRENCIES } from "@typings/globals";
import { TERMINATION_PERIOD } from "@typings/bookingsToValidate";

export enum CONTRACT_ACTION {
  DOWNLOAD_CONTRACT = "DOWNLOAD_CONTRACT",
  TERMINATE_CONTRACT = "TERMINATE_CONTRACT",
  EDIT_SALARY = "EDIT_SALARY",
  ADD_BONUS = "ADD_BONUS",
}

export enum EMPLOYEE_STATUS {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  TERMINATED = "TERMINATED",
}

export interface ContractAction {
  action: CONTRACT_ACTION;
}

export interface BankAccountData {
  number: string;
  ownerName: string;
  ownerAddress: string;
}

// backend didn't use enums so we need to map these magic strings ourselves
export enum CONTRACT_SUMMARY_STATUS {
  ACTIVE = "active",
  INACTIVE = "inactive",
  CANCELLED = "cancelled",
  PENDING = "admin_created",
}

export interface EmployeeDetails {
  firstName: string;
  lastName: string;
  profilePictureUrl: string | null;
  email: string | null;
  dateOfBirth: string | null;
  homePhone: string | null;
  mobilePhone: string | null;
  address: string;
  zip: string;
  city: string;
  workPermit: string;
  socialNumber: string;
  bankAccount: BankAccountData | null;
  contracts: ContractSummary[];
}

export interface Employee {
  agentUuid: string | null;
  employeeName: string | null;
  employeeEmail: string;
  employeePhoneNumber: string | null;
  employeeProfilePictureUrl: string | null;
  contracts: Contracts;
  employeeDetails: Record<EmployeeDetails> | null;
}

export interface ContractSummary {
  uuid: string;
  status: CONTRACT_SUMMARY_STATUS;
  creationDate: string;
}

export interface Contract {
  address: string;
  agentUuid: string | null;
  agreeToProfessionalAccidentInsurance: boolean | null;
  allowedActions: ContractAction[];
  bathrooms: number;
  bedrooms: number;
  canChangeLegalLocation: boolean;
  cleaningHours: string;
  contractUuid: string;
  creationDateTime: string;
  currency: AVAILABLE_CURRENCIES;
  earliestLegalTerminationDate: string;
  employeeEmail: string;
  employeeName: string | null;
  employeePhoneNumber: string | null;
  employeeProfilePictureUrl: string | null;
  frequency: CLEANING_FREQUENCY;
  grossSalary: number;
  insurancePolicy: boolean | null;
  insurancePolicyNumber: string | null;
  insurancePolicyWillBeProvidedLater: boolean | null;
  isPending: boolean;
  isTerminated: boolean;
  keyPick: KEY_PICK;
  locationComment: string;
  locationUuid: string;
  secondPillarRetirement: SECOND_PILLAR_RETIREMENT;
  serviceFee: number;
  serviceLocationId: number;
  sicknessInsurance: SICKNESS_INSURANCE;
  specialInstructions: string;
  startDateTime: string;
  tasks: Task[];
  terminationDate: string | null;
  terminationPeriod: TERMINATION_PERIOD;
  totalHoursDone: number;
}

export interface ContractToPauseDto {
  uuid: string;
  address: string;
  periodicity: number;
  executionDateTime: string;
  planName: string;
}

export type Contracts = Map<string, Record<Contract>>;
export type Employees = Map<string, Record<Employee>>;
