import { Map, Record } from "immutable";

import { Location, LocationRaw } from "@typings/locations";

export const LOCAL_STORAGE_KEY = "store:locations";

export interface LocationsReducer {
  defaultLocationId: string;
  invoiceLocationId: string;
  legalLocationId: string;
  locations: Map<string, Record<Location>>;
}

export interface IPetsUpdate {
  hasPets: boolean;
}

export interface LocationsRawResponse {
  defaultLocationId: string;
  invoiceLocationId: string;
  legalLocationId: string;
  locations: LocationRaw[];
}
