import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";

const getAvailablePaymentMethodsFunction = (store: IStore) =>
  store.bookingsToValidate.get("availablePaymentMethods");

export const getAvailablePaymentMethods = createSelector(
  getAvailablePaymentMethodsFunction,
  f => f,
);

const getSelectedPaymentMethodFunction = (store: IStore) =>
  store.bookingsToValidate.get("selectedPaymentMethod");

export const getSelectedPaymentMethod = createSelector(
  getSelectedPaymentMethodFunction,
  f => f,
);

const getLegalCityFunction = (store: IStore) =>
  store.bookingsToValidate.getIn(["legalLocation", "city"]);

export const getLegalCity = createSelector(getLegalCityFunction, f => f);

const getLegalLocationFunction = (store: IStore) =>
  store.bookingsToValidate.get("legalLocation");

export const getLegalLocation = createSelector(
  getLegalLocationFunction,
  f => f,
);

const getIsLegalLocationTheSameFunction = (store: IStore) =>
  store.bookingsToValidate.get("isLegalLocationTheSame");

export const getIsLegalLocationTheSame = createSelector(
  getIsLegalLocationTheSameFunction,
  f => f,
);

const getIsLegalLocationPutFunction = (store: IStore) =>
  store.bookingsToValidate.get("isLegalLocationPut");

export const getIsLegalLocationPut = createSelector(
  getIsLegalLocationPutFunction,
  f => f,
);

const getVoucherFunction = (store: IStore) =>
  store.bookingsToValidate.get("voucherToken");

export const getVoucher = createSelector(getVoucherFunction, f => f);

const getVoucherStatusFunction = (store: IStore) =>
  store.bookingsToValidate.get("voucherStatus");

export const getVoucherStatus = createSelector(
  getVoucherStatusFunction,
  f => f,
);

const getVoucherMessageFunction = (store: IStore) =>
  store.bookingsToValidate.get("voucherMessage");

export const getVoucherMessage = createSelector(
  getVoucherMessageFunction,
  f => f,
);

const getJobsToValidateFunction = (store: IStore) =>
  store.bookingsToValidate.get("jobs");

export const getJobsToValidate = createSelector(
  getJobsToValidateFunction,
  f => f,
);
