/**
 * @author tomekbuszewski & jakubbujakowski
 * @since 2019-3-8
 */

import * as React from "react";
import { connect } from "react-redux";
import { Record } from "immutable";
import styled from "styled-components";

import {
  ADMIN_DASHBOARD_LINK,
  AGENT_LOGOUT_LINK,
  AGENT_SCHEDULE_LINK,
  GIFT_CARDS_LINK,
  HELP_LINK_BATSOFT,
} from "@config/links";
import { ROUTES } from "@config/soft.routing";
import { HAS_USER_LOGGED_OUT, IS_BROWSER, IS_PROD } from "@config/consts";
import * as HTTP_CODE from "@config/httpStatuses";

import {
  AVAILABLE_LANGS,
  AVAILABLE_COUNTRIES,
  APP_NAMES,
} from "@typings/globals";
import { MODALS } from "@typings/modals";
import { IUser, USER_TYPES } from "@typings/user";
import { IOAuthState } from "@typings/oauth";

import { Header as HeaderUI } from "@ui/Organisms/Header";
import {
  Navigation as NavigationUI,
  NavLink,
  NavbarToggle,
} from "@ui/Organisms/Navbar";
import { UserDropdown } from "@ui/Organisms/UserDropdown";
import { LanguageDropdownBasic } from "@ui/Organisms/LanguageDropdownBasic";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { resolveCountryFromUrl } from "@services/ResolveCountryFromUrl";
import { setLanguageInUrl } from "@services/SetLanguageInUrl";
import { sessionStorageFacade } from "@services/SessionStorageFacade";

import { ToastContainer } from "@containers/Toastify";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useTranslate } from "@hooks/useTranslate";
import { useOnClickOutside } from "@hooks/useOnClickOutside";

import { hideMenu, showMenu } from "@soft/redux/navigation/navigation.actions";
import { getNavigationState } from "@soft/redux/navigation/navigation.selectors";
import { IStore } from "@soft/redux/reducers";
import { fetchUserData, logoutUser } from "@soft/redux/user/user.actions";
import { showModal } from "@soft/redux/modals/modals.actions";
import {
  getCountry,
  getLanguage,
} from "@soft/redux/localisation/localisation.selectors";
import {
  getUserData,
  isUserAdmin,
  isUserLoggedIn,
  isUserClient,
  isAdminImpersonator,
  isUserAgent,
} from "@soft/redux/user/user.selectors";
import {
  setCountry,
  setLanguage,
} from "@soft/redux/localisation/localisation.actions";
import {
  getUserNotificationValue,
  USER_NOTIFICATIONS,
} from "@soft/redux/userNotifications";
import { getUserData as getOAuthUserData } from "@soft/redux/oauth/oauth.selectors";

import { UserNotificationHandler } from "../UserNotificationHandler";

import {
  createHref,
  getLanguagesList,
  getUserNavigationConfig,
  getAgentNavigationConfig,
} from "./Header.helpers";
import { HeaderModals } from "./Header.modals";

interface Props {
  // component-props
  children?: React.ReactNode;
  useNativeLinks: boolean;
  pathname?: string;
  staticLanguage?: AVAILABLE_LANGS;
  isSSR?: boolean;

  // redux-props
  fetchUserData: () => Promise<AsyncActionResult>;
  country: AVAILABLE_COUNTRIES;
  hideMenu: () => void;
  isUserAdmin: boolean;
  isUserClient: boolean;
  isUserLoggedIn: boolean;
  isUserAgent: boolean;
  isAdminImpersonator: boolean;
  language: AVAILABLE_LANGS;
  logoutUser: () => Promise<AsyncActionResult>;
  menuState: boolean;
  setCountry: (flag: string) => void;
  setLanguage: (lang: string) => void;
  showMenu: () => void;
  showModal: (id: string, options?: { [k: string]: any }) => void;
  userData: Record<IUser> | null;
  oAuthUserData: Record<IOAuthState>;
  hasBookingsToValidate: boolean;
}

const HeaderComponent = (props: Props) => {
  const sessionStorage = sessionStorageFacade();
  const { mobile, tablet } = useBreakpoint();
  const translate = useTranslate();
  const [isMenuActive, setIsMenuActive] = React.useState<boolean>(false);

  const menuEffectDeps = IS_BROWSER ? [window.location.href] : [];
  const language = props.staticLanguage || props.language;
  const isMobile = mobile || tablet;
  const isClientPath = IS_BROWSER && window.location.href.includes("client#");

  const userType = props.userData?.get("userType");
  const oAuthUserType = props.oAuthUserData.get("userType");

  const ref = useOnClickOutside(() => setIsMenuActive(false));

  React.useEffect(() => {
    setIsMenuActive(false);
  }, menuEffectDeps);

  const handleSetLanguage = (lang: AVAILABLE_LANGS) => {
    setLanguageInUrl(lang);
    props.setLanguage(lang);
  };

  const logoutUser = async () => {
    if (!props.isSSR) {
      /* 
        set localstorage flag (only for PWA, gatsby won't redirect)
        to hide modal in /redirection after intentional logout by UserDropdown
      */
      sessionStorage.setItem(HAS_USER_LOGGED_OUT, "true");
    }
    await props.logoutUser();
    window.location.href = "/";
  };

  const loginUser = () => {
    props.showModal(MODALS.NAVBAR_LOGIN);
  };

  React.useEffect(() => {
    (async () => {
      const { onError } = await props.fetchUserData();

      onError(
        () => {
          props.logoutUser();
        },
        { matchCode: HTTP_CODE.UNAUTHORIZED },
      );

      props.setCountry(resolveCountryFromUrl());
    })();
  }, []);

  React.useEffect(() => {
    if (props.menuState) {
      props.hideMenu();
    }
  }, [props.pathname, props.menuState]);

  /**
   * Handle redirects after initial login.
   * This can be moved to a separate component in the future if logic gets more complex.
   */
  React.useEffect(() => {
    if ((userType || oAuthUserType) && !isClientPath) {
      /**
       * Admin logs in on home page or other static page without impersonating.
       * Redirect to admin dashboard.
       */
      if (
        (userType === USER_TYPES.ADMIN || oAuthUserType === USER_TYPES.ADMIN) &&
        !props.isAdminImpersonator &&
        IS_PROD
      ) {
        window.location.href = `/${language}${ADMIN_DASHBOARD_LINK}`;
        return;
      }

      /**
       * Admin, admin-impersonator or client logs in on home page or other static page.
       * Redirect to client dashboard.
       */
      if (
        userType === USER_TYPES.CLIENT ||
        oAuthUserType === USER_TYPES.CLIENT
      ) {
        window.location.href = `/${language}${ROUTES.CLIENT_DASHBOARD}`;
        return;
      }

      /**
       * Agent logs in on home page or other static page.
       * Redirect to agent app in legacy frontend.
       */
      if (userType === USER_TYPES.AGENT && IS_PROD) {
        window.location.href = `/${language}${AGENT_SCHEDULE_LINK}`;
        return;
      }
    }
  }, [userType, oAuthUserType]);

  return (
    <React.Fragment>
      {/** Main View */}
      <HeaderUI
        currentLanguage={language}
        variant={APP_NAMES.BATSOFT}
        isSSR={props.isSSR}
        loginUser={loginUser}
        rowRef={ref}
      >
        <NavigationUI active={isMenuActive} variant={APP_NAMES.BATSOFT}>
          <DesktopOnlyContainer>
            {/* 
                First NavLink won't get styles, that's why we use empty one as the first NavLink. 
                This is a temporary solution for a styled-components issue.
              */}
            <NavLink>
              <a href={"#"} style={{ display: "none" }} />
            </NavLink>

            <NavLink isDark>
              <a href={createHref(language, HELP_LINK_BATSOFT)}>
                {translate("batsoft_pwa_navigation.help")}
              </a>
            </NavLink>

            {!props.isUserAgent && (
              <NavLink special>
                <a href={createHref(language, GIFT_CARDS_LINK)}>
                  {translate("batsoft_pwa_navigation.gift_cards")}
                </a>
              </NavLink>
            )}
          </DesktopOnlyContainer>

          <UserDropdown
            isUserLoggedIn={props.isUserClient || props.isUserAdmin}
            userData={props.userData}
            currentLanguage={language}
            baseUrl={ROUTES.CLIENT_DASHBOARD}
            useNativeLinks={props.useNativeLinks}
            isDark
            loginLabel={translate("batsoft_pwa_navigation.login")}
            loginUser={loginUser}
            config={
              props.isUserAgent
                ? getAgentNavigationConfig({
                    exitUserUrl:
                      props.userData && props.userData.get("exitUserUrl")
                        ? props.userData.get("exitUserUrl")
                        : "",
                    isUserAdmin: props.isUserAdmin,
                    isAdminImpersonator: props.isAdminImpersonator,
                    isMobile,
                    language,
                    logoutUrl: AGENT_LOGOUT_LINK,
                    logoutAction: logoutUser,
                    translate,
                  })
                : getUserNavigationConfig({
                    exitUserUrl:
                      props.userData && props.userData.get("exitUserUrl")
                        ? props.userData.get("exitUserUrl")
                        : "",
                    logoutUrl: props.userData
                      ? props.userData.get("logoutUrl")
                      : "",
                    isUserAdmin: props.isUserAdmin,
                    isUserClient: props.isUserClient,
                    isAdminImpersonator: props.isAdminImpersonator,
                    isMobile: isMobile,
                    hasBookingsToValidate: props.hasBookingsToValidate,
                    language,
                    logoutAction: logoutUser,
                    loginAction: loginUser,
                    translate,
                  })
            }
          />
        </NavigationUI>
        <LanguageDropdownBasic
          isDark
          currentLanguage={language}
          languages={getLanguagesList(translate)}
          setLanguage={handleSetLanguage}
        />
        <NavbarToggle
          onClick={() => setIsMenuActive(!isMenuActive)}
          active={isMenuActive}
          variant={APP_NAMES.BATSOFT}
          label={translate("batsoft_pwa_navigation.toggle_menu")}
        />
      </HeaderUI>
      {/** Extras */}
      <HeaderModals />
      <ToastContainer />
      {IS_BROWSER && <UserNotificationHandler />}
    </React.Fragment>
  );
};

const mapState = (store: IStore) => ({
  country: getCountry(store),
  isUserAdmin: isUserAdmin(store),
  isUserClient: isUserClient(store),
  isUserLoggedIn: isUserLoggedIn(store),
  isUserAgent: isUserAgent(store),
  isAdminImpersonator: isAdminImpersonator(store),
  language: getLanguage(store),
  menuState: getNavigationState(store),
  userData: getUserData(store),
  oAuthUserData: getOAuthUserData(store),
  hasBookingsToValidate: getUserNotificationValue(
    store,
    USER_NOTIFICATIONS.HAS_BOOKINGS_TO_VALIDATE,
  ),
});

const mapDispatch = {
  fetchUserData,
  hideMenu,
  logoutUser,
  setCountry,
  setLanguage,
  showMenu,
  showModal,
};

export const Header = connect(mapState, mapDispatch)(HeaderComponent);

const DesktopOnlyContainer = styled.div`
  display: none;

  ${props => props.theme.breakpoints.desktop} {
    display: flex;
  }
`;
