export enum NAVBAR {
  LANGUAGE_SELECTOR = "languageSelector",
  LANGUAGE_DROPDOWN = "languageDropdown",
  LANGUAGE_TRIGGER = "languageTrigger",
  LANGUAGE_BUTTON_EN = "languageButtonEn",
  LANGUAGE_BUTTON_FR = "languageButtonFr",
  LANGUAGE_BUTTON_DE = "languageButtonDe",
  LANGUAGE_BUTTON_IT = "languageButtonIt",
  LOGIN_BUTTON = "loginButton",
  LOGOUT_BUTTON = "logoutButton",
  USER_DROPDOWN = "userDropdown",
  USER_DROPDOWN_MY_SCHEDULE = "userMySchedule",
  USER_DROPDOWN_MY_EMPLOYEES = "userMyEmployees",
  USER_DROPDOWN_BOOKINGS_TO_VALIDATE = "userBookingsToValidate",
  USER_DROPDOWN_PROFILE = "userProfile",
  USER_DROPDOWN_OPTIONS = "userOptions",
  USER_DROPDOWN_BATGROUP_HEADING = "userBatgroupAppsHeading",
  USER_DROPDOWN_BATMAID_LINK = "userDropdownBatmaidLink",
  USER_DROPDOWN_BATWORK_LINK = "userDropdownBatworkLink",
  USER_MOBILE_DROPDOWN_GIFT_CARDS = "userMobileGiftCards",
  HAMBURGER_DROPDDOWN = "hamburgerDropdown",
}
