import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";

const getServiceLocationIdFunction = (store: IStore) =>
  store.salary.get("serviceLocationId");

export const getServiceLocationId = createSelector(
  getServiceLocationIdFunction,
  f => f,
);

const getSicknessInsuranceFunction = (store: IStore) =>
  store.salary.get("sicknessInsurance");

export const getSicknessInsurance = createSelector(
  getSicknessInsuranceFunction,
  f => f,
);

const getPrevSicknessInsuranceFunction = (store: IStore) =>
  store.salary.get("prevSicknessInsurance");

export const getPrevSicknessInsurance = createSelector(
  getPrevSicknessInsuranceFunction,
  f => f,
);

const getSicknessInsuranceCostsFunction = (store: IStore) =>
  store.salary.get("sicknessInsuranceCosts");

export const getSicknessInsuranceCosts = createSelector(
  getSicknessInsuranceCostsFunction,
  f => f,
);

const getSecondPillarRetirementFunction = (store: IStore) =>
  store.salary.get("secondPillarRetirement");

export const getSecondPillarRetirement = createSelector(
  getSecondPillarRetirementFunction,
  f => f,
);

const getPrevSecondPillarRetirementFunction = (store: IStore) =>
  store.salary.get("prevSecondPillarRetirement");

export const getPrevSecondPillarRetirement = createSelector(
  getPrevSecondPillarRetirementFunction,
  f => f,
);

const getSecondPillarRetirementCostsFunction = (store: IStore) =>
  store.salary.get("secondPillarRetirementCosts");

export const getSecondPillarRetirementCosts = createSelector(
  getSecondPillarRetirementCostsFunction,
  f => f,
);

const getProfessionalAccidentInsuranceFunction = (store: IStore) =>
  store.salary.get("professionalAccidentInsurance");

export const getProfessionalAccidentInsurance = createSelector(
  getProfessionalAccidentInsuranceFunction,
  f => f,
);

const getAgreeToProfessionalAccidentInsuranceFunction = (store: IStore) =>
  store.salary.get("agreeToProfessionalAccidentInsurance");

export const getAgreeToProfessionalAccidentInsurance = createSelector(
  getAgreeToProfessionalAccidentInsuranceFunction,
  f => f,
);

const getInsurancePolicyNumberFunction = (store: IStore) =>
  store.salary.get("insurancePolicyNumber");

export const getInsurancePolicyNumber = createSelector(
  getInsurancePolicyNumberFunction,
  f => f,
);

const getInsurancePolicyWillBeProvidedLaterFunction = (store: IStore) =>
  store.salary.get("insurancePolicyWillBeProvidedLater");

export const getInsurancePolicyWillBeProvidedLater = createSelector(
  getInsurancePolicyWillBeProvidedLaterFunction,
  f => f,
);

const getSalaryDefaultsFunction = (store: IStore) =>
  store.salary.get("salaryDefaults");

export const getSalaryDefaults = createSelector(
  getSalaryDefaultsFunction,
  f => f,
);

const getSliderSupergrossSalaryFunction = (store: IStore) =>
  store.salary.get("sliderSuperGrossSalary");

export const getSliderSupergrossSalary = createSelector(
  getSliderSupergrossSalaryFunction,
  f => f,
);

const getSalaryDefaultsFetchedStatusFunction = (store: IStore) =>
  store.salary.get("salaryDefaultsFetched");

export const getSalaryDefaultsFetchedStatus = createSelector(
  getSalaryDefaultsFetchedStatusFunction,
  f => f,
);

const getSliderChangeCountFunction = (store: IStore) =>
  store.salary.get("sliderChangeCount");

export const getSliderChangeCount = createSelector(
  getSliderChangeCountFunction,
  f => f,
);

const getSalaryFunction = (store: IStore) => store.salary.get("salary");

export const getSalary = createSelector(getSalaryFunction, f => f);

const getChosenAgentIdFunction = (store: IStore) =>
  store.salary.get("chosenAgentId");

export const getChosenAgentId = createSelector(
  getChosenAgentIdFunction,
  f => f,
);

const getCachePreviousDataFunction = (store: IStore) =>
  store.salary.get("cachePreviousData");

export const getCachePreviousData = createSelector(
  getCachePreviousDataFunction,
  f => f,
);

const getCachedDataFunction = (store: IStore) => store.salary.get("cachedData");

export const getCachedData = createSelector(getCachedDataFunction, f => f);
