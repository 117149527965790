import { SliceComponentProps } from "@prismicio/react";
import React from "react";
import styled, { useTheme } from "styled-components";

import { Container, Heading, Margins, Paragraph } from "@ui/Atoms";
import { renderIcon, Icon } from "@ui/Molecules/Steps";
import { DraggableCarousel } from "@ui/Molecules/DraggableCarousel";
import { rem } from "@ui/helpers";

import { PrismicGlobalContentPageDataBodySteps } from "../../../graphql-types";
import { StyledSection } from "../Homepage/shared";

const slidesToShow = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
};

const StepsSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodySteps>,
): React.ReactElement => {
  const theme = useTheme();

  const { primary, items } = props.slice;

  return (
    <Section backgroundColor={theme.colors.black800}>
      <Container widthVariant="fullMobile">
        <Margins xs={[null, "base_x2", null, null]}>
          <Heading
            type="h2"
            styleAs="h4"
            textAlignLg="left"
            color={theme.colors.white}
          >
            {primary?.title1?.text}
          </Heading>
        </Margins>
        <StyledParagraph color={theme.colors.white}>
          {primary?.description?.text}
        </StyledParagraph>

        <DraggableCarousel hideButtons slidesToShow={slidesToShow}>
          {items?.map(step => (
            <StepContent key={step.step_title?.text}>
              <Margins xs={[null, "base_x2", null, null]}>
                <span>
                  {renderIcon({
                    icon: step.icon as Icon,
                    variant: "line",
                    iconsSize: 26,
                    iconsColor: theme.colors.white,
                  })}
                </span>
              </Margins>

              <Heading type="h6" color={theme.colors.white}>
                {step?.step_title?.text}
              </Heading>
              <p>{step?.step_description?.text}</p>
            </StepContent>
          ))}
        </DraggableCarousel>
      </Container>
    </Section>
  );
};

export { StepsSlice };

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${props => props.theme.margins.base_x4};
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin-bottom: 0;
  }
`;

const Section = styled(StyledSection)`
  padding: ${props =>
    `${props.theme.margins.base_x4} ${props.theme.margins.base_x3} ${rem(88)}`};
  overflow: hidden;
  color: ${props => props.theme.colors.white};

  ${props => props.theme.breakpoints.tablet} {
    padding: ${rem(96)} 0 ${props => props.theme.margins.base_x8};
  }
`;
