import { createSelector } from "reselect";
import { List, Record } from "immutable";
import { compareAsc, compareDesc } from "date-fns";

import { IStore } from "@soft/redux/reducers";

import { Absence, ABSENCE_UI_STATE } from "./absences.typings";

const getAbsencesByTypeFunction = (
  store: IStore,
  absenceType: ABSENCE_UI_STATE,
): List<Record<Absence>> => {
  return store.absences
    .filter((item: Record<Absence>) => item.get("uiState") === absenceType)
    .sort((a: Record<Absence>, b: Record<Absence>) =>
      absenceType === ABSENCE_UI_STATE.PREVIOUS
        ? compareDesc(
            new Date(a.get("startDate")),
            new Date(b.get("startDate")),
          )
        : compareAsc(
            new Date(a.get("startDate")),
            new Date(b.get("startDate")),
          ),
    );
};
export const getAbsencesByType = createSelector(
  getAbsencesByTypeFunction,
  f => f,
);

const getAbsencesSizeFunction = (store: IStore) => store.absences.size;
export const getAbsencesSize = createSelector(getAbsencesSizeFunction, f => f);
