import { AVAILABLE_LANGS, AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";
import { Contact } from "@typings/footer";

import { rem, transition } from "@ui/helpers";
import theme from "@ui/themes/default";

export interface Props {
  className?: string;
  children?: React.ReactNode;
  isTeaserVersion?: boolean;

  variant: ORIGIN;
  logo: JSX.Element;

  links: string;
  cities: string;
  comingSoonBody: string;
  hashtagLinks: string;
  locationsLeftColumn: string;
  locationsRightColumn: string;
  contact: Contact;

  navigationHeader: string;
  officesHeader: string;
  locationsHeader: string;
  appDownloadHeader: string;
  reviewUsHeader: string;
  followUsHeader: string;

  termsOfUseText: string;
  appComingSoonText: string;

  isStandalone: boolean;
  language: AVAILABLE_LANGS;
  country: AVAILABLE_COUNTRIES;

  androidAppUrl: string;
  iosAppUrl: string;

  googleReviewUrl?: string;
  trustpilotUrl?: string;

  onEmailClick?: () => void;
  onPhoneClick?: () => void;
}

const LOGO_HEIGHT: string = rem(40);

export const styling = (): string => {
  return `
  *, *::before, *::after {
    box-sizing: border-box;
  }
  
  .footer-universal {
    color: ${theme.colors.white};
    padding-top: ${theme.margins.base_x4};
    text-align: left;
    background-color: ${theme.colors.black500};
  }

  .footer-universal.batsoft {
    background-color: ${theme.colors.blue900};
  }

  .footer-universal.batwork {
    background-color: ${theme.colors.white};
  }

  .footer-universal a {
    transition: ${transition(["color", "opacity", "background"])};
    color: ${theme.colors.greyDark};
    line-height: ${theme.fonts.lineHeights.normal};
  }

  .footer-universal.batwork a {
    color: ${theme.colors.grey700};
  }

  .footer-universal.batsoft a {
    color: ${theme.colors.blueDisabled};
  }

  .footer-universal a:hover {
    color: ${theme.colors.white};
  }

  .footer-universal.batwork a:hover {
    color: ${theme.colors.black700};
  }

  .footer-universal a.disabled-link {
    cursor: auto;
    pointer-events: none;
  }

  .footer-universal .container {
    padding-left: ${theme.margins.base_x2};
    padding-right: ${theme.margins.base_x2};
  }

  ${theme.breakpoints.desktop} {
    .footer-universal .container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .footer__grid-container {
    width: 100%;
    margin: auto;
    padding-left: ${theme.margins.base_x2};
    padding-right: ${theme.margins.base_x2};
  }

  ${theme.breakpoints.tablet} {
    .footer__grid-container {
      max-width: ${theme.container.tablet};
    }
  }

  ${theme.breakpoints.desktop} {
    .footer__grid-container {
      max-width: ${theme.container.desktop};
    }
  }

  ${theme.breakpoints.desktopLarge} {
    .footer__grid-container {
      max-width: ${theme.container.desktopLarge};
    }
  }

  .footer__grid-row {
    display: flex;
    flex-flow: row wrap;
    margin-left: -${theme.margins.base_x2};
    margin-right: -${theme.margins.base_x2};
  }

  ${theme.breakpoints.desktop} {
    .footer__grid-row {
      flex-flow: row;
    }
  }

  .teaser__row {
    justify-content: flex-start;
    margin-bottom: ${theme.margins.base_x2};
  }

  ${theme.breakpoints.tablet} {
    .teaser__row {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
    }
  }

  ${theme.breakpoints.desktop} {
    .footer__grid-row--even, .teaser__row {
      justify-content: space-between;
    }
  }

  .footer__column {
    padding-left: ${theme.margins.base_x2};
    padding-right: ${theme.margins.base_x2};
    width: 100%;
  }

  ${theme.breakpoints.tablet} {
    .footer__column {
      width: 50%;
    }
  }

  ${theme.breakpoints.desktop} {
    .footer__column {
      width: auto;
      max-width: ${rem(240)};
    }
  }

  ${theme.breakpoints.tablet} {
    .footer__column--tablet-gutter {
      margin-bottom: ${theme.margins.base_x3};
    }
  }

  ${theme.breakpoints.desktop} {
    .footer__column--tablet-gutter {
      margin-bottom: 0;
    }
  }

  .footer__column--50-mobile {
    width: 50%;
  }

  ${theme.breakpoints.desktop} {
    .footer__column--50-mobile {
      width: auto;
    } 
  }

  .footer__column-social {
    margin-top: -${theme.margins.base_x4};
    padding-left: ${theme.margins.base_x2};
    padding-right: ${theme.margins.base_x2};
    width: 100%;
  }

  .footer__column-social svg {
    overflow: hidden;
    vertical-align: middle;
  }

  ${theme.breakpoints.desktop} {
    .footer__column-social {
      margin-top: 0;
      width: auto;
      max-width: none;
    }
  }

  .footer__column-social--teaser {
    padding-left: ${theme.margins.base_x2};
  }

  ${theme.breakpoints.tablet} {
    .footer__column-social--teaser {
      width: auto;
      margin-top: -${theme.margins.base_x4};
    }
  }

  .footer__column-social a {
    opacity: 0.6;
  }

  .footer__column-social a:hover {
    opacity: 1;
  }

  .footer__column-full {
    padding-left: ${theme.margins.base_x2};
    padding-right: ${theme.margins.base_x2};
    width: 100%;
  }

  .footer__column-full strong {
    text-transform: uppercase;
    color: ${theme.colors.white};
  }

  .footer__links ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .footer__links--teaser {
    width: auto;
  }

  .footer__links ul.footer__contact {
    padding-top: ${theme.margins.base};
  }

  .footer-universal.batwork .footer__links ul li {
    color: ${theme.colors.grey700};
  }

  .footer-universal.batsoft .footer__links ul li {
    color: ${theme.colors.blueDisabled};
  }

  .footer__links ul li {
    font-size: ${theme.fonts.sizes.body2};
    margin: 0 0 ${theme.margins.base_x2};
    color: ${theme.colors.greyDark};
  }

  .footer__links ul li svg {
    flex-shrink: 0;
    opacity: 0.6;
  }

  .footer__links ul.footer__contact li {
    display: flex;
    margin-bottom: ${theme.margins.base_x3};
  }

  .footer__links.footer__navigation ul li {
    font-size: ${theme.fonts.sizes.body2};
    margin: 0 0 ${theme.margins.base};
  }
  

  .footer__location-container {
    background: ${theme.colors.black700};
    font-size: ${theme.fonts.sizes.body2};
    color: ${theme.colors.greyDisabledText};
    padding: ${theme.margins.base_x2} 0;
    margin-top: ${theme.margins.base_x5};
  }

  .footer__location-container.batwork {
    background: ${theme.colors.white};
  }

  .footer__location-container.batsoft {
    background: ${theme.colors.blue900};
    color: ${theme.colors.blueDisabled};
  }

  ${theme.breakpoints.desktop} {
    .footer__location-container {
      margin-top: ${theme.margins.base_x2};
    }
  }
  

  .footer__location-container a {
    color: ${theme.colors.greyDark};
  }

  .footer__copyright-container {
    background: ${theme.colors.black800};
    font-size: ${theme.fonts.sizes.body2};
    color: ${theme.colors.grey300};
    padding: ${rem(10)} 0;
  }

  .footer__copyright-container.extra-margin {
    margin-top: ${theme.margins.base_x4};
  }

  .footer__copyright-container.batsoft {
    background: ${theme.colors.blue900};
    color: ${theme.colors.blueDisabled};
  }

  .footer__copyright-container.batwork {
    background: ${theme.colors.greySurface};
    color: ${theme.colors.grey700};
  }

  .footer__copyright {
    display: block;
    color: ${theme.colors.white};
    padding-right: ${theme.margins.base_x2};
  }

  .footer__copyright.batwork {
    color: ${theme.colors.black700};
  }

  ${theme.breakpoints.desktop} {
    .footer__copyright {
      display: inline-block;
    }
  }

  .footer__copyright-container a {
    color: ${theme.colors.grey300};
  }

  .footer__copyright-container.batwork a {
    color: ${theme.colors.grey700};
  }

  .footer-universal.batwork .footer__heading {
    color: ${theme.colors.black700};
  }

  .footer__heading {
    font-size: ${theme.fonts.sizes.overline};
    font-weight: ${theme.fonts.weights.normal};
    line-height: ${theme.fonts.lineHeights.overline};
    letter-spacing: ${theme.fonts.letterSpacings.overline};
    margin: ${theme.margins.base_x6} 0 ${theme.margins.base_x2};
    text-transform: uppercase;
    color: ${theme.colors.white};
  }

  .footer__heading--sm-gutter {
    margin-bottom: ${theme.margins.base_x2};
  }

  .footer__heading--locations {
    margin-top: 0;
    margin-bottom: ${theme.margins.base};
  }

  .footer__heading--visible-tablet {
    visibility: hidden;
  }

  ${theme.breakpoints.tablet} {
    .footer__heading--visible-tablet {
      visibility: visible;
    }
    
    .footer__heading--shrink-tablet {
      margin-top: ${theme.margins.base_x4};
    }
  }

  ${theme.breakpoints.desktop} {
    .footer__heading--shrink-tablet {
      margin-top: ${theme.margins.base_x6};
    }
  }

  .footer-universal.batwork .footer__coming-soon-locations {
    color: ${theme.colors.black700};
  }

  .footer__coming-soon-locations {
    font-size: ${theme.fonts.sizes.caption};
    color: ${theme.colors.white};
  }

  .footer__logo-wrapper svg {
    margin-bottom: ${theme.margins.base_x4};
    height: ${LOGO_HEIGHT};
  }
  
  .footer__logo-wrapper--teaser svg {
    margin-bottom: ${theme.margins.base_x2};
    height: ${LOGO_HEIGHT};
  }

  ${theme.breakpoints.tablet} {
    .footer__logo-wrapper--teaser svg {
      margin-bottom: ${theme.margins.base_x4};
    }
  }

  .footer__downloads {
    display: flex;
    align-items: baseline;
  }

  .footer-universal.batsoft .footer__app-coming-soon {
    color: ${theme.colors.blueDisabled};
  }

  .footer__app-coming-soon {
    font-size: ${theme.fonts.sizes.caption};
    padding-top: ${theme.margins.base_x2};
    color: ${theme.colors.grey300};
  }

  ${theme.breakpoints.desktop} {
    .footer__app-coming-soon {
      margin-bottom: ${theme.margins.base_x7};
    }
  }

  .footer__downloads a {
    min-height: ${rem(30)};
  }

  .footer__downloads a:first-of-type {
    margin-right: ${theme.margins.base_x3};
  }

  .footer__social {
    display: flex;
    justify-content: flex-start;
  }

  .footer__social--teaser {
    display: flex;
    justify-content: flex-start;
    margin: ${theme.margins.base_x2} 0;
  }

  ${theme.breakpoints.desktop} {
    .footer__social--teaser {
      margin: 0;
    }
  }

  .footer__social a {
    position: relative;
    margin-right: ${theme.margins.base_x2};
    padding-right: ${theme.margins.half};
  }

  .footer__social a:last-of-type {
    margin-right: 0;
    padding-right: ${theme.margins.half};
  }

  .footer__contact svg {
    margin-right: ${theme.margins.base_x2};
  }

  .footer__reviews {
    margin-top: -${theme.margins.base};
    margin-bottom: ${theme.margins.base_x6};
  }

  ${theme.breakpoints.desktop} {
    .footer__reviews {
      margin-bottom: ${theme.margins.base_x8};
    }
  }

  .footer__reviews a:first-of-type svg {
    transform: translateY(${rem(4)});
    margin-right: ${theme.margins.base_x5};
  }

  .footer__paragraph {
    font-size: ${theme.fonts.sizes.body2};
    color: ${theme.colors.greyDark};
    line-height: ${theme.fonts.lineHeights.normal};
  }

  .footer__paragraph--top-margin {
    margin-top: ${theme.margins.base_x10};
  }

  ${theme.breakpoints.tablet} {
    .footer__paragraph--top-margin {
      margin-top: ${theme.margins.base_x8};
    }
  }

  ${theme.breakpoints.desktop} {
    .footer__paragraph--top-margin {
      margin-top: ${theme.margins.base_x10};
    }
  }

  .footer-universal.batwork .footer__paragraph,
  .footer-universal.batwork .footer__paragraph strong {
    color: ${theme.colors.grey700};
  }

  .footer-universal.batsoft .footer__paragraph,
  .footer-universal.batsoft .footer__paragraph strong {
    color: ${theme.colors.blueDisabled};
  }

  .footer__paragraph strong {
    color: ${theme.colors.white};
  }

  .footer__social-tablet-item {
    flex-grow: 1;
  }

  ${theme.breakpoints.tablet} {
    .footer__social-tablet-item {
      width: 50%;
    }
  }

  ${theme.breakpoints.desktop} {
    .footer__social-tablet-item {
      width: 100%;
    }
  }

  ${theme.breakpoints.tablet} {
    .footer__social-tablet-wrapper {
      display: flex;
      flex-wrap: wrap;
    }
  }

  ${theme.breakpoints.desktop} {
    .footer__social-tablet-wrapper {
      display: block;
    }
  }
  
`;
};
