import * as React from "react";
import { useTheme } from "styled-components";

import { style, MyIconProps } from "./MyIcons";

export const UserRotate = (props: MyIconProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <svg
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      stroke={props.color || theme.colors.blue400}
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
      className={props.className}
    >
      <path d="M17.5 15v-.65a2.85 2.85 0 0 0-2.85-2.85h-4.3a2.85 2.85 0 0 0-2.85 2.85V15" />
      <circle cx={12.5} cy={6} r={3} />
      <path d="m11.167 21 1.333-1.333-1.333-1.333" />
      <path d="M3.518 14A2.345 2.345 0 0 0 2.5 15.75c0 2.198 4.434 3.98 9.919 3.998M21.482 14c.586.4.96 1.043 1.018 1.75 0 1.791-2.943 3.306-7 3.816" />
    </svg>
  );
};
