import color from "color";
import * as React from "react";
import styled from "styled-components";

import { rem, transition } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";

interface DropdownContainerProps {
  active?: boolean;
  auto?: boolean;
  children?: React.ReactNode;
  theme: ITheme;
  withPadding?: boolean;
  withVerticalPadding?: boolean;
  alignment?: "left" | "right";
  maxHeight?: string | null;
  maxWidth?: string | null;
}

const DropdownContainer = styled.div<DropdownContainerProps>`
  background: ${props => props.theme.colors.white};
  border-radius: ${props => props.theme.buttons.borderRadius};
  box-shadow: 0 ${rem(2)} ${rem(8)} ${rem(1)}
    ${props => color(props.theme.colors.black).fade(0.76).toString()};

  overflow: hidden;
  z-index: 2;
  position: absolute;
  top: ${rem(40)};
  ${props => (props.alignment === "right" ? `right: 0;` : `left: 0;`)};
  max-height: ${props => props.maxHeight || rem(480)};
  max-width: ${props => props.maxWidth || "none"};
  overflow-y: auto;

  padding: ${props => props.withPadding && props.theme.margins.base};

  padding: ${props =>
    props.withVerticalPadding && `${props.theme.margins.base} 0`};

  min-width: ${props => !props.auto && rem(175)};

  display: flex;
  flex-flow: row wrap;

  transition: ${transition(["opacity", "transform"])};

  opacity: ${props => (props.active ? 1 : 0)};
  transform: translateY(-${props => (props.active ? 0 : rem(8))});
  pointer-events: ${props => (props.active ? "all" : "none")};
  white-space: nowrap;
`;

DropdownContainer.defaultProps = {
  active: false,
  auto: false,
};

export default DropdownContainer;
