/**
 * @author jakubbujakowski
 * @since 2020-8-17
 */

import * as React from "react";
import styled from "styled-components";

import { ACCOUNT_BALANCE } from "@config/testIds/clientProfile";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  label: string;
  amount: number;
  subLabel?: string;
  className?: string;
}

const HoursSummary = (props: Props): React.ReactElement => (
  <HoursSummaryWrapper className={props.className}>
    <Title>
      <TestWrapper testId={ACCOUNT_BALANCE.DURATION_BALANCE_LABEL || ""}>
        <div>{props.label}</div>
      </TestWrapper>
      {props.subLabel && (
        <TestWrapper testId={ACCOUNT_BALANCE.DURATION_BALANCE_SUB_LABEL || ""}>
          <SubLabel>{props.subLabel}</SubLabel>
        </TestWrapper>
      )}
    </Title>
    <TestWrapper testId={ACCOUNT_BALANCE.DURATION_BALANCE_AMOUNT || ""}>
      <Amount>{props.amount}</Amount>
    </TestWrapper>
  </HoursSummaryWrapper>
);

const Title = styled.div`
  display: flex;
  flex-direction: column;
`;

const HoursSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fonts.sizes.h6};
`;

const Amount = styled.span`
  margin-left: ${props => props.theme.margins.base_x3};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  flex-shrink: 0;
  text-transform: lowercase;
`;

const SubLabel = styled.span`
  font-size: ${props => props.theme.fonts.sizes.body2};
`;

export { HoursSummary };
