export enum ROUTES {
  // Generic
  BASE = "/",
  NOT_FOUND = "/not-found",
  REDIRECTION = "/redirection",
  TERMS_OF_USE = "/batsoft-terms-of-use",
  PRIVACY_POLICY = "/batsoft-privacy-policy",
  TRUST_MANDATE = "/downloads/mandate_en.pdf",
  TRUST_MANDATE_DECLARE_EMPLOYEE = "/downloads/declare_employee_mandate_en.pdf",
  PAYMENT_PROCESSED = "/payment-is-being-processed",
  ALTN = "/downloads/altn_en.pdf",

  // Booking
  BOOKING = "/booking",

  // Client dashboard
  CLIENT_DASHBOARD = "/client",
  CLIENT_DASHBOARD_PROFILE = "/profile",
  CLIENT_DASHBOARD_OPTIONS = "/options",
  CLIENT_DASHBOARD_MY_SCHEDULE = "/my-schedule",
  CLIENT_DASHBOARD_BOOKINGS_TO_VALIDATE = "/bookings-to-validate",
  CLIENT_DASHBOARD_MY_EMPLOYEES = "/my-employees",
  CLIENT_DASHBOARD_MY_EMPLOYEES_DETAILS = "/my-employees/details",
  CLIENT_DASHBOARD_MY_EMPLOYEES_EDIT_SALARY_PROPOSAL = "/my-employees/edit-salary-proposal",
  CLIENT_DASHBOARD_MY_EMPLOYEES_RESILIATE_CONTRACT = "/my-employees/resiliate-contract",
  CLIENT_DASHBOARD_SKIP_CLEANING = "/skip-cleaning",
  CLIENT_DASHBOARD_ADD_ABSENCE = "/add-absence",
  CLIENT_DASHBOARD_ADJUST_TIME = "/adjust-time",
  CLIENT_DASHBOARD_ANNOUNCE_PREGNANCY = "/announce-pregnancy",
  CLIENT_DASHBOARD_DECLARE_ACCIDENT = "/declare-accident",
  CLIENT_DASHBOARD_MY_CLEANINGS = "/my-cleanings", // just for redirect purposes
  CLIENT_DASHBOARD_RATING = "/rating",

  // Client dashboard - Options
  CLIENT_DASHBOARD_OPTIONS_NOTIFICATIONS = "/notifications",
  CLIENT_DASHBOARD_OPTIONS_PETS = "/pets",
  CLIENT_DASHBOARD_OPTIONS_REPLACEMENT = "/replacement",
  CLIENT_DASHBOARD_OPTIONS_HOLIDAYS = "/holidays",

  VALIDATE_CONTRACT = "/validate-contract",
  VALIDATE_CONTRACT_LINK_INVALID = "/validate-contract-link-invalid",

  // Account creation
  ACCOUNT_CREATION = "/account-creation",

  // Declare employee
  DECLARE_EMPLOYEE = "/declare-employee",
  DECLARE_EMPLOYEE_SALARY = "/declare-employee/salary",
  DECLARE_EMPLOYEE_CHECKOUT = "/declare-employee/checkout",
}
