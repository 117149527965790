import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CoffeeMachine = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M7.99834 8.99875V7.99833" />
      <path d="M17.0021 10.9996H18.0025" />
      <path d="M17.0021 14.0008H18.0025" />
      <path d="M17.0021 17.0021H18.0025" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0008 7.99833V18.0025H3.99667C3.44416 18.0025 2.99625 18.4504 2.99625 19.0029V20.0033C2.99625 20.5558 3.44416 21.0037 3.99667 21.0037H19.0029C20.1079 21.0037 21.0038 20.1079 21.0038 19.0029V4.99708C21.0038 3.89205 20.1079 2.99625 19.0029 2.99625H4.99709C3.89206 2.99625 2.99625 3.89205 2.99625 4.99708V5.9975C2.99625 7.10253 3.89206 7.99833 4.99709 7.99833H14.0008Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.9975 12H9.99917C10.5517 12 10.9996 12.4479 10.9996 13.0004V15.0013C10.9996 16.6588 9.65588 18.0025 7.99834 18.0025V18.0025C6.34079 18.0025 4.99709 16.6588 4.99709 15.0013V13.0004C4.99709 12.4479 5.44499 12 5.9975 12Z"
      />
      <path d="M5.9975 12H3.99667" />
    </svg>
  );
};
