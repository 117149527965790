/**
 * @author jakubbujakowski
 * @since 2020-7-6
 */

import * as React from "react";
import styled, { css } from "styled-components";

import theme from "@ui/themes/default";
import { Info } from "@ui/Assets/Symbolicons/Info";
import { Warning } from "@ui/Assets/Symbolicons/Warning";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

export type Color = "red" | "black" | "grey" | "blue";
type Icon = "info" | "warning" | "none";

interface Props {
  message: string | JSX.Element;
  color: Color;
  icon: Icon;
  testId?: string;
  className?: string;
  withBorder?: boolean;
}

const getColorFromTheme = (color: Color) => {
  switch (color) {
    case "red":
      return theme.colors.red700;
    case "black":
      return theme.colors.black700;
    case "blue":
      return theme.colors.blue700;
    default:
      return theme.colors.grey700;
  }
};

const InfoMessage = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <InfoMessageContainer
      color={getColorFromTheme(props.color)}
      className={props.className}
      withBorder={props.withBorder}
    >
      {props.icon === "info" && (
        <Info
          size={24}
          height={24}
          variant="line"
          color={getColorFromTheme(props.color)}
        />
      )}
      {props.icon === "warning" && (
        <Warning
          size={24}
          height={24}
          variant="solid"
          color={theme.colors.red700}
        />
      )}
      {props.message}
    </InfoMessageContainer>
  </TestWrapper>
);

InfoMessage.displayName = "InfoMessage";

InfoMessage.defaultProps = {
  color: "grey",
  icon: "info",
};

export { InfoMessage };

const InfoMessageContainer = styled.div<{
  color: string;
  withBorder?: boolean;
}>`
  display: flex;
  align-items: self-start;
  color: ${props => props.color};
  font-size: ${props => props.theme.fonts.sizes.body2};

  svg {
    flex-shrink: 0;
    margin-right: ${props => props.theme.margins.base};
    margin-left: -${props => props.theme.margins.half};
  }

  ${props =>
    props.withBorder &&
    css`
      border: 1px solid ${props.theme.colors.greyDark};
      border-radius: ${props.theme.border.radius.base};
      padding: ${props.theme.margins.base};
    `}
`;
