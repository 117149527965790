import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const CompilantLanding = (props: Icon): React.ReactElement => (
  <svg
    viewBox="0 0 104 104"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <defs>
      <path id="compilant_landing_a" d="M0 0h34.454v91.116H0z" />
      <path id="compilant_landing_c" d="M.44.727h58.03V59H.44z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="compilant_landing_b" fill="#fff">
        <use xlinkHref="#compilant_landing_a" />
      </mask>
      <path
        d="M33.823 91.116l-27.06-.004c-.327-.39-.721-.416-1.156-.218-3.522-.91-5.582-3.346-5.586-6.936a35747.47 35747.47 0 010-76.434C.025 3.564 2.594.28 6.346.2c9.337-.2 18.683-.36 28.023.084l-.04 32.319H13.183v6.485H34.3c-.119 2.205.616 4.473-.473 6.608l-20.29.01c-.305-.305-.439-.205-.446.193v6.329h21.224l.015 6.274H13.074v6.3c.025.389.147.54.474.198h20.277c1.122 2.134.38 4.404.476 6.613H13.205v6.527h21.101c.025 2.997.1 5.995.05 8.99-.021 1.335.444 2.77-.533 3.986"
        fill="#060608"
        mask="url(#compilant_landing_b)"
      />
      <g transform="translate(44.571 45)">
        <mask id="compilant_landing_d" fill="#fff">
          <use xlinkHref="#compilant_landing_c" />
        </mask>
        <path
          d="M26.463 36.357c-.225-.092-.318-.104-.37-.156-2.63-2.63-5.245-5.276-7.886-7.894-1.95-1.933-3.784-2.157-5.264-.695-1.482 1.463-1.295 3.412.556 5.295 3.178 3.231 6.365 6.453 9.567 9.66 2.51 2.511 3.943 2.494 6.523-.085 3.319-3.319 6.612-6.664 9.914-9.999 2.734-2.76 5.483-5.504 8.188-8.293.874-.902 1.445-1.979.904-3.31-.528-1.295-1.46-2.184-2.836-2.383-1.437-.208-2.383.764-3.304 1.696C37.11 25.598 31.764 31 26.463 36.357M29.195.753C46.128.333 58.42 14.388 58.471 29.967 58.522 45.892 45.322 59.017 29.395 59 13.16 58.982.419 45.777.44 29.06.458 14.918 13.66.018 29.195.753"
          fill="#61C1E0"
          mask="url(#compilant_landing_d)"
        />
      </g>
      <path
        d="M51.756 7.744c0 4.67-.154 9.594.071 14.5.098 2.134 1.81 3.652 3.89 3.743 4.774.206 9.563.065 14.37.065L51.756 7.744zM34.328 32.603c.014-10.773.028-21.546.04-32.318C39.692.26 45.014.255 50.334.192c.99-.012 1.638.394 2.3 1.067a10949.15 10949.15 0 0023.415 23.69c.708.715 1.274 1.47 1.275 2.53.002 3.81.001 7.62.001 11.665-3.412.005-6.68.05-9.916.663a34.142 34.142 0 00-11.846 4.654c-1.368.857-2.73 1.225-4.321 1.214-5.804-.04-11.609.007-17.415.02 1.09-2.134.356-4.402.476-6.607h29.916v-6.485h-29.89z"
        fill="#504F4F"
      />
      <path
        d="M33.823 91.116c.977-1.216.512-2.651.534-3.986.048-2.995-.026-5.993-.051-8.99h4.387c.79 4.238 1.832 8.478 3.757 12.976h-8.627z"
        fill="#545353"
      />
      <path
        d="M34.33 58.502l-.015-6.274H46.46c-1.22 1.981-2.34 3.801-3.463 5.621-.454.737-1.18.73-1.917.72-2.25-.028-4.501-.046-6.751-.067"
        fill="#4E4E4D"
      />
      <path
        d="M34.301 71.612c-.097-2.209.645-4.478-.476-6.612h5.854c.129 2.403-.568 4.445-.961 6.612h-4.417z"
        fill="#565556"
      />
      <path
        d="M5.607 90.894c.435-.198.829-.17 1.156.218-.431.168-.828.163-1.156-.218"
        fill="#545353"
      />
      <path
        d="M13.091 45.9c.007-.399.141-.5.446-.193l-.446.192"
        fill="#504F4F"
      />
      <path
        d="M13.548 65c-.327.343-.449.19-.475-.198l.475.198"
        fill="#565556"
      />
    </g>
  </svg>
);
