import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Setting = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        clipRule="evenodd"
        d="M15.55 3.119h0a2.02 2.02 0 0 1 1.382 1.917v.055c0 1.099.878 1.997 1.977 2.02l.118.003A2.02 2.02 0 0 1 20.79 8.23v0a2.02 2.02 0 0 1-.378 2.333l-.04.039a2.02 2.02 0 0 0-.03 2.826l.082.086a2.02 2.02 0 0 1 .457 2.036v0a2.02 2.02 0 0 1-1.917 1.382h-.055a2.02 2.02 0 0 0-2.02 1.976l-.003.119a2.02 2.02 0 0 1-1.116 1.763v0a2.02 2.02 0 0 1-2.333-.379l-.039-.038a2.02 2.02 0 0 0-2.826-.031l-.086.081a2.02 2.02 0 0 1-2.036.458v0a2.02 2.02 0 0 1-1.382-1.917v-.055a2.02 2.02 0 0 0-1.977-2.02l-.118-.003a2.02 2.02 0 0 1-1.763-1.117v0a2.02 2.02 0 0 1 .378-2.332l.04-.039a2.02 2.02 0 0 0 .03-2.827l-.082-.085A2.02 2.02 0 0 1 3.12 8.45v0a2.02 2.02 0 0 1 1.917-1.382h.055a2.02 2.02 0 0 0 2.02-1.977l.003-.118A2.02 2.02 0 0 1 8.23 3.209v0a2.02 2.02 0 0 1 2.333.38l.039.038a2.02 2.02 0 0 0 2.826.03l.086-.08a2.02 2.02 0 0 1 2.036-.458Z"
      />
      <circle cx={12} cy={12} r={2.501} />
    </svg>
  );
};
