import { Map, Record } from "immutable";
import { AnyAction } from "redux";

import { ModalRecord, ModalReducer, MODAL_STATE } from "@typings/modals";

import { DESTROY, HIDE, SHOW } from "./modals.actions";

const ModalFactory = Record<ModalRecord>({
  state: MODAL_STATE.HIDDEN,
  options: {},
  autohide: false,
});

const modalsReducer = (store: ModalReducer = Map(), action: AnyAction) => {
  const { type, payload } = action;

  switch (type) {
    case SHOW: {
      const { id } = payload;
      return store.set(
        id,
        ModalFactory({
          autohide: payload.autohide,
          state: MODAL_STATE.VISIBLE,
          options: payload.options || {},
        }),
      );
    }

    case HIDE: {
      const id: string = typeof payload === "string" ? payload : payload.id;
      return store.set(id, ModalFactory({ state: MODAL_STATE.HIDDEN }));
    }

    case DESTROY: {
      const id: string = typeof payload === "string" ? payload : payload.id;
      return store.remove(id);
    }

    default: {
      return store;
    }
  }
};

export { modalsReducer };
