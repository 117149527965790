import * as React from "react";

const usePrevious = (value: any) => {
  const ref = React.useRef(value);
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { usePrevious };
