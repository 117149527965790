import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Caret = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={props.className}
    >
      <defs>
        <path
          d="M16.1649489,9.56099691 C16.4074036,9.28390582 16.8285787,9.25582748 17.1056698,9.49828219 C17.3614462,9.72208653 17.405046,10.0981733 17.219543,10.3726482 L17.1683845,10.4390031 L12.5017178,15.7723364 C12.2550822,16.0542056 11.8302957,16.0743392 11.55821,15.832737 L11.4982822,15.7723364 L6.8316155,10.4390031 C6.5891608,10.161912 6.61723914,9.74073689 6.89433023,9.49828219 C7.15010662,9.27447785 7.5286528,9.28118687 7.7760744,9.50148296 L7.8350511,9.56099691 L12,14.3206667 L16.1649489,9.56099691 Z"
          id="path-1-carret-line"
        ></path>
      </defs>
      <g
        id="icon-24x24px[16x16px]---line&amp;solid"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atom/icon/controls/caret-down/line">
          <mask id="mask-2-carret-line" fill="white">
            <use xlinkHref="#path-1-carret-line"></use>
          </mask>
          <use
            id="Atom/icon/caret-down"
            fill={props.color || theme.colors.grey700}
            fillRule="nonzero"
            xlinkHref="#path-1-carret-line"
          ></use>
          <g
            id="Group"
            mask="url(#mask-2-carret-line)"
            fill={props.color || theme.colors.grey700}
            fillOpacity="0"
          >
            <g id="🎨-color">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <defs>
        <path
          d="M7.05666664,10.9786667 L12,16 L16.9433334,10.9786667 C17.4626667,10.4506667 17.4626667,9.59133332 16.9433334,9.06333331 C16.424,8.53533331 15.5773334,8.53533331 15.0573334,9.06333331 L12,12.1693333 L8.94199998,9.06333331 C8.68999998,8.80733331 8.35533331,8.66666665 7.99933331,8.66666665 C7.64333331,8.66666665 7.30866664,8.80733331 7.05666664,9.06333331 C6.53666663,9.59133332 6.53666663,10.4513333 7.05666664,10.9786667 Z"
          id="path-1-carret"
        ></path>
      </defs>
      <g
        id="icon-24x24px[16x16px]---line&amp;solid"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Atom/icon/controls/caret-down/solid">
          <mask id="mask-2-carret" fill="white">
            <use xlinkHref="#path-1-carret"></use>
          </mask>
          <use
            id="Atom/icon/caret-down"
            fill={props.color || theme.colors.grey700}
            fillRule="nonzero"
            xlinkHref="#path-1-carret"
          ></use>
          <g
            id="Group"
            mask="url(#mask-2-carret)"
            fill={props.color || theme.colors.grey700}
            fillOpacity="0"
          >
            <g id="🎨-color">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
