import styled from "styled-components";

import { Paragraph, Heading, Column } from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { StyledSection } from "../Homepage/";

export const Description = styled(Paragraph)`
  margin-bottom: ${props => props.theme.margins.base_x7};
  font-size: ${props => props.theme.fonts.sizes.body};
  color: ${props => props.theme.colors.grey700};
  text-align: left;

  ${props => props.theme.breakpoints.desktop} {
    text-align: center;
    margin-bottom: ${props => props.theme.margins.base_x9};
  }
`;

export const StyledHeading = styled(Heading)`
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin: ${props => props.theme.margins.base_x3} 0;
  font-size: ${props => props.theme.fonts.sizes.h4};
  line-height: ${props => props.theme.fonts.lineHeights.h4};
  text-align: left;

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.fonts.sizes.h3};
    line-height: ${props => props.theme.fonts.lineHeights.h2};
    text-align: center;
  }
`;

export const Section = styled(StyledSection)`
  padding: ${props => props.theme.margins.base_x2} 0
    ${props => props.theme.margins.base_x8};

  ${props => props.theme.breakpoints.tablet} {
    padding: ${props => props.theme.margins.base_x4} 0
      ${props => props.theme.margins.base_x8};
  }

  ${props => props.theme.breakpoints.desktop} {
    padding: ${rem(96)} 0 ${rem(128)};
  }
`;

export const StyledColumn = styled(Column)`
  ${props => props.theme.breakpoints.upToTablet} {
    padding: 0 ${props => props.theme.margins.base_x3};
  }
`;
