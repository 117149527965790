import styled, { css } from "styled-components";

import { transition, rem } from "@ui/helpers";

import DropdownHeader, { DropdownHeaderProps } from "./DropdownHeader";

interface DropdownItemProps extends DropdownHeaderProps {
  active?: boolean;
  color?: string;
  href?: string;
  inline?: boolean;
  style?: any;
  disabled?: boolean;
  withBorder?: boolean;
  withIcon?: boolean;
  paddingVariant?: "normal" | "big";
}
const DropdownItem = styled(DropdownHeader)<DropdownItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: ${props => props.active && props.theme.fonts.weights.bold};
  text-align: left;
  line-height: ${props => props.theme.fonts.lineHeights.normal};

  width: ${props => (props.inline ? "auto" : "100%")};
  max-height: ${rem(48)};
  min-height: ${rem(40)};

  background: ${props =>
    props.active ? props.theme.colors.whiteHover : props.theme.colors.white};
  color: ${props => props.color || props.theme.colors.black};
  border: 0;

  padding: ${props => props.theme.margins.base}
    ${props => props.theme.margins.base_x2};

  transition: ${transition(["background", "color"])};

  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

  ${props =>
    props.paddingVariant === "big" &&
    css`
      padding: ${props => props.theme.margins.base}
        ${props => props.theme.margins.base_x3};
    `}

  ${props =>
    props.active &&
    `
    pointer-events: none;
  `}

  ${props =>
    props.withBorder &&
    css`
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: ${props => props.theme.margins.base_x2};
        border-bottom: 1px solid ${props => props.theme.colors.greyDark};
        width: ${props => `calc(100% - ${props.theme.margins.base_x4})`};
      }
    `}

  ${props =>
    props.withIcon &&
    css`
      justify-content: space-between;
      padding: ${props => props.theme.margins.base_x2};
    `}

  &:hover {
    ${props =>
      !props.disabled &&
      `
        background: ${props.theme.colors.whiteHover};
        color: ${props.theme.colors.blue400};

        & > a {
          color: ${props.theme.colors.blue400};
        }
      `}
  }

  & > a {
    color: ${props => props.color || props.theme.colors.black};
  }
`;

export default DropdownItem;
