import React, { useCallback, useEffect } from "react";
import { Formik, FormikProps, FormikValues } from "formik";

import { LOADING_STATE } from "@typings/reduxThunkActions";
import { AVAILABLE_LANGS, SERVICE_TYPE } from "@typings/globals";
import { MODALS } from "@typings/modals";

import { SelectOption } from "@ui/Atoms/Form/SelectExtended";
import { Variant } from "@ui/Atoms/Button/Button";

import { getQueryValue } from "@services/QueryParamsParser";

import { useTelemetry } from "@hooks/useTelemetry";
import { useModalManager } from "@hooks/modal/useModalManager";
import { useTranslate } from "@hooks/useTranslate";

import {
  FIELDS,
  handleSubmit,
  initialValues,
  getValidationSchema,
} from "./StartBookingForm.helpers";
import { StartBookingFormView } from "./StartBookingFormView";

interface Props {
  className?: string;
  language: AVAILABLE_LANGS;
  emailSubmit: string;
  emailPlaceholder: string;
  buttonVariant?: Variant;
  inputIcon?: React.ReactElement;
  autoFocus?: boolean;
  onSuccess: (url: string) => void;
  isHorizontal?: boolean;
  isZipCodeUnknownVisible?: boolean;
  serviceType: SERVICE_TYPE;
}

const StartBookingForm = (props: Props): React.ReactElement => {
  const [uncoveredServiceType, setUncoveredServiceType] = React.useState("");

  const { sendEvent } = useTelemetry();
  const { showModal } = useModalManager();
  const translate = useTranslate();

  // zip suggestion part
  const [zipSuggestionsFetchingState, setZipSuggestionsFetchingState] =
    React.useState<LOADING_STATE>(LOADING_STATE.IDLE);
  const [pendingZipSubmission, setPendingZipSubmission] = React.useState("");
  const [zipSuggestions, setZipSuggestions] = React.useState<SelectOption[]>(
    [],
  );

  const showUncoveredLocationModal = (uncoveredCode: string) => {
    sendEvent("landing_submit_zip", {
      context: {
        zipCovered: false,
        zip: uncoveredCode,
      },
    });

    showModal(MODALS.UNCOVERED_CODE_SUBSCRIPTION, {
      serviceType: uncoveredServiceType,
      uncoveredCode,
    });
  };

  const handleSubmitSuccess = async (url: string, zip: string) => {
    await sendEvent("landing_submit_zip", {
      context: {
        zipCovered: true,
        zip,
      },
    });

    props.onSuccess(url);
  };

  const onSubmit = useCallback(
    (zip: string) =>
      handleSubmit({
        zip,
        onSuccess: handleSubmitSuccess,
        showUncoveredLocationModal,
        language: props.language,
        serviceType: props.serviceType,
        hasSuggestions: zipSuggestions.length > 0,
        zipSuggestionsFetchingState,
      }),
    [
      zipSuggestions.length,
      props.serviceType,
      zipSuggestionsFetchingState,
      props.language,
    ],
  );

  useEffect(() => {
    if (
      pendingZipSubmission &&
      zipSuggestionsFetchingState === LOADING_STATE.SUCCEEDED
    ) {
      onSubmit(pendingZipSubmission);
      setPendingZipSubmission("");
    }
  }, [pendingZipSubmission, zipSuggestionsFetchingState, onSubmit]);

  useEffect(() => {
    // uncovered part
    const uncoveredZip =
      getQueryValue(window.location.href, "uncoveredZip") || "";
    const uncoveredServiceTypeValue =
      getQueryValue(window.location.href, "uncoveredServiceType") ||
      props.serviceType;

    uncoveredServiceTypeValue &&
      setUncoveredServiceType(uncoveredServiceTypeValue);

    if (uncoveredZip && uncoveredServiceType) {
      showModal(MODALS.UNCOVERED_CODE_SUBSCRIPTION, {
        uncoveredCode: uncoveredZip,
        serviceType: uncoveredServiceTypeValue,
      });
    }
  }, []);

  return (
    <Formik
      onSubmit={values => {
        const zip = values[FIELDS.ZIPCODE];

        // when we don't have zip suggestions
        if (zipSuggestionsFetchingState !== LOADING_STATE.SUCCEEDED) {
          setPendingZipSubmission(zip);
          return Promise.resolve();
        }

        return onSubmit(zip);
      }}
      initialValues={initialValues}
      validateOnBlur
      validationSchema={getValidationSchema(translate)}
    >
      {(formikProps: FormikProps<FormikValues>) => (
        <StartBookingFormView
          className={props.className}
          formikProps={formikProps}
          serviceType={props.serviceType}
          emailSubmit={props.emailSubmit}
          emailPlaceholder={props.emailPlaceholder}
          buttonVariant={props.buttonVariant}
          zipSuggestions={zipSuggestions}
          loadingSuggestionsState={zipSuggestionsFetchingState}
          isZipCodeUnknownVisible={props.isZipCodeUnknownVisible}
          setZipSuggestions={setZipSuggestions}
          setLoadingSuggestionsState={setZipSuggestionsFetchingState}
        />
      )}
    </Formik>
  );
};

export { StartBookingForm };
