import styled from "styled-components";

import { rem } from "@ui/helpers";
import { Section } from "@ui/Atoms";

export const NewCleaningWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  justify-content: center;
  background: ${props => props.backgroundColor || props.theme.colors.black700};
  padding: ${props => props.theme.margins.base_x2};
  margin: ${props =>
    `-${props.theme.margins.base_x4} 0 ${props.theme.margins.base_x4}`};
`;

export const AboutSection = styled(Section)`
  div {
    > p:nth-last-child(2) {
      text-align: center;

      img {
        margin-top: ${props => props.theme.margins.base_x5};
        width: ${rem(90)};
      }
    }

    > p:last-child {
      text-align: center;
      margin-bottom: ${props => props.theme.margins.base_x5};
    }
  }
`;
