export enum BOOKING_TERMS {
  WRAPPER = "bookingTermsWrapper",
  LABEL = "bookingTermsLabel",
}

export enum BOOKING_MARKETING_CONSENT {
  WRAPPER = "bookingMarketingConsentWrapper",
  LABEL = "bookingMarketingConsentLabel",
}

export enum CLEANING_INFORMATION {
  // Booking Types
  HOME_CLEANING_TYPE = "bookingHomeCleaningTypeButton",
  END_OF_TENANCY_TYPE = "bookingEndOfTenancyTypeButton",
  B2B_TYPE = "bookingB2BTypeButton",
  DURATION = "bookingDuration",
  PRICE_INFO_MESSAGE = "bookingPriceInfoMessage",
  CALCULATE_TIME = "bookingCalculateTime",
  PETS_RADIO_NO = "bookingPetsRadioNoButton",
  PETS_RADIO_YES = "bookingPetsRadioYesButton",

  //CHECKOUT
  SUPPLIES_CONFIRM = "bookingMigrosSuppliesCheckbox",
  SUPPLIES_CONFIRM_REQUIRED_ERROR = "bookingMigrosSuppliesRequiredError",
  CHECKOUT = "bookingCheckoutButton",
  MINIMUM_DURATION_TOAST = "bookingMinimumDurationToast",
  MAXIMUM_DURATION_TOAST = "bookingMaximumDurationToast",
}

export enum BOOKING_EXTRAS {
  INTERIOR_WINDOWS = "bookingWindowsExtra",
  INTERIOR_WINDOWS_MODAL = "interiorWindowsCleaningModal",
  INTERIOR_WINDOWS_MODAL_CONTENT = "bookingWindowsExtraModal",
  IRONING = "bookingIroningExtra",
  IRONING_MODAL = "ironingServiceModal",
  IRONING_MODAL_CONTENT = "bookingIroningExtraModal",
  LAUNDRY_WASH_AND_DRY = "bookingLaundryWashExtra",
  INSIDE_OVEN = "bookingOvenExtra",
  INSIDE_CABINETS = "bookingCabinetsExtra",
  INSIDE_FRIDGE = "bookingFridgeExtra",
  BONUS_15 = "bookingBonus15MinutesExtra",
}

export enum BOOKING_SUMMARY {
  WRAPPER = "bookingSummaryWrapper",
  CLEANING_TYPE = "bookingSummaryCleaningType",
  BEDROOMS = "bookingSummaryBedrooms",
  BATHROOMS = "bookingSummaryBathrooms",
  DURATION = "bookingSummaryDuration",
  DATE_AND_TIME = "bookingSummaryDateAndTime",
  FREQUENCY = "bookingSummaryFrequency",
  PLAN = "bookingSummaryPlan",
  PRICE = "bookingSummaryPrice",
  VOUCHERS = "bookingSummaryVouchers",
  SECOND_PRICE = "bookingSummarySecondPrice",
  CLEANING_PRODUCTS = "bookingSummaryCleaningProducts",
  BOTTOMSHEET_BAR = "summaryBottomSheetBar",
  DISCLAIMER = "bookingSummaryDisclaimer",
  TOOLTIP = "bookingSummaryTooltip",
  CAPTION = "bookingSummaryCaption",
  DESCRIPTION = "bookingSummaryDescription",
}

export enum BOOKING_SUMMARY_EXTRAS {
  INTERIOR_WINDOWS = "summaryWindowsExtra",
  IRONING = "summaryIroningExtra",
  LAUNDRY_WASH_AND_DRY = "summaryLaundryWashExtra",
  INSIDE_OVEN = "summaryOvenExtra",
  INSIDE_CABINETS = "summaryCabinetsExtra",
  INSIDE_FRIDGE = "summaryFridgeExtra",
  BONUS_15 = "summaryBonus15MinutesExtra",
}

export enum PERSONAL_INFORMATION {
  EMAIL = "bookingEmailField",
  PASSWORD = "bookingPasswordField",
  CONFIRM_PASSWORD = "bookingConfirmPasswordField",
  FIRST_NAME = "bookingFirstNameField",
  LAST_NAME = "bookingLastNameField",
  ADDRESS = "bookingAddressField",
  NEW_ADDRESS = "bookingNewAddressField",
  FLOOR_AND_DOOR = "bookingFloorAndDoorField",
  NEW_FLOOR_AND_DOOR = "bookingNewFloorAndDoorField",
  ENTRY_CODE = "bookingEntryCodeField",
  NEW_ENTRY_CODE = "bookingNewEntryCodeField",
  CITY = "bookingCityField",
  PHONE_NUMBER = "bookingPhoneNumberField",
  ADD_NEW_ADDRESS = "bookingAddNewAddressButton",
  SPECIAL_INSTRUCTIONS = "bookingSpecialInstructions",
  NOT_LOGGED_USER_WRAPPER = "bookingPersonalInformationNotLoggedUser",
  LOGGED_USER_WRAPPER = "bookingPersonalInformationLoggedUser",
  LOG_IN_RETURNING_CUSTOMER_LINK = "bookingPersonalInformationLogInReturningCustomer",
  OPEN_NEW_ADDRESS_FORM_BUTTON = "bookingPersonalInformationOpenNewAddressFormButton",
  ADD_NEW_ADDRESS_FORM = "bookingPersonalInformationAddNewAddressForm",
}

export enum PERSONAL_INFORMATION_ERRORS {
  EMAIL_REQUIRED_ERROR = "bookingEmailFieldRequiredError",
  PASSWORD_REQUIRED_ERROR = "bookingPasswordFieldRequiredError",
  CONFIRM_PASSWORD_REQUIRED_ERROR = "bookingConfirmPasswordFieldRequiredError",
  CONTACT_FIRST_NAME_REQUIRED_ERROR = "bookingFirstNameFieldRequiredERror",
  LAST_NAME_REQUIRED_ERROR = "bookingLastNameFieldRequirdError",
  ADDRESS_REQUIRED_ERROR = "bookingAddressFieldRequiredError",
  PHONE_NUMBER_REQUIRED_ERROR = "bookingPhoneNumberFieldRequiredError",
}

export enum CHECKOUT {
  PROMO_CODE = "bookingVoucherField",
  VOUCHER_APPLY = "bookingVoucherApplyButton",
  MARKETING_CONSENT = "bookingMarketingConsentCheckbox",
  CONFIRM_BOOKING = "bookingConfirmBookingButton",
  PAYMENT_WRAPPER = "paymentMethodsWrapper",
  TERMS_OF_USE = "bookingTermsOfUseText",
}

export enum PAYMENT_METHODS {
  CLIENT_PAYS_LATER = "CLIENT_PAYS_LATER",
  CLIENT_PAY_FIRST = "CLIENT_PAY_FIRST",
  NEW_CREDIT_CARD = "NEW_CREDIT_CARD",
  EXISTING_CREDIT_CARD = "EXISTING_CREDIT_CARD",
  PAYPAL = "PAYPAL",
  POST_FINANCE = "POST_FINANCE",
  APPLE_PAY = "APPLE_PAY",
  TWINT = "TWINT",
  GOOGLE_PAY = "GOOGLE_PAY",
  MONTHLY_INVOICE = "MONTHLY_INVOICE",
}

export enum BOOKING_MORE_OFTEN_CALENDAR {
  DATE_PICKER = "moreOftenDatePicker",
  MONTH_DROPDOWN = "moreOftenCalendarMonthDropdown",
  YEAR_DROPDOWN = "moreOftenCalendarYearDropdown",
}

export const BOOKING_CLEANING_SUPPLIES_CONFIRM = "cleaningSuppliesConfirm";

export enum BOOKING_THANK_YOU {
  SETUP_CHECKLIST = "bookingThankYouSetupChecklist",
  SKIP_CHECKLIST = "bookingThankYouSkipChecklist",
}
