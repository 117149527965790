export enum USER_NOTIFICATIONS_TEST_IDS {
  PENDING_PAYMENTS_ALERT = "pendingPaymentsAlert",
  TM2020_SIGNED = "trustMandate2020Signed",
  END_OF_TENANCY_BOOKING_CONFIRMED = "endOfTenancyBookingConfirmed",
  CANNOT_SKIP_CLEANING = "cannotSkipCleaning",
  B2B_INVOICE_PAYMENT = "b2bInvoicePayment",
  NEW_B2B_BOOKING_WITH_NON_STANDARD_FREQUENCY = "newB2BBookingWithNonStandardFrequency",
  NEW_BOOKING_WITH_MORE_OFTEN_FREQUENCY = "newBookingWithMoreOftenFrequency",
  NEW_DRY_BOOKING = "newDryBooking",
  VOUCHER_APPLIED = "voucherApplied",
  CLEANING_ALREADY_RATED = "cleaningAlreadyRated",
}
