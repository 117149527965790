import { createSelector } from "reselect";
import { List, Record } from "immutable";

import { IStore } from "@soft/redux/reducers";
import {
  Job,
  JobLocation,
  JOB_BATCH,
  UI_STATE_ENUM,
  TYPE_ENUM,
} from "@typings/jobs";

const sortJobs = (showOnlyUpcoming: boolean) => (
  a: Record<Job>,
  b: Record<Job>,
) => {
  const aDate = new Date(a.get("executionDate")).getTime();
  const bDate = new Date(b.get("executionDate")).getTime();

  if (aDate > bDate) {
    return showOnlyUpcoming ? 1 : -1;
  }

  if (aDate < bDate) {
    return showOnlyUpcoming ? -1 : 1;
  }

  return 0;
};

const sortLocations = (
  a: Record<JobLocation> | string,
  b: Record<JobLocation> | string,
): number => {
  const aLocation = (Record.isRecord(a) ? a.get("location") : a).toLowerCase();
  const bLocation = (Record.isRecord(b) ? b.get("location") : b).toLowerCase();

  if (aLocation < bLocation) {
    return -1;
  }

  if (aLocation > bLocation) {
    return 1;
  }

  return 0;
};

const getAllJobsFunction = (store: IStore) => store.jobs.get("jobs");
export const getAllJobs = createSelector(getAllJobsFunction, f => f);

const getAllLocationsFunction = (store: IStore) =>
  store.jobs.get("locations").sort(sortLocations);
export const getAllLocations = createSelector(getAllLocationsFunction, f => f);

const getJobFunction = (store: IStore, job: string) =>
  store.jobs.getIn(["jobs", job]);
export const getJob = createSelector(getJobFunction, f => f);

const getOnlyLocationsFunction = (store: IStore) => {
  return store.jobs
    .get("locations")
    .sort(sortLocations)
    .toList();
};
export const getOnlyLocations = createSelector(
  getOnlyLocationsFunction,
  f => f,
);

const getBatchByLocationFunction = (
  store: IStore,
  location: string,
  batchType: JOB_BATCH,
) => store.jobs.getIn(["locations", location, batchType]);

export const getBatchByLocation = createSelector(
  getBatchByLocationFunction,
  f => f,
);

const getLocationFunction = (store: IStore, location: string) =>
  store.jobs.getIn(["locations", location]);

export const getLocation = createSelector(getLocationFunction, f => f);

const getJobsFromLocationFunction = (store: IStore, location: string) =>
  store.jobs.getIn(["locations", location]);
export const getJobsFromLocation = createSelector(
  getJobsFromLocationFunction,
  f => f,
);

export const jobFinishTime = (item: Record<Job>) => {
  const execDate = new Date(item.get("executionDate"));
  const duration = Number(item.get("duration"));

  return execDate.setHours(execDate.getHours() + duration);
};

const isJobUpcoming = (item: Record<Job>): boolean => {
  return jobFinishTime(item) > new Date().getTime();
};

const isJobPrevious = (item: Record<Job>): boolean => {
  return jobFinishTime(item) < new Date().getTime();
};

export const isJobCurrentlyActive = (item: Record<Job>) => {
  const minute = 360000;
  const offset = Math.abs(new Date().getTimezoneOffset()) * minute;
  /**
   * We need to add "Z" to "convert" (or make it behave) like Zulu time.
   * After that, we convert it to microtime and adjust to the timezone offset.
   * This had to be done like this due to Safari's interpretation of Date object.
   * Other browsers are totally fine with adjusting these differences themselves.
   */
  const start = new Date(`${item.get("executionDate")}Z`).getTime() + offset;
  const finish = jobFinishTime(item);
  const current = new Date().getTime();
  const isNotCancelled = item.get("uiState") !== UI_STATE_ENUM.CANCELLED;

  return isNotCancelled && start <= current && current < finish;
};

export const filterJobsByState = (
  jobs: List<Record<Job>>,
  showOnlyUpcoming = true,
  filterUpcomingByDate?: boolean,
): List<Record<Job>> => {
  return jobs?.sort(sortJobs(showOnlyUpcoming)).filter((item: Record<Job>) => {
    if (filterUpcomingByDate) {
      const isExecutionUpcoming = isJobUpcoming(item);

      return showOnlyUpcoming ? isExecutionUpcoming : !isExecutionUpcoming;
    }

    const isUpcoming = item.get("uiState") === UI_STATE_ENUM.UPCOMING;

    return showOnlyUpcoming ? isUpcoming : !isUpcoming;
  });
};

const getJobsByLocationAndStateFunction = (
  store: IStore,
  location: string,
  upcoming: boolean,
  filterUpcomingByDate?: boolean,
) => {
  return filterJobsByState(
    store.jobs
      .getIn(["locations", location, "jobs"])
      ?.map((item: string) => getJob(store, item)),
    upcoming,
    filterUpcomingByDate,
  );
};
export const getJobsByLocationAndState = createSelector(
  getJobsByLocationAndStateFunction,
  f => f,
);

const getAllJobsByStateFunction = (
  store: IStore,
  upcoming: boolean,
  filterUpcomingByDate?: boolean,
) =>
  filterJobsByState(
    store.jobs.get("jobs").toList(),
    upcoming,
    filterUpcomingByDate,
  ).sort(sortJobs(upcoming));
export const getAllJobsByState = createSelector(
  getAllJobsByStateFunction,
  f => f,
);

const countAllJobsByStateFunction = (
  store: IStore,
  upcoming: boolean,
): number => {
  return getAllJobsByState(store, upcoming, true).size;
};
export const countAllJobsByState = createSelector(
  countAllJobsByStateFunction,
  f => f,
);

const hasUpcomingHomeCleaningJobsFunction = (store: IStore): boolean => {
  const upcomingJobs = getAllJobsByState(store, true, true);

  return Boolean(
    upcomingJobs.find(
      (upcomingJob: Record<Job>) =>
        upcomingJob.get("type") !== TYPE_ENUM.END_OF_TENANCY,
    ),
  );
};
export const hasUpcomingHomeCleaningJobs = createSelector(
  hasUpcomingHomeCleaningJobsFunction,
  f => f,
);

const getCancellationReasonsFunction = (store: IStore, jobId: string) =>
  store.jobs.getIn(["jobs", jobId, "cancellationProcess", "reasons"]);
export const getCancellationReasons = createSelector(
  getCancellationReasonsFunction,
  f => f,
);

const getCancellationUuidFunction = (store: IStore, jobId: string) =>
  store.jobs.getIn([
    "jobs",
    jobId,
    "cancellationProcess",
    "cancellationRequestUuid",
  ]);
export const getCancellationUuid = createSelector(
  getCancellationUuidFunction,
  f => f,
);

const getCancellationStateFunction = (store: IStore, jobId: string) =>
  store.jobs.getIn(["jobs", jobId, "cancellationState"]);
export const getCancellationState = createSelector(
  getCancellationStateFunction,
  f => f,
);

const getCancellationProgressFunction = (store: IStore, jobId: string) =>
  store.jobs.getIn(["jobs", jobId, "cancellationProcess"]);
export const getCancellationProgress = createSelector(
  getCancellationProgressFunction,
  f => f,
);

const checkIfJobExistsFunction = (store: IStore, id: string): boolean => {
  return typeof store.jobs.getIn(["jobs", id]) !== "undefined";
};
export const checkIfJobExists = createSelector(
  checkIfJobExistsFunction,
  f => f,
);

const hasAnyJobsFunction = (store: IStore): boolean =>
  store.jobs.get("jobs").size > 0;
export const hasAnyJobs = createSelector(hasAnyJobsFunction, f => f);

const isInHolidayAutomationFunction = (store: IStore): boolean => {
  const jobs = store.jobs.get("jobs");

  return Boolean(
    jobs.find(
      (item: Record<Job>) =>
        item.get("uiState") === UI_STATE_ENUM.HOLIDAYS_PENDING ||
        item.get("uiState") === UI_STATE_ENUM.REPLACEMENT_PENDING,
    ),
  );
};
export const isInHolidayAutomation = createSelector(
  isInHolidayAutomationFunction,
  f => f,
);

const hasPendingHolidaysFunction = (store: IStore): boolean => {
  const jobs = store.jobs.get("jobs");

  return Boolean(
    jobs.find(
      (item: Record<Job>) =>
        item.get("uiState") === UI_STATE_ENUM.HOLIDAYS_PENDING,
    ),
  );
};
export const hasPendingHolidays = createSelector(
  hasPendingHolidaysFunction,
  f => f,
);

const hasAnyUpcomingJobsFunction = (store: IStore): boolean =>
  store.jobs.get("jobs").filter(isJobUpcoming).size > 0;
export const hasAnyUpcomingJobs = createSelector(
  hasAnyUpcomingJobsFunction,
  f => f,
);

const hasAnyPreviousJobsFunction = (store: IStore): boolean =>
  store.jobs.get("jobs").filter(isJobPrevious).size > 0;
export const hasAnyPreviousJobs = createSelector(
  hasAnyPreviousJobsFunction,
  f => f,
);
