import { ITheme } from "@ui/themes/ThemeInterface";
import * as React from "react";
import styled from "styled-components";

export interface DropdownHeaderProps {
  children: React.ReactNode;
  theme: ITheme;
}

const DropdownHeader = styled.div<DropdownHeaderProps>`
  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props => props.theme.fonts.weights.normal};
  color: ${props => props.theme.colors.grey700};

  width: 100%;
  margin: 0;
  padding: ${props =>
    `${props.theme.margins.base} ${props.theme.margins.base_x2} ${props.theme.margins.base_x2}`};

  display: block;
`;

export default DropdownHeader;
