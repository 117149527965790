import React from "react";

import {
  GIFT_CARDS_LINK,
  HELP_LINK_BATWORK,
  BECOME_A_BATWORKER_LINK,
  BATMAID_LINK,
  BATSOFT_LINK,
} from "@config/links";
import { EXCLUDED_LANGS_BATWORK } from "@config/consts";

import { AVAILABLE_LANGS } from "@typings/globals";

import theme from "@ui/themes/default";
import { UserOff } from "@ui/Assets/Symbolicons/UserOff";
import { PowerOff } from "@ui/Assets/Symbolicons/PowerOff";
import { Bell } from "@ui/Assets/Symbolicons/Bell";
import { NavigationConfig } from "@ui/Organisms/UserDropdown";

export const createHref = (language: AVAILABLE_LANGS, link: string): string =>
  `/${language}${link}`;

export const getLanguagesList = (
  translate: (k: string) => string,
): { value: AVAILABLE_LANGS; label: string }[] =>
  Object.values(AVAILABLE_LANGS)
    .filter(lang => !EXCLUDED_LANGS_BATWORK.includes(lang))
    .map(lang => ({
      value: lang,
      label: translate(`batwork_pwa_generic.language_${lang}`),
    }));

interface GetUserNavigationInput {
  logoutUrl: string;
  isUserClient: boolean;
  isUserAdmin: boolean;
  exitUserUrl: string;
  isMobile: boolean;
  language: AVAILABLE_LANGS;
  translate: (k: string) => string;
  logoutAction: () => void;
}

export const getUserNavigationConfig = ({
  isUserClient,
  isUserAdmin,
  exitUserUrl,
  isMobile,
  language,
  logoutUrl,
  logoutAction,
  translate,
}: GetUserNavigationInput): NavigationConfig => {
  const iconConfig = {
    size: 24,
    color: theme.colors.grey700,
  };

  return [
    {
      items: [
        ...(isMobile
          ? [
              {
                label: translate("batwork_pwa_navigation.become_a_batworker"),
                href: createHref(language, BECOME_A_BATWORKER_LINK),
                isHidden: isUserClient,
              },
              {
                label: translate("batwork_pwa_navigation.help"),
                href: createHref(language, HELP_LINK_BATWORK),
              },
            ]
          : []),
      ],
    },
    ...(isMobile
      ? [
          {
            items: [
              {
                label: translate("batwork_pwa_navigation.gift_cards"),
                href: createHref(language, GIFT_CARDS_LINK),
                isHidden: !isMobile,
                isSpecial: true,
              },
            ],
          },
        ]
      : []),
    {
      items: [
        ...(isUserClient || isUserAdmin
          ? [
              {
                label: translate("batwork_pwa_navigation.subnav_logout"),
                icon: <PowerOff {...iconConfig} />,
                href: logoutUrl,
                action: logoutAction,
              },
            ]
          : []),
      ],
    },
    ...(isUserClient && isUserAdmin
      ? [
          {
            items: [
              {
                label: translate("batwork_pwa_navigation.subnav_exit_user"),
                icon: <UserOff {...iconConfig} />,
                href: exitUserUrl,
                skipHrefPrefix: true,
              },
            ],
          },
        ]
      : []),
  ];
};
