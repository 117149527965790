/**
 * @author jakubmaslon
 * @since 2020-12-02
 */

import * as React from "react";
import styled from "styled-components";

import defaultProfilePicture from "@assets/images/default_profile_picture.png";

import { rem } from "@ui/helpers";

interface Props {
  picturePath?: string | null;
  shrinkOnMobile?: boolean;
  enlargeOnTabletUp?: boolean;
  size?: number;
}

const RoundPicture = (props: Props): JSX.Element => (
  <RoundPictureStyled {...props} />
);

RoundPicture.displayName = "RoundPicture";

export { RoundPicture };

const RoundPictureStyled = styled.div<Props>`
  min-width: ${props => (props.size ? rem(props.size) : rem(64))};
  min-height: ${props => (props.size ? rem(props.size) : rem(64))};
  max-width: ${props => (props.size ? rem(props.size) : rem(64))};
  max-height: ${props => (props.size ? rem(props.size) : rem(64))};
  border-radius: 50%;
  border: ${rem(1)} solid ${props => props.theme.colors.grey500};
  background-position: center;
  background-size: cover;
  background-color: ${props => props.theme.colors.grey500};
  background-image: ${props =>
    props.picturePath
      ? `url(${props.picturePath})`
      : `url(${defaultProfilePicture})`};

  ${props => props.theme.breakpoints.upToTablet} {
    ${props =>
      props.shrinkOnMobile &&
      `
    min-width: ${props.size ? rem(props.size) : rem(56)};
    min-height: ${props.size ? rem(props.size) : rem(56)};
    max-width: ${props.size ? rem(props.size) : rem(56)};
    max-height: ${props.size ? rem(props.size) : rem(56)};
  `}
  }

  ${props => props.theme.breakpoints.tablet} {
    ${props =>
      props.enlargeOnTabletUp &&
      `
    min-width: ${props.size ? rem(props.size) : rem(72)};
    min-height: ${props.size ? rem(props.size) : rem(72)};
    max-width: ${props.size ? rem(props.size) : rem(72)};
    max-height: ${props.size ? rem(props.size) : rem(72)};
  `}
  }
`;
