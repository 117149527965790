// SALARY
export const BOOKING_SALARY_INSURANCES_APG_ENDPOINT =
  "/client/batsoft/salary/insurances/apg?serviceLocationId={serviceLocationIdValue}&superGrossSalary={superGrossSalaryValue}&secondPillarRetirement={secondPillarRetirementValue}&sicknessInsurance={sicknessInsuranceValue}";
export const BOOKING_SALARY_INSURANCES_LPP_ENDPOINT =
  "/client/batsoft/salary/insurances/lpp?serviceLocationId={serviceLocationIdValue}&superGrossSalary={superGrossSalaryValue}&secondPillarRetirement={secondPillarRetirementValue}&sicknessInsurance={sicknessInsuranceValue}";
export const BOOKING_SALARY_DEFAULTS_ENDPOINT =
  "/client/batsoft/defaults?serviceLocationId={serviceLocationIdValue}&agentUuid={agentUuidValue}&secondPillarRetirement={secondPillarRetirementValue}";
export const BOOKING_SALARY_ENDPOINT =
  "/client/batsoft/salary?serviceLocationId={serviceLocationIdValue}&superGrossSalary={superGrossSalaryValue}&secondPillarRetirement={secondPillarRetirementValue}&sicknessInsurance={sicknessInsuranceValue}&agreeToProfessionalAccidentInsurance={agreeToProfessionalAccidentInsuranceValue}";
export const BOOKING_SALARY_SUPERGROSS_ENDPOINT =
  "/client/batsoft/salary/supergrosssalary?serviceLocationId={serviceLocationIdValue}&grossSalaryIncludingVacation={grossSalaryIncludingVacationValue}&currentSecondPillarRetirement={currentSecondPillarRetirementValue}&newSecondPillarRetirement={newSecondPillarRetirementValue}&currentSicknessInsurance={currentSicknessInsuranceValue}&newSicknessInsurance={newSicknessInsuranceValue}";

export const LEGAL_LOCATION_ENDPOINT = "/client/batsoft/legal-location";
export const CONTRACT_VOUCHER_ENDPOINT =
  "/client/batsoft/contract/{contractUuid}/voucher";

export const EMPLOYEE_ABSENCE_ENDPOINT = "/client/agents/absences";

// ONCE BOOKING
export const ONCE_BOOKING_ADD_JOB_TO_CONTRACT_ENDPOINT =
  "/client/batsoft/once-booking/contracts/{contractUuid}/job";
export const ONCE_BOOKING_AVAILABILITY_ENDPOINT =
  "/client/batwork/once-booking/availability";

// DECLARE EMPLOYEE
export const CLIENT_DECLARE_YOUR_EMPLOYEE_ENDPOINT =
  "/client/batsoft/declare-your-employee";
export const HR_DECLARE_YOUR_EMPLOYEE_ENDPOINT = "/declare-your-employee";
export const AGENTS_LIST_ENDPOINT =
  "/client/agents?filter={filterValue}&serviceLocationId={serviceLocationIdValue}";
export const DECLARE_EMPLOYEE_SALARY_ENDPOINT =
  "/client/batsoft/declare-your-employee/{declarationUuidValue}/salary";
export const DECLARE_EMPLOYEE_WORK_PERMIT_ENDPOINT =
  "/client/batsoft/declare-your-employee/{declarationUuidValue}/work-permit";
export const DECLARE_EMPLOYEE_PAYMENT_ALIAS_ENDPOINT =
  "/client/batsoft/declare-your-employee/{declarationUuidValue}/payment-alias";
