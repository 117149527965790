import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const KeyRounded = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M15.031 9.342a.374.374 0 1 0-.75.002.375.375 0 0 0 .75-.001" />
      <path
        clipRule="evenodd"
        d="m12.499 14.785-1.03 1.032H9.637v1.469H8.172v1.836L6.798 20.5H3.5v-3.305l5.723-5.736a5.856 5.856 0 0 1 1.311-6.245 5.83 5.83 0 0 1 8.257 0 5.862 5.862 0 0 1 0 8.276 5.83 5.83 0 0 1-6.292 1.295Z"
      />
    </svg>
  );
};
