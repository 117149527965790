import { AnimationSequence, stagger } from "framer-motion";

const sequence: AnimationSequence = [
  [
    ".loader-list > *",
    {
      opacity: [0, 1],
      pathLength: [0, 1],
    },
    { delay: stagger(0.5, { from: "first" }), duration: 0.5 },
  ],
  [
    ".loader-list",
    {
      opacity: [1, 0],
    },
    { delay: 0.5, duration: 0.5 },
  ],
  [
    ".loader-shape",
    {
      width: ["100%", "48%"],
      height: ["98%", "48%"],
      borderRadius: ["8%", "50%"],
      transform: [
        "translate(-50%, -50%) rotate(0deg)",
        "translate(-50%, -50%) rotate(90deg)",
      ],
    },
    { duration: 0.7 },
  ],
  [
    ".loader-bell",
    {
      opacity: 1,
    },
    { duration: 0.5 },
  ],
  [
    ".loader-bell",
    {
      opacity: [1, 0],
    },
    { delay: 0.5, duration: 0.5 },
  ],
  [
    ".loader-shape",
    {
      width: "100%",
      height: "98%",
      borderRadius: "8%",
      transform: "translate(-50%, -50%) rotate(180deg)",
    },
    { duration: 0.7 },
  ],
];

export { sequence };
