import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const BellNotificationAlarm = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M9.665 18.19v.48a2.333 2.333 0 0 0 2.333 2.334H12a2.334 2.334 0 0 0 2.335-2.333v-.48" />
      <path
        clipRule="evenodd"
        d="M17.578 18.19a1.925 1.925 0 0 0 1.925-1.925v0c0-.54-.215-1.058-.596-1.44l-1.28-1.279V9.749A5.627 5.627 0 0 0 12 4.122v0A5.627 5.627 0 0 0 6.373 9.75v3.797l-1.28 1.278c-.381.383-.596.9-.596 1.44v0c0 1.064.862 1.926 1.925 1.926h11.156v0Z"
      />
      <path d="M22.026 2.996 20.024 4.33M23.005 9h-1.98M1.975 2.996l2 1.334M.995 9h1.98" />{" "}
    </svg>
  );
};
