import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { LOGIN } from "@config/testIds/login";

import { ILoginUser } from "@typings/user";
import { OAUTH_PROVIDERS, IOAuthState } from "@typings/oauth";
import { MODALS } from "@typings/modals";

import { ModalContentLogin } from "@ui/Organisms";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { useTranslate } from "@hooks/useTranslate";

import { Modal } from "@soft/containers/Modal";
import { showLoading, hideLoading } from "@soft/redux/loading/loading.actions";
import {
  loginUserByAccessToken,
  setUserData,
} from "@soft/redux/oauth/oauth.actions";
import {
  fetchUserData,
  loginUser,
  recoverPassword,
} from "@soft/redux/user/user.actions";
import { useModalStore } from "@soft/hooks/useModalStore";

interface Props {
  loginUser: (credentials: ILoginUser) => Promise<AsyncActionResult>;
  fetchUserData: () => Promise<AsyncActionResult>;
  showLoading: () => void;
  hideLoading: () => void;
  loginUserByAccessToken: (
    provider: OAUTH_PROVIDERS,
    accessToken: string,
  ) => Promise<AsyncActionResult>;
  recoverPassword: (email: string) => Promise<AsyncActionResult>;
  setUserData: (payload: IOAuthState) => void;
}

const LoginModalComponent = (props: Props): React.ReactElement => {
  const translate = useTranslate("batsoft_pwa_generic");
  const { options, hideModal } = useModalStore();

  const modalOpts = options(MODALS.NAVBAR_LOGIN);

  return (
    <Modal
      title={translate("login_modal_title")}
      id={MODALS.NAVBAR_LOGIN}
      withFooter={false}
      size="thin"
      close={() => {
        modalOpts.onClose && modalOpts.onClose();
      }}
      testId={LOGIN.MODAL_WRAPPER}
    >
      <ModalContentLogin
        loginUser={props.loginUser}
        fetchUserData={props.fetchUserData}
        showLoading={props.showLoading}
        hideLoading={props.hideLoading}
        loginUserByAccessToken={props.loginUserByAccessToken}
        recoverPassword={props.recoverPassword}
        setUserData={props.setUserData}
        hideModal={hideModal}
        modalOpts={modalOpts}
        translations={{
          retryLoginText: translate("retry_login"),
          loginFailedText: translate("login_failed"),
          wrongEmailFormatText: translate("email_wrong_format"),
          emailLabelText: translate("email"),
          passwordLabelText: translate("password"),
          resetPasswordButtonText: translate("reset_password"),
          backToLoginButtonText: translate("back_to_login"),
          forgotPasswordLinkText: translate("forgot_password_link"),
          rememberMeCheckboxLabel: translate("remember_me"),
          loginButtonText: translate("login"),
          loginSeparatorText: translate("or_login_with"),
          socialMediaRestrictedText: translate("social_media_login_restricted"),
        }}
      />
    </Modal>
  );
};

const mapDispatch = {
  fetchUserData,
  loginUser,
  showLoading,
  hideLoading,
  loginUserByAccessToken,
  recoverPassword,
  setUserData,
};

export const LoginModal = compose(connect(null, mapDispatch))(
  LoginModalComponent,
);

LoginModal.displayName = "LoginModal";
