import { combineReducers } from "redux";
import { Record } from "immutable";

import { ModalReducer } from "@typings/modals";

import {
  LocalisationState,
  useLocalisationReducer,
} from "@hooks/useLocalisation/redux";

import { bookingReducer, BookingReducer } from "./booking";
import { userReducer, UserReducer } from "./user";
import { modalsReducer } from "./modals";
import { loadingReducer, LoadingReducer } from "./loading";
import { locationsReducer, LocationsReducer } from "./locations";
import { localisationReducer, LocalisationReducer } from "./localisation";
import { oauthReducer, OAuthReducer } from "./oauth";
import { navigationReducer } from "./navigation";
import {
  serviceLocationReducer,
  ServiceLocationReducer,
} from "./serviceLocation";
import { applyReducer, ApplyReducer } from "./apply";
import { paymentReducer, PaymentReducer } from "./payment";

type IStoreKeys = keyof IStore;
export type IStoreValues = IStore[IStoreKeys];

export interface IStore extends LocalisationState {
  booking: BookingReducer;
  loading: LoadingReducer;
  modals: ModalReducer;
  user: UserReducer;
  locations: Record<LocationsReducer>;
  localisation: LocalisationReducer;
  oauth: OAuthReducer;
  serviceLocation: ServiceLocationReducer;
  navigation: boolean;
  apply: ApplyReducer;
  payment: Record<PaymentReducer>;
}

export const allReducers = {
  ...useLocalisationReducer,
  booking: bookingReducer,
  loading: loadingReducer,
  modals: modalsReducer,
  user: userReducer,
  locations: locationsReducer,
  localisation: localisationReducer,
  oauth: oauthReducer,
  serviceLocation: serviceLocationReducer,
  navigation: navigationReducer,
  apply: applyReducer,
  payment: paymentReducer,
};

const combinedReducers = combineReducers(allReducers);

export { combinedReducers };
