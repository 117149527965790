/**
 * @author michalstolarski
 * @since 2024-1-5
 */

import * as React from "react";
import { useAnimate, AnimationPlaybackControls } from "framer-motion";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

import * as Styled from "./LoaderV2.styled";
import * as Icons from "./LoaderV2.icons";
import { sequence } from "./LoaderV2.sequence";

interface Props {
  testId?: string;
  className?: string;
  state?: "play" | "pause";
}

/**
 * All dimensions are in % and based on Styled.Container's width.
 * `aspect-ratio` is used to make sure that the height is equal to the width.
 */
const LoaderV2 = (props: Props): React.ReactElement => {
  const [scope, animate] = useAnimate();
  const [controls, setControls] = React.useState<AnimationPlaybackControls>();

  React.useEffect(() => {
    setControls(animate(sequence, { repeat: Infinity, delay: 0.5 }));
  }, []);

  React.useEffect(() => {
    if (!controls) return;

    switch (props.state) {
      case "play":
        controls.play();
        break;
      case "pause":
        controls.pause();
        break;
    }
  }, [controls, props.state]);

  return (
    <TestWrapper testId={props.testId}>
      <Styled.Container ref={scope}>
        <Icons.List />
        <Icons.Bell />

        <Styled.Shape className="loader-shape" />
      </Styled.Container>
    </TestWrapper>
  );
};

export { LoaderV2 };
