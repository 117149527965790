export const formatAmount = (
  currency: string,
  value: number | string,
  suffix?: string,
  trimDecimals?: boolean,
): string => {
  const addDecimals = (val: string) => {
    const splitVal = val.split(".");

    return splitVal.length > 1
      ? splitVal[1].length === 1
        ? `${val}0`
        : `${val.substring(0, val.indexOf(".") + 3)}`
      : `${val}.00`;
  };

  const removeDecimals = (val: string) => val.split(".")[0];

  const suffixFormatted = suffix ? `/${suffix.toLowerCase()}` : "";
  const valueStringified = String(value);
  const valueFormatted = trimDecimals
    ? removeDecimals(valueStringified)
    : addDecimals(valueStringified);

  return `${currency} ${valueFormatted}${suffixFormatted}`.trim();
};
