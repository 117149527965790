import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Star = (props: Icon): React.ReactElement => {
  if (props.variant === "line") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27 26"
        style={{ ...style(props.size, props.height), ...props.style }}
        className={props.className}
      >
        <defs>
          <path d="M19.168 16.126l2.548 7.946s1.472 3.34-1.526 1.182c-2.747-1.976-6.673-4.96-6.673-4.96s-4.116 3.05-6.838 5.063c-2.738 2.028-1.339-1.389-1.339-1.389l2.534-7.852-6.882-5.054s-2.494-1.705.427-1.705l8.618.002 2.746-8.62S12.997 0 13.537 0c.574 0 .766.793.766.793l2.714 8.573s5.203-.01 8.567-.01c2.765 0 .629 1.594.629 1.594l-7.045 5.176z"></path>
        </defs>
        <path
          fill="none"
          stroke={props.color || theme.colors.grey700}
          strokeWidth={2}
          d="M25.778 10.21a5.877 5.877 0 00-.194-.003 3323.469 3323.469 0 00-7.7.007l-.865.002h-.624l-.188-.594-2.672-8.445-2.877 9.032H9.163l-2.175-.001a26868.006 26868.006 0 00-5.717 0c.082.067.16.124.224.169l7.377 5.416-.189.584-2.556 7.913a5.37 5.37 0 00-.185.548 5.84 5.84 0 00.231-.164l2.245-1.662.004-.003 3.155-2.337c.61-.45.61-.45 1.04-.77l.394-.292.513-.38.507.387a269.47 269.47 0 001.376 1.04c1.036.78 2.08 1.56 3.064 2.288l.195.144a197.79 197.79 0 002.02 1.475c.15.108.287.2.41.278a6.894 6.894 0 00-.19-.51l-2.734-8.53.493-.361 7.04-5.173c.02-.015.045-.034.073-.058zM.842 9.778a.508.508 0 00.012.017zm20.385 15.698a.265.265 0 000-.02z"
        ></path>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 27 26"
      style={{ ...style(props.size, props.height), ...props.style }}
      fill={props.color || theme.colors.grey700}
      className={props.className}
    >
      <defs>
        <path
          id="star-solid-a"
          d="M26.177 10.95l-7.035 5.176 2.544 7.946s1.47 3.34-1.524 1.182c-2.743-1.976-6.664-4.96-6.664-4.96s-4.11 3.05-6.828 5.063c-2.734 2.028-1.337-1.389-1.337-1.389l2.53-7.852-6.872-5.054s-2.491-1.705.426-1.705l8.606.002 2.742-8.62S12.979 0 13.518 0c.574 0 .766.793.766.793l2.71 8.573s5.195-.01 8.555-.01c2.76 0 .628 1.594.628 1.594"
        ></path>
      </defs>
      <use fillRule="evenodd" xlinkHref="#star-solid-a"></use>
    </svg>
  );
};
