// app
import batmaid_pwa_booking from "@translations/app/en.batmaid_pwa_booking.json";
import batmaid_pwa_client_options from "@translations/app/en.batmaid_pwa_client_options.json";
import batmaid_pwa_client_notifications from "@translations/app/en.batmaid_pwa_client_notifications.json";
import batmaid_pwa_generic from "@translations/app/en.batmaid_pwa_generic.json";
import batmaid_pwa_holidays from "@translations/app/en.batmaid_pwa_holidays.json";
import batmaid_pwa_my_cleanings from "@translations/app/en.batmaid_pwa_my_cleanings.json";
import batmaid_pwa_navigation from "@translations/app/en.batmaid_pwa_navigation.json";
import batmaid_pwa_password from "@translations/app/en.batmaid_pwa_password.json";
import batmaid_pwa_apply from "@translations/app/en.batmaid_pwa_apply.json";
import batmaid_pwa_bookings_to_validate from "@translations/app/en.batmaid_pwa_bookings_to_validate.json";
import batmaid_pwa_end_of_tenancy from "@translations/app/en.batmaid_pwa_end_of_tenancy.json";
import batmaid_pwa_b2b from "@translations/app/en.batmaid_pwa_b2b.json";
import batmaid_pwa_profile from "@translations/app/en.batmaid_pwa_profile.json";
import batmaid_pwa_landing_page from "@translations/app/en.batmaid_pwa_landing_page.json";
import batmaid_pwa_onboarding from "@translations/app/en.batmaid_pwa_onboarding.json";
import batmaid_pwa_agent_profile from "@translations/app/en.batmaid_pwa_agent_profile.json";
import batmaid_pwa_agent_schedule from "@translations/app/en.batmaid_pwa_agent_schedule.json";
import batmaid_pwa_agent_cleanings from "@translations/app/en.batmaid_pwa_agent_cleanings.json";
import batmaid_pwa_agent_availabilities from "@translations/app/en.batmaid_pwa_agent_availabilities.json";
import batmaid_pwa_agent_onboarding from "@translations/app/en.batmaid_pwa_agent_onboarding.json";
import batmaid_pwa_agent_login from "@translations/app/en.batmaid_pwa_agent_login.json";
import batmaid_pwa_rating from "@translations/app/en.batmaid_pwa_rating.json";
import batmaid_pwa_urssaf from "@translations/app/en.batmaid_pwa_urssaf.json";
import batmaid_pwa_booking_experience_survey from "@translations/app/en.batmaid_pwa_booking_experience_survey.json";
import batmaid_pwa_help_center from "@translations/app/en.batmaid_pwa_help_center.json";
import batmaid_pwa_checklist from "@translations/app/en.batmaid_pwa_checklist.json";

export const appTranslations = {
  batmaid_pwa_booking,
  batmaid_pwa_client_options,
  batmaid_pwa_client_notifications,
  batmaid_pwa_generic,
  batmaid_pwa_holidays,
  batmaid_pwa_my_cleanings,
  batmaid_pwa_navigation,
  batmaid_pwa_password,
  batmaid_pwa_apply,
  batmaid_pwa_bookings_to_validate,
  batmaid_pwa_end_of_tenancy,
  batmaid_pwa_b2b,
  batmaid_pwa_profile,
  batmaid_pwa_landing_page,
  batmaid_pwa_onboarding,
  batmaid_pwa_agent_profile,
  batmaid_pwa_agent_schedule,
  batmaid_pwa_agent_cleanings,
  batmaid_pwa_agent_availabilities,
  batmaid_pwa_agent_onboarding,
  batmaid_pwa_agent_login,
  batmaid_pwa_rating,
  batmaid_pwa_urssaf,
  batmaid_pwa_booking_experience_survey,
  batmaid_pwa_help_center,
  batmaid_pwa_checklist,
};
