import { AVAILABLE_CURRENCIES } from "@typings/globals";

import { getLangCode } from "@services/IntlLanguage";

/**
 * Returns a formatted currency string depending on the current country
 * @param options - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
 * @param options.maximumFractionDigits - returns the number with the specified number of digits after the decimal point
 */
const intlNumberFormatCurrency = (
  amount: number,
  currency: AVAILABLE_CURRENCIES,
  options?: Omit<Intl.NumberFormatOptions, "style" | "currency">,
) => {
  const parts = intlNumberFormatCurrencyToParts(amount, currency, options);
  const result = new Intl.NumberFormat(getLangCode(), {
    style: "currency",
    currency,
    ...options,
  }).format(amount);

  // If the minus sign in before the currency symbol, then we need to swap them
  // Example: -CHF 1.00 -> CHF -1.00
  const minusSignPart = parts.find(part => part.type === "minusSign");
  const currencyPart = parts.find(
    part => part.type === "currency",
  ) as Intl.NumberFormatPart;
  const literal = parts.find(part => part.type === "literal");

  if (!minusSignPart) return result;

  return result.replace(
    `${minusSignPart.value}${currencyPart.value}${literal?.value ?? ""}`,
    `${currencyPart.value}${literal?.value ?? ""}${minusSignPart.value}`,
  );
};

/**
 * Returns an object with parts of the formatted currency string depending on the current country
 * @param options - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
 */
const intlNumberFormatCurrencyToParts = (
  amount: number,
  currency: AVAILABLE_CURRENCIES,
  options?: Intl.NumberFormatOptions,
) => {
  return new Intl.NumberFormat(getLangCode(), {
    style: "currency",
    currency,
    ...options,
  }).formatToParts(amount);
};

export { intlNumberFormatCurrency, intlNumberFormatCurrencyToParts };
