/**
 * @author jakubmaslon
 * @since 2019-07-13
 */

import * as React from "react";
import { Field, FieldProps, FormikProps } from "formik";
import { Props as ReactSelectProps } from "react-select/base";

import { TestWrapper } from "@ui/Atoms";
import { SelectExtended } from "@ui/Atoms/Form/SelectExtended/SelectExtended";

import { Markdown } from "@containers/Markdown";

interface SelectOption {
  value: string;
  label: string;
}

interface OnChangeInput {
  name: string;
  value: string;
}

interface Props {
  name: string;
  options: SelectOption[];
  value: string | null; // "null" clears ReactSelect
  isSearchable?: boolean;
  placeholder?: string;
  optionalLabel?: string;
  isDisabled?: boolean;
  menuPlacement?: ReactSelectProps["menuPlacement"];
  hideDropdownIndicator?: boolean;
  autoFocus?: boolean;
  testId?: string;
  errorTestId?: string;
  clearInput?: boolean;
  className?: string;
  formatOptionLabel?: ReactSelectProps["formatOptionLabel"];
  onChange: (input: OnChangeInput) => void;
  onInputChange?: (val: any) => void;
  customOption?: (optionProps: any) => JSX.Element;
  customValueContainer?: (props: any) => JSX.Element;
  customSingleValue?: (props: any) => JSX.Element;
  filterOption?: ReactSelectProps["filterOption"];
}

const FormikSelectExtended = (props: Props): React.ReactElement => {
  const valueAsSelectOption =
    props.value === null
      ? null
      : props.options.filter(item => item.value === props.value)[0];

  const getErrors = (form: FormikProps<any>) => {
    const result =
      form.errors[props.name] && form.touched[props.name]
        ? form.errors[props.name]
        : undefined;

    return typeof result === "string" ? (
      <TestWrapper testId={props.errorTestId}>
        <div>
          <Markdown>{result}</Markdown>
        </div>
      </TestWrapper>
    ) : undefined;
  };

  return (
    <Field>
      {({ form }: FieldProps) => (
        <TestWrapper testId={props.testId}>
          <div>
            <React.Fragment>
              <SelectExtended
                className={props.className}
                autoFocus={props.autoFocus}
                value={valueAsSelectOption}
                onChange={(selectedOption: SelectOption) => {
                  form.setFieldValue(props.name, selectedOption.value);
                  props.onChange({
                    name: props.name,
                    value: selectedOption.value,
                  });
                }}
                onBlur={() => form.setFieldTouched(props.name)}
                onInputChange={props.onInputChange}
                options={props.options}
                isSearchable={props.isSearchable}
                placeholder={props.placeholder}
                optionalLabel={props.optionalLabel}
                errors={getErrors(form)}
                isDisabled={props.isDisabled}
                formatOptionLabel={props.formatOptionLabel}
                menuPlacement={props.menuPlacement}
                hideDropdownIndicator={props.hideDropdownIndicator}
                clearInput={props.clearInput}
                customOption={props.customOption}
                customValueContainer={props.customValueContainer}
                customSingleValue={props.customSingleValue}
                filterOption={props.filterOption}
              />
            </React.Fragment>
          </div>
        </TestWrapper>
      )}
    </Field>
  );
};

export { FormikSelectExtended, SelectOption, OnChangeInput };
