import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const PageWithSearch = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <defs>
      <path
        id="page-with-search-a"
        d="M13.333 4c.16 0 .313.057.434.16l4.667 4a.667.667 0 01.233.507V18a2 2 0 01-2 2H7.333a2 2 0 01-2-2V6a2 2 0 012-2zm-.246 1.333H7.333A.666.666 0 006.667 6v12c0 .368.298.667.666.667h9.334a.667.667 0 00.666-.667V8.973l-4.246-3.64zm-1.754 4a3.333 3.333 0 012.781 5.172l1.69 1.69a.667.667 0 01-.88.999l-.062-.056-1.691-1.69a3.333 3.333 0 11-1.837-6.115zm0 1.334a2 2 0 100 4 2 2 0 000-4z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="page-with-search-b" fill="#fff">
        <use xlinkHref="#page-with-search-a"></use>
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#page-with-search-a"></use>
      <g
        fill={props.color || theme.colors.grey700}
        mask="url(#page-with-search-b)"
      >
        <path d="M0 0h24v24H0z"></path>
      </g>
    </g>
  </svg>
);
