import * as React from "react";
import { useTheme } from "styled-components";

import { Icon, style } from "@ui/Assets/Symbolicons/Symbolicons";

const Pen = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  return (
    <svg
      width={28}
      height={28}
      fill="none"
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.523 22.604c-.1.1-.22.18-.35.236l-.134.047L4.58 24.99l-2.017 2.018A1.111 1.111 0 0 1 .9 25.54l.092-.104 2.016-2.018 2.105-7.458c.039-.138.103-.266.19-.378l.094-.106L16.872 3.999l-.189-.19a2.222 2.222 0 0 0-3.002-.13l-.14.13-4.31 4.31a1.111 1.111 0 0 1-1.665-1.467l.093-.104 4.31-4.31a4.444 4.444 0 0 1 6.109-.168l.177.168.188.189.287-.284a5.04 5.04 0 0 1 6.885-.226l.242.226a5.04 5.04 0 0 1 .227 6.885l-.227.242-13.334 13.334Zm-.746-10.367-4.605 4.606-1.565 5.55 5.548-1.566 4.605-4.607-3.983-3.983Zm8.725-8.705a2.817 2.817 0 0 1 3.783.183l.138.147.168.214a2.817 2.817 0 0 1-.306 3.623l-6.952 6.951-3.983-3.983 6.951-6.952.201-.183Z"
        fill={props.color || theme.colors.black500}
      />
    </svg>
  );
};

export { Pen };
