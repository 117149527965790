import * as React from "react";
import styled, { css } from "styled-components";
import color from "color";

import {
  NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT,
  IS_BROWSER,
  CHRISTMAS_MODE,
} from "@config/consts";

import {
  APP_NAMES,
  AVAILABLE_LANGS,
  AVAILABLE_COUNTRIES,
} from "@typings/globals";

import {
  rem,
  getBackgroundPerApp,
  getBackgroundHoverPerApp,
} from "@ui/helpers";
import { Column, Container, Row } from "@ui/Atoms";
import { NavLink, Navigation } from "@ui/Organisms/Navbar";
import { BatmaidLogo, BatsoftLogo, BatworkLogo } from "@ui/Assets/Logos";

import { getQueryValue } from "@services/QueryParamsParser";
import { stringToBool } from "@services/StringToBool";

interface Props {
  children: React.ReactNode;
  currentLanguage: AVAILABLE_LANGS;
  variant: APP_NAMES;
  loginUser: () => void;
  isMobile?: boolean;
  isSSR?: boolean;
  currentCountry?: AVAILABLE_COUNTRIES;
  logoHref?: string;
  rowRef?: React.MutableRefObject<any>;
  id?: string;
  onLogoClick?: (e: React.SyntheticEvent<HTMLAnchorElement>) => void;
}

export const Header = ({
  onLogoClick,
  ...props
}: Props): React.ReactElement => {
  const useStandaloneAppMode =
    props.isMobile && props.variant === APP_NAMES.BATMAID && !props.isSSR;

  const languagePathName = `/${props.currentLanguage}/`;

  const batmaidLogoLink = languagePathName;

  const renderLogoWLink = () => {
    if (useStandaloneAppMode) {
      return (
        <a href={props.logoHref || batmaidLogoLink} onClick={onLogoClick}>
          <BatmaidLogo
            white
            isChristmas={CHRISTMAS_MODE}
            country={props.currentCountry}
          />
        </a>
      );
    }

    return (
      <a href={props.logoHref || languagePathName} onClick={onLogoClick}>
        {props.variant === APP_NAMES.BATMAID && (
          <BatmaidLogo
            white
            isChristmas={CHRISTMAS_MODE}
            country={props.currentCountry}
          />
        )}
        {props.variant === APP_NAMES.BATSOFT && <BatsoftLogo />}
        {props.variant === APP_NAMES.BATWORK && <BatworkLogo />}
      </a>
    );
  };

  React.useEffect(() => {
    if (IS_BROWSER) {
      const showLoginQueryParam = getQueryValue(
        window.location.href,
        "showlogin",
      );

      if (showLoginQueryParam && stringToBool(showLoginQueryParam)) {
        props.loginUser();
      }
    }
  }, []);

  return (
    <React.Fragment>
      <StyledHeader variant={props.variant} id={props.id}>
        <HeaderContainer>
          <HeaderRow ref={props.rowRef}>
            <LogoColumn xs={5} sm={3}>
              {renderLogoWLink()}
            </LogoColumn>
            <NavigationColumn xs={7} sm={9} {...props}>
              {props.children}
            </NavigationColumn>
          </HeaderRow>
        </HeaderContainer>
      </StyledHeader>
    </React.Fragment>
  );
};

export const StyledHeader = styled.header<{ variant: APP_NAMES }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: ${props => getBackgroundPerApp(props.variant)};
  height: ${rem(NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT)};
  position: relative;
  z-index: ${props => props.theme.zIndex.header};
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;

  transition: transform ${props => props.theme.animations.duration}
    ${props => props.theme.animations.easing};

  @supports (height: env(safe-area-inset-top)) {
    padding-top: env(safe-area-inset-top);
    height: calc(
      env(safe-area-inset-top) + ${rem(NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT)}
    );
  }

  ${props =>
    props.variant === APP_NAMES.BATWORK &&
    css`
      box-shadow: 0 ${rem(1)} ${rem(2)} ${rem(1)}
        ${color(props.theme.colors.black).fade(0.5).toString()};
    `}
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  margin: 0 auto;
`;

export const HeaderRow = styled(Row)`
  justify-content: center;
`;

export const LogoColumn = styled(Column)`
  display: flex;
  align-items: center;

  & svg {
    height: ${rem(24)};
  }

  @media (min-width: 380px) {
    & svg {
      height: ${rem(40)};
    }
  }
`;

export const NavigationColumn = styled(Column)<Props>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > ${Navigation} {
    top: ${rem(72)};
    right: 0;

    @supports (top: env(safe-area-inset-top)) {
      top: calc(env(safe-area-inset-top) + ${rem(72)});
    }
  }

  ${NavLink} {
    a {
      &:hover {
        background: ${props => getBackgroundHoverPerApp(props.variant)};
        color: ${props =>
          props.variant === APP_NAMES.BATWORK
            ? props.theme.colors.black700
            : props.theme.colors.white};
      }
    }
  }
`;
