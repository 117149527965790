import React from "react";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { getImage, withArtDirection } from "gatsby-plugin-image";
import styled from "styled-components";

import { Heading } from "@ui/Atoms/Headings";
import { Container } from "@ui/Atoms";
import { mediaQueries } from "@ui/themes/default";

import { PrismicGlobalContentPageDataBodyTitleDescriptionImage } from "../../../graphql-types";
import { GatsbyImage } from "../../components/GatsbyImage";
import { SliceContainer } from "../../components/SliceContainer";

const TitleDescriptionImageSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyTitleDescriptionImage>,
): React.ReactElement => {
  const primary = props.slice.primary;

  const backgroundResponsiveImage =
    primary?.image?.gatsbyImageData &&
    withArtDirection(getImage(primary.image.gatsbyImageData)!, [
      {
        media: mediaQueries.mobile,
        image: getImage(primary.image.thumbnails?.mobile?.gatsbyImageData)!,
      },
    ]);

  return (
    <SliceContainer>
      <StyledContainer widthVariant="fullMobile">
        <StyledHeader type="h1" styleAs="h4">
          {primary?.title1?.text}
        </StyledHeader>

        <DescriptionWrapper>
          <PrismicRichText
            field={primary?.description?.raw || primary?.description?.richText}
          />
        </DescriptionWrapper>

        <GatsbyImage
          image={backgroundResponsiveImage}
          alt={primary?.image?.alt || ""}
          style={{
            minHeight: "300px",
          }}
        />
      </StyledContainer>
    </SliceContainer>
  );
};

export { TitleDescriptionImageSlice };

const StyledContainer = styled(Container)`
  padding-top: ${props => props.theme.margins.base_x6};

  ${props => props.theme.breakpoints.tablet} {
    padding-top: 0;
  }
`;

const StyledHeader = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base_x3};
  padding: 0 ${props => props.theme.margins.base_x3};
  font-size: ${props => props.theme.fonts.sizes.h6};
  text-align: left;
  line-height: ${props => props.theme.fonts.lineHeights.h5};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.h5};
    text-align: center;
    width: 75%;
    margin: 0 auto ${props => props.theme.margins.base_x3};
    padding: 0;
  }
`;

const DescriptionWrapper = styled.div`
  margin-bottom: ${props => props.theme.margins.base_x4};
  padding: 0 ${props => props.theme.margins.base_x3};
  text-align: left;

  ${props => props.theme.breakpoints.tablet} {
    text-align: center;
    width: 75%;
    margin: 0 auto ${props => props.theme.margins.base_x4};
    padding: 0;
  }
`;
