/**
 * @author jakubmaslon
 * @since 2021-04-22
 */

import * as React from "react";
import styled, { css } from "styled-components";
import { navigate } from "gatsby";

import { rem } from "@ui/helpers";
import { Check } from "@ui/Assets/Symbolicons/Check";
import theme from "@ui/themes/default";

interface Props {
  title: string;
  imgAlt: string;
  img: React.ReactElement;
  imgMobile?: React.ReactElement;
  isSquareOnDesktop?: boolean;
  list?: string[];
  url?: string;
  onClick?: () => void;
}

const Tile = (props: Props): React.ReactElement => (
  <Wrapper
    isSquareOnDesktop={props.isSquareOnDesktop}
    {...(props.url && { href: props.url })}
    {...(!props.url && { as: "div" })}
    onClick={(e: React.SyntheticEvent) => {
      if (!props.url) return;
      e.preventDefault();
      navigate(props.url);
    }}
  >
    <React.Fragment>
      {props.imgMobile && (
        <ImgWrapperMobile>{props.imgMobile}</ImgWrapperMobile>
      )}
      <ImgWrapper isHiddenOnMobile={!!props.imgMobile}>{props.img}</ImgWrapper>
      <Title hasList={!!props.list?.length}>{props.title}</Title>
      {props.list?.map(benefit => (
        <Benefit key={benefit}>
          <CheckIconWrapper>
            <Check variant="line" size={14} color={theme.colors.green500} />
          </CheckIconWrapper>
          <BenefitText>{benefit}</BenefitText>
        </Benefit>
      ))}
    </React.Fragment>
  </Wrapper>
);

Tile.displayName = "Tile";

export { Tile };

const Wrapper = styled.a<{
  isSquareOnDesktop?: boolean;
}>`
  width: 100%;
  color: ${props => props.theme.colors.black700};

  &:hover {
    color: ${props => props.theme.colors.black700};
  }

  ${props =>
    !props.isSquareOnDesktop &&
    css`
      ${props.theme.breakpoints.tablet} {
        max-width: ${rem(152)};
      }
    `};

  ${props => props.theme.breakpoints.desktop} {
    min-width: ${props => (props.isSquareOnDesktop ? rem(232) : rem(168))};
    max-width: ${props => (props.isSquareOnDesktop ? rem(232) : rem(168))};
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    min-width: ${props => (props.isSquareOnDesktop ? rem(256) : rem(184))};
    max-width: ${props => (props.isSquareOnDesktop ? rem(256) : rem(184))};
  }
`;

const ImgWrapper = styled.div<{ isHiddenOnMobile?: boolean }>`
  ${props => props.isHiddenOnMobile && "display: none"};
  width: 100%;
  height: ${rem(191)};
  margin-bottom: ${props => props.theme.margins.base};
  border-radius: ${props => props.theme.border.radius.base};
  overflow: hidden;

  ${props => props.theme.breakpoints.tablet} {
    height: ${rem(208)};
  }

  ${props => props.theme.breakpoints.desktop} {
    display: block;
    height: ${rem(232)};
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    height: ${rem(256)};
  }

  .gatsby-image-wrapper {
    height: 100%;

    img {
      border-radius: ${props => props.theme.border.radius.base};
    }
  }
`;

const ImgWrapperMobile = styled(ImgWrapper)`
  ${props => props.theme.breakpoints.desktop} {
    display: none;
  }
`;

const Title = styled.div<{ hasList?: boolean }>`
  font-weight: ${props => props.theme.fonts.weights.bold};
  ${props =>
    props.hasList &&
    css`
      margin-bottom: ${props.theme.margins.base};
    `}
`;

const Benefit = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.margins.base};
  font-size: ${props => props.theme.fonts.sizes.body};
`;

const BenefitText = styled.span`
  margin-left: ${props => props.theme.margins.base};
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.light};
`;

const CheckIconWrapper = styled.span`
  flex-shrink: 0;
`;
