import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const DresserDrawers = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M21 11H3" />
      <rect x="3" y="3.00001" width="18" height="17" rx="2" />
      <path d="M19 20V21" />
      <path d="M5 20V21" />
      <path d="M14 7.00001H10" />
      <path d="M14 14H10" />
    </svg>
  );
};
