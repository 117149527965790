import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";

import { ThunkActionDispatch } from "@typings/work.reduxThunkActions";

import { LOCATIONS_ENDPOINT } from "@config/endpoints";

import { IStore } from "@work/redux/reducers";

import { asyncActionCreator } from "@work/services/AsyncActionCreator";

const action = new ReduxActionNameCreator("jobs");

export const FETCH_LOCATIONS = action.make("fetch locations");

export const fetchLocations = () => async (
  dispatch: ThunkActionDispatch,
  store: () => IStore,
) => {
  const action = await asyncActionCreator({
    url: LOCATIONS_ENDPOINT,
    action: FETCH_LOCATIONS,
  });

  return dispatch(action);
};
