import React from "react";
import {
  GatsbyImage as GatsbyImagePlugin,
  getImage,
  IGatsbyImageData,
} from "gatsby-plugin-image";
import { CSSProperties } from "styled-components";

interface Props {
  image: IGatsbyImageData;
  alt: string;
  objectFit?: CSSProperties["objectFit"];
  /* 
    Prismic Preview can't read localFile. 
    That's why we use src as a fallback. 
    This is used for Preview mode only. 
  */
  src?: string;
  style?: React.CSSProperties;
  className?: string;
}

const GatsbyImage = (props: Props): React.ReactElement | null => {
  // useMemo prevents the image from flickering when the parent re-renders
  const resolvedImage = React.useMemo(
    () => getImage(props.image),
    [JSON.stringify(props.image)],
  );

  return resolvedImage ? (
    <GatsbyImagePlugin
      image={resolvedImage}
      alt={props.alt}
      objectFit={props.objectFit}
      style={props.style}
      className={props.className}
    />
  ) : props.src ? (
    <img src={props.src} />
  ) : null;
};

export { GatsbyImage };
