import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";

const getAccountBalanceFunction = (store: IStore) =>
  store.profile.get("accountBalance");

export const getAccountBalance = createSelector(
  getAccountBalanceFunction,
  f => f,
);

const getBasicInfoFunction = (store: IStore) => store.profile.get("basicInfo");

export const getBasicInfo = createSelector(
  getBasicInfoFunction,
  f => f,
);

const getPaymentAliasesFunction = (store: IStore) =>
  store.profile.get("paymentAliases");

export const getPaymentAliases = createSelector(
  getPaymentAliasesFunction,
  f => f,
);
