import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const TextileMaterial = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03213 4.72914L5.45289 3.1499C5.25303 2.95004 4.92899 2.95004 4.72913 3.1499L3.1499 4.72914C3.05392 4.82511 3 4.95529 3 5.09102C3 5.22675 3.05392 5.35693 3.1499 5.4529L4.72913 7.03213C4.92899 7.23199 4.92899 7.55603 4.72913 7.75589L3.1499 9.33513C2.95004 9.53499 2.95004 9.85903 3.1499 10.0589L4.72913 11.6381C4.92899 11.838 4.92899 12.162 4.72913 12.3619L3.1499 13.9411C2.95004 14.141 2.95004 14.465 3.1499 14.6649L4.72913 16.2441C4.92899 16.444 4.92899 16.768 4.72913 16.9679L3.1499 18.5471C2.95004 18.747 2.95004 19.071 3.1499 19.2709L4.72913 20.8501C4.92899 21.05 5.25303 21.05 5.45289 20.8501L7.03213 19.2709C7.23199 19.071 7.55603 19.071 7.75589 19.2709L9.33512 20.8501C9.53499 21.05 9.85903 21.05 10.0589 20.8501L11.6381 19.2709C11.838 19.071 12.162 19.071 12.3619 19.2709L13.9411 20.8501C14.141 21.05 14.465 21.05 14.6649 20.8501L16.2441 19.2709C16.444 19.071 16.768 19.071 16.9679 19.2709L18.5471 20.8501C18.747 21.05 19.071 21.05 19.2709 20.8501L20.8501 19.2709C20.9461 19.1749 21 19.0447 21 18.909C21 18.7732 20.9461 18.6431 20.8501 18.5471L19.2709 16.9679C19.071 16.768 19.071 16.444 19.2709 16.2441L20.8501 14.6649C20.9461 14.5689 21 14.4387 21 14.303C21 14.1673 20.9461 14.0371 20.8501 13.9411L19.2709 12.3619C19.071 12.162 19.071 11.838 19.2709 11.6381L20.8501 10.0589C21.05 9.85903 21.05 9.53499 20.8501 9.33512L18.909 7.394L20.8501 5.4529C21.05 5.25304 21.05 4.929 20.8501 4.72914L19.2709 3.1499C19.071 2.95004 18.747 2.95004 18.5471 3.1499L16.9679 4.72914C16.768 4.929 16.444 4.929 16.2441 4.72914L14.6649 3.1499C14.465 2.95004 14.141 2.95004 13.9411 3.1499L12.3619 4.72914C12.162 4.929 11.838 4.929 11.6381 4.72914L10.0589 3.1499C9.85902 2.95004 9.53498 2.95004 9.33512 3.1499L7.75589 4.72914C7.55603 4.929 7.23199 4.929 7.03213 4.72914Z"
      />
    </svg>
  );
};
