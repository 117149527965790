import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const SunSeaSunrise = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M12 5V3M2 13h2M4.9 5.9 6 7M19.1 5.9 18 7M22 12.9h-2M3 17.4c1.1-.5 2.5-.5 3.6 0s2.5.5 3.6 0 2.5-.5 3.6 0 2.5.5 3.6 0 2.5-.5 3.6 0M6.6 20.6c1.1.5 2.5.5 3.6 0s2.5-.5 3.6 0 2.5.5 3.6 0M7 13c0-2.8 2.2-5 5-5s5 2.2 5 5" />
    </svg>
  );
};
