import React from "react";
import styled, { css } from "styled-components";

import { Container, Margins, Paragraph } from "@ui/Atoms";
import { Heading, HeadingTypes } from "@ui/Atoms/Headings";
import { Column, Row } from "@ui/Atoms/Grid";
import { DraggableCarousel } from "@ui/Molecules/DraggableCarousel";
import { renderIcon, Steps, Icon } from "@ui/Molecules/Steps";
import { rem } from "@ui/helpers";
import theme from "@ui/themes/default";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useBrowserRerender } from "@hooks/useBrowserRerender";

import { StyledSection } from "./shared";

export interface HowItWorksProps {
  // data props
  how_it_works_title: { text: string };
  how_it_works_title_mobile: { text: string };
  how_it_works_step_one_title?: { text: string };
  how_it_works_step_one: { text: string };
  how_it_works_step_one_icon: string;
  how_it_works_step_two_title?: { text: string };
  how_it_works_step_two: { text: string };
  how_it_works_step_two_icon: string;
  how_it_works_step_three_title?: { text: string };
  how_it_works_step_three: { text: string };
  how_it_works_step_three_icon: string;
  how_it_works_bottom_text?: { text: string };

  // section props
  isWhite?: boolean;
  iconsColor?: string;
  stepTextColor?: string;
  iconsSize?: number;
  iconsSecondaryColor?: string;
  hasLargePadding?: boolean;
  hasGradientBackground?: boolean;
  headingSize?: HeadingTypes;
}

interface Step {
  text: string;
  icon: Icon;
  title?: string | undefined;
}

const HowItWorks = (props: HowItWorksProps): React.ReactElement => {
  const isBrowser = useBrowserRerender();
  const { mobile, tablet } = useBreakpoint();
  const isMobile = mobile || tablet;

  if (isBrowser && isMobile) {
    const stepsList: Step[] = [
      {
        icon: props.how_it_works_step_one_icon as Icon,
        text: props.how_it_works_step_one.text,
        title: props.how_it_works_step_one_title?.text,
      },
      {
        icon: props.how_it_works_step_two_icon as Icon,
        text: props.how_it_works_step_two.text,
        title: props.how_it_works_step_two_title?.text,
      },
      {
        icon: props.how_it_works_step_three_icon as Icon,
        text: props.how_it_works_step_three.text,
        title: props.how_it_works_step_three_title?.text,
      },
    ];

    /**
     * Mobile view
     */
    return (
      <Section
        isWhite={props.isWhite}
        hasGradient={props.hasGradientBackground}
        hasLargePadding={props.hasLargePadding}
      >
        <Container>
          <Margins xs={[null, "base_x4", null, null]}>
            <Heading
              type="h2"
              styleAs={props.headingSize || "h4"}
              textAlignXs="left"
              textAlignMd="left"
            >
              {props.how_it_works_title_mobile?.text}
            </Heading>
          </Margins>

          <DraggableCarousel
            slidesToShow={{
              mobile: 1,
              tablet: 2,
              desktop: 3,
            }}
          >
            {stepsList.map((step: Step, index) => (
              <StepContent key={index}>
                <IconWrapper>
                  {renderIcon({
                    icon: step.icon,
                    iconsColor: props.iconsColor,
                    iconsSecondaryColor: props.iconsSecondaryColor,
                    iconsSize: isBrowser ? props.iconsSize : 64,
                  })}
                  <StepNumber
                    iconsColor={props.iconsColor}
                    isWhite={props.isWhite}
                  >
                    {index + 1}
                  </StepNumber>
                </IconWrapper>
                <StepTitle>{step.title}</StepTitle>
                <StepText stepTextColor={props.stepTextColor}>
                  {step.text}
                </StepText>
              </StepContent>
            ))}
          </DraggableCarousel>
          {props.how_it_works_bottom_text?.text && (
            <BottomText>{props.how_it_works_bottom_text.text}</BottomText>
          )}
        </Container>
      </Section>
    );
  }

  /**
   * Desktop view
   */
  return (
    <StyledSection
      isWhite={props.isWhite}
      hasGradient={props.hasGradientBackground}
      hasLargePadding={props.hasLargePadding}
    >
      <Container>
        <Margins xs={[null, "base_x8", null, null]}>
          <Heading
            type="h2"
            styleAs={props.headingSize || "h4"}
            textAlignLg="center"
          >
            {props.how_it_works_title?.text}
          </Heading>
        </Margins>

        <Row>
          <Column pushLg={1} lg={10}>
            <Row>
              <Steps
                steps={[
                  {
                    icon: props.how_it_works_step_one_icon as Icon,
                    markdownContent: props.how_it_works_step_one.text,
                    title: props.how_it_works_step_one_title?.text,
                  },
                  {
                    icon: props.how_it_works_step_two_icon as Icon,
                    markdownContent: props.how_it_works_step_two.text,
                    title: props.how_it_works_step_two_title?.text,
                  },
                  {
                    icon: props.how_it_works_step_three_icon as Icon,
                    markdownContent: props.how_it_works_step_three.text,
                    title: props.how_it_works_step_three_title?.text,
                  },
                ]}
                iconsColor={props.iconsColor}
                stepTextColor={props.stepTextColor}
                numberBackground={props.isWhite ? theme.colors.white : ""}
                iconsSize={isBrowser ? props.iconsSize : 96}
              />
            </Row>
          </Column>
        </Row>
        {props.how_it_works_bottom_text?.text && (
          <BottomText>{props.how_it_works_bottom_text.text}</BottomText>
        )}
      </Container>
    </StyledSection>
  );
};

export { HowItWorks };

const Section = styled(StyledSection)`
  overflow: hidden;
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin-bottom: 0;
  }
`;

const StepTitle = styled.div`
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: ${rem(64)};
  position: relative;
  margin-bottom: ${props => props.theme.margins.base_x2};

  // icon has padding, we have to revert it
  transform: translateX(-10px);
`;

const StepNumber = styled.span<{ iconsColor?: string; isWhite?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: ${props => props.theme.margins.base};
  right: -${rem(2)};
  width: ${rem(21)};
  height: ${rem(21)};
  z-index: 2;
  border-radius: 50%;
  background-color: ${props =>
    props.isWhite ? props.theme.colors.white : props.theme.colors.greySurface};
  border: ${rem(2)} solid
    ${props => props.iconsColor || props.theme.colors.blue400};
  color: ${props => props.iconsColor || props.theme.colors.blue400};
  font-weight: ${props => props.theme.fonts.weights.bold};
  text-align: center;
  font-size: ${props => props.theme.fonts.sizes.body2};
`;

const BottomText = styled(Paragraph)`
  display: none;

  ${props => props.theme.breakpoints.desktop} {
    display: block;
    margin: ${props => `${props.theme.margins.base_x3} auto 0`};
    font-weight: ${props => props.theme.fonts.weights.bold};
    font-size: ${props => props.theme.fonts.sizes.subtitle};
    text-align: center;
  }
`;

const StepText = styled.p<{ stepTextColor?: string }>`
  font-size: ${props => props.theme.fonts.sizes.body};

  ${props =>
    props.stepTextColor &&
    css`
      color: ${props.stepTextColor};
    `}
`;
