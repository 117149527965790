import { Record } from "immutable";
import { AnyAction } from "redux";

import { ServiceLocationState } from "@typings/serviceLocation";

import { ACTION_SUCCESS } from "@services/AsyncActionCreatorFactory";

import { FETCH_SERVICE_LOCATION, RESET_STATE } from "./serviceLocation.actions";

const initialState = Record<ServiceLocationState>({
  zip: "",
  city: "",
  hideCityName: false,
  bookingUrl: "",
  serviceTypes: [],
});

const serviceLocationReducer = (
  store: Record<ServiceLocationState> = initialState(),
  action: AnyAction,
): Record<ServiceLocationState> => {
  const { type, payload } = action;

  switch (type) {
    case `${FETCH_SERVICE_LOCATION}${ACTION_SUCCESS}`: {
      return store.merge(payload.data);
    }
    case RESET_STATE: {
      return initialState();
    }
    default: {
      return store;
    }
  }
};

export { serviceLocationReducer };
