import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const BathBathroom = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.527 9.12H11.474C10.791 9.12 10.309 8.451 10.525 7.804V7.804C10.86 6.798 11.801 6.12 12.861 6.12H13.139C14.199 6.12 15.14 6.798 15.475 7.804V7.804C15.691 8.451 15.209 9.12 14.527 9.12V9.12Z"
      />
      <path d="M6.99299 13.263H3.99999C3.38599 13.263 2.91699 13.811 3.01199 14.417L3.35599 16.617C3.65999 18.565 5.33699 20 7.30799 20H16.692C18.663 20 20.34 18.565 20.644 16.618L20.988 14.418C21.083 13.812 20.614 13.264 20 13.264H12.263" />
      <path d="M13 6.12V6C13 4.343 14.343 3 16 3V3C17.657 3 19 4.343 19 6V13.263" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.158 17.263H9.105C7.943 17.263 7 16.321 7 15.158V13.153C7 12.516 7.516 12 8.153 12H11.11C11.747 12 12.263 12.516 12.263 13.153V15.158C12.263 16.321 11.321 17.263 10.158 17.263Z"
      />
      <path d="M6.04 19.8L5 21" />
      <path d="M19 21L17.96 19.8" />
    </svg>
  );
};
