import styled, { css } from "styled-components";

import { rem } from "@ui/helpers";
import { TaskListStar } from "@ui/Assets/MyIcons/TaskListStar";

import { ChecklistAdProps } from "./ChecklistAd";

const Container = styled.div`
  position: relative;
  padding: ${({ theme }) => `${theme.margins.base} ${theme.margins.base_x3}`};
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.checklistGreen700};
  overflow: hidden;
`;

const Wrapper = styled.div<Pick<ChecklistAdProps, "variant">>`
  position: relative;
  z-index: 1;

  ${props =>
    props.variant === "sidebar" &&
    css`
      max-width: ${rem(265)};
    `}
`;

const Heading = styled.div`
  font-size: ${props => props.theme.fonts.sizes.body};

  strong {
    font-weight: ${props => props.theme.fonts.weights.semibold};
  }
`;

const Description = styled.div`
  font-size: ${props => props.theme.fonts.sizes.body2};
  line-height: ${rem(22)};
`;

const Icon = styled.div<Pick<ChecklistAdProps, "variant">>`
  position: absolute;
  color: ${props => props.theme.colors.checklistGreen500};

  ${props =>
    props.variant === "sidebar" &&
    css`
      bottom: ${rem(-21)};
      right: ${rem(-18)};
      opacity: 0.6;
    `}

  ${props =>
    props.variant === "section" &&
    css`
      bottom: ${rem(-20)};
      right: ${rem(-7)};
      opacity: 0.5;
    `}
`;

const StyledTaskListStar = styled(TaskListStar)`
  stroke-width: 0.55;
  transform: scaleX(-1);
`;

export { Container, Wrapper, Heading, Description, Icon, StyledTaskListStar };
