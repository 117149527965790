import * as React from "react";

import { UNCOVERED_LOCATIONS_ENDPOINT } from "@config/endpoints";

import { MODALS } from "@typings/modals";

import { ModalContentUncoveredCodeSubscription } from "@ui/Organisms/ModalContentUncoveredCodeSubscription";
import { Modal } from "@ui/Molecules/Modal2";

import { intlNumberFormatCurrency } from "@services/IntlNumberFormatCurrency";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";

import { showToast } from "@containers/Toastify";

import { useTranslate } from "@hooks/useTranslate";
import { useConfig } from "@hooks/useConfig";
import { useModalManager } from "@hooks/modal/useModalManager";

import { asyncActionCreator } from "@app/services/AsyncActionCreator";

const UncoveredCodeSubscriptionModal = (): React.ReactElement => {
  const { hideModal, getOption } = useModalManager();
  const translate = useTranslate("batmaid_pwa_generic");
  const welcomeAmount = useConfig(["welcomeRewardAmount"]);
  const currency = useConfig(["currency"]);

  const { uncoveredCode = "", serviceType = "" } = getOption<{
    uncoveredCode: string;
    serviceType: string;
  }>(MODALS.UNCOVERED_CODE_SUBSCRIPTION);

  const onFormSubmit = async (email: string) => {
    const { onSuccess, onError } = await asyncActionCreator({
      url: UNCOVERED_LOCATIONS_ENDPOINT,
      action: "",
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        email,
        zipCode: uncoveredCode,
        ...(serviceType && { serviceType }),
      },
    });

    onError(err => {
      showToast(err.message || translate("something_went_wrong"), {
        type: "dark",
      });
    });

    onSuccess(() => {
      showToast(translate("covered_area_notification"), {
        type: "success",
      });
    });

    hideModal(MODALS.UNCOVERED_CODE_SUBSCRIPTION);
  };

  const formattedWelcomeAmount = intlNumberFormatCurrency(
    welcomeAmount,
    currency,
  );

  return (
    <Modal
      cancelText=""
      submitText=""
      title={translate("uncovered_code_subscription_title")}
      withContainedHeight
      withFooter={false}
      onRequestClose={() => hideModal(MODALS.UNCOVERED_CODE_SUBSCRIPTION)}
    >
      <ModalContentUncoveredCodeSubscription
        onFormSubmit={onFormSubmit}
        translations={{
          infoText: translate("uncovered_code_subscription_info", {
            amount: formattedWelcomeAmount,
          }),
          submitText: translate("submit"),
          emailPlaceholderText: translate("email"),
          emailRequiredText: translate("validate_is_required"),
          emailWrongFormatText: translate("email_wrong_format"),
        }}
      />
    </Modal>
  );
};

export { UncoveredCodeSubscriptionModal };

UncoveredCodeSubscriptionModal.displayName = "UncoveredCodeSubscriptionModal";
