/**
 * @author jakubmaslon
 * @since 2020-02-27
 */

import * as React from "react";
import styled, { keyframes, withTheme } from "styled-components";

import { rem } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  theme: ITheme;
}

const loadingAnimation = keyframes`
  0% {
    transform: none;
  }

  20% {
    transform: translateX(-2.5%) rotate(10deg);
  }

  50% {
    transform: translateX(2.5%) rotate(-10deg);
  }

  100% {
    transform: translateX(-2.5%) rotate(10deg);
  }
`;

const BellSvg = styled.svg`
  display: block;
  max-width: ${rem(50)};
  min-width: ${rem(50)};
  height: auto;

  ${props => props.theme.breakpoints.tablet} {
    max-width: ${rem(125)};
    min-width: ${rem(125)};
  }
`;

const Bell = styled.path`
  transform-origin: center center;
  transform: translateX(-2.5%) rotate(10deg);
  animation-duration: 1s;
  animation-name: ${loadingAnimation};
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.94, 0.05, 0.94, 0.57);
`;

export const Loader = withTheme((props: Props) => (
  <BellSvg viewBox="0 0 1006 1005" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(.076477)" fill="none" fillRule="evenodd">
      <ellipse
        fill={props.theme.colors.black700}
        cx="502.961761"
        cy="502.5"
        rx="502.961761"
        ry="502.5"
      />
      <Bell
        className="bell"
        d="M475.444727 788.306501h53.541161c-5.583017 15.413796-14.870014 21.998966-29.543583 20.490043-12.19381-1.252691-20.623853-7.333938-23.997578-20.490043M478.767206 460.741983c-17.258669-15.220198-18.164023-18.499972-7.143187-36.575737 14.952578-24.518583 11.789532-51.317634 9.753909-77.581444-4.170892-53.848641-9.614405-107.600482-14.679263-161.377946-2.835638-30.132918 9.372408-46.116119 35.596359-46.130362 23.983343-.011381 37.748711 13.671424 36.043343 38.873294-2.234915 33.068201-6.787309 65.974121-9.540382 99.016699-2.872649 34.468938-4.928201 69.009051-7.057776 103.532082-.896814 14.528372 3.202903 28.102989 9.847861 40.883287 11.595935 22.306444 11.669957 22.266586-4.515383 39.43415 9.489136 3.089022 19.231657 5.355255 28.205482 9.349631 34.739405 15.450808 54.16466 42.434915 55.787464 80.4484 1.745227 40.857663 5.466289 81.225637 18.912791 120.135935 6.832861 19.772592 15.257209 38.708158 30.349292 53.874263 5.079093 5.104717 10.832932 9.230057 17.509206 11.863555 4.526771 1.782238 9.511913 3.373726 9.660674 8.993754.172952 6.841402-6.24709 7.450666-10.836495 9.261374-16.008825 6.309008-32.977096 8.840014-49.87704 10.28915-88.522564 7.595864-177.047974 8.48983-265.416799-2.294703-13.087776-1.597181-26.058824-4.336019-38.201388-10.058541-8.105481-3.817861-9.050694-10.266374-1.725297-13.472124 38.901756-17.030907 48.64143-53.079944 59.135566-88.553881 8.646417-29.224717 11.948966-59.443046 12.777451-89.783796 1.463371-53.561091 25.492266-84.331743 77.396388-97.539094 2.269079-.577946 4.46983-1.434901 8.017224-2.587946"
        fill={props.theme.colors.white}
      />
    </g>
  </BellSvg>
));

Loader.displayName = "Loader";
