import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Ghost = (props: Icon): React.ReactElement => (
  <svg
    style={{ ...style(props.size, props.height), ...props.style }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    ref={props.elementRef}
  >
    <defs>
      <path
        d="M12 4c-3.313 0-6 2.893-6 6.461V20l2-1.385L10 20l2-1.385L14 20l2-1.385L18 20v-9.539C18 6.893 15.314 4 12 4zm-2.333 7.333a1 1 0 11-.001-1.999 1 1 0 010 2zm4.666 0a1 1 0 110-1.999 1 1 0 010 2z"
        id="prefix__a-ghost"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="prefix__b-ghost" fill="#fff">
        <use xlinkHref="#prefix__a-ghost" />
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#prefix__a-ghost" />
      <g
        mask="url(#prefix__b-ghost)"
        fill={props.color || theme.colors.black500}
      >
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
