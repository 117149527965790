import * as React from "react";
import styled, { css } from "styled-components";

import twint_logo from "@assets/images/twint_logo.png";

import theme from "@ui/themes/default";

type Position = "left" | "right";

interface Props {
  className?: string;
  position?: Position;
}

const TwintLogo = (props: Props): React.ReactElement => (
  <StyledTwintLogo
    src={twint_logo}
    alt="Twint"
    height={theme.icons.sizes.base_x3}
    className={props.className}
    position={props.position}
  />
);

export { TwintLogo };

const StyledTwintLogo = styled.img<{ position?: Position }>`
  display: block;
  margin-bottom: ${props => props.theme.margins.base};

  ${props => props.theme.breakpoints.tablet} {
    display: inline;
    margin-bottom: 0;

    ${({ position }) =>
      position !== "right" &&
      css`
        margin-right: ${props => props.theme.margins.base};
      `}

    ${({ position }) =>
      position === "right" &&
      css`
        margin-left: ${props => props.theme.margins.base};
      `}
  }
`;
