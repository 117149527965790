import { createSelector } from "reselect";

import { ModalOptions, MODAL_STATE } from "@typings/modals";

import { IStore } from "@soft/redux/reducers";

const getModalsFunction = (store: IStore) => store.modals;
export const getModals = createSelector(getModalsFunction, f => f);

const getModalInfoFunction = (store: IStore, id: string): MODAL_STATE => {
  const modal = store.modals.get(id);

  if (modal) {
    return modal.get("state");
  }

  return MODAL_STATE.HIDDEN;
};

export const getModalInfo = createSelector([getModalInfoFunction], f => f);

const getModalsOptionsFunction = (store: IStore, id: string): ModalOptions => {
  const modal = store.modals.get(id);

  if (modal) {
    return modal.get("options");
  }

  return {};
};

export const getModalOptions = createSelector(
  [getModalsOptionsFunction],
  f => f,
);

const getModalAutohideOptionFunction = (store: IStore, id: string): boolean => {
  return store.modals.getIn([id, "autohide"]);
};

export const getModalAutohideOption = createSelector(
  getModalAutohideOptionFunction,
  f => f,
);
