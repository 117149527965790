import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const UserProfileCircle = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        clipRule="evenodd"
        d="M6 18.584a8.62 8.62 0 0 1 6.023-2.463c2.308 0 4.408.919 5.977 2.416A8.62 8.62 0 0 1 11.977 21C9.669 21 7.569 20.081 6 18.584Z"
      />
      <path d="M3.975 16.025A8.93 8.93 0 0 1 3 12c0-4.974 4.026-9 9-9s9 4.026 9 9c0 1.45-.363 2.81-.977 4.023" />
      <path d="M14.121 7.879A3 3 0 1 1 9.88 12.12 3 3 0 0 1 14.12 7.88" />
    </svg>
  );
};
