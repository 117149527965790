import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Dog = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M8.04949 17.849L6.99792 21.0038" />
      <path d="M15.9505 17.849L17.0021 21.0038" />
      <path d="M10.9996 13.0004H13.0004" />
      <path d="M7.99834 4.99708C8.86215 4.34385 9.91659 3.99237 10.9996 3.99667H13.0004C14.0834 3.99237 15.1379 4.34385 16.0017 4.99708" />
      <path d="M6.09755 7.99834C6.02863 8.32729 5.99509 8.66267 5.9975 8.99875V15.0013C5.9975 16.6588 7.34121 18.0025 8.99875 18.0025H9.99917C11.1042 18.0025 12 17.1067 12 16.0017C12 17.1067 12.8958 18.0025 14.0008 18.0025H15.0013C16.6588 18.0025 18.0025 16.6588 18.0025 15.0013V8.99875C18.0049 8.66267 17.9714 8.32729 17.9025 7.99834" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.99708 2.99625H6.99792C7.55043 2.99625 7.99833 3.44416 7.99833 3.99667V5.9975C7.99833 7.10253 7.10253 7.99834 5.9975 7.99834V7.99834C4.89247 7.99834 3.99667 7.10253 3.99667 5.9975V3.99667C3.99667 3.44416 4.44457 2.99625 4.99708 2.99625Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0021 2.99625H19.0029C19.5554 2.99625 20.0033 3.44416 20.0033 3.99667V5.9975C20.0033 7.10253 19.1075 7.99834 18.0025 7.99834V7.99834C16.8975 7.99834 16.0017 7.10253 16.0017 5.9975V3.99667C16.0017 3.44416 16.4496 2.99625 17.0021 2.99625Z"
      />
      <path d="M14.0008 18.0025V19.0029C14.0008 19.8317 13.329 20.5035 12.5002 20.5035H11.4998C10.671 20.5035 9.99917 19.8317 9.99917 19.0029V18.0025" />
      <path d="M12 13.0004V16.0017" />
      <path d="M14.2513 9.49928C14.2511 9.63732 14.1391 9.74912 14.001 9.74906C13.863 9.749 13.7511 9.63711 13.7511 9.49906C13.751 9.36102 13.8628 9.24903 14.0008 9.24885C14.0673 9.24877 14.131 9.27512 14.178 9.32211C14.225 9.36909 14.2513 9.43283 14.2513 9.49928" />
      <path d="M10.2496 9.49928C10.2494 9.63732 10.1374 9.74912 9.99938 9.74906C9.86134 9.749 9.74945 9.63711 9.74939 9.49906C9.74933 9.36102 9.86113 9.24903 9.99917 9.24885C10.0656 9.24877 10.1294 9.27512 10.1763 9.32211C10.2233 9.36909 10.2497 9.43283 10.2496 9.49928" />
    </svg>
  );
};
