import * as React from "react";

import { style, Icon } from "./Symbolicons";

export const Headset = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <rect
      width="4"
      height="8"
      x="5"
      y="9"
      rx="2"
      fill="none"
      stroke={props.color || "currentColor"}
      strokeWidth="2"
    ></rect>
    <rect
      width="4"
      height="8"
      x="15"
      y="9"
      rx="2"
      fill="none"
      stroke={props.color || "currentColor"}
      strokeWidth="2"
    ></rect>
    <path
      d="M1 13v-1A11 11 0 0112 1a11 11 0 0111 11v5a4 4 0 01-4 4h-5"
      fill="none"
      stroke={props.color || "currentColor"}
      strokeWidth="2"
    ></path>
    <circle
      cx="12"
      cy="21"
      r="2"
      fill="none"
      stroke={props.color || "currentColor"}
      strokeWidth="2"
    ></circle>
  </svg>
);
