import * as React from "react";

import { HIDE_MODAL_AFTER } from "@config/consts";

import { SimpleObject } from "@typings/globals";
import { ModalReducer, MODAL_STATE } from "@typings/modals";

import { ModalView } from "./Modal.view";

export type Size = "thin" | "base" | "base_x2" | "large";
export type Padding = "normal" | "base_x9" | "none";

interface Props {
  auto?: boolean;
  cancel?: (e?: any) => void;
  cancelText: string;
  centeredContent?: boolean;
  children?: React.ReactNode;
  className?: string;
  close?: (e?: any) => void;
  id: string;
  isSubmitBtnDisabled?: boolean;
  isSubmitBtnLoading?: boolean;
  padding?: Padding;
  size?: Size;
  submit?: (e?: any) => void;
  submitText: string;
  testId?: string;
  title?: string | React.ReactElement;
  withContainedHeight?: boolean;
  withFooter?: boolean;
  withHeader?: boolean;
  withCancelBtn?: boolean;
  withMobileHeaderGutter?: boolean;
  submitBtnColor?: "green" | "blue" | "red";
  isBottomVariant?: boolean;
  customHeader?: React.ReactNode;
  isMobileFullScreen?: boolean;
  withOldCloseIcon?: boolean;

  // redux based props
  getInfo: (id: string) => MODAL_STATE;
  getOptions: (id: string) => SimpleObject;
  getAutohide: (id: string) => boolean;
  showModal: (id: string) => void;
  hideModal: (id: string) => void;
  modals: ModalReducer;
}

const useConstructor = (callBack: () => void) => {
  const hasBeenCalled = React.useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

const Modal = (props: Props): React.ReactElement | null => {
  const [visible, setVisible] = React.useState<boolean>(false);

  let autohideTimeout: number;

  const checkModal =
    typeof props.modals !== "undefined" &&
    typeof props.modals.get(props.id) !== "undefined";

  useConstructor(() => {
    if (!checkModal && props.auto) {
      props.showModal(props.id);
    }
  });

  const close = (): void => {
    if (checkModal) {
      window.clearTimeout(autohideTimeout);
      setVisible(false);

      props.hideModal(props.id);
      typeof props.close === "function" && props.close();
    }
  };

  const hideWithEsc = (e: KeyboardEvent): void => {
    if (e.key === "Escape") {
      close();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", hideWithEsc);

    return () => {
      document.removeEventListener("keydown", hideWithEsc);
      window.clearTimeout(autohideTimeout);
    };
  }, []);

  const shouldHide = props.getAutohide(props.id);

  const modalVisibilityState = props.getInfo(props.id);
  const modalOptions = props.getOptions(props.id);

  if (checkModal) {
    if (shouldHide) {
      autohideTimeout = window.setTimeout(() => {
        close();
      }, HIDE_MODAL_AFTER);
    }

    return (
      <ModalView
        {...props}
        padding={props.padding || ("normal" as Padding)}
        close={close}
        visible={modalVisibilityState === MODAL_STATE.VISIBLE}
        options={modalOptions}
      />
    );
  }

  return null;
};

export { Props as ModalProps, Modal };
