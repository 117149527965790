/**
 * @author tomekbuszewski & jakubbujakowski
 * @since 2019-3-8
 */

import * as React from "react";
import { connect } from "react-redux";
import { Record } from "immutable";

import {
  GIFT_CARDS_LINK,
  HELP_LINK_BATWORK,
  BECOME_A_BATWORKER_LINK,
} from "@config/links";
import { ROUTES } from "@config/work.routing";
import { IS_BROWSER } from "@config/consts";
import * as HTTP_CODE from "@config/httpStatuses";

import {
  AVAILABLE_LANGS,
  AVAILABLE_COUNTRIES,
  APP_NAMES,
} from "@typings/globals";
import { MODALS } from "@typings/modals";
import { IUser } from "@typings/user";

import { Header as HeaderUI } from "@ui/Organisms/Header";
import {
  Navigation as NavigationUI,
  NavLink,
  NavbarToggle,
} from "@ui/Organisms/Navbar";
import { UserDropdown } from "@ui/Organisms/UserDropdown";
import { LanguageDropdownBasic } from "@ui/Organisms/LanguageDropdownBasic";

import { setLanguageInUrl } from "@services/SetLanguageInUrl";
import { resolveCountryFromUrl } from "@services/ResolveCountryFromUrl";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { ToastContainer } from "@containers/Toastify";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useTranslate } from "@hooks/useTranslate";
import { useOnClickOutside } from "@hooks/useOnClickOutside";

import { hideMenu, showMenu } from "@work/redux/navigation/navigation.actions";
import { getNavigationState } from "@work/redux/navigation/navigation.selectors";
import { IStore } from "@work/redux/reducers";
import { fetchUserData, logoutUser } from "@work/redux/user/user.actions";
import { showModal } from "@work/redux/modals/modals.actions";
import {
  getCountry,
  getLanguage,
} from "@work/redux/localisation/localisation.selectors";
import {
  getUserData,
  isUserAdmin,
  isUserLoggedIn,
  isUserClient,
} from "@work/redux/user/user.selectors";
import {
  setCountry,
  setLanguage,
} from "@work/redux/localisation/localisation.actions";

import {
  createHref,
  getLanguagesList,
  getUserNavigationConfig,
} from "./Header.helpers";
import { HeaderModals } from "./Header.modals";

interface Props {
  // component-props
  children?: React.ReactNode;
  useNativeLinks: boolean;
  pathname?: string;
  staticLanguage?: AVAILABLE_LANGS;
  isSSR?: boolean;

  // redux-props
  fetchUserData: () => Promise<AsyncActionResult>;
  country: AVAILABLE_COUNTRIES;
  hideMenu: () => void;
  isUserAdmin: boolean;
  isUserClient: boolean;
  isUserLoggedIn: boolean;
  language: AVAILABLE_LANGS;
  logoutUser: () => Promise<AsyncActionResult>;
  menuState: boolean;
  setCountry: (flag: string) => void;
  setLanguage: (lang: string) => void;
  showMenu: () => void;
  showModal: (id: string, options?: { [k: string]: any }) => void;
  userData: Record<IUser> | null;
}

const HeaderComponent = (props: Props) => {
  const { mobile, tablet } = useBreakpoint();
  const translate = useTranslate();
  const [isMenuActive, setIsMenuActive] = React.useState<boolean>(false);

  const menuEffectDeps = IS_BROWSER ? [window.location.href] : [];
  const language = props.staticLanguage || props.language;
  const isMobile = mobile || tablet;

  const ref = useOnClickOutside(() => setIsMenuActive(false));

  React.useEffect(() => {
    setIsMenuActive(false);
  }, menuEffectDeps);

  const handleSetLanguage = (lang: AVAILABLE_LANGS) => {
    setLanguageInUrl(lang);
    props.setLanguage(lang);
  };

  const logoutUser = async () => {
    await props.logoutUser();
    window.location.href = "/";
  };

  const loginUser = () => {
    props.showModal(MODALS.NAVBAR_LOGIN, {
      onOAuthLoginSuccess: () => null,
    });
  };

  React.useEffect(() => {
    (async () => {
      const { onError } = await props.fetchUserData();

      onError(
        () => {
          props.logoutUser();
        },
        { matchCode: HTTP_CODE.UNAUTHORIZED },
      );

      props.setCountry(resolveCountryFromUrl());
    })();
  }, []);

  React.useEffect(() => {
    if (props.menuState) {
      props.hideMenu();
    }
  }, [props.pathname, props.menuState]);

  return (
    <React.Fragment>
      {/** Main View */}
      <HeaderUI
        currentLanguage={language}
        variant={APP_NAMES.BATWORK}
        isSSR={props.isSSR}
        loginUser={loginUser}
        rowRef={ref}
      >
        <NavigationUI active={isMenuActive} variant={APP_NAMES.BATWORK}>
          {!isMobile && (
            <React.Fragment>
              {/* 
                First NavLink won't get styles, that's why we use empty one as the first NavLink. 
                This is a temporary solution for a styled-components issue.
              */}
              <NavLink>
                <a href={"#"} style={{ display: "none" }} />
              </NavLink>
              {!props.isUserClient && (
                <NavLink>
                  <a href={createHref(language, BECOME_A_BATWORKER_LINK)}>
                    {translate("batwork_pwa_navigation.become_a_batworker")}
                  </a>
                </NavLink>
              )}
              <NavLink>
                <a href={createHref(language, HELP_LINK_BATWORK)}>
                  {translate("batwork_pwa_navigation.help")}
                </a>
              </NavLink>
              <NavLink special>
                <a href={createHref(language, GIFT_CARDS_LINK)}>
                  {translate("batwork_pwa_navigation.gift_cards")}
                </a>
              </NavLink>
            </React.Fragment>
          )}

          <UserDropdown
            isUserLoggedIn={props.isUserClient || props.isUserAdmin}
            userData={props.userData}
            currentLanguage={language}
            baseUrl={ROUTES.CLIENT_DASHBOARD}
            useNativeLinks={props.useNativeLinks}
            isDark={false}
            skipLoginButton
            loginLabel={translate("batwork_pwa_navigation.login")}
            loginUser={loginUser}
            config={getUserNavigationConfig({
              exitUserUrl:
                props.userData && props.userData.get("exitUserUrl")
                  ? props.userData.get("exitUserUrl")
                  : "",
              logoutUrl: props.userData ? props.userData.get("logoutUrl") : "",
              isUserClient: props.isUserClient,
              isUserAdmin: props.isUserAdmin,
              isMobile: isMobile,
              language: props.language,
              translate,
              logoutAction: logoutUser,
            })}
          />
        </NavigationUI>
        <LanguageDropdownBasic
          isDark={false}
          currentLanguage={language}
          languages={getLanguagesList(translate)}
          setLanguage={handleSetLanguage}
        />
        <NavbarToggle
          onClick={() => setIsMenuActive(!isMenuActive)}
          active={isMenuActive}
          variant={APP_NAMES.BATWORK}
          label={translate("batwork_pwa_navigation.toggle_menu")}
        />
      </HeaderUI>

      {/** Extras */}
      <HeaderModals />
      <ToastContainer />
    </React.Fragment>
  );
};

const mapState = (store: IStore) => ({
  country: getCountry(store),
  isUserAdmin: isUserAdmin(store),
  isUserClient: isUserClient(store),
  isUserLoggedIn: isUserLoggedIn(store),
  language: getLanguage(store),
  menuState: getNavigationState(store),
  userData: getUserData(store),
});

const mapDispatch = {
  fetchUserData,
  hideMenu,
  logoutUser,
  setCountry,
  setLanguage,
  showMenu,
  showModal,
};

export const Header = connect(mapState, mapDispatch)(HeaderComponent);
