import { AnyAction } from "redux";
import { Record, Map, List } from "immutable";

import { defaultValues } from "@config/work.defaultValues";

import { BookingPricing } from "@typings/booking";
import { SalaryDefaults, BookingSalary } from "@typings/salary";
import { Contract } from "@typings/contracts";

import { getQueryValue } from "@services/QueryParamsParser";
import {
  ACTION_FAIL,
  ACTION_SUCCESS,
} from "@services/AsyncActionCreatorFactory";
import { stringifyFalsies } from "@services/StringifyFalsies";

import {
  BookingReducer,
  BookingReducerState,
  BookingAvailability,
  BookingRegistrationForm,
  MapOfChosenAgents,
} from "./booking.typings";
import { BOOKING_ACTIONS } from "./booking.actions";
import {
  bookingTasks,
  pricingInitialState,
  bookingAvailabilityInitialState,
  bookingAvailabilityFactory,
  chosenAgentIdsFactory,
  mapChosenAgentsFactory,
  sicknessInsuranceCostsFactory,
  secondPillarRetirementCostsFactory,
  salaryDefaultsFactory,
  registerFormInitialState,
  salaryFactory,
  previousEmployeeFactory,
  agentsFactory,
} from "./booking.factories";

export const getServiceLocationIdFromUrl = (url: string): number =>
  !isNaN(Number(getQueryValue(url, "serviceLocationId")))
    ? Number(getQueryValue(url, "serviceLocationId"))
    : defaultValues.booking.serviceLocationId;

const initialState = Record<BookingReducerState>({
  serviceLocationId: defaultValues.booking.serviceLocationId,
  tasks: bookingTasks,
  keyPick: defaultValues.booking.keyPick,
  locationComment: defaultValues.booking.locationComment,
  hasPets: defaultValues.booking.pets.selectedOptionValue,
  specialInstructions: defaultValues.booking.specialInstructions,
  frequency: defaultValues.booking.cleaningFrequency,
  pricing: Record<BookingPricing>(pricingInitialState)(),
  calendar: Record<BookingAvailability>(bookingAvailabilityInitialState)(),
  executionDateTime: defaultValues.booking.executionDateTime,
  reservationUuid: defaultValues.booking.reservationUuid,
  isExecutionDateTimeValidated:
    defaultValues.booking.isExecutionDateTimeValidated,
  chosenAgentIds: defaultValues.booking.chosenAgentIds,
  chosenAgents: defaultValues.booking.chosenAgents as MapOfChosenAgents,
  chosenAgentId: defaultValues.booking.chosenAgentId,
  prevSicknessInsurance: defaultValues.booking.sicknessInsurance,
  sicknessInsurance: defaultValues.booking.sicknessInsurance,
  sicknessInsuranceCosts: Record(
    defaultValues.booking.sicknessInsuranceCosts,
  )(),
  professionalAccidentInsurance:
    defaultValues.booking.professionalAccidentInsurance,
  agreeToProfessionalAccidentInsurance:
    defaultValues.booking.agreeToProfessionalAccidentInsurance,
  insurancePolicyNumber: defaultValues.booking.insurancePolicyNumber,
  insurancePolicyWillBeProvidedLater:
    defaultValues.booking.insurancePolicyWillBeProvidedLater,
  prevSecondPillarRetirement: defaultValues.booking.secondPillarRetirement,
  secondPillarRetirement: defaultValues.booking.secondPillarRetirement,
  secondPillarRetirementCosts: Record(
    defaultValues.booking.secondPillarRetirementCosts,
  )(),
  salaryDefaults: Record<SalaryDefaults>(
    defaultValues.booking.salaryDefaults,
  )(),
  salaryDefaultsFetched: defaultValues.booking.salaryDefaultsFetched,
  salary: Record<BookingSalary>(defaultValues.booking.salary)(),
  sliderSuperGrossSalary: defaultValues.booking.salarySlider.superGrossSalary,
  sliderChangeCount: defaultValues.booking.sliderChangeCount,
  queryParams: defaultValues.booking.queryParams,
  locationUuid: defaultValues.booking.locationUuid,
  form: Record<BookingRegistrationForm>(registerFormInitialState)(),
  mainLocationUuid: defaultValues.booking.mainLocationUuid,
  hasSomePaymentAliases: defaultValues.booking.hasSomePaymentAliases,
  plan: defaultValues.booking.plan,
  isCheckoutDone: defaultValues.booking.isCheckoutDone,
  previousEmployee: defaultValues.booking.previousEmployee,
  hasPreviousActiveContract: defaultValues.booking.hasPreviousActiveContract,
  relaxPlanRedirectUrl: defaultValues.booking.relaxPlanRedirectUrl,
  isRelaxPlanCompleted: defaultValues.booking.isRelaxPlanCompleted,
  previousAgents: List(),
});

const bookingReducer = (
  store: BookingReducer = initialState(),
  action: AnyAction,
): BookingReducer => {
  const { payload, type } = action;

  switch (type) {
    case BOOKING_ACTIONS.SET_FROM_LOCAL_STORAGE: {
      return action.payload || store;
    }
    case BOOKING_ACTIONS.SET_SERVICE_LOCATION_ID:
      return store.set("serviceLocationId", payload);
    case BOOKING_ACTIONS.SET_TASK: {
      return store.mergeIn(["tasks", payload.service], {
        ...payload,
      });
    }
    case BOOKING_ACTIONS.SET_KEY_PICK: {
      return store.set("keyPick", payload);
    }
    case `${BOOKING_ACTIONS.FETCH_PAYMENT_ALIASES}${ACTION_SUCCESS}`: {
      return store.set("hasSomePaymentAliases", payload.data.length > 0);
    }
    case BOOKING_ACTIONS.SET_LOCATION_COMMENT: {
      return store.set("locationComment", payload);
    }
    case BOOKING_ACTIONS.SET_PETS: {
      return store.set("hasPets", payload);
    }
    case BOOKING_ACTIONS.SET_PROFESSIONAL_ACCIDENT_INSURANCE: {
      return store.set("professionalAccidentInsurance", payload);
    }
    case BOOKING_ACTIONS.SET_AGREE_TO_PROFESSIONAL_ACCIDENT_INSURANCE: {
      return store.set("agreeToProfessionalAccidentInsurance", payload);
    }
    case BOOKING_ACTIONS.SET_INSURANCE_POLICY_NUMBER: {
      return store.set("insurancePolicyNumber", payload);
    }
    case BOOKING_ACTIONS.SET_INSURANCE_POLICY_WILL_BE_PROVIDED_LATER: {
      return store.set("insurancePolicyWillBeProvidedLater", payload);
    }
    case BOOKING_ACTIONS.SET_SICKNESS_INSURANCE: {
      return store
        .set("prevSicknessInsurance", payload.prevValue)
        .set("sicknessInsurance", payload.newValue);
    }
    case BOOKING_ACTIONS.SET_SECOND_PILLAR_RETIREMENT: {
      return store
        .set("prevSecondPillarRetirement", payload.prevValue)
        .set("secondPillarRetirement", payload.newValue);
    }
    case BOOKING_ACTIONS.SET_SPECIAL_INSTRUCTIONS: {
      return store.set("specialInstructions", payload);
    }
    case BOOKING_ACTIONS.SET_FREQUENCY: {
      return store.set("frequency", payload.frequency);
    }
    case `${BOOKING_ACTIONS.MAKE_CHECKOUT}${ACTION_SUCCESS}`: {
      return store.set("isCheckoutDone", true);
    }
    case `${BOOKING_ACTIONS.FETCH_AVAILABILITY}${ACTION_SUCCESS}`: {
      return store.set("calendar", bookingAvailabilityFactory(payload.data));
    }
    case BOOKING_ACTIONS.SET_WEEK_START_DATE: {
      return store.setIn(["calendar", "weekStartDate"], payload);
    }
    case BOOKING_ACTIONS.SET_EXECUTION_DATETIME: {
      return store
        .set("chosenAgentIds", chosenAgentIdsFactory(payload, store))
        .set("executionDateTime", payload)
        .set("isExecutionDateTimeValidated", true);
    }
    case `${BOOKING_ACTIONS.MAKE_RESERVATION}${ACTION_SUCCESS}`: {
      return store.set("reservationUuid", payload.data.reservationUuid);
    }
    case `${BOOKING_ACTIONS.SET_AGENTS_METADATA}${ACTION_SUCCESS}`: {
      return store.set(
        "chosenAgents",
        mapChosenAgentsFactory(
          payload.data,
          initialState().get("chosenAgents"),
        ),
      );
    }
    case `${BOOKING_ACTIONS.SET_AGENTS_METADATA}${ACTION_FAIL}`: {
      return store.set("chosenAgents", Map());
    }
    case `${BOOKING_ACTIONS.RESET_AGENTS_METADATA}`: {
      return store.set("chosenAgents", Map());
    }
    case `${BOOKING_ACTIONS.SET_CHOSEN_AGENT_ID}`: {
      return store.set("chosenAgentId", payload);
    }
    case `${BOOKING_ACTIONS.RESET_CHOSEN_AGENT_ID}`: {
      return store.set("chosenAgentId", null);
    }
    case `${BOOKING_ACTIONS.FETCH_SICKNESS_INSURANCE_COSTS}${ACTION_SUCCESS}`: {
      return store.set(
        "sicknessInsuranceCosts",
        sicknessInsuranceCostsFactory(payload.data),
      );
    }
    case `${BOOKING_ACTIONS.FETCH_SECOND_PILLAR_RETIREMENT_COSTS}${ACTION_SUCCESS}`: {
      return store.set(
        "secondPillarRetirementCosts",
        secondPillarRetirementCostsFactory(payload.data),
      );
    }
    case `${BOOKING_ACTIONS.FETCH_SALARY_DEFAULTS}${ACTION_SUCCESS}`: {
      return store
        .set("salaryDefaults", salaryDefaultsFactory(payload.data))
        .set("salaryDefaultsFetched", true)
        .set("sliderSuperGrossSalary", payload.data.defaultSuperGrossSalary)
        .set("sliderChangeCount", store.get("sliderChangeCount") + 1);
    }
    case `${BOOKING_ACTIONS.FETCH_SALARY}${ACTION_SUCCESS}`: {
      return store.set("salary", salaryFactory(payload.data));
    }
    case BOOKING_ACTIONS.SET_QUERY_PARAMS: {
      return store.set("queryParams", payload);
    }
    case BOOKING_ACTIONS.SET_LOCATION_UUID: {
      return store.set("locationUuid", payload);
    }
    case `${BOOKING_ACTIONS.ADD_LOCATION}${ACTION_SUCCESS}`: {
      return store.set("locationUuid", payload.data.uuid);
    }
    case BOOKING_ACTIONS.SET_REGISTRATION_FORM: {
      return store.mergeIn(["form"], payload);
    }
    case BOOKING_ACTIONS.SET_PLAN: {
      return store.set("plan", payload);
    }
    case `${BOOKING_ACTIONS.REGISTER_CLIENT}${ACTION_SUCCESS}`: {
      return store
        .set("locationUuid", payload.data.mainLocationUuid)
        .set("mainLocationUuid", payload.data.mainLocationUuid);
    }
    case BOOKING_ACTIONS.SET_SLIDER_SUPERGROSS_SALARY: {
      return store
        .set("sliderSuperGrossSalary", payload)
        .set("sliderChangeCount", store.get("sliderChangeCount") + 1);
    }
    case `${BOOKING_ACTIONS.FETCH_SUPERGROSS_SALARY}${ACTION_SUCCESS}`: {
      return store
        .set("sliderSuperGrossSalary", payload.data.amount)
        .set("sliderChangeCount", store.get("sliderChangeCount") + 1);
    }
    case `${BOOKING_ACTIONS.FETCH_EMPLOYEE}${ACTION_SUCCESS}`: {
      return store.set(
        "previousEmployee",
        previousEmployeeFactory(payload.data),
      );
    }
    case `${BOOKING_ACTIONS.FETCH_SALARY_PROPOSAL}${ACTION_SUCCESS}`: {
      const resp: Contract = payload.data;
      return store
        .set("hasPreviousActiveContract", true)
        .set("sliderSuperGrossSalary", resp.grossSalary)
        .set("sliderChangeCount", store.get("sliderChangeCount") + 1)
        .set("secondPillarRetirement", resp.secondPillarRetirement)
        .set("sicknessInsurance", resp.sicknessInsurance)
        .set("professionalAccidentInsurance", !!resp.insurancePolicy)
        .set(
          "insurancePolicyNumber",
          stringifyFalsies(resp.insurancePolicyNumber),
        )
        .set(
          "insurancePolicyWillBeProvidedLater",
          !!resp.insurancePolicyWillBeProvidedLater,
        );
    }
    case `${BOOKING_ACTIONS.MAKE_BATSOFT_TRANSFER}${ACTION_SUCCESS}`: {
      const { redirectTo } = payload.data;

      return store
        .set("relaxPlanRedirectUrl", redirectTo)
        .set("isRelaxPlanCompleted", true);
    }
    case BOOKING_ACTIONS.RESET_IS_RELAX_PLAN_COMPLETED: {
      return store.set("isRelaxPlanCompleted", false);
    }
    case `${BOOKING_ACTIONS.FETCH_PREVIOUS_AGENTS}${ACTION_SUCCESS}`: {
      return store.set("previousAgents", agentsFactory(payload.data));
    }
    default:
      break;
  }

  return store;
};

export { bookingReducer };
