import React from "react";

import { JOB_BY_UUID_ENDPOINT } from "@config/endpoints";

import { Job } from "@typings/jobs";

import { ContactForm as ContactFormOrganism } from "@ui/Organisms/ContactForm";
import { SkeletonGenerator } from "@ui/Atoms";

import { sendContactForm } from "@services/ContactForm";
import { IntlDateFormat, intlFormatDate } from "@services/IntlFormatDate";

import { useTranslate } from "@hooks/useTranslate";
import { useTelemetry } from "@hooks/useTelemetry";
import { getIsUserDataFetched, getUserData } from "@hooks/useAuth/redux/user";

import { useAppSelector } from "@app/redux/hooks";

import { asyncActionCreator } from "@www/src/services/AsyncActionCreator";

const getQuery = () => {
  if (typeof window !== "undefined") {
    return new URLSearchParams(window.location.search);
  }
  return new URLSearchParams();
};

const ContactForm = (): React.ReactElement | null => {
  const translate = useTranslate("batmaid_pwa_generic");
  const { elementClick } = useTelemetry();
  const [jobDetails, setJobDetails] = React.useState<null | Job>(null);

  const jobDate = intlFormatDate(
    new Date(jobDetails?.executionDate ?? 0),
    IntlDateFormat.shortWithYear,
  );

  const query = getQuery();
  const variant = query.get("variant");
  const jobId = query.get("jobId") ?? "";

  const userData = useAppSelector(getUserData);
  const isUserDataFetched = useAppSelector(getIsUserDataFetched);

  React.useEffect(() => {
    (async () => {
      if (jobId && !jobDetails) {
        const { onSuccess } = await asyncActionCreator({
          url: JOB_BY_UUID_ENDPOINT.replace("{uuid}", jobId),
          action: "",
        });

        onSuccess(payload => setJobDetails(payload.data));
      }
    })();
  }, [jobId]);

  const reasonsList =
    variant === "b2b"
      ? [
          translate("contact_form_b2b_reason_change_cleaning_daytime"),
          translate("contact_form_b2b_reason_change_cleaning_duration"),
          translate("contact_form_b2b_reason_additional_services"),
          translate("contact_form_b2b_reason_replacement"),
          translate("contact_form_b2b_reason_important_information"),
          translate("contact_form_b2b_reason_other"),
        ]
      : [
          translate("contact_form_reason_bookings"),
          translate("contact_form_reason_our_services"),
          translate("contact_form_reason_trust_and_security"),
          translate("contact_form_reason_sponsorship_and_giftcards"),
          translate("contact_form_reason_my_account"),
          translate("contact_form_reason_payment"),
          translate("contact_form_reason_policies"),
          translate("contact_form_reason_contracts"),
        ];

  if (!isUserDataFetched) {
    return <SkeletonGenerator count={2} customHeight={270} />;
  }

  return (
    <ContactFormOrganism
      userName={
        userData
          ? `${userData?.get("firstName")} ${userData?.get("lastName")}`
          : ""
      }
      userEmail={userData?.get("email") ?? ""}
      reasonAsSelect
      onSubmitAction={payload => {
        const requestBody = jobDetails
          ? {
              ...payload,
              message: payload.message + `\n\n${translate("job")}: ${jobDate}`,
            }
          : payload;

        elementClick("send_email_submit_form", {
          buttonId: "send_email_submit_form",
        });
        return sendContactForm(requestBody);
      }}
      onEmailTabClick={() =>
        elementClick("send_email_contact_form", {
          buttonId: "send_email_contact_form",
        })
      }
      onCallTabClick={() =>
        elementClick("give_call_contact_form", {
          buttonId: "give_call_contact_form",
        })
      }
      translations={{
        emailTabText: translate("contact_form_email_tab_md"),
        callTabText: translate("contact_form_call_tab"),
        messageSuccessText: translate("contact_form_message_sent"),
        introText: translate("contact_form_intro"),
        nameLabelText: translate("contact_form_name_placeholder"),
        emailLabelText: translate("contact_form_email_placeholder"),
        reasonLabelText: translate("contact_form_reason_placeholder"),
        tellUsMoreLabelText: translate("contact_form_tell_us_more_placeholder"),
        submitButtonText: translate("contact_form_send_email_button"),
        callUsTabInfoText: translate("contact_form_call_us_info"),
        callHoursText: translate("contact_form_call_hours"),
        phoneNumberText: translate("contact_form_phone"),
        isRequiredText: translate("validate_is_required"),
        invalidEmailText: translate("email_wrong_format"),
        reasonsList,
      }}
    />
  );
};

export { ContactForm };
