/**
 * @author tomekbuszewski
 * @since 2019-3-22
 */

import React, { memo } from "react";
import styled from "styled-components";

import {
  directions,
  shapeHeights,
  shapes,
} from "@ui/Atoms/Skeleton/Skeleton.helpers";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { Skeleton } from "@ui/Atoms/Skeleton";

interface Props {
  count: number;
  randomWidth?: number;
  randomHeight?: number;
  direction?: directions;
  shape?: shapes;
  noGutter?: boolean;
  customHeight?: number;
  testId?: string;
  className?: string;
}

const SkeletonGenerator = memo((props: Props): React.ReactElement => {
  const hasRandomHeight = props.randomHeight && props.randomHeight > 0;
  const hasRandomWidth = props.randomWidth && props.randomWidth > 0;

  return (
    <TestWrapper testId={props.testId}>
      <Container
        direction={props.direction || "column"}
        className={props.className}
      >
        {/* eslint-disable no-mixed-operators */}
        {Array.from({ length: props.count }, () => {
          const minHeightForShape =
            props.customHeight || shapeHeights[props.shape || "row"];
          const height = hasRandomHeight
            ? `${Math.floor(
                Math.random() * (minHeightForShape - props.randomHeight! + 1) +
                  props.randomHeight!,
              )}px`
            : `${minHeightForShape}px`;
          const width = hasRandomWidth
            ? `${Math.floor(Math.random() * (100 - 75 + 1) + 75)}%`
            : `100%`;
          return { width, height };
        }).map((item: { width: string; height: string }) => (
          <Skeleton
            style={{ width: item.width, minHeight: item.height }}
            key={`k-${item.width}-${Math.random() * (10 - 1) + 1}`}
            direction={props.direction || "column"}
            shape={props.shape || "row"}
            noGutter={props.noGutter}
          />
        ))}
        {/* eslint-enable no-mixed-operators */}
      </Container>
    </TestWrapper>
  );
});

export { SkeletonGenerator };

SkeletonGenerator.displayName = "SkeletonGenerator";

const Container = styled.div<{ direction: directions }>`
  display: flex;
  flex-direction: ${props => (props.direction === "column" ? "column" : "row")};
`;
