import * as React from "react";
import styled from "styled-components";

interface MessageProps {
  bold?: boolean;
  color: string;
  className?: string;
  children: string;
}

const CleaningStatusMessage = (props: MessageProps) => (
  <CleaningStatusMessageWrapper
    className={props.className}
    bold={props.bold}
    color={props.color}
  >
    {props.children}
  </CleaningStatusMessageWrapper>
);

const CleaningStatusMessageWrapper = styled.span<{
  bold?: boolean;
  color: string;
}>`
  font-weight: ${props => props.bold && props.theme.fonts.weights.bold};
  color: ${props => props.theme.colors[props.color]};
  white-space: pre-line;
`;

CleaningStatusMessage.defaultProps = { bold: true };

export { CleaningStatusMessage };
