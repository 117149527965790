import React from "react";
import styled from "styled-components";
import { SliceComponentProps } from "@prismicio/react";
import { Script, ScriptStrategy } from "gatsby";

import { AVAILABLE_LANGS } from "@typings/globals";

import { Container } from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { langInTrustpilotFormat } from "@services/LangInTrustpilotFormat";
import { langFromPathname } from "@services/LangFromPathname";

import { PrismicGlobalContentPageDataBodyTrustpilotWidget } from "../../../graphql-types";
import { SliceContainer } from "../../components/SliceContainer";

const TrustPilotWidgetSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyTrustpilotWidget>,
): React.ReactElement => {
  const [loaded, setLoaded] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!loaded) return;

    (window as any).Trustpilot?.loadFromElement(ref?.current);
  }, [loaded]);

  const language = langFromPathname() || AVAILABLE_LANGS.EN;
  const primary = props.slice.primary;

  return (
    <StyledContainer>
      <Container widthVariant="fullMobile">
        <TrustpilotWidgetStyled
          id="trustpilot-widget"
          data-locale={langInTrustpilotFormat(language)}
          data-template-id={primary?.template_id?.text}
          data-businessunit-id={primary?.business_unit_id?.text}
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars={primary?.stars?.text}
          data-review-languages={language}
          ref={ref}
        >
          <TrustpilotSpaceKeeper />
        </TrustpilotWidgetStyled>
        <Script
          key="trustpilot"
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          strategy={ScriptStrategy.idle}
          onLoad={() => setLoaded(true)}
        />
      </Container>
    </StyledContainer>
  );
};

export { TrustPilotWidgetSlice };

const TrustpilotWidgetStyled = styled.div`
  width: 100%;
`;

const TrustpilotSpaceKeeper = styled.div`
  height: ${rem(140)};
`;

const StyledContainer = styled(SliceContainer)`
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.margins.base_x4} 0;
`;
