import { createSelector } from "reselect";

import { IStore } from "@work/redux/reducers";

const getLocationsCollectionFn = (store: IStore) =>
  store.locations.get("locations");

export const getLocationsCollection = createSelector(
  getLocationsCollectionFn,
  f => f,
);

const getLocationsFn = (store: IStore) => store.locations;
export const getLocations = createSelector(
  getLocationsFn,
  f => f,
);

export const getDefaultLocationIdFn = (store: IStore) =>
  store.locations.get("defaultLocationId");

export const getDefaultLocationId = createSelector(
  getDefaultLocationIdFn,
  f => f,
);

export const getLocationByIdFn = (store: IStore, uuid: string) =>
  store.locations.getIn(["locations", uuid]);

export const getLocationById = createSelector(
  getLocationByIdFn,
  f => f,
);
