import React, { ReactElement } from "react";

import { OptionButton } from "@ui/Atoms";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

import { RadioGroupComponent } from "./RadioGroup.types";
import * as Styled from "./RadioGroup.styled";

export const RadioGroup: RadioGroupComponent = ({
  value,
  name,
  onChange,
  testId,
  ...props
}) => {
  const checkedIndex = React.Children.toArray(props.children).findIndex(
    (radio: ReactElement<{ value: string | number | readonly string[] }>) => {
      return Array.isArray(value)
        ? String(radio.props.value) === String(value)
        : radio.props.value == value;
    },
  );

  return (
    <TestWrapper testId={testId}>
      <Styled.Root
        spacing={props.variant === "ordinary" ? props.spacing : undefined}
        grid={props.variant === "ordinary" ? props.grid : undefined}
        withPadding={props.variant === "select"}
      >
        {props.variant === "select" && (
          <React.Fragment>
            <Styled.OptionButtonSelect
              name={name}
              onChange={onChange}
              disabled={!props.children[checkedIndex - 1]}
              value={props.children[checkedIndex - 1]?.props.value}
              testId={props.children[checkedIndex - 1]?.props.testId}
              checked={false}
            >
              <Styled.LeftSelect />
            </Styled.OptionButtonSelect>
            <Styled.SelectValue>
              {props.children[checkedIndex]?.props.children}
            </Styled.SelectValue>
            <Styled.OptionButtonSelect
              name={name}
              onChange={onChange}
              disabled={!props.children[checkedIndex + 1]}
              value={props.children[checkedIndex + 1]?.props.value}
              testId={props.children[checkedIndex + 1]?.props.testId}
              checked={false}
            >
              <Styled.RightSelect />
            </Styled.OptionButtonSelect>
          </React.Fragment>
        )}

        {props.variant === "ordinary" &&
          React.Children.map(props.children, (radio, index) => {
            return React.cloneElement(radio, {
              name,
              checked: checkedIndex === index,
              onChange,
            });
          })}
      </Styled.Root>
    </TestWrapper>
  );
};

RadioGroup.OptionButton = OptionButton;
