import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const LivingRoomColorfulAsteriskLamp = (
  props: Icon,
): React.ReactElement => (
  <svg
    style={{ ...style(props.size), ...props.style }}
    viewBox="0 0 168 104"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="On-boarding"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="how-it-works-step-3---8pt-copy-2"
        transform="translate(-104.000000, -288.000000)"
      >
        <g
          id="Organism/widget/onboarding/step-3"
          transform="translate(32.000000, 184.000000)"
        >
          <g
            id="Atom/artwork/salon"
            transform="translate(72.000000, 104.000000)"
          >
            <path
              d="M160.030738,34.6010132 L159.144578,34 L160.030738,33.3989258 C160.392219,33.1926269 160.517242,32.7316894 160.308581,32.3743897 C160.099797,32.0172119 159.633183,31.8936768 159.271701,32.0998535 L158.385542,32.7009278 L158.385542,31.75 C158.385542,31.3375244 158.043951,31 157.626505,31 C157.20906,31 156.867469,31.3375244 156.867469,31.75 L156.867469,32.7009278 L155.981309,32.0998536 C155.619828,31.8936768 155.153214,32.017212 154.944429,32.3743897 C154.735769,32.7316895 154.860792,33.192627 155.222273,33.3989259 L156.108433,34 L155.222273,34.6010132 C154.860792,34.8073121 154.735769,35.2683106 154.944429,35.6255494 C155.153214,35.9827881 155.619828,36.1063233 155.981309,35.9000855 L156.867469,35.2990722 L156.867469,36.25 C156.867469,36.6624756 157.20906,37 157.626505,37 C158.043951,37 158.385542,36.6624756 158.385542,36.25 L158.385542,35.2990722 L159.271701,35.9000855 C159.633183,36.1063233 160.099797,35.9827881 160.308581,35.6255493 C160.517242,35.2683106 160.392219,34.807312 160.030738,34.6010132 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
