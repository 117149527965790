export enum SMARTLOOK_IDS {
  START_BOOKING = "start-booking",
  THX_FOR_BOOKING_MODAL_GO_TO_MY_CLEANINGS = "thank-you-for-booking-go-to-my-cleanings",
  THX_FOR_BOOKING_MODAL_CLOSE_BUTTON = "thank-you-for-booking-close-button",
  RESCHEDULE_ACTION_BUTTON = "reschedule-action-button",
  RESCHEDULE_DETAILS_PAGE = "reschedule-details-page",
  RESCHEDULE_CONFIRMATION_PAGE = "reschedule-confirmation-page",
  SHOW_DETAILS_ACTION = "show-details-action",
  EXTENDED_JOB_DETAILS = "extended-job-details",
  OPT_IN_VIEW_OPTIONS = "opt-in-view-options",
}
