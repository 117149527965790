import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled, { css } from "styled-components";

import { Container } from "@ui/Atoms";
import { DraggableCarousel } from "@ui/Molecules/DraggableCarousel";
import { rem } from "@ui/helpers";

import { GatsbyImage } from "../../components/GatsbyImage";

import { StyledSection } from "./shared";

interface Partner {
  partner_logo: { gatsbyImageData: IGatsbyImageData; alt: string };
  partner_link: { url: string | null; target: string | null };
}

export interface PartnersProps {
  partners_title?: {
    text: string;
  };
  partners_logos: Partner[];
}

interface Props extends PartnersProps {
  noPaddingBottom?: boolean;
  backgroundColor?: string;
}

const Partners = (props: Props): React.ReactElement | null => {
  if (!props.partners_logos.length) {
    return null;
  }

  return (
    <Section
      noPaddingBottom={props.noPaddingBottom}
      backgroundColor={props.backgroundColor}
      hasPartners={!!props.partners_logos.length}
    >
      <Container>
        <DraggableCarousel
          isVerticallyCentered
          whiteButtons
          slidesToShow={{
            mobile: 3,
            tablet: 5,
            desktop: props.partners_logos?.length > 4 ? 5 : 4,
          }}
          slidesAmount={props.partners_logos?.length}
        >
          {props.partners_logos?.map((item, index) => {
            const linkAttrs = {
              href: item.partner_link?.url || "#",
              target: item.partner_link?.target || "_self",
            };
            return (
              <ImageLink key={index} {...linkAttrs}>
                <StepContent>
                  <GatsbyImage
                    image={item.partner_logo.gatsbyImageData}
                    alt={item.partner_logo?.alt || "company logo"}
                    objectFit="contain"
                  />
                </StepContent>
              </ImageLink>
            );
          })}
        </DraggableCarousel>
      </Container>
    </Section>
  );
};

export { Partners };

const Section = styled(StyledSection)<{
  noPaddingBottom?: boolean;
  hasPartners: boolean;
}>`
  overflow: hidden;
  padding: ${props => props.theme.margins.base_x3};

  ${props =>
    props.noPaddingBottom &&
    css`
      padding-bottom: 0;
    `}

  /* additional hiding the section when is no partners */
  ${props =>
    !props.hasPartners &&
    css`
      display: none;
    `}
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${rem(144)};

  img {
    max-height: ${rem(30)};
    /* stylelint-disable-next-line declaration-no-important -- GatsbyImage styling needs to be overwritten */
    margin: auto !important;
  }

  ${props => props.theme.breakpoints.desktop} {
    min-width: ${rem(180)};
    max-width: ${rem(180)};
  }
`;

const ImageLink = styled.a<{ href: string }>`
  ${props =>
    (props.href === "#" || props.href === "") &&
    css`
      pointer-events: none;
    `}
`;
