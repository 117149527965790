import { formatISO, addDays, format, roundToNearestMinutes } from "date-fns";
import { List, Map, OrderedMap } from "immutable";

import {
  AVAILABLE_CURRENCIES,
  PROMO_CODE_NOTIFICATION,
  WEEKDAYS,
} from "@typings/globals";
import {
  EXTRA_SERVICES_BOOKING,
  CLEANING_FREQUENCY,
  KEY_PICK,
  AGENT_PREFERENCE,
} from "@typings/booking";
import { PAYMENT_METHODS } from "@typings/globals";
import { PaymentAliasType } from "@typings/payments";
import {
  PREMISES,
  FREQUENCY,
  EXTRA_SERVICES_B2B,
  B2B_SHIFTS,
  KEY_PICK_B2B,
} from "@typings/b2b";
import { LOADING_STATE } from "@typings/reduxThunkActions";
import { RESCHEDULING_TRIGGERED_BY } from "@typings/jobs";

import { DATE_FORMAT } from "@services/DateFormatter";

import { END_OF_TENANCY_EXTRA_SERVICES } from "@app/redux/endOfTenancy/endOfTenancy.typings";

import { IS_PROD } from "./consts";

export const defaultValues = {
  misc: {
    minPasswordLength: 6,
  },
  booking: {
    lowAgentAvailability: 2,
    bedroomNo: 2,
    bathroomNo: 1,
    pets: {
      selectedOptionValue: false,
      types: [],
    },
    serviceLocationId: 0,
    cleaningFrequency: CLEANING_FREQUENCY.WEEKLY,
    frequencies: List(Object.keys(CLEANING_FREQUENCY) as CLEANING_FREQUENCY[]),
    preselectedAgentUuid: "",
    agentPreference: AGENT_PREFERENCE.ANY,
    isChoosingAnyBatmaid: true,
    executionDateTime: formatISO(
      new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000), // today + 48h
    ),
    reservationUuid: "",
    isCleaningChecklistAvailable: false,
    previousAgents: List(),
    previousAgentsLoadingState: LOADING_STATE.IDLE,
    voucherToken: "",
    voucherMessage: "",
    voucherStatus: PROMO_CODE_NOTIFICATION.NONE,
    specialInstructions: null,
    keyPick: KEY_PICK.SOMEONE_AT_HOME,
    locationComment: "",
    locationUuid: "",
    mainLocationUuid: "",
    selectedPaymentMethod: null,
    templateJobId: null,
    queryParams: "",
    pricingLoadingState: LOADING_STATE.IDLE,
    priceWillChange: false,
    availabilityLoadingState: LOADING_STATE.IDLE,
    isExecutionDateTimeValidated: false,
    tasks: {
      [EXTRA_SERVICES_BOOKING.HOME_CLEANING]: {
        selected: true,
        duration: 3,
      },
      [EXTRA_SERVICES_BOOKING.INTERIOR_WINDOWS]: {
        selected: false,
        duration: 0.5,
        maxDuration: 4,
      },
      [EXTRA_SERVICES_BOOKING.IRONING]: {
        selected: false,
        duration: 0.5,
        maxDuration: 4,
      },
      [EXTRA_SERVICES_BOOKING.LAUNDRY_WASH_AND_DRY]: {
        selected: false,
        duration: 1,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_OVEN]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_CABINETS]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.INSIDE_FRIDGE]: {
        selected: false,
        duration: 0.5,
      },
      [EXTRA_SERVICES_BOOKING.BONUS_15]: {
        selected: false,
        duration: 0.25,
      },
    },
    homeCleaningTime: {
      max: 15,
      min: 0,
      step: 0.5,
    },
    homeCleaningTimeAdmin: {
      min: 0,
    },
    pricePlan: null,
    nonStandardFrequencyCleaningDuration: {
      maxValue: 15,
      minValue: 2,
      default: 2,
      step: 0.5,
    },
    timesPerWeek: {
      maxValue: 6,
      minValue: 2,
    },
    nonStandardFrequencyShifts: [
      {
        dayOfWeek: WEEKDAYS.MON,
        shift: "08:00",
        duration: 2,
      },
      {
        dayOfWeek: WEEKDAYS.TUE,
        shift: "08:00",
        duration: 2,
      },
    ],
    contactAfterBooking: false,
    hasServiceVouchers: false,
    hasUrssaf: false,
  },
  endOfTenancy: {
    keyPick: KEY_PICK.SOMEONE_AT_HOME,
    locationComment: "",
    specialInstructions: null,
    showOffer: false,
    squareMeters: null,
    skipAgentAssignment: false,
    warranty: false,
    warrantyPriceAndCurrency: { warrantyPrice: 0, currency: "CHF" },
    requestDifferentStartingHour: false,
    executionDate: format(addDays(new Date(), 2), DATE_FORMAT.YEAR_MONTH_DAY),
    selectedPaymentMethod: null,
    serviceLocationId: 0,
    reservationUuid: "",
    voucherToken: "",
    voucherMessage: "",
    voucherStatus: PROMO_CODE_NOTIFICATION.NONE,
    locationUuid: "",
    mainLocationUuid: "",
    isPricingFetched: false,
    queryParams: "",
    tasks: {
      [END_OF_TENANCY_EXTRA_SERVICES.END_OF_TENANCY_CLEANING]: {
        selected: true,
      },
      [END_OF_TENANCY_EXTRA_SERVICES.INSIDE_FRIDGE]: {
        selected: true,
      },
      [END_OF_TENANCY_EXTRA_SERVICES.INSIDE_OVEN]: {
        selected: true,
      },
      [END_OF_TENANCY_EXTRA_SERVICES.DOOR_HANDLES]: {
        selected: true,
      },
      [END_OF_TENANCY_EXTRA_SERVICES.BASEBOARDS]: {
        selected: true,
      },
      [END_OF_TENANCY_EXTRA_SERVICES.INTERIOR_WINDOWS]: {
        selected: true,
      },
      [END_OF_TENANCY_EXTRA_SERVICES.INSIDE_CABINETS]: {
        selected: true,
      },
      [END_OF_TENANCY_EXTRA_SERVICES.WINDOW_BLINDS]: {
        selected: false,
      },
      [END_OF_TENANCY_EXTRA_SERVICES.BALCONY]: {
        selected: false,
      },
    },
  },
  b2b: {
    serviceLocationId: 0,
    surfaceArea: "",
    recommendedDurationsApplied: false,
    recommendedDuration: 0,
    premises: PREMISES.OFFICE,
    premisesServicesFetched: false,
    duration: {
      max: 15,
      min: 1.5,
      minAdmin: 0.5,
      default: 2,
      step: 0.5,
    },
    preselectedAgentUuid: "",
    agentPreference: AGENT_PREFERENCE.ANY,
    isChoosingAnyBatmaid: true,
    timesPerWeek: {
      maxValue: 6,
      minValue: 2,
    },
    tasks: {
      [EXTRA_SERVICES_B2B.B2B_CLEANING]: {
        selected: true,
        duration: 2,
      },
      /*
        cleaning one glass wall = 15 min, two = 30m, three = 45m...
        the minimum number of glass walls is 2
      */
      [EXTRA_SERVICES_B2B.GLASS_WALLS]: {
        duration: 0.5,
        durationStep: 0.25,
        minDuration: 0.5,
        maxDuration: 5,
      },
    },
    frequency: FREQUENCY.WEEKLY,
    hasCleaningMaterials: true,
    executionDateTime: formatISO(
      roundToNearestMinutes(new Date(), { nearestTo: 30 }),
    ),
    isExecutionDateTimeValidated: false,
    availabilityLoadingState: LOADING_STATE.IDLE,
    nonStandardFrequencyCleaningDuration: {
      maxValue: 15,
      minValue: 1.5,
      default: 2,
      step: 0.5,
    },
    nonStandardFrequencyShifts: [
      {
        dayOfWeek: WEEKDAYS.MON,
        shift: B2B_SHIFTS.FROM_EIGHT_TO_EIGHTEEN,
        duration: 2,
      },
      {
        dayOfWeek: WEEKDAYS.TUE,
        shift: B2B_SHIFTS.FROM_EIGHT_TO_EIGHTEEN,
        duration: 2,
      },
    ],
    specialPricingTranslatedMessage: "",
    displayedPriceForFirstBooking: 0,
    hasSpecialPricing: false,
    currency: AVAILABLE_CURRENCIES.CHF,
    locationUuid: "",
    form: {
      name: "",
      phone: "",
      vat: "",
      sdi: "",
      email: "",
      address: "",
      serviceLocationId: IS_PROD ? 0 : 1,
      floorAndDoor: "",
      entryCode: "",
      contactPersonFirstName: "",
      contactPersonLastName: "",
      contactPersonPhone: "",
      contactPersonEmail: "",
      loginEmail: "",
      password: "",
      marketingConsent: false,
    },
    contactAfterBooking: false,
    reservationUuid: "",
    hasPets: false,
    specialInstructions: null,
    keyPick: KEY_PICK_B2B.SOMEONE_WILL_BE_THERE,
    locationComment: "",
    selectedPaymentMethod: null,
    pricingLoadingState: LOADING_STATE.IDLE,
    voucherToken: "",
    voucherMessage: "",
    voucherStatus: PROMO_CODE_NOTIFICATION.NONE,
    iDontKnowFrequencyNotification: false,
    iDontKnowDurationNotification: false,
    queryParams: "",
  },
  getAQuote: {
    serviceLocationId: IS_PROD ? 0 : 1,
    premises: PREMISES.OFFICE,
    premisesServicesFetched: false,
    form: {
      name: "",
      address: "",
      email: "",
      phone: "",
      serviceLocationId: IS_PROD ? 0 : 1,
      contactPersonFirstName: "",
      contactPersonLastName: "",
      additionalInfo: "",
    },
    additionalInfo: "",
  },
  profile: {
    referral: {
      referralCode: "",
      referralUrl: "",
      shareFacebookUrl: "",
      shareTwitterUrl: "",
    },
    accountBalance: {
      totalBalance: {
        balance: 0,
        currency: AVAILABLE_CURRENCIES.CHF,
      },
      moneyBalance: {
        balance: 0,
        currency: AVAILABLE_CURRENCIES.CHF,
      },
      durationBalance: 0,
    },
    basicInfo: {
      firstName: "",
      lastName: "",
      email: "",
      dateOfBirth: "",
      homePhone: "",
      mobilePhone: "",
      oAuthProvider: null,
      serviceVoucher: null,
    },
    paymentAliases: {
      type: PaymentAliasType.VIS,
      maskedNumber: "",
      expirationDate: "",
      uuid: "",
      default: false,
    },
    companyInfo: {
      company: {
        name: "",
        email: "",
        phone: "",
      },
      contactPerson: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
      },
    },
  },
  bookingsToValidate: {
    selectedPaymentMethod: PAYMENT_METHODS.DEFAULT,
  },
  payment: {
    status: null,
    publicMessage: null,
    referenceNumber: "",
    originRoute: "",
    targetRoute: "",
  },
  userNotifications: {
    pendingPaymentsAlert: false,
    remindMissingTrustMandate: false,
    showNewBookingReminder: false,
    hasBookingsToValidate: false,
    showHolidayAutomationAlert: false,
    trustMandateIsApproved: false,
    showMissingTrustMandate2020: false,
    showEndOfTenancyReservationWithoutJobs: false,
    showB2BSuccessMessageForInvoicePayment: false,
    showB2BSuccessMessageForNonStandardFrequencyBooking: false,
    showSuccessMessageForMoreOftenFrequencyBooking: false,
    showSuccessMessageForDryBooking: false,
  },
  giftCard: {
    quantity: {
      min: 1,
      max: 100,
      step: 1,
    },
    amount: "50",
  },
  rating: {
    job: {
      uuid: "",
      startDate: new Date(),
      endDate: new Date(),
      mainServiceType: "",
    },
    rating: 0,
    negativeRatings: [],
    positiveRatings: [],
    negativeIssues: Map(),
    positiveIssues: Map(),
    comment: "",
    pictures: OrderedMap(),
    shareWithAgent: false,
    loadingState: LOADING_STATE.IDLE,
    showReferral: false,
    allowPictures: false,
    editable: false,
    maxPictures: 0,
    pictureErrorMessage: "",
  },
  rescheduling: {
    reason: "",
    previousCleaningDate: "",
    currentCleaningDate: "",
    changeRequestRelativeUrl: "",
    previousCleaningDuration: 0,
    currentCleaningDuration: 0,
    appliedStartingFrom: "",
    triggeredBy: RESCHEDULING_TRIGGERED_BY.NONE,
    timeslots: {
      preferredAgentUuid: "",
      availabilities: [],
    },
  },
  cancellationThreshold: 48,
  agentReplacement: {
    previousCleaningDate: "",
    previousCleaningDuration: 0,
    currentCleaningDate: "",
    currentCleaningDuration: 0,
    changeRequestRelativeUrl: "",
    previousAgent: {
      id: "",
      fullName: "",
    },
    newAgent: {
      id: "",
      fullName: "",
    },
  },
};
