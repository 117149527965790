import React from "react";
import styled from "styled-components";
import { SliceComponentProps } from "@prismicio/react";

import { Container, Heading, Margins } from "@ui/Atoms";
import { rem } from "@ui/helpers";
import { DraggableCarousel } from "@ui/Molecules/DraggableCarousel";

import { PrismicGlobalContentPageDataBodyStepsWithLines } from "../../../graphql-types";
import { StyledSection } from "../Homepage/shared";

const slidesToShow = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
};

const StepsWithLinesSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyStepsWithLines>,
): React.ReactElement => {
  const { primary, items } = props.slice;

  return (
    <Section>
      <Container>
        <Margins xs={[null, "base_x2", null, null]}>
          <StyledHeading type="h2" styleAs="h3" textAlignLg="center">
            {primary?.title1?.text}
          </StyledHeading>
        </Margins>

        <DraggableCarousel hideButtons slidesToShow={slidesToShow}>
          {items?.map(step => (
            <StepContent key={step?.title1?.text}>
              <Heading type="h6">{step?.title1?.text}</Heading>
              <Subtitle>{step?.subtitle?.text}</Subtitle>
              <Description>{step?.description?.text}</Description>
              <StepLine>
                <Ball />
                <Line />
              </StepLine>
            </StepContent>
          ))}
        </DraggableCarousel>
      </Container>
    </Section>
  );
};

export { StepsWithLinesSlice };

const Section = styled(StyledSection)`
  overflow: hidden;
  padding: ${props => props.theme.margins.base_x8}
    ${props => props.theme.margins.base_x2};
`;

const StyledHeading = styled(Heading)`
  margin-bottom: ${props => props.theme.margins.base_x4};
  font-size: ${props => props.theme.fonts.sizes.h5};
  text-align: left;
  font-weight: ${props => props.theme.fonts.weights.bold};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.h3};
    text-align: center;
  }
`;

const Subtitle = styled.span`
  display: block;
  height: ${rem(21)};
  margin-bottom: ${props => props.theme.margins.base_x2};
`;

const Description = styled.p`
  display: flex;
  flex: 1;
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StepLine = styled.div`
  display: flex;
  align-items: center;
`;

const Ball = styled.div`
  min-width: ${rem(16)};
  max-width: ${rem(16)};
  min-height: ${rem(16)};
  max-height: ${rem(16)};
  border-radius: 50%;
  margin-right: ${props => props.theme.margins.base_x2};
  background: ${props => props.theme.colors.black800};
`;

const Line = styled.div`
  height: ${rem(1)};
  width: 100%;
  background: ${props => props.theme.colors.black800};
`;
