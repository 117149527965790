import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Cat = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        clipRule="evenodd"
        d="m15.076 6.417 2.389-2.047a1.538 1.538 0 0 1 2.538 1.167V13A8.003 8.003 0 0 1 12 21.004v0A8.003 8.003 0 0 1 3.997 13V5.537A1.538 1.538 0 0 1 6.535 4.37l2.39 2.047a9.227 9.227 0 0 1 6.15 0Z"
      />
      <path d="M10.25 10.75a.25.25 0 1 1-.501-.001.25.25 0 0 1 .5 0M14.25 10.75a.25.25 0 1 1-.5 0 .25.25 0 0 1 .5 0" />
      <path
        clipRule="evenodd"
        d="M12 14.501v0a.5.5 0 0 1-.5-.5v0c0-.092.074-.167.166-.167h.668c.092 0 .166.075.166.167v0a.5.5 0 0 1-.5.5Z"
      />
      <path d="M9.812 17.127c.24.24.567.376.907.375v0c.707 0 1.281-.573 1.281-1.281a1.281 1.281 0 0 0 2.188.906M12 14.501v1.72M17.002 12.807l4.002-.667M21.004 16.002l-4.002-.667M6.998 12.807l-4.002-.667M2.996 16.002l4.002-.667" />{" "}
    </svg>
  );
};
