import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const SmileysSad = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M18.364 5.636A9 9 0 1 1 5.636 18.364 9 9 0 0 1 18.364 5.636M8.5 9v1M15.5 9v1" />
      <path d="M8.5 16s1.313-1.312 3.5-1.312c2.188 0 3.5 1.312 3.5 1.312" />
    </svg>
  );
};
