import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const WhipOven = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0029 21.0037H4.99708C3.89205 21.0037 2.99625 20.1079 2.99625 19.0029V4.99708C2.99625 3.89205 3.89205 2.99625 4.99708 2.99625H19.0029C20.1079 2.99625 21.0037 3.89205 21.0037 4.99708V19.0029C21.0037 20.1079 20.1079 21.0037 19.0029 21.0037Z"
      />
      <rect x="5.56875" y="12" width="12.8625" height="6.43125" rx="1" />
      <path d="M21.0037 8.99875H2.99625" />
      <path d="M14.5511 5.99756C14.5511 6.02517 14.5287 6.04753 14.5011 6.04752C14.4735 6.04751 14.4511 6.02513 14.4511 5.99752C14.4511 5.96991 14.4734 5.94751 14.501 5.94748C14.5143 5.94746 14.5271 5.95273 14.5365 5.96213C14.5459 5.97153 14.5511 5.98427 14.5511 5.99756" />
      <path d="M17.5524 5.99756C17.5523 6.02517 17.5299 6.04753 17.5023 6.04752C17.4747 6.04751 17.4523 6.02513 17.4523 5.99752C17.4523 5.96991 17.4747 5.94751 17.5023 5.94748C17.5156 5.94746 17.5283 5.95273 17.5377 5.96213C17.5471 5.97153 17.5524 5.98427 17.5524 5.99756" />
      <path d="M9.54904 5.99756C9.54901 6.02517 9.52661 6.04753 9.499 6.04752C9.47139 6.04751 9.44901 6.02513 9.449 5.99752C9.44899 5.96991 9.47135 5.94751 9.49896 5.94748C9.51225 5.94746 9.52499 5.95273 9.53439 5.96213C9.54378 5.97153 9.54905 5.98427 9.54904 5.99756" />
      <path d="M6.54779 5.99756C6.54775 6.02517 6.52536 6.04753 6.49775 6.04752C6.47014 6.04751 6.44776 6.02513 6.44775 5.99752C6.44774 5.96991 6.4701 5.94751 6.49771 5.94748C6.51099 5.94746 6.52374 5.95273 6.53314 5.96213C6.54253 5.97153 6.5478 5.98427 6.54779 5.99756" />
    </svg>
  );
};
