/**
 * @author jakubbujakowski
 * @since 2020-3-27
 */

import * as React from "react";
import styled from "styled-components";

import { formatAmount } from "@services/FormatAmount";

interface Props {
  label: string;
  amount: number;
  currency: string;
  color?: string;
}

const PriceSummary = (props: Props) => (
  <PriceSummaryWrapper>
    <Label>{props.label}</Label>
    <Price color={props.color}>
      {formatAmount(props.currency, props.amount)}
    </Price>
  </PriceSummaryWrapper>
);

export { PriceSummary };

const Label = styled.div`
  margin-bottom: ${props => props.theme.margins.base};
  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props => props.theme.fonts.weights.normal};

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.fonts.sizes.body2};
  }
`;

const PriceSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: ${props => props.theme.fonts.weights.semibold};
`;

const Price = styled.span<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.blue400};
  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props => props.theme.fonts.weights.bold};
  flex-shrink: 0;
`;
