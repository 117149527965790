/**
 * @author jakubmaslon
 * @since 2020-02-18
 */

import * as React from "react";
import styled from "styled-components";

import { BOOKING_SUMMARY } from "@config/testIds/booking";

import { AVAILABLE_CURRENCIES } from "@typings/globals";

import { rem } from "@ui/helpers";
import {
  SummaryPrice,
  SummaryVouchers,
  PriceTitle,
  SkeletonGenerator,
  TestWrapper,
  Skeleton,
} from "@ui/Atoms";
import { InfoMessage } from "@ui/Molecules";

import { Markdown } from "@containers/Markdown";

import { useBreakpoint } from "@hooks/useBreakpoint";

interface Props {
  title: string;
  subtitle?: string;
  currency: AVAILABLE_CURRENCIES | string;
  price: number;
  priceSubtitle?: string | React.ReactElement;
  tooltip?: string;
  isLoading?: boolean;
  isPriceChanging?: boolean;
  serviceVouchersSubtitle?: string;
  hasServiceVouchersSubtitle?: boolean;
  disclaimer?: string;
  isPriceNotFixed?: boolean;
}

const SidebarSummaryFooter = (props: Props): React.ReactElement => {
  const { desktop, desktopLarge } = useBreakpoint();
  const isDesktop = desktop || desktopLarge;
  const isServiceVouchersEnabled =
    props.hasServiceVouchersSubtitle && props.serviceVouchersSubtitle;

  return (
    <SidebarSectionFooter>
      {props.isLoading && (
        <SkeletonWrapper>
          <SkeletonGenerator count={2} direction="row" />
        </SkeletonWrapper>
      )}

      {!props.isLoading && (
        <React.Fragment>
          <FooterWrapper>
            {isDesktop && (
              <PriceWrapper>
                <PriceTitle title={props.title} />
                {!props.hasServiceVouchersSubtitle && props.subtitle && (
                  <PriceTitle title={props.subtitle} variant="caption" />
                )}
              </PriceWrapper>
            )}

            {props.isPriceChanging && (
              <PriceLoader>
                <Skeleton />
              </PriceLoader>
            )}

            {!props.isPriceChanging && !isServiceVouchersEnabled && (
              <PriceWrapper>
                <SummaryPrice
                  price={props.price}
                  currency={props.currency}
                  withTooltip={!!props.tooltip}
                  tooltip={props.tooltip}
                  testId={BOOKING_SUMMARY.PRICE}
                  isPriceNotFixed={props.isPriceNotFixed}
                />
                {props.priceSubtitle}
              </PriceWrapper>
            )}

            {!props.isPriceChanging && isServiceVouchersEnabled && (
              <SummaryVouchers
                vouchersLabel={props.serviceVouchersSubtitle as string}
                tooltip={props.tooltip}
                testId={BOOKING_SUMMARY.VOUCHERS}
              />
            )}
          </FooterWrapper>

          {props.disclaimer && (
            <FooterWrapper>
              <TestWrapper testId={BOOKING_SUMMARY.DISCLAIMER}>
                <Disclaimer>
                  <InfoMessage
                    message={
                      <span>
                        <Markdown>{props.disclaimer}</Markdown>
                      </span>
                    }
                  />
                </Disclaimer>
              </TestWrapper>
            </FooterWrapper>
          )}
        </React.Fragment>
      )}
    </SidebarSectionFooter>
  );
};

export { SidebarSummaryFooter };

const FooterWrapper = styled.div`
  margin: 0;
  padding: 0;

  ${props => props.theme.breakpoints.desktop} {
    display: flex;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const SkeletonWrapper = styled.div`
  width: 100%;
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SidebarSectionFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: ${props => props.theme.buttons.borderRadius};
  border-bottom-right-radius: ${props => props.theme.buttons.borderRadius};
  text-align: right;
  color: ${props => props.theme.colors.black700};

  ${props => props.theme.breakpoints.desktop} {
    width: auto;
    padding: ${props => props.theme.margins.base_x2}
      ${props => props.theme.margins.base_x5};
    margin: -${props => props.theme.margins.base_x5};
    margin-top: ${props => props.theme.margins.base_x4};
    border-top: ${rem(1)} solid ${props => props.theme.colors.greyDark};
    text-align: left;
  }
`;

const Disclaimer = styled.div`
  margin-top: ${props => props.theme.margins.base};
  width: 100%;
`;

const PriceLoader = styled.div`
  margin: 0 -${props => rem(props.theme.icons.sizes.base_x1_5)} 0 ${props => props.theme.margins.base_x3};
  flex: 1 1 100%;

  ${Skeleton} {
    min-height: auto;
    height: ${props => props.theme.fonts.lineHeights.h4};
    margin: 0;
  }
`;
