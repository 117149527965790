import { EXCLUDED_COUNTRIES, LANGS_BY_COUNTRY } from "@config/consts";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

export const getCountriesList = (
  translate: (k: string) => string,
): { value: AVAILABLE_COUNTRIES; label: string }[] =>
  Object.values(AVAILABLE_COUNTRIES)
    .filter(country => !EXCLUDED_COUNTRIES.includes(country))
    .map(country => ({
      value: country,
      label: translate(`batmaid_pwa_generic.country_${country}`),
    }));

export const getLanguagesList = (): AVAILABLE_LANGS[] =>
  Object.values(AVAILABLE_LANGS) as AVAILABLE_LANGS[];

export const getCountryAndLanguageVariants = (): {
  country: AVAILABLE_COUNTRIES;
  language: AVAILABLE_LANGS;
}[] => {
  return Object.values(AVAILABLE_COUNTRIES)
    .filter(country => !EXCLUDED_COUNTRIES.includes(country))
    .flatMap(country => {
      return LANGS_BY_COUNTRY[country].map(language => ({ country, language }));
    });
};
