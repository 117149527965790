import React, { ReactElement } from "react";
import styled, { css } from "styled-components";

import { NAVBAR } from "@config/testIds/navbar";
import { LANGS_BY_COUNTRY } from "@config/consts";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

import { Dropdown, DropdownHeader, DropdownItem } from "@ui/Molecules/Dropdown";
import { Flag } from "@ui/Assets/Flags";
import { rem } from "@ui/helpers";
import { Margins, TestWrapper } from "@ui/Atoms";

import { useBreakpoint } from "@hooks/useBreakpoint";

interface CurrentStateProps {
  flag: AVAILABLE_COUNTRIES;
  language: AVAILABLE_LANGS;
}

interface Props {
  currentFlag: AVAILABLE_COUNTRIES;
  currentLanguage: AVAILABLE_LANGS;
  countries: { value: AVAILABLE_COUNTRIES; label: string }[];
  languages: AVAILABLE_LANGS[];
  languageHeaderText: string;
  countryHeaderText: string;
  setCountry: (country: AVAILABLE_COUNTRIES) => void;
  setLanguage: (lang: AVAILABLE_LANGS) => void;
  onToggle?: (active: boolean) => void;
}

const COUNTRY_SORT_ORDER = [
  AVAILABLE_COUNTRIES.AT,
  AVAILABLE_COUNTRIES.BE,
  AVAILABLE_COUNTRIES.CH,
  AVAILABLE_COUNTRIES.DE,
  AVAILABLE_COUNTRIES.FR,
  AVAILABLE_COUNTRIES.LU,
  AVAILABLE_COUNTRIES.NL,
  AVAILABLE_COUNTRIES.PL,
  AVAILABLE_COUNTRIES.IT,
  AVAILABLE_COUNTRIES.UK,
  AVAILABLE_COUNTRIES.US,
];

const MAX_COUNTRIES_WITHOUT_SCROLL = 6;

const CurrentState = (props: CurrentStateProps): ReactElement => (
  <CurrentStateContainer>
    <Flag flag={props.flag} width={rem(16)} /> {props.language}
  </CurrentStateContainer>
);

export const LanguageDropdown = (props: Props): ReactElement => {
  const { mobile } = useBreakpoint();

  return (
    <Dropdown
      size="small"
      isDark
      currentState={
        <CurrentState
          flag={props.currentFlag}
          language={props.currentLanguage}
        />
      }
      buttonTestId={NAVBAR.LANGUAGE_TRIGGER}
      containerTestId={NAVBAR.LANGUAGE_DROPDOWN}
      withWideTrigger
      alignment={mobile ? "right" : "left"}
      onToggle={props.onToggle}
      autoWidth
    >
      <FadeOutContainer
        isActive={props.countries.length > MAX_COUNTRIES_WITHOUT_SCROLL}
      >
        <DropdownHeader id="country-selector-header">
          {props.countryHeaderText}
        </DropdownHeader>
        <CountriesWrapper aria-labelledby="country-selector-header">
          {props.countries
            .sort(
              (a, b) =>
                COUNTRY_SORT_ORDER.indexOf(a.value) -
                COUNTRY_SORT_ORDER.indexOf(b.value),
            )
            .map(country => (
              <DropdownItem
                active={country.value === props.currentFlag}
                as="button"
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  props.setCountry(country.value);
                }}
                key={`country-${country.value}`}
                withBorder
              >
                <Margins xs={[null, null, null, "base_x2"]}>
                  <span>
                    <Flag flag={country.value} width={rem(24)} />
                  </span>
                </Margins>
                {country.label}
              </DropdownItem>
            ))}
        </CountriesWrapper>

        <DropdownHeader id="language-selector-header">
          {props.languageHeaderText}
        </DropdownHeader>
        <DropdownGroup aria-labelledby="language-selector-header">
          {props.languages
            .filter(lang => LANGS_BY_COUNTRY[props.currentFlag]?.includes(lang))
            .map((lang: AVAILABLE_LANGS) => (
              <TestWrapper
                key={`lang-${lang}-wrapper`}
                testId={
                  (NAVBAR as any)[`LANGUAGE_BUTTON_${lang.toUpperCase()}`]
                }
              >
                <DropdownItem
                  active={lang === props.currentLanguage}
                  as="button"
                  onClick={(e: React.SyntheticEvent) => {
                    e.preventDefault();
                    props.setLanguage(lang);
                  }}
                  key={`lang-${lang}`}
                >
                  {lang}
                </DropdownItem>
              </TestWrapper>
            ))}
        </DropdownGroup>
      </FadeOutContainer>
    </Dropdown>
  );
};

const FadeOutContainer = styled.div<{ isActive: boolean }>`
  ${props =>
    props.isActive &&
    css`
      :after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: ${rem(80)};
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 90%
        );
        width: 100%;
        height: ${rem(18)};
      }
    `}
`;

const CurrentStateContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${props => props.theme.fonts.weights.semibold};

  svg {
    margin-right: ${props => props.theme.margins.base};
  }
`;

const CountriesWrapper = styled.ul`
  position: relative;
  max-height: ${rem(275)};
  overflow: auto;
  padding: 0;
  margin: 0;

  button:last-of-type {
    margin-bottom: ${props => props.theme.margins.half};
  }
`;

const DropdownGroup = styled.ul`
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;

  > button {
    text-align: center;
  }
`;
