/** DISCLAIMER
 * This template is used only to create a standalone HTML file with footer
 * that can be later copy-pasted/injected in our other, non-React apps.
 * Please do not modify this file unless absolutely necessary.
 */
import * as React from "react";
import { graphql, PageProps } from "gatsby";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { langFromTag } from "@services/LangFromTag/LangFromTag";

import FooterStatic from "../components/FooterStatic";

type Props = PageProps<
  null,
  {
    lang: string;
    country: AVAILABLE_COUNTRIES;
    origin: ORIGIN;
  }
>;

const FooterAsAPage = (props: Props): React.ReactElement => {
  const origin = props.pageContext.origin;
  const lang = props.pageContext.lang;
  const country = props.pageContext.country;

  return (
    <FooterStatic
      isStandalone
      currentFlag={country}
      currentLanguage={langFromTag(lang)}
      origin={origin}
    />
  );
};

export default FooterAsAPage;

export const pageQuery = graphql`
  query FooterQuery($id: String) {
    prismicFooter(id: { eq: $id }) {
      id
    }
  }
`;
