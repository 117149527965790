import { AnyAction } from "redux";

import {
  AGENT_BY_UUID_ENDPOINT,
  CLIENT_REGISTRATION_ENDPOINT,
  LOCATIONS_ENDPOINT,
  PROFILE_PAYMENT_ALIASES_ENDPOINT,
  BOOKING_PREVIOUS_AGENTS_ENDPOINT,
} from "@config/endpoints";
import {
  BOOKING_AVAILABILITY_ENDPOINT,
  BOOKING_RESERVATION_ENDPOINT,
  BOOKING_AGENTS_METADATA_ENDPOINT,
  BOOKING_RESERVATION_DATA_ENDPOINT,
  BOOKING_RESERVATION_CHECKOUT_ENDPOINT,
  BOOKING_RESERVATION_COMPLETION_ENDPOINT,
  BOOKING_RESERVATION_COMPLETION_NOTIFICATION_ENDPOINT,
  BOOKING_RESERVATION_BATSOFT_TRANSFER_ENDPOINT,
  BOOKING_RESERVATION_BATSOFT_TRANSFER_NOTIFICATION_ENDPOINT,
  BOOKING_SALARY_PROPOSAL_ENDPOINT,
} from "@config/work.endpoints";
import {
  BOOKING_SALARY_INSURANCES_LPP_ENDPOINT,
  BOOKING_SALARY_INSURANCES_APG_ENDPOINT,
  BOOKING_SALARY_DEFAULTS_ENDPOINT,
  BOOKING_SALARY_ENDPOINT,
  BOOKING_SALARY_SUPERGROSS_ENDPOINT,
} from "@config/soft.endpoints";
import { BATSOFT_HOST } from "@config/consts";

import {
  Task,
  KEY_PICK,
  CLEANING_FREQUENCY,
  PLAN,
  AGENTS_FILTER,
} from "@typings/booking";
import { SECOND_PILLAR_RETIREMENT, SICKNESS_INSURANCE } from "@typings/salary";
import { ThunkActionDispatch } from "@typings/work.reduxThunkActions";

import { stringifyFalsies } from "@services/StringifyFalsies";
import { stringifyObjectValues } from "@services/StringifyObjectValues";
import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";
import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";
import { buildUrl } from "@services/BuildUrl";

import { asyncActionCreator } from "@work/services/AsyncActionCreator";
import { IStore } from "@work/redux/reducers";
import {
  deleteLocalStorageHelper,
  setFromLocalStorageHelper,
  setLocalStorageHelper,
} from "@work/redux/helpers";

import { BookingRegistrationForm, LOCAL_STORAGE_KEY } from "./booking.typings";
import {
  getReservationEndpointRequest,
  getAgentsMetadataEndpointRequest,
  getAvailabilityEndpointRequest,
  getSicknessInsuranceEndpointRequest,
  getSalaryDefaultsEndpointRequest,
  getSalaryEndpointRequest,
  getReservationDataEndpointRequest,
  getAddLocationEndpointRequest,
  getClientRegistrationEndpointRequest,
  getSecondPillarRetirementEndpointRequest,
  getSupergrossSalaryEndpointRequest,
} from "./booking.repository";
import {
  getChosenAgentId,
  getReservationUuid,
  getServiceLocationId,
} from "./booking.selectors";

const action = new ReduxActionNameCreator("booking");

export const BOOKING_ACTIONS = {
  ADD_LOCATION: action.make("add location"),
  SET_LOCATION_UUID: action.make("set location uuid"),
  SET_SERVICE_LOCATION_ID: action.make("set service location id"),
  SET_TASK: action.make("set task"),
  SET_FREQUENCY: action.make("set frequency"),
  SET_KEY_PICK: action.make("set key pick"),
  SET_LOCATION_COMMENT: action.make("set location comment"),
  SET_PETS: action.make("set pets"),
  SET_SPECIAL_INSTRUCTIONS: action.make("set special instructions"),
  RESET_AGENTS_METADATA: action.make("reset agents metadata"),
  RESET_CHOSEN_AGENT_ID: action.make("reset chosen agent"),
  SET_CHOSEN_AGENT_ID: action.make("set chosen agent"),
  FETCH_AVAILABILITY: action.make("fetch availability"),
  SET_WEEK_START_DATE: action.make("set week start date"),
  SET_EXECUTION_DATETIME: action.make("set execution datetime"),
  SET_PROFESSIONAL_ACCIDENT_INSURANCE: action.make(
    "set professional accident insurance",
  ),
  SET_AGREE_TO_PROFESSIONAL_ACCIDENT_INSURANCE: action.make(
    "set agree to professional accident insurance",
  ),
  SET_INSURANCE_POLICY_NUMBER: action.make("set insurance policy number"),
  SET_INSURANCE_POLICY_WILL_BE_PROVIDED_LATER: action.make(
    "set insurance policy will be provided later",
  ),
  SET_SICKNESS_INSURANCE: action.make("set sickness insurance"),
  MAKE_RESERVATION: action.make("make reservation"),
  PATCH_RESERVATION_DATA: action.make("patch reservation data"),
  SET_AGENTS_METADATA: action.make("set agents metadata"),
  FETCH_SICKNESS_INSURANCE_COSTS: action.make("fetch sickness insurance costs"),
  SET_SECOND_PILLAR_RETIREMENT: action.make("set second pillar retirement"),
  FETCH_SECOND_PILLAR_RETIREMENT_COSTS: action.make(
    "fetch second pillar retirement costs",
  ),
  FETCH_SALARY_DEFAULTS: action.make("fetch salary defaults"),
  FETCH_SALARY: action.make("fetch salary"),
  SET_SLIDER_SUPERGROSS_SALARY: action.make("set slider supergross salary"),
  SET_QUERY_PARAMS: action.make("set query params"),
  SET_REGISTRATION_FORM: action.make("set registration form"),
  REGISTER_CLIENT: action.make("register client"),
  FETCH_SUPERGROSS_SALARY: action.make("fetch supergross salary"),
  MAKE_CHECKOUT: action.make("make checkout"),
  MAKE_BATSOFT_TRANSFER: action.make("make batsoft transfer"),
  MAKE_BATSOFT_TRANSFER_NOTIFICATION: action.make(
    "make batsoft transfer notification",
  ),
  RESET_IS_RELAX_PLAN_COMPLETED: action.make("reset is relax plan completed"),
  FETCH_PAYMENT_ALIASES: action.make("fetch payment aliases"),
  SET_PLAN: action.make("set plan"),
  FETCH_EMPLOYEE: action.make("fetch employee"),
  COMPLETE_RESERVATION: action.make("complete reservation"),
  COMPLETE_RESERVATION_NOTIFICATION: action.make(
    "complete reservation notification",
  ),
  FETCH_PREVIOUS_AGENTS: action.make("fetch previous agents"),
  FETCH_SALARY_PROPOSAL: action.make("fetch salary proposal"),

  /** LOCAL STORAGE ACTIONS */
  SET_IN_LOCAL_STORAGE: action.make("set in localstorage"),
  SET_FROM_LOCAL_STORAGE: action.make("set from localstorage"),
  DELETE_IN_LOCAL_STORAGE: action.make("delete in localstorage"),
};

export const setPlan = (payload: PLAN): AnyAction => ({
  type: BOOKING_ACTIONS.SET_PLAN,
  payload,
});

export const setServiceLocationId = (payload: number): AnyAction => ({
  type: BOOKING_ACTIONS.SET_SERVICE_LOCATION_ID,
  payload,
});

export const setLocationUuid = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_LOCATION_UUID,
  payload,
});

export const setTask = (payload: Task): AnyAction => ({
  type: BOOKING_ACTIONS.SET_TASK,
  payload,
});

export const setFrequency = (frequency: CLEANING_FREQUENCY): AnyAction => ({
  type: BOOKING_ACTIONS.SET_FREQUENCY,
  payload: {
    frequency,
  },
});

export const setKeyPick = (payload: KEY_PICK): AnyAction => ({
  type: BOOKING_ACTIONS.SET_KEY_PICK,
  payload,
});

export const setLocationComment = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_LOCATION_COMMENT,
  payload,
});

export const setPets = (payload: boolean): AnyAction => ({
  type: BOOKING_ACTIONS.SET_PETS,
  payload,
});

export const setProfessionalAccidentInsurance = (
  payload: boolean,
): AnyAction => ({
  type: BOOKING_ACTIONS.SET_PROFESSIONAL_ACCIDENT_INSURANCE,
  payload,
});

export const setAgreeToProfessionalAccidentInsurance = (
  payload: boolean,
): AnyAction => ({
  type: BOOKING_ACTIONS.SET_AGREE_TO_PROFESSIONAL_ACCIDENT_INSURANCE,
  payload,
});

export const setInsurancePolicyNumber = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_INSURANCE_POLICY_NUMBER,
  payload,
});

export const setInsurancePolicyWillBeProvidedLater = (
  payload: boolean,
): AnyAction => ({
  type: BOOKING_ACTIONS.SET_INSURANCE_POLICY_WILL_BE_PROVIDED_LATER,
  payload,
});

export const setSicknessInsurance =
  (payload: SICKNESS_INSURANCE) =>
  (dispatch: ThunkActionDispatch, store: () => IStore): AnyAction =>
    dispatch({
      type: BOOKING_ACTIONS.SET_SICKNESS_INSURANCE,
      payload: {
        prevValue: store().booking.get("sicknessInsurance"),
        newValue: payload,
      },
    });

export const setSecondPillarRetirement =
  (payload: SECOND_PILLAR_RETIREMENT) =>
  (dispatch: ThunkActionDispatch, store: () => IStore): AnyAction =>
    dispatch({
      type: BOOKING_ACTIONS.SET_SECOND_PILLAR_RETIREMENT,
      payload: {
        prevValue: store().booking.get("secondPillarRetirement"),
        newValue: payload,
      },
    });

export const setSpecialInstructions = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_SPECIAL_INSTRUCTIONS,
  payload,
});

export const resetAgentsMetadata = (): AnyAction => ({
  type: BOOKING_ACTIONS.RESET_AGENTS_METADATA,
});

export const resetChosenAgentId = (): AnyAction => ({
  type: BOOKING_ACTIONS.RESET_CHOSEN_AGENT_ID,
});

export const resetIsRelaxPlanCompleted = (): AnyAction => ({
  type: BOOKING_ACTIONS.RESET_IS_RELAX_PLAN_COMPLETED,
});

export const setChosenAgentId = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_CHOSEN_AGENT_ID,
  payload,
});

export const fetchSicknessInsuranceCosts =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = getSicknessInsuranceEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: BOOKING_SALARY_INSURANCES_APG_ENDPOINT.replace(
          "{serviceLocationIdValue}",
          stringifiedParams.serviceLocationId,
        )
          .replace(
            "{superGrossSalaryValue}",
            stringifiedParams.superGrossSalary,
          )
          .replace(
            "{secondPillarRetirementValue}",
            stringifiedParams.secondPillarRetirement,
          )
          .replace(
            "{sicknessInsuranceValue}",
            stringifiedParams.sicknessInsurance,
          ),
        action: BOOKING_ACTIONS.FETCH_SICKNESS_INSURANCE_COSTS,
        method: ASYNC_ACTION_TYPES.GET,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const fetchSecondPillarRetirementCosts =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = getSecondPillarRetirementEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: BOOKING_SALARY_INSURANCES_LPP_ENDPOINT.replace(
          "{serviceLocationIdValue}",
          stringifiedParams.serviceLocationId,
        )
          .replace(
            "{superGrossSalaryValue}",
            stringifiedParams.superGrossSalary,
          )
          .replace(
            "{secondPillarRetirementValue}",
            stringifiedParams.secondPillarRetirement,
          )
          .replace(
            "{sicknessInsuranceValue}",
            stringifiedParams.sicknessInsurance,
          ),
        action: BOOKING_ACTIONS.FETCH_SECOND_PILLAR_RETIREMENT_COSTS,
        method: ASYNC_ACTION_TYPES.GET,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const fetchBookingAvailability =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getAvailabilityEndpointRequest(store());

    const action = await asyncActionCreator({
      url: BOOKING_AVAILABILITY_ENDPOINT,
      action: BOOKING_ACTIONS.FETCH_AVAILABILITY,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

export const setWeekStartDate = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_WEEK_START_DATE,
  payload,
});

export const setExecutionDateTime = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_EXECUTION_DATETIME,
  payload,
});

export const patchReservationData =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const reservationUuid = stringifyFalsies(getReservationUuid(store()));
    const body = getReservationDataEndpointRequest(store());

    const action = await asyncActionCreator({
      url: BOOKING_RESERVATION_DATA_ENDPOINT.replace(
        "{reservationUuid}",
        reservationUuid,
      ),
      action: BOOKING_ACTIONS.PATCH_RESERVATION_DATA,
      method: ASYNC_ACTION_TYPES.PATCH,
      body,
    });

    return dispatch(action);
  };

export const makeReservation =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getReservationEndpointRequest(store());

    const action = await asyncActionCreator({
      url: BOOKING_RESERVATION_ENDPOINT,
      action: BOOKING_ACTIONS.MAKE_RESERVATION,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

export const setAgentsMetadata =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const ids = getAgentsMetadataEndpointRequest(store());

    const action = await asyncActionCreator({
      url: `${BOOKING_AGENTS_METADATA_ENDPOINT}${ids}`,
      action: BOOKING_ACTIONS.SET_AGENTS_METADATA,
      method: ASYNC_ACTION_TYPES.GET,
    });

    return dispatch(action);
  };

export const fetchSalaryDefaults =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = getSalaryDefaultsEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: buildUrl(BOOKING_SALARY_DEFAULTS_ENDPOINT)
          .setQuery("serviceLocationId", stringifiedParams.serviceLocationId)
          .setQuery(
            "secondPillarRetirement",
            stringifiedParams.secondPillarRetirement,
          )
          .setQuery("agentUuid", stringifiedParams.chosenAgentId, {
            optional: true,
          })
          .done(),
        action: BOOKING_ACTIONS.FETCH_SALARY_DEFAULTS,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const fetchSalary =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = getSalaryEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: BOOKING_SALARY_ENDPOINT.replace(
          "{serviceLocationIdValue}",
          stringifiedParams.serviceLocationId,
        )
          .replace(
            "{superGrossSalaryValue}",
            stringifiedParams.superGrossSalary,
          )
          .replace(
            "{secondPillarRetirementValue}",
            stringifiedParams.secondPillarRetirement,
          )
          .replace(
            "{sicknessInsuranceValue}",
            stringifiedParams.sicknessInsurance,
          )
          .replace(
            "{agreeToProfessionalAccidentInsuranceValue}",
            stringifiedParams.agreeToProfessionalAccidentInsurance,
          ),
        action: BOOKING_ACTIONS.FETCH_SALARY,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const setSliderSupergrossSalary = (payload: number): AnyAction => ({
  type: BOOKING_ACTIONS.SET_SLIDER_SUPERGROSS_SALARY,
  payload,
});

export const setQueryParams = (payload: string): AnyAction => ({
  type: BOOKING_ACTIONS.SET_QUERY_PARAMS,
  payload,
});

export const addLocation =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getAddLocationEndpointRequest(store());

    const action = await asyncActionCreator({
      url: LOCATIONS_ENDPOINT,
      action: BOOKING_ACTIONS.ADD_LOCATION,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

export const setRegistrationForm = (
  payload: Partial<BookingRegistrationForm>,
): AnyAction => ({
  type: BOOKING_ACTIONS.SET_REGISTRATION_FORM,
  payload,
});

export const registerClient =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const body = getClientRegistrationEndpointRequest(store());

    const action = await asyncActionCreator({
      url: CLIENT_REGISTRATION_ENDPOINT,
      action: BOOKING_ACTIONS.REGISTER_CLIENT,
      method: ASYNC_ACTION_TYPES.POST,
      body,
    });

    return dispatch(action);
  };

export const checkout =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const reservationUuid = stringifyFalsies(getReservationUuid(store()));

    const action = await asyncActionCreator({
      url: BOOKING_RESERVATION_CHECKOUT_ENDPOINT.replace(
        "{reservationUuid}",
        reservationUuid,
      ),
      action: BOOKING_ACTIONS.MAKE_CHECKOUT,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

export const completeReservation =
  (returnUrl?: string) =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const reservationUuid = stringifyFalsies(getReservationUuid(store()));

    const action = await asyncActionCreator({
      url: BOOKING_RESERVATION_COMPLETION_ENDPOINT.replace(
        "{reservationUuid}",
        reservationUuid,
      ),
      action: BOOKING_ACTIONS.COMPLETE_RESERVATION,
      method: ASYNC_ACTION_TYPES.POST,
      body: {
        ...(returnUrl && { returnUrl }),
      },
    });

    return dispatch(action);
  };

export const completeReservationNotification =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const reservationUuid = stringifyFalsies(getReservationUuid(store()));

    const action = await asyncActionCreator({
      url: BOOKING_RESERVATION_COMPLETION_NOTIFICATION_ENDPOINT.replace(
        "{reservationUuid}",
        reservationUuid,
      ),
      action: BOOKING_ACTIONS.COMPLETE_RESERVATION_NOTIFICATION,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

export const fetchSupergrossSalary =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const params = getSupergrossSalaryEndpointRequest(store());
    const stringifiedParams = stringifyObjectValues(params);

    const action = await asyncActionCreator(
      {
        url: BOOKING_SALARY_SUPERGROSS_ENDPOINT.replace(
          "{serviceLocationIdValue}",
          stringifiedParams.serviceLocationId,
        )
          .replace(
            "{grossSalaryIncludingVacationValue}",
            stringifiedParams.grossSalaryIncludingVacation,
          )
          .replace(
            "{currentSecondPillarRetirementValue}",
            stringifiedParams.currentSecondPillarRetirement,
          )
          .replace(
            "{newSecondPillarRetirementValue}",
            stringifiedParams.newSecondPillarRetirement,
          )
          .replace(
            "{currentSicknessInsuranceValue}",
            stringifiedParams.currentSicknessInsurance,
          )
          .replace(
            "{newSicknessInsuranceValue}",
            stringifiedParams.newSicknessInsurance,
          ),
        action: BOOKING_ACTIONS.FETCH_SUPERGROSS_SALARY,
      },
      {
        host: BATSOFT_HOST,
      },
    );

    return dispatch(action);
  };

export const makeBatsoftTransfer =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const reservationUuid = stringifyFalsies(getReservationUuid(store()));

    const action = await asyncActionCreator({
      url: BOOKING_RESERVATION_BATSOFT_TRANSFER_ENDPOINT.replace(
        "{reservationUuid}",
        reservationUuid,
      ),
      action: BOOKING_ACTIONS.MAKE_BATSOFT_TRANSFER,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

export const makeBatsoftTransferNotification =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const reservationUuid = stringifyFalsies(getReservationUuid(store()));

    const action = await asyncActionCreator({
      url: BOOKING_RESERVATION_BATSOFT_TRANSFER_NOTIFICATION_ENDPOINT.replace(
        "{reservationUuid}",
        reservationUuid,
      ),
      action: BOOKING_ACTIONS.MAKE_BATSOFT_TRANSFER_NOTIFICATION,
      method: ASYNC_ACTION_TYPES.POST,
    });

    return dispatch(action);
  };

export const fetchPaymentAliases =
  () =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: PROFILE_PAYMENT_ALIASES_ENDPOINT,
      action: BOOKING_ACTIONS.FETCH_PAYMENT_ALIASES,
    });

    return dispatch(action);
  };

export const fetchEmployee =
  (uuid: string) =>
  async (dispatch: ThunkActionDispatch): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: AGENT_BY_UUID_ENDPOINT.replace("{uuid}", uuid),
      action: BOOKING_ACTIONS.FETCH_EMPLOYEE,
      params: {
        uuid,
      },
    });

    return dispatch(action);
  };

export const fetchPreviousAgents =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const action = await asyncActionCreator({
      url: BOOKING_PREVIOUS_AGENTS_ENDPOINT.replace(
        "{filterValue}",
        AGENTS_FILTER.PREVIOUS,
      ).replace(
        "{serviceLocationIdValue}",
        String(getServiceLocationId(store())),
      ),
      action: BOOKING_ACTIONS.FETCH_PREVIOUS_AGENTS,
    });

    return dispatch(action);
  };

export const setLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch, store: () => IStore): void => {
    setLocalStorageHelper(
      dispatch,
      BOOKING_ACTIONS.SET_IN_LOCAL_STORAGE,
      store().booking,
      LOCAL_STORAGE_KEY,
    );
  };

export const setFromLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch): void => {
    setFromLocalStorageHelper(
      dispatch,
      BOOKING_ACTIONS.SET_FROM_LOCAL_STORAGE,
      LOCAL_STORAGE_KEY,
    );
  };

export const deleteLocalStorage =
  () =>
  (dispatch: ThunkActionDispatch): void => {
    deleteLocalStorageHelper(
      dispatch,
      BOOKING_ACTIONS.DELETE_IN_LOCAL_STORAGE,
      LOCAL_STORAGE_KEY,
    );
  };

export const fetchSalaryProposal =
  () =>
  async (
    dispatch: ThunkActionDispatch,
    store: () => IStore,
  ): Promise<AsyncActionResult> => {
    const agentUuid = stringifyFalsies(getChosenAgentId(store()));

    const action = await asyncActionCreator({
      url: BOOKING_SALARY_PROPOSAL_ENDPOINT.replace(
        "{agentUuidValue}",
        agentUuid,
      ),
      action: BOOKING_ACTIONS.FETCH_SALARY_PROPOSAL,
    });

    return dispatch(action);
  };
