import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const InvoiceHomeHouse = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M20 9V4.49752C20 4.11947 19.7868 3.77374 19.449 3.604L18.459 3.10648C18.1785 2.96552 17.8481 2.96446 17.5667 3.10361L15.9942 3.8812L14.4533 3.10713C14.172 2.96581 13.8406 2.96521 13.5588 3.10551L12 3.88151L10.4412 3.10551C10.1594 2.96521 9.82799 2.96581 9.54668 3.10713L8.00578 3.8812L6.4333 3.10361C6.15191 2.96446 5.82151 2.96552 5.54102 3.10648L4.55102 3.604C4.21321 3.77374 4.00001 4.11947 4 4.49752V20C4 20.5523 4.44772 21 5 21H9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 19.5V16.3597C21 15.904 20.7928 15.473 20.437 15.1884L18.2493 13.4383C17.5189 12.8539 16.481 12.8539 15.7506 13.4383L13.563 15.1884C13.2072 15.473 13 15.904 13 16.3597V19.5C13 20.3284 13.6716 21 14.5 21H19.5C20.3284 21 21 20.3284 21 19.5Z"
      />
      <path d="M8 8.99994H16" />
      <path d="M9 14.9999H8" />
      <path d="M8 11.9999H12" />
    </svg>
  );
};
