import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Blinds = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <rect x="3" y="3.00001" width="18" height="18" rx="3" />
      <path d="M9.5 3.00001V13" />
      <circle cx="9.5" cy="15" r="2" />
      <path d="M7 11H3" />
      <path d="M21 7.00001H12" />
      <path d="M21 11H12" />
      <path d="M5 15H3" />
      <path d="M21 15H14" />
      <path d="M7 7.00001H3" />
    </svg>
  );
};
