/**
 * @author jakubmaslon
 * @since 2020-02-18
 */

import * as React from "react";
import styled from "styled-components";

interface Props {
  head: string;
  headRight?: string;
  children: React.ReactNode;
  isExtended?: boolean;
}

const SummaryCategory = (props: Props) => (
  <SummaryCategoryStyled>
    <SummaryCategoryHead>
      <span>{props.head}</span>
      {props.headRight && <span>{props.headRight}</span>}
    </SummaryCategoryHead>
    <SummaryCategoryBody isExtended={props.isExtended}>
      {props.children}
    </SummaryCategoryBody>
  </SummaryCategoryStyled>
);

export { SummaryCategory };

const SummaryCategoryStyled = styled.div`
  margin-bottom: ${props => props.theme.margins.base_x6};

  &:last-child {
    margin-bottom: 0;
  }
`;

const SummaryCategoryHead = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin-bottom: ${props => props.theme.margins.base_x4};
  color: ${props => props.theme.colors.black500};
`;

const SummaryCategoryBody = styled.div<{ isExtended?: boolean }>`
  ${props => props.theme.breakpoints.desktop} {
    margin: ${props => props.isExtended && `0 -${props.theme.margins.base_x4}`};
  }
`;
