import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const DocumentStatusDoneCheckmark = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M7 20H5C3.89543 20 3 19.1046 3 18V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V18C21 19.1046 20.1046 20 19 20H17" />
      <path d="M17 13H7" />
      <path d="M17 9H7" />
      <path d="M10.25 19L11.75 20.5L14.25 18" />
    </svg>
  );
};
