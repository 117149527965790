import { createGlobalStyle } from "styled-components";
import reboot from "styled-reboot";

import { BODY_WHITE_BG_CLASS, BODY_FOCUS_MODE_CLASS } from "@config/consts";

import "@ui/fonts.css";
import theme from "@ui/themes/default";

const options = {
  bodyBg: theme.colors.greySurface,
  black: theme.colors.black700,
  fontFamilyBase: theme.fonts.base,
  fontSizeBase: theme.fonts.baseSize,
  linkColor: theme.colors.blue400,
  linkHoverColor: theme.colors.blue500,
};

const GlobalStyle = createGlobalStyle<{ backgroundColor?: string }>`
  ${reboot(options)}

  * {
    -webkit-tap-highlight-color: transparent;
  }

  html {
    font-size: ${theme.fonts.baseSize};
  }
  
  body {
    font-size: ${theme.fonts.sizes.body2};
    font-variation-settings: "wdth" 100;
    letter-spacing: ${theme.fonts.letterSpacings.normal};
    color: ${theme.colors.black700};
    background-color: ${props => props.backgroundColor || options.bodyBg};

    &.scroll-smooth {
      scroll-behavior: smooth;
    }

    &.is-modal-open {
      overflow: hidden;

      // fix for iOS scrolling issue
      @supports (-webkit-overflow-scrolling: touch) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    &.${BODY_WHITE_BG_CLASS} {
        background-color: ${theme.colors.white};
    }

    &.${BODY_FOCUS_MODE_CLASS} {
        background-color: ${theme.colors.white};

      #header,
      #footer,
      #bottom-nav,
      #bottom-nav-spacer {
        display: none;
      }

      #main {
        overflow: hidden;
      }

      #footer {
        ${theme.breakpoints.desktop} {
          display: block;
        }
      }
    }
  }

  ul {
    color: ${theme.colors.black700};
  }

  button {
    cursor: pointer;
    background: none;
    border: 0;
  }


  button:active,
  button:focus {
    outline: 0;
  }

  a:hover {
    text-decoration: none;
  }

  input:disabled {
    opacity: 1;
  }
 
  @media all and (display-mode: standalone) {
    body {
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    }
    
    * {
      -webkit-overflow-scrolling: auto; 
    }

    *::-webkit-scrollbar {
      width: 0px;
    }
  }
  
  .headless {
    #header,
    #footer,
    #bottom-nav,
    #bottom-nav-spacer {
      display: none;
    }
  }
`;

export { GlobalStyle as default };
