import { Record, List } from "immutable";

import { FileAPIAccepted } from "@typings/fileUploading";

export interface ApplyState {
  form: Record<ApplyForm>;
  accessToken: string | null;
  isFormDataPrefetched: boolean;
  isTokenInvalid: boolean;
  isLocalStorageChecked: boolean;
}

export interface ApplyForm
  extends ApplyFormPersonalInfo,
    ApplyFormExperienceInfo {
  email: string | null;
}

export interface ApplyFormPersonalInfo {
  title: string;
  firstName: string;
  lastName: string;
  phoneNumberPrefix: string;
  phoneNumber: string;
  streetAddress: string;
  zipCode: string;
  city: string;
}

export enum COMMUTING {
  CAR = "CAR",
  SCOOTER = "SCOOTER",
  BIKE = "BIKE",
  NONE = "NONE",
}
export interface ApplyFormExperienceInfo {
  workPermitType: WORK_PERMIT_TYPE | null;
  hoursPerWeek: HOURS_PER_WEEK | null;
  yearsOfExperience: YEARS_OF_EXPERIENCE | null;
  sourcesOfExperience: List<SOURCES_OF_EXPERIENCE>;
  methodOfCommuting: COMMUTING | null;
  files: List<FileAPIAccepted>;
}

export enum WORK_PERMIT_TYPE {
  IS_SWISS = "IS_SWISS",
  PERMIT_C = "PERMIT_C",
  PERMIT_B = "PERMIT_B",
  PERMIT_L = "PERMIT_L",
  PERMIT_F = "PERMIT_F",
  PERMIT_S = "PERMIT_S",
  PERMIT_N = "PERMIT_N",
  NO_PERMIT = "NO_PERMIT",
}

export enum HOURS_PER_WEEK {
  FIVE_TO_FIFTEEN = "FIVE_TO_FIFTEEN",
  FIFTEEN_TO_THIRTY = "FIFTEEN_TO_THIRTY",
  MORE_THAN_THIRTY = "MORE_THAN_THIRTY",
}

export enum YEARS_OF_EXPERIENCE {
  NONE = "NONE",
  TWO_OR_LESS = "TWO_OR_LESS",
  MORE_THAN_TWO = "MORE_THAN_TWO",
  MORE_THAN_FIVE = "MORE_THAN_FIVE",
}

export enum SOURCES_OF_EXPERIENCE {
  CLEANING_HOUSES = "CLEANING_HOUSES",
  CLEANING_OFFICES = "CLEANING_OFFICES",
  CLEANING_HOTELS = "CLEANING_HOTELS",
  CLEANING_CLINICAL = "CLEANING_CLINICAL",
}

export type ApplyReducer = Record<ApplyState>;

export const LOCAL_STORAGE_KEY = "store:apply";
