import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const LivingRoomColorfulAsteriskPictureFrame = (
  props: Icon,
): React.ReactElement => (
  <svg
    style={{ ...style(props.size), ...props.style }}
    viewBox="0 0 168 104"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="On-boarding"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="how-it-works-step-3---8pt-copy-2"
        transform="translate(-104.000000, -288.000000)"
      >
        <g
          id="Organism/widget/onboarding/step-3"
          transform="translate(32.000000, 184.000000)"
        >
          <g
            id="Atom/artwork/salon"
            transform="translate(72.000000, 104.000000)"
          >
            <path
              d="M140.654334,8.20118207 L139.620481,7.5 L140.654334,6.79874677 C141.076062,6.55806477 141.221923,6.02030436 140.978486,5.60345459 C140.734904,5.18674722 140.19052,5.04262288 139.768792,5.28316242 L138.734939,5.98441571 L138.734939,4.875 C138.734939,4.39377847 138.336416,4 137.849397,4 C137.362378,4 136.963855,4.39377847 136.963855,4.875 L136.963855,5.98441571 L135.930002,5.28316248 C135.508274,5.04262293 134.96389,5.18674728 134.720308,5.60345465 C134.476871,6.02030442 134.622731,6.55806483 135.044459,6.79874683 L136.078313,7.5 L135.044459,8.20118207 C134.622731,8.44186406 134.476871,8.97969564 134.720308,9.39647424 C134.96389,9.81325278 135.508274,9.95737718 135.930002,9.71676642 L136.963855,9.01558429 L136.963855,10.125 C136.963855,10.6062215 137.362378,11 137.849397,11 C138.336416,11 138.734939,10.6062215 138.734939,10.125 L138.734939,9.01558429 L139.768792,9.71676636 C140.19052,9.95737713 140.734904,9.81325278 140.978486,9.39647418 C141.221923,8.97969564 141.076062,8.441864 140.654334,8.20118207 Z"
              id="Path"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
