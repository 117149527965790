import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const ClothHanger = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M12 10V7.90738L13.3823 7.13938C14.0722 6.75618 14.5 6.02911 14.5 5.24C14.4183 3.92771 13.2929 2.92731 11.9801 3C10.807 2.95132 9.76537 3.74427 9.5 4.88793" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 16.5H17C17.5523 16.5 18 16.9477 18 17.5V21H6V17.5C6 16.9477 6.44772 16.5 7 16.5Z"
      />
      <path d="M18 18H19.2386C20.0154 18.0039 20.7016 17.4949 20.9234 16.7504C21.1451 16.006 20.8491 15.2044 20.1968 14.7827L13.4783 10.4362C12.5786 9.8546 11.4214 9.8546 10.5216 10.4362L3.80322 14.7827C3.15085 15.2044 2.85489 16.006 3.07662 16.7504C3.29835 17.4949 3.98462 18.0039 4.76142 18H6" />
    </svg>
  );
};
