import { createSelector } from "reselect";
import { isAfter } from "date-fns";
import { Record } from "immutable";

import { isDateInRange } from "@services/DateFormatter";

import { IStore } from "@soft/redux/reducers";

import { Holiday } from "./holidays.typings";

const getHolidaysFunction = (store: IStore) => store.holidays.get("holidays");
export const getHolidays = createSelector(getHolidaysFunction, f => f);

const getHolidaysByLatestFunction = (store: IStore) =>
  store.holidays
    .get("holidays")
    .valueSeq()
    .sort((a, b) => {
      const firstDate = new Date(
        a.get("startDate").replace(" ", "T"),
      ).getTime();
      const secondDate = new Date(
        b.get("startDate").replace(" ", "T"),
      ).getTime();
      return firstDate - secondDate;
    });

export const getHolidaysByLatest = createSelector(
  getHolidaysByLatestFunction,
  f => f,
);

const isOngoingHolidayFunction = (store: IStore, holidayId: string) => {
  const selectedJob = store.holidays.getIn(["holidays", holidayId]);

  if (selectedJob) {
    const selectedJobStart = new Date(selectedJob.get("startDate"));
    const selectedJobEnd = new Date(selectedJob.get("endDate"));

    return isDateInRange(new Date(), selectedJobStart, selectedJobEnd);
  }

  return false;
};

export const isOngoingHoliday = createSelector(
  isOngoingHolidayFunction,
  f => f,
);

const hasUpcomingHolidaysFunction = (store: IStore): boolean =>
  store.holidays
    .get("holidays")
    .valueSeq()
    .some(holiday => isAfter(new Date(holiday.get("startDate")), new Date()));

export const hasUpcomingHolidays = createSelector(
  hasUpcomingHolidaysFunction,
  f => f,
);

export const getProcessedHolidayFunction = (
  store: IStore,
): Record<Holiday> | null => store.holidays.get("processedHoliday");

export const getProcessedHoliday = createSelector(
  getProcessedHolidayFunction,
  f => f,
);
