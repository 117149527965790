import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const PowerOff = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{
      ...style(props.size, props.height),
      ...props.style,
      transform: "rotate(180deg)",
    }}
  >
    <path
      d="m12 4a8 8 0 1 1 0 16 8 8 0 0 1 0-16zm0 1.333a6.667 6.667 0 1 0 0 13.334 6.667 6.667 0 0 0 0-13.334zm0 6.667c.342 0 .624.257.662.589l.005.078v3.333a.667.667 0 0 1 -1.33.078l-.003-.078v-3.333c0-.369.299-.667.667-.667zm0-4.667a4.666 4.666 0 0 1 3.478 7.778.667.667 0 1 1 -.993-.889 3.333 3.333 0 1 0 -4.97 0 .667.667 0 1 1 -.993.89 4.666 4.666 0 0 1 3.478-7.779z"
      fill={props.color || theme.colors.grey700}
      fillRule="nonzero"
    />
  </svg>
);
