// work
import batwork_pwa_booking from "@translations/work/en.batwork_pwa_booking.json";
import batwork_pwa_generic from "@translations/work/en.batwork_pwa_generic.json";
import batwork_pwa_navigation from "@translations/work/en.batwork_pwa_navigation.json";
import batwork_pwa_password from "@translations/work/en.batwork_pwa_password.json";
import batwork_pwa_apply from "@translations/work/en.batwork_pwa_apply.json";

export const workTranslations = {
  batwork_pwa_booking,
  batwork_pwa_generic,
  batwork_pwa_navigation,
  batwork_pwa_password,
  batwork_pwa_apply,
};
