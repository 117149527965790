import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const User = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <defs>
      <path
        id="user-a"
        d="M12 13.333c3.553 0 6.554 1.712 6.664 4.035l.003.132v1.833a.667.667 0 01-1.33.078l-.004-.078V17.5c0-1.461-2.352-2.833-5.333-2.833-2.916 0-5.23 1.313-5.33 2.738l-.003.095v1.833a.667.667 0 01-1.33.078l-.004-.078V17.5c0-2.39 3.047-4.167 6.667-4.167zM12 4a4 4 0 110 8 4 4 0 010-8zm0 1.333a2.667 2.667 0 100 5.334 2.667 2.667 0 000-5.334z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="user-b" fill="#fff">
        <use xlinkHref="#user-a"></use>
      </mask>
      <use
        fill={props.color || theme.colors.grey700}
        fillRule="nonzero"
        xlinkHref="#user-a"
      ></use>
      <g fill="#666" mask="url(#user-b)">
        <path d="M0 0h24v24H0z"></path>
      </g>
    </g>
  </svg>
);
