import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const KitchenCloche = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <circle cx="12" cy="9.5" r="1.5" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 21H5C3.89543 21 3 20.1046 3 19V19C3 18.4477 3.44772 18 4 18H20C20.5523 18 21 18.4477 21 19V19C21 20.1046 20.1046 21 19 21Z"
      />
      <path d="M4.5 18C4.64279 13.9981 7.99775 10.8668 12 11C16.0022 10.8668 19.3572 13.9981 19.5 18" />
      <path d="M22 10H21" />
      <path d="M3 10H2" />
      <path d="M12 2V3" />
      <path d="M5.29289 4.29289L6 5" />
      <path d="M18.7071 4.29289L18 5" />
    </svg>
  );
};
