import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const ClockTime = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M5.62535 18.3576C8.50408 21.244 12.963 21.836 16.4952 19.8008C20.0274 17.7657 21.7514 13.6111 20.698 9.67302C19.6446 5.73492 16.0766 2.99609 12 2.99609" />
      <path d="M3.77792 15.427C3.77789 15.4546 3.75549 15.477 3.72788 15.477C3.70027 15.477 3.67789 15.4546 3.67788 15.427C3.67787 15.3994 3.70023 15.377 3.72784 15.3769C3.74113 15.3769 3.75387 15.3822 3.76327 15.3916C3.77267 15.401 3.77794 15.4137 3.77792 15.427" />
      <path d="M3.09636 12.0003C3.09632 12.0279 3.07393 12.0502 3.04632 12.0502C3.01871 12.0502 2.99633 12.0278 2.99631 12.0002C2.9963 11.9726 3.01867 11.9502 3.04628 11.9502C3.05956 11.9502 3.07231 11.9554 3.08171 11.9648C3.0911 11.9742 3.09637 11.987 3.09636 12.0003" />
      <path d="M3.77792 8.57345C3.77792 8.60107 3.75554 8.62346 3.72792 8.62347C3.7003 8.62348 3.6779 8.60111 3.67788 8.57349C3.67786 8.54587 3.70022 8.52346 3.72784 8.52343C3.74113 8.52341 3.75388 8.52869 3.76327 8.53808C3.77267 8.54748 3.77794 8.56023 3.77792 8.57352" />
      <path d="M5.71885 5.66922C5.71881 5.69683 5.69641 5.71919 5.6688 5.71917C5.64119 5.71916 5.61882 5.69678 5.6188 5.66918C5.61879 5.64157 5.64115 5.61917 5.66876 5.61913C5.68205 5.61911 5.6948 5.62439 5.7042 5.63378C5.71359 5.64318 5.71886 5.65593 5.71885 5.66922" />
      <path d="M8.62364 3.72781C8.62361 3.75542 8.60121 3.77778 8.5736 3.77777C8.54599 3.77776 8.52361 3.75537 8.5236 3.72776C8.5236 3.70015 8.54596 3.67775 8.57357 3.67773C8.58686 3.67771 8.59961 3.68298 8.60901 3.69237C8.61841 3.70177 8.62368 3.71452 8.62366 3.72781" />
      <path d="M16.0494 12.6027H11.3975" />
      <path d="M11.3975 12.6026V6.99805" />
    </svg>
  );
};
