import React from "react";

import { EXCLUDED_LANGS_BATSOFT } from "@config/consts";
import { ROUTES } from "@config/soft.routing";
import { NAVBAR } from "@config/softTestIds/navbar";
import {
  ADMIN_DASHBOARD_LINK,
  GIFT_CARDS_LINK,
  BATWORK_LINK,
  BATMAID_LINK,
  HELP_LINK_BATSOFT,
  AGENT_SCHEDULE_LINK,
  AGENT_MY_CLEANINGS_LINK,
  AGENT_NEW_CLEANINGS_LINK,
  AGENT_AVAILABILITIES_LINK,
  AGENT_PROFILE_LINK,
} from "@config/links";

import { AVAILABLE_LANGS } from "@typings/globals";

import theme from "@ui/themes/default";
import { Bell } from "@ui/Assets/Symbolicons/Bell";
import { UserCircle } from "@ui/Assets/Symbolicons/UserCircle";
import { UserOff } from "@ui/Assets/Symbolicons/UserOff";
import { PowerOff } from "@ui/Assets/Symbolicons/PowerOff";
import { User } from "@ui/Assets/Symbolicons/User";
import { NavigationConfig } from "@ui/Organisms/UserDropdown";

export const createHref = (language: AVAILABLE_LANGS, link: string): string =>
  `/${language}${link}`;

export const getLanguagesList = (
  translate: (k: string) => string,
): { value: AVAILABLE_LANGS; label: string }[] =>
  Object.values(AVAILABLE_LANGS)
    .filter(lang => !EXCLUDED_LANGS_BATSOFT.includes(lang))
    .map(lang => ({
      value: lang,
      label: translate(`batsoft_pwa_generic.language_${lang}`),
    }));

interface GetUserNavigationInput {
  logoutUrl: string;
  exitUserUrl: string;
  isUserAdmin: boolean;
  isAdminImpersonator: boolean;
  isUserClient: boolean;
  isMobile: boolean;
  hasBookingsToValidate: boolean;
  language: AVAILABLE_LANGS;
  logoutAction: () => void;
  loginAction: () => void;
  translate: (k: string) => string;
}

export const getUserNavigationConfig = ({
  logoutUrl,
  exitUserUrl,
  isUserAdmin,
  isUserClient,
  isAdminImpersonator,
  isMobile,
  hasBookingsToValidate,
  language,
  logoutAction,
  loginAction,
  translate,
}: GetUserNavigationInput): NavigationConfig => {
  const iconConfig = {
    size: 24,
    height: 24,
    color: isMobile ? theme.colors.white : theme.colors.grey700,
  };

  return [
    {
      items: [
        {
          label: translate("batsoft_pwa_navigation.admin"),
          icon: <UserCircle {...iconConfig} />,
          href: ADMIN_DASHBOARD_LINK,
          isHidden: !isUserAdmin,
          skipHrefPrefix: true,
        },
      ],
    },
    ...(isUserAdmin && !isAdminImpersonator
      ? []
      : [
          {
            items: [
              {
                label: translate("batsoft_pwa_navigation.subnav_my_schedule"),
                href: ROUTES.CLIENT_DASHBOARD_MY_SCHEDULE,
                testId: NAVBAR.USER_DROPDOWN_MY_SCHEDULE,
                isHidden: !isUserClient,
              },
              {
                label: translate(
                  "batsoft_pwa_navigation.subnav_bookings_to_validate",
                ),
                href: ROUTES.CLIENT_DASHBOARD_BOOKINGS_TO_VALIDATE,
                testId: NAVBAR.USER_DROPDOWN_BOOKINGS_TO_VALIDATE,
                isHidden: !hasBookingsToValidate,
              },
              {
                label: translate("batsoft_pwa_navigation.subnav_my_employees"),
                href: ROUTES.CLIENT_DASHBOARD_MY_EMPLOYEES,
                testId: NAVBAR.USER_DROPDOWN_MY_EMPLOYEES,
                isHidden: !isUserClient,
              },
              {
                label: translate("batsoft_pwa_navigation.subnav_profile"),
                href: ROUTES.CLIENT_DASHBOARD_PROFILE,
                testId: NAVBAR.USER_DROPDOWN_PROFILE,
                isHidden: !isUserClient,
              },
              {
                label: translate("batsoft_pwa_navigation.subnav_options"),
                href: ROUTES.CLIENT_DASHBOARD_OPTIONS,
                testId: NAVBAR.USER_DROPDOWN_OPTIONS,
                isHidden: !isUserClient,
              },
              ...(isMobile
                ? [
                    {
                      label: translate("batsoft_pwa_navigation.help"),
                      href: createHref(language, HELP_LINK_BATSOFT),
                      skipHrefPrefix: true,
                    },
                  ]
                : []),
            ],
          },
        ]),
    ...(isMobile
      ? [
          {
            items: [
              {
                label: translate("batsoft_pwa_navigation.gift_cards"),
                href: createHref(language, GIFT_CARDS_LINK),
                skipHrefPrefix: true,
                isHidden: !isMobile,
                isSpecial: true,
                testId: NAVBAR.USER_MOBILE_DROPDOWN_GIFT_CARDS,
              },
            ],
          },
        ]
      : []),
    ...(isUserClient
      ? [
          {
            heading: translate("batsoft_pwa_navigation.batgroup_apps"),
            headingIcon: (
              <Bell
                size={24}
                color={isMobile ? theme.colors.white : theme.colors.black800}
              />
            ),
            items: [
              {
                label: "Batmaid",
                href: BATMAID_LINK.replace("{{lang}}", language),
                skipHrefPrefix: true,
                testId: NAVBAR.USER_DROPDOWN_BATMAID_LINK,
              },
              {
                label: "Batwork",
                href: BATWORK_LINK.replace("{{lang}}", language),
                skipHrefPrefix: true,
                testId: NAVBAR.USER_DROPDOWN_BATWORK_LINK,
              },
            ],
          },
        ]
      : []),
    {
      items: [
        ...(isUserClient || isUserAdmin
          ? [
              {
                label: translate("batsoft_pwa_navigation.subnav_logout"),
                icon: <PowerOff {...iconConfig} />,
                href: logoutUrl,
                action: logoutAction,
                testId: NAVBAR.LOGOUT_BUTTON,
              },
            ]
          : [
              {
                label: translate("batsoft_pwa_navigation.login"),
                icon: <User {...iconConfig} />,
                action: loginAction,
                testId: NAVBAR.LOGIN_BUTTON,
              },
            ]),
      ],
    },
    ...(isAdminImpersonator
      ? [
          {
            items: [
              {
                label: translate("batsoft_pwa_navigation.subnav_exit_user"),
                icon: <UserOff {...iconConfig} />,
                href: exitUserUrl,
                skipHrefPrefix: true,
              },
            ],
          },
        ]
      : []),
  ];
};

interface GetAgentNavigationInput {
  logoutUrl: string;
  exitUserUrl: string;
  isUserAdmin: boolean;
  isAdminImpersonator: boolean;
  isMobile: boolean;
  language: AVAILABLE_LANGS;
  logoutAction: () => void;
  translate: (k: string) => string;
}

export const getAgentNavigationConfig = ({
  logoutUrl,
  exitUserUrl,
  isUserAdmin,
  isAdminImpersonator,
  isMobile,
  language,
  logoutAction,
  translate,
}: GetAgentNavigationInput): NavigationConfig => {
  const iconConfig = {
    size: 24,
    color: isMobile ? theme.colors.white : theme.colors.grey700,
  };

  return [
    {
      items: [
        {
          label: translate("batsoft_pwa_navigation.admin"),
          icon: <UserCircle {...iconConfig} />,
          href: ADMIN_DASHBOARD_LINK,
          isHidden: !isUserAdmin,
          skipHrefPrefix: true,
        },
      ],
    },
    ...(isUserAdmin && !isAdminImpersonator
      ? []
      : [
          {
            items: [
              {
                label: translate(
                  "batsoft_pwa_navigation.subnav_agent_schedule",
                ),
                href: createHref(language, AGENT_SCHEDULE_LINK),
                skipHrefPrefix: true,
              },
              {
                label: translate(
                  "batsoft_pwa_navigation.subnav_agent_my_cleanings",
                ),
                href: createHref(language, AGENT_MY_CLEANINGS_LINK),
                skipHrefPrefix: true,
              },
              {
                label: translate(
                  "batsoft_pwa_navigation.subnav_agent_new_cleanings",
                ),
                href: createHref(language, AGENT_NEW_CLEANINGS_LINK),
                skipHrefPrefix: true,
              },
              {
                label: translate(
                  "batsoft_pwa_navigation.subnav_agent_availabilities",
                ),
                href: createHref(language, AGENT_AVAILABILITIES_LINK),
                skipHrefPrefix: true,
              },
              {
                label: translate("batsoft_pwa_navigation.subnav_agent_profile"),
                href: createHref(language, AGENT_PROFILE_LINK),
                skipHrefPrefix: true,
              },
            ],
          },
        ]),
    {
      heading: translate("batsoft_pwa_navigation.batgroup_apps"),
      headingIcon: (
        <Bell
          size={24}
          color={isMobile ? theme.colors.white : theme.colors.black800}
        />
      ),
      items: [
        {
          label: "Batmaid",
          href: BATMAID_LINK.replace("{{lang}}", language),
          skipHrefPrefix: true,
        },
        {
          label: "Batwork",
          href: BATWORK_LINK.replace("{{lang}}", language),
          skipHrefPrefix: true,
        },
      ],
    },
    {
      items: [
        {
          label: translate("batmaid_pwa_navigation.subnav_logout"),
          icon: <PowerOff {...iconConfig} />,
          href: logoutUrl,
          action: logoutAction,
        },
      ],
    },
    ...(isAdminImpersonator
      ? [
          {
            items: [
              {
                label: translate("batmaid_pwa_navigation.subnav_exit_user"),
                icon: <UserOff {...iconConfig} />,
                href: exitUserUrl,
                skipHrefPrefix: true,
              },
            ],
          },
        ]
      : []),
  ];
};
