/**
 * @author jakubbujakowski
 * @since 2020-3-27
 */

import * as React from "react";
import styled from "styled-components";

import { ACCOUNT_BALANCE } from "@config/testIds/clientProfile";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  amount: number;
  currency: string;
  className?: string;
}

const AmountSummary = (props: Props): React.ReactElement => (
  <AmountSummaryWrapper className={props.className}>
    <TestWrapper testId={ACCOUNT_BALANCE.ACCOUNT_BALANCE_CURRENCY || ""}>
      <span>{props.currency}</span>
    </TestWrapper>
    <TestWrapper testId={ACCOUNT_BALANCE.ACCOUNT_BALANCE_AMOUNT || ""}>
      <Amount>{props.amount.toFixed(2)}</Amount>
    </TestWrapper>
  </AmountSummaryWrapper>
);

export { AmountSummary };

const AmountSummaryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${props => props.theme.fonts.sizes.h6};
`;

const Amount = styled.span`
  margin-left: ${props => props.theme.margins.base_x3};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  flex-shrink: 0;
  text-transform: lowercase;
`;
