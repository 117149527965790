import React from "react";
import styled, { useTheme } from "styled-components";
import { SliceComponentProps } from "@prismicio/react";
import { GatsbyImage, getImage, withArtDirection } from "gatsby-plugin-image";

import { Heading } from "@ui/Atoms/Headings";
import { Button } from "@ui/Atoms/Button";
import { Column, Container, Row } from "@ui/Atoms";
import { rem } from "@ui/helpers";
import { mediaQueries } from "@ui/themes/default";

import {
  Maybe,
  PrismicGlobalContentPageDataBodyTitleTwoCards,
  PrismicGlobalContentPageDataBodyTitleTwoCardsItem,
} from "../../../graphql-types";
import { SliceContainer } from "../../components/SliceContainer";

// @TODO: doesn't work on mobile
const getBackgroundImage = (
  item: Maybe<PrismicGlobalContentPageDataBodyTitleTwoCardsItem>,
) =>
  item?.background_image?.gatsbyImageData &&
  withArtDirection(getImage(item.background_image.gatsbyImageData)!, [
    {
      media: mediaQueries.mobile,
      image: getImage(item.background_image_mobile?.gatsbyImageData)!,
    },
  ]);

const TwoCardsSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyTitleTwoCards>,
): React.ReactElement => {
  const theme = useTheme();

  const primary = props.slice.primary;
  const items = props.slice.items;

  return (
    <SliceContainer>
      <StyledContainer>
        <HeadingWrapper>
          {primary?.two_cards_title?.text && (
            <StyledHeading type="h3" styleAs="h5" color={theme.colors.white}>
              {primary?.two_cards_title?.text}
            </StyledHeading>
          )}

          {primary?.two_cards_description?.text && (
            <Description>{primary?.two_cards_description?.text}</Description>
          )}
        </HeadingWrapper>

        <Row>
          {items?.map(item => (
            <ImageColumn xs={12} lg={6} key={item?.card_title?.text}>
              <Image
                alt=""
                image={getBackgroundImage(item)}
                style={{
                  minHeight: rem(408),
                  borderRadius: theme.border.radius.base,
                }}
              />
              <TextContainer>
                <Heading type="h6">{item?.card_title?.text}</Heading>
                <p>{item?.card_description?.text}</p>
              </TextContainer>
            </ImageColumn>
          ))}
        </Row>

        {props.slice.primary?.two_cards_target_url?.url && (
          <div>
            <Button
              as="a"
              variant="green"
              href={props.slice.primary?.two_cards_target_url?.url}
            >
              {props.slice.primary.two_cards_cto_text?.text}
            </Button>
          </div>
        )}
      </StyledContainer>
    </SliceContainer>
  );
};

export { TwoCardsSlice };

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: ${props =>
    `${props.theme.margins.base_x8} ${props.theme.margins.base_x3}`};

  ${props => props.theme.breakpoints.tablet} {
    padding: ${props => props.theme.margins.base_x4} 0;
  }
`;

const Description = styled.p`
  margin-bottom: ${props => props.theme.margins.base_x4};
  text-align: left;

  ${props => props.theme.breakpoints.desktop} {
    text-align: center;
  }
`;

const StyledHeading = styled(Heading)`
  font-size: ${props => props.theme.fonts.sizes.h5};
  font-weight: ${props => props.theme.fonts.weights.bold};
  margin-bottom: ${props => props.theme.margins.base_x2};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.h3};
  }

  ${props => props.theme.breakpoints.desktop} {
    text-align: center;
  }
`;

const Image = styled(GatsbyImage)`
  ${props => props.theme.breakpoints.tablet} {
    width: ${rem(448)};
    border-radius: ${props => props.theme.border.radius.base};
  }
`;

const ImageColumn = styled(Column)`
  position: relative;
  margin-bottom: ${props => props.theme.margins.base_x4};
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: ${props => props.theme.margins.base};
  left: ${props => props.theme.margins.base_x4};
  right: 0;
  margin-right: auto;
  padding-right: ${props => props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.tablet} {
    width: ${rem(400)};
    padding-right: 0;
  }
`;

const HeadingWrapper = styled.div`
  ${props => props.theme.breakpoints.desktop} {
    max-width: 50%;
  }
`;
