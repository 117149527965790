/**
 * Achtung!
 * This component has a sibling component in `@hooks/useAuth/components/ModalContentLogin`
 * You might want to consider synchronising changes between those components.
 */
import * as React from "react";
import styled from "styled-components";
import { Form, Formik } from "formik";

import { LOGIN } from "@config/testIds/login";

import { MODALS } from "@typings/modals";
import { OAUTH_PROVIDERS, IOAuthState } from "@typings/oauth";
import { ILoginUser } from "@typings/user";
import { NOTIFICATION_TYPES } from "@typings/globals";

import theme from "@ui/themes/default";
import { rem } from "@ui/helpers";
import { Input, Checkbox, FormError } from "@ui/Atoms/Form";
import { Arrow } from "@ui/Assets/Symbolicons/Arrow";
import { Info } from "@ui/Assets/Symbolicons/Info";
import { FadeInContainer, Button, TestWrapper, Margins } from "@ui/Atoms";
import { Notification } from "@ui/Molecules";
import { FormikInputWithError } from "@ui/Molecules/FormikInputWithError";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { GoogleLogin } from "@containers/GoogleLogin";

import {
  getLoginSchema,
  handleOAuthLogin,
  FIELDS,
  sharedInputProps,
} from "./ModalContentLogin.helpers";

interface Props {
  loginUser: (credentials: ILoginUser) => Promise<AsyncActionResult>;
  fetchUserData: () => Promise<AsyncActionResult>;
  showLoading: () => void;
  hideLoading: () => void;
  loginUserByAccessToken: (
    provider: OAUTH_PROVIDERS,
    accessToken: string,
  ) => Promise<AsyncActionResult>;
  recoverPassword: (email: string) => Promise<AsyncActionResult>;
  setUserData: (payload: IOAuthState) => void;
  hideModal: (id: string) => void;
  modalOpts: {
    [key: string]: any;
    id?: string;
  };
  customHeader?: React.ReactNode;
  isMobileFullScreen?: boolean;
  translations: {
    retryLoginText: string;
    loginFailedText: string;
    wrongEmailFormatText: string;
    emailLabelText: string;
    passwordLabelText: string;
    resetPasswordButtonText: string;
    backToLoginButtonText: string;
    forgotPasswordLinkText: string;
    rememberMeCheckboxLabel: string;
    loginButtonText: string;
    loginSeparatorText: string;
    socialMediaRestrictedText?: string;
  };
}

const ModalContentLogin = (props: Props): React.ReactElement => {
  const [rememberMe, setRememberMe] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | null>(null);
  const [notification, setNotification] = React.useState<string | null>(null);
  const [showForgotPassword, setShowForgotPassword] =
    React.useState<boolean>(false);

  const sharedHandleOAuthLoginProps = {
    setError: (errMessage: string) =>
      setError(errMessage || props.translations.loginFailedText),
    hideModal: props.hideModal,
    fetchUserData: props.fetchUserData,
    onOAuthLoginSuccess: props.modalOpts.onOAuthLoginSuccess,
    loginUserByAccessToken: props.loginUserByAccessToken,
    tryTemporaryLogin: props.modalOpts.tryTemporaryLogin,
    setOAuthUserData: props.setUserData,
  };

  const handleSubmit = async (e: ILoginUser) => {
    setError(null);
    props.showLoading();

    const loginResult = await props.loginUser({ ...e, rememberMe });
    props.hideLoading();

    loginResult.onSuccess(async () => {
      if (loginResult.retry) {
        // this API message is already pretranslated
        setNotification(
          loginResult.message || props.translations.retryLoginText,
        );
        return;
      }

      if (loginResult.payload?.data?.redirectUrl) {
        window.location.href = loginResult.payload.data.redirectUrl;
        return;
      }

      const userDataResp = await props.fetchUserData();
      props.hideModal(MODALS.NAVBAR_LOGIN);

      userDataResp.onSuccess(payload => {
        props.modalOpts.onLoginSuccess &&
          props.modalOpts.onLoginSuccess(payload.data);
      });
    });

    loginResult.onError(err => {
      setError(err.message || props.translations.loginFailedText);
    });
  };

  const handlePasswordResetSubmit = async (e: {
    email: string;
  }): Promise<void> => {
    const { onError, onSuccess } = await props.recoverPassword(e.email);

    onSuccess(resp => {
      setNotification(resp.data.message);
      setError(null);
    });

    onError(err => {
      setError(err.message);
    });
  };

  const handleBackToLogin = () => {
    setError(null);
    setNotification(null);
    setShowForgotPassword(!showForgotPassword);
  };

  return showForgotPassword ? (
    <FadeInContainer>
      <Formik
        onSubmit={handlePasswordResetSubmit}
        initialValues={{
          [FIELDS.EMAIL]: "",
        }}
        validationSchema={getLoginSchema(
          props.translations.wrongEmailFormatText,
        )}
      >
        {formikProps => (
          <Form>
            {!!notification && (
              <Margins xs={[null, "base_x2", null, null]}>
                <Notification
                  onClose={() => setNotification(null)}
                  variant={NOTIFICATION_TYPES.SUCCESS}
                  autoHide={false}
                  testId={LOGIN.RESET_PASSWORD_NOTIFICATION}
                >
                  {notification}
                </Notification>
              </Margins>
            )}
            <Margins xs={[null, "base_x2", null, null]}>
              <div>
                <FormikInputWithError
                  label={props.translations.emailLabelText}
                  name={FIELDS.EMAIL}
                  value={formikProps.values[FIELDS.EMAIL]}
                  handleChange={formikProps.handleChange}
                  testId={LOGIN.FORGOT_PASSWORD_INPUT}
                />
              </div>
            </Margins>
            <Margins xs={[null, "base_x2", null, null]}>
              <div>
                <TestWrapper testId={LOGIN.RESET_PASSWORD_BUTTON}>
                  <Button size="large" variant="blue">
                    {props.translations.resetPasswordButtonText}
                  </Button>
                </TestWrapper>
              </div>
            </Margins>
            <Button
              size="large"
              variant="borderBlue"
              onClick={handleBackToLogin}
            >
              {props.translations.backToLoginButtonText}
            </Button>
          </Form>
        )}
      </Formik>
    </FadeInContainer>
  ) : (
    <FadeInContainer>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          [FIELDS.USERNAME]: "",
          [FIELDS.PASSWORD]: "",
          [FIELDS.REMEMBER_ME]: rememberMe,
        }}
      >
        {formikProps => (
          <TestWrapper testId={LOGIN.FORM_WRAPPER}>
            <Form>
              {!!error && (
                <Margins xs={[null, "base_x2", null, null]}>
                  <div>
                    <FormError isCentered testId={LOGIN.ERROR}>
                      {error}
                    </FormError>
                  </div>
                </Margins>
              )}
              {!!notification && (
                <Margins xs={[null, "base_x2", null, null]}>
                  <Notification
                    onClose={() => setNotification(null)}
                    variant={NOTIFICATION_TYPES.SUCCESS}
                    autoHide={false}
                  >
                    {notification}
                  </Notification>
                </Margins>
              )}
              <Margins xs={[null, "base_x2", null, null]}>
                <Input
                  {...sharedInputProps}
                  name={FIELDS.USERNAME}
                  onChange={formikProps.handleChange}
                  value={formikProps.values[FIELDS.USERNAME]}
                  id={FIELDS.USERNAME}
                  label={props.translations.emailLabelText}
                />
              </Margins>
              <Input
                {...sharedInputProps}
                name={FIELDS.PASSWORD}
                onChange={formikProps.handleChange}
                value={formikProps.values[FIELDS.PASSWORD]}
                id={FIELDS.PASSWORD}
                type={"password"}
                label={props.translations.passwordLabelText}
              />
              <Margins xs={["base", "half", null, null]}>
                <LoginOptions>
                  <TestWrapper testId={LOGIN.FORGOT_PASSWORD}>
                    <ForgotPassword
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setShowForgotPassword(!showForgotPassword);
                      }}
                    >
                      <InfoStyled variant="line" size={24} height={24} />
                      {props.translations.forgotPasswordLinkText}
                    </ForgotPassword>
                  </TestWrapper>
                  {!props.isMobileFullScreen && (
                    <Checkbox
                      label={props.translations.rememberMeCheckboxLabel}
                      onChange={() => setRememberMe(!rememberMe)}
                      name="rememberMe"
                      checked={rememberMe}
                      testId={LOGIN.REMEMBER_ME_CHECKBOX}
                    />
                  )}
                </LoginOptions>
              </Margins>
              <TestWrapper testId={LOGIN.CTA}>
                <Button
                  size="large"
                  variant="blue"
                  icon={<Arrow variant="line" color={theme.colors.white} />}
                  iconAnimation="arrowAnimation"
                >
                  {props.translations.loginButtonText}
                </Button>
              </TestWrapper>
              <Separator>
                <TestWrapper testId={LOGIN.SEPARATOR}>
                  <SeparatorText>
                    {props.translations.loginSeparatorText}
                  </SeparatorText>
                </TestWrapper>
              </Separator>

              <SocialAccountLoginButtons>
                <GoogleLogin
                  variant={"login"}
                  onSuccess={resp => {
                    handleOAuthLogin({
                      ...sharedHandleOAuthLoginProps,
                      provider: OAUTH_PROVIDERS.GOOGLE,
                      accessToken: resp.access_token,
                      profile: {
                        firstName: "",
                        lastName: "",
                        email: "",
                      },
                      infoToastText:
                        props.translations.socialMediaRestrictedText,
                    });
                  }}
                  onError={() => setError(props.translations.loginFailedText)}
                />
              </SocialAccountLoginButtons>
            </Form>
          </TestWrapper>
        )}
      </Formik>
    </FadeInContainer>
  );
};

export { ModalContentLogin };

const LoginOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoStyled = styled(Info)`
  margin-left: -${theme.margins.half};
  margin-right: ${theme.margins.base_x1_5};
`;

const ForgotPassword = styled.a`
  margin-bottom: ${theme.margins.base_x2};
  float: right;
  font-size: ${theme.fonts.sizes.body2};
`;

const Separator = styled.div`
  display: block;
  position: relative;
  margin: ${props => `${props.theme.margins.base_x3} 0`};
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.colors.grey500};
  font-size: ${theme.fonts.sizes.overline};

  &::before {
    position: absolute;
    width: 100%;
    border-bottom: ${rem(1)} solid ${props => props.theme.colors.greyDark};
    content: "";
    display: block;
    top: 50%;
    left: 0;
    transform: translateY(-${rem(0.5)});
  }
`;

const SeparatorText = styled.span`
  display: block;
  position: relative;
  padding: 0 ${props => props.theme.margins.base_x2};
  background: ${props => props.theme.colors.white};

  svg {
    position: absolute;
    left: ${props => props.theme.margins.base};
    top: 50%;
    transform: translateY(-50%);
    width: ${rem(24)};
    height: ${rem(24)};
    fill: ${props => props.theme.colors.white};
  }
`;

const SocialAccountLoginButtons = styled.div`
  display: flex;
  justify-content: center;

  & > div:last-of-type {
    margin-left: ${props => props.theme.margins.base_x2};
  }
`;
