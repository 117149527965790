/**
 * @author jakubbujakowski
 * @since 2021-1-26
 */

import * as React from "react";
import styled from "styled-components";

import { fadeIn, fadeOut } from "@ui/animations";
import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  children?: React.ReactNode;
  theme: ITheme;
  isCentered?: boolean;
}

const FormSuccess = styled.div<Props>`
  animation: ${props => (props.hidden ? fadeOut : fadeIn)}
    ${props => props.theme.animations.duration}
    ${props => props.theme.animations.easing} forwards;
  width: 100%;
  position: relative;
  color: ${props => props.theme.colors.green700};
  font-size: ${props => props.theme.fonts.sizes.caption};
  margin-top: ${props => props.theme.margins.base};
  text-align: ${props => (props.isCentered ? "center" : "left")};
`;

export { FormSuccess };

FormSuccess.displayName = "FormSuccess";
