import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";
import { USER_NOTIFICATIONS } from "./userNotifications.typings";

const getUserNotificationValueFunction = (
  store: IStore,
  option: USER_NOTIFICATIONS,
): boolean =>
  store && store.userNotifications && store.userNotifications.get(option);
export const getUserNotificationValue = createSelector(
  getUserNotificationValueFunction,
  f => f,
);

const getUserNotificationsFn = (store: IStore) => store.userNotifications;
export const getUserNotifications = createSelector(
  getUserNotificationsFn,
  f => f,
);
