import styled from "styled-components";

const SliceContainer = styled.div<{
  background?: string;
}>`
  background: ${props => props.background || props.theme.colors.white};

  ${props => props.theme.breakpoints.tablet} {
    padding: ${props => props.theme.margins.base_x8} 0;
  }
`;

export { SliceContainer };
