import React from "react";
import { useDispatch } from "react-redux";

import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import { ContactForm as ContactFormOrganism } from "@ui/Organisms/ContactForm";

import { AsyncActionResult } from "@services/AsyncActionCreatorFactory";

import { useTranslate } from "@hooks/useTranslate";

import { sendContactForm } from "@soft/redux/user";

const ContactForm = (): React.ReactElement => {
  const translate = useTranslate("batsoft_pwa_generic");
  const dispatch = useDispatch<ThunkActionDispatch>();

  return (
    <ContactFormOrganism
      onSubmitAction={async payload => {
        const resp = await dispatch<Promise<AsyncActionResult>>(
          sendContactForm(payload),
        );
        return resp;
      }}
      translations={{
        emailTabText: translate("contact_form_email_tab"),
        callTabText: translate("contact_form_call_tab"),
        messageSuccessText: translate("contact_form_message_sent"),
        introText: translate("contact_form_intro"),
        nameLabelText: translate("contact_form_name_placeholder"),
        emailLabelText: translate("contact_form_email_placeholder"),
        reasonLabelText: translate("contact_form_reason_placeholder"),
        tellUsMoreLabelText: translate("contact_form_tell_us_more_placeholder"),
        submitButtonText: translate("contact_form_send_email_button"),
        callUsTabInfoText: translate("contact_form_call_us_info"),
        callHoursText: translate("contact_form_call_hours"),
        phoneNumberText: translate("contact_form_phone"),
        isRequiredText: translate("validate_is_required"),
        invalidEmailText: translate("email_wrong_format"),
        reasonsList: [],
      }}
    />
  );
};

export { ContactForm };
