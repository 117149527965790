import React from "react";

import { SystemError } from "@ui/Organisms/SystemError";

import { useTranslate } from "@hooks/useTranslate";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const InternalServerErrorPage = (): React.ReactElement => {
  const translate = useTranslate("batmaid_pwa_generic");

  return (
    <Layout>
      <SEO title="5xx: Internal Server Error" />
      <SystemError
        htmlMetaTitleText={translate("5xx_meta_title")}
        titleText={translate("5xx_title")}
        bodyText={translate("5xx_body")}
        btnText={translate("5xx_btn_text")}
        statusCode={500}
      />
    </Layout>
  );
};

export default InternalServerErrorPage;
