import { Maybe, PrismicLinkField } from "@www/graphql-types";
import React from "react";
import styled from "styled-components";

import { HOMEPAGE } from "@config/testIds/app";

import { Margins, Container, Heading, TestWrapper } from "@ui/Atoms";
import { rem, transition } from "@ui/helpers";
import theme from "@ui/themes/default";
import { Play } from "@ui/Assets/Symbolicons/Play";

import { getIframeSrc } from "@services/GetIframeSrc";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useBrowserRerender } from "@hooks/useBrowserRerender";

import { StyledSection } from "./shared";

export interface VideoProps {
  video_title: { text: string };
  video_title_bottom: { text: string };
  video_url: Maybe<PrismicLinkField>;
}

const VideoSection = (props: VideoProps): React.ReactElement => {
  const [showVideo, setShowVideo] = React.useState<boolean>(false);
  const videoEmbedUrl = getIframeSrc(props.video_url?.url);

  const isBrowser = useBrowserRerender();
  const { mobile, tablet } = useBreakpoint();

  const isMobile = mobile || tablet;

  if (showVideo && videoEmbedUrl) {
    return (
      <TestWrapper testId={HOMEPAGE.VIDEO_SECTION}>
        <IframeSection>
          <IframeWrapper>
            <StyledIframe
              src={videoEmbedUrl}
              frameBorder="0"
              allowFullScreen
              allow="autoplay"
            />
          </IframeWrapper>
        </IframeSection>
      </TestWrapper>
    );
  }

  return (
    <TestWrapper testId={HOMEPAGE.VIDEO_SECTION}>
      <Section>
        <Container>
          <Wrapper onClick={() => setShowVideo(true)}>
            <Margins
              xs={[null, "base_x3", null, null]}
              lg={[null, "base_x8", null, null]}
            >
              <StyledHeading
                type="h2"
                styleAs={isBrowser && isMobile ? "h6" : "h4"}
              >
                {props.video_title.text}
              </StyledHeading>
            </Margins>

            <PlayButton>
              <Play size={72} color={theme.colors.white} />
            </PlayButton>

            <Margins
              xs={["base_x3", null, null, null]}
              lg={["base_x8", null, null, null]}
            >
              <StyledBottomText>
                {props.video_title_bottom.text}
              </StyledBottomText>
            </Margins>
          </Wrapper>
        </Container>
      </Section>
    </TestWrapper>
  );
};

export { VideoSection };

const IframeSection = styled(StyledSection)`
  background: ${props => props.theme.colors.black800};
  padding: 0;
  margin: 0;
`;

const Section = styled(StyledSection)`
  overflow: hidden;
  background: ${props => props.theme.colors.black800};
`;

const StyledHeading = styled(Heading)`
  align-self: flex-start;
  color: ${props => props.theme.colors.white};

  ${props => props.theme.breakpoints.desktop} {
    align-self: inherit;
  }
`;

const StyledBottomText = styled.div`
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fonts.sizes.body};

  ${props => props.theme.breakpoints.desktop} {
    font-size: ${props => props.theme.fonts.sizes.h4};
  }
`;

const IframeWrapper = styled.div`
  position: relative;
  padding-top: ${rem(464)};
  width: 100%;
  height: 100%;
  max-height: ${rem(464)};
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const PlayButton = styled.div`
  transition: ${transition("transform")};
  z-index: 1;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;

  &:hover ${PlayButton} {
    transform: scale(1.1);
  }
`;
