import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Fridge = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M10.0427 2.99625V21.0037" />
      <path d="M6.91092 8.47679V15.5232" />
      <rect x="13.0004" y="9.99917" width="5.00208" height="3.95817" />

      <path d="M15.5015 10.8256V10.0427" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0029 21.0037H4.99708C3.89205 21.0037 2.99625 20.1079 2.99625 19.0029V4.99708C2.99625 3.89205 3.89205 2.99625 4.99708 2.99625H19.0029C20.1079 2.99625 21.0037 3.89205 21.0037 4.99708V19.0029C21.0037 20.1079 20.1079 21.0037 19.0029 21.0037Z"
      />
    </svg>
  );
};
