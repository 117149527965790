export enum COMMITMENTS {
  PLAN = "commitmentsPlan",
  PLAN_NAME = "commitmentsPlanName",
  PLAN_WRAPPER = "commitmentsPlanWrapper",
  MOST_POPULAR = "commitmentsPlanMostPopular",
  CHECKMARK = "commitmentsPlanCheckMark",
  ONCE_CLEANING_PRICE_COMMITMENT_MESSAGE = "commitmentsPlansOncePriceMessage",
  PROFILE_HEADER = "clientProfileCommitmentPlansHeader",
  PROFILE_PLAN_INFO = "clientProfileCommitmentPlanInfo",
  PROFILE_SUBSCRIPTION_NAME = "clientProfileSubscriptionName",
  PROFILE_PLAN_SKELETON = "clientProfileCommitmentPlanSkeleton",
  PROFILE_NO_COMMITMENTS_INFO = "clientProfileNoCommitments",
}
