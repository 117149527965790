/**
 * @author tomekbuszewski
 * @since 2019-3-6
 */

import styled, { css } from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";

type Background = "white" | "greySurface";

interface Props {
  background?: Background;
  children?: any;
  small?: boolean;
  theme: ITheme;
  flat?: boolean;
  smallMargins?: boolean;
  noBottomMargin?: boolean;
  noStylingLi?: boolean;
}

const Section = styled.section<Props>`
  padding-top: ${props => (props.small ? 0 : props.theme.margins.base_x9)};
  padding-bottom: ${props => (props.small ? 0 : props.theme.margins.base_x9)};

  margin: ${props =>
    props.smallMargins
      ? `0 -${props.theme.margins.base_x2} -${props.theme.margins.base}`
      : `${props.theme.margins.base_x3} -${props.theme.margins.base_x2}`};

  ${({ flat }) =>
    flat &&
    css`
      padding: 0;
    `}

  ${props =>
    props.noBottomMargin &&
    css`
      margin-bottom: 0;
    `}

  ${props => props.theme.breakpoints.tablet} {
    margin: ${props =>
      props.smallMargins
        ? `0 0 -${props.theme.margins.base}`
        : `${props.theme.margins.base_x2} 0`};
  }

  img {
    max-width: 100%;
  }

  // @TODO: this style tends to overwrite every <li> margin inside <Section>, it should be removed
  // The noStylingLi prop and the no-styling-li class are quick workarounds
  li:not(.no-styling-li) {
    ${props =>
      !props.noStylingLi &&
      css`
        margin-bottom: ${props => props.theme.margins.half};

        &:last-of-type {
          margin-bottom: 0;
        }
      `}
  }
`;

Section.defaultProps = {
  background: "white",
};

export { Section };

Section.displayName = "Section";
