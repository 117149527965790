import * as React from "react";

import { Row, Column } from "@ui/Atoms/Grid";
import { SkeletonGenerator } from "@ui/Atoms";

const HelpWidgetSearchResultsSkeleton = React.memo(() => (
  <SkeletonGenerator count={5} randomWidth={50} />
));

HelpWidgetSearchResultsSkeleton.displayName = "HelpWidgetSearchResultsSkeleton";

const HelpWidgetCategoriesSkeleton = React.memo(() => (
  <React.Fragment>
    {[0, 1, 2, 3].map(idx => (
      <Row key={idx}>
        <Column xs={12} md={6}>
          <SkeletonGenerator count={1} randomWidth={75} />
        </Column>
        <Column xs={12} md={6}>
          <SkeletonGenerator count={1} randomWidth={50} />
        </Column>
      </Row>
    ))}
  </React.Fragment>
));

HelpWidgetCategoriesSkeleton.displayName = "HelpWidgetCategoriesSkeleton";

export { HelpWidgetCategoriesSkeleton, HelpWidgetSearchResultsSkeleton };
