import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import styled from "styled-components";

import { Container, Margins, Paragraph } from "@ui/Atoms";

import { SectionHeading, StyledSection } from "./shared";

export interface TextSectionProps {
  text_section_title: { text: string };
  text_section_text: { text: string };
  isWhite?: boolean;
  backgroundColor?: string;
}

const TextSection = (props: TextSectionProps): React.ReactElement => (
  <StyledSection
    isWhite={props.isWhite}
    backgroundColor={props.backgroundColor}
  >
    <StyledContainer>
      <Margins xs={[null, "base_x3", null, null]}>
        <StyledHeading type="h2" styleAs="h4">
          {props.text_section_title?.text}
        </StyledHeading>
      </Margins>
      <ReactMarkdown
        linkTarget={"_blank"}
        unwrapDisallowed
        rehypePlugins={[rehypeRaw]}
        components={{
          p: StyledParagraph,
        }}
      >
        {props.text_section_text?.text}
      </ReactMarkdown>
    </StyledContainer>
  </StyledSection>
);

export { TextSection };

const StyledContainer = styled(Container)`
  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.body};
  }
`;

const StyledHeading = styled(SectionHeading)`
  font-size: ${props => props.theme.fonts.sizes.h6};
  line-height: ${props => props.theme.fonts.lineHeights.normal};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.h4};
    line-height: ${props => props.theme.fonts.lineHeights.h4};
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-size: ${props => props.theme.fonts.sizes.body};
  margin: ${props =>
    `${props.theme.margins.base_x3} 0 ${props.theme.margins.base_x4}`};

  ${props => props.theme.breakpoints.tablet} {
    margin: ${props =>
      `${props.theme.margins.base} 0 ${props.theme.margins.base_x4}`};
  }
`;
