import { Map } from "immutable";

export const mergeTranslations = (
  input: Map<string, string> | { [k: string]: string },
  db: Map<string, string> = Map(),
): Map<string, string> => {
  const data = Map.isMap(input) ? input : Map(input);

  return db.merge(data);
};
