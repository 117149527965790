/**
 * @author jakubmaslon
 * @since 2020-07-03
 */

import styled, { css } from "styled-components";

import theme from "@ui/themes/default";

const Segment = styled.div<{ isWithoutPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${props =>
    !props.isWithoutPadding &&
    css`
      padding: ${theme.margins.base_x4} ${theme.margins.base_x2};
    `};
  text-align: center;

  ${theme.breakpoints.tablet} {
    ${props =>
      !props.isWithoutPadding &&
      css`
        padding: ${theme.margins.base_x6} ${theme.margins.base_x2};
      `};
  }

  ${theme.breakpoints.desktop} {
    ${props =>
      !props.isWithoutPadding &&
      css`
        padding: ${theme.margins.base_x8};
      `};
  }
`;

Segment.displayName = "Segment";

export { Segment };
