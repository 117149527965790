import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const BroomCircle = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="m15.412 8.588 1.002-1.002" />
      <path
        clipRule="evenodd"
        d="m15.14 12.334.272-.271c.96-.96.96-2.516 0-3.475v0a2.458 2.458 0 0 0-3.475 0l-.271.272-4.526 1.226a1 1 0 0 0-.723 1.083 7.343 7.343 0 0 0 6.414 6.414 1 1 0 0 0 1.083-.723l1.226-4.526Z"
      />
      <circle cx={12} cy={12} r={9.004} />
      <path d="M15.14 12.334 11.666 8.86" />
    </svg>
  );
};
