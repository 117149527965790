import React from "react";
import styled, { css, useTheme } from "styled-components";

import { slideDownTransition, slideUpTransition } from "@ui/animations";
import { transition } from "@ui/helpers";
import { Caret } from "@ui/Assets/Symbolicons/Caret";
import { Plus, Minus } from "@ui/Assets/MyIcons";

import { TestWrapper } from "../TestWrapper";
import { Heading } from "../Headings";
import { Margins } from "../Margins";

type Variant = "plus" | "default";

interface Props {
  className?: string;
  title?: string | React.ReactElement;
  icon?: React.ReactElement;
  caretTitle?: string | React.ReactElement;
  subtitle?: string;
  children: React.ReactNode;
  titleTestId?: string;
  contentTestId?: string;
  renderAccordionText?: () => React.ReactElement;
  isClosedOnInit?: boolean;
  variant?: Variant;
  forcedOpen?: number;
  onChange?: (isOpened: boolean) => void;
}

interface ChildProps extends Omit<Props, "onChange"> {
  isOpen: boolean;
  onToggle: () => void;
}

const PlusVariant = (props: ChildProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <div className={props.className}>
      <TestWrapper testId={props.titleTestId}>
        <AccordionHeader onClick={props.onToggle}>
          <CaretWrapper>
            <Margins xs={[null, null, null, "base_x2"]}>
              {props.isOpen ? (
                <Minus width={theme.icons.sizes.base_x3} />
              ) : (
                <Plus width={theme.icons.sizes.base_x3} />
              )}
            </Margins>
            <AccordionTitle variant="plus" type="h6">
              {props.caretTitle}
            </AccordionTitle>
          </CaretWrapper>
        </AccordionHeader>
      </TestWrapper>
      <AccordionItem isOpen={props.isOpen}>
        <TestWrapper testId={props.contentTestId}>
          <AccordionContent>{props.children}</AccordionContent>
        </TestWrapper>
      </AccordionItem>
    </div>
  );
};

const DefaultVariant = (props: ChildProps): React.ReactElement => {
  const theme = useTheme();

  return (
    <div className={props.className}>
      <TestWrapper testId={props.titleTestId}>
        <AccordionHeader onClick={props.onToggle}>
          {props.title && (
            <AccordionTitle type="h6">{props.title}</AccordionTitle>
          )}
          {props.subtitle && (
            <AccordionSubtitle>{props.subtitle}</AccordionSubtitle>
          )}

          {!!props.icon &&
            React.cloneElement(props.icon, {
              isOpen: props.isOpen,
            })}

          {!props.icon && (
            <CaretWrapper>
              <StyledCaret
                size={28}
                variant="line"
                color={theme.colors.blue400}
                isOpen={props.isOpen}
              />
              {props.caretTitle && (
                <AccordionTitle type="h6">{props.caretTitle}</AccordionTitle>
              )}
            </CaretWrapper>
          )}
        </AccordionHeader>
      </TestWrapper>
      {!props.isOpen &&
        !!props.renderAccordionText &&
        props.renderAccordionText()}
      <AccordionItem isOpen={props.isOpen}>
        <TestWrapper testId={props.contentTestId}>
          <AccordionContent>{props.children}</AccordionContent>
        </TestWrapper>
      </AccordionItem>
    </div>
  );
};

const variants = {
  plus: PlusVariant,
  default: DefaultVariant,
};

const Accordion = (props: Props): React.ReactElement => {
  const { variant, ...restProps } = props;
  const [isOpen, setOpen] = React.useState(!props.isClosedOnInit);

  const toggle = React.useCallback(
    () =>
      setOpen(state => {
        const newState = !state;

        props.onChange?.(newState);
        return newState;
      }),
    [setOpen],
  );

  const Variant = React.useMemo(
    () => variants[variant || "default"],
    [variant],
  );

  React.useEffect(() => {
    if (!props.forcedOpen) return;

    setOpen(true);
  }, [props.forcedOpen]);

  return <Variant {...restProps} isOpen={isOpen} onToggle={toggle} />;
};

const AccordionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`;

const AccordionSubtitle = styled.div`
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

const AccordionItem = styled.div<{ isOpen: boolean }>`
  max-height: 99999px; /* transition will break without max-height in px */
  opacity: 1;
  visibility: visible;
  transition: ${slideDownTransition};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      transition: ${slideUpTransition};
    `}
`;

const AccordionTitle = styled(Heading)<{ variant?: "plus" | "default" }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.theme.margins.base};

  ${props =>
    props.variant === "plus" &&
    css`
      font-size: ${props => props.theme.fonts.sizes.body};
      font-weight: ${props => props.theme.fonts.weights.normal};
    `}
`;

const AccordionContent = styled.div`
  margin-top: ${props => props.theme.margins.base_x3};
`;

const StyledCaret = styled(Caret)<{ isOpen?: boolean }>`
  transform: ${props => (props.isOpen ? "rotate(180deg)" : "none")};
  transition: ${transition(["transform"])};
`;

const CaretWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export { Accordion, AccordionHeader, AccordionTitle, AccordionContent };
