/**
 * @author tomekbuszewski
 * @since 2019-7-22
 */

import * as React from "react";
import styled from "styled-components";

import { fadeInFromRight } from "@ui/animations";
import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  children?: React.ReactNode;
  theme: ITheme;
}

const InlineSuccessNotification = styled.span<Props>`
  animation: ${fadeInFromRight} ${props => props.theme.animations.durationLong}
    forwards;
  background: ${props => props.theme.colors.green100};
  color: ${props => props.theme.colors.green900};

  margin-left: ${props => props.theme.margins.base};
  padding: ${props =>
    `${props.theme.margins.base} ${props.theme.margins.base_x2}`};
  border-radius: ${props => props.theme.buttons.borderRadius};
`;

export { InlineSuccessNotification };

InlineSuccessNotification.displayName = "InlineSuccessNotification";
