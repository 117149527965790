import React from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";

import { BoxesProps, BoxesSection, DefaultCard } from "./BoxesSection";

interface Location {
  image_caption: { text: string };
  city_image: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  service_link?: { slug: string };
}

export interface CitiesServicesProps {
  enjoy_service_title: { text: string };
  enjoy_service_cities: Location[];
  language: string;
}

const CitiesServices = (
  props: CitiesServicesProps,
): React.ReactElement | null => {
  //@TODO: refactor prismic custom type to be the same
  const boxesData: BoxesProps<DefaultCard> = {
    section_title: props.enjoy_service_title,
    items: props.enjoy_service_cities.map(card => ({
      box_image: card.city_image.gatsbyImageData,
      box_image_text: card.image_caption,
      service_link: card.service_link,
    })),
    language: props.language,
  };

  if (boxesData.items.length) {
    return <BoxesSection {...boxesData} isWhite />;
  }

  return null;
};

export { CitiesServices };
