import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const SofaLamp = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M3 19.7305V21" />
      <path d="M13 13.5V12C13 10.3431 11.6569 9 10 9H7C5.34315 9 4 10.3431 4 12V13.5" />
      <path d="M14 19.7287V21" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 20H13C14.1046 20 15 19.1046 15 18V15C15 14.1716 14.3284 13.5 13.5 13.5H13C12.1716 13.5 11.5 14.1716 11.5 15V17H5.5V15C5.5 14.1716 4.82843 13.5 4 13.5H3.5C2.67157 13.5 2 14.1716 2 15V18C2 19.1046 2.89543 20 4 20H4.5Z"
      />
      <path d="M19 20V9.00002" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5708 2H17.4292C16.9827 2 16.5903 2.29598 16.4677 2.72528L15.0391 7.72528C14.9529 8.027 15.0133 8.35168 15.2023 8.6022C15.3912 8.85271 15.6868 9 16.0006 9H21.9994C22.3132 9 22.6088 8.85271 22.7977 8.6022C22.9867 8.35168 23.0471 8.027 22.9609 7.72528L21.5323 2.72528C21.4097 2.29598 21.0173 2 20.5708 2Z"
      />
    </svg>
  );
};
