import * as React from "react";

import { ORIGIN } from "@typings/globals";

import { Analytics as AnalyticsBatmaid } from "@app/containers/Analytics";

import { Analytics as AnalyticsBatsoft } from "@soft/containers/Analytics";

interface AnalyticsProps {
  origin: ORIGIN;
}

const Analytics = (props: AnalyticsProps): React.ReactElement | null => {
  /* 
    It is intentional, gatsby during build time gets mad when
    - switch is used
    - const with process.env.[someKey] value is used
    - enum is used
    @TODO: find a way to use enums
  */

  if (props.origin === "batmaid") {
    return <AnalyticsBatmaid />;
  }

  if (props.origin === "batsoft") {
    return <AnalyticsBatsoft />;
  }

  if (props.origin === "batwork") {
    return null;
  }

  return null;
};

export default Analytics;
