import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const UserProfileRefresh = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <circle cx="12" cy="8.24844" r="4.25177" />
      <path
        d="M3.37166 20.0033C3.37166 20.3485 3.65148 20.6283 3.99666 20.6283C4.34184 20.6283 4.62166 20.3485 4.62166 20.0033H3.37166ZM11.0826 16.1264C11.4278 16.1264 11.7076 15.8466 11.7076 15.5014C11.7076 15.1563 11.4278 14.8764 11.0826 14.8764V16.1264ZM4.62166 20.0033C4.62166 17.8625 6.35768 16.1264 8.49853 16.1264V14.8764C5.66732 14.8764 3.37166 17.1721 3.37166 20.0033H4.62166ZM8.49853 16.1264H11.0826V14.8764H8.49853V16.1264Z"
        fill="currentColor"
      />
      <path d="M19.5862 16.2708H21.0007V14.8552" />
      <path d="M16.4218 19.3331H15.0072V20.7486" />
      <path d="M21.0037 18.8689C20.8537 19.305 20.6176 19.7172 20.2684 20.0654C19.0179 21.3159 16.9901 21.3159 15.7396 20.0654C15.5175 19.8433 15.3364 19.5952 15.1933 19.3321" />
      <path d="M20.8157 16.2698C20.6726 16.0067 20.4915 15.7596 20.2694 15.5365C19.0189 14.286 16.9911 14.286 15.7406 15.5365C15.3914 15.8856 15.1523 16.2968 15.0022 16.733" />
    </svg>
  );
};
