import { ReduxActionNameCreator } from "@services/ReduxActionNameCreator";

import { ThunkActionDispatch } from "@typings/work.reduxThunkActions";
import { ModalCaller } from "@typings/modals";

import { DESTROY_MODAL_AFTER } from "@config/consts";

const action = new ReduxActionNameCreator("modals");

// Actions
export const SHOW = action.make("show");
export const HIDE = action.make("hide");
export const DESTROY = action.make("destroy");

// Action creators
export const showModal = (
  payload: string | ModalCaller,
  options: object = {},
) => {
  return {
    type: SHOW,
    payload: {
      autohide: typeof payload === "string" ? false : payload.autohide,
      id: typeof payload === "string" ? payload : payload.id,
      options,
    },
  };
};

export const destroyModal = (payload: string) => ({ type: DESTROY, payload });
export const hideModal = (payload: string) => (
  dispatch: ThunkActionDispatch,
) => {
  dispatch({ type: HIDE, payload });

  setTimeout(() => {
    dispatch({ type: DESTROY, payload });
  }, DESTROY_MODAL_AFTER);
};
