import { Record, Map } from "immutable";

import { ChosenAgent } from "@typings/booking";
import {
  SECOND_PILLAR_RETIREMENT,
  SICKNESS_INSURANCE,
  SicknessInsuranceCosts,
  SecondPillarRetirementCosts,
  SalaryDefaults,
  BookingSalary,
} from "@typings/salary";
import { Contract } from "@typings/contracts";

export interface SalaryReducerState {
  serviceLocationId: number;
  chosenAgentId: string | null;
  prevSicknessInsurance: SICKNESS_INSURANCE;
  sicknessInsurance: SICKNESS_INSURANCE;
  sicknessInsuranceCosts: Record<SicknessInsuranceCosts>;
  professionalAccidentInsurance: boolean;
  agreeToProfessionalAccidentInsurance: boolean;
  insurancePolicyNumber: string;
  insurancePolicyWillBeProvidedLater: boolean;
  prevSecondPillarRetirement: SECOND_PILLAR_RETIREMENT;
  secondPillarRetirement: SECOND_PILLAR_RETIREMENT;
  secondPillarRetirementCosts: Record<SecondPillarRetirementCosts>;
  salaryDefaults: Record<SalaryDefaults>;
  salaryDefaultsFetched: boolean;
  salary: Record<BookingSalary>;
  sliderSuperGrossSalary: number;
  sliderChangeCount: number;
  cachePreviousData: boolean;
  cachedData: CachedSalaryData | null;
}

export type SalaryProposalParams = Pick<
  Contract,
  | "grossSalary"
  | "secondPillarRetirement"
  | "sicknessInsurance"
  | "insurancePolicy"
  | "insurancePolicyNumber"
  | "insurancePolicyWillBeProvidedLater"
>;

export type CachedSalaryData = {
  existingAPG: SICKNESS_INSURANCE;
  existingLPP: SECOND_PILLAR_RETIREMENT;
  existingNetSalary: number;
  existingSuperGrossSalary: number;
};

export type MapOfChosenAgents = Map<string, Record<ChosenAgent>>;

export type SalaryReducer = Record<SalaryReducerState>;

export const LOCAL_STORAGE_KEY = "store:salary";
