/**
 * @author jakubbujakowski
 * @since 2019-11-22
 */

import styled from "styled-components";

const OptionHolder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    margin-right: ${props => props.theme.margins.base_x2};
  }

  > div:last-of-type {
    margin-right: 0;
  }
`;

OptionHolder.displayName = "OptionHolder";

export { OptionHolder };
