import { ROUTES } from "@config/app.routing";

import {
  AVAILABLE_LANGS,
  BOOKING_TYPES,
  HERO_TYPES,
  SERVICE_TYPE,
} from "@typings/globals";
import { DEFAULT_CLEANING_TYPE } from "@typings/user";

export const resolveBookingRoute = (bookingType: BOOKING_TYPES): ROUTES => {
  switch (bookingType) {
    case BOOKING_TYPES.B2B:
      return ROUTES.B2B_GET_A_QUOTE;
    case BOOKING_TYPES.EOT:
      return ROUTES.END_OF_TENANCY;
    default:
      return ROUTES.BOOKING;
  }
};

export const resolveBookingRouteByDefaultCleaningType = (
  defaultCleaningType: DEFAULT_CLEANING_TYPE | null,
): ROUTES => {
  switch (defaultCleaningType) {
    case DEFAULT_CLEANING_TYPE.B2B:
      return ROUTES.B2B_GET_A_QUOTE;
    case DEFAULT_CLEANING_TYPE.EOT:
      return ROUTES.END_OF_TENANCY;
    default:
      return ROUTES.BOOKING;
  }
};

const getServiceRoute = (heroType?: HERO_TYPES | SERVICE_TYPE) => {
  switch (heroType) {
    case SERVICE_TYPE.B2B:
    case HERO_TYPES.OFFICE_CLEANING:
      return ROUTES.B2B_GET_A_QUOTE;
    case SERVICE_TYPE.END_OF_TENANCY:
    case HERO_TYPES.END_OF_TENANCY:
      return ROUTES.END_OF_TENANCY;
    case SERVICE_TYPE.HOME_CLEANING:
    case HERO_TYPES.HOME_CLEANING:
    default:
      return ROUTES.BOOKING;
  }
};

export const buildBookingUrl = ({
  language,
  zip,
  serviceType,
}: {
  language: AVAILABLE_LANGS;
  zip: string;
  serviceType?: SERVICE_TYPE;
}): string => {
  const serviceRoute = getServiceRoute(serviceType);
  const baseRoute = `/${language}${ROUTES.CLIENT_DASHBOARD}#${serviceRoute}`;

  // temporary solution until we fully migrate to postalCode query for all types of booking
  const queries = `?postalCode=${zip}&serviceSelected=✓`;

  return `${baseRoute}${queries}`;
};
