import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const ShieldCheckmark = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 21 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        clipRule="evenodd"
        d="M17.167 10.85c0 3.146-3.871 5.483-5.717 6.421-.595.305-1.3.305-1.897.002-1.845-.938-5.72-3.28-5.72-6.422V6.308a.844.844 0 0 1 .752-.827 9.494 9.494 0 0 0 4.894-1.81 1.71 1.71 0 0 1 2.042 0 9.493 9.493 0 0 0 4.894 1.81c.423.047.745.401.752.827v4.543Z"
      />
      <path d="m11.833 9.583-1.665 1.667-1.001-1" />
    </svg>
  );
};
