import { List, Record, Map } from "immutable";

import { CLEANING_FREQUENCY } from "@typings/booking";
import {
  PROMO_CODE_NOTIFICATION,
  AVAILABLE_CURRENCIES,
  PAYMENT_METHODS,
} from "@typings/globals";
import { BOOKINGS_TO_VALIDATE_JOB_TYPE } from "@typings/jobs";

export interface BookingsToValidateReducer {
  availablePaymentMethods: List<PAYMENT_METHODS>;
  selectedPaymentMethod: PAYMENT_METHODS | null;
  legalLocation: Record<LegalLocation>;
  voucherToken: string;
  voucherStatus: PROMO_CODE_NOTIFICATION;
  voucherMessage: string | null | undefined;
  jobs: JobsToValidateMap;
  isLegalLocationTheSame: boolean;
  isLegalLocationPut: boolean;
}

export interface LegalLocation {
  address: string;
  floorAndDoor: string;
  serviceLocationId: number;
  city: string;
  zip: string;
}

export interface JobToValidate {
  firstExecutionDate: string;
  frequency: CLEANING_FREQUENCY;
  price: number;
  currency: AVAILABLE_CURRENCIES;
  uuid: string;
  jobUuid: string;
  contractUrl: string;
  bookingServiceType: BOOKINGS_TO_VALIDATE_JOB_TYPE;
}

export type JobsToValidateMap = Map<string, Record<JobToValidate>>;

export const LOCAL_STORAGE_KEY = "store:bookingsToValidate";
