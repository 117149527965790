import React from "react";
import styled, { useTheme } from "styled-components";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { getImage, withArtDirection } from "gatsby-plugin-image";

import { mediaQueries } from "@ui/themes/default";
import rem from "@ui/helpers/rem";
import { Container, Heading } from "@ui/Atoms";
import { DraggableCarousel } from "@ui/Molecules/DraggableCarousel";

import { useBreakpoint } from "@hooks/useBreakpoint";

import { PrismicGlobalContentPageDataBodyImagewithlistedtext } from "../../../graphql-types";
import { GatsbyImage } from "../../components/GatsbyImage";
import { SliceContainer } from "../../components/SliceContainer";

const ImageWithListedTexts = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyImagewithlistedtext>,
): React.ReactElement => {
  const theme = useTheme();
  const { mobile } = useBreakpoint();
  const primary = props.slice.primary;
  const items = props.slice.items;

  const backgroundResponsiveImage =
    primary?.image?.gatsbyImageData &&
    withArtDirection(getImage(primary.image.gatsbyImageData)!, [
      {
        media: mediaQueries.mobile,
        image: getImage(primary.image.thumbnails?.mobile?.gatsbyImageData)!,
      },
    ]);

  return (
    <SliceContainer background={theme.colors.greySurface}>
      <StyledContainer widthVariant="fullMobile">
        {mobile && (
          <Content>
            <Heading type={"h2"} styleAs={"h4"}>
              {props?.slice?.primary?.section_title?.text}
            </Heading>
            {primary?.section_subtitle?.richText && (
              <PrismicRichText field={primary.section_subtitle.richText} />
            )}
          </Content>
        )}

        <ImageWrapper>
          <BackgroundWrapper>
            <GatsbyImage
              image={backgroundResponsiveImage}
              alt={primary?.image?.alt || ""}
              className={"art-directed"}
              style={{
                minHeight: rem(240),
              }}
            />
          </BackgroundWrapper>

          {!mobile && (
            <Content>
              <BoldHeading type={"h2"} styleAs={"h3"}>
                {props?.slice?.primary?.section_title?.text}
              </BoldHeading>
              {primary?.section_subtitle?.richText && (
                <PrismicRichText field={primary.section_subtitle.richText} />
              )}
            </Content>
          )}
        </ImageWrapper>

        <CarouselWrapper>
          <DraggableCarousel
            slidesToShow={{
              mobile: 1,
              tablet: 3,
              desktop: 3,
            }}
          >
            {items?.map(item => (
              <div key={item?.text_title?.text}>
                <Heading type="h6">{item?.text_title?.text}</Heading>
                <p>{item?.text?.text}</p>
              </div>
            ))}
          </DraggableCarousel>
        </CarouselWrapper>
      </StyledContainer>
    </SliceContainer>
  );
};

export { ImageWithListedTexts };

const BoldHeading = styled(Heading)`
  font-weight: ${props => props.theme.fonts.weights.bold};

  ${props => props.theme.breakpoints.desktop} {
    margin-right: ${props => props.theme.margins.base_x4};
  }
`;

const StyledContainer = styled(Container)`
  overflow: hidden;
`;

const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .art-directed {
    width: 100%;

    ${props => props.theme.breakpoints.tablet} {
      width: auto;
      height: 100%;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  position: relative;
  overflow: hidden;
  padding: ${props => props.theme.margins.base_x4};
  font-size: ${props => props.theme.fonts.sizes.body};

  ${props => props.theme.breakpoints.tablet} {
    min-height: ${rem(352)};
    align-content: center;
    max-width: 50%;
  }

  ${props => props.theme.breakpoints.desktop} {
    min-height: ${rem(475)};

    p {
      margin-right: ${props => props.theme.margins.base_x6};
    }
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    min-height: ${rem(582)};
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  min-height: ${rem(240)};
  margin-bottom: ${props => props.theme.margins.base_x3};

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base_x4};

    img {
      border-radius: ${props => props.theme.border.radius.base};
    }
  }
`;

const CarouselWrapper = styled.div`
  padding: 0 ${props => props.theme.margins.base_x3};

  ${props => props.theme.breakpoints.desktop} {
    padding: 0;
  }
`;
