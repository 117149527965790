export enum NAVBAR {
  BECOME_BATMAID = "becomeBatmaidButton",
  BECOME_BATMAID_MOBILE = "becomeBatmaidButtonMobile",
  LANGUAGE_SELECTOR = "languageSelector",
  LANGUAGE_DROPDOWN = "languageDropdown",
  LANGUAGE_TRIGGER = "languageTrigger",
  LANGUAGE_BUTTON_EN = "languageButtonEn",
  LANGUAGE_BUTTON_FR = "languageButtonFr",
  LANGUAGE_BUTTON_DE = "languageButtonDe",
  LANGUAGE_BUTTON_IT = "languageButtonIt",
  LOGIN_BUTTON = "loginButton",
  LOGIN_HEADER_BUTTON = "loginHeaderButton",
  LOGOUT_BUTTON = "logoutButton",
  USER_DROPDOWN = "userDropdown",
  USER_DROPDOWN_ITEMS = "userDropdownItems",
  USER_DROPDOWN_MY_CLEANINGS = "userMyCleanings",
  USER_DROPDOWN_MY_LAUNDRY = "myLaundry",
  USER_DROPDOWN_BOOKINGS_TO_VALIDATE = "userBookingsToValidate",
  USER_DROPDOWN_PROFILE = "userProfile",
  USER_DROPDOWN_OPTIONS = "userOptions",
  USER_DROPDOWN_BATGROUP_HEADING = "userBatgroupAppsHeading",
  USER_DROPDOWN_BATSOFT_LINK = "userDropdownBatsoftLink",
  USER_DROPDOWN_BATWORK_LINK = "userDropdownBatworkLink",
  USER_MOBILE_DROPDOWN_GIFT_CARDS = "userMobileGiftCards",
  HAMBURGER_DROPDDOWN = "hamburgerDropdown",
  MOBILE_HEADING = "userDropdownMobileHeading",
}
