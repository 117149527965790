import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const PhoneTalking = (props: Icon): React.ReactElement => (
  <svg
    style={{ ...style(props.size, props.height), ...props.style }}
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={props.className}
  >
    <defs>
      <path
        d="M13.3333333,5.33333329 C16.274,5.33333329 18.6666667,7.72599997 18.6666667,10.6666667 C18.6666667,11.0346667 18.9653334,11.3333333 19.3333334,11.3333333 C19.7013334,11.3333333 20.0000001,11.0346667 20.0000001,10.6666667 C20.0000001,6.99066664 17.0093334,3.99999995 13.3333333,3.99999995 C12.9653333,3.99999995 12.6666667,4.29866662 12.6666667,4.66666662 C12.6666667,5.03466662 12.9653333,5.33333329 13.3333333,5.33333329 Z M13.3333333,8.66666665 C14.436,8.66666665 15.3333334,9.56399998 15.3333334,10.6666667 C15.3333334,11.0346667 15.632,11.3333333 16,11.3333333 C16.368,11.3333333 16.6666667,11.0346667 16.6666667,10.6666667 C16.6666667,8.82866665 15.1713334,7.3333333 13.3333333,7.3333333 C12.9653333,7.3333333 12.6666667,7.63199997 12.6666667,7.99999998 C12.6666667,8.36799998 12.9653333,8.66666665 13.3333333,8.66666665 Z M17.4526667,13.5126667 C16.88,12.94 15.9513334,12.94 15.378,13.5126667 L13.622,15.2686667 C11.418,14.3473333 9.65266665,12.582 8.73133331,10.378 L10.4866667,8.62199998 C11.0593333,8.04933331 11.0593333,7.11999997 10.4866667,6.5473333 L8.36933331,4.42933329 C7.79666664,3.85666662 6.8673333,3.85666662 6.29466663,4.42933329 L5.28799996,5.43599996 C4.24199995,6.48266663 3.80066662,7.98266664 4.08399995,9.43399998 C5.11466662,14.714 9.28533332,18.8853334 14.566,19.916 C16.0173334,20.1993334 17.518,19.758 18.564,18.712 L19.5706667,17.7053334 C20.1433334,17.1326667 20.1433334,16.204 19.5706667,15.6306667 L17.4526667,13.5126667 Z"
        id="path-1-phone-talking"
      ></path>
    </defs>
    <g
      id="Atom/icon/communication/phone-talking/solid"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <mask id="mask-2-phone-talking" fill="white">
        <use xlinkHref="#path-1-phone-talking"></use>
      </mask>
      <use
        id="Atom/icon/phone-talking"
        fill="#FFFFFF"
        fillRule="nonzero"
        xlinkHref="#path-1-phone-talking"
      ></use>
      <g
        id="Group"
        mask="url(#mask-2-phone-talking)"
        fill={props.color || theme.colors.grey700}
      >
        <g id="🎨-color">
          <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
        </g>
      </g>
    </g>
  </svg>
);
