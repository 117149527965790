import React from "react";
import { OptionProps } from "react-select/src/types";
import { PlaceholderProps } from "react-select/src/components/Placeholder";
import {
  ContainerProps,
  ValueContainerProps,
} from "react-select/src/components/containers";
import { ControlProps } from "react-select/src/components/Control";

import { ITheme } from "@ui/themes/ThemeInterface";
import theme from "@ui/themes/default";

export const getColors = (themeContext: ITheme) => ({
  ...theme.colors,
  primary: themeContext.colors.blue,
  primary25: themeContext.colors.blue50,
  primary50: themeContext.colors.blue400,
  neutral20: themeContext.colors.grey700,
});

export const getStyles = (themeContext: ITheme) => ({
  placeholder: (styles: React.CSSProperties, state: PlaceholderProps<any>) => {
    const isFocused =
      state.hasValue ||
      state.selectProps.menuIsOpen ||
      state.selectProps.inputValue;

    return {
      ...styles,
      top: isFocused ? 0 : "50%",
      transition: "all 0.1s",
      color: theme.colors.grey700,
      fontSize: isFocused ? theme.fonts.sizes.caption : theme.fonts.sizes.body,
    };
  },
  container: (styles: React.CSSProperties, state: ContainerProps<any>) => ({
    ...styles,
    cursor: state.selectProps.isSearchable ? "text" : "pointer",
    background: theme.colors.white,
  }),
  singleValue: (styles: React.CSSProperties, state: ContainerProps<any>) => ({
    ...styles,
    marginTop: state.selectProps.placeholder ? theme.margins.half : 0,
  }),
  valueContainer: (
    styles: React.CSSProperties,
    state: ValueContainerProps<any>,
  ) => ({
    ...styles,
    fontSize: theme.fonts.sizes.body,
    overflow: "visible",
    background: state.selectProps.isDisabled
      ? theme.colors.greyLight
      : theme.colors.white,
    cursor: state.selectProps.isSearchable ? "text" : "pointer",
    maxWidth: "100%",
  }),
  menu: (styles: React.CSSProperties) => ({
    ...styles,
    margin: 0,
    zIndex: 11,
    background: theme.colors.white,
    boxShadow: "0px 2px 2px 1px rgba(0, 0, 0, 0.1)",
  }),
  menuList: (styles: React.CSSProperties) => ({
    ...styles,
    padding: 0,
  }),
  control: (styles: React.CSSProperties, state: ControlProps<any>) => ({
    ...styles,
    cursor: state.selectProps.isSearchable ? "text" : "pointer",
    minHeight: theme.buttons.height,
    color: theme.colors.black700,
    borderColor: theme.colors.greyDark,
    backgroundColor: state.isDisabled
      ? theme.colors.greyLight
      : theme.colors.white,

    ".option__radio": {
      display: "none",
    },

    "&:hover": {
      borderColor: theme.colors.grey700,
    },

    "&:active": {
      borderColor: theme.colors.blue400,
    },
  }),
  option: (styles: React.CSSProperties, state: OptionProps) => ({
    ...styles,
    cursor: "pointer",
    fontSize: themeContext.fonts.sizes.body2,
    color: state.isDisabled
      ? themeContext.colors.greyDisabledText
      : themeContext.colors.black700,
    backgroundColor: state.isSelected
      ? theme.colors.blue50
      : theme.colors.white,
    padding: 12,

    ":hover": {
      backgroundColor: theme.colors.blue100,
    },
  }),
  input: (styles: React.CSSProperties) => ({
    ...styles,
    overflow: "hidden",
  }),
});
