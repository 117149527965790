import * as React from "react";
import color from "color";
import styled, { keyframes, css } from "styled-components";

import { rem, transition } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";

const ActiveIndicator = styled.span`
  transition: ${transition(["opacity", "transform"])};
  font-weight: ${props => props.theme.fonts.weights.bold};
  color: ${props => props.theme.colors.blue400};
  display: block;
`;

const AddressWrapper = styled.span`
  display: block;
  transition: ${transition(["opacity", "transform"])};
  word-break: break-word;
`;

const Table = styled.table<{ borderCollapse?: "separate" | "initial" }>`
  background: ${props => props.theme.colors.white};
  width: 100%;
  font-size: ${props => props.theme.fonts.sizes.body2};
  border-collapse: ${props => props.borderCollapse || "collapse"};
`;

const TableHeader = styled.thead`
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${rem(30)};
  color: ${props => props.theme.colors.black700};

  border-bottom: ${rem(2)} solid ${props => props.theme.colors.whiteBorder};

  margin-bottom: ${props => props.theme.margins.base_x4};
`;

const TableBody = styled.tbody<{ ref?: any }>`
  &:not(:first-of-type) {
    border-top: ${rem(1)} solid ${props => props.theme.colors.whiteBorder};
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const TableCell = styled.td<{
  right?: boolean;
  cancelled?: boolean;
  minWidth?: number;
  maxWidth?: number;
  flat?: boolean;
  fullPadding?: boolean;
  alignTop?: boolean;
  forceCellDisplay?: boolean;
}>`
  padding-right: ${props => props.theme.margins.base_x2};
  padding-top: ${props => props.theme.margins.base_x2};
  padding-bottom: ${props =>
    props.flat ? props.theme.margins.half : props.theme.margins.base_x2};

  ${({ fullPadding }) =>
    fullPadding &&
    css`
      padding-left: ${props => props.theme.margins.base_x2};
    `}

  text-align: ${props => props.right && "right"};

  min-height: ${rem(50)};
  max-width: ${props => props.maxWidth && `${props.maxWidth}px`};
  min-width: ${props => props.minWidth && `${props.minWidth}px`};

  opacity: ${props => props.cancelled && 0.5};

  border-radius: ${props => props.theme.buttons.borderRadius};

  ${({ alignTop }) =>
    alignTop &&
    css`
      vertical-align: top;
    `}

  &:last-of-type {
    display: ${props => (props.forceCellDisplay ? "table-cell" : "flex")};
    justify-content: flex-end;
    padding-right: ${props =>
      props.fullPadding ? props.theme.margins.base_x2 : "0"};
  }
`;

interface Props {
  active?: boolean;
  theme: ITheme;
  cancelled?: boolean;
}

const OPACITY = 0.35;

const ongoingAnimation = (props: Props) => keyframes`
  0% {
    background-color: ${props.theme.colors.white};
  }
  
  100% {
    background-color: ${color(props.theme.colors.blue100)
      .alpha(OPACITY)
      .toString()};
  }
`;

const TableRow = styled.tr<Props>`
  -webkit-appearance: none;
  animation: ${props => (props.active ? ongoingAnimation : "")} 2s infinite
    alternate;
  opacity: ${props => props.cancelled && 0.5};

  ${props =>
    props.active &&
    css`
      ${ActiveIndicator} {
        opacity: 0;
      }

      &:hover {
        ${ActiveIndicator} {
          opacity: 1;
          transform: translateY(-50%);
        }

        ${AddressWrapper} {
          opacity: 0;
          transform: translateY(50%);
        }
      }
    `}
`;

export {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  ActiveIndicator,
  AddressWrapper,
};
