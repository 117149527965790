import * as React from "react";
import Creatable from "react-select/creatable";
import styled, { ThemeContext } from "styled-components";
import ReactSelect, { components, createFilter } from "react-select";
import { Props } from "react-select/base";

import { SELECT_EXTENDED } from "@config/testIds/selectExtended";

import { Caret } from "@ui/Assets/Symbolicons/Caret";
import { ITheme } from "@ui/themes/ThemeInterface";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { FormError } from "@ui/Atoms/Form/FormError";

import { forceBlur } from "@hooks/useActiveElement";
import { useBreakpoint } from "@hooks/useBreakpoint";

import { getStyles, getColors } from "./SelectExtended.helpers";

export interface SelectOption {
  value: string;
  label: string;
}

export interface SelectOptionCustom extends SelectOption {
  [k: string]: string;
}

interface SelectExtendedProps extends Props {
  isCreatable?: boolean;
  errors?: string | React.ReactNode;
  hideDropdownIndicator?: boolean;
  clearInput?: boolean;
  optionalLabel?: string;
  customOption?: (props: any) => JSX.Element;
  customValueContainer?: (props: any) => JSX.Element;
  customSingleValue?: (props: any) => JSX.Element;
}

const { ValueContainer, Placeholder, Option } = components;

const CustomOption = (props: any) => (
  <Option {...props}>
    <TestWrapper testId={`${SELECT_EXTENDED.OPTION}-${props.value}`}>
      <span>{props.children}</span>
    </TestWrapper>
  </Option>
);

const CustomValueContainer = (props: any) => (
  <ValueContainer {...props}>
    <Placeholder {...props}>{props.selectProps.placeholder}</Placeholder>
    {React.Children.map(props.children, child =>
      child && child.type !== Placeholder ? child : null,
    )}
  </ValueContainer>
);

const DropdownIndicator = () => <CaretStyled variant="line" size={24} />;

export const SelectExtended = ({
  className = "",
  ...props
}: SelectExtendedProps): React.ReactElement => {
  const themeContext: ITheme = React.useContext(ThemeContext);
  const { mobile, tablet } = useBreakpoint();

  const [input, setInput] = React.useState("");

  React.useEffect(() => {
    if (props.clearInput) {
      setInput("");
    }
  }, [props.clearInput]);

  const config = {
    noOptionsMessage: () => null,
    menuPlacement: props.menuPlacement,
    styles: getStyles(themeContext),
    components: {
      DropdownIndicator: props.hideDropdownIndicator ? null : DropdownIndicator,
      IndicatorSeparator: () => null,
      Option: props.customOption || CustomOption,
    },
  };

  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    stringify: (option: { label: string }) =>
      input.includes("-") ? option.label : `${option.label.replace(/-/g, "")}`,
  };

  return (
    <React.Fragment>
      {props.isCreatable ? (
        <Creatable
          theme={theme => ({
            ...theme,
            colors: getColors(themeContext),
          })}
          {...config}
          {...props}
          menuShouldScrollIntoView={false}
        />
      ) : (
        <ReactSelect
          className={[
            "select-extended",
            className,
            props.isDisabled ? "select-extended--disabled" : "",
          ].join(" ")}
          theme={theme => ({
            ...theme,
            colors: getColors(themeContext),
          })}
          {...props}
          {...config}
          inputValue={input}
          onInputChange={(value, action) => {
            if (
              action.action === "input-change" ||
              action.action === "set-value"
            ) {
              setInput(value);
              props.onInputChange && props.onInputChange(value, action);
            }
          }}
          components={{
            ...config.components,
            ValueContainer: props.customValueContainer || CustomValueContainer,
            ...(props.customSingleValue && {
              SingleValue: props.customSingleValue,
            }),
          }}
          menuShouldScrollIntoView={false}
          filterOption={props.filterOption || createFilter(filterConfig)}
          onMenuClose={() => (mobile || tablet) && forceBlur()}
        />
      )}
      {props.errors && <FormError>{props.errors}</FormError>}
      {props.optionalLabel && !props.errors && (
        <OptionalLabel>{props.optionalLabel}</OptionalLabel>
      )}
    </React.Fragment>
  );
};

const CaretStyled = styled(Caret)`
  margin-right: ${props => props.theme.margins.base};
`;

const OptionalLabel = styled.span`
  display: inline-block;
  font-size: ${props => props.theme.fonts.sizes.caption};
  padding-left: ${props => props.theme.margins.base};
`;
