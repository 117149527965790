import React from "react";

import { IS_BROWSER } from "@config/consts";
import { UTM_ENDPOINT } from "@config/endpoints";

import { getQueryValue } from "@services/QueryParamsParser/QueryParamsParser";
import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";

import { asyncActionCreator } from "../services/AsyncActionCreator";

interface UTMData {
  utm_medium?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
}

const getUTMEndpointUrlWithQuery = (utmData: UTMData): string => {
  const queryValues = Object.entries(utmData).map(
    entry => `${entry[0]}=${entry[1]}`,
  );
  const query = queryValues.join("&");

  return `${UTM_ENDPOINT}?${query}`;
};

const UTMCatcher = (): null => {
  const putUTMTags = async (utmData: UTMData) => {
    const UTMEndpointUrlWithQuery = getUTMEndpointUrlWithQuery(utmData);

    await asyncActionCreator({
      url: UTMEndpointUrlWithQuery,
      action: "",
      method: ASYNC_ACTION_TYPES.PUT,
    });
  };

  React.useEffect(() => {
    if (!IS_BROWSER) {
      return;
    }

    const utmMedium = getQueryValue(window.location.href, "utm_medium");
    const utmSource = getQueryValue(window.location.href, "utm_source");
    const utmCampaign = getQueryValue(window.location.href, "utm_campaign");
    const utmContent = getQueryValue(window.location.href, "utm_content");
    const utmTerm = getQueryValue(window.location.href, "utm_term");

    const utm: UTMData = {
      ...(utmMedium && { utm_medium: utmMedium }),
      ...(utmSource && { utm_source: utmSource }),
      ...(utmCampaign && { utm_campaign: utmCampaign }),
      ...(utmContent && { utm_content: utmContent }),
      ...(utmTerm && { utm_term: utmTerm }),
    };

    if (Object.keys(utm).length) {
      putUTMTags(utm);
    }
  }, []);

  return null;
};

export default UTMCatcher;
