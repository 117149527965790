import * as React from "react";
import styled from "styled-components";
import color from "color";

import { APP_NAMES } from "@typings/globals";

import { getBackgroundPerApp, rem, transition } from "@ui/helpers";

interface NavigationProps {
  active?: boolean;
  children: React.ReactNode;
  className?: string;
  variant: APP_NAMES;
}
const NavigationComponent = React.forwardRef(
  (props: NavigationProps, ref: React.RefObject<any>) => (
    <nav className={props.className} ref={ref}>
      <ul>{props.children}</ul>
    </nav>
  ),
);

NavigationComponent.displayName = "NavigationComponent";

const Navigation = styled(NavigationComponent)`
  position: absolute;
  width: 100%;
  top: ${rem(54)};
  left: 0;

  transition: ${transition(["opacity", "transform"])};

  pointer-events: ${props => (props.active ? "all" : "none")};

  opacity: ${props => (props.active ? 1 : 0)};
  transform: translateY(${props => (props.active ? 0 : "-8px")});

  background: ${props => getBackgroundPerApp(props.variant)};

  ${props =>
    props.variant === APP_NAMES.BATWORK &&
    `
  box-shadow: 0 ${rem(1)} ${rem(2)} ${rem(1)}
  ${color(props.theme.colors.black).fade(0.5).toString()};
  `}

  padding: ${props => props.theme.margins.base_x2};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    height: 100%;
  }

  .user-dropdown {
    button {
      font-weight: ${props => props.theme.fonts.weights.bold};
    }
  }

  hr {
    margin: 0;
    width: 100%;
    border-bottom: ${rem(1)} solid ${props => props.theme.colors.whiteHover};
  }

  ${props => props.theme.breakpoints.desktop} {
    position: static;
    width: auto;
    opacity: 1;
    height: auto;

    transform: none;
    pointer-events: all;

    background: none;
    border: 0;

    padding: 0;
    margin-right: ${props => props.theme.margins.base};

    transition: none;

    box-shadow: none;

    ul {
      display: flex;
      align-items: center;
    }
  }
`;

export { Navigation };
