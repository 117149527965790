interface DataLayerPush {
  event: string;
  page?: string;
  product?: string;
  field?: string;
  newClient?: boolean;
  paymentStatus?: string;
  option?: string;
  humanId?: string;
  isUserLoggedIn?: string;
  clientId?: string;
  commitment?: boolean;
  user_category?: string;
  click?: string;
  button?: string;
}

export const dataLayerFacade = () => {
  const isDataLayerAvailable = () =>
    typeof (window as any).dataLayer !== "undefined" &&
    typeof (window as any).dataLayer.push === "function";

  return {
    push: (data: DataLayerPush) => {
      if (isDataLayerAvailable()) {
        (window as any).dataLayer.push(data);
      }
    },
  };
};
