import React, { useEffect } from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";

import {
  DEFAULT_LANGUAGE,
  IS_DEV,
  NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT,
} from "@config/consts";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS, ORIGIN } from "@typings/globals";
import { FOOTER_TYPE } from "@typings/footer";

import theme from "@ui/themes/default";
import GlobalStyle from "@ui/style";
import { rem } from "@ui/helpers";
import { Container } from "@ui/Atoms/Grid";
import { ErrorBoundary } from "@ui/Atoms";
import { ErrorFallback } from "@ui/Molecules/ErrorFallback";

import { langFromPathname } from "@services/LangFromPathname";

import { Cookiebot } from "@containers/Cookiebot";

import { useTelemetry } from "@hooks/useTelemetry";
import { useTranslate } from "@hooks/useTranslate";

import FooterStatic from "./FooterStatic";
import Header from "./Header";
import Analytics from "./Analytics";

interface Props {
  children?: React.ReactNode;
  language?: string;
  isFullWidth?: boolean;
  origin?: ORIGIN;
  country?: AVAILABLE_COUNTRIES;
  isTeaserVersion?: boolean;
  footerType?: FOOTER_TYPE;
  specialBanner?: React.ReactElement;
  backgroundColor?: string;
}

const Layout = (props: Props): React.ReactElement => {
  const translate = useTranslate();
  const resolvedCountry = process.env.COUNTRY;
  const resolvedOrigin = process.env.ORIGIN;
  const resolvedLanguage = props.language || langFromPathname();
  const location = useLocation();
  const { pageView } = useTelemetry();

  useEffect(() => {
    const pageId = (Helmet.peek().htmlAttributes as any)?.[
      "data-prismic-document-id"
    ];

    if (IS_DEV && !pageId) {
      console.warn(
        "`data-prismic-document-id` attribute is missing, you probably forgot to pass `prismicId` to `context` when using `createPage()`.",
      );
    }

    if (pageId) {
      pageView({
        pageId,
      });
    }
  }, [pageView, location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle backgroundColor={props.backgroundColor} />
        <CustomGlobalStyles />
        <React.Fragment>
          <Cookiebot />
          <Analytics origin={resolvedOrigin as ORIGIN} />
          {props.specialBanner}

          <Header
            staticLanguage={resolvedLanguage as AVAILABLE_LANGS}
            origin={resolvedOrigin as ORIGIN}
            country={resolvedCountry as AVAILABLE_COUNTRIES}
            isTeaserVersion={props.isTeaserVersion}
          />
          <ErrorBoundary
            fallbackUi={() => (
              <ErrorFallback
                somethingWentWrongText={translate(
                  "batmaid_pwa_generic.something_went_wrong",
                )}
                appCrashExplanationText={translate(
                  "batmaid_pwa_generic.app_crash_explanation",
                )}
              />
            )}
          >
            <StyledContainer
              widthVariant={props.isFullWidth ? "full" : "default"}
            >
              {props.children}
            </StyledContainer>
          </ErrorBoundary>
          {props.footerType === FOOTER_TYPE.DEFAULT && (
            <FooterStatic
              currentLanguage={resolvedLanguage as AVAILABLE_LANGS}
              currentFlag={resolvedCountry as AVAILABLE_COUNTRIES}
              origin={props.origin}
              isTeaserVersion={props.isTeaserVersion}
            />
          )}
        </React.Fragment>
      </React.Fragment>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  language: DEFAULT_LANGUAGE,
  footerType: FOOTER_TYPE.DEFAULT,
};

export default Layout;

const StyledContainer = styled(Container)`
  --layout-min-height: calc(
    100vh - ${rem(NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT + 508)}
  ); // 508px of footer + NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT of header

  ${theme.breakpoints.desktop} {
    min-height: var(--layout-min-height);
  }
`;

// body can not be set to any type of the `overflow` property as the default state
// otherwise, the scroll to top will not work after route changing
const CustomGlobalStyles = createGlobalStyle`
html {
  overflow-y: auto;
}

html, body {
  height: 100%;
}
`;
