import { Map, List } from "immutable";

export default function mapToList<T>(input: Map<string, T>): List<T> {
  if (Map.isMap(input)) {
    return input.reduce((acc: List<T>, item: T): List<T> => {
      return acc.push(item);
    }, List());
  }

  return List();
}
