/**
 * @author jakubmaslon / tomekbuszewski
 * @since 2020-02-11
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { COMMITMENTS } from "@config/testIds/commitments";

import { rem, transition } from "@ui/helpers";
import theme from "@ui/themes/default";
import { Button } from "@ui/Atoms/Button";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { CheckMark } from "@ui/Assets/Symbolicons/CheckMark";

interface SelectButtonsContainerProps {
  withGutters?: boolean;
  withShrinkedButtons?: boolean;
  disabled?: boolean;
  justify?: "space-between" | "flex-start";
  useGrid?: boolean; // @TODO: use styles from this prop as default in a separate task
}

interface SelectButtonProps {
  children: React.ReactNode;
  active?: boolean;
  withCheckMark?: boolean;
  disabled?: boolean;
  justify?: "space-between" | "flex-start";
  isLite?: boolean;
  testId?: string;
  containerTestId?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  className?: string;
  ribbon?: string | React.ReactNode;
  verticalAlignment?: "top" | "center";
  autoWidth?: boolean;
  withBackgroundChangeOnHover?: boolean;
  hideRadio?: boolean;
}

interface RibbonProps {
  active: boolean;
  isLite: boolean;
}

const SelectButton = (props: SelectButtonProps): React.ReactElement => (
  <TestWrapper testId={props.containerTestId}>
    <SelectButtonWrapper
      active={props.active}
      disabled={props.disabled}
      isLite={props.isLite}
      className={props.className}
      autoWidth={props.autoWidth}
      withBackgroundChangeOnHover={props.withBackgroundChangeOnHover}
      hideRadio={props.hideRadio}
      onClick={(e: React.SyntheticEvent) => {
        e.preventDefault();
        if (props.disabled) return;
        props.onClick && props.onClick(e);
      }}
    >
      {props.ribbon && (
        <TestWrapper testId={COMMITMENTS.MOST_POPULAR}>
          <Ribbon active={!!props.active} isLite={!!props.isLite}>
            {props.ribbon}
          </Ribbon>
        </TestWrapper>
      )}
      <TestWrapper testId={props.testId}>
        <StyledSelectButton
          active={props.active}
          type="button"
          isLite={props.isLite}
          variant={props.disabled ? "borderGrey" : "blue"}
          disabled={props.disabled}
          verticalAlignment={props.verticalAlignment}
          withBackgroundChangeOnHover={props.withBackgroundChangeOnHover}
          hideRadio={props.hideRadio}
        >
          {props.children}
        </StyledSelectButton>
      </TestWrapper>
      {props.withCheckMark && (
        <TestWrapper testId={COMMITMENTS.CHECKMARK}>
          <CheckMarkWrapper>
            {props.active && (
              <CheckMark
                variant="solid"
                color={props.isLite ? theme.colors.blue400 : theme.colors.white}
                size={16}
              />
            )}
          </CheckMarkWrapper>
        </TestWrapper>
      )}
    </SelectButtonWrapper>
  </TestWrapper>
);

const CheckMarkWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 50%;
  bottom: -${rem(6)};
  height: ${rem(24)};
  width: ${rem(24)};
  transform: translateX(-50%);
  pointer-events: none;
  justify-content: center;
  z-index: 10;
`;

const SelectButtonWrapper = styled.div<{
  disabled?: boolean;
  active?: boolean;
  isLite?: boolean;
  autoWidth?: boolean;
  withBackgroundChangeOnHover?: boolean;
  hideRadio?: boolean;
}>`
  position: relative;
  margin-top: ${props => props.theme.margins.base_x3};
  background: transparent;
  ${props =>
    !props.autoWidth &&
    css`
      width: calc(50% - ${props => props.theme.margins.base_x2});
    `}

  ${props => props.theme.breakpoints.tablet} {
    margin-bottom: ${props => props.theme.margins.base};
    ${props =>
      !props.autoWidth &&
      css`
        width: calc(25% - ${props => props.theme.margins.base_x2});
      `}
  }

  ${props =>
    props.disabled &&
    css`
      margin-top: ${props => props.theme.margins.base_x5};

      ${props => props.theme.breakpoints.tablet} {
        margin-top: ${props => props.theme.margins.base_x3};
      }
    `}

  &::before {
    content: "";
    display: flex;
    position: absolute;
    left: 50%;
    bottom: -${rem(6)};
    border-radius: 50%;
    border: ${rem(1)} solid
      ${props =>
        props.disabled
          ? props.theme.colors.greyDisabled
          : props.theme.colors.blue400};
    height: ${rem(24)};
    width: ${rem(24)};
    transform: translateX(-50%);
    pointer-events: none;
    background: ${props => props.theme.colors.white};
    transition: ${transition(["background", "border", "color"])};

    ${props =>
      props.isLite &&
      css<{ active: boolean }>`
        height: ${rem(28)};
        width: ${rem(32)};
        bottom: -${rem(10)};
        transition: ${transition(["background", "color"])};

        ${props =>
          props.active &&
          css`
            border-width: ${rem(3)};
          `}
      `}
  }

  &::after {
    content: "";
    display: flex;
    position: absolute;
    left: 50%;
    bottom: -${rem(1)};
    height: ${rem(4)};
    width: ${rem(20)};
    transform: translateX(-50%);
    pointer-events: none;
    background: ${props => props.theme.colors.white};
    z-index: 9;
    border-radius: 50%;
    transition: ${transition(["background", "border", "color"])};

    ${props =>
      props.isLite &&
      css`
        clip-path: inset(50% 0 0 0);
      `}

    ${props =>
      props.isLite &&
      css<{ active: boolean }>`
        height: ${rem(28)};
        width: ${rem(28)};
        bottom: -${rem(7)};

        ${props =>
          !props.active &&
          css`
            width: ${rem(32)};
          `}
      `}
  }

  ${props =>
    props.active &&
    css<{ isLite: boolean }>`
      &::before,
      &::after {
        content: "";
        background: ${props =>
          props.isLite ? props.theme.colors.white : props.theme.colors.blue400};
      }
    `}

  ${props =>
    !props.active &&
    !props.disabled &&
    css<{ isLite: boolean }>`
      &:hover {
        &::before,
        &::after {
          content: "";
          background: ${props =>
            props.isLite
              ? props.theme.colors.white
              : props.theme.colors.blue50};
        }

        ${CheckMarkWrapper} {
          border: ${rem(1)} solid ${props.theme.colors.blue400};
          border-radius: 50%;
          bottom: -${rem(2)};
          height: ${rem(16)};
          width: ${rem(16)};
        }
      }
    `}

    ${props =>
    props.withBackgroundChangeOnHover &&
    !props.disabled &&
    css`
      &:hover {
        &::before,
        &::after {
          background: ${props => props.theme.colors.blue50};
        }
      }
    `}

    ${props =>
    props.hideRadio &&
    css`
      &,
      &:hover {
        &::before,
        &::after {
          content: none;
        }
      }
    `}
`;

const StyledSelectButton = styled(Button)<{
  active?: boolean;
  isLite?: boolean;
  verticalAlignment?: "top" | "center";
  withBackgroundChangeOnHover?: boolean;
  hideRadio?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.verticalAlignment === "top" ? "flex-start" : "center"};
  align-items: center;

  min-width: inherit;
  width: 100%;
  height: 100%;
  min-height: ${rem(64)};

  padding: ${props => props.theme.margins.base_x2}
    ${props => props.theme.margins.base} ${props => props.theme.margins.base_x2};

  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${props => props.theme.buttons.lineHeight};
  background: ${props => props.theme.colors.white};
  border: ${rem(1)} solid
    ${props =>
      props.disabled
        ? props.theme.colors.greyDisabled
        : props.theme.colors.blue400};
  color: ${props =>
    props.disabled
      ? props.theme.colors.greyDisabled
      : props.isLite
      ? props.theme.colors.black700
      : props.theme.colors.blue400};

  &:hover {
    ${props =>
      (!props.isLite || props.withBackgroundChangeOnHover) &&
      css<{ active: boolean; withBackgroundChangeOnHover: boolean }>`
        background: ${props =>
          props.active && !props.withBackgroundChangeOnHover
            ? props.theme.colors.blue400
            : props.theme.colors.blue50};
        ${!props.active ? `color: ${props.theme.colors.blue400}` : ""};
      `}

    ${props =>
      props.isLite &&
      !props.withBackgroundChangeOnHover &&
      css`
        background: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.black700};
      `}

      ${props =>
      props.withBackgroundChangeOnHover &&
      css`
        color: ${props => props.theme.colors.black700};
      `}
  }

  ${props =>
    props.active &&
    !props.isLite &&
    css`
      background: ${props => props.theme.colors.blue400};
      color: ${props => props.theme.colors.white};
    `}

  ${props =>
    props.active &&
    props.isLite &&
    css`
      color: ${props => props.theme.colors.black700};
      background: ${props => props.theme.colors.white};
      box-shadow: 0 0 0 ${rem(2)} ${props => props.theme.colors.blue400};
    `}

    ${props =>
    props.hideRadio &&
    css`
      padding: ${props =>
        `${props.theme.margins.base_x2} ${props.theme.margins.base_x3}`};
    `}

  small {
    font-size: ${props => props.theme.fonts.sizes.caption};
    font-weight: ${props => props.theme.fonts.weights.light};
    margin-top: ${props => props.theme.margins.half};
    display: block;

    ${props => props.theme.breakpoints.tablet} {
      margin-top: ${props => props.theme.margins.base};
    }
  }

  span {
    text-align: center;
    position: absolute;
    display: block;
    width: 100%;
    top: -${rem(28)};
    left: 0;
    color: ${props => props.theme.colors.grey500};
    font-weight: ${props => props.theme.fonts.weights.light};
    font-size: ${props => props.theme.fonts.sizes.caption};

    &::before,
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 15%;
      border-bottom: ${rem(1)} solid ${props => props.theme.colors.greyDark};
      top: calc(50% - ${rem(0.5)});
    }

    &::before {
      left: 0;
    }

    &::after {
      right: 0;
    }
  }

  &:disabled {
    background: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.greyDisabled};
    border-color: ${props => props.theme.colors.greyDisabled};
  }
`;

const SelectButtonsContainer = styled.div<SelectButtonsContainerProps>`
  display: flex;
  flex-flow: row wrap;
  justify-content: ${props => props.justify || "space-between"};

  ${props => props.theme.breakpoints.tablet} {
    flex-flow: row nowrap;
  }

  ${props =>
    props.withGutters &&
    css`
      ${props.theme.breakpoints.tablet} {
        margin-right: -${props => props.theme.margins.base_x2};
        margin-left: -${props => props.theme.margins.base_x2};
      }

      ${SelectButtonWrapper} {
        width: ${props.withShrinkedButtons ? `calc(25% - 1rem)` : "100%"};

        ${props.theme.breakpoints.tablet} {
          margin-right: ${props => props.theme.margins.base_x2};
          margin-left: ${props => props.theme.margins.base_x2};
        }
      }
    `}

  ${props =>
    props.disabled &&
    `
      opacity: 0.5;
      pointer-events: none;
    `}

    ${props =>
    props.useGrid &&
    css`
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: ${props =>
          `${props.theme.margins.base_x3} ${props.theme.margins.base_x2}`};
        margin: 0;
  
       ${SelectButtonWrapper} {
          width: 100%;
          margin: 0;
       }

        ${props => props.theme.breakpoints.tablet} {
          margin 0;
  
          ${SelectButtonWrapper} {
            width: ${props.withShrinkedButtons ? `calc(25% - 1rem)` : "100%"};
          }
        }
      `}
`;

const Ribbon = styled.div<RibbonProps>`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: ${rem(3)} ${rem(6)};
  border-radius: ${rem(10)};
  background: ${props => props.theme.colors.blue400};
  text-transform: uppercase;
  white-space: nowrap;
  font-size: ${props => props.theme.fonts.sizes.overline};
  color: ${props => props.theme.colors.white};

  ${props =>
    !props.isLite &&
    props.active &&
    css`
      background: ${theme.colors.white};
      color: ${theme.colors.blue400};
    `}
`;

SelectButton.displayName = "SelectButton";
SelectButtonsContainer.displayName = "SelectButtonsContainer";

export { SelectButton, SelectButtonsContainer };
