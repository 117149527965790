import styled, { css } from "styled-components";

import { CaretLeft, CaretRight } from "@ui/Assets/MyIcons";
import { OptionButton } from "@ui/Atoms/OptionButton";

import { Grid, Spacing } from "./RadioGroup.types";

export const Root = styled.div<{
  spacing?: Spacing;
  grid?: Grid;
  withPadding: boolean;
}>`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.margins[props.spacing || "none"]};

  ${props =>
    props.withPadding &&
    css`
      padding: 0 ${props.theme.margins.base_x3};
    `}

  ${props =>
    props.grid &&
    {
      line: css`
        flex-direction: row;

        > * {
          flex: 1;
        }
      `,
      one_column: css`
        flex-direction: column;

        > * {
          flex: 1;
          width: 100%;
        }
      `,
      two_columns: css`
        display: grid;
        justify-content: normal;
        grid-template-columns: auto auto;
        column-gap: ${props => props.theme.margins.base_x3};
      `,
    }[props.grid]}
`;

export const LeftSelect = styled(CaretLeft).attrs(props => ({
  height: props.theme.icons.sizes.base_x3,
  width: props.theme.icons.sizes.base_x3,
}))``;

export const RightSelect = styled(CaretRight).attrs(props => ({
  height: props.theme.icons.sizes.base_x3,
  width: props.theme.icons.sizes.base_x3,
}))``;

export const SelectValue = styled.span`
  text-align: center;
  color: ${props => props.theme.colors.black700};
  font-size: ${props => props.theme.fonts.sizes.subtitle};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${props => props.theme.fonts.lineHeights.subtitle};
  user-select: none;
  flex: 1;
`;

export const OptionButtonSelect = styled(OptionButton)`
  padding: ${props => props.theme.margins.base};
  min-height: unset;
  line-height: 0;
  border-radius: 50%;
  text-align: center;
`;
