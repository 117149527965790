/**
 * @author jakubbujakowski
 * @since 2022-7-8
 *
 * Taking lessons from `SelectButton` component, which has hundreds of permutations,
 * I've decided to make this component a multi-use-case wrapper button with minimal styling of children,
 * so that it can take in various types of content.
 * Please style the children of this component in the parent component.
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { TestWrapper } from "@ui/Atoms";
import { rem, transition } from "@ui/helpers";

type InputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange" | "checked" | "value" | "name" | "disabled" | "id" | "className"
>;

type InputType = "radio" | "checkbox";

type Props = InputProps &
  React.PropsWithChildren<{
    type?: InputType;
    testId?: string;
    noBorder?: boolean;
  }>;

const OptionButton = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <OptionButtonLabel
      className={props.className}
      checked={props.checked}
      disabled={props.disabled}
      htmlFor={props.id}
      noBorder={props.noBorder}
      data-checked={props.checked}
    >
      <HiddenRadio
        className="radio-element-connector"
        type={props.type ?? "radio"}
        id={props.id}
        name={props.name}
        value={props.value}
        checked={props.checked}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      {props.children}
    </OptionButtonLabel>
  </TestWrapper>
);

export { Props as OptionButtonProps, OptionButton };

const OptionButtonLabel = styled.label<
  Pick<Props, "checked" | "disabled" | "noBorder">
>`
  --option-button-border-color: ${props => props.theme.colors.grey200};
  --option-button-border-color--hover: ${props => props.theme.colors.blue400};
  --option-button-border-color--active: ${props => props.theme.colors.blue400};

  --option-button-font-color: ${props => props.theme.colors.grey700};
  --option-button-font-color--hover: ${props => props.theme.colors.grey700};
  --option-button-font-color--active: ${props => props.theme.colors.black700};

  display: block;
  position: relative;
  padding: ${props => props.theme.margins.base_x1_5};
  margin-bottom: 0;
  min-height: ${rem(48)};
  color: var(--option-button-font-color);
  font-weight: ${props => props.theme.fonts.weights.semibold};
  font-size: ${props => props.theme.fonts.sizes.body};
  cursor: pointer;
  border-radius: ${props => props.theme.buttons.borderRadius};
  box-shadow: inset 0 0 0 ${rem(1)} var(--option-button-border-color);
  background-color: transparent;
  transition: ${transition(["background-color", "color", "box-shadow"])};
  user-select: none;

  &:hover {
    color: var(--option-button-font-color--hover);
    box-shadow: inset 0 0 0 ${rem(1)} var(--option-button-border-color--hover);
  }

  ${props =>
    props.checked &&
    css`
      &,
      &:hover {
        color: var(--option-button-font-color--active);
        box-shadow: inset 0 0 0 ${rem(1.5)}
          var(--option-button-border-color--active);
      }
    `}

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.grey500};
      box-shadow: inset 0 0 0 ${rem(1)} ${props.theme.colors.greySurface};
      background-color: ${props.theme.colors.greySurface};
      pointer-events: none;
    `}

  ${props =>
    props.noBorder &&
    css`
      box-shadow: none;

      &,
      &:hover {
        color: var(--option-button-font-color--active);
      }
    `}
`;

const HiddenRadio = styled.input`
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;
`;
