/**
 * @author michalstolarski
 * @since 2022-10-13
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

type Variant =
  | "default"
  | "info"
  | "warning"
  | "danger"
  | "success"
  | "success-light";

type Size = "default" | "overline";

interface Props {
  testId?: string;
  className?: string;
  children?: React.ReactNode;
  variant?: Variant;
  size?: Size;
}

const InfoLabel = ({
  size = "default",
  ...props
}: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <Label className={props.className} variant={props.variant} size={size}>
      {props.children}
    </Label>
  </TestWrapper>
);

interface InfoLabelWithIconProps extends Props {
  icon?: React.ReactNode;
}

const InfoLabelWithIcon = ({
  icon,
  size = "default",
  ...props
}: InfoLabelWithIconProps): React.ReactElement => (
  <LabelWithGap {...props} size={size}>
    {!!icon && icon}
    {props.children}
  </LabelWithGap>
);

export { Variant as InfoLabelVariant, InfoLabel, InfoLabelWithIcon };

const createBgColor = (variant: Variant, color: string): string =>
  `--info-label-bg-color--${variant}: ${color}`;
const createTextColor = (variant: Variant, color: string): string =>
  `--info-label-color--${variant}: ${color}`;

const Label = styled.div<{ variant?: Variant; size: Size }>`
  /* variant -- default */
  ${props => createTextColor("default", props.theme.colors.black700)};
  ${props => createBgColor("default", props.theme.colors.grey20)};

  /* variant -- info */
  ${props => createTextColor("info", props.theme.colors.blue700)};
  ${props => createBgColor("info", props.theme.colors.blue50)};

  /* variant -- warning */
  ${props => createTextColor("warning", props.theme.colors.black700)};
  ${props => createBgColor("warning", props.theme.colors.orange50)};

  /* variant -- danger */
  ${props => createTextColor("danger", props.theme.colors.white)};
  ${props => createBgColor("danger", props.theme.colors.red700)};

  /* variant -- success */
  ${props => createTextColor("success", props.theme.colors.white)};
  ${props => createBgColor("success", props.theme.colors.green500)};

  /* variant -- success-light */
  ${props => createTextColor("success-light", props.theme.colors.green900)};
  ${props => createBgColor("success-light", props.theme.colors.green50)};

  display: flex;
  align-items: center;
  width: fit-content;
  font-weight: ${props => props.theme.fonts.weights.normal};
  border-radius: ${props => props.theme.buttons.borderRadius};

  color: var(
    --info-label-color--${props => props.variant},
    ${props => props.theme.colors.blue700}
  );
  background-color: var(
    --info-label-bg-color--${props => props.variant},
    ${props => props.theme.colors.blue50}
  );

  /* size */
  ${props => {
    return {
      ["default"]: css`
        padding: ${props => props.theme.margins.half}
          ${props => props.theme.margins.base};
        font-size: ${props => props.theme.fonts.sizes.caption};
      `,
      ["overline"]: css`
        padding: 0 ${props => props.theme.margins.half};
        font-size: ${props => props.theme.fonts.sizes.overline};
        line-height: ${props => props.theme.fonts.lineHeights.caption};
      `,
    }[props.size];
  }}
`;

const LabelWithGap = styled(Label)`
  gap: ${props => props.theme.margins.base};
`;
