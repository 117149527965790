import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";

import {
  Row,
  Container,
  Margins,
  Shadow,
  Heading,
  CheckListItem,
} from "@ui/Atoms";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useBrowserRerender } from "@hooks/useBrowserRerender";

import { GatsbyImage } from "../../components/GatsbyImage";
import { List } from "../Homepage/";

import { Description, Section, StyledColumn } from "./shared";

interface ListItem {
  list_item: { text: string };
}

export interface PayrollProps {
  payroll_title: {
    text: string;
  };
  payroll_description: {
    text: string;
  };
  payroll_list: ListItem[];
  payroll_image_right: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  payroll_image_bottom: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
  payroll_image_bottom_mobile: {
    alt: string;
    gatsbyImageData: IGatsbyImageData;
  };
}

const Payroll = (props: PayrollProps): React.ReactElement => {
  const { mobile, tablet } = useBreakpoint();
  const isBrowser = useBrowserRerender();
  const isMobile = mobile || tablet;

  const CostSliderImage = () => {
    if (isBrowser && isMobile) {
      return (
        <GatsbyImage
          image={props.payroll_image_bottom_mobile.gatsbyImageData}
          alt={props.payroll_image_bottom_mobile.alt}
        />
      );
    }

    return (
      <GatsbyImage
        image={props.payroll_image_bottom.gatsbyImageData}
        alt={props.payroll_image_bottom.alt}
      />
    );
  };

  return (
    <Section isWhite>
      <Container>
        <Row>
          <StyledColumn xs={12}>
            <GridContainer>
              <SectionText>
                <Margins xs={[null, "base_x3", null, null]}>
                  <Heading type="h2" styleAs="h4">
                    {props.payroll_title.text}
                  </Heading>
                </Margins>
                <Description>{props.payroll_description.text}</Description>
                <List isFullWidth>
                  {props.payroll_list?.map(({ list_item }) => (
                    <CheckListItem
                      key={list_item.text}
                      value={list_item.text}
                    />
                  ))}
                </List>
              </SectionText>
              <CostSlider>
                <ImgWrapper>
                  <Shadow type={"vertical"} />
                  <CostSliderImage />
                </ImgWrapper>
              </CostSlider>
              <Costs>
                <ImgWrapper>
                  <Shadow
                    type={isBrowser && isMobile ? "vertical" : "horizontal"}
                  />
                  <GatsbyImage
                    image={props.payroll_image_right.gatsbyImageData}
                    alt={props.payroll_image_right.alt}
                  />
                </ImgWrapper>
              </Costs>
            </GridContainer>
          </StyledColumn>
        </Row>
      </Container>
    </Section>
  );
};

export { Payroll };

const ImgWrapper = styled.div`
  position: relative;
`;

const GridContainer = styled.div`
  display: grid;
  grid-column-gap: ${props => props.theme.margins.base};
  grid-row-gap: ${props => props.theme.margins.base_x3};
  grid-auto-columns: 1fr;
  grid-template-areas:
    "sectionText"
    "costsSlider"
    "costs";

  ${props => props.theme.breakpoints.tablet} {
    grid-row-gap: ${props => props.theme.margins.base_base_x7};
    grid-template-areas:
      "sectionText sectionText"
      "costsSlider costs";
  }

  ${props => props.theme.breakpoints.desktop} {
    grid-row-gap: ${props => props.theme.margins.base_x8};
    grid-column-gap: ${props => props.theme.margins.base_x10};
    grid-template-areas:
      "sectionText sectionText sectionText costs costs"
      "costsSlider costsSlider costsSlider costs costs";
  }
`;

const SectionText = styled.div`
  grid-area: sectionText;

  ${Description} {
    margin-bottom: ${props => props.theme.margins.base_x3};
    text-align: left;

    ${props => props.theme.breakpoints.desktop} {
      margin-bottom: ${props => props.theme.margins.base_x6};
    }
  }
`;

const Costs = styled.div`
  grid-area: costs;
  align-self: end;
`;

const CostSlider = styled.div`
  grid-area: costsSlider;
`;
