import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const TrashDeleteBin = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5429 21.0041H8.4571C7.2809 21.0041 6.30301 20.0986 6.21279 18.9258L5.24719 6.37305H18.7528L17.7872 18.9258C17.697 20.0986 16.7191 21.0041 15.5429 21.0041V21.0041Z"
      />
      <path d="M20.0033 6.37326H3.99667" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18632 2.99609H14.8137C15.4352 2.99609 15.9391 3.49998 15.9391 4.12156V6.3725H8.06085V4.12156C8.06085 3.49998 8.56474 2.99609 9.18632 2.99609V2.99609Z"
      />
      <path d="M10.4994 12.1582L13.5006 14.8431" />
      <path d="M10.6576 15.0013L13.3424 12" />
    </svg>
  );
};
