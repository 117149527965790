/**
 * @author michalstolarski
 * @since 2022-4-4
 */

import React from "react";
import styled, { css } from "styled-components";

import { NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT } from "@config/consts";

import { rem } from "@ui/helpers";
import { OptionPanel } from "@ui/Atoms/OptionPanel";
import { Container } from "@ui/Atoms/Grid";
import { Margins } from "@ui/Atoms/Margins";

import { SupportMobileBack } from "./SupportMobileBack";

interface Props {
  className?: string;
  children: React.ReactNode;
  backButtonRoute?: string;
  isBackButtonVisible?: boolean;
  isFullHeight?: boolean;
  onBackButtonClick?: () => void;
}

const SupportMainPanel = (props: Props): React.ReactElement => (
  <Margins
    xs={["none", "none", null, null]}
    md={["base_x10", "base", null, null]}
  >
    <CustomPanel
      className={props.className}
      isFullHeight={props.isFullHeight}
      noMarginsMobile
    >
      {props.isBackButtonVisible && props.backButtonRoute && (
        <SupportMobileBack
          path={props.backButtonRoute}
          onClick={props.onBackButtonClick}
        />
      )}

      <Container widthVariant="small">{props.children}</Container>
    </CustomPanel>
  </Margins>
);

export { SupportMainPanel };

const CustomPanel = styled(OptionPanel)<{ isFullHeight?: boolean }>`
  padding-bottom: ${props => props.theme.margins.base_x10};

  ${props =>
    props.isFullHeight &&
    css`
      min-height: calc(100vh - ${rem(NAVIGATION_HEADER_SPACE_KEEPER_HEIGHT)});
    `}
`;
