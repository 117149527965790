import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const NotebookCheckList = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M13 16H17" />
      <path d="M7 15.726L7.919 16.554L9.77 14.888" />
      <path d="M13 11H17" />
      <path d="M7 10.726L7.919 11.554L9.769 9.888" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4H5C3.895 4 3 4.895 3 6V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V6C21 4.895 20.105 4 19 4Z"
      />
      <path d="M16 3V6" />
      <path d="M12 3V6" />
      <path d="M8 3V6" />
    </svg>
  );
};
