import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { LocalisationState } from "./store";

export const useLocalisationDispatch = () =>
  useDispatch<ThunkDispatch<LocalisationState, null, AnyAction>>();
export const useLocalisationSelector: TypedUseSelectorHook<LocalisationState> =
  useSelector;
