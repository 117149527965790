import styled from "styled-components";

export const PaymentsWrapper = styled.div`
  display: flex;

  img {
    margin-right: ${({ theme }) => theme.margins.base};
  }

  svg {
    min-width: ${({ theme }) => theme.fonts.sizes.h5};
    height: ${({ theme }) => theme.fonts.sizes.body2};
    margin-left: ${({ theme }) => theme.margins.base};
  }
`;
