import styled, { css } from "styled-components";

import { Heading, Section } from "@ui/Atoms";
import { rem } from "@ui/helpers";

export const ListWrapper = styled.div`
  display: flex;
`;

export const List = styled.ul<{ isFullWidth?: boolean }>`
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  margin: ${props => (props.isFullWidth ? `0` : `0 auto 0 0`)};
  width: ${props =>
    props.isFullWidth ? "100%" : `calc(100% - ${props.theme.margins.base_x5})`};
`;

export const StyledSection = styled(Section)<{
  isWhite?: boolean;
  isBlack?: boolean;
  isIframe?: boolean;
  hasGradient?: boolean;
  hasOverflow?: boolean;
  hasLargePadding?: boolean;
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  padding: ${props => props.theme.margins.base_x4}
    ${props => props.theme.margins.base_x2}
    ${props => props.theme.margins.base_x4};
  overflow: ${props => (props.hasOverflow ? "visible" : "hidden")};
  background: ${props =>
    props.backgroundColor || props.theme.colors.greySurface};

  ${props => props.theme.breakpoints.desktop} {
    padding: ${props => props.theme.margins.base_x9}
      ${props => props.theme.margins.base_x2}
      ${props => props.theme.margins.base_x9};

    > div {
      margin: 0 ${rem(144)};
    }
  }

  ${props =>
    props.hasLargePadding &&
    css`
      ${props.theme.breakpoints.desktop} {
        padding: ${rem(128)} ${props.theme.margins.base_x2};
      }
    `}

  ${props =>
    props.isWhite &&
    css`
      background: white;
    `}

  ${props =>
    props.hasGradient &&
    css`
      background: ${props.theme.gradients.lightblue_white};
    `}
`;

export const SectionHeading = styled(Heading)`
  text-align: left;

  ${props => props.theme.breakpoints.desktop} {
    text-align: center;
  }
`;
