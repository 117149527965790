import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CalendarScheduleDate = (
  props: MyIconProps,
): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M16.0017 12.301H14.0525" />
      <path d="M16.0017 10.3516V12.3012" />
      <path d="M7.99832 12.301C8.77091 10.8821 10.2573 9.99902 11.8729 9.99902C13.4885 9.99902 14.9749 10.8821 15.7475 12.301" />
      <path d="M7.49812 16.0022H8.49854" />
      <path d="M11.4998 16.0022H12.5002" />
      <path d="M11.4998 16.0022H12.5002" />
      <path d="M15.5014 16.0022H16.5019" />
      <path d="M7.49812 2.99609V5.99734" />
      <path d="M16.5019 2.99609V5.99734" />
      <rect x="2.99625" y="4.49707" width="18.0075" height="16.5069" rx="3" />
    </svg>
  );
};
