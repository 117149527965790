import React from "react";
import Cookies from "js-cookie";

import { ROUTES } from "@config/app.routing";
import {
  ADMIN_DASHBOARD_LINK,
  BATSOFT_LINK,
  BATWORK_LINK,
  BECOME_A_BATMAID_LINK,
  GIFT_CARDS_LINK,
  HELP_LINK_BATMAID,
} from "@config/links";
import { NAVBAR } from "@config/testIds/navbar";

import { AVAILABLE_LANGS } from "@typings/globals";

import { Bell } from "@ui/Assets/Symbolicons/Bell";
import { EyeOff } from "@ui/Assets/Symbolicons/EyeOff";
import { PowerOff } from "@ui/Assets/Symbolicons/PowerOff";
import { User } from "@ui/Assets/Symbolicons/User";
import { UserCircle } from "@ui/Assets/Symbolicons/UserCircle";
import { UserOff } from "@ui/Assets/Symbolicons/UserOff";
import { NavigationConfig } from "@ui/Organisms/UserDropdown";
import theme from "@ui/themes/default";

const createHref = (language: AVAILABLE_LANGS, link: string): string =>
  `/${language}${link}`;

const eventHandler =
  (eventFn: () => void, redirect?: boolean) =>
  async (e: React.MouseEvent<HTMLElement, MouseEvent>): Promise<void> => {
    e.preventDefault();

    await eventFn();

    if (redirect) {
      window.location.href = (e.target as HTMLElement).closest("a")!.href;
    }
  };

interface GetUserNavigationInput {
  logoutUrl: string;
  exitUserUrl: string;
  isUserAdmin: boolean;
  isUserClient: boolean;
  isAdminImpersonator: boolean;
  hasBookingsToValidate: boolean;
  isMobile: boolean;
  showBatgroupApps: boolean;
  language: AVAILABLE_LANGS;
  isTeaserVersion?: boolean;
  isGiftCardEnabled?: boolean;
  logoutAction: () => void;
  loginAction: () => void;
  translate: (k: string) => string;
  toggleEnvironment: () => void;
}

const getUserNavigationConfig = ({
  logoutUrl,
  exitUserUrl,
  isUserAdmin,
  isUserClient,
  isAdminImpersonator,
  isMobile,
  showBatgroupApps,
  language,
  hasBookingsToValidate,
  isTeaserVersion,
  isGiftCardEnabled,
  logoutAction,
  loginAction,
  translate,
  toggleEnvironment,
}: GetUserNavigationInput): NavigationConfig => {
  const environmentSwitchLabel = Cookies.get("hidden")
    ? translate("batmaid_pwa_navigation.switch_to_live")
    : translate("batmaid_pwa_navigation.switch_to_hidden");

  const iconConfig = {
    size: 24,
    height: 24,
    color: isMobile ? theme.colors.white : theme.colors.grey700,
  };

  return [
    {
      items: [
        {
          label: translate("batmaid_pwa_navigation.admin"),
          icon: <UserCircle {...iconConfig} />,
          href: ADMIN_DASHBOARD_LINK,
          isHidden: !isUserAdmin,
          skipHrefPrefix: true,
        },
      ],
    },
    ...(isUserAdmin && !isAdminImpersonator
      ? []
      : [
          {
            items: [
              {
                label: translate("batmaid_pwa_navigation.subnav_my_cleaning"),
                href: ROUTES.CLIENT_DASHBOARD_MY_CLEANINGS,
                testId: NAVBAR.USER_DROPDOWN_MY_CLEANINGS,
                isHidden: !isUserClient,
              },
              {
                label: translate(
                  "batmaid_pwa_navigation.subnav_bookings_to_validate",
                ),
                href: ROUTES.CLIENT_DASHBOARD_BOOKINGS_TO_VALIDATE,
                testId: NAVBAR.USER_DROPDOWN_BOOKINGS_TO_VALIDATE,
                isHidden: !hasBookingsToValidate,
              },
              {
                label: translate("batmaid_pwa_navigation.subnav_profile"),
                href: ROUTES.CLIENT_DASHBOARD_PROFILE,
                testId: NAVBAR.USER_DROPDOWN_PROFILE,
                isHidden: !isUserClient,
              },
              {
                label: translate("batmaid_pwa_navigation.subnav_options"),
                href: ROUTES.CLIENT_DASHBOARD_OPTIONS,
                testId: NAVBAR.USER_DROPDOWN_OPTIONS,
                isHidden: !isUserClient,
              },
              ...(isMobile
                ? [
                    {
                      label: translate(
                        "batmaid_pwa_navigation.become_a_batmaid",
                      ),
                      href: createHref(language, BECOME_A_BATMAID_LINK),
                      isHidden: isUserClient,
                      skipHrefPrefix: true,
                      testId: NAVBAR.BECOME_BATMAID_MOBILE,
                      telemetryEventId: "become-batmaid-header",
                    },
                    ...(!isTeaserVersion
                      ? [
                          {
                            label: translate("batmaid_pwa_navigation.help"),
                            href: createHref(language, HELP_LINK_BATMAID),
                            skipHrefPrefix: true,
                            telemetryEventId: "help-header",
                          },
                        ]
                      : []),
                  ]
                : []),
            ],
          },
        ]),
    ...(isMobile && !isTeaserVersion && isGiftCardEnabled
      ? [
          {
            items: [
              {
                label: translate("batmaid_pwa_navigation.gift_cards"),
                href: createHref(language, GIFT_CARDS_LINK),
                isHidden: !isMobile,
                isSpecial: true,
                testId: NAVBAR.USER_MOBILE_DROPDOWN_GIFT_CARDS,
                skipHrefPrefix: true,
                telemetryEventId: "gift-card-header",
              },
            ],
          },
        ]
      : []),
    ...(isUserClient && showBatgroupApps
      ? [
          {
            heading: translate("batmaid_pwa_navigation.batgroup_apps"),
            headingIcon: (
              <Bell
                size={24}
                color={isMobile ? theme.colors.white : theme.colors.black800}
              />
            ),
            items: [
              {
                label: "Batsoft",
                href: BATSOFT_LINK.replace("{{lang}}", language),
                skipHrefPrefix: true,
                testId: NAVBAR.USER_DROPDOWN_BATSOFT_LINK,
              },
              {
                label: "Batwork",
                href: BATWORK_LINK.replace("{{lang}}", language),
                skipHrefPrefix: true,
                testId: NAVBAR.USER_DROPDOWN_BATWORK_LINK,
              },
            ],
          },
        ]
      : []),
    ...(isUserAdmin
      ? [
          {
            items: [
              {
                label: environmentSwitchLabel,
                icon: <EyeOff {...iconConfig} variant="line" />,
                href: ADMIN_DASHBOARD_LINK,
                isHidden: !isUserAdmin,
                skipHrefPrefix: true,
                action: toggleEnvironment,
              },
            ],
          },
        ]
      : []),
    ...[
      {
        items: !isTeaserVersion
          ? [
              ...(isUserClient || isUserAdmin
                ? [
                    {
                      label: translate("batmaid_pwa_navigation.subnav_logout"),
                      icon: <PowerOff {...iconConfig} />,
                      href: logoutUrl,
                      action: logoutAction,
                      testId: NAVBAR.LOGOUT_BUTTON,
                      telemetryEventId: "logout-header",
                    },
                  ]
                : [
                    {
                      label: translate("batmaid_pwa_navigation.login"),
                      icon: <User {...iconConfig} />,
                      action: loginAction,
                      testId: NAVBAR.LOGIN_HEADER_BUTTON,
                      telemetryEventId: "login-header",
                    },
                  ]),
            ]
          : [],
      },
    ],
    ...(isAdminImpersonator
      ? [
          {
            items: [
              {
                label: translate("batmaid_pwa_navigation.subnav_exit_user"),
                icon: <UserOff {...iconConfig} />,
                href: exitUserUrl,
                skipHrefPrefix: true,
              },
            ],
          },
        ]
      : []),
  ];
};

export { createHref, eventHandler, getUserNavigationConfig };
