import styled, { css } from "styled-components";

import { rem } from "@ui/helpers";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.margins.base_x3};
`;

const Header = styled.div`
  min-height: ${props => props.theme.margins.base_x3};
  padding-top: ${props => props.theme.margins.base_x2};
  display: flex;
  align-items: flex-end;
  gap: ${props => props.theme.margins.base_x1_5};
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.margins.base};
`;

const Heading = styled.h2`
  margin: 0;
  font-size: ${props => props.theme.fonts.sizes.h5};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${rem(32)};
`;

const Description = styled.p`
  margin: 0;
`;

const BackButton = styled.button<{ hideText?: boolean }>`
  --subpage-layout-back-button-font-size: ${props =>
    props.theme.fonts.sizes.body};

  all: unset;
  font-size: 0;
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${props => props.theme.fonts.lineHeights.normal};
  display: flex;
  align-items: center;
  cursor: pointer;

  ${props => props.theme.breakpoints.desktop} {
    ${props =>
      typeof props.hideText !== "boolean" &&
      css`
        font-size: var(--subpage-layout-back-button-font-size);
      `}
  }

  ${props =>
    props.hideText === false &&
    css`
      font-size: 0;
    `}

  ${props =>
    props.hideText === true &&
    css`
      font-size: var(--subpage-layout-back-button-font-size);
    `}
`;

export { Layout, Header, HeadingWrapper, Heading, Description, BackButton };
