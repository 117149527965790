import React from "react";
import styled from "styled-components";

import { Checkbox } from "@ui/Atoms/Form";
import { Margins } from "@ui/Atoms/Margins";
import { Counter } from "@ui/Molecules/Counter";

import { Markdown } from "@containers/Markdown";

interface Props {
  counterName: string;
  counterValue: number;
  checkboxName?: string;
  checkboxValue?: boolean;
  maxValue: number;
  translations: {
    headingText: string;
    counterLabelText: string;
    checkboxText?: string;
  };
  isCheckboxEnabled?: boolean;
  setFieldValue: (field: string, value: any) => void;
}

const SupportItemsCounter = React.memo((props: Props): React.ReactElement => {
  const isCheckboxEnabled =
    props.isCheckboxEnabled &&
    !!props.checkboxName &&
    !!props.translations.checkboxText;

  return (
    <React.Fragment>
      <CounterContainer>
        <CounterContent>
          <Markdown>{props.translations.headingText}</Markdown>
        </CounterContent>

        <Counter
          autoWidth
          inputCustomWidth="wide"
          step={1}
          minValue={1}
          maxValue={props.maxValue}
          value={props.counterValue}
          displayValue={props.translations.counterLabelText}
          onChange={(value: number) =>
            props.setFieldValue(props.counterName, value)
          }
          isInputDisabled
        />
      </CounterContainer>

      {isCheckboxEnabled && (
        <Margins xs={["base_x4", null, null, null]}>
          <NotSureBox>
            <Checkbox
              label={props.translations.checkboxText}
              name={props.checkboxName}
              checked={props.checkboxValue}
              onChange={() => {
                props.setFieldValue(
                  props.checkboxName as string,
                  !props.checkboxValue,
                );
              }}
              inline
            />
          </NotSureBox>
        </Margins>
      )}
    </React.Fragment>
  );
});

SupportItemsCounter.displayName = "SupportItemsCounter";

export { SupportItemsCounter };

const CounterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.mobile} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const CounterContent = styled.div`
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  text-align: center;
  flex: 0 0 100%;

  ${props => props.theme.breakpoints.mobile} {
    text-align: left;
    flex: 1 1 100%;
  }

  br {
    ${props => props.theme.breakpoints.upToDesktop} {
      display: none;
    }
  }
`;

const NotSureBox = styled.div`
  display: flex;
  justify-content: center;

  ${props => props.theme.breakpoints.mobile} {
    justify-content: flex-end;
  }

  label {
    font-size: ${props => props.theme.fonts.sizes.body};
  }
`;
