/**
 * @author jakubbujakowski
 * @since 2020-3-25
 */

import * as React from "react";
import styled from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  label: string;
  extraSpacing?: boolean;
  children?: React.ReactNode;
  theme?: ITheme;
  strikethrough?: boolean;
  underline?: boolean;
  hasChanges?: boolean;
}

const LabelledData = (props: Props): React.ReactElement => (
  <LabelledDataWrapper
    className="labelled-data-wrapper"
    extraSpacing={props.extraSpacing}
    underline={props.underline}
  >
    <Label>{props.label}</Label>
    <Content strikethrough={props.strikethrough} hasChanges={props.hasChanges}>
      {props.children}
    </Content>
  </LabelledDataWrapper>
);

const Label = styled.span`
  color: ${props => props.theme.colors.grey500};
  font-size: ${props => props.theme.fonts.sizes.caption};
  text-transform: uppercase;
`;

const Content = styled.span<{
  strikethrough?: boolean;
  hasChanges?: boolean;
}>`
  font-weight: ${props => props.theme.fonts.weights.normal};
  text-decoration: ${props => (props.strikethrough ? "line-through" : "none")};
  color: ${props =>
    props.hasChanges
      ? props.theme.colors.orange700
      : props.theme.colors.black700};
`;

const LabelledDataWrapper = styled.div<{
  extraSpacing?: boolean;
  underline?: boolean;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props =>
    props.extraSpacing
      ? props.theme.margins.base_x5
      : props.theme.margins.base_x3};

  ${props =>
    props.underline &&
    `
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -${props.theme.margins.base};
      background: ${props.theme.colors.black};
    }`}
`;

LabelledData.displayName = "LabelledData";

export { LabelledData, Content };
