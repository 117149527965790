import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const PhoneLegacy = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <path
      fill="none"
      stroke={props.color || theme.colors.grey700}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M22.41 17l-2.91-2.92a2 2 0 00-2.86 0l-2.41 2.42a12.55 12.55 0 01-6.72-6.73l2.41-2.41a2 2 0 000-2.86L7 1.59a2 2 0 00-2.85 0L2.77 3a6.08 6.08 0 00-1.65 5.47 18.28 18.28 0 0014.41 14.41A6.08 6.08 0 0021 21.23l1.39-1.39a2 2 0 00.02-2.84z"
    ></path>
  </svg>
);
