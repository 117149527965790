/**
 * @author michalstolarski
 * @since 2023-11-17
 */

import * as React from "react";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

import * as Styled from "./ChecklistAd.styled";

type Variant = "sidebar" | "section";

const iconSizes: Record<Variant, number> = {
  sidebar: 102,
  section: 80,
};

interface Props {
  testId?: string;
  className?: string;
  variant: Variant;
  translations: {
    heading: string;
    description: string;
  };
}

const ChecklistAd = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <Styled.Container className={props.className}>
      <Styled.Wrapper variant={props.variant}>
        <Styled.Heading>
          <strong>{props.translations.heading}</strong>
        </Styled.Heading>
        <Styled.Description>
          {props.translations.description}
        </Styled.Description>
      </Styled.Wrapper>

      <Styled.Icon variant={props.variant}>
        <Styled.StyledTaskListStar width={iconSizes[props.variant]} />
      </Styled.Icon>
    </Styled.Container>
  </TestWrapper>
);

export { Props as ChecklistAdProps, ChecklistAd };
