import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import { Section, List, OptionPanel, Heading, Paragraph } from "@ui/Atoms";

import { langFromTag } from "@services/LangFromTag/LangFromTag";

import { useTranslate } from "@hooks/useTranslate";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Breadcrumbs } from "../components/Breadcrumbs";

interface HelpArticle {
  id: string;
  data: {
    title: { text: string };
    sort_order: number;
    slug: { text: string };
  };
}

type Props = PageProps<
  {
    prismicHelpCategory: {
      data: {
        title: { text: string };
        seo_title: { text: string };
        slug: { text: string };
      };
    };
    allPrismicHelpArticle: {
      nodes: HelpArticle[];
    };
  },
  {
    country: AVAILABLE_COUNTRIES;
    origin: ORIGIN;
    lang: string;
  }
>;

const HelpCategory = (props: Props): React.ReactElement => {
  const origin = props.pageContext.origin;
  const lang = langFromTag(props.pageContext.lang);
  const slug = props.data.prismicHelpCategory.data.slug.text.trim();
  const title = props.data.prismicHelpCategory.data.title.text;
  const seoTitle = props.data.prismicHelpCategory.data.seo_title.text;

  const translate = useTranslate(`${origin}_pwa_navigation`);

  const createLink = (node: HelpArticle) =>
    `/${lang}/${node.data.slug.text?.trim()}`;

  return (
    <Layout language={lang || DEFAULT_LANGUAGE} origin={origin}>
      <SEO title={seoTitle} path={props.location.pathname} />
      <Section flat>
        <Breadcrumbs
          paths={[
            {
              title: translate("help"),
              href: `/${lang}/${slug.split("/")[0]}`,
            },
          ]}
          currentPath={title}
        />
      </Section>
      <Section small>
        <OptionPanel>
          <Heading type="h1" styleAs="h4">
            {title}
          </Heading>
          <List>
            {props.data.allPrismicHelpArticle.nodes
              .sort(
                (first, second) =>
                  first.data.sort_order - second.data.sort_order,
              )
              .map(node => (
                <li key={node.id}>
                  <Paragraph type="body">
                    <Link to={createLink(node)}>{node.data.title.text}</Link>
                  </Paragraph>
                </li>
              ))}
          </List>
        </OptionPanel>
      </Section>
    </Layout>
  );
};

export default HelpCategory;

/**
 * prismicId is used in this query along with regular id
 * because prismicHelpArticle can only reference the category it is in via prismicId
 */
export const helpCategoryQuery = graphql`
  query HelpCategoryQuery($id: String, $prismicId: ID, $lang: String) {
    prismicHelpCategory(id: { eq: $id }) {
      id
      data {
        title {
          text
        }
        seo_title {
          text
        }
        slug {
          text
        }
      }
    }
    allPrismicHelpArticle(
      filter: {
        data: { category: { id: { eq: $prismicId } } }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        id
        data {
          title {
            text
          }
          slug {
            text
          }
          sort_order
        }
      }
    }
  }
`;
