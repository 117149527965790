import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const HeadphonesSupport = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M21.004 12A9.004 9.004 0 0 1 12 21.004M2.996 12A9.004 9.004 0 0 1 12 2.996" />
      <path d="M2.996 12a8.9 8.9 0 0 0 .554 3.09c.168.46.64.733 1.122.65l.986-.174a1.5 1.5 0 0 0 1.225-1.69l-.297-2.079a1.5 1.5 0 0 0-1.746-1.266l-1.761.311M21.004 12A9.004 9.004 0 0 0 12 2.996" />
      <path d="M21.004 12c0 1.055-.188 2.1-.554 3.09a1.01 1.01 0 0 1-1.122.65l-.986-.174a1.5 1.5 0 0 1-1.225-1.69l.297-2.079a1.5 1.5 0 0 1 1.746-1.266l1.761.311M13.493 12.491a.007.007 0 0 0-.007.005.007.007 0 0 0 .002.008.008.008 0 0 0 .008.002.008.008 0 0 0 .005-.007l-.002-.006a.007.007 0 0 0-.006-.002M10.507 12.494a.008.008 0 0 0-.007.005.008.008 0 0 0 .002.008.008.008 0 0 0 .008.002.008.008 0 0 0 .004-.007c0-.002 0-.004-.002-.006a.007.007 0 0 0-.005-.002" />
    </svg>
  );
};
