import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CalendarCrossDelete = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M7.5 3V6" />
      <path d="M16.5 3V6" />
      <rect x="3" y="4.5" width="18" height="16.5" rx="3" />
      <path d="M10.5 15L13.5 12" />
      <path d="M13.5 15L10.5 12" />
    </svg>
  );
};
