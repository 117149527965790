import { IS_BROWSER, LANGS_BY_COUNTRY } from "@config/consts";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS } from "@typings/globals";

/**
 * process.env variables cannot be accessed dynamically.
 * Doing process.env[SOME_VAR] will return undefined,
 * because of that we're using this big switch statement.
 */
export const getDomain = (country: AVAILABLE_COUNTRIES): string | undefined => {
  switch (country) {
    case AVAILABLE_COUNTRIES.CH:
      return process.env.DOMAIN_CH;
    case AVAILABLE_COUNTRIES.LU:
      return process.env.DOMAIN_LU;
    case AVAILABLE_COUNTRIES.FR:
      return process.env.DOMAIN_FR;
    case AVAILABLE_COUNTRIES.DE:
      return process.env.DOMAIN_DE;
    case AVAILABLE_COUNTRIES.IT:
      return process.env.DOMAIN_IT;
    case AVAILABLE_COUNTRIES.PL:
      return process.env.DOMAIN_PL;
    case AVAILABLE_COUNTRIES.AT:
      return process.env.DOMAIN_AT;
    case AVAILABLE_COUNTRIES.BE:
      return process.env.DOMAIN_BE;
    case AVAILABLE_COUNTRIES.NL:
      return process.env.DOMAIN_NL;
    case AVAILABLE_COUNTRIES.UK:
      return process.env.DOMAIN_UK;
    case AVAILABLE_COUNTRIES.US:
      return process.env.DOMAIN_US;
    default:
      return process.env.DOMAIN_CH;
  }
};

export const setCountryInUrl = (
  country: AVAILABLE_COUNTRIES,
  language: AVAILABLE_LANGS,
  isMobile: boolean,
  onCountryChange?: (country: AVAILABLE_COUNTRIES) => void,
  mobileRedirectRoute?: string,
): void => {
  if (IS_BROWSER) {
    const resolvedLanguage = LANGS_BY_COUNTRY[country].includes(language)
      ? language
      : AVAILABLE_LANGS.EN;

    const COUNTRY_AS_KEY = country.toUpperCase() as keyof typeof AVAILABLE_COUNTRIES;

    if (country === AVAILABLE_COUNTRIES[COUNTRY_AS_KEY] && getDomain(country)) {
      onCountryChange && onCountryChange(country);

      if (isMobile && mobileRedirectRoute) {
        window.location.href = `https://${getDomain(
          country,
        )}/${resolvedLanguage}/client#${mobileRedirectRoute}`;

        return;
      }

      window.location.href = `https://${getDomain(
        country,
      )}/${resolvedLanguage}/`;
    }
  }
};
