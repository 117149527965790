import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const StopwatchLanding = (props: Icon): React.ReactElement => (
  <svg
    viewBox="0 0 88 104"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <title>{"A4AA5569-1A0E-4C41-A195-35A6C7BAACCD@1x"}</title>
    <defs>
      <path id="stopwatchlanding_a" d="M.578.373H43.83v101.516H.578z" />
      <path id="stopwatchlanding_c" d="M0 .401h44.238V102H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(43.494 .627)">
        <mask id="stopwatchlanding_b" fill="#fff">
          <use xlinkHref="#stopwatchlanding_a" />
        </mask>
        <path
          d="M.675.457C3.767.43 6.86.325 9.95.398c2.763.065 3.887 1.205 4.06 3.781.248 3.705-.748 5.36-3.534 5.87-1.469.268-3.243.766-4.37 1.8-.363.333-.625.814-.57 1.302.032.298.177.572.344.822 1.634 2.46 4.96 3.16 7.585 4.043 3.09 1.04 6.24 3.08 9.606 2.977 3.119-.095 5.518-2.22 7.143-4.68 1.51-2.283 2.315-2.412 4.422-.652a92.322 92.322 0 013.294 2.874c1.189 1.09 1.178 2.198-.026 3.303-.715.657-1.433 1.321-2.07 2.05-1.98 2.268-2.529 4.857-1.344 7.631.722 1.692 1.57 3.372 2.619 4.882 16.23 23.38 1.716 59.368-29.089 64.574-2.268.383-4.585.914-7.32.914 0-.857-.079-3.168-.066-4.39.004-.444-.021-5.24.054-5.248.483-.04.969-.088 1.453-.122 20.744-1.432 35.09-19.67 31.221-39.8-3.21-16.71-16.77-27.427-32.785-27.005 0-1.276.125-1.485.122-2.469C.678 15.39.68 7.923.675.457"
          fill="#504F4F"
          mask="url(#stopwatchlanding_b)"
        />
      </g>
      <g transform="translate(0 .627)">
        <mask id="stopwatchlanding_d" fill="#fff">
          <use xlinkHref="#stopwatchlanding_c" />
        </mask>
        <path
          d="M41.784 25.43c-1.287.166-2.582.293-3.861.504-22.394 3.68-34.868 28.286-24.22 48.09 6.447 11.988 16.938 17.752 30.521 18.283-.054-.003.014 8.754.014 9.637 0 .311-7.027-.774-7.612-.889-3.043-.602-5.96-1.469-8.898-2.437a44.355 44.355 0 01-10.364-5.916C11.002 87.834 6 81.276 3.058 73.87c-.18-.45-.35-.904-.514-1.36C.44 66.648-.7 59.764.468 53.587c.434-2.292.98-4.613 1.677-6.84 2.317-7.419 6.475-14.229 12.286-19.482a43.79 43.79 0 019.572-6.548c4.287-2.172 10.38-2.103 13.381-6.338.333-.468.611-.999.634-1.57.074-1.944-3.013-2.473-4.43-2.679-2.935-.428-4.247-2.558-3.904-5.864.292-2.822 1.545-3.865 4.786-3.865 3.247.002 6.493.071 9.74.111.011 0-.004 3.564-.006 3.89-.004 1.301-.011 2.604-.018 3.907-.006 1.303-.012 2.605-.016 3.908a838.781 838.781 0 00-.002 3.908 419.446 419.446 0 00.052 5.861c.018 1.08-.02 2.157-.054 3.237"
          fill="#0A0A0C"
          mask="url(#stopwatchlanding_d)"
        />
      </g>
      <path
        d="M48.525 41.882a28.644 28.644 0 00-.002 2.245 35.235 35.235 0 001.5 8.702c.872 2.848 2.401 5.547 1.684 8.62-1.214 5.203-7.672 7.013-12.16 4.802-1.68-.828-3.126-2.227-3.724-3.987-1.185-3.492 1.182-6.636 1.974-9.91.625-2.592.796-5.282.85-7.937.054-2.666-.445-5.439.329-8.044.23-.778.586-1.536 1.15-2.124 1.754-1.823 5.603-1.36 7.324.294 1.163 1.12 1.265 2.83 1.214 4.34-.036 1.004-.102 2-.139 3"
        fill="#27A7D2"
      />
    </g>
  </svg>
);
