// BOOKING
export const BOOKING_AVAILABILITY_ENDPOINT =
  "/client/batwork/booking/availability";
export const BOOKING_RESERVATION_ENDPOINT =
  "/client/batwork/booking/reservation";
export const BOOKING_RESERVATION_DATA_ENDPOINT =
  "/client/batwork/booking/reservation/{reservationUuid}";
export const BOOKING_RESERVATION_COMPLETION_ENDPOINT =
  "/client/batwork/booking/reservation/{reservationUuid}/completion";
export const BOOKING_RESERVATION_COMPLETION_NOTIFICATION_ENDPOINT =
  "/client/batwork/booking/reservation/{reservationUuid}/completion/notification";
export const BOOKING_RESERVATION_CHECKOUT_ENDPOINT =
  "/client/batwork/booking/reservation/{reservationUuid}/checkout";
export const BOOKING_RESERVATION_BATSOFT_TRANSFER_ENDPOINT =
  "/client/batwork/reservation/{reservationUuid}/batsoft-transfer";
export const BOOKING_RESERVATION_BATSOFT_TRANSFER_NOTIFICATION_ENDPOINT =
  "/client/batwork/booking/reservation/{reservationUuid}/batsoft-transfer/notification";
export const BOOKING_AGENTS_METADATA_ENDPOINT =
  "/client/batwork/booking/agents-metadata";
export const BOOKING_SALARY_PROPOSAL_ENDPOINT =
  "/client/batwork/booking/salary/proposal?agentUuid={agentUuidValue}";
