export enum PROFILE_BASIC_INFO {
  FIRST_NAME = "clientProfileFirstName",
  LAST_NAME = "clientProfileLastName",
  EMAIL = "clientProfileEmail",
  BIRTH_DATE_PICKER = "clientProfileBirthDatePicker",
  HOME_PHONE = "clientProfileHomePhone",
  MOBILE_PHONE = "clientProfileMobilePhone",
  SERVICE_VOUCHER = "clientProfileServiceVoucher",
}

export enum ACCOUNT_BALANCE {
  ACCOUNT_BALANCE_WRAPPER = "clientProfileAccountBalanceWrapper",
  ACCOUNT_BALANCE_HEADING = "clientProfileAccountBalanceHeading",
  ACCOUNT_BALANCE_AMOUNT = "clientProfileAccountBalanceAmount",
  ACCOUNT_BALANCE_CURRENCY = "clientProfileAccountBalanceCurr",
  DURATION_BALANCE_WRAPPER = "clientProfileAccountDurationBalanceWrapper",
  DURATION_BALANCE_HEADING = "clientProfileAccountDurationBalanceHeading",
  DURATION_BALANCE_LABEL = "clientProfileAccountDurationBalanceLabel",
  DURATION_BALANCE_SUB_LABEL = "clientProfileAccountDurationBalanceSubLabel",
  DURATION_BALANCE_AMOUNT = "clientProfileAccountDurationBalanceAmount",
}

export enum PROFILE_BUTTONS {
  // general
  EDIT_INFO = "clientProfileEditInfoBtn",
  SAVE = "clientProfileSaveBtn",
  CANCEL = "clientProfileCancelBtn",
  CHANGE_PASSWORD = "clientProfileChangePasswordBtn",
  CONTACT_US = "clientProfileContactUsBtn",
  BOOK_A_NEW_CLEANING = "clientProfileBookCleaningBtn",

  // payment methods
  ADD_NEW_CREDIT_CARD = "clientProfileAddCreditCardBtn",
  CREDIT_CARD_EDIT = "clientProfileEditCreditCardBtn",
  CREDIT_CARD_DELETE = "clientProfileCreditCardDelete",
  CREDIT_CARD_SET_DEFAULT = "clientProfileCreditCardSetDefault",
}

export enum PAYMENT_METHODS {
  // labels
  DEFAULT_PAYMENT_METHOD = "clientProfileDefaultPaymentMethodLabel",
  OTHER_PAYMENT_METHODS = "clientProfileOtherPaymentMethodsLabel",
  NO_PAYMENT_METHODS = "clientProfileNoPaymentMethodsLabel",
  CARD_NUMBER = "clientProfileCardNumber",
  EXPIRY_DATE = "clientProfileExpiryDate",

  // elements
  DEFAULT_PAYMENT_LIST = "clientProfileDefaultPaymentMethodList",
  OTHER_PAYMENT_LIST = "clientProfileOtherPaymentMethodList",
}

export enum LOCATIONS {
  LOCATIONS_LIST = "clientProfileLocationsList",
  ADDRESS = "clientProfileLocationAddress",
  ZIP_CODE = "clientProfileLocationZipCode",
  CITY = "clientProfileLocationCity",
  COUNTRY = "clientProfileLocationCountry",
  DEFAULT = "clientProfileDefaultAddress",
}

export enum UPCOMING_CLEANING {
  UPCOMING_CLEANING_WRAPPER = "clientProfileUpcomingCleaningWrapper",
  NO_UPCOMING_CLEANINGS = "clientProfileNoUpcomingCleanings",
  CLEANING_TYPE = "clientProfileUpcomingCleaningType",
  BEDROOMS = "clientProfileUpcomingCleaningBedrooms",
  BATHROOMS = "clientProfileUpcomingCleaningBathrooms",
  DURATION = "clientProfileUpcomingCleaningDuration",
  FREQUENCY = "clientProfileUpcomingCleaningFrequency",
  EOT_WARRANTY = "clientProfileUpcomingCleaningWarranty",
  DATE = "clientProfileUpcomingCleaningExecutionDate",
}

export enum UPCOMING_CLEANING_EXTRAS {
  INTERIOR_WINDOWS = "upcomingCleaningWindowsExtra",
  IRONING = "upcomingCleaningIroningExtra",
  LAUNDRY_WASH_AND_DRY = "upcomingCleaningLaundryWashExtra",
  INSIDE_OVEN = "upcomingCleaningOvenExtra",
  INSIDE_CABINETS = "upcomingCleaningCabinetsExtra",
  INSIDE_FRIDGE = "upcomingCleaningFridgeExtra",
  BONUS_15 = "upcomingCleaningBonus15MinutesExtra",
  DOOR_HANDLES = "upcomingCleaningDoorHandlesExtra",
  BASEBOARDS = "upcomingCleaningBaseboardsExtra",
  WINDOW_BLINDS = "upcomingCleaningWindowBlindsExtra",
  BALCONY = "upcomingCleaningBalconyExtra",
}
