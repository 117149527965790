/**
 * Addition of an array of rem values
 * @returns `${value}rem`
 */
export default function (input: Array<string>): string {
  const value: number = input.reduce((acc, cur) => {
    const curValue = parseFloat(cur.replace("--", "-"));

    if (isNaN(curValue)) {
      throw new Error("Please provide correct value!");
    }

    return acc + curValue;
  }, 0);

  return `${value}rem`;
}
