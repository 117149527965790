import * as React from "react";
import styled from "styled-components";
import color from "color";

import { transition, rem } from "@ui/helpers";
import { ITheme } from "@ui/themes/ThemeInterface";

/**
 * Navlink
 */
interface NavLinkProps {
  className?: string;
  theme: ITheme;
  children: React.ReactNode;
  href?: string;
  special?: boolean;
  withIcon?: boolean;
  withBorder?: boolean;
  isThin?: boolean;
  isDark?: boolean;
}

const NavLinkComponent = (props: NavLinkProps) => (
  <li className={props.className}>{props.children}</li>
);

const NavLink = styled(NavLinkComponent)`
  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props =>
    props.isThin
      ? props.theme.fonts.weights.normal
      : props.theme.fonts.weights.bold};
  line-height: ${props => props.theme.fonts.lineHeights.normal};

  ${props => props.theme.breakpoints.desktop} {
    &::after {
      content: "";
      border-bottom: none !important; // fixes SSR bug
    }
  }

  ${props =>
    props.withIcon &&
    `
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}

  ${props =>
    props.withBorder &&
    ` 
    position: relative;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: ${rem(1)} solid ${color(
      props.isDark ? props.theme.colors.white : props.theme.colors.black,
    )
      .alpha(0.2)
      .toString()};
    }
    `}

  a {
    color: ${props =>
      props.isDark ? props.theme.colors.white : props.theme.colors.black700};
    background: transparent;

    padding: ${props => props.theme.margins.base};
    padding-right: 0;
    border-radius: ${props => props.theme.buttons.borderRadius};

    transition: ${transition(["background, color"])};

    ${props =>
      props.special &&
      `
      color: ${props.theme.colors.green500};
      font-weight: ${props.theme.fonts.weights.bold};

      &:hover {
        color: ${props.theme.colors.green500} !important;
      }
    `}

    ${props => props.theme.breakpoints.desktop} {
      padding: ${props => props.theme.margins.base};
    }
  }

  ${props => props.theme.breakpoints.desktop} {
    display: flex;
    align-items: center;
    margin-right: ${props => props.theme.margins.base_x3};
  }
`;

export { NavLink };
