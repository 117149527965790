import debounce from "lodash.debounce";
import { RichTextField, PrismicDocument } from "@prismicio/types";

import { LONG_DEBOUNCE } from "@config/consts";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS, ORIGIN } from "@typings/globals";

import { GetPrismicEntry } from "@hooks/usePrismic";

export type HelpCategory = PrismicDocument<{
  country: AVAILABLE_COUNTRIES;
  origin: ORIGIN;
  icon: {
    alt: string;
    url: string;
  };
  title: RichTextField<"filled">;
  slug: RichTextField<"filled">;
  sort_order: number;
}>;

export type HelpArticle = PrismicDocument<{
  country: AVAILABLE_COUNTRIES;
  origin: ORIGIN;
  title: RichTextField<"filled">;
  slug: RichTextField<"filled">;
}>;

export type HelpCategories = HelpCategory[];

export interface SearchProps {
  searchString: string;
  setSearchResults: (input: HelpArticle[]) => void;
  setIsQueryPending: (input: boolean) => void;
  country: AVAILABLE_COUNTRIES;
  origin: ORIGIN;
  language: AVAILABLE_LANGS;
  getPrismicEntry: GetPrismicEntry;
}

export const sortByOrder = (
  first: HelpCategory,
  second: HelpCategory,
): number => first.data.sort_order - second.data.sort_order;

export const debouncedSearch = debounce(
  ({
    searchString,
    setSearchResults,
    setIsQueryPending,
    country,
    origin,
    language,
    getPrismicEntry,
  }: SearchProps) => {
    (async () => {
      const resolvedOrigin = origin || ORIGIN.BATMAID;

      if (!searchString.trim()) {
        setSearchResults([]);
        setIsQueryPending(false);
        return;
      }

      setIsQueryPending(true);
      const result = await getPrismicEntry({
        origin: resolvedOrigin,
        country: country,
        lang: language,
        contentType: "help_article",
        search: searchString,
      });

      if (result) {
        setSearchResults(result as HelpArticle[]);
      }

      setIsQueryPending(false);
    })();
  },
  LONG_DEBOUNCE,
);

interface FetchCategoriesProps {
  country: AVAILABLE_COUNTRIES;
  origin: ORIGIN;
  language: AVAILABLE_LANGS;
  custom?: {
    label: string;
    value: string;
  };
  setCategories: (input: HelpCategories) => void;
  getPrismicEntry: GetPrismicEntry;
}

export const fetchCategories = async ({
  country,
  origin,
  language,
  custom,
  setCategories,
  getPrismicEntry,
}: FetchCategoriesProps): Promise<void> => {
  const resolvedOrigin = origin || ORIGIN.BATMAID;

  const result = await getPrismicEntry({
    origin: resolvedOrigin,
    country: country,
    lang: language,
    contentType: "help_category",
    ...(custom && {
      customField: custom.label,
      customFieldValue: custom.value,
    }),
  });

  if (result) {
    setCategories(result as HelpCategories);
  }
};
