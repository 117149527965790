import { SliceComponentProps } from "@prismicio/react";
import React from "react";
import styled, { useTheme } from "styled-components";

import { Container, Heading, Margins } from "@ui/Atoms";

import { PrismicGlobalContentPageDataBodyTitleWithTitleTextBoxes } from "../../../graphql-types";
import { StyledSection } from "../Homepage/shared";

const TitleWithTitleTextBoxesSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyTitleWithTitleTextBoxes>,
): React.ReactElement => {
  const theme = useTheme();

  const { primary, items } = props.slice;

  return (
    <Section backgroundColor={theme.colors.black800}>
      <Container widthVariant="fullMobile">
        <Margins xs={[null, "base_x6", null, null]}>
          <StyledHeading type="h2" styleAs="h4" textAlignLg="center">
            {primary?.title1?.text}
          </StyledHeading>
        </Margins>

        <BoxesContainer>
          {items?.map(step => (
            <StepContent key={step?.title1?.text}>
              <StepHeading type="h6" color={theme.colors.white}>
                {step?.title1?.text}
              </StepHeading>
              <p>{step?.description?.text}</p>
            </StepContent>
          ))}
        </BoxesContainer>
      </Container>
    </Section>
  );
};

export { TitleWithTitleTextBoxesSlice };

const Section = styled(StyledSection)`
  overflow: hidden;
  padding: ${props => props.theme.margins.base_x8}
    ${props => props.theme.margins.base_x2};
`;

const StyledHeading = styled(Heading)`
  font-size: ${props => props.theme.fonts.sizes.h5};
  color: ${props => props.theme.colors.white};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.h3};
  }
`;

const StepHeading = styled(Heading)`
  font-weight: ${props => props.theme.fonts.weights.bold};
  font-size: ${props => props.theme.fonts.sizes.h4};
`;

const StepContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${props => props.theme.colors.white};
`;

const BoxesContainer = styled.div`
  display: grid;
  gap: ${props => props.theme.margins.base};
  grid-template-columns: 1fr 1fr;

  ${props => props.theme.breakpoints.tablet} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
