import React from "react";
import Cookies from "js-cookie";

import { PATHS, ROUTES } from "@config/app.routing";
import {
  ADMIN_DASHBOARD_LINK,
  AGENT_AVAILABILITIES_LINK,
  AGENT_MY_CLEANINGS_LINK,
  AGENT_NEW_CLEANINGS_LINK,
  AGENT_PROFILE_LINK,
  AGENT_SCHEDULE_LINK,
  BATSOFT_LINK,
  BATWORK_LINK,
} from "@config/links";
import { NAVBAR } from "@config/testIds/navbar";

import { TranslateFunction } from "@hooks/useTranslate";

import { AVAILABLE_LANGS } from "@typings/globals";

import {
  Bell,
  EyeOff,
  PowerOff,
  User,
  UserCircle,
  UserOff,
} from "@ui/Assets/Symbolicons";
import { NavigationConfig } from "@ui/Organisms/UserDropdown";
import theme from "@ui/themes/default";

type Target = "_blank" | "self";

export type Service =
  | "facebook"
  | "instagram"
  | "twitter"
  | "googleplus"
  | "youtube";

interface IBaseLink {
  href: string;
  target?: Target;
}

export interface ILink extends IBaseLink {
  children: string;
  options?: { [k: string]: string | boolean };
}

export interface ISocial extends IBaseLink {
  service: Service;
}

export interface IFooterService {
  children: string;
  href?: string;
  target?: Target;
}

export const FOOTER_LINKS: ILink[] = [
  { children: "About", href: "/" },
  { children: "Contact", href: "/" },
  { children: "Help", href: "/" },
  { children: "Careers", href: "/" },
  { children: "Become a pro", href: "/" },
  { children: "What is included in our cleaning", href: "/" },
];

export const FOOTER_SOCIAL_LINKS: ISocial[] = [
  {
    service: "facebook",
    href: "https://www.facebook.com/batmaid",
    target: "_blank",
  },
  {
    service: "instagram",
    href: "https://www.instagram.com/batmaid",
    target: "_blank",
  },
  {
    service: "twitter",
    href: "https://twitter.com/BatmaidCom",
    target: "_blank",
  },
  {
    service: "googleplus",
    href: "https://plus.google.com/100047952158005507015",
    target: "_blank",
  },
  {
    service: "youtube",
    href: "https://www.youtube.com/channel/UCAwAC4lRJNolu0uc17pNbwg",
    target: "_blank",
  },
];

interface GetAgentNavigationInput {
  logoutUrl: string;
  exitUserUrl: string;
  isUserAdmin: boolean;
  isAdminImpersonator: boolean;
  isUserLoggedIn: boolean;
  isMobile: boolean;
  showBatgroupApps: boolean;
  language: AVAILABLE_LANGS;
  routesFeatureFlagConfig: {
    usePwaAgentScheduleRoute: boolean;
    usePwaAgentMyCleaningsRoute: boolean;
    usePwaAgentNewCleaningsRoute: boolean;
    usePwaAgentAvailabilitiesRoute: boolean;
    usePwaAgentProfileRoute: boolean;
  };
  counters?: {
    agentNewCleanings: number | null;
    agentMyCleanings: number | null;
  };
  logoutAction: () => void;
  loginAction: () => void;
  translate: TranslateFunction;
  toggleEnvironment: () => void;
}

export const getAgentNavigationConfig = ({
  logoutUrl,
  exitUserUrl,
  isUserAdmin,
  isAdminImpersonator,
  isMobile,
  showBatgroupApps,
  language,
  routesFeatureFlagConfig,
  counters,
  loginAction,
  logoutAction,
  translate,
  toggleEnvironment,
  isUserLoggedIn,
}: GetAgentNavigationInput): NavigationConfig => {
  const environmentSwitchLabel = Cookies.get("hidden")
    ? translate("batmaid_pwa_navigation.switch_to_live")
    : translate("batmaid_pwa_navigation.switch_to_hidden");

  const createHref = (language: AVAILABLE_LANGS, link: string): string =>
    `/${language}${link}`;

  const iconConfig = {
    size: 24,
    color: isMobile ? theme.colors.white : theme.colors.grey700,
  };

  return [
    {
      items: [
        {
          label: translate("batmaid_pwa_navigation.admin"),
          icon: <UserCircle {...iconConfig} />,
          href: ADMIN_DASHBOARD_LINK,
          isHidden: !isUserAdmin,
          skipHrefPrefix: true,
        },
      ],
    },

    ...(isUserAdmin && !isAdminImpersonator
      ? []
      : [
          {
            items: [
              {
                label: translate(
                  "batmaid_pwa_navigation.subnav_agent_schedule",
                ),
                href: routesFeatureFlagConfig.usePwaAgentScheduleRoute
                  ? createHref(
                      language,
                      `${PATHS.AGENT_APP}#${ROUTES.AGENT_SCHEDULE}`,
                    )
                  : createHref(language, AGENT_SCHEDULE_LINK),
                skipHrefPrefix: true,
              },
              {
                label: translate(
                  "batmaid_pwa_navigation.subnav_agent_my_cleanings",
                ),
                href: routesFeatureFlagConfig.usePwaAgentMyCleaningsRoute
                  ? createHref(
                      language,
                      `${PATHS.AGENT_APP}#${ROUTES.AGENT_MY_CLEANINGS}`,
                    )
                  : createHref(language, AGENT_MY_CLEANINGS_LINK),
                skipHrefPrefix: true,
                counter: {
                  value: counters?.agentMyCleanings || 0,
                  color: "orange700",
                },
              },
              {
                label: translate(
                  "batmaid_pwa_navigation.subnav_agent_new_cleanings",
                ),
                href: routesFeatureFlagConfig.usePwaAgentNewCleaningsRoute
                  ? createHref(
                      language,
                      `${PATHS.AGENT_APP}#${ROUTES.AGENT_NEW_CLEANINGS}`,
                    )
                  : createHref(language, AGENT_NEW_CLEANINGS_LINK),
                skipHrefPrefix: true,
                counter: {
                  value: counters?.agentNewCleanings || 0,
                  color: "red700",
                },
              },
              {
                label: translate(
                  "batmaid_pwa_navigation.subnav_agent_availabilities",
                ),
                href: routesFeatureFlagConfig.usePwaAgentAvailabilitiesRoute
                  ? createHref(
                      language,
                      `${PATHS.AGENT_APP}#${ROUTES.AGENT_AVAILABILITIES}`,
                    )
                  : createHref(language, AGENT_AVAILABILITIES_LINK),
                skipHrefPrefix: true,
              },
              {
                label: translate("batmaid_pwa_navigation.subnav_agent_profile"),
                href: routesFeatureFlagConfig.usePwaAgentProfileRoute
                  ? createHref(
                      language,
                      `${PATHS.AGENT_APP}#${ROUTES.AGENT_PROFILE}`,
                    )
                  : createHref(language, AGENT_PROFILE_LINK),

                skipHrefPrefix: true,
              },
            ],
          },
        ]),
    ...(showBatgroupApps
      ? [
          {
            heading: translate("batmaid_pwa_navigation.batgroup_apps"),
            headingIcon: (
              <Bell
                size={24}
                color={isMobile ? theme.colors.white : theme.colors.black800}
              />
            ),
            items: [
              {
                label: "Batsoft",
                href: BATSOFT_LINK.replace("{{lang}}", language),
                skipHrefPrefix: true,
                testId: NAVBAR.USER_DROPDOWN_BATSOFT_LINK,
              },
              {
                label: "Batwork",
                href: BATWORK_LINK.replace("{{lang}}", language),
                skipHrefPrefix: true,
                testId: NAVBAR.USER_DROPDOWN_BATWORK_LINK,
              },
            ],
          },
        ]
      : []),

    ...(isUserAdmin
      ? [
          {
            items: [
              {
                label: environmentSwitchLabel,
                icon: <EyeOff {...iconConfig} variant="line" />,
                href: ADMIN_DASHBOARD_LINK,
                isHidden: !isUserAdmin,
                skipHrefPrefix: true,
                action: toggleEnvironment,
              },
            ],
          },
        ]
      : []),
    ...((isUserLoggedIn && [
      {
        items: [
          {
            label: translate("batmaid_pwa_navigation.subnav_logout"),
            icon: <PowerOff {...iconConfig} />,
            href: logoutUrl,
            action: logoutAction,
          },
        ],
      },
    ]) || [
      {
        items: [
          {
            label: translate("batmaid_pwa_navigation.login"),
            icon: <User {...iconConfig} />,
            action: loginAction,
            testId: NAVBAR.LOGIN_HEADER_BUTTON,
          },
        ],
      },
    ]),
    ...(isAdminImpersonator
      ? [
          {
            items: [
              {
                label: translate("batmaid_pwa_navigation.subnav_exit_user"),
                icon: <UserOff {...iconConfig} />,
                href: exitUserUrl,
                skipHrefPrefix: true,
              },
            ],
          },
        ]
      : []),
  ];
};
