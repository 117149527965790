import * as React from "react";
import styled from "styled-components";

import { Paragraph } from "@ui/Atoms/Paragraph";
import { rem } from "@ui/helpers";
import theme from "@ui/themes/default";

interface Props {
  text?: string;
  withFullWidth?: boolean;
  withLargeMargins?: boolean;
  className?: string;
}

const Divider = React.memo(
  (props: Props): React.ReactElement => (
    <Wrapper
      withLargeMargins={props.withLargeMargins}
      className={props.className}
    >
      {props.text ? (
        <React.Fragment>
          <DividerLine />
          <Text type="body2" color={theme.colors.grey700}>
            {props.text}
          </Text>
          <DividerLine />
        </React.Fragment>
      ) : (
        <DividerLine withFullWidth={props.withFullWidth} />
      )}
    </Wrapper>
  ),
);

Divider.displayName = "Divider";

export { Divider, DividerLine };

const Wrapper = styled.div<{ withLargeMargins?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${props =>
      props.withLargeMargins
        ? props.theme.margins.base_x3
        : props.theme.margins.base_x2}
    0;

  ${props => props.theme.breakpoints.tablet} {
    justify-content: center;
  }
`;

const DividerLine = styled.div<{ withFullWidth?: boolean }>`
  width: ${props =>
    props.withFullWidth ? "100%" : props.theme.margins.base_x3};
  height: ${rem(1)};
  background: ${props => props.theme.colors.greyDark};
`;

const Text = styled(Paragraph)`
  margin: 0 ${props => props.theme.margins.base};
`;
