import React from "react";
import styled, { css } from "styled-components";

import { Column, Margins, Row } from "@ui/Atoms";
import { MaidLanding } from "@ui/Assets/Symbolicons/MaidLanding";
import { StopwatchLanding } from "@ui/Assets/Symbolicons/StopwatchLanding";
import { SmileyLanding } from "@ui/Assets/Symbolicons/SmileyLanding";
import { CompilantLanding } from "@ui/Assets/Symbolicons/CompilantLanding";
import { rem } from "@ui/helpers";

import { SectionHeading, StyledSection } from "./shared";

interface CardProps {
  children: React.ReactNode;
}

const Card = (props: CardProps): React.ReactElement => (
  <CardWrapper>
    <CardContent>{props.children}</CardContent>
  </CardWrapper>
);

export interface WhyBatmaidProps {
  whyChooseTitle: string;
  whyChooseMaidTitle: string;
  whyChooseMaidDescription: string;
  whyChooseClockTitle: string;
  whyChooseClockDescription: string;
  whyChooseSmileyTitle: string;
  whyChooseSmileyDescription: string;
  whyChooseDocumentTitle: string;
  whyChooseDocumentDescription: string;
}

const WhyBatmaidSection = (props: WhyBatmaidProps): React.ReactElement => {
  return (
    <StyledSection>
      <Margins xs={[null, "base_x8", null, null]}>
        <SectionHeading type="h4">{props.whyChooseTitle}</SectionHeading>
      </Margins>
      <StyledRow>
        <Column xs={12} lg={3}>
          <Card>
            <CardSide>
              <MaidLanding size={120} height={120} />
              <span>{props.whyChooseMaidTitle}</span>
            </CardSide>
            <CardSide isBack>{props.whyChooseMaidDescription}</CardSide>
          </Card>
        </Column>
        <Column xs={12} lg={3}>
          <Card>
            <CardSide>
              <StopwatchLanding size={120} height={120} />
              <span>{props.whyChooseClockTitle}</span>
            </CardSide>
            <CardSide isBack>{props.whyChooseClockDescription}</CardSide>
          </Card>
        </Column>
        <Column xs={12} lg={3}>
          <Card>
            <CardSide>
              <SmileyLanding size={120} height={120} />
              <span>{props.whyChooseSmileyTitle}</span>
            </CardSide>
            <CardSide isBack>{props.whyChooseSmileyDescription}</CardSide>
          </Card>
        </Column>
        <Column xs={12} lg={3}>
          <Card>
            <CardSide>
              <CompilantLanding size={120} height={120} />
              <span>{props.whyChooseDocumentTitle}</span>
            </CardSide>
            <CardSide isBack>{props.whyChooseDocumentDescription}</CardSide>
          </Card>
        </Column>
      </StyledRow>
    </StyledSection>
  );
};

export { WhyBatmaidSection };

const CardWrapper = styled.div`
  width: 100%;
  height: ${rem(270)};
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  color: ${props => props.theme.colors.black};

  margin-bottom: ${props => props.theme.margins.base_x4};

  &:hover > div {
    transform: rotateY(180deg);
  }
`;

const StyledRow = styled(Row)`
  width: 100%;
  max-width: ${rem(960)};
`;

const CardContent = styled.div`
  position: relative;
  width: ${rem(240)};
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  margin: 0 auto;

  ${props => props.theme.breakpoints.desktop} {
    width: 100%;
    margin: initial;
  }
`;

const CardSide = styled.div<{ isBack?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: ${props => props.theme.margins.base_x4};

  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  background: ${props => props.theme.colors.white};
  text-transform: uppercase;
  font-weight: ${props => props.theme.fonts.weights.bold};

  ${props =>
    props.isBack &&
    css`
      justify-content: flex-start;
      transform: rotateY(180deg);
      text-transform: initial;
      font-weight: ${props => props.theme.fonts.weights.normal};
    `}
`;
