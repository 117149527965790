import { createSelector } from "reselect";

import { IStore } from "@soft/redux/reducers";

const getUserDataFunction = (store: IStore) => store.oauth;

export const getUserData = createSelector(
  getUserDataFunction,
  f => f,
);
