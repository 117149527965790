/**
 * @author tomekbuszewski
 * @since 2019-6-26
 */

import * as React from "react";
import color from "color";
import styled from "styled-components";

import { rem, transition } from "@ui/helpers";
import { Label, LabelWrapper } from "@ui/Atoms/Form/Label";
import { FormError } from "@ui/Atoms/Form/FormError";
import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  children?: React.ReactNode;
  theme: ITheme;
}

const PristineSelect = styled.select<Props>`
  transition: ${transition(["border", "box-shadow"])};
  width: 100%;
  height: ${props => props.theme.buttons.height};
  box-shadow: ${props =>
    `0 0 ${rem(8)} 0 ${color(props.theme.colors.blue400).alpha(0).toString()}`};

  color: ${props => props.theme.colors.black};
  background: ${props => props.theme.colors.white};
  border: ${rem(1)} solid ${props => props.theme.colors.greyDark};
  border-radius: ${props => props.theme.buttons.borderRadius};
  outline: 0;

  font-size: ${props => props.theme.fonts.sizes.body2};

  &:focus,
  &:active {
    outline: 0;

    border-color: ${props => props.theme.colors.blue400};
    box-shadow: ${props =>
      `0 0 ${rem(4)} 0 ${color(props.theme.colors.blue400)
        .alpha(0.75)
        .toString()}`};
  }
`;

interface ExtendedSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  errors?: string;
  label?: string;
}

const Select = (props: ExtendedSelectProps) => (
  <LabelWrapper>
    <Label htmlFor={props.id}>{props.label}</Label>
    <PristineSelect {...props}>{props.children}</PristineSelect>
    {props.errors && <FormError>{props.errors}</FormError>}
  </LabelWrapper>
);

export { Select, PristineSelect };

Select.displayName = "Select";
