/**
 * @author michalstolarski
 * @since 2022-4-4
 */

import React from "react";
import styled, { css } from "styled-components";

import { OptionPanel } from "@ui/Atoms/OptionPanel";
import { Column, Container, Row } from "@ui/Atoms/Grid";
import { Margins } from "@ui/Atoms/Margins";

interface Props {
  className?: string;
  children: React.ReactNode;
  center?: boolean;
}

const SupportFooter = (props: Props): React.ReactElement => {
  return (
    <Margins xs={[null, "none", null, null]} md={[null, "base_x5", null, null]}>
      <OptionPanel className={props.className} noMarginsMobile>
        <Container widthVariant="small">
          <Row>
            <Column>
              <Wrapper center={props.center}>{props.children}</Wrapper>
            </Column>
          </Row>
        </Container>
      </OptionPanel>
    </Margins>
  );
};

export { SupportFooter };

const Wrapper = styled.div<{ center?: boolean }>`
  font-weight: ${props => props.theme.fonts.weights.semibold};
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.theme.margins.base_x2};

  ${props =>
    props.center &&
    css`
      justify-content: center;
    `}

  > * {
    flex: 1 1 100%;

    ${props => props.theme.breakpoints.mobile} {
      flex: 0 0 auto;
    }
  }
`;
