/**
 * @author michalstolarski
 * @since 2022-11-16
 */

import * as React from "react";
import styled, { css } from "styled-components";

import { rem, transition } from "@ui/helpers";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { NormalizedList } from "@ui/Atoms/List";

interface CustomElementProps {
  active?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

interface RangeProps {
  range: {
    start: number;
    stop: number;
    step: number;
  };
}

interface LabelsProps {
  labels?: {
    leftText: string;
    rightText: string;
  };
}

interface Props extends RangeProps, LabelsProps {
  testId?: string;
  className?: string;
  customElement?: (props: CustomElementProps) => React.ReactElement;
  selected?: number;
  disabled?: boolean;
  onChange?: (value: number) => void;
}

const ScoreRatingRange = (props: Props): React.ReactElement => {
  // Brackets are important in this calculation
  // prettier-ignore
  const range = Array.from(
    { length: ((props.range.stop - props.range.start) / props.range.step) + 1 },
    (_, i) => (props.range.start + i) * props.range.step,
  );
  const {
    customElement = customProps => <ScoreRatingRangeButton {...customProps} />,
  } = props;

  return (
    <TestWrapper testId={props.testId}>
      <Container className={props.className}>
        <Range>
          {range.map(el => (
            <li key={el}>
              {customElement({
                children: el,
                active: el === props.selected,
                disabled: props.disabled,
                onClick: () => props.onChange?.(el),
              })}
            </li>
          ))}
        </Range>

        {!!props.labels && (
          <LabelsWrapper>
            <Label>{props.labels.leftText}</Label>
            <Label>{props.labels.rightText}</Label>
          </LabelsWrapper>
        )}
      </Container>
    </TestWrapper>
  );
};

export {
  RangeProps,
  LabelsProps,
  ScoreRatingRange,
  Label as ScoreRatingRangeLabel,
};

const Container = styled.div`
  --score-rating-range-padding: ${props => props.theme.margins.base_x3};
  --score-rating-range-element-size: ${rem(40)};

  width: fit-content;
  padding: 0 var(--score-rating-range-padding);
`;

const Range = styled(NormalizedList)`
  color: currentColor;
  display: flex;
  justify-content: center;
  gap: ${props => props.theme.margins.base_x1_5};
`;

const ScoreRatingRangeButton = styled.button<CustomElementProps>`
  width: var(--score-rating-range-element-size);
  height: var(--score-rating-range-element-size);
  font-size: ${props => props.theme.fonts.sizes.body2};
  line-height: 1;
  color: currentColor;
  border: ${rem(1)} solid ${props => props.theme.colors.blue500};
  border-radius: ${props => props.theme.border.radius.half};
  transition: ${transition(["color", "background-color"])};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blue500};
  }

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.grey600};
      border-color: ${props => props.theme.colors.grey600};
      background-color: transparent;
      pointer-events: none;
    `}

  ${props =>
    props.active &&
    !props.disabled &&
    css`
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.blue500};
    `};

  ${props =>
    props.active &&
    props.disabled &&
    css`
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.grey600};
    `};
`;

const LabelsWrapper = styled.div`
  margin: ${props => props.theme.margins.base_x1_5}
    calc(var(--score-rating-range-padding) * -1) 0;
  display: grid;
  grid-template-columns: auto auto;
  gap: ${props => props.theme.margins.base_x2};
  justify-content: space-between;
`;

const Label = styled.div`
  font-size: ${props => props.theme.fonts.sizes.overline};
  font-weight: ${props => props.theme.fonts.weights.normal};
  line-height: ${props => props.theme.fonts.lineHeights.overline};
  color: ${props => props.theme.colors.grey500};
`;
