import React from "react";

import { USER_NOTIFICATIONS_ENDPOINT } from "@config/endpoints";
import { defaultValues } from "@config/defaultValues";

import {
  UserNotifications,
  USER_NOTIFICATIONS,
} from "@typings/userNotifications";

import { ASYNC_ACTION_TYPES } from "@services/FetchFacade";

import { useAuth } from "@hooks/useAuth";
import { useLocalisation } from "@hooks/useLocalisation";

import { asyncActionCreator } from "@app/services/AsyncActionCreator";

type ContextValues = {
  userNotifications: UserNotifications;
  patchUserNotifications: (
    notifications: Partial<Record<USER_NOTIFICATIONS, boolean>>,
  ) => void;
};

const UserNotificationsContext = React.createContext<ContextValues>({
  userNotifications: defaultValues.userNotifications,
  patchUserNotifications: () => null,
});

interface Props {
  children: React.ReactNode;
}

const UserNotificationsProvider = (props: Props): React.ReactElement => {
  const { isUserClient, isUserAgent } = useAuth();
  const { country } = useLocalisation();

  const [userNotifications, setUserNotifications] =
    React.useState<UserNotifications>(defaultValues.userNotifications);

  const fetchUserNotifications = async () => {
    const { onSuccess } = await asyncActionCreator({
      action: "",
      url: USER_NOTIFICATIONS_ENDPOINT,
      method: ASYNC_ACTION_TYPES.GET,
    });

    onSuccess(resp => {
      setUserNotifications(resp.data);
    });
  };

  const patchUserNotifications = async (
    notifications: Partial<Record<USER_NOTIFICATIONS, boolean>>,
  ) => {
    const { onSuccess } = await asyncActionCreator({
      action: "",
      url: USER_NOTIFICATIONS_ENDPOINT,
      method: ASYNC_ACTION_TYPES.PATCH,
      body: notifications,
    });

    onSuccess(resp => {
      setUserNotifications(resp.data);
    });
  };

  React.useEffect(() => {
    if (isUserClient && !isUserAgent) {
      fetchUserNotifications();
    }
  }, [isUserClient, isUserAgent, country, window.location.hash]);

  return (
    <UserNotificationsContext.Provider
      value={{
        userNotifications,
        patchUserNotifications,
      }}
    >
      {props.children}
    </UserNotificationsContext.Provider>
  );
};

const useUserNotifications = (): ContextValues => {
  const context = React.useContext(UserNotificationsContext);
  return context;
};

export { useUserNotifications, UserNotificationsProvider };
