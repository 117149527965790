import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const HomeHeart = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <title>{"HomeHeart"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M13.465 10.67c-.528.025-.929.171-1.322.452l-.143.109-.012-.01c-.476-.377-.945-.554-1.617-.554-1.411 0-2.296 1.13-2.296 2.533 0 1.74 1.683 3.302 3.682 4.087.157.062.33.062.487 0 2-.785 3.683-2.347 3.683-4.087-.001-1.402-.887-2.533-2.298-2.533l-.164.003zM13.63 12c.594 0 .964.473.964 1.2l-.004.109c-.076.903-1.083 1.906-2.356 2.528l-.232.107-.072-.032c-1.4-.637-2.521-1.743-2.521-2.712 0-.728.37-1.2.963-1.2.457 0 .707.148 1.154.601a.667.667 0 00.95 0c.447-.453.697-.601 1.154-.601z"
          fill={props.secondaryColor || props.color || theme.colors.grey700}
        />
        <path
          d="M11.578 4.15l-7.333 6a.667.667 0 00-.245.517V18a2 2 0 002 2h12a2 2 0 002-2v-7.333c0-.2-.09-.39-.245-.516l-7.333-6a.667.667 0 00-.844 0zM12 5.528l6.667 5.456V18a.666.666 0 01-.667.667H6l-.078-.005A.666.666 0 015.333 18v-7.018L12 5.527z"
          fill={props.color || theme.colors.grey700}
        />
      </g>
    </svg>
  ) : (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <defs>
        <path
          d="M19.467 10.11L12 4l-7.467 6.11c-.337.275-.533.689-.533 1.125v7.31C4 19.35 4.651 20 5.455 20h13.09C19.35 20 20 19.349 20 18.545v-7.31c0-.436-.196-.85-.533-1.126zm-3.494 2.993c-.322 1.878-1.982 3.205-3.973 4.2-1.991-.995-3.65-2.322-3.973-4.2-.2-1.172.725-2.294 1.906-2.423a2.152 2.152 0 012.066.998 2.153 2.153 0 012.066-.998c1.183.129 2.108 1.251 1.908 2.423z"
          id="homeheart_solid_a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="homeheart_solid_b" fill="#fff">
          <use xlinkHref="#homeheart_solid_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#homeheart_solid_a" />
        <g
          mask="url(#homeheart_solid_b)"
          fill={props.color || theme.colors.grey700}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
