import { IStore } from "@soft/redux/reducers";
import { getProcessedHoliday } from "./holidays.selectors";
import { HolidayBase } from "./holidays.typings";

export const getAddHolidayEndpointRequest = (store: IStore): HolidayBase => {
  const processedHoliday = getProcessedHoliday(store);

  return {
    startDate: String(processedHoliday?.get("startDate")),
    endDate: String(processedHoliday?.get("endDate")),
    skipCleanings: Boolean(processedHoliday?.get("skipCleanings")),
    compensateAgent: Boolean(processedHoliday?.get("compensateAgent")),
  };
};

interface GetEditHolidayEndpointRequest {
  body: HolidayBase;
  params: { id: string };
}

export const getEditHolidayEndpointRequest = (
  store: IStore,
): GetEditHolidayEndpointRequest => {
  const processedHoliday = getProcessedHoliday(store);

  return {
    body: {
      startDate: String(processedHoliday?.get("startDate")),
      endDate: String(processedHoliday?.get("endDate")),
      skipCleanings: Boolean(processedHoliday?.get("skipCleanings")),
      compensateAgent: Boolean(processedHoliday?.get("compensateAgent")),
    },
    params: {
      id: String(processedHoliday?.get("id")),
    },
  };
};

export const getDeleteHolidayEndpointRequest = (
  store: IStore,
): { id: string } => {
  const processedHoliday = getProcessedHoliday(store);

  return {
    id: String(processedHoliday?.get("id")),
  };
};
