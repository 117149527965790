import * as React from "react";
import ReactMarkdown from "react-markdown";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { graphql, PageProps } from "gatsby";
import rehypeRaw from "rehype-raw";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { DEFAULT_LANGUAGE } from "@config/consts";

import { AVAILABLE_COUNTRIES, ORIGIN } from "@typings/globals";

import theme from "@ui/themes/default";
import { Subheading, Paragraph, OptionPanel, Button } from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { langFromTag } from "@services/LangFromTag/LangFromTag";

import { isUserLoggedIn } from "@soft/redux/user";

import SEO from "../../components/SEO";
import Layout from "../../components/Layout";
import { AboutSection, NewCleaningWrapper } from "../../shared/about";

type Props = PageProps<
  {
    prismicContentPage: {
      data: {
        origin: ORIGIN;
        title: { text: string };
        content: { raw: { text: string; url: string }[] };

        hero_button_register_text: {
          text: string;
        };
        hero_button_register_link: {
          text: string;
        };
        hero_button_declare_employee_text: {
          text: string;
        };
        hero_button_declare_employee_link: {
          text: string;
        };
      };
    };
  },
  {
    lang: string;
    country: AVAILABLE_COUNTRIES;
  }
>;

const About = (props: Props): React.ReactElement => {
  const { prismicContentPage } = props.data;
  const title = prismicContentPage.data.title.text;
  const content = prismicContentPage.data.content.raw;
  const origin = prismicContentPage.data.origin || ORIGIN.BATSOFT;
  const isLoggedIn = useSelector(isUserLoggedIn);
  const buttonText = isLoggedIn
    ? props.data.prismicContentPage.data.hero_button_declare_employee_text.text
    : props.data.prismicContentPage.data.hero_button_register_text.text;

  const buttonLink = isLoggedIn
    ? props.data.prismicContentPage.data.hero_button_declare_employee_link.text
    : props.data.prismicContentPage.data.hero_button_register_link.text;

  const handleRedirect = () => {
    window.location.href = `/${langFromTag(
      props.pageContext.lang,
    )}${buttonLink}`;
  };

  const lang = props.pageContext.lang;
  const country = props.pageContext.country;

  return (
    <Layout
      language={langFromTag(lang) || DEFAULT_LANGUAGE}
      country={country}
      origin={origin}
    >
      <SEO title={title} path={props.location.pathname} />
      <AboutSection small>
        <OptionPanel>
          <Subheading>{title}</Subheading>
          {content.map((item, idx) => (
            <React.Fragment key={idx}>
              <ReactMarkdown
                key={idx}
                unwrapDisallowed
                rehypePlugins={[rehypeRaw]}
                components={{
                  p: Paragraph,
                }}
              >
                {item.text}
              </ReactMarkdown>
              {item.url && (
                <Paragraph>
                  <img src={item.url} />
                </Paragraph>
              )}
            </React.Fragment>
          ))}
        </OptionPanel>
      </AboutSection>
      <NewCleaningWrapper backgroundColor={theme.colors.blue900}>
        <LinkWrapper>
          <Button variant="green" size="fill" onClick={handleRedirect}>
            {buttonText}
          </Button>
        </LinkWrapper>
      </NewCleaningWrapper>
    </Layout>
  );
};

const LinkWrapper = styled.div`
  width: 100%;
  max-width: ${rem(328)};
`;

// @ts-ignore
export default withPrismicPreview(About);

export const pageQuery = graphql`
  query AboutUsBatsoft($id: String) {
    prismicContentPage(id: { eq: $id }) {
      _previewable
      data {
        country
        origin
        content {
          raw
        }
        title {
          text
        }
        hero_button_register_text {
          text
        }
        hero_button_register_link {
          text
        }
        hero_button_declare_employee_text {
          text
        }
        hero_button_declare_employee_link {
          text
        }
      }
    }
  }
`;
