import { IS_PROD } from "@config/consts";
import i18next from "@config/i18next";

import { ORIGIN } from "@typings/globals";

import { AsyncActionCreatorFactory } from "@services/AsyncActionCreatorFactory";
import { versionRefresherMiddleware } from "@services/VersionRefresherMiddleware";

import { showToast } from "@containers/Toastify";

const middleware =
  process.env.NODE_ENV === "production" ? [versionRefresherMiddleware] : [];

const getTranslationDomain = () => {
  switch (process.env.ORIGIN) {
    case ORIGIN.BATMAID:
      return "batmaid_pwa_generic";
    case ORIGIN.BATSOFT:
      return "batsoft_pwa_generic";
    case ORIGIN.BATWORK:
      return "batwork_pwa_generic";
    default:
      return "batmaid_pwa_generic";
  }
};

const onFetchErrorNotification = (customMessage?: string) => {
  const domain = getTranslationDomain();
  const message = String(
    customMessage || i18next.t(`${domain}.something_went_wrong`),
  );

  showToast(message, {
    type: "error",
    toastId: "generic-www-error",
  });
};

const asyncActionCreator = AsyncActionCreatorFactory({
  onFetchErrorNotification,
  invalidValueText: i18next.t(`${getTranslationDomain()}.invalid_value`),
  middleware,
});

export { asyncActionCreator };
