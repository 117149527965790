import * as React from "react";
import { useSelector } from "react-redux";

import { dataLayerFacade } from "@services/DataLayerFacade";

import {
  getUserData,
  isUserLoggedIn as isUserLoggedInSelector,
  isUserDataFetched as isUserDataFetchedSelector,
} from "@soft/redux/user/user.selectors";
import { GTM_EVENT_BATSOFT_LOGIN_STATE_CHANGED } from "@config/consts";

const Analytics = (): null => {
  const userData = useSelector(getUserData);
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  const isUserDataFetched = useSelector(isUserDataFetchedSelector);

  React.useEffect(() => {
    if (isUserDataFetched) {
      dataLayerFacade().push({
        event: GTM_EVENT_BATSOFT_LOGIN_STATE_CHANGED,
        isUserLoggedIn: String(isUserLoggedIn),
        ...(userData?.get("hexHumanId") && {
          humanId: userData?.get("hexHumanId"),
        }),
      });
    }
  }, [isUserLoggedIn, isUserDataFetched]);

  return null;
};

export { Analytics };
