/**
 * @author jakubbujakowski
 * @since 2020-3-4
 */

import * as React from "react";
import styled from "styled-components";

import { ITheme } from "@ui/themes/ThemeInterface";
import { rem } from "@ui/helpers";

interface Props {
  children?: React.ReactNode;
  theme: ITheme;
}

const CleaningMaterialsList = styled.div<Props>`
  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    display: flex;
    margin-bottom: ${props => props.theme.margins.base_x3};

    &:last-child {
      margin-bottom: 0;
    }

    > blockquote,
    > span {
      margin-bottom: 0;
      margin-left: ${props => props.theme.margins.base_x3};
    }
  }

  strong {
    display: block;
  }

  img {
    min-width: ${rem(25)};
    max-width: ${rem(25)};
  }
`;

CleaningMaterialsList.displayName = "CleaningMaterialsList";

export { CleaningMaterialsList };
