/**
 * @author tomekbuszewski
 * @since 2019-3-6
 */

import * as React from "react";
import styled from "styled-components";

import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { ITheme } from "@ui/themes/ThemeInterface";

export type HeadingTypes = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

interface Props {
  center?: boolean;
  children?: any;
  theme: ITheme;
}

interface HeadingProps {
  children: React.ReactNode;
  type: HeadingTypes;
  color?: string;
  className?: string;
  // use this prop to override styling, but preserve html tag
  styleAs?: HeadingTypes;
  textAlignXs?: string;
  textAlignMd?: string;
  textAlignLg?: string;
  hotjarSupress?: boolean;
  testId?: string;
  withWordBreak?: boolean;
  noMarginBotton?: boolean;
}

const getFontWeight = (theme: ITheme, type: HeadingTypes) => {
  const weightMap = {
    h1: theme.fonts.weights.bold,
    h2: theme.fonts.weights.light,
    h3: theme.fonts.weights.normal,
    h4: theme.fonts.weights.bold,
    h5: theme.fonts.weights.normal,
    h6: theme.fonts.weights.semibold,
  };
  return weightMap[type];
};

const StyledHeading = styled.div<{
  type: HeadingTypes;
  color?: string;
  center?: boolean;
  textAlignXs?: string;
  textAlignMd?: string;
  textAlignLg?: string;
  withWordBreak?: boolean;
  noMarginBotton?: boolean;
}>`
  color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.theme.colors.black700};
  font-size: ${props => props.theme.fonts.sizes[props.type]};
  line-height: ${props => props.theme.fonts.lineHeights[props.type]};
  letter-spacing: ${props => props.theme.fonts.letterSpacings[props.type]};
  font-weight: ${props => getFontWeight(props.theme, props.type)};
  text-align: ${props => props.textAlignXs || "initial"};

  ${props => props.theme.breakpoints.tablet} {
    text-align: ${props => props.textAlignMd || "initial"};
  }

  ${props => props.theme.breakpoints.desktop} {
    text-align: ${props => props.textAlignLg || "initial"};
  }

  ${props =>
    props.withWordBreak &&
    `
    word-break: break-all;
  `}

  ${props =>
    props.noMarginBotton &&
    `
    margin-bottom: 0
  `}
`;

/**
 * Heading
 */
export const Heading = (props: HeadingProps): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <StyledHeading
      className={props.className}
      as={props.type}
      type={props.styleAs || props.type}
      color={props.color}
      textAlignXs={props.textAlignXs}
      textAlignMd={props.textAlignMd}
      textAlignLg={props.textAlignLg}
      data-hj-suppress={props.hotjarSupress}
      withWordBreak={props.withWordBreak}
      noMarginBotton={props.noMarginBotton}
    >
      {props.children}
    </StyledHeading>
  </TestWrapper>
);

/**
 * Main heading
 */
const MainHeading = styled.h2<Props & { white?: boolean }>`
  font-size: ${props => props.theme.fonts.sizes.h5};
  font-weight: ${props => props.theme.fonts.weights.bold};
  color: ${props =>
    props.white ? props.theme.colors.white : props.theme.colors.black};
  text-align: ${props => props.center && "center"};
`;

MainHeading.defaultProps = {
  center: true,
  white: false,
};

MainHeading.displayName = "MainHeading";

/**
 * Small heading
 */
const SmallHeading = styled.h2<Props>`
  font-size: ${props => props.theme.fonts.sizes.enlarged};
  font-weight: ${props => props.theme.fonts.weights.bold};
  color: ${props => props.theme.colors.black};
  text-align: ${props => props.center && "center"};

  ${props => props.theme.breakpoints.tablet} {
    font-weight: ${props => props.theme.fonts.weights.normal};
  }
`;

SmallHeading.defaultProps = {
  center: false,
};

SmallHeading.displayName = "SmallHeading";

/**
 * Subheading
 */
const Subheading = styled.h2<Props>`
  font-size: ${props => props.theme.fonts.sizes.h4};
  font-weight: ${props => props.theme.fonts.weights.normal};
  color: ${props => props.theme.colors.black};

  text-align: ${props => props.center && "center"};
`;

Subheading.defaultProps = {
  center: false,
};

Subheading.displayName = "Subheading";

export { MainHeading, SmallHeading, Subheading };
