export enum TABBED_NAVIGATION_TABS {
  PREVIOUS,
  UPCOMING,
}

export interface TabbedNavigationProps {
  activeTab: TABBED_NAVIGATION_TABS;
  headingVisible?: boolean;
  inputVisible?: boolean;
  tabsVisible?: boolean;
  customHeading?: string | null;
  setActiveTab: (tab: TABBED_NAVIGATION_TABS) => void;
}

export enum MY_CLEANINGS_MODALS {
  BOOK_NEW_CLEANING = "BOOK_NEW_CLEANING",
  MOBILE_JOB_ACTIONS = "MOBILE_JOB_ACTIONS",
  DETAILS = "DETAILS",
  KEY_INFO = "KEY_INFO",
  PAY_ANYWAY = "PAY_ANYWAY",
  RATE_CLEANING = "RATE_CLEANING",
  REPLACE_CLEANING = "REPLACE_CLEANING",
  THANK_YOU_FOR_COMMENT = "THANK_YOU_FOR_COMMENT",
  THANK_YOU_FOR_BOOKING = "THANK_YOU_FOR_BOOKING",
  THANK_YOU_FOR_RATING = "THANK_YOU_FOR_RATING",
  TRUST_MANDATE_REMINDER = "TRUST_MANDATE_REMINDER",
  SKIP_CLEANING = "SKIP_CLEANING",
  SKIP_CLEANING_FAILED = "SKIP_CLEANING_FAILED",
  ADD_TIP = "ADD_TIP",
  ADJUST_TIMING = "ADJUST_TIMING",
  ADD_ABSENCE = "ADD_ABSENCE",
  JOB_OPTIONS = "JOB_OPTIONS",
  EMPLOYEE_ABSENCES = "EMPLOYEE_ABSENCES",
  WELCOME_TO_BATSOFT = "WELCOME_TO_BATSOFT",
  WORK_PERMIT_UPLOAD = "WORK_PERMIT_UPLOAD",
  CLEANING_MATERIALS = "CLEANING_MATERIALS",
  WARRANTY = "WARRANTY",
  CHECKLIST_WARNING = "CHECKLIST_WARNING",
}
