import React from "react";
import styled from "styled-components";

export const DropdownGroupHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${props =>
    `${props.theme.margins.base} ${props.theme.margins.base_x2}`};
  font-size: ${props => props.theme.fonts.sizes.overline};
  font-weight: ${props => props.theme.fonts.weights.normal};
  letter-spacing: ${props => props.theme.fonts.letterSpacings.overline};
  text-transform: uppercase;
`;
