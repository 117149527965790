import { IS_PROD } from "@config/consts";
import i18next from "@config/i18next";

import { AsyncActionCreatorFactory } from "@services/AsyncActionCreatorFactory";
import { versionRefresherMiddleware } from "@services/VersionRefresherMiddleware";

import { showGenericErrorToast } from "@work/containers/Toastify";

const onFetchErrorNotification = () => showGenericErrorToast();

const middleware = IS_PROD ? [versionRefresherMiddleware] : [];

const asyncActionCreator = AsyncActionCreatorFactory({
  onFetchErrorNotification,
  invalidValueText: i18next.t("batwork_pwa_generic.invalid_value"),
  middleware,
});

export { asyncActionCreator };
