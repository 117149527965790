import React from "react";
import { useTheme } from "styled-components";

import { MyIconProps } from "@ui/Assets/MyIcons/MyIcons";
import { CaretLeft } from "@ui/Assets/MyIcons";
import { TestWrapper } from "@ui/Atoms/TestWrapper";

import * as Styled from "./SubpageLayout.styled";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  testId?: string;
  className?: string;
  children: React.ReactNode;
  Icon?: React.ComponentType<MyIconProps>;
}

const BackButton = ({
  type = "button",
  testId,
  children,
  Icon = CaretLeft,
  ...props
}: Props): React.ReactElement => {
  const theme = useTheme();

  return (
    <TestWrapper testId={testId}>
      <Styled.BackButton type={type} {...props}>
        <React.Fragment>
          {!Icon && <CaretLeft width={theme.icons.sizes.base_x3} />}
          {!!Icon && <Icon width={theme.icons.sizes.base_x3} />}

          <span>{children}</span>
        </React.Fragment>
      </Styled.BackButton>
    </TestWrapper>
  );
};

export { BackButton };
