/**
 * @author michalstolarski
 * @since 2023-6-30
 */

import * as React from "react";
import styled from "styled-components";

import * as Grid from "@ui/Atoms/Grid";

const Container = styled(Grid.Container)`
  --form-grid-gap: ${props => props.theme.margins.base_x2};
  --form-grid-cell-gap: calc(var(--form-grid-gap) / 2);

  padding-left: 0;
  padding-right: 0;
`;

const Row = styled(Grid.Row)`
  margin-left: calc(var(--form-grid-cell-gap) * -1);
  margin-right: calc(var(--form-grid-cell-gap) * -1);
  margin-bottom: var(--form-grid-gap);

  &:last-child {
    margin-bottom: 0;
  }
`;

const Column = styled(Grid.Column)`
  margin-bottom: var(--form-grid-gap);
  padding-left: var(--form-grid-cell-gap);
  padding-right: var(--form-grid-cell-gap);

  ${props => props.theme.breakpoints.mobile} {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const FormGrid = {
  Container,
  Row,
  Column,
};

export { FormGrid };
