import styled, { css } from "styled-components";

import { rem } from "@ui/helpers";
import transition from "@ui/helpers/transition";

import { NormalizedList } from "../List";

interface ICalendarWrapper {
  isLoading: boolean;
  isFetched: boolean;
  hasFetchError: boolean;
}

export const CalendarWrapper = styled.div<ICalendarWrapper>`
  position: relative;
  transition: opacity 0.25s;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.65;
      pointer-events: none;
    `}

  ${props =>
    (!props.isFetched || props.hasFetchError) &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        cursor: not-allowed;
      }
    `}
`;

export const DaysSection = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${props => props.theme.breakpoints.desktopLarge} {
    flex-direction: row;
  }
`;

export const DaysWrapper = styled.ul`
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
  margin-bottom: ${props => props.theme.margins.base_x3};

  li {
    flex-grow: 1;
    flex-basis: ${rem(48)};
  }

  ${props => props.theme.breakpoints.tablet} {
    justify-content: center;
    margin-bottom: ${props => props.theme.margins.base_x4};

    li {
      flex-grow: 0;
      flex-basis: auto;
    }
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    justify-content: flex-end;
  }
`;

export const DayTile = styled.li<{ isDisabled: boolean }>`
  &:last-of-type {
    label {
      border-top-right-radius: ${props => props.theme.buttons.borderRadius};
      border-bottom-right-radius: ${props => props.theme.buttons.borderRadius};
      border-right: 1px solid
        ${props =>
          props.isDisabled
            ? props.theme.colors.greyDisabled
            : props.theme.colors.blue400};
    }
  }

  &:first-of-type {
    label {
      border-top-left-radius: ${props => props.theme.buttons.borderRadius};
      border-bottom-left-radius: ${props => props.theme.buttons.borderRadius};
    }
  }
`;

export const DayLabel = styled.label<{
  isDisabled?: boolean;
  inUnavailable: boolean;
}>`
  padding: ${props => props.theme.margins.base} 0;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.blue400};
  color: ${props => props.theme.colors.blue400};
  font-size: ${props => props.theme.fonts.sizes.caption};
  text-align: center;
  border-right: 0;
  cursor: pointer;
  transition: ${transition(["background"])};

  &:hover {
    background: ${props => props.theme.colors.blue50};
  }

  ${props =>
    props.inUnavailable &&
    css`
      border-color: ${props => props.theme.colors.greyDisabled};
      color: ${props => props.theme.colors.greyDisabled};
    `}

  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}
  
  ${props => props.theme.breakpoints.tablet} {
    width: ${rem(80)};
  }
`;

export const HoursSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  font-size: ${props => props.theme.fonts.sizes.caption};

  ${props => props.theme.breakpoints.desktopLarge} {
    flex-direction: row;
  }
`;

export const HoursWrapper = styled.ul`
  display: flex;
  padding: 0;
  list-style-type: none;
  margin-bottom: ${props => props.theme.margins.base_x2};
  flex-wrap: wrap;
  justify-content: flex-start;

  ${props => props.theme.breakpoints.tablet} {
    flex-wrap: nowrap;
  }
`;

export const HourTile = styled.li<{ isDisabled: boolean }>`
  margin-right: ${props => props.theme.margins.half};

  ${props => props.theme.breakpoints.tablet} {
    margin-right: 0;

    label {
      border-right: 0;
    }

    &:last-of-type {
      label {
        border-top-right-radius: ${rem(4)};
        border-bottom-right-radius: ${rem(4)};
        border-right: 1px solid ${props => props.theme.colors.blue400};

        ${({ isDisabled }) =>
          isDisabled &&
          css`
            border-right: 1px solid ${props => props.theme.colors.greyDisabled};
          `}
      }
    }

    &:first-of-type {
      label {
        border-top-left-radius: ${rem(4)};
        border-bottom-left-radius: ${rem(4)};
      }
    }

    ${({ isDisabled }) =>
      isDisabled &&
      css`
        li {
          &:last-of-type {
            border-right: 1px solid ${props => props.theme.colors.greyDisabled};
          }
        }
      `}
  }
`;

export const HourLabel = styled.label<{
  isDisabled: boolean;
  withDecreasedWidth?: boolean;
}>`
  padding: ${rem(10)} 0;
  border: 1px solid ${props => props.theme.colors.blue400};
  color: ${props => props.theme.colors.blue400};
  font-size: ${props => props.theme.fonts.sizes.caption};
  text-align: center;
  width: ${props => rem(props.withDecreasedWidth ? 40 : 56)};
  margin: 0;
  cursor: pointer;
  transition: ${transition(["background"])};

  &:hover {
    background: ${props => props.theme.colors.blue50};
    transition: ${transition(["background"])};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      border: 1px solid ${props => props.theme.colors.greyDisabled};
      color: ${props => props.theme.colors.greyDisabled};
      pointer-events: none;

      ${props => props.theme.breakpoints.tablet} {
        border-right: 0;
      }
    `}
`;

export const StyledRadio = styled.input`
  display: none;

  &:checked {
    & + label {
      background: ${props => props.theme.colors.blue400};
      color: ${props => props.theme.colors.white};
    }
  }
`;

export const WeekToggleRow = styled.div<{ canSelectPreviousWeek: boolean }>`
  display: flex;
  justify-content: ${props =>
    props.canSelectPreviousWeek ? "space-between" : "flex-end"};
`;

export const CaretWrapper = styled.span<{ isPrev?: boolean }>`
  display: flex;
  align-self: ${props => (props.isPrev ? "flex-start" : "flex-end")};
  align-items: center;
  cursor: pointer;

  span {
    color: ${props => props.theme.colors.blue400};
    font-size: ${props => props.theme.fonts.sizes.half};
  }

  svg {
    transform: ${props => (props.isPrev ? "rotate(90deg)" : "rotate(-90deg)")};
  }

  &:hover {
    svg g {
      fill: ${props => props.theme.colors.blue700};
    }
  }

  ${props => props.theme.breakpoints.upToTablet} {
    ${props => props.isPrev && `margin-left: -${rem(12)}`}
    ${props => !props.isPrev && `margin-right: -${rem(12)}`}
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    display: block;
    position: absolute;
    top: calc(50% - ${rem(42)});
    right: ${props => props.theme.margins.base};
    cursor: pointer;

    ${props =>
      props.isPrev &&
      `
        left: ${props.theme.margins.base};
        right: auto;
      `}

    span {
      display: none;
    }
  }
`;

export const HoursRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const WeekdayLabel = styled.span`
  text-transform: uppercase;
  font-weight: bolder;
`;

export const TimeSlotsRow = styled(NormalizedList)`
  width: 100%;
  margin: -${props => props.theme.margins.base_x1_5} 0 ${props => props.theme.margins.base_x2};
  display: grid;
  grid-template-columns: 1fr;

  ${props => props.theme.breakpoints.mobile} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const TimeSlotLabel = styled(HourLabel)`
  width: 100%;
`;
