import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Calculator2 = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <rect x="4" y="2" width="16" height="19" rx="2" />
      <path d="M20 13H4" />
      <path d="M14.5 17H4" />
      <path d="M14.5 9V21" />
      <path d="M9.5 9V21" />
      <path d="M20 9H4" />
      <path d="M16.5 5V6" />
      <path d="M13.5 5V6" />
    </svg>
  );
};
