/**
 * @author jakubmaslon
 * @since 2020-02-14 💕
 */

import * as React from "react";
import styled, { useTheme } from "styled-components";

import { PlusWithBorder } from "@ui/Assets/Symbolicons/PlusWithBorder";
import { Paragraph } from "@ui/Atoms/Paragraph";

interface Props {
  title: string;
  desc?: React.ReactElement | string;
  calc: string;
  withPlusIcon?: boolean;
}

const CalcItem = (props: Props) => {
  const theme = useTheme();

  return (
    <CalcItemStyled>
      <Text>
        <Paragraph type="body" noMarginBottom weight="semibold">
          {props.title}
        </Paragraph>
        {!!props.desc && <Desc>{props.desc}</Desc>}
      </Text>
      <Paragraph type="body" noMarginBottom weight="semibold" hotjarAllow>
        {props.calc}
      </Paragraph>
      {props.withPlusIcon && (
        <IconWrapper>
          <PlusWithBorder
            size={37}
            variant="solid"
            color={theme.colors.greyDisabled}
          />
        </IconWrapper>
      )}
    </CalcItemStyled>
  );
};

export { CalcItem };

const IconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 70%);
  z-index: 1;
`;

const CalcItemStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.theme.margins.base_x2};
  background: ${props => props.theme.colors.greyLight};
  border-radius: ${props => props.theme.buttons.borderRadius};
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.margins.base};
`;

const Desc = styled.span`
  color: ${props => props.theme.colors.grey700};
`;
