import React from "react";
import styled from "styled-components";
import { Script, ScriptStrategy } from "gatsby";

import { AVAILABLE_LANGS } from "@typings/globals";

import { Container } from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { langInTrustpilotFormat } from "@services/LangInTrustpilotFormat";

import { StyledSection } from "./shared";

interface Props {
  language: AVAILABLE_LANGS;
}

const TrustpilotWidget = React.memo((props: Props): React.ReactElement => {
  const [loaded, setLoaded] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!loaded) return;

    (window as any).Trustpilot?.loadFromElement(ref?.current);
  }, [loaded]);

  return (
    <Section isWhite>
      <StyledContainer>
        <TrustpilotWidgetStyled
          className="trustpilot-widget"
          data-locale={langInTrustpilotFormat(props.language)}
          data-template-id="53aa8912dec7e10d38f59f36"
          data-businessunit-id="5d556e43b38e4c000162c39f"
          data-style-height="140px"
          data-style-width="100%"
          data-theme="light"
          data-stars="4,5"
          data-review-languages={props.language}
          ref={ref}
        />
        <Script
          key="trustpilot"
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          strategy={ScriptStrategy.idle}
          onLoad={() => setLoaded(true)}
        />
      </StyledContainer>
    </Section>
  );
});

TrustpilotWidget.displayName = "TrustpilotWidget";

export { TrustpilotWidget };

const Section = styled(StyledSection)`
  padding: ${props => props.theme.margins.base_x3};

  ${props => props.theme.breakpoints.desktop} {
    padding: ${props => props.theme.margins.base_x4};
  }
`;

const TrustpilotWidgetStyled = styled.div`
  width: 100%;
`;

const StyledContainer = styled(Container)`
  min-height: ${rem(140)};
`;
