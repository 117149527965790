import { createSelector } from "reselect";

import { AVAILABLE_COUNTRIES, AVAILABLE_CURRENCIES } from "@typings/globals";

import { IStore } from "@soft/redux/reducers";

const getLanguageFunction = (store: IStore) =>
  store.localisation.get("language");

export const getLanguage = createSelector(getLanguageFunction, f => f);

const getCountryFunction = (store: IStore) => store.localisation.get("country");

export const getCountry = createSelector(getCountryFunction, f => f);

const getCurrencyFunction = (store: IStore) => {
  const country = getCountry(store) as AVAILABLE_COUNTRIES.CH;

  const currencies = {
    ch: AVAILABLE_CURRENCIES.CHF,
  };
  return currencies[country];
};
export const getCurrency = createSelector(getCurrencyFunction, f => f);
