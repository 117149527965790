/**
 * @author jakubbujakowski
 * @since 2020-8-25
 */
import * as React from "react";

import {
  Container,
  Section,
  SmallHeading,
  Paragraph,
  OptionPanel,
} from "@ui/Atoms";

interface Props {
  somethingWentWrongText: string;
  appCrashExplanationText: string;
}

const ErrorFallback = (props: Props) => (
  <Container>
    <Section small>
      <OptionPanel>
        <SmallHeading>{props.somethingWentWrongText}</SmallHeading>
        <Paragraph>{props.appCrashExplanationText}</Paragraph>
      </OptionPanel>
    </Section>
  </Container>
);

export { ErrorFallback };
