import React from "react";

import { BlogCard } from "@ui/Molecules/BlogCard";

import { BlogCardProps, BoxesProps, BoxesSection } from "./BoxesSection";

interface BlogCard {
  blog_card_title: { text: string };
  blog_card_hashtags: { text: string };
  blog_card_link: { text: string };
  blog_card_background_photo: { fluid: { src: string } }; // @TODO: find a way to use gatsby-plugin-image
}

export interface CleaningTipsProps {
  cleaning_tips_title: { text: string };
  blog_cards: BlogCard[];
}

const CleaningTips = (props: CleaningTipsProps): React.ReactElement => {
  //@TODO: refactor prismic custom type to be the same
  const boxesData: BoxesProps<BlogCardProps> = {
    section_title: props.cleaning_tips_title,
    items: props.blog_cards,
  };

  return <BoxesSection {...boxesData} isWhite isBlog />;
};

export { CleaningTips };
