import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CheckmarkBigCircle = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 25 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path id="Path" d="M11.75 14.25L9.5 12" />
      <path id="Path_2" d="M15.5 10.5L11.75 14.25" />
      <path
        id="Rectangle"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 21C7.52944 21 3.5 16.9706 3.5 12V12C3.5 7.02944 7.52944 3 12.5 3V3C17.4706 3 21.5 7.02944 21.5 12V12C21.5 16.9706 17.4706 21 12.5 21V21Z"
      />
    </svg>
  );
};
