import React from "react";
import styled, { useTheme } from "styled-components";
import { Link } from "gatsby";

import {
  ABOUT_BATMAID_GLOBAL_LINK,
  BECOME_A_BATMAID_GLOBAL_LINK,
  OUR_SERVICES_GLOBAL_LINK,
} from "@config/links";

import { APP_NAMES, AVAILABLE_LANGS } from "@typings/globals";

import { Header } from "@ui/Organisms/Header";
import { NavbarToggle, Navigation, NavLink } from "@ui/Organisms/Navbar";

import { useTranslate } from "@hooks/useTranslate";
import { useBreakpoint } from "@hooks/useBreakpoint";

const GlobalContentPageHeader = (): React.ReactElement => {
  const theme = useTheme();
  const translate = useTranslate("batmaid_pwa_navigation");
  const { mobile, tablet } = useBreakpoint();

  const [isMenuActive, setIsMenuActive] = React.useState<boolean>(false);

  const isMobile = mobile || tablet;

  return (
    <Header
      variant={APP_NAMES.BATMAID}
      loginUser={() => null}
      currentLanguage={AVAILABLE_LANGS.EN}
      logoHref={"/"}
    >
      <Navigation variant={APP_NAMES.BATMAID} active={isMenuActive}>
        <StyledNavLink isDark withBorder={isMobile}>
          <Link to={"/"}>{translate("select_location")}</Link>
        </StyledNavLink>
        <StyledNavLink isDark withBorder={isMobile}>
          <Link
            to={OUR_SERVICES_GLOBAL_LINK}
            activeStyle={{ color: theme.colors.green500 }}
          >
            {translate("our_services")}
          </Link>
        </StyledNavLink>
        <StyledNavLink isDark withBorder={isMobile}>
          <Link
            to={ABOUT_BATMAID_GLOBAL_LINK}
            activeStyle={{ color: theme.colors.green500 }}
          >
            {translate("about_batmaid")}
          </Link>
        </StyledNavLink>
        <StyledNavLink isDark withBorder={false}>
          <Link
            to={BECOME_A_BATMAID_GLOBAL_LINK}
            activeStyle={{ color: theme.colors.green500 }}
          >
            {translate("become_a_batmaid")}
          </Link>
        </StyledNavLink>
      </Navigation>
      <NavbarToggle
        onClick={() => setIsMenuActive(!isMenuActive)}
        active={isMenuActive}
        variant={APP_NAMES.BATMAID}
        label={translate("toggle_menu")}
      />
    </Header>
  );
};

export { GlobalContentPageHeader };

const StyledNavLink = styled(NavLink)`
  padding: ${props => props.theme.margins.base_x2} 0;
  font-weight: ${props => props.theme.fonts.weights.normal};

  ${props => props.theme.breakpoints.desktop} {
    padding: 0;
    font-weight: ${props => props.theme.fonts.weights.bold};
  }
`;
