import * as React from "react";

import { NAVBAR } from "@config/testIds/navbar";

import { AVAILABLE_LANGS } from "@typings/globals";

import { TestWrapper } from "@ui/Atoms";
import { Dropdown, DropdownItem } from "@ui/Molecules/Dropdown";

interface Props {
  currentLanguage: AVAILABLE_LANGS;
  languages: { value: AVAILABLE_LANGS; label: string }[];
  setLanguage: (lang: AVAILABLE_LANGS) => void;
  isDark?: boolean;
}

export const LanguageDropdownBasic = (props: Props): React.ReactElement => (
  <Dropdown
    size="small"
    isDark={props.isDark}
    isDarkOutline={!props.isDark}
    currentState={props.currentLanguage}
    buttonTestId={NAVBAR.LANGUAGE_TRIGGER}
    containerTestId={NAVBAR.LANGUAGE_DROPDOWN}
    autoWidth
  >
    {props.languages.map(item => (
      <DropdownItem
        active={item.value === props.currentLanguage}
        as="button"
        onClick={(e: React.SyntheticEvent) => {
          e.preventDefault();
          props.setLanguage(item.value);
        }}
        key={`lang-${item.value}`}
      >
        <TestWrapper
          testId={
            (NAVBAR as any)[`LANGUAGE_BUTTON_${item.value.toUpperCase()}`]
          }
        >
          <div>{item.label}</div>
        </TestWrapper>
      </DropdownItem>
    ))}
  </Dropdown>
);
