/**
 * @author tomekbuszewski
 * @since 2019-8-13
 */

import styled, { keyframes } from "styled-components";

import { rem, transition } from "@ui/helpers";

import { ITheme } from "@ui/themes/ThemeInterface";

interface Props {
  show: boolean;
  theme: ITheme;
}

const animate = keyframes`
  0% {
    transform: translateX(-50%) scale(0);
    transform-origin: left;
    opacity: 0;
  }
  
  50% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
  
  100% {
    transform: translateX(50%) scale(1);
    opacity: 0;
  }
`;

const LoadingIndicator = styled.div<Props>`
  transition: ${transition(["opacity", "transform", "background"])};
  position: fixed;
  top: ${props => (props.show ? 0 : rem(10))};
  left: 0;
  width: 100%;
  height: ${rem(4)};
  z-index: ${props => props.theme.zIndex.loadingIndicator};
  pointer-events: none;
  opacity: ${props => (props.show ? 1 : 0)};

  &:before {
    animation: 1s ${animate} infinite ease-in-out;
    box-shadow: 0 0 ${rem(16)} 0 ${props => props.theme.colors.green300};
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to right,
      ${props => props.theme.colors.green300} 0%,
      ${props => props.theme.colors.blue400} 100%
    );
    display: ${props => (props.show ? "block" : "none")};
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: transformX(-50%) scale(0);
  }
`;

export { LoadingIndicator };

LoadingIndicator.displayName = "LoadingIndicator";
