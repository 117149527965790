import React from "react";
import styled, { css } from "styled-components";

import { rem } from "@ui/helpers";
import { InfoLabel } from "@ui/Atoms/InfoLabel";

import { LayoutVariant } from "./FocusLayout.types";

const Layout = styled.div<{ variant: LayoutVariant }>`
  --focus-layout-header-padding-top: ${props => props.theme.margins.base_x1_5};
  --focus-layout-header-padding-bottom: ${props => props.theme.margins.base};
  --focus-layout-step-counter-padding-top: ${props => props.theme.margins.half};
  --focus-layout-step-counter-line-height: ${props =>
    props.theme.fonts.lineHeights.normal};

  min-height: 100vh;
  min-height: 100dvh;
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.desktop} {
    min-height: auto;
    padding-top: ${props => props.theme.margins.base_x8};
  }

  ${props =>
    props.variant === "page" &&
    css`
      ${props => props.theme.breakpoints.upToDesktop} {
        padding-left: ${props => props.theme.margins.base};
        padding-right: ${props => props.theme.margins.base};
      }

      ${props => props.theme.breakpoints.desktop} {
        gap: ${props => props.theme.margins.base_x8};
      }
    `}

  ${props =>
    props.variant === "step" &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        min-height: 100vh;
        gap: ${props => props.theme.margins.base_x4};
      }
    `}

  ${props =>
    props.variant === "loading" &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        padding-top: ${rem(192)};
      }
    `}
`;

const Body = styled.div<{
  mobileJustifyContent?: React.CSSProperties["justifyContent"];
}>`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.mobileJustifyContent ?? "flex-start"};

  ${props => props.theme.breakpoints.desktop} {
    flex: 1 0 auto;
    justify-content: flex-start;
  }
`;

const BackButton = styled.button`
  all: unset;
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: ${props => props.theme.fonts.lineHeights.normal};
  color: currentColor;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.margins.half};
  cursor: pointer;
`;

const BackButtonContent = styled.span`
  display: none;

  ${props => props.theme.breakpoints.desktop} {
    display: inline;
  }
`;

const StepCounter = styled(InfoLabel)<{ hidden?: boolean }>`
  padding: var(--focus-layout-step-counter-padding-top)
    ${({ theme }) => theme.margins.base_x2};

  font-size: ${props => props.theme.fonts.sizes.body2};
  font-weight: ${props => props.theme.fonts.weights.semibold};
  line-height: var(--focus-layout-step-counter-line-height);
  background-color: ${props => props.theme.colors.blue20};

  ${props => props.theme.breakpoints.desktop} {
    background-color: ${props => props.theme.colors.grey20};
    padding: var(--focus-layout-step-counter-padding-top)
      ${({ theme }) => theme.margins.base_x1_5};
  }
`;

const Info = styled.button`
  all: unset;
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.normal};
  line-height: ${props => props.theme.fonts.lineHeights.normal};
  color: currentColor;
  display: flex;
  align-items: center;
  gap: ${props => props.theme.margins.base};
  cursor: pointer;
`;

const HeaderBar = styled.header<{ minHeight?: boolean | number }>`
  padding: var(--focus-layout-header-padding-top) 0
    var(--focus-layout-header-padding-bottom);
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.theme.breakpoints.desktop} {
    padding: var(--focus-layout-header-padding-top) 0
      var(--focus-layout-header-padding-bottom);
  }

  ${props =>
    !!props.minHeight &&
    typeof props.minHeight === "boolean" &&
    css`
      min-height: calc(
        var(--focus-layout-step-counter-padding-top) * 2 +
          var(--focus-layout-step-counter-line-height) +
          var(--focus-layout-header-padding-top) +
          var(--focus-layout-header-padding-bottom)
      );
    `}

  ${props =>
    !!props.minHeight &&
    typeof props.minHeight === "number" &&
    css`
      min-height: ${rem(props.minHeight)};
    `}
`;

export {
  Layout,
  Body,
  BackButton,
  BackButtonContent,
  StepCounter,
  Info,
  HeaderBar,
};
