import * as React from "react";
import { graphql, PageProps } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import * as HTTP_CODE from "@config/httpStatuses";
import { ROUTES } from "@config/soft.routing";
import { DEFAULT_LANGUAGE } from "@config/consts";

import { ORIGIN } from "@typings/globals";
import { MODALS } from "@typings/modals";
import { ThunkActionDispatch } from "@typings/soft.reduxThunkActions";

import theme from "@ui/themes/default";
import { Container } from "@ui/Atoms";

import { AsyncActionReturnType } from "@services/AsyncActionCreatorFactory";
import { langFromTag } from "@services/LangFromTag/LangFromTag";

import { showToast } from "@containers/Toastify";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useVoucherManager } from "@hooks/useVoucherManager";

import { useModalStore } from "@soft/hooks/useModalStore";
import { addVoucher } from "@soft/redux/declareEmployee/declareEmployee.actions";

import UTMCatcher from "../components/UTMCatcher";
import Layout from "../components/Layout";
import SEO, { SeoProps } from "../components/SEO";
import { repositoryConfigs } from "../config/repositoryConfig";

import { HowItWorks, HowItWorksProps } from "./Homepage/";
import {
  HeroSection,
  BatsoftHeroProps,
  DeclareNow,
  DeclareNowProps,
  FrictionlessCollaboration,
  FrictionlessCollaborationProps,
  Payroll,
  PayrollProps,
  TimeManagementProps,
  TimeManagement,
  FullVideoSection,
  VideoProps,
} from "./BatsoftHomepage/";

interface PageData
  extends BatsoftHeroProps,
    HowItWorksProps,
    SeoProps,
    FrictionlessCollaborationProps,
    DeclareNowProps,
    TimeManagementProps,
    PayrollProps,
    VideoProps {
  title: {
    text: string;
  };
  body: [{ primary: HowItWorksProps }];
}

type Props = PageProps<
  { prismicBatsoftLandingPage: { data: PageData } },
  {
    lang: string;
    origin: ORIGIN;
  }
>;

const BatsoftHomepage = (props: Props): React.ReactElement => {
  const { data, pageContext } = props;
  const { prismicBatsoftLandingPage } = data;

  const { showModal } = useModalStore();
  const voucherManager = useVoucherManager();
  const dispatch = useDispatch<ThunkActionDispatch>();
  const { desktop, desktopLarge } = useBreakpoint();

  const language = langFromTag(pageContext.lang) || DEFAULT_LANGUAGE;

  const loginSuccessRedirectLink = `/${language}/client#${ROUTES.CLIENT_DASHBOARD}${ROUTES.CLIENT_DASHBOARD_MY_SCHEDULE}`;

  React.useEffect(() => {
    (async () => {
      const voucherId = voucherManager.getStoredDeeplinkVoucher();

      if (voucherId) {
        const { onError, onSuccess } = await dispatch<AsyncActionReturnType>(
          addVoucher(voucherId),
        );

        onSuccess(payload => {
          showToast(payload.data.translatedMessage, {
            type: "success",
          });
        });

        onError(
          () => {
            showModal(MODALS.NAVBAR_LOGIN, {
              onOAuthLoginSuccess: () => {
                window.location.href = loginSuccessRedirectLink;
              },
              onLoginSuccess: () => {
                window.location.href = loginSuccessRedirectLink;
              },
            });
          },
          { matchCode: HTTP_CODE.NOT_FOUND },
        );

        onError(
          err => {
            showToast(err.message, { type: "dark" });
            voucherManager.removeStoredDeeplinkVoucher();
          },
          { matchCode: HTTP_CODE.BAD_REQUEST },
        );
      }
    })();
  }, []);

  const heroData: BatsoftHeroProps = {
    hero_title: prismicBatsoftLandingPage.data.hero_title,
    hero_title_description:
      prismicBatsoftLandingPage.data.hero_title_description,
    hero_list: prismicBatsoftLandingPage.data.hero_list,
    hero_button_register_text:
      prismicBatsoftLandingPage.data.hero_button_register_text,
    hero_button_register_link:
      prismicBatsoftLandingPage.data.hero_button_register_link,
    hero_button_declare_employee_text:
      prismicBatsoftLandingPage.data.hero_button_declare_employee_text,
    hero_button_declare_employee_link:
      prismicBatsoftLandingPage.data.hero_button_declare_employee_link,
    hero_info: prismicBatsoftLandingPage.data.hero_info,
    hero_login: prismicBatsoftLandingPage.data.hero_login,
    hero_image: prismicBatsoftLandingPage.data.hero_image,
    lang: language,
  };

  const howItWorksData: HowItWorksProps = {
    how_it_works_title:
      prismicBatsoftLandingPage.data.body[0].primary.how_it_works_title,
    how_it_works_title_mobile:
      prismicBatsoftLandingPage.data.body[0].primary.how_it_works_title_mobile,
    how_it_works_step_one:
      prismicBatsoftLandingPage.data.body[0].primary.how_it_works_step_one,
    how_it_works_step_one_icon:
      prismicBatsoftLandingPage.data.body[0].primary.how_it_works_step_one_icon,
    how_it_works_step_two:
      prismicBatsoftLandingPage.data.body[0].primary.how_it_works_step_two,
    how_it_works_step_two_icon:
      prismicBatsoftLandingPage.data.body[0].primary.how_it_works_step_two_icon,
    how_it_works_step_three:
      prismicBatsoftLandingPage.data.body[0].primary.how_it_works_step_three,
    how_it_works_step_three_icon:
      prismicBatsoftLandingPage.data.body[0].primary
        .how_it_works_step_three_icon,
    how_it_works_bottom_text:
      prismicBatsoftLandingPage.data.body[0].primary.how_it_works_bottom_text,
  };

  const declareNowData: DeclareNowProps = {
    are_you_ready_title: prismicBatsoftLandingPage.data.are_you_ready_title,
    are_you_ready_description:
      prismicBatsoftLandingPage.data.are_you_ready_description,
    are_you_ready_register_button_link:
      prismicBatsoftLandingPage.data.are_you_ready_register_button_link,
    are_you_ready_register_button_text:
      prismicBatsoftLandingPage.data.are_you_ready_register_button_text,
    are_you_ready_declare_now_button_text:
      prismicBatsoftLandingPage.data.are_you_ready_declare_now_button_text,
    are_you_ready_declare_now_button_link:
      prismicBatsoftLandingPage.data.are_you_ready_declare_now_button_link,
    lang: language,
  };

  const collaborationData: FrictionlessCollaborationProps = {
    collaboration_title: prismicBatsoftLandingPage.data.collaboration_title,
    collaboration_description:
      prismicBatsoftLandingPage.data.collaboration_description,
    collaboration_list: prismicBatsoftLandingPage.data.collaboration_list,
    collaboration_image: prismicBatsoftLandingPage.data.collaboration_image,
  };

  const payrollData: PayrollProps = {
    payroll_title: prismicBatsoftLandingPage.data.payroll_title,
    payroll_description: prismicBatsoftLandingPage.data.payroll_description,
    payroll_list: prismicBatsoftLandingPage.data.payroll_list,
    payroll_image_right: prismicBatsoftLandingPage.data.payroll_image_right,
    payroll_image_bottom: prismicBatsoftLandingPage.data.payroll_image_bottom,
    payroll_image_bottom_mobile:
      prismicBatsoftLandingPage.data.payroll_image_bottom_mobile,
  };

  const timeManagementData: TimeManagementProps = {
    time_management_description:
      prismicBatsoftLandingPage.data.time_management_description,
    time_management_image: prismicBatsoftLandingPage.data.time_management_image,
    time_management_list: prismicBatsoftLandingPage.data.time_management_list,
    time_management_title: prismicBatsoftLandingPage.data.time_management_title,
    title: prismicBatsoftLandingPage.data.title,
  };

  const videoData: VideoProps = {
    video_image: prismicBatsoftLandingPage.data.video_image,
    video_url: prismicBatsoftLandingPage.data.video_url,
  };

  const seoData: SeoProps = {
    seo_title: prismicBatsoftLandingPage.data.seo_title,
    seo_description: prismicBatsoftLandingPage.data.seo_description,
    seo_image: prismicBatsoftLandingPage.data.seo_image,
  };

  return (
    <Layout language={language} origin={ORIGIN.BATSOFT} isFullWidth>
      <SectionsWrapper>
        <UTMCatcher />
        <SEO
          withHreflangs
          title={prismicBatsoftLandingPage.data.title.text}
          lang={language}
          path={props.location.pathname}
          {...seoData}
        />
        <HeroSection {...heroData} />
        <HowItWorksWrapper>
          <HowItWorks
            {...howItWorksData}
            isWhite
            hasGradientBackground
            hasLargePadding
            iconsColor={theme.colors.blue800}
            stepTextColor={theme.colors.grey700}
            iconsSize={desktop || desktopLarge ? 96 : 64}
          />
        </HowItWorksWrapper>
        <FullVideoSection {...videoData} />
        <FrictionlessCollaboration {...collaborationData} />
        <TimeManagement {...timeManagementData} />
        <Payroll {...payrollData} />
        <DeclareNow {...declareNowData} />
      </SectionsWrapper>
    </Layout>
  );
};

export default withPrismicPreview(BatsoftHomepage);

export const query = graphql`
  query BatsoftLandingPageQuery($id: String) {
    prismicBatsoftLandingPage(id: { eq: $id }) {
      _previewable
      lang
      data {
        title {
          text
        }
        # ==== SEO ====
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          gatsbyImageData(width: 1200)
        }

        # ==== HERO SECTION ====
        hero_title {
          text
        }
        hero_title_description {
          text
        }
        hero_list {
          list_item {
            text
          }
        }
        hero_button_register_text {
          text
        }
        hero_button_register_link {
          text
        }
        hero_button_declare_employee_text {
          text
        }
        hero_button_declare_employee_link {
          text
        }

        hero_info {
          text
        }
        hero_login {
          text
        }
        hero_image {
          alt
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { q: 95 }
            width: 1980
          )
        }

        # ===== HOW IT WORKS SECTION ====
        body {
          ... on PrismicBatsoftLandingPageDataBodyHowItWorks {
            slice_type
            primary {
              how_it_works_title {
                text
              }
              how_it_works_title_mobile {
                text
              }
              how_it_works_step_one {
                text
              }
              how_it_works_step_one_icon
              how_it_works_step_two {
                text
              }
              how_it_works_step_two_icon
              how_it_works_step_three {
                text
              }
              how_it_works_step_three_icon
              how_it_works_bottom_text {
                text
              }
            }
          }
        }

        # ==== DECLARE NOW SECTION ====
        are_you_ready_title {
          text
        }
        are_you_ready_description {
          text
        }
        are_you_ready_register_button_link {
          text
        }
        are_you_ready_register_button_text {
          text
        }
        are_you_ready_declare_now_button_text {
          text
        }
        are_you_ready_declare_now_button_link {
          text
        }

        # ===== COLLABORATION SECTION ====
        collaboration_title {
          text
        }
        collaboration_description {
          text
        }
        collaboration_list {
          list_title {
            text
          }
          list_text {
            text
          }
        }
        collaboration_image {
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { q: 95 }
            width: 1980
          )
        }

        # ===== PAYROLL SECTION ====
        payroll_title {
          text
        }
        payroll_description {
          text
        }
        payroll_list {
          list_item {
            text
          }
        }
        payroll_image_right {
          alt
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { q: 95 }
            width: 1980
          )
        }
        payroll_image_bottom {
          alt
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { q: 95 }
            width: 1980
          )
        }
        payroll_image_bottom_mobile {
          alt
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { q: 95 }
            width: 1980
          )
        }

        # ===== TIME MANAGEMENT SECTION ====
        time_management_description {
          text
        }
        time_management_image {
          alt
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { q: 95 }
            width: 1980
          )
        }
        time_management_list {
          list_item {
            text
          }
        }
        time_management_title {
          text
        }
        title {
          text
        }

        # ==== VIDEO SECTION ====
        video_image {
          alt
          gatsbyImageData(
            layout: CONSTRAINED
            imgixParams: { q: 95 }
            width: 1980
          )
        }
        video_url {
          url
        }
      }
    }
  }
`;

const SectionsWrapper = styled.main`
  ${props => props.theme.breakpoints.upToDesktop} {
    section {
      background: ${props => props.theme.colors.white};
    }
  }
`;

const HowItWorksWrapper = styled.div`
  ${props => props.theme.breakpoints.upToTablet} {
    > section {
      padding: ${props => props.theme.margins.base_x4}
        ${props => props.theme.margins.base_x3};
    }

    ${Container} {
      padding: 0;
    }
  }
`;
