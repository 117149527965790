import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const DocumentsFileClock = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        stroke={props.color || "currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M18.003 10V7.825a2 2 0 00-.587-1.414l-2.829-2.83a2.002 2.002 0 00-1.415-.586H5.997a2 2 0 00-2 2.001v14.006a2 2 0 002 2H10"
      ></path>
      <path
        stroke={props.color || "currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M18.003 7.998H14a1 1 0 01-1-1V2.996m3.502 19.008a4.502 4.502 0 110-9.004 4.502 4.502 0 010 9.004"
      ></path>
      <path
        stroke={props.color || "currentColor"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M16.395 15.985v1.758l1.382.844"
      ></path>
    </svg>
  );
};
