import * as React from "react";
import { useTheme } from "styled-components";

import { style, Icon } from "./Symbolicons";

export const WashingMachine = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  if (props.variant === "line") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="24"
        fill="none"
        viewBox="0 0 25 24"
        style={{ ...style(props.size, props.height), ...props.style }}
        className={props.className}
      >
        <path
          fill={props.color || theme.colors.grey700}
          fillRule="evenodd"
          d="M18.25 4a2 2 0 011.995 1.85l.005.15v13.333a.667.667 0 01-.568.66l-.098.007H6.25a.667.667 0 01-.66-.568l-.006-.099V6a2 2 0 011.85-1.995L7.584 4H18.25zm0 1.333H7.584a.666.666 0 00-.66.568L6.917 6v12.667h12V6a.666.666 0 00-.492-.644l-.097-.018-.078-.005zm-1.033 7.126a4.334 4.334 0 00-8.6-.003.666.666 0 00-.027.306l-.005.133-.002.105a4.333 4.333 0 108.66-.234.663.663 0 00-.026-.307zm-4.3 1.32l-.03.029a2.686 2.686 0 01-2.641.559 3 3 0 005.343-.001 2.686 2.686 0 01-2.672-.586zM9.95 12.555a3 3 0 015.934.002 1.353 1.353 0 01-1.134.61 1.352 1.352 0 01-1.229-.78l-.046-.084-.054-.072-.05-.051-.07-.057a.667.667 0 00-.091-.054l-.094-.037-.075-.02a.666.666 0 00-.396.047l-.104.058-.065.05-.067.068-.058.082.007-.014a.628.628 0 00-.029.05l-.083.158c-.24.402-.677.657-1.162.657-.467 0-.89-.236-1.134-.613zm2.4-.238l-.037.07.03-.06.008-.01zM8.918 6.66c.342 0 .623.257.662.589l.005.084a.667.667 0 01-1.33.078l-.004-.084c0-.369.299-.667.667-.667zm2.662.589a.667.667 0 00-1.329.078l.005.084a.667.667 0 001.329-.078l-.005-.084z"
          clipRule="evenodd"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      fill="none"
      viewBox="0 0 96 96"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <path
        fill={props.color || theme.colors.grey700}
        fillRule="evenodd"
        d="M72 16H24a5.335 5.335 0 00-5.333 5.333V80h58.666V21.333A5.335 5.335 0 0072 16zm-32 5.333a2.668 2.668 0 010 5.334 2.668 2.668 0 010-5.334zm-10.667 0a2.668 2.668 0 000 5.334 2.668 2.668 0 000-5.334zM48 72c-10.31 0-18.667-8.357-18.667-18.667 0-10.309 8.358-18.666 18.667-18.666 10.31 0 18.667 8.357 18.667 18.666C66.667 63.643 58.309 72 48 72zm0-21.885c1.693 1.981 4.05 3.218 6.667 3.218 2.458 0 4.666-1.12 6.328-2.904.208.939.338 1.904.338 2.904 0 7.352-5.981 13.334-13.333 13.334s-13.333-5.982-13.333-13.334c0-1.016.138-2 .354-2.949 1.662 1.79 3.846 2.95 6.312 2.95 2.616 0 4.974-1.238 6.667-3.22z"
        clipRule="evenodd"
      />
    </svg>
  );
};
