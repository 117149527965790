import { DefaultTheme, css } from "styled-components";

import { rem, transition } from "@ui/helpers";

import { TabProps } from "./Tab.types";

export const variantStyles = (
  props: TabProps & { theme: DefaultTheme },
): ReturnType<typeof css> => {
  if (!props.variant) {
    throw new Error("Tab variant is required");
  }

  return {
    buttons: css`
      background: ${props.active
        ? props.theme.colors[props.color!]
        : "transparent"};
      color: ${props.active
        ? props.theme.colors.white
        : props.theme.colors[props.color!]};
      padding: ${`${props.theme.buttons.paddings.vertical} ${props.theme.buttons.paddings.xhorizontal}`};
      border: ${`1px solid ${props.theme.colors[props.color!]}`};
      border-right: 0;

      a {
        color: ${props.active
          ? props.theme.colors.white
          : props.theme.colors[props.color!]};
      }

      &:first-of-type {
        border-radius: ${`${props.theme.buttons.borderRadius} 0 0 ${props.theme.buttons.borderRadius}`};
      }

      &:last-of-type {
        border-right: ${`1px solid ${props.theme.colors[props.color!]}`};
        border-radius: 0
          ${`${props.theme.buttons.borderRadius} ${props.theme.buttons.borderRadius} 0`};
      }

      &:disabled {
        border-color: currentColor;
      }
    `,
    links: css`
      background: transparent;
      color: ${props.theme.colors.black};
      margin-right: ${props.theme.margins.base_x2};
      padding: 0;
      border: 0;
      a {
        color: ${props.theme.colors.black};
      }

      &::after {
        transition: ${transition(["transform", "opacity"])};
        content: "";

        position: absolute;
        left: 0;
        bottom: -20%;

        opacity: 0;
        background: ${props.theme.colors.black};
        display: block;

        width: 100%;
        height: ${rem(4)};
        transform-origin: bottom;
        transform: scaleY(0) translateY(${rem(8)});

        ${props.active &&
        css`
          opacity: 1;
          transform: scaleY(1) translateY(0);
        `}
      }
    `,
    light: css`
      display: flex;
      justify-content: center;
      transition: ${transition(["background", "border-color"])};
      background: transparent;
      color: ${props.theme.colors.black700};
      height: ${rem(48)};
      padding: ${props.theme.margins.base_x1_5} ${props.theme.margins.base_x6};
      width: 100%;
      border-radius: ${props.theme.buttons.borderRadius};
      border: 2px solid transparent;

      ${props.active &&
      css`
        border-color: ${props.theme.colors.blue400};
        background: ${props.theme.colors.white};
      `};
    `,
  }[props.variant];
};
