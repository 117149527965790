/**
 * @author jakubbujakowski
 * @since 2020-09-11
 *
 * Component responsible for scrolling to first error field within a form.
 *
 * Simply place it inside <Formik/> and it should do its job.
 *
 * If you want to have scrolling to errors from API
 * or errors not depending on Formik's isSubmitting prop
 * use the asyncErrorFields prop by passing an array of input field names
 */

import * as React from "react";
import { connect, FormikContextType, FormikValues } from "formik";
import { scroller } from "react-scroll";

import { SCROLL_ELEMENT_OFFSET, ERROR_SCROLLING_TIMEOUT } from "@config/consts";

import { INPUT_ERROR_CLASS } from "@ui/Atoms/Form";

interface OuterProps {
  asyncErrorFields?: string[];
  containerId?: string;
  children?: React.ReactNode;
}

interface Props extends OuterProps {
  formik: FormikContextType<FormikValues>;
}

const ErrorFocusComponent = (props: Props) => {
  const { isSubmitting, errors } = props.formik;
  const formikErrors = Object.keys(errors);
  const hasSyncErrors = formikErrors.length > 0 && isSubmitting;
  const hasAsyncErrors = props.asyncErrorFields?.some(field =>
    formikErrors.includes(field),
  );

  React.useEffect(() => {
    if (hasAsyncErrors || hasSyncErrors) {
      // setTimeout is needed to detect errors which show up with delay
      setTimeout(() => {
        const errorInputs = document.querySelectorAll<HTMLInputElement>(
          `.${INPUT_ERROR_CLASS}`,
        );

        if (errorInputs.length) {
          scroller.scrollTo(errorInputs[0].name, {
            smooth: true,
            offset: SCROLL_ELEMENT_OFFSET,
            containerId: props.containerId,
          });
        }
      }, ERROR_SCROLLING_TIMEOUT);
    }
  });

  return props.children;
};

ErrorFocusComponent.displayName = "ErrorFocus";

export const ErrorFocus = connect<OuterProps>(ErrorFocusComponent as any);
