import * as React from "react";
import styled, { css, withTheme } from "styled-components";
import color from "color";

import { Caret } from "@ui/Assets/Symbolicons/Caret";
import { ITheme } from "@ui/themes/ThemeInterface";
import { rem, transition } from "@ui/helpers";
import { TestWrapper } from "@ui/Atoms";

interface DropdownTriggerProps {
  active?: boolean;
  className?: string;
  children?: React.ReactNode;
  isDark?: boolean;
  isDarkOutline?: boolean;
  theme: ITheme;
  onClick: (event: React.SyntheticEvent) => void;
  toLeft?: boolean;
  testId?: string;
  withWideTrigger?: boolean;
  smallPaddings?: boolean;
  variant?: "borderless" | "default";
}

const Trigger = withTheme((props: DropdownTriggerProps) => {
  const caretColor = React.useMemo(() => {
    if (props.variant === "borderless") {
      return props.theme.colors.blue400;
    }

    if (props.isDark) {
      return props.theme.colors.greySurface;
    }

    return props.theme.colors.grey700;
  }, [props.variant, props.isDark]);

  return (
    <TestWrapper testId={props.testId}>
      <button className={props.className} onClick={props.onClick}>
        {props.children}{" "}
        <StyledCaret
          size={props.theme.icons.sizes.base_x3}
          variant="line"
          active={props.active}
          noMargin={props.smallPaddings}
          color={caretColor}
        />
      </button>
    </TestWrapper>
  );
});

const DropdownTrigger = styled(Trigger)<DropdownTriggerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: ${transition(["background, border, color"])};
  border-radius: ${props => props.theme.buttons.borderRadius};
  position: relative;
  z-index: 1;
  height: ${rem(40)};
  white-space: nowrap;

  padding-left: ${props =>
    props.smallPaddings
      ? props.theme.margins.base
      : props.theme.margins.base_x2};
  padding-right: ${props =>
    props.smallPaddings
      ? props.theme.margins.base
      : props.theme.margins.base_x2};

  min-width: ${props => (props.withWideTrigger ? rem(104) : "inherit")};

  margin-left: ${props => (props.toLeft ? 0 : "auto")};

  color: ${props =>
    props.isDark ? props.theme.colors.greySurface : props.theme.colors.grey700};
  background: ${props => props.active && props.theme.colors.whiteHover};

  ${props =>
    ((props.active && props.isDark) || (props.active && props.isDarkOutline)) &&
    `
    background: transparent;
  `}

  border: ${rem(1)} solid
    ${props =>
    props.isDark
      ? color(props.theme.colors.white).alpha(0.2).toString()
      : color(props.theme.colors.black).alpha(0.2).toString()};

  ${props =>
    props.isDarkOutline &&
    `
      border: ${rem(1)} solid ${props.theme.colors.black700};
      color: ${props.theme.colors.black700};
  `}

  cursor: pointer;

  transition: ${transition(["color", "border", "background"])};

  &:active,
  &:focus {
    outline: 0;
    border: ${rem(1)} solid ${props => props.theme.colors.blue400};

    ${props =>
      !props.isDark &&
      `
      color: ${props.theme.colors.black700};
      svg {
        fill: ${props.theme.colors.black500};
      }
    `}
  }

  & svg {
    fill: ${props =>
      props.isDark
        ? props.theme.colors.greySurface
        : props.theme.colors.grey700};
  }

  &:hover {
    ${props =>
      props.isDark
        ? `
      border: ${rem(1)} solid ${color(props.theme.colors.white)
            .alpha(0.3)
            .toString()};
    `
        : `
    background: ${
      props.isDarkOutline ? "transparent" : props.theme.colors.whiteHover
    };
    border: ${rem(1)} solid ${props.theme.colors.grey700};
    color: ${props.theme.colors.black700};

    svg {
      fill: ${props.theme.colors.black500};
    }
  `}
  }

  ${props =>
    props.variant === "borderless" &&
    css`
      &,
      &:active,
      &:focus,
      &:hover {
        border: none;
        background: transparent;
        padding: 0;
        height: auto;
      }
    `}
`;

const StyledCaret = styled(Caret)<{ active?: boolean; noMargin?: boolean }>`
  margin-left: ${props => (props.noMargin ? 0 : props.theme.margins.base)};
  margin-right: -${props => props.theme.margins.base};
  transform: ${props => (props.active ? "rotate(180deg)" : "none")};
  transition: ${transition(["transform"])};
`;

DropdownTrigger.defaultProps = {
  toLeft: false,
};

export default DropdownTrigger;
