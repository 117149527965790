/**
 * @author jakubmaslon
 * @since 2020-08-14
 */

import * as React from "react";
import styled from "styled-components";

import { LivingRoomColorful } from "@ui/Assets/Symbolicons/LivingRoomColorful";
import { LivingRoomColorfulAsteriskSofa } from "@ui/Assets/Symbolicons/LivingRoomColorfulAsteriskSofa";
import { LivingRoomColorfulAsteriskLamp } from "@ui/Assets/Symbolicons/LivingRoomColorfulAsteriskLamp";
import { LivingRoomColorfulAsteriskPictureFrame } from "@ui/Assets/Symbolicons/LivingRoomColorfulAsteriskPictureFrame";

const AnimatedIconLivingRoom = () => (
  <Icon>
    <LivingRoomColorful size={164} />
    <Asterisk>
      <LivingRoomColorfulAsteriskLamp size={164} />
    </Asterisk>
    <Asterisk>
      <LivingRoomColorfulAsteriskPictureFrame size={164} />
    </Asterisk>
    <Asterisk>
      <LivingRoomColorfulAsteriskSofa size={164} />
    </Asterisk>
  </Icon>
);

const Icon = styled.div`
  position: relative;
`;

const Asterisk = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  animation-duration: 2s;
  animation-name: blink;
  animation-iteration-count: infinite;

  @keyframes blink {
    0% {
      opacity: 0;
    }

    10% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  &:nth-child(1) {
    animation-delay: 0.2s;
  }
  &:nth-child(2) {
    animation-delay: 0.4s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`;

export { AnimatedIconLivingRoom };
