import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Phone = (props: Icon): React.ReactElement => {
  if (props.variant === "line") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 24 24"
        style={{ ...style(props.size, props.height), ...props.style }}
      >
        <defs>
          <path
            id="phone-line-a"
            d="M6.299 4.59l-.923.922A4.737 4.737 0 004.09 9.776 12.873 12.873 0 0014.224 19.91a4.737 4.737 0 004.264-1.286l.923-.924a2.012 2.012 0 000-2.844l-1.942-1.942-.096-.09a2.011 2.011 0 00-2.748.09L13.34 14.2l-.02-.01a7.71 7.71 0 01-3.4-3.295l-.12-.236 1.285-1.283a2.01 2.01 0 000-2.845L9.142 4.59a2.011 2.011 0 00-2.844 0zm1.902.942l1.94 1.941a.677.677 0 010 .959l-1.61 1.61a.667.667 0 00-.143.728 9.036 9.036 0 004.84 4.842.67.67 0 00.73-.144l1.61-1.61a.678.678 0 01.959 0l1.94 1.941a.679.679 0 01.001.96l-.923.923a3.404 3.404 0 01-3.065.92A11.54 11.54 0 015.398 9.52a3.404 3.404 0 01.92-3.065l.923-.923a.678.678 0 01.96 0z"
          ></path>
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask id="phone-line-b" fill="#fff">
            <use xlinkHref="#phone-line-a"></use>
          </mask>
          <use fill="#FFF" fillRule="nonzero" xlinkHref="#phone-line-a"></use>
          <g
            fill={props.color || theme.colors.grey700}
            mask="url(#phone-line-b)"
          >
            <path d="M0 0h24v24H0z"></path>
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      fill="none"
      viewBox="0 0 96 96"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <path
        fill="#1EA6D1"
        d="M78.28 62.523l-8.472-8.472a5.87 5.87 0 00-8.299 0l-7.024 7.024a36.522 36.522 0 01-19.562-19.563l7.024-7.024a5.87 5.87 0 000-8.299l-8.47-8.472a5.87 5.87 0 00-8.298 0l-4.027 4.027c-4.184 4.187-5.95 10.187-4.816 15.992 4.123 21.123 20.805 37.805 41.928 41.928 5.805 1.133 11.808-.632 15.992-4.816l4.027-4.027a5.867 5.867 0 00-.003-8.298z"
      ></path>
    </svg>
  );
};
