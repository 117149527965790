import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const ClockTimePause = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <circle cx="6.49771" cy="17.5029" r="4.50187" />
      <path d="M7.78425 18.5028V16.502" />
      <path d="M4.36583 13.5372C3.20096 9.58069 4.86814 5.33809 8.41492 3.23312C11.9617 1.12814 16.4845 1.69708 19.3994 4.61489C22.3144 7.53271 22.8789 12.056 20.7704 15.6008C18.6619 19.1455 14.4177 20.8085 10.4624 19.6397" />
      <path d="M16.893 11.3913H12.5002V5.99805" />
      <path d="M5.21118 18.5028V16.502" />
    </svg>
  );
};
