import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { Button, Container, Heading, Margins } from "@ui/Atoms";
import { DraggableCarousel } from "@ui/Molecules/DraggableCarousel";
import { BlogCard } from "@ui/Molecules/BlogCard";

import { useBreakpoint } from "@hooks/useBreakpoint";
import { useBrowserRerender } from "@hooks/useBrowserRerender";

import { GatsbyImage } from "../../components/GatsbyImage";
import { Tile } from "../../components/Tile";

import { StyledSection } from "./shared";

export interface DefaultCard {
  box_image: IGatsbyImageData;
  box_image_mobile?: IGatsbyImageData;
  box_image_text: {
    text: string;
  };
  service_link?: {
    slug?: string;
    url?: string;
  };
  list?: string[];
}

export interface BlogCardProps {
  blog_card_background_photo: {
    fluid: { src: string };
  };
  blog_card_hashtags: { text: string };
  blog_card_link: { text: string };
  blog_card_title: { text: string };
}

export interface BoxesProps<ItemsType> {
  section_title: {
    text: string;
  };
  section_link?: {
    url: string | null;
    slug: string | null;
    relative: string | null;
    label: string;
  };
  isBlog?: boolean;
  items: ItemsType[];
  isWhite?: boolean;
  language?: string;
  hideTilesWithoutLink?: boolean;
}

const BoxesSection = (
  props: BoxesProps<DefaultCard | BlogCardProps>,
): React.ReactElement => {
  const { mobile, desktop, desktopLarge } = useBreakpoint();
  const isBrowser = useBrowserRerender();

  const isDesktopSecure = isBrowser && desktop;
  const isDesktopLargeSecure = isBrowser && desktopLarge;
  const isBoxWithList = !!props.items?.find(
    (item: DefaultCard) => item.list?.length,
  );

  const isBoxWithText = props.isBlog || isBoxWithList;

  const stringifySectionLink = JSON.stringify(props.section_link);
  const sectionLinkURL = React.useCallback(() => {
    if (!props.section_link) {
      return null;
    }

    if (props.section_link.url) {
      return props.section_link.url;
    }

    if (props.section_link.slug) {
      return `/${props.language}/${props.section_link.slug}`;
    }

    if (props.section_link.relative) {
      return props.section_link.relative;
    }

    return null;
  }, [stringifySectionLink, props.language])();

  return (
    <StyledSection isWhite={props.isWhite}>
      <StyledContainer>
        <Margins
          xs={[null, "base_x3", null, null]}
          lg={[null, "base_x8", null, null]}
        >
          <Heading
            type="h2"
            styleAs={desktop || desktopLarge ? "h4" : "h6"}
            textAlignXs="left"
            textAlignMd="left"
            textAlignLg="center"
          >
            {props.section_title.text}
          </Heading>
        </Margins>

        <DraggableCarousel
          slidesToShow={{
            mobile: isBoxWithText ? 1 : 2,
            tablet: isBoxWithText ? 2 : 4,
            desktop: props.items?.length > 4 ? 5 : 4,
          }}
          isHorizontallyCentered={
            (isDesktopSecure || isDesktopLargeSecure) && props.items?.length < 5
          }
          slidesAmount={props.items.length}
        >
          {props.isBlog
            ? props.items.map((blogCard: BlogCardProps) => (
                <BlogCard
                  key={blogCard.blog_card_title.text}
                  title={blogCard.blog_card_title.text}
                  // when BlogCards are in use, we should rather use GatsbyImage
                  // instead of background-image, due to performance issues
                  backgroundPhoto={
                    blogCard.blog_card_background_photo?.fluid?.src
                  }
                  hashTags={blogCard.blog_card_hashtags.text}
                  link={blogCard.blog_card_link.text}
                />
              ))
            : props.items?.map((service: DefaultCard) => (
                <React.Fragment key={service.box_image_text.text}>
                  {!(
                    props.hideTilesWithoutLink &&
                    !service.service_link?.slug &&
                    !service.service_link?.url
                  ) && (
                    <Tile
                      title={service.box_image_text.text}
                      img={
                        <GatsbyImage
                          image={service.box_image}
                          alt={"batmaid service"}
                        />
                      }
                      {...(service.box_image_mobile && {
                        imgMobile: (
                          <GatsbyImage
                            image={service.box_image_mobile}
                            alt={"batmaid service"}
                          />
                        ),
                      })}
                      imgAlt={service.box_image_text.text}
                      isSquareOnDesktop={props.items?.length === 3}
                      list={service.list}
                      {...(service.service_link?.slug &&
                        !service.service_link?.url && {
                          url: `/${props.language}/${service.service_link.slug}`,
                        })}
                      {...(service.service_link?.url &&
                        !service.service_link?.slug && {
                          url: service.service_link?.url,
                        })}
                    />
                  )}
                </React.Fragment>
              ))}
        </DraggableCarousel>

        {!!props.section_link && !!sectionLinkURL && (
          <Margins xs={["base_x3", null, null, null]}>
            <ButtonWrapper>
              <StyledButton
                forwardedAs="a"
                href={sectionLinkURL}
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  navigate(sectionLinkURL);
                }}
                variant="green"
                size={mobile ? "fill" : "medium"}
              >
                {props.section_link.label}
              </StyledButton>
            </ButtonWrapper>
          </Margins>
        )}
      </StyledContainer>
    </StyledSection>
  );
};

export { BoxesSection };

const StyledContainer = styled(Container)`
  ${props => props.theme.breakpoints.desktop} {
    overflow: hidden;
  }
`;

const StyledButton = styled(Button)`
  &,
  &:hover {
    color: ${props => props.theme.colors.white};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
