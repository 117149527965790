/**
 * @author michalstolarski
 * @since 2022-4-5
 */

import * as React from "react";
import styled from "styled-components";

import { rem } from "@ui/helpers";
import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { Margins, MarginsBreakpoints } from "@ui/Atoms/Margins";

interface Props extends MarginsBreakpoints {
  testId?: string;
  className?: string;
  thickness?: string;
  color?: string;
}

const HorizontalRule = (props: Props): React.ReactElement => {
  const { testId, className, thickness, color, ...rest } = props;
  return (
    <TestWrapper testId={testId}>
      <Margins {...rest}>
        <HorizontalRuleWrapper
          className={className}
          thickness={thickness}
          color={color}
        />
      </Margins>
    </TestWrapper>
  );
};

export { HorizontalRule };

const HorizontalRuleWrapper = styled.hr<{ thickness?: string; color?: string }>`
  border: none;
  border-top: ${props => props.thickness || rem(1)} solid;
  border-color: ${props => props.color || props.theme.colors.greyDark};
`;
