import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const StarCheckMark = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 21 20"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        clipRule="evenodd"
        d="M7.856 15.547H5.788a.836.836 0 0 1-.836-.835v-2.069a.836.836 0 0 0-.245-.59L3.245 10.59a.835.835 0 0 1 0-1.182l1.478-1.477a.784.784 0 0 0 .23-.555V5.288c0-.461.374-.836.835-.836h2.069a.836.836 0 0 0 .59-.245L9.91 2.745a.835.835 0 0 1 1.182 0l1.462 1.462c.157.157.37.245.591.245h2.068c.462 0 .836.375.836.836v2.069c0 .221.088.434.245.59l1.463 1.463a.835.835 0 0 1 0 1.182l-1.463 1.462a.835.835 0 0 0-.245.591v2.068a.836.836 0 0 1-.836.836h-2.068a.835.835 0 0 0-.59.245l-1.463 1.463a.835.835 0 0 1-1.182 0l-1.463-1.463a.83.83 0 0 0-.591-.247Z"
      />
      <path d="m13 8.75-3.125 3.125L8 10" />
    </svg>
  );
};
