import * as React from "react";
import { format, addMinutes, parse } from "date-fns";
import styled, { css } from "styled-components";

import date, { addZero, DATE_FORMAT } from "@services/DateFormatter";

const showTime = (
  time: string,
  endTime: string,
  duration?: number,
  isDesktop?: boolean,
) =>
  isDesktop ? (
    <div>
      {time}
      {duration ? ` - ${endTime}` : ""}
    </div>
  ) : (
    `, ${time}${duration ? ` - ${endTime}` : ""}`
  );

interface Props {
  executionDate: string | number;
  duration?: number;
  short?: boolean;
  isDesktop?: boolean;
  skipParsing?: boolean;
  dayTranslated: string;
  monthTranslated: string;
  boldDay?: boolean;
  withYear?: boolean;
}

/**
 * @deprecated use <DateDisplay /> instead
 */
const CleaningDate = (props: Props): React.ReactElement => {
  const dateObject = date(props.executionDate);
  const dayNo = addZero(dateObject.day);
  const day = props.dayTranslated;
  const month = props.monthTranslated;

  // safari parse date fix
  if (!props.skipParsing) {
    const dateJs = parse(
      String(props.executionDate),
      DATE_FORMAT.YEAR_MONTH_DAY_HOUR_MINUTE_ISO_8601,
      new Date(),
    );

    const time = format(dateJs, DATE_FORMAT.HOUR_MINUTE_SHORT);
    const MINUTES_IN_ONE_HOUR = 60;
    const endDate = addMinutes(
      dateJs,
      props.duration ? props.duration * MINUTES_IN_ONE_HOUR : 0,
    );
    const endTime = format(endDate, DATE_FORMAT.HOUR_MINUTE_SHORT);

    return (
      <React.Fragment>
        <Day isBold={props.boldDay}>{day}</Day>, {month} {dayNo}
        {props.withYear ? `, ${dateObject.year}` : " "}
        {!props.short &&
          showTime(time, endTime, props.duration, props.isDesktop)}
      </React.Fragment>
    );
  }

  // @TODO: return it in a more elegant way
  return (
    <React.Fragment>
      <strong style={{ textTransform: "uppercase" }}>{day}</strong>, {month}{" "}
      {dayNo}
      {!props.short &&
        `, ${addZero(dateObject.hours)}:${addZero(dateObject.minutes)}`}
      {props.withYear && `.${dateObject.year}`}
    </React.Fragment>
  );
};

export { CleaningDate };

const Day = styled.span<{ isBold?: boolean }>`
  text-transform: uppercase;
  ${props =>
    props.isBold &&
    css`
      font-weight: ${props => props.theme.fonts.weights.bold};
    `}
`;
