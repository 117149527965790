/**
 * @author jakubmaslon
 * @since 2020-10-23
 */

import * as React from "react";
import styled from "styled-components";

import { BOTTOM_SHEET_COLLAPSED_HEIGHT } from "@config/consts";
import { BOOKING_SUMMARY } from "@config/testIds/booking";

import { TestWrapper } from "@ui/Atoms/TestWrapper";
import { rem } from "@ui/helpers";

import { useOnClickOutside } from "@hooks/useOnClickOutside";

interface Props {
  testId?: string;
  className?: string;
  children: React.ReactNode;
  hideCurtain?: boolean;
  initState?: boolean;
  setToggleState?: (value: boolean) => void;
  onTransitionEnd?: React.TransitionEventHandler<HTMLDivElement>;
}

const BottomSheet = (props: Props): React.ReactElement => {
  const [isOpen, setIsOpen] = React.useState(() => !!props.initState);
  const [direction, setDirection] = React.useState(0);

  const handleSwipe = (e: React.TouchEvent) => {
    e.stopPropagation();

    const clientY = e.changedTouches[0].clientY;
    const swipeOffset = 2.5;

    if (direction < clientY + swipeOffset) {
      setIsOpen(false);
    } else if (direction > clientY - swipeOffset) {
      setIsOpen(true);
    }

    setDirection(clientY);
  };

  const handleBarClick = () => {
    setIsOpen(!isOpen);
  };

  const ref = useOnClickOutside(() => setIsOpen(false));

  React.useEffect(() => {
    if (!props.setToggleState) return;

    props.setToggleState(isOpen);
  }, [isOpen]);

  return (
    <TestWrapper testId={props.testId}>
      <BottomSheetStyled
        isOpen={isOpen}
        onTouchMove={handleSwipe}
        ref={ref}
        className={props.className}
        onTransitionEnd={props.onTransitionEnd}
      >
        <TestWrapper testId={BOOKING_SUMMARY.BOTTOMSHEET_BAR}>
          <Bar onClick={handleBarClick} />
        </TestWrapper>
        {!props.hideCurtain && <Curtain isOpen={isOpen} />}

        {props.children}
      </BottomSheetStyled>
    </TestWrapper>
  );
};

export { BottomSheet };

const BottomSheetStyled = styled.div<{ isOpen: boolean }>`
  --bottom-sheet-background: ${props => props.theme.colors.white};

  position: fixed;
  bottom: 0;
  transform: ${props =>
    props.isOpen
      ? "translateY(0)"
      : `translateY(calc(100% - ${rem(BOTTOM_SHEET_COLLAPSED_HEIGHT)}))`};
  left: 0;
  right: 0;
  max-height: 100%;
  margin: 0;
  padding: ${props => props.theme.margins.base_x4}
    ${props => props.theme.margins.base_x2};
  background: var(--bottom-sheet-background);
  border-top-left-radius: ${props => props.theme.border.radius.base_x1_5};
  border-top-right-radius: ${props => props.theme.border.radius.base_x1_5};
  box-shadow: 0 0 ${rem(4)} 0 rgb(0 0 0 / 20%), 0 0 ${rem(6)} 0 rgb(0 0 0 / 7%);
  transition: transform ${props => props.theme.animations.duration}
    ${props => props.theme.animations.easing};
  overflow-y: ${props => (props.isOpen ? "scroll" : "hidden")};
  z-index: ${props => props.theme.zIndex.bottomSheet};
  touch-action: none;
`;

const Bar = styled.div`
  position: absolute;
  top: ${props => props.theme.margins.base_x1_5};
  left: 50%;
  transform: translateX(-50%);
  height: ${rem(4)};
  width: ${rem(40)};
  background: ${props => props.theme.colors.greyDark};
  border-radius: ${props => props.theme.buttons.borderRadius};
  z-index: 1;

  /* extends the clickable area of the Bar */
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + ${props => props.theme.margins.base_x2});
    height: calc(100% + ${props => props.theme.margins.base_x2});
    transform: translate3d(-50%, -50%, 0);
  }
`;

const curtainHeight = 20;

const Curtain = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: ${props =>
    props.isOpen ? "100%" : rem(BOTTOM_SHEET_COLLAPSED_HEIGHT - curtainHeight)};
  height: ${rem(curtainHeight)};
  background: linear-gradient(
    0,
    ${props => props.theme.colors.white},
    rgba(255, 255, 255, 0) // transparent doesn't work in Safari
  );
  pointer-events: none;
`;
