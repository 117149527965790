/*
  it parses this:
  https://www.youtube.com/watch?v=XXXXXXXXXXXX

  into this:
  https://www.youtube.com/embed/XXXXXXXXXXXX?autoplay=1&rel=0&showinfo=0&modestbranding=1&enablejsapi=1&widgetid=1`;
*/
export const getIframeSrc = (
  videoUrl: string | undefined | null,
): string | null => {
  if (!videoUrl) {
    return null;
  }

  const videoId = videoUrl.split("v=")[1];

  return `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&showinfo=0&modestbranding=1&enablejsapi=1&widgetid=1`;
};
