import * as React from "react";
import { Link } from "gatsby";

import { AVAILABLE_LANGS } from "@typings/globals";

import { List } from "@ui/Atoms/List";

export interface LinkedArticle {
  article: {
    document: {
      id: string;
      data: {
        title: { text: string };
        slug: { text: string };
      };
    };
  };
}

interface Props {
  lang: AVAILABLE_LANGS;
  articles: LinkedArticle[];
}

const TopQuestions = (props: Props): React.ReactElement => (
  <div>
    <List>
      {props.articles
        .filter(item => !!item.article?.document)
        .map(item => (
          <li key={item.article.document.id}>
            <Link
              to={`/${
                props.lang
              }/${item.article.document.data.slug.text.trim()}`}
            >
              {item.article.document.data.title.text}
            </Link>
          </li>
        ))}
    </List>
  </div>
);

export default TopQuestions;
