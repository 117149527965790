import React from "react";

import { GTM_EVENT_COOKIES_DECLINED } from "@config/consts";

import { dataLayerFacade } from "@services/DataLayerFacade";

import { useAuth } from "@hooks/useAuth";

const Cookiebot = (): null => {
  const dataLayer = dataLayerFacade();
  const { user } = useAuth();

  const handleCookiebotDecline = () => {
    dataLayer.push({
      event: GTM_EVENT_COOKIES_DECLINED,
      clientId: user?.get("identifier"),
    });
  };

  React.useEffect(() => {
    window.addEventListener("CookiebotOnDecline", handleCookiebotDecline);
  }, []);

  return null;
};

export { Cookiebot };
