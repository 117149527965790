import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const ContactCard = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M6 18.667a2 2 0 01-2-2V7.333a2 2 0 012-2h12a2 2 0 012 2v9.334a2 2 0 01-2 2zm12-12H6a.666.666 0 00-.667.666v9.334c0 .368.299.666.667.666h.666v-1a3 3 0 015.997-.14l.004.14-.001 1H18a.666.666 0 00.66-.568l.007-.098V7.333A.666.666 0 0018 6.667zm-8.333 8c-.884 0-1.607.687-1.663 1.557l-.004.11v1h3.333v-1c0-.921-.746-1.667-1.666-1.667zm7-2.667a.667.667 0 01.077 1.329l-.077.004H14a.667.667 0 01-.078-1.329L14 12h2.667zm-7-4a2.333 2.333 0 110 4.667 2.333 2.333 0 010-4.667zm0 1.333a1 1 0 100 2 1 1 0 000-2zm7 0a.667.667 0 01.077 1.33l-.077.004H14a.667.667 0 01-.078-1.33L14 9.334h2.667z"
          id="contactcard_line_a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="contactcard_line_b" fill="#fff">
          <use xlinkHref="#contactcard_line_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#contactcard_line_a" />
        <g
          mask="url(#contactcard_line_b)"
          fill={props.color || theme.colors.black500}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M18.667 5.333H5.333C4.597 5.333 4 5.93 4 6.667v10.666c0 .737.597 1.334 1.333 1.334h1.334a1.333 1.333 0 112.666 0h5.334a1.333 1.333 0 112.666 0h1.334c.736 0 1.333-.597 1.333-1.334V6.667c0-.737-.597-1.334-1.333-1.334zM9 8c.92 0 1.667.747 1.667 1.667 0 .919-.748 1.666-1.667 1.666-.92 0-1.667-.747-1.667-1.666C7.333 8.747 8.081 8 9 8zm2.333 6.667H6.667v-.504C6.667 12.97 7.713 12 9 12c1.287 0 2.333.97 2.333 2.163v.504zm5.334-2H14a.669.669 0 01-.667-.667c0-.367.3-.667.667-.667h2.667c.366 0 .666.3.666.667 0 .367-.3.667-.666.667zm0-2.667H14a.669.669 0 01-.667-.667c0-.366.3-.666.667-.666h2.667c.366 0 .666.3.666.666 0 .367-.3.667-.666.667z"
          id="contactcard_solid_a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="contactcard_solid_b" fill="#fff">
          <use xlinkHref="#contactcard_solid_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#contactcard_solid_a" />
        <g
          mask="url(#contactcard_solid_b)"
          fill={props.color || theme.colors.black500}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
