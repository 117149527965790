/**
 * @author jakubmaslon
 * @since 2020-10-27
 */

import * as React from "react";
import styled from "styled-components";

import { TAGS as TEST_IDS } from "@config/testIds/tags";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  tags: string[];
}

const Tags = (props: Props): React.ReactElement => (
  <StyledTags>
    {props.tags.map(tag => (
      <TestWrapper testId={`${TEST_IDS.TAG} ${TEST_IDS.TAG}-${tag}`} key={tag}>
        <Tag key={tag}>{tag}</Tag>
      </TestWrapper>
    ))}
  </StyledTags>
);

Tags.displayName = "Tags";

export { Tags };

const StyledTags = styled.div`
  display: flex;
  margin: 0 -${props => props.theme.margins.half};
`;

const Tag = styled.div`
  display: inline;
  padding: ${props => props.theme.margins.half};
  margin: 0 ${props => props.theme.margins.half};
  border: 1px solid ${props => props.theme.colors.grey300};
  border-radius: ${props => props.theme.buttons.borderRadius};
  font-size: ${props => props.theme.fonts.sizes.caption};
`;
