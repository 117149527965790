import { format, parse } from "date-fns";

import { IS_DEV } from "@config/consts";

import { AVAILABLE_LANGS } from "@typings/globals";

import { getLocale, DATE_FORMAT } from "@services/DateFormatter";

interface HumanReadableDate {
  dayInWeek: string;
  monthAndDay: string;
  time: string;
}

/**
 * @deprecated use intlFormatDate()
 */
export const formatDateToHumanReadable = (
  dateString: string,
  language: AVAILABLE_LANGS,
): HumanReadableDate => {
  if (!(new Date(dateString).getFullYear() > 0)) {
    if (IS_DEV) {
      console.error("provided DateString can't be converted into date.");
    }
    return { dayInWeek: "", monthAndDay: "", time: "" };
  }

  // get rid of seconds from string to not break parse()
  let formatted = "";
  const dateArray = dateString.split(":");

  if (dateArray.length === 1) {
    formatted = `${dateArray[0]}T00:00`;
  } else {
    formatted = `${dateArray[0]}:${dateArray[1]}`;
  }

  const dateJs = parse(
    formatted,
    DATE_FORMAT.YEAR_MONTH_DAY_HOUR_MINUTE_ISO_8601,
    new Date(),
  );

  const locale = getLocale(language);

  const dayInWeek = format(dateJs, DATE_FORMAT.DAY_IN_WEEK, { locale });
  const monthAndDay = format(dateJs, getMonthAndDayFormat(language), {
    locale,
  });
  const time = format(new Date(dateJs), DATE_FORMAT.HOUR_MINUTE);

  return { dayInWeek, monthAndDay, time };
};

const getMonthAndDayFormat = (language: AVAILABLE_LANGS) => {
  switch (language) {
    case AVAILABLE_LANGS.PL: {
      return DATE_FORMAT.MONTH_DAY_REVERTED;
    }
    default: {
      return DATE_FORMAT.MONTH_DAY;
    }
  }
};
