import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Computer = (props: Icon): React.ReactElement =>
  props.variant === "line" ? (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          id="computer_line_a"
          d="M7.333 20a.667.667 0 01-.077-1.329l.077-.004h1.334a.666.666 0 00.662-.59L9.333 18v-2H6a2 2 0 01-1.995-1.85L4 14V6a2 2 0 012-2h12a2 2 0 012 2v8a2 2 0 01-2 2h-3.334v2c0 .342.258.624.59.662l.077.005h1.334a.667.667 0 01.077 1.329l-.077.004H7.333zm3.334-2c0 .234-.04.459-.114.667h2.894a1.992 1.992 0 01-.11-.55L13.333 18v-2h-2.667v2zm7.999-4.667H5.333V14c0 .335.247.612.568.66l.099.007h12a.666.666 0 00.667-.667l-.001-.667zm-.666-8H6A.666.666 0 005.333 6v6h13.333V6a.666.666 0 00-.567-.66L18 5.334z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="computer_line_b" fill="#fff">
          <use xlinkHref="#computer_line_a"></use>
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#computer_line_a"></use>
        <g
          fill={props.color || theme.colors.black500}
          mask="url(#computer_line_b)"
        >
          <path d="M0 0h24v24H0z"></path>
        </g>
      </g>
    </svg>
  ) : (
    <svg
      style={{ ...style(props.size, props.height), ...props.style }}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M15.333 18.667A1.334 1.334 0 0114 17.333h-4c0 .736-.597 1.334-1.333 1.334a.667.667 0 000 1.333h6.666a.667.667 0 000-1.333zM18.667 4H5.333C4.597 4 4 4.597 4 5.333v9.334C4 15.403 4.597 16 5.333 16h13.334c.736 0 1.333-.597 1.333-1.333V5.333C20 4.597 19.403 4 18.667 4zM18 13.333H6V6h12v7.333z"
          id="computer_solid_a"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="computer_solid_b" fill="#fff">
          <use xlinkHref="#computer_solid_a" />
        </mask>
        <use fill="#FFF" fillRule="nonzero" xlinkHref="#computer_solid_a" />
        <g
          mask="url(#computer_solid_b)"
          fill={props.color || theme.colors.black500}
        >
          <path d="M0 0h24v24H0z" />
        </g>
      </g>
    </svg>
  );
