import { IS_BROWSER, LANGUAGE_LOCAL_STORAGE_KEY } from "@config/consts";

import { AVAILABLE_LANGS } from "@typings/globals";

import { langFromPathname } from "@services/LangFromPathname";
import { isLocalStorageAvailable } from "@services/IsLocalStorageAvailable";

export const setLanguageInUrl = (lang: AVAILABLE_LANGS): void => {
  if (!IS_BROWSER) return;

  const langStr = `/${langFromPathname()}/`;
  const newLang = `/${lang}/`;
  const newUrl = window.location.href.replace(langStr, newLang);

  isLocalStorageAvailable() &&
    localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, lang);

  window.location.replace(newUrl);
};
