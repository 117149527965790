import React from "react";
import styled, { useTheme } from "styled-components";
import { PrismicRichText, SliceComponentProps } from "@prismicio/react";
import { getImage, withArtDirection } from "gatsby-plugin-image";

import { mediaQueries } from "@ui/themes/default";
import rem from "@ui/helpers/rem";
import { Button, Container, Heading } from "@ui/Atoms";

import { PrismicGlobalContentPageDataBodyBannerWithCta } from "../../../graphql-types";
import { GatsbyImage } from "../../components/GatsbyImage";
import { SliceContainer } from "../../components/SliceContainer";

const BannerWithCtaSlice = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyBannerWithCta>,
): React.ReactElement => {
  const theme = useTheme();
  const primary = props.slice.primary;

  const backgroundResponsiveImage =
    primary?.background_image?.gatsbyImageData &&
    withArtDirection(getImage(primary.background_image.gatsbyImageData)!, [
      {
        media: mediaQueries.mobile,
        image: getImage(
          primary.background_image.thumbnails?.mobile?.gatsbyImageData,
        )!,
      },
    ]);

  return (
    <StyledSliceContainer>
      <ContainerStyled widthVariant="fullMobile">
        <BackgroundWrapper>
          <GatsbyImage
            image={backgroundResponsiveImage}
            alt={primary?.background_image?.alt || ""}
            style={{
              borderRadius: theme.border.radius.base,
            }}
            className={"art-directed"}
          />
        </BackgroundWrapper>

        <Content>
          <StyledHeading type={"h1"} styleAs={"h2"}>
            {primary?.title1?.text}
          </StyledHeading>
          {primary?.description?.raw && (
            <ParagraphWrapper>
              <PrismicRichText field={primary.description.raw} />
            </ParagraphWrapper>
          )}
          {primary?.target_url?.url && (
            <ButtonWrapper>
              <Button
                variant={"green"}
                as={"a"}
                href={primary?.target_url?.url}
              >
                {primary.cta_text}
              </Button>
            </ButtonWrapper>
          )}
        </Content>
      </ContainerStyled>
    </StyledSliceContainer>
  );
};

export { BannerWithCtaSlice };

const BackgroundWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  .art-directed {
    width: 100%;
    height: 100vh;

    ${props => props.theme.breakpoints.tablet} {
      width: auto;
      height: 100%;
    }
  }
`;

const Content = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  padding: ${props =>
    `${props.theme.margins.base_x8} ${props.theme.margins.base_x4} ${props.theme.margins.base_x4}`};
  font-size: ${props => props.theme.fonts.sizes.body};

  ${props => props.theme.breakpoints.tablet} {
    min-height: ${rem(352)};
    align-content: center;
    max-width: 50%;
  }

  ${props => props.theme.breakpoints.desktop} {
    min-height: ${rem(475)};
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    min-height: ${rem(582)};
  }
`;

const ContainerStyled = styled(Container)`
  position: relative;
`;

const ButtonWrapper = styled.div`
  a {
    line-height: normal;
  }
`;

const StyledHeading = styled(Heading)`
  font-size: ${props => props.theme.fonts.sizes.h4};
  font-weight: ${props => props.theme.fonts.weights.bold};
  line-height: ${props => props.theme.fonts.lineHeights.h3};

  ${props => props.theme.breakpoints.tablet} {
    font-size: ${props => props.theme.fonts.sizes.h2};
    line-height: ${props => props.theme.fonts.lineHeights.h2};
  }
`;

const StyledSliceContainer = styled(SliceContainer)`
  padding-bottom: ${props => props.theme.margins.base_x8};

  ${props => props.theme.breakpoints.desktop} {
    padding-bottom: ${rem(128)};
  }
`;

const ParagraphWrapper = styled.div`
  ${props => props.theme.breakpoints.desktop} {
    margin-right: ${props => props.theme.margins.base_x2};
  }
`;
