import * as React from "react";

import { TestWrapper } from "@ui/Atoms/TestWrapper";
import * as Grid from "@ui/Atoms/Grid";

import * as Styled from "./SubpageLayout.styled";

interface Props {
  testId?: string;
  className?: string;
  children?: React.ReactNode;
}

const Layout = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <Grid.Container>
      <Grid.Row>
        <Grid.Column xs={12} lg={8}>
          <Styled.Layout className={props.className}>
            {props.children}
          </Styled.Layout>
        </Grid.Column>
      </Grid.Row>
    </Grid.Container>
  </TestWrapper>
);

export { Layout };
