import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const CheckboxLine = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path
        id="Shape"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 4.6665H16C17.8408 4.6665 19.3333 6.15898 19.3333 7.99984V15.9998C19.3333 17.8407 17.8408 19.3332 16 19.3332H7.99999C6.15913 19.3332 4.66666 17.8407 4.66666 15.9998V7.99984C4.66666 6.15898 6.15913 4.6665 7.99999 4.6665ZM16 5.99984H7.99999C6.89551 5.99984 5.99999 6.89536 5.99999 7.99984V15.9998C5.99999 17.1043 6.89551 17.9998 7.99999 17.9998H16C17.1045 17.9998 18 17.1043 18 15.9998V7.99984C18 6.89536 17.1045 5.99984 16 5.99984Z"
        fill="#666666"
      />
    </svg>
  );
};
