import * as React from "react";
import { useTheme } from "styled-components";

import { Icon, style } from "@ui/Assets/Symbolicons/Symbolicons";

export const Pencil = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      fill="none"
      viewBox="0 0 96 96"
      style={{ ...style(props.size, props.height), ...props.style }}
      className={props.className}
    >
      <path
        fill={props.color || theme.colors.black500}
        fillRule="evenodd"
        d="M67.877 16c3.24 0 6.283 1.261 8.571 3.552A12.031 12.031 0 0180 28.122a12.04 12.04 0 01-3.552 8.571l-6.032 6.032-17.141-17.141 6.032-6.032A12.036 12.036 0 0167.877 16zM16 80l6.736-23.877 26.76-26.76 17.141 17.141-26.76 26.76L16 80z"
        clipRule="evenodd"
      />
    </svg>
  );
};
