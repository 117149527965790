import React from "react";
import styled from "styled-components";

import { SkeletonGenerator } from "@ui/Atoms/Skeleton";
import { rem } from "@ui/helpers";

export const HourRowSkeleton = (): React.ReactElement => (
  <SkeletonWrapper>
    <SkeletonGenerator count={3} />
  </SkeletonWrapper>
);

const SkeletonWrapper = styled.div`
  margin: auto;

  ${props => props.theme.breakpoints.tablet} {
    width: ${rem(560)};
  }

  ${props => props.theme.breakpoints.desktop} {
    margin-left: -${props => props.theme.margins.base_x2};
  }

  ${props => props.theme.breakpoints.desktopLarge} {
    margin-left: auto;
  }
`;
