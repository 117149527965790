import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const UserOff = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <path
      fill={props.color || theme.colors.grey700}
      fillRule="nonzero"
      d="M10.231 14.133a.667.667 0 01-.478.812c-1.834.475-3.006 1.462-3.082 2.453l-.004.102v1.833a.667.667 0 01-1.33.078l-.004-.078V17.5c0-1.762 1.668-3.22 4.086-3.845a.667.667 0 01.812.478zM5.075 4.14l.063.055 14.667 14.667a.667.667 0 01-.88.998l-.063-.055L4.195 5.138a.667.667 0 01.88-.998zM12 4c2.208 0 4 1.792 4 4 0 .858-.279 1.675-.784 2.36a.667.667 0 11-1.073-.79c.339-.46.524-1.002.524-1.57A2.668 2.668 0 0012 5.333a2.64 2.64 0 00-1.57.524.667.667 0 11-.79-1.074A3.972 3.972 0 0112 4z"
    ></path>
  </svg>
);
