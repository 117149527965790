import { List, Record } from "immutable";

import { AVAILABLE_DIALING_CODES, TITLE_OPTS } from "@typings/globals";
import { FileAPIAccepted } from "@typings/fileUploading";

import { ApplyForm } from "./apply.typings";

export const applyFormDefaultState = {
  email: null,

  // personal info
  title: TITLE_OPTS.MS,
  firstName: "",
  lastName: "",
  phoneNumberPrefix: AVAILABLE_DIALING_CODES.CH,
  phoneNumber: "",
  streetAddress: "",
  zipCode: "",
  city: "",

  // experience info
  workPermitType: null,
  hoursPerWeek: null,
  yearsOfExperience: null,
  sourcesOfExperience: List(),
  files: List(),
  methodOfCommuting: null,
};

export const deleteApplicationFileFactory = (
  form: Record<ApplyForm>,
  fileToDeleteId: string,
): List<FileAPIAccepted> =>
  form.get("files").filter(file => file.id !== fileToDeleteId);
