/**
 * @author michalstolarski
 * @since 2022-11-8
 */

import * as React from "react";
import styled from "styled-components";

import { TestWrapper } from "@ui/Atoms/TestWrapper";

interface Props {
  testId?: string;
  className?: string;
  children: React.ReactNode;
}

const ExtendedJobDetailsContainer = (props: Props): React.ReactElement => (
  <TestWrapper testId={props.testId}>
    <Container className={props.className}>{props.children}</Container>
  </TestWrapper>
);

export { ExtendedJobDetailsContainer };

const Container = styled.div`
  font-size: ${props => props.theme.fonts.sizes.body};
  line-height: ${props => props.theme.fonts.lineHeights.normal};
  display: grid;
  gap: ${props => props.theme.margins.base_x3};
`;
