/**
 * Not yet a real hook, but it might become one.
 *
 * Use it to manage vouchers in sessionStorage to reuse them between different views/pages.
 * At any given time sessionStorage should hold max. one 'normal' voucher and one deeplink voucher.
 */
import { VOUCHER_FROM_DEEPLINK_KEY, VOUCHER_USED_KEY } from "@config/consts";

import { sessionStorageFacade } from "@services/SessionStorageFacade";

const useVoucherManager = () => {
  const sessionStorage = sessionStorageFacade();

  const storeVoucher = (voucherValue: string) => {
    return sessionStorage.setItem(VOUCHER_USED_KEY, voucherValue);
  };

  const removeStoredVoucher = () => {
    sessionStorage.removeItem(VOUCHER_USED_KEY);
  };

  const getStoredVoucher = () => {
    return sessionStorage.getItem(VOUCHER_USED_KEY);
  };

  const storeDeeplinkVoucher = (voucherValue: string) => {
    return sessionStorage.setItem(VOUCHER_FROM_DEEPLINK_KEY, voucherValue);
  };

  const removeStoredDeeplinkVoucher = () => {
    sessionStorage.removeItem(VOUCHER_FROM_DEEPLINK_KEY);
  };

  const getStoredDeeplinkVoucher = () => {
    return sessionStorage.getItem(VOUCHER_FROM_DEEPLINK_KEY);
  };

  const getVoucherFromURLParameter = () => {
    return new URLSearchParams(window.location.href.split("?")[1]).get(
      "voucher",
    );
  };

  return {
    getVoucherFromURLParameter,

    storeVoucher,
    removeStoredVoucher,
    getStoredVoucher,

    storeDeeplinkVoucher,
    removeStoredDeeplinkVoucher,
    getStoredDeeplinkVoucher,
  };
};

export { useVoucherManager };
