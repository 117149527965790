import React from "react";

import { NotificationBall, Color } from "@ui/Atoms/NotificationBall";

interface Props {
  className?: string;
  color?: Color;
  total: number | null;
}
const TotalCounter = React.memo((props: Props): React.ReactElement | null => {
  if (!props.total) {
    return null;
  }

  return (
    <NotificationBall className={props.className} color={props.color}>
      {props.total}
    </NotificationBall>
  );
});

TotalCounter.displayName = "TotalCounter";

export { TotalCounter };
