import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const DotsMenu = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M18.5027 12.0002C18.5027 12.2765 18.2788 12.5004 18.0025 12.5004C17.7262 12.5004 17.5023 12.2765 17.5023 12.0002C17.5023 11.724 17.7262 11.5 18.0025 11.5C18.2788 11.5 18.5027 11.724 18.5027 12.0002" />
      <path d="M12.5002 12.0002C12.5002 12.2765 12.2763 12.5004 12 12.5004C11.7237 12.5004 11.4998 12.2765 11.4998 12.0002C11.4998 11.724 11.7237 11.5 12 11.5C12.2763 11.5 12.5002 11.724 12.5002 12.0002" />
      <path d="M6.4977 12.0002C6.4977 12.2765 6.27375 12.5004 5.99749 12.5004C5.72123 12.5004 5.49728 12.2765 5.49728 12.0002C5.49728 11.724 5.72123 11.5 5.99749 11.5C6.27375 11.5 6.4977 11.724 6.4977 12.0002" />
    </svg>
  );
};
