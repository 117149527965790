import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const LogoutExitCircle = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <g>
        <path d="M8.5 15V9" />
        <path d="M14 14.5L11.5 12L14 9.5" />
        <path d="M11.5 12H17.5" />
        <circle cx="12.5" cy="12" r="9" />
      </g>
    </svg>
  );
};
