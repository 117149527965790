import React from "react";

import * as Styled from "./LoaderV2.styled";

const List = () => (
  <Styled.Icon
    className="loader-list"
    width="76%"
    height="52%"
    viewBox="0 0 76 52"
    fill="currentColor"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      x1="2"
      y1="2"
      x2="62"
      y2="1.99999"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <rect x="71" width="4" height="4" rx="1" />

    <line
      x1="2"
      y1="18"
      x2="62"
      y2="18"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <rect x="71" y="16" width="4" height="4" rx="1" />

    <line
      x1="2"
      y1="34"
      x2="46"
      y2="34"
      strokeWidth="4"
      strokeLinecap="round"
    />
    <rect x="71" y="32" width="4" height="4" rx="1" />

    <line
      x1="2"
      y1="50"
      x2="46"
      y2="50"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </Styled.Icon>
);

const Bell = () => (
  <Styled.Icon
    className="loader-bell"
    width="16%"
    height="32%"
    viewBox="0 0 16 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
  >
    <path d="M15.5854 29.3604C15.2983 29.2295 15.0508 29.0257 14.8328 28.7737C14.1857 28.0256 13.8267 27.0944 13.5361 26.1228C12.9651 24.2091 12.8137 22.2275 12.7471 20.2224C12.6848 18.356 11.8534 17.027 10.3585 16.2602C9.97184 16.0623 9.55227 15.9481 9.14395 15.7946C9.84554 14.9559 9.84294 14.9578 9.34733 13.8603C9.06349 13.2322 8.8897 12.5648 8.93113 11.8521C9.03062 10.159 9.12665 8.46384 9.25814 6.77364C9.38444 5.15235 9.58774 3.53894 9.69155 1.91667C9.77028 0.680279 9.18028 0.00597249 8.14563 3.2897e-05C7.01504 -0.00584017 6.48465 0.77478 6.60066 2.25432C6.80733 4.89444 7.03052 7.53359 7.19835 10.1767C7.28054 11.4662 7.41117 12.7814 6.76062 13.9813C6.28135 14.8653 6.32028 15.0268 7.0608 15.7778C6.90768 15.8339 6.81252 15.8753 6.71477 15.9058C4.47331 16.5417 3.43001 18.0449 3.35561 20.6732C3.31322 22.1616 3.16356 23.6431 2.78465 25.0754C2.32442 26.8128 1.89715 28.5798 0.215321 29.4057C-0.101303 29.5612 -0.0615082 29.8782 0.287124 30.0672C0.80964 30.3507 1.36849 30.4885 1.93262 30.5702C5.74067 31.1215 9.55833 31.0989 13.3777 30.7484C14.107 30.6815 14.8389 30.5624 15.5309 30.2562C15.729 30.1686 16.0059 30.1401 15.9999 29.8044C15.9946 29.5288 15.7801 29.449 15.5854 29.3604Z" />
    <path d="M7.89625 31.9897C8.44479 32.0633 8.79175 31.7444 9 31H7C7.12589 31.6349 7.44063 31.9296 7.89625 31.9897Z" />
  </Styled.Icon>
);

export { List, Bell };
