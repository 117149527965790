import * as React from "react";
import { useTheme } from "styled-components";

import { style, Icon } from "./Symbolicons";

export const LightBulb = (props: Icon): React.ReactElement => {
  const theme = useTheme();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      style={{ ...style(props.size, props.height), ...props.style }}
    >
      <path
        fill={props.color || theme.colors.grey700}
        fillRule="evenodd"
        d="M14.4 5.49c5.036-.982 9.6 2.82 9.6 7.843a7.947 7.947 0 01-1.365 4.465l-.44.618c-.51.74-.918 1.532-1.21 2.353l-.19.566a2.685 2.685 0 01-2.296 1.768l-.21.008h-4.591c-1.18 0-2.203-.78-2.554-1.913a10.757 10.757 0 00-1.356-2.795l-.422-.604c-1.174-1.736-1.628-3.868-1.217-6.029.6-3.142 3.114-5.67 6.252-6.28zm3.378 19.399a.889.889 0 01.104 1.772l-.104.006h-3.556a.889.889 0 01-.103-1.772l.103-.006h3.556zm-2.77-17.701c3.825-.585 7.214 2.325 7.214 6.145a6.17 6.17 0 01-1.07 3.485l-.455.638a11.854 11.854 0 00-1.55 3.207c-.123.405-.468.67-.858.67h-4.591l-.128-.01a.916.916 0 01-.728-.651 12.562 12.562 0 00-1.635-3.345l-.246-.35-.193-.28c-.86-1.33-1.187-2.943-.873-4.594.449-2.353 2.3-4.274 4.62-4.82l.226-.049.267-.046z"
        clipRule="evenodd"
      />
    </svg>
  );
};
