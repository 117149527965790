import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const EmailAt = (props: Icon): React.ReactElement => (
  <svg
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <defs>
      <path
        d="M12 5.333A6.666 6.666 0 0118.667 12 2.667 2.667 0 0114 13.764 2.667 2.667 0 1114.666 12a1.333 1.333 0 102.667 0A5.333 5.333 0 1012 17.333a.667.667 0 010 1.334 6.666 6.666 0 110-13.334zm0 5.334a1.333 1.333 0 100 2.666 1.333 1.333 0 000-2.666z"
        id="emailat_a"
      />
    </defs>
    <g transform="translate(-5 -5)" fill="none" fillRule="evenodd">
      <mask id="emailat_b" fill="#fff">
        <use xlinkHref="#emailat_a" />
      </mask>
      <use fill="#FFF" fillRule="nonzero" xlinkHref="#emailat_a" />
      <g mask="url(#emailat_b)" fill={props.color || theme.colors.grey700}>
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
