import * as React from "react";

import theme from "@ui/themes/default";

import { style, Icon } from "./Symbolicons";

export const Megaphone = (props: Icon): React.ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 16 16"
    style={{ ...style(props.size, props.height), ...props.style }}
  >
    <defs>
      <path
        id="megaphone-a"
        d="M11.28 19.07a.667.667 0 01-1.191.596l-.035-.07-1.828-4.263H6A2 2 0 014.018 13.6l-.015-.148L4 13.333v-4a2 2 0 012-2h6.464l3.833-2.554a.667.667 0 011.036.554v3.334a2.667 2.667 0 01.133 5.33l-.133.003v3.333c0 .533-.593.85-1.036.555l-3.832-2.555H9.677l1.602 3.738zM16 6.58l-2.964 1.976a.667.667 0 01-.37.112H6a.666.666 0 00-.667.666v4c0 .369.299.667.667.667h6.667a.67.67 0 01.37.112L16 16.087V6.58zM17.333 10v2.667a1.333 1.333 0 00.1-2.663l-.1-.004z"
      ></path>
    </defs>
    <use
      fill={props.color || theme.colors.grey700}
      transform="translate(-4 -4)"
      xlinkHref="#megaphone-a"
    ></use>
  </svg>
);
