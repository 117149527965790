import React from "react";
import styled from "styled-components";

import { rem, transition } from "@ui/helpers";

interface Props {
  title: string;
  backgroundPhoto: string;
  hashTags: string;
  link: string;
}

const BlogCard = (props: Props): React.ReactElement => (
  <Wrapper href={props.link}>
    <Background backgroundPhoto={props.backgroundPhoto} />
    <Label>
      <Title>{props.title}</Title>
      <HashTags>{props.hashTags}</HashTags>
      <Overlay />
    </Label>
  </Wrapper>
);

export { BlogCard };

const Background = styled.div<{ backgroundPhoto: string }>`
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.greyLight};
  background-image: url(${props => props.backgroundPhoto});
  background-size: cover;
  background-position: center;
  overflow: hidden;
  /* uncomment when we need animation */
  /* transition: ${transition("transform")}; */
`;

const Wrapper = styled.a`
  display: block;
  position: relative;
  height: ${rem(256)};
  max-width: 100%;
  border-radius: ${props => props.theme.border.radius.base};
  overflow: hidden;
  cursor: pointer;

  ${props => props.theme.breakpoints.tablet} {
    max-width: ${rem(328)};
  }

  /* uncomment when we need animation */
  /* &:hover {
    ${Background} {
      transform: scale(1.04);
    }
  } */
`;

const Label = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: ${props => props.theme.margins.base_x2};
  color: ${props => props.theme.colors.white};
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => props.theme.colors.black500};
  opacity: 0.7;
  z-index: 1;
`;

const Title = styled.div`
  position: relative;
  font-size: ${props => props.theme.fonts.sizes.body};
  font-weight: ${props => props.theme.fonts.weights.bold};
  z-index: 2;
`;

const HashTags = styled.div`
  position: relative;
  font-size: ${props => props.theme.fonts.sizes.body2};
  z-index: 2;
`;
