import * as React from "react";

import { style, MyIconProps } from "./MyIcons";

export const Pin = (props: MyIconProps): React.ReactElement => {
  return (
    <svg
      ref={props.elementRef}
      className={props.className}
      style={{ ...style(props), ...props.style }}
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      //   please check if below code is valid for your icon
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    >
      <path d="M3.5 19.9999L7.87 15.6299" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.956 11.294C4.565 11.685 4.565 12.318 4.956 12.708L7.874 15.626L10.792 18.544C11.183 18.935 11.816 18.935 12.206 18.544L13.381 17.369C13.569 17.181 13.674 16.927 13.674 16.662V12.726L16.574 9.82597L18.883 9.94897C19.166 9.96397 19.443 9.85797 19.643 9.65797L20.217 9.08397C20.608 8.69297 20.608 8.05997 20.217 7.66997L15.831 3.28397C15.44 2.89297 14.807 2.89297 14.417 3.28397L13.843 3.85797C13.642 4.05897 13.536 4.33497 13.552 4.61797L13.675 6.92697L10.775 9.82697H6.838C6.573 9.82697 6.318 9.93197 6.131 10.12L4.956 11.294V11.294Z"
      />
      <path d="M16.57 9.82993L13.67 6.92993" />
    </svg>
  );
};
