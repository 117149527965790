import React from "react";
import {
  PrismicRichText,
  SliceComponentProps,
  SliceLike,
} from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css, DefaultTheme, useTheme } from "styled-components";

import { Check } from "@ui/Assets/Symbolicons";
import { Container, Heading } from "@ui/Atoms";
import { rem } from "@ui/helpers";

import { useBreakpoint } from "@hooks/useBreakpoint";

import { PrismicGlobalContentPageDataBodyPictureWithBullets } from "../../../graphql-types";
import { SliceContainer } from "../../components/SliceContainer";

// temporary workaround until @prismicio/react catches up with React18
type SliceWithType = {
  slice_type: string;
};

const PictureWithBullets = (
  props: SliceComponentProps<PrismicGlobalContentPageDataBodyPictureWithBullets>,
): React.ReactElement => {
  const theme = useTheme();
  const { mobile, tablet, desktop, desktopLarge } = useBreakpoint();

  const hasBullets = !!props.slice.items?.some(
    item => item?.marker !== BULLET_MARKER.NONE,
  );

  const sliceIndex = props.slices
    ?.filter(
      (slice: SliceLike & SliceWithType) =>
        slice.slice_type === "picture_with_bullets",
    )
    ?.indexOf(props.slice);

  const getImagePerBreakpoint = () => {
    if (mobile && props.slice.primary?.image_mobile) {
      return props.slice.primary.image_mobile.gatsbyImageData;
    }
    if (tablet && props.slice.primary?.image_tablet) {
      return props.slice.primary.image_tablet.gatsbyImageData;
    }
    if ((desktop || desktopLarge) && props.slice.primary?.image) {
      return props.slice.primary.image.gatsbyImageData;
    }
    return null;
  };

  const image = getImagePerBreakpoint();

  return (
    <StyledSliceContainer>
      <StyledContainer widthVariant="fullMobile">
        {!mobile && props.slice.primary?.title1?.text && (
          <Header type={"h2"} styleAs={"h4"}>
            {props.slice.primary?.title1?.text}
          </Header>
        )}

        <Inner isFirst={sliceIndex === 0}>
          {image && (
            <Image
              alt={""}
              image={image}
              objectFit={mobile || tablet ? "cover" : "contain"}
              alignment={props.slice.primary?.image_alignment_dry?.toLocaleLowerCase()}
              mobileFullWidth={!!props.slice.primary?.image_mobile_full_width}
            />
          )}

          {mobile && props.slice.primary?.title1?.text && (
            <MobileHeader type={"h4"}>
              {props.slice.primary?.title1?.text}
            </MobileHeader>
          )}

          <List
            alignment={props.slice.primary?.image_alignment_dry?.toLocaleLowerCase()}
          >
            {props.slice.items?.map(bullet => (
              <ListItem withBullets={hasBullets} key={bullet?.header}>
                <StyledHeading type={"h5"}>
                  {renderMarker(bullet?.marker, theme)}
                  {bullet?.header}
                </StyledHeading>

                {bullet?.description?.raw && (
                  <PrismicRichText field={bullet.description.raw} />
                )}
              </ListItem>
            ))}
          </List>
        </Inner>
      </StyledContainer>
    </StyledSliceContainer>
  );
};

export { PictureWithBullets };

enum BULLET_MARKER {
  NONE = "none",
  CHECK = "check",
}

const renderMarker = (
  marker: string | undefined | null,
  theme: DefaultTheme,
) => {
  return marker === BULLET_MARKER.CHECK ? (
    <CheckStyled size={14} variant="line" color={theme.colors.green300} />
  ) : null;
};

type AligmentImageType = "left" | "right" | string;

const StyledContainer = styled(Container)`
  background: ${props => props.theme.colors.white};
`;

const Inner = styled.div<{ isFirst: boolean }>`
  display: grid;
  align-items: center;
  gap: ${props => props.theme.margins.base_x1_5}
    ${props => props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.desktop} {
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;
  }
`;

const List = styled.ul<{ alignment?: AligmentImageType }>`
  margin: 0;
  padding: 0 ${props => props.theme.margins.base_x3};
  list-style: none;

  ${props => props.theme.breakpoints.tablet} {
    padding: 0;
  }

  ${props => props.theme.breakpoints.desktop} {
    order: 2;
  }

  ${props =>
    props.alignment === "right" &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        padding-right: ${props => props.theme.margins.base_x3};
        padding-left: ${props => props.theme.margins.base_x2};
      }
    `}

  ${props =>
    props.alignment !== "right" &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        padding-left: ${props => props.theme.margins.base_x3};
        padding-right: ${props => props.theme.margins.base_x2};
      }
    `}
`;

const Image = styled(GatsbyImage)<{
  alignment?: AligmentImageType;
  mobileFullWidth?: boolean;
}>`
  margin: 0
    ${props => (props.mobileFullWidth ? "0" : props.theme.margins.base_x2)};

  ${props => props.theme.breakpoints.tablet} {
    margin: 0;
  }

  ${props => props.theme.breakpoints.desktop} {
    margin: 0 ${props => props.theme.margins.base_x2};
    order: 1;
  }

  ${props =>
    props.alignment === "right" &&
    css`
      ${props => props.theme.breakpoints.desktop} {
        order: 3;
      }
    `}
`;

const CheckStyled = styled(Check)`
  position: absolute;
  transform: translateY(100%) translateX(-150%);
`;

const ListItem = styled.li<{ withBullets: boolean }>`
  list-style: none;
  margin-bottom: ${props => props.theme.margins.base_x4};

  ${props => props.theme.breakpoints.desktop} {
    margin-bottom: ${props => props.theme.margins.base_x3};
  }

  p {
    margin-bottom: ${props => props.theme.margins.base};
  }

  ${props =>
    props.withBullets &&
    css`
      padding-left: ${props => props.theme.margins.base_x3};
    `}
`;

const MobileHeader = styled(Heading)`
  margin: ${props => props.theme.margins.base_x3}
    ${props => props.theme.margins.base_x3} ${props => props.theme.margins.base};
  font-size: ${props => props.theme.fonts.sizes.h5};
  line-height: ${props => props.theme.fonts.lineHeights.h5};
`;

const StyledHeading = styled(Heading)`
  font-weight: ${props => props.theme.fonts.weights.bold};
`;

const StyledSliceContainer = styled(SliceContainer)`
  ${props => props.theme.breakpoints.tablet} {
    padding-top: 0;
  }
`;

const Header = styled(Heading)`
  text-align: center;
  font-size: ${props => props.theme.fonts.sizes.h3};
  margin-bottom: ${props => props.theme.margins.base_x4};
  line-height: ${rem(56)};
`;
