export enum ROUTES {
  // Generic
  BASE = "/",
  PRIVACY_POLICY = "/batwork-privacy-policy",
  TERMS_OF_USE = "/batwork-terms-of-use",
  ALTN = "/downloads/altn_en.pdf",
  PAYMENT_PROCESSED = "/payment-is-being-processed",

  // Booking
  BOOKING = "/booking",
  BOOKING_EMPLOYEE_SELECTION = "/booking/employee",
  BOOKING_SALARY_PROPOSAL = "/booking/salary",
  BOOKING_ACCOUNT_CREATION = "/booking/account",
  BOOKING_SELECT_PLAN = "/booking/plan",
  BOOKING_EMAIL_CONFIRMATION = "/booking/email-confirmation",
  BOOKING_THANK_YOU = "/booking/thank-you",

  // Client dashboard
  CLIENT_DASHBOARD = "/client",
  CLIENT_DASHBOARD_PROFILE = "/profile",
  CLIENT_DASHBOARD_OPTIONS = "/options",

  // HR Application
  APPLY = "/apply",
  APPLY_PERSONAL_INFO = "/apply/personal-information",
  APPLY_EXPERIENCE_INFO = "/apply/experience-information",
  THANK_YOU_PAGE = "/apply/thank-you",
}
