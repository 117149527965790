/**
 * @author piotrzurek
 * @since 2021-11-30
 */

import * as React from "react";

interface Props {
  value: number; // 0 - 100
  color: string;
  fillColor: string;
  className?: string;
}

const Pie = (props: Props): React.ReactElement => (
  <svg className={props.className} height="20" width="20" viewBox="0 0 20 20">
    <circle r="10" cx="10" cy="10" fill={props.color} />
    <circle
      r="5"
      cx="10"
      cy="10"
      fill="transparent"
      stroke={props.fillColor}
      strokeWidth="10"
      strokeDasharray={`calc(${props.value} * 31.4 / 100) 31.4`}
      transform="rotate(-90) translate(-20)"
    />
  </svg>
);

Pie.displayName = "Pie";

export { Pie };
