import React from "react";
import { navigate } from "gatsby";

import { AVAILABLE_COUNTRIES, AVAILABLE_LANGS, ORIGIN } from "@typings/globals";

import { Header as AppHeader } from "@app/containers/Header/Header";

import { Header as SoftHeader } from "@soft/containers/Header/Header";

import { Header as WorkHeader } from "@work/containers/Header/Header";

interface Props {
  origin?: ORIGIN;
  staticLanguage: AVAILABLE_LANGS;
  country: AVAILABLE_COUNTRIES;
  isTeaserVersion?: boolean;
}

const Header = (props: Props): React.ReactElement => {
  const headerProps = {
    useNativeLinks: true,
    isSSR: true,
    staticLanguage: props.staticLanguage,
    isTeaserVersion: props.isTeaserVersion,
    staticNavigate: navigate,
  };

  /* 
    It is intentional, gatsby during build time gets mad when
    - switch is used
    - const with process.env.[someKey] value is used
    - enum is used
    @TODO: find a way to use enums
  */

  if (process.env.ORIGIN === "batsoft") {
    return <SoftHeader {...headerProps} />;
  }

  if (process.env.ORIGIN === "batwork") {
    return <WorkHeader {...headerProps} />;
  }

  if (process.env.ORIGIN === "batmaid") {
    return <AppHeader {...headerProps} />;
  }

  return <div />;
};

export default Header;
