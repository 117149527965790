import * as React from "react";
import styled from "styled-components";

import { Caret } from "@ui/Assets/Symbolicons/Caret";
import { Icon } from "@ui/Assets/Symbolicons/Symbolicons";

interface Props {
  isRight?: boolean;
  iconStyles: Icon;
}

const CaretWithDirection = (props: Props): React.ReactElement => (
  <CaretWrapper isRight={props.isRight}>
    <Caret {...props.iconStyles} />
  </CaretWrapper>
);

const CaretWrapper = styled.div<{ isRight?: boolean }>`
  transform: rotate(${props => (props.isRight ? "-90deg" : "90deg")});
`;

export { CaretWithDirection };
